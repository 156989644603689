<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BasicLayout',
})
</script>
<template>
  <a-layout :class="['layout', device]">
    <!-- SideMenu -->
    <a-drawer
      v-if="device === 'mobile' || device === 'tablet' || !fixSidebar"
      placement="left"
      :class="`drawer-sider ${navTheme}`"
      :closable="false"
      :open="collapsed"
      @close="drawerClose"
      width="210px"
      bodyStyle="padding:0"
    >
      <side-menu mode="inline" :menus="menus" :theme="navTheme" :collapsed="false" :collapsible="true" @menuSelect="menuSelect"></side-menu>
    </a-drawer>

    <side-menu v-else-if="isSideMenu()" mode="inline" :menus="menus" :theme="navTheme" :collapsed="collapsed" :collapsible="true"></side-menu>
    <a-layout :class="['transition-layout', layoutMode, `content-width-${contentWidth}`]" :style="{ paddingLeft: contentPaddingLeft, background: '#f8f9fd' }">
      <!-- layout header -->
      <global-header :mode="layoutMode" :menus="menus" :theme="navTheme" :collapsed="collapsed" :device="device" @toggle="toggle" @refresh="onRefresh" />

      <!-- layout content -->
      <!-- <a-layout-content :style="{ height: '100%', }"> -->
      <a-layout-content :style="{ height: '100%' }">
        <multi-tab v-if="multiTab"></multi-tab>
        <transition name="page-transition">
          <section class="layout-content">
            <route-view v-if="showRouter" />
          </section>
        </transition>
      </a-layout-content>
      <!-- layout footer -->
    </a-layout>
  </a-layout>
</template>

<script lang="ts" setup name="BasicLayout">
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import { triggerWindowResizeEvent, isMobile, isTablet, isDesktop } from '@/utils/device'
import RouteView from './RouteView.vue'
import MultiTab from '@/components/MultiTab/index.vue'
import SideMenu from '@/components/Menu/SideMenu.vue'
import GlobalHeader from '@/components/GlobalHeader/index.vue'
import { convertRoutes } from '@/router/generateAsyncRoutes'
import { filteRouterPermission } from '@/router/permission'
import { PERMISSION, SET_SIDEBAR_TYPE } from '@/store/mutation-types'
import cloneDeep from 'lodash.clonedeep'
import useSiteSettings from '@/store/useSiteSettings'
import ls from '@/utils/Storage'
import { systemConfig } from '@/store/reactiveState'
import { useRouter } from 'vue-router'
import emitter from '@/utils/eventBus'
import baseService from '@/utils/http/axios'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()
const collapsed = ref(false)
const menus = ref([])
const { fixSidebar, sidebarOpened, multiTab, device, layoutMode, contentWidth, fixedHeader, navTheme, isSideMenu } = useSiteSettings()

const contentPaddingLeft = computed(() => {
  if (!fixSidebar.value || device.value === 'tablet' || device.value === 'mobile') {
    return '0'
  }
  if (sidebarOpened.value) {
    return '210px'
  }
  return '80px'
})

watch(
  () => sidebarOpened.value,
  (val) => {
    collapsed.value = !val
  }
)

// console.log('?????????>>>>>>>>>>>')
// console.log(router.getRoutes())
// created()
// bug:TODO:克隆时报警告[Vue warn]: Avoid app logic that relies on enumerating keys on a component instance. The keys will be empty in production mode to avoid performance overhead.目前还不知道解决方案
// const mainMenu = cloneDeep(router.getRoutes())
// const orginRoutes = filteRouterPermission(mainMenu, ls.get(PERMISSION))
//改成这样
const orginRoutes = router.getRoutes()
// 相对路径转绝对路径
// 系统菜单以/为第一级,/外面的都不显示在菜单中,但是可以跳转到该路由
const routes = convertRoutes(orginRoutes.find((item) => item.path === '/'))
menus.value = (routes && routes.children) || []
collapsed.value = !sidebarOpened.value

onMounted(() => {
  getUserMenu()

  const userAgent = navigator.userAgent
  if (userAgent.indexOf('Edge') > -1) {
    nextTick(() => {
      collapsed.value = !collapsed.value
      setTimeout(() => {
        collapsed.value = !collapsed.value
      }, 16)
    })
  }
})

const structRoute = (path, query, name, title = '') => {
  return {
    name,
    path,
    meta: { title: title ? title : name },
    params: { query }
  }
}

//显示动态栏目
const showHideMenu = (item,actionChildren=true) => {
  item.meta.hidden = false;
  if(actionChildren){
    item.hideChildrenInMenu = false;
    ;
    (item.children || []).map((childrenItem) => {
      childrenItem.meta.hidden = false
    })
  }
}

//获取菜单栏
const getUserMenu = () => {
  baseService.post('/api/user/menu').then(({ data }) => {
    const toolsMenu = data.toolsMenu || []
    const userType = data.userType || 0
    const knowledge = data.knowledge || 0

    menus.value.map((item, index) => {
      if (item.name == 'tool') {
        toolsMenu.map((toolItem) => {
          item.children.push(structRoute('/dashboard/tools-detail?id=' + toolItem.id, {}, toolItem.name))
        })
      }
      if (item.name == 'knowledge' && knowledge) {
        showHideMenu(item, false)
      }
      if (item.name == 'invoice' && store.state.user.user_ident == 'person') {
        item.meta.hidden = true;
      }
      if (item.name == 'account-company' && store.state.user.user_ident == 'company') {
        showHideMenu(item, false)
      }
      if (item.name == 'order-page' && store.state.user.user_ident == 'company') {
        item.meta.hidden = false;
      }
      if (item.name == 'account-user' && (store.state.user.user_ident == 'company_member' || store.state.user.user_ident == 'person')) {
        showHideMenu(item)
      }
    })
  })
}

const toggle = () => {
  collapsed.value = !collapsed.value
  systemConfig.commit(SET_SIDEBAR_TYPE, !collapsed.value)
  triggerWindowResizeEvent()
}
const paddingCalc = () => {
  let left = ''
  if (sidebarOpened.value) {
    left = isDesktop.value ? '210px' : '80px'
  } else {
    left = (isMobile.value && '0') || (fixSidebar.value && '80px') || '0'
  }
  return left
}
const menuSelect = () => {}
const drawerClose = () => {
  collapsed.value = false
}

const showRouter = ref(true)
const onRefresh = () => {
  emitter.all.clear()
  showRouter.value = false
  nextTick(() => (showRouter.value = true))
}
</script>

<style lang="less">
/*
 * The following styles are auto-applied to elements with
 * transition="page-transition" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the page transition by editing
 * these styles.
 */
.layout {
  background-color: #f8f9fd;
}

.page-transition-enter {
  opacity: 0;
}

.page-transition-leave-active {
  opacity: 0;
}

.page-transition-enter .page-transition-container,
.page-transition-leave-active .page-transition-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.transition-layout {
  transition: padding-left 0.3s ease-in-out; /* 0.3秒的过渡效果 */
}
</style>
