import app from './modules/global'
import user from './modules/user'
import project from './modules/project'
import tools from './modules/tools'
import tools2 from './modules/tools2'
import tools2P from './modules/tools2P'
import { createStore } from 'vuex';
// import { genStore } from '@/utils/batchImportFiles'

// const allStores = genStore()
const store = createStore({
    modules: {
        app,
        project,
        user,
        tools,
        tools2,
        tools2P,
        // ...allStores
    },
});

export default store
