<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'history',
})
</script>
<template>
    <div class="account">
        <div class="header">
            <div class="title">成员管理</div>
            <div class="subtitle">支持查看本地的历史方案</div>
        </div>

        <div class="body">
            <div id="table-container" class="children">
                <div class="searchBox">
                    <div class="aciton">
                        <a-space :size="16">
                            <a-button type="primary" @click="handleCreate">
                                <template #icon>
                                    <PlusOutlined />
                                </template>
                                添加成员
                            </a-button>

                            <a-button type="primary" @click="handleApply">
                                扩增成员申请
                            </a-button>
                        </a-space>
                    </div>
                    <div class="search">
                        <a-space :size="16">
                            <a-input v-model:value="keywords" placeholder="搜索成员名称/手机号" @pressEnter="getData">
                                <template #suffix>
                                    <search-outlined style="color: #999" @click="getData" />
                                </template>
                            </a-input>

                            <a-button @click="handleFilter">
                                <template #icon>
                                    <SvgIcon name="icon-filter" size="16" />
                                </template>
                            </a-button>
                        </a-space>
                    </div>
                </div>

                <div class="tableWrapper">
                    <!-- :row-selection="{ columnWidth: 80, selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange }" -->

                    <a-table :scroll="{ y: '100%' }"
                        :columns="columns" :data-source="dataList">
                        <template #bodyCell="{ column, text, record, index }">
                            <template v-if="column.dataIndex === 'departments'">

                                <a-tag v-for="label in record.departments" :key="label">{{ label.name }}</a-tag>

                            </template>
                            <template v-else-if="column.dataIndex === 'is_disabled'">

                                <a-badge :color="record.is_disabled === 0 ? 'blue' : 'gray'"
                                    :text="record.is_disabled === 0 ? '启用' : '禁用'" />

                            </template>

                            <template v-else-if="column.dataIndex === 'operation'">
                                <a-button type="link" @click="handlEdit(record)">编辑</a-button>
                                <a-button type="text" @click="handleDel(record,index)" danger>删除</a-button>
                            </template>
                        </template>
                    </a-table>
                </div>
            </div>
        </div>

        <CreateMember ref="createMember" @change="getData" />

        <ApplyAddMember ref="applyAddMember" @change="getData" />

        <FilterMember ref="filterMember" @change="getData" />

    </div>
</template>

<script lang="ts" setup name="history">

import { ref, reactive, computed, onMounted } from 'vue'
import baseService from '@/utils/http/axios'

import { Modal, message } from 'ant-design-vue';

import { PlusOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';

import SvgIcon from '@/components/SvgIcon/index.vue'

import CreateMember from './components/CreateMember.vue'
import ApplyAddMember from './components/ApplyAddMember.vue'
import FilterMember from './components/FilterMember.vue'

const keywords = ref('');

onMounted(() => {
    getData()
})

const columns = [
    {
        title: '成员ID',
        dataIndex: 'id',
        width: 150,
    },
    {
        title: '姓名',
        dataIndex: 'name',
        width: 200,
    },
    {
        title: '手机号',
        dataIndex: 'phone',
        width: 200,
    },
    {
        title: '部门',
        key: 'tags',
        dataIndex: 'departments',
    },
    {
        title: '职位',
        dataIndex: 'job',
        width: 200,
    },
    {
        title: '状态',
        dataIndex: 'is_disabled',
        width: 200,
    },
    {
        title: '操作',
        dataIndex: 'operation',
        align: 'center'
    },

];

const dataList = ref([])
const getData = () => {
    baseService.post('/api/company/memberList', {
        keyword: keywords.value
    }).then(res => {
        const data = res.data || {}
        dataList.value = data.list || []
    }).catch(err => {
        console.error(err)
    })
}
const handleDel = (e, index) => {
    console.log("log>>>>>", e, index)
    Modal.confirm({
        title: '删除提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '确定删除吗？删除后不可撤销',
        okText: '确定删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
            baseService.post('/api/company/memberDel', { id: e.id }).then(res => {
                dataList.value.splice(index, 1);
            }).catch(err => {
                console.error(err)
            })
        },
        onCancel() {
            console.log('Cancel');
        },
    });

}

const state = reactive<{
    selectedRowKeys: Key[];
    loading: boolean;
}>({
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
});
const hasSelected = computed(() => state.selectedRowKeys.length > 0);
const hasCompared = computed(() => state.selectedRowKeys.length >= 2);

const start = () => {
    state.loading = true;
    // ajax request after empty completing
    setTimeout(() => {
        state.loading = false;
        state.selectedRowKeys = [];
    }, 1000);
};
const onSelectChange = (selectedRowKeys: Key[]) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    state.selectedRowKeys = selectedRowKeys;
};

// 创建
const createMember = ref(null)
const handleCreate = () => {
    console.log(createMember.value)
    createMember.value.open({})
};

// 编辑
const handlEdit = (record) => {
    console.log(createMember.value)
    createMember.value.open(record)
};

// 扩增
const applyAddMember = ref(null)
const handleApply = () => {
    console.log(applyAddMember.value)
    applyAddMember.value.open()
};
// 筛选
const filterMember = ref(null)
const handleFilter = () => {
    console.log(filterMember.value)
    filterMember.value.open()
};
</script>

<style lang="less" scoped>
</style>
