<template>
  <a-modal v-model:open="show" :title="title"  :footer="null" width="50%">
      <div v-html="content"></div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      content: '',
      show: false
    }
  },
  
  methods: {
    open(type) {
      this.content = ''
      const typeTitle = type + '_title'
      const typeContent = type + '_content'
      const cacheTitle = this.$store.state.app.editorContent[typeTitle]
      const cacheContent = this.$store.state.app.editorContent[typeContent]
      this.show = true
      if (cacheContent) {
        this.title = cacheTitle
        this.content = cacheContent
        return
      }
      this.$store.state.app.editorContent = {}
      this.$network('/api/index/editor', { type: type }).then((res) => {
        const data = res.data || {}
        const title = data.title
        const content = data.content
        this.title = title
        this.content = content
        this.$store.state.app.editorContent = {}
        this.$store.state.app.editorContent[typeTitle] = title
        this.$store.state.app.editorContent[typeContent] = content
      })
    }
  }
}
</script>

<style>
</style>