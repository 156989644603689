<template>
  <div class="setting-params">
    <!-- <a-modal title="参数配置" width="90%" style="top: 20px" v-model:open="visible" :confirmLoading="loading" @ok="handleSubmit" :footer="null" @cancel="close"> -->
    <div style="font-size: 20px; font-weight: bold">策略参数</div>
    <div class="params-from" style="margin-top: 20px">
      <a-form ref="formAuth" :rules="rules" :model="form" :colon="false" :label-col="labelCol" labelAlign="left">
        <a-row>
          <a-col :span="24">
            <a-form-item label="客户" name="customer">
              <a-select placeholder="请选择" v-model:value="form.customer" :size="body_width < 1919 ? 'small' : 'middle'" allowClear show-search @change="handleSelectChange('customer')">
                <a-select-option v-for="(plan, index) in customer" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-item label="产品线" name="line">
              <a-select placeholder="请选择" v-model:value="form.line" :size="body_width < 1919 ? 'small' : 'middle'" allowClear show-search @change="handleSelectChange('line')">
                <a-select-option v-for="(plan, index) in line" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="产品类别" name="type">
              <a-select placeholder="请选择" v-model:value="form.type" :size="body_width < 1919 ? 'small' : 'middle'" allowClear show-search @change="handleSelectChange('type')">
                <a-select-option v-for="(plan, index) in type" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="产品编码" name="no">
              <a-select placeholder="请选择" v-model:value="form.no" :size="body_width < 1919 ? 'small' : 'middle'" allowClear show-search @change="handleSelectChange('group')">
                <a-select-option v-for="(plan, index) in no" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-item label="业务组" name="group">
              <a-select placeholder="请选择" v-model:value="form.group" :size="body_width < 1919 ? 'small' : 'middle'" allowClear show-search @change="handleSelectChange('group')">
                <a-select-option v-for="(plan, index) in group" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="区域" name="area">
              <a-select placeholder="请选择" v-model:value="form.area" :size="body_width < 1919 ? 'small' : 'middle'" allowClear show-search @change="handleSelectChange('area')">
                <a-select-option v-for="(plan, index) in area" :key="index" :value="plan">{{ plan }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8" style="padding-left: 20px; display: flex">
            <!-- <a-form-item label="&nbsp;"> -->
            <a-space>
              <a-button type="primary" :size="body_width < 1919 ? 'small' : 'middle'" @click="handleAdd">添加</a-button>
              <a-button :size="body_width < 1919 ? 'small' : 'middle'" @click="handleReset">重置</a-button>
            </a-space>
            <!-- </a-form-item> -->
          </a-col>
        </a-row>
        <!-- <a-row :gutter="10">
              <a-col :span="4">
                  <a-form-item label="金额"  >
                      <a-input type="number" v-model:value="params.money"/>
                  </a-form-item>
              </a-col>
              <a-col :span="4">
                  <a-form-item label="年利率(%)"   >
                      <a-input type="number" v-model:value="params.per"/>
                  </a-form-item>
              </a-col>
              <a-col :span="4">
                  <a-form-item label="天数"   >
                      <a-input type="number" v-model:value="params.day"/>
                  </a-form-item>
              </a-col>
              <a-col :span="4">
                  <a-form-item label="&nbsp;"    >
                      <a-button type="primary"  @click="handleCal">计算</a-button>
                  </a-form-item>
              </a-col>
          </a-row> -->
      </a-form>
    </div>

    <!-- <a-button type="primary"  @click="handleCal">计算</a-button> -->
    <div v-if="isOpenDrader" style="margin-top: 10px">
      <a-tabs v-if="tableData.length > 0" v-model:activeKey="activeKey" type="editable-card" :hideAdd="true" @edit="onEdit" @change="changeTab(activeKey)" @tabClick="changeTab(activeKey)">
        <a-tab-pane v-for="(item, index) in tableData" :key="index + 1" :tab="'策略' + (index + 1)" :closable="true">
          <div style="font-weight: bold">内容：{{ currentTabData[0].groupName }}</div>

          <div class="drader-descriptions" style="margin-top: 10px">
            <a-descriptions :column="2" size="small" bordered :labelStyle="{ width: '130px', display: 'flex', alignItems: 'center' }">
              <a-descriptions-item label="含税价格(%)">
                <div class="descriptions-span">
                  <SliderInputBlock v-model:value="currentTabData[0].price_per" :min="-100" :max="100" @change="handleChangeData" />
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="销量(%)">
                <div class="descriptions-span">
                  <SliderInputBlock v-model:value="currentTabData[0].sold_num_per" :min="-100" :max="100" @change="handleChangeData" />
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="单位变动成本(%)">
                <div class="descriptions-span">
                  <SliderInputBlock v-model:value="currentTabData[0].material_cost_per" :min="-100" :max="100" @change="handleChangeData" />
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="合格率(%)">
                <div class="descriptions-span">
                  <SliderInputBlock v-model:value="currentTabData[0].pass_per" :min="-100" :max="100" @change="handleChangeData" />
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>

          <a-table :pagination="false" :columns="currentColumns" :data-source="currentTabData">
            <template #bodyCell="{ column, text, record, index }">
              <template v-if="column.dataIndex === 'operate'">
                <a-button size="small" danger @click="handleDel(index)">删除</a-button>
              </template>
              <template v-if="column.dataIndex === 'cost_money'">
                <a-input-number v-model:value="record.cost_money" style="width: 100%" @change="handleChangeData" />
              </template>
              <template v-if="column.dataIndex === 'cost_year_per'">
                <SliderInputBlock v-model:value="record.cost_year_per" :min="0" :max="100" @change="handleChangeData" />
              </template>
              <template v-if="column.dataIndex === 'cost_day'">
                <SliderInputBlock v-model:value="record.cost_day" :min="-365" :max="365" @change="handleChangeData" />
              </template>
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>

      <div v-show="tableData.length > 0" style="margin-top: 20px">
        <a-table :pagination="false" :columns="allOptionColumns" :data-source="transposedData" :scroll="{ x: true }">
          <template #headerCell="{ column, title }">
            <template v-if="column.key !== 'name'">
              <a-popover :title="title" placement="bottomLeft">
                <template #content>
                  <!-- <div v-for="(item, index) in transposedData" :key="index">
                    {{ item.name }}:<br /><span>{{ item[`col${index + 1}`] }}</span>
                  </div> -->
                  <div>客户：{{ column.group.customer }}</div>
                  <div>产品线：{{ column.group.line }}</div>
                  <div>产品类别：{{ column.group.type }}</div>
                  <div>产品编码：{{ column.group.no }}</div>
                  <div>业务组：{{ column.group.group }}</div>
                  <div>区域：{{ column.group.area }}</div>
                </template>
                <span style="color: #1890ff">{{ title }}</span>
              </a-popover>
            </template>
          </template>
          <template #bodyCell="{ column, text, record, index }">
            <template v-if="column.dataIndex !== 'name'">
              {{ $dealThousands(text) }}
            </template>
          </template>
        </a-table>
        <!-- 
        <div style="font-weight: bold; margin: 10px 0">当前方案：{{ currentTabData[0].groupName }}</div> -->

        <a-row :gutter="10" style="margin-top: 10px">
          <a-col :span="12">
            <div class="result-descriptions">
              <a-descriptions :column="1" title="策略组合" size="small" bordered :labelStyle="{ width: '90px', display: 'flex', alignItems: 'center' }">
                <a-descriptions-item label="总边际收益">
                  <div class="descriptions-span">
                    {{ $dealThousands(totalSideMoney) }}
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="总管理利润">
                  <div class="descriptions-span">
                    {{ $dealThousands(managerRate) }}
                  </div>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="result-descriptions">
              <a-descriptions :column="1" title="结果" size="small" bordered :labelStyle="{ width: '80px', display: 'flex', alignItems: 'center' }">
                <a-descriptions-item label="最终收益">
                  <div class="descriptions-span">
                    {{ $dealThousands(getMoney) }}
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="最终利润">
                  <div class="descriptions-span">
                    {{ $dealThousands(rateMoney) }}
                  </div>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div v-else>
      <a-table :pagination="false" :columns="columns" :data-source="tableData">
        <template #bodyCell="{ column, text, record, index }">
          <template v-if="column.dataIndex === 'type'">
            {{ groupType[record.type] }}
          </template>
          <template v-if="column.dataIndex === 'operate'">
            <a-button danger @click="handleDel(index)">删除</a-button>
          </template>
          <template v-if="column.dataIndex === 'price_per'">
            <SliderInputBlock v-model:value="record.price_per" :min="-100" :max="100" @change="handleChangeData" />
          </template>
          <template v-if="column.dataIndex === 'sold_num_per'">
            <SliderInputBlock v-model:value="record.sold_num_per" :min="-100" :max="100" @change="handleChangeData" />
          </template>
          <template v-if="column.dataIndex === 'material_cost_per'">
            <SliderInputBlock v-model:value="record.material_cost_per" :min="-100" :max="100" @change="handleChangeData" />
          </template>
          <template v-if="column.dataIndex === 'pass_per'">
            <SliderInputBlock v-model:value="record.pass_per" :min="-100" :max="100" @change="handleChangeData" />
          </template>
          <template v-if="column.dataIndex === 'cost_money'">
            <a-input-number v-model:value="record.cost_money" style="width: 100%" @change="handleChangeData" />
          </template>
          <template v-if="column.dataIndex === 'cost_year_per'">
            <SliderInputBlock v-model:value="record.cost_year_per" :min="0" :max="100" @change="handleChangeData" />
          </template>
          <template v-if="column.dataIndex === 'cost_day'">
            <SliderInputBlock v-model:value="record.cost_day" :min="-365" :max="365" @change="handleChangeData" />
          </template>
        </template>
      </a-table>

      <div class="solution">
        <div style="overflow-x: scroll; display: flex; white-space: nowrap; margin-top: 4px">
          <div v-for="(item, index) in tableData" :key="index" style="margin-right: 10px; width: 400px">
            <a-descriptions :title="item.groupName" :column="1" size="small" bordered :labelStyle="getTransferWidth">
              <a-descriptions-item label="变动前不含税销售收入">
                <div class="descriptions-span">
                  {{ $dealThousands(item.price_come_in) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动后不含税销售收入">
                <div class="descriptions-span">
                  {{ $dealThousands(item.price_come_in_after) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动前含税销售收入">
                <div class="descriptions-span">
                  {{ $dealThousands(item.sold_come_in) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动后含税销售收入">
                <div class="descriptions-span">
                  {{ $dealThousands(item.sold_come_in_after) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动前边际收益">
                <div class="descriptions-span">
                  {{ $dealThousands(item.side_money) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动后边际收益">
                <div class="descriptions-span">
                  {{ $dealThousands(item.side_money_after) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="边际收益差异">
                <div class="descriptions-span">
                  {{ $dealThousands(item.side_money_diff) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="机会成本">
                <div class="descriptions-span">
                  {{ $dealThousands(item.calCostMoney) }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="该次策略组合收益">
                <div class="descriptions-span">
                  {{ $dealThousands(item.group_money) }}
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </div>

        <a-row :gutter="10">
          <a-col :span="12">
            <div>
              <a-descriptions :column="1" title="策略组合" size="small" bordered :labelStyle="getTransferWidth">
                <a-descriptions-item label="总边际收益">
                  <div class="descriptions-span">
                    {{ $dealThousands(totalSideMoney) }}
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="总管理利润">
                  <div class="descriptions-span">
                    {{ $dealThousands(managerRate) }}
                  </div>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              <a-descriptions :column="1" title="结果" size="small" bordered :labelStyle="getTransferWidth">
                <a-descriptions-item label="最终收益">
                  <div class="descriptions-span">
                    {{ $dealThousands(getMoney) }}
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="最终利润">
                  <div class="descriptions-span">
                    {{ $dealThousands(rateMoney) }}
                  </div>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
  <!-- </a-modal> -->
</template>

<script>
import { message, Modal } from 'ant-design-vue'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import calMixIn from '../calMixIn.vue'
import { center } from '@antv/g2plot/lib/plots/sankey/sankey'
export default {
  name: 'SettingParams',
  props: {
    id: {
      type: [String, Number],
      default: 0
    },
    title: {
      type: String,
      default: '方案名称'
    },
    isOpenDrader: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SliderInputBlock,
    Modal
  },
  mixins: [calMixIn],
  emits: ['paramsChange'],
  data() {
    return {
      body_width: document.body.offsetWidth,
      initDataState: false,
      visible: false,
      loading: false,
      form: {
        line: null,
        type: null,
        customer: null,
        area: null,
        group: null,
        no: null
      },
      params: {
        money: 0,
        per: 0,
        day: 0
      },
      limitTableItem: 5, //限制添加行数
      totalSideMoney: 0,
      managerRate: 0,
      getMoney: 0,
      rateMoney: 0,
      callback: null,
      rules: {},
      groupType: { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组', no: '产品编码' },

      line: [],
      type: [],
      customer: [],
      area: [],
      group: [],
      no: [],
      groupNoData: [],

      columns: [
        // { title: '类型', dataIndex: 'type' ,width:100 },
        { title: '内容', dataIndex: 'groupName', minWidth: 200, align: 'center' },
        { title: '含税价格(%)', dataIndex: 'price_per', width: 200, align: 'left' },
        { title: '销量(%)', dataIndex: 'sold_num_per', width: 200 },
        { title: '单位变动成本(%)', dataIndex: 'material_cost_per', width: 200 },
        { title: '合格率(%)', dataIndex: 'pass_per', width: 200 },
        { title: '资金成本', dataIndex: 'cost_money', width: 200 },
        { title: '年利率(%)', dataIndex: 'cost_year_per', width: 200 },
        { title: '天数', dataIndex: 'cost_day', width: 200 },
        { title: '操作', dataIndex: 'operate', width: 80 }
      ],
      currentColumns: [
        { title: '资金成本', dataIndex: 'cost_money', width: 80 },
        { title: '年利率(%)', dataIndex: 'cost_year_per', width: 150 },
        { title: '天数', dataIndex: 'cost_day', width: 150 },
        { title: '操作', dataIndex: 'operate', width: 50 }
      ],

      tableData: [],
      currentTabData: [
        {
          groupName: '',
          price_per: '',
          sold_num_per: '',
          material_cost_per: '',
          pass_per: ''
        }
      ],

      calResultTimer: 0,
      labelCol: { style: { width: '65px' } },
      activeKey: 1,

      allOptionParams: [
        {
          key: 'price_come_in',
          name: '原销售收入'
        },
        {
          key: 'price_come_in_after',
          name: '现销售收入'
        },
        {
          key: 'sold_come_in',
          name: '原销售额'
        },
        {
          key: 'sold_come_in_after',
          name: '现销售额'
        },
        {
          key: 'side_money',
          name: '原边际收益'
        },
        {
          key: 'side_money_after',
          name: '现边际收益'
        },
        {
          key: 'calCostMoney',
          name: '机会成本'
        },
        {
          key: 'side_money_diff',
          name: '边际收益差异'
        },
        {
          key: 'group_money',
          name: '该次策略收益'
        }
      ],
      allOptionColumns: [],
      transposedData: []
    }
  },

  computed: {
    excelData_ycChange() {
      const data = this.$store.state.tools.excelData_ycOrigin || []
      return data
    },
    tableData_step_fixed() {
      return this.$store.state.tools.tableData_step_fixed
    },
    tableData_step_mix() {
      return this.$store.state.tools.tableData_step_mix
    },
    columns_step_mix() {
      return this.$store.state.tools.columns_step_mix
    },
    getTransferWidth() {
      if (document.body.clientWidth < 1441) {
        return { width: '118px', minWidth: '118px', fontSize: '9px', textAlign: 'left', padding: 1 }
      } else if (document.body.clientWidth > 1441 && document.body.clientWidth < 1919) {
        return { width: '160px', minWidth: '160px', fontSize: '12px', textAlign: 'left', padding: 1 }
      } else {
        return { width: '100px', minWidth: '100px', fontSize: '12px' }
      }
    }
  },
  watch: {
    excelData_ycChange: {
      immediate: true, //首次加载的时候执行函数
      handler(value) {
        this.handleSelectChange()
        this.handleCal()
        // if(this.initDataState){
        //   this.initDataState = false;
        //   this.handleCal()
        // }
      }
    }
    // tableData:{
    //     deep:true,
    //     handler(value){
    //         clearTimeout(this.calResultTimer)
    //         this.calResultTimer = setTimeout(()=>{
    //             console.log('>>>>>>>>>>>> watch tableData handler')
    //             this.handleCal()
    //         },200)
    //     }
    // }
  },
  mounted() {
    // console.log('-------------------------transposedData', this.transposedData)
    // this.getTransposedData()
    // this.getAllOptionColumns()
  },
  methods: {
    // 转置数据
    getTransposedData() {
      if (!this.tableData.length) {
        this.transposedData = [] // 如果没有数据，则清空 transposedData
        return
      }

      const keys = Object.keys(this.tableData[0])
      this.transposedData = keys
        .filter((key) => this.allOptionParams.some((option) => option.key === key))
        .map((key) => {
          const option = this.allOptionParams.find((option) => option.key === key)
          const name = option ? option.name : ''

          const rowData = this.tableData.reduce((acc, cur, index) => {
            acc[`col${index + 1}`] = cur[key]
            return acc
          }, {})

          return {
            key,
            name,
            ...rowData
          }
        })
    },
    // 定义列
    getAllOptionColumns() {
      if (!this.tableData.length) return []

      // 生成所有方案的列配置
      const columns = this.tableData.map((_, index) => ({
        group: _.group,
        title: `策略${index + 1}`,
        dataIndex: `col${index + 1}`,
        key: `col${index + 1}`,
        width: this.body_width < 1441 ? 70 : this.body_width > 1441 && this.body_width < 1919 ? 90 : 120,
        ellipsis: true,
        align: 'right'
      }))

      // 在列配置数组的最前面添加一个用于显示名称的列
      columns.unshift({
        title: '',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        ellipsis: true
      })

      // return columns
      this.allOptionColumns = columns
    },
    changeTab(key) {
      this.currentTabData = []
      this.currentTabData.push(this.tableData[key - 1])
    },
    setParams(tableData) {
      this.tableData = tableData || []
      // console.log('--setParams', tableData)
      //计算数据
      // if (this.tableData.length) {
      //   this.initDataState = !this.excelData_ycChange.length;
      //   if(!this.initDataState){
      //     this.handleChangeData()
      //   }
      // }
    },
    handleReset() {
      this.$refs.formAuth.resetFields()
      this.$nextTick(() => {
        this.handleSelectChange()
      })
    },
    handleSelectChange() {
      Object.keys(this.groupType).map((typeKey) => {
        this[typeKey] = []
      })

      this.excelData_ycChange.map((item) => {
        const state = Object.keys(this.groupType).every((typeKey) => {
          return item[typeKey] && (!this.form[typeKey] || item[typeKey] == this.form[typeKey])
        })
        Object.keys(this.groupType).map((typeKey) => {
          if (item[typeKey] && this[typeKey].indexOf(item[typeKey]) === -1) {
            if (state) {
              this[typeKey].push(item[typeKey])
            }
          }
        })
      })
    },
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleAdd() {
      if (this.tableData.length >= this.limitTableItem) {
        return message.error(`至多不得超过${this.limitTableItem}组合`)
      }
      const group = {}
      if (this.form.line) group.line = this.form.line
      if (this.form.type) group.type = this.form.type
      if (this.form.customer) group.customer = this.form.customer
      if (this.form.area) group.area = this.form.area
      if (this.form.group) group.group = this.form.group
      if (this.form.no) group.no = this.form.no
      if (!Object.keys(group).length) {
        return message.error('请选择项')
      }
      this.tableData.push({
        group: group,
        groupName: Object.values(group).join(' '),
        price_per: 0,
        sold_num_per: 0,
        pass_per: 0,
        cost_money: 0,
        cost_year_per: 0,
        cost_day: 0,
        material_cost_per: 0,
        //不含税销售收入
        price_come_in: 0,
        //后不含税销售收入
        price_come_in_after: 0,
        //前含税销售收入
        sold_come_in: 0,
        //后含税销售收入
        sold_come_in_after: 0,
        //边际贡献
        side_money: 0,
        //加成后边际贡献
        side_money_after: 0,
        //该次策略组合收益
        group_money: 0,
        //总边际收益
        totalSideMoney: 0,
        //机会成本
        calCostMoney: 0,
        //边际差异
        side_money_diff: 0
      })

      //计算数据
      this.handleChangeData()
      this.activeKey = this.tableData.length
      this.changeTab(this.tableData.length)
      // this.getTransposedData()
      // this.getAllOptionColumns()
      // console.log('------------------------transposedData', this.transposedData)
      // console.log('------------------------allOptionColumns', this.allOptionColumns)
    },
    handleDel(index) {
      Modal.confirm({
        title: '提示',
        content: `真的要删除方案${index + 1}吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.tableData.splice(index, 1)
          //计算数据
          this.handleChangeData()
          this.activeKey = this.tableData.length
          this.changeTab(this.tableData.length)
        },
        onCancel() {}
      })
    },
    onEdit(index) {
      this.handleDel(index - 1)
    },
    handleSubmit() {},
    //组合数据-可能存在重复的数据产品编码问题
    handleCalBefore() {
      const excelData_ycChange = JSON.parse(JSON.stringify(this.excelData_ycChange))
      this.tableData.map((tableItem, tableIndex) => {
        excelData_ycChange.map((originItem) => {
          const dataItem = JSON.parse(JSON.stringify(originItem))
          const isEq = Object.keys(tableItem.group).every((groupKey) => {
            return dataItem[groupKey] === tableItem.group[groupKey]
          })
          if (isEq) {
            if (!('settingParam' in originItem)) {
              originItem.settingParam = {
                price_per: 0,
                sold_num_per: 0,
                material_cost_per: 0,
                pass_per: 0
              }
            }
            originItem.settingParam.price_per += tableItem.price_per * 1
            originItem.settingParam.sold_num_per += tableItem.sold_num_per * 1
            originItem.settingParam.material_cost_per += tableItem.material_cost_per * 1
            originItem.settingParam.pass_per += tableItem.pass_per * 1
          }
        })
      })
      return excelData_ycChange
    },

    getHandleCalOriginDataItems(tableItem) {
      const excelData_ycChange = JSON.parse(JSON.stringify(this.excelData_ycChange))
      excelData_ycChange.map((originItem) => {
        const dataItem = JSON.parse(JSON.stringify(originItem))
        const isEq = Object.keys(tableItem.group).every((groupKey) => {
          return dataItem[groupKey] === tableItem.group[groupKey]
        })
        if (isEq) {
          if (!('settingParam' in originItem)) {
            originItem.settingParam = {
              price_per: 0,
              sold_num_per: 0,
              material_cost_per: 0,
              pass_per: 0
            }
          }
          originItem.settingParam.price_per += tableItem.price_per * 1
          originItem.settingParam.sold_num_per += tableItem.sold_num_per * 1
          originItem.settingParam.material_cost_per += tableItem.material_cost_per * 1
          originItem.settingParam.pass_per += tableItem.pass_per * 1
        }
      })
      return excelData_ycChange
    },
    handleTableItemData(excelData_ycChange) {
      //初始化数据
      this.tableData.map((_tableItem) => {
        _tableItem.price_come_in = 0
        _tableItem.price_come_in_after = 0
        _tableItem.sold_come_in = 0
        _tableItem.sold_come_in_after = 0
        _tableItem.side_money = 0
        _tableItem.side_money_after = 0
        //机会成本
        _tableItem.calCostMoney = 0
        _tableItem.side_money_diff = 0
      })

      //策略组合总边际收益
      console.log('--------------------------------')
      this.tableData.map((tableItem, tableIndex) => {
        // console.log('++++++++',tableItem)
        const excelData_ycChange = this.getHandleCalOriginDataItems(tableItem)
        excelData_ycChange.map((originItem) => {
          //总变动成本
          originItem.material_cost_money = originItem.material_cost * originItem.pass_sold_num
          originItem.slid_money = originItem.price_come_in - originItem.material_cost_money

          const dataItem = JSON.parse(JSON.stringify(originItem))
          let side_money = dataItem.slid_money * 1

          if ('settingParam' in originItem) {
            console.log('++++++++', dataItem.price_come_in)
            // console.log('----settingParam:side_money',originItem,side_money)
            //前不含税销售收入
            tableItem.price_come_in += dataItem.price_come_in * 1
            //前含税销售收入
            tableItem.sold_come_in += dataItem.sold_come_in * 1
            //边际贡献
            tableItem.side_money += side_money
            //计算加成后
            //含税价格
            const price_per = tableItem.price_per * 1
            dataItem.price_add_tax = dataItem.price_add_tax * (1 + price_per / 100)
            //不含税价格
            if (price_per) {
              dataItem.price = dataItem.price_add_tax / (1 + dataItem.tax_per / 100)
            }
            //销量
            const sold_num_per = tableItem.sold_num_per * 1
            dataItem.sold_num = dataItem.sold_num * (1 + sold_num_per / 100)

            //后含税销售收入
            tableItem.sold_come_in_after += dataItem.price_add_tax * dataItem.sold_num
            //后不含税销售收入
            tableItem.price_come_in_after += dataItem.price * dataItem.sold_num

            //合格率
            const pass_per = tableItem.pass_per * 1
            dataItem.pass_per = dataItem.pass_per * (1 + pass_per / 100)
            //产量
            dataItem.pass_sold_num = !dataItem.pass_per ? 0 : dataItem.sold_num / (dataItem.pass_per / 100)

            //单位变动成本
            const material_cost_per = tableItem.material_cost_per * 1
            dataItem.material_cost = dataItem.material_cost * (1 + material_cost_per / 100)

            //销售收入
            dataItem.price_come_in = dataItem.price * dataItem.sold_num
            //总变动成本
            dataItem.material_cost_money = dataItem.material_cost * dataItem.pass_sold_num

            //边际贡献 = 销售收入 - 总变动成本
            side_money = dataItem.price_come_in - dataItem.material_cost_money
            tableItem.side_money_after += side_money

            tableItem.side_money_diff = tableItem.side_money_after - tableItem.side_money
          }
        })
        //机会成本
        const cost_money = (tableItem.cost_money || 0) * 1
        const cost_year_per = (tableItem.cost_year_per || 0) * 1
        const cost_day = (tableItem.cost_day || 0) * 1
        const calCostMoney = (cost_money * (cost_day / 365) * cost_year_per) / 100
        tableItem.calCostMoney = calCostMoney

        // 边际收益-机会成本=该次策略组合收益
        const groupMoney = tableItem.side_money_after - calCostMoney
        tableItem.group_money = groupMoney
      })
    },
    handleGroupData(excelData_ycChange) {
      //策略组合总边际收益
      let totalSideMoney = 0
      let totalGroupMoney = 0
      let totalCalCostMoney = 0
      let totalSideMoneyAfter = 0
      let totalSideMoneyOrigin = 0

      excelData_ycChange.map((dataItem) => {
        // const dataItem = JSON.parse(JSON.stringify(originItem))
        //总变动成本
        dataItem.material_cost_money = dataItem.material_cost * dataItem.pass_sold_num
        dataItem.side_money = dataItem.price_come_in - dataItem.material_cost_money
        //总变动成本
        let side_money = dataItem.side_money || 0 * 1
        if ('settingParam' in dataItem) {
          const price_per = dataItem.settingParam.price_per || 0
          const sold_num_per = dataItem.settingParam.sold_num_per || 0
          const material_cost_per = dataItem.settingParam.material_cost_per || 0
          const pass_per = dataItem.settingParam.pass_per || 0
          // console.log('----settingParam:side_money',dataItem,side_money,sold_num_per)
          //计算加成后
          //含税价格
          dataItem.price_add_tax = dataItem.price_add_tax * (1 + price_per / 100)
          //不含税价格
          if (price_per) {
            dataItem.price = dataItem.price_add_tax / (1 + dataItem.tax_per / 100)
          }
          //销量
          dataItem.sold_num = dataItem.sold_num * (1 + sold_num_per / 100)
          //合格率
          dataItem.pass_per = dataItem.pass_per * (1 + pass_per / 100)
          //产量
          dataItem.pass_sold_num = !dataItem.pass_per ? 0 : dataItem.sold_num / (dataItem.pass_per / 100)

          //单位变动成本
          dataItem.material_cost = dataItem.material_cost * (1 + material_cost_per / 100)

          //销售收入
          dataItem.price_come_in = dataItem.price * dataItem.sold_num
          //总变动成本
          dataItem.material_cost_money = dataItem.material_cost * dataItem.pass_sold_num

          // console.log('----settingParam:side_money', dataItem, dataItem.price, dataItem.sold_num, dataItem.price_come_in, dataItem.material_cost_money)
          //边际贡献 = 销售收入 - 总变动成本
          side_money = dataItem.price_come_in - dataItem.material_cost_money
          totalSideMoneyAfter += side_money
        } else {
          totalSideMoneyOrigin += side_money
        }
        totalSideMoney += side_money
      })

      this.tableData.map((tableItem) => {
        totalGroupMoney += tableItem.group_money * 1
        totalCalCostMoney += tableItem.calCostMoney * 1
      })
      // console.log(totalSideMoney)
      // console.log(totalGroupMoney)
      // console.log(totalSideMoneyOrigin)
      // console.log(totalGroupMoney + totalSideMoneyOrigin)
      // console.log(totalCalCostMoney)
      if (!this.tableData.length) {
        this.totalSideMoney = 0
        this.managerRate = 0
        this.getMoney = 0
        this.rateMoney = 0
      } else {
        //计算收益
        const calData = this.handleData(excelData_ycChange, this.tableData_step_fixed, this.tableData_step_mix, this.columns_step_mix)
        //策略组合总边际收益  （A客户，B大类）的边际收益+（C客户，D区域）的边际收益+其他不在此范围内的原方案加成后的边际收益=策略组合总边际收益
        //总边界收益
        this.totalSideMoney = totalSideMoney
        //策略组合总边际收益-固定成本-混合成本=策略组合总管理利润
        this.managerRate = totalSideMoney - calData.sum_fixed_total_money - calData.sum_mix_total_money

        //最终收益 =（A客户，B大类）的边际收益-机会成本+（C客户，D区域）的边际收益-机会成本+其他不在此范围内的原方案加成后的边际收益
        const getMoney = totalSideMoney - totalCalCostMoney
        this.getMoney = getMoney
        //最终利润 = 最终收益-固定成本-混合成本
        this.rateMoney = getMoney - calData.sum_fixed_total_money - calData.sum_mix_total_money
      }
    },
    handleChangeData() {
      clearTimeout(this.calResultTimer)
      this.calResultTimer = setTimeout(() => {
        this.$emit('paramsChange', this.tableData)
        this.handleCal()
        this.getTransposedData()
        this.getAllOptionColumns()
      }, 200)
    },
    handleCal() {
      //处理加成前的数据
      const excelData_ycChange = this.handleCalBefore()
      // console.log('--------handleCal----------')
      // console.log(excelData_ycChange)
      //处理单项数据
      this.handleTableItemData(excelData_ycChange)

      //处理策略组合数据
      this.handleGroupData(excelData_ycChange)
    }
  }
}
</script>

<style scoped>
</style>
