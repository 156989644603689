<template>
    <a-drawer title="添加成员" :width="400" :open="visible" :body-style="{ paddingBottom: '80px' }"
        :footer-style="{ textAlign: 'right' }" @close="close">
        <a-form layout="vertical" r>

            <a-form-item label="姓名" name="name">
                <a-input v-model:value="form.name" placeholder="请输入姓名" />
            </a-form-item>
            <a-form-item label="手机号" name="phone">
                <a-input v-model:value="form.phone" addon-before="+86" placeholder="请输入部门名称" />
            </a-form-item>
            <a-form-item label="密码" name="password">
                <a-input v-model:value="form.password" type="password" placeholder="请输入密码" />
            </a-form-item>
            <a-form-item label="部门" name="name">
                <a-select v-model:value="form.company_department_id" mode="tags" :options="list"
                    :field-names="{ label: 'name', value: 'id' }" style="width: 100%" placeholder="选择部门"
                    @change="handleChange">
                    <!-- <a-select-option value="caiwu">财务部</a-select-option>
                    <a-select-option value="shichang">市场部</a-select-option> -->
                </a-select>
            </a-form-item>
            <a-form-item label="职位" name="name">
                <a-input v-model:value="form.job" placeholder="请输入部门名称" />
            </a-form-item>
            <a-form-item label="状态" name="name">
                <a-select ref="select" v-model:value="form.is_disabled" style="width: 100%" placeholder="选择状态">
                    <a-select-option :value="0" label="启用">启用</a-select-option>
                    <a-select-option :value="1" label="禁用">禁用</a-select-option>
                </a-select>
            </a-form-item>
            <!-- <a-form-item label="权限" name="name">
                <a-select v-model:value="form.tools" mode="tags" style="width: 100%" placeholder="选择权限">
                    <a-select-option value="tool1">工具名称</a-select-option>
                    <a-select-option value="tool2">工具名称</a-select-option>
                    <a-select-option value="tool3">工具名称</a-select-option>
                    <a-select-option value="tool4">工具名称</a-select-option>
                    <a-select-option value="tool5">工具名称</a-select-option>
                    <a-select-option value="tool6">工具名称</a-select-option>
                </a-select>
            </a-form-item> -->
        </a-form>
        <template #footer>
            <a-space>
                <a-button @click="close">取消</a-button>
                <a-button type="primary" @click="handleSubmit">保存</a-button>
            </a-space>
        </template>
    </a-drawer>
</template>

<script lang="ts" setup>
import { ref, reactive, computed, onMounted } from 'vue'
import baseService from '@/utils/http/axios'

import { message } from 'ant-design-vue';

const emit = defineEmits(['change'])

onMounted(() => {
    getData()
})

const tempData = {
    id: '',
    name: '',
    phone: '',
    job: '',
    password: '',
    tools: [],
    is_disabled: 0,
    company_department_id: [],
};
const form = reactive(tempData);

const visible = ref(false)

const open = (record) => {
    visible.value = true
    Object.keys(tempData).forEach(key => {
        form[key] = record[key]
        console.log('>>>>>>>>>>>>>>>>', key)
    });
}

const close = () => {
    visible.value = false
}

defineExpose({
    open
})

const list = ref([])
const getData = () => {
    baseService.post('/api/company/departmentList').then(res => {
        const data = res.data || {}
        list.value = data.list || []
    }).catch(err => {
        console.error(err)
    })
}
const handleChange = (value: string) => {
    console.log(`selected ${value}`);
}
const handleSubmit = () => {
    baseService.post('/api/company/memberAdd', form).then(res => {
        message.success(res.msg)
        setTimeout(() => {
            emit('change')

            close()
        }, 700);

    }).catch(err => {
        console.error(err)
    })
}

</script>
<style lang="less" scoped>
.ant-form-text {
    font-size: 14px;
    font-weight: bold;
}
</style>
