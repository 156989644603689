<template>
      
  <div id="SolutionTemplatePDFCompare" class="solutionTemplatePDF">
    <a-watermark :content="getWaterName">      
      <div class="solution-box">
        <div class="headBox">
          <div class="headBoxBg">
            <img src="@/assets/images/solution_img.png" />
            <div class="headBoxLogo"><img :src="getPlanLogo" class="logo" alt="logo" /></div>
          </div>
          <div class="headBoxContent">
            <div class="headTitle">
              <div class="title">本量利（基于BOM）方案对比</div>
            </div>
            <div class="author">
              <div class="author_name">{{ getTitle }}</div>
              <div class="author_info"></div>
            </div>
          </div>
          <div class="headTypeText">
            <VipTag :isText="true" moduleName="printPDF"/>
            <span>对比方案</span>
          </div>
        </div>

        <div class="solution-wrap">
          <div class="solution-first">
            <div class="solution-children">
              <div class="title"><span>工具指引</span></div>
              <div class="row">
                <div class="RichText">
                  母件 BOM 成本=∑《02.01.BOM
                  信息表》对应的非采购件子件的“子件耗量（含损耗）”×下一层级子件成本+∑采购件子件的“子件耗量（含损耗）”×《03.01.材料价格表》对应的“单位采购成本（元）”根据《01.01.销售预测表（通用）》的“产品编码
                  ID”字段（不去重复），对应的不含税售价×对应的销量，得到该行产品的销售收入。
                </div>
              </div>
            </div>   
            <!-- 基础方案 -->
            <div class="solution-children">
              <div class="title"><span>基础方案对比</span></div>
              <a-row :gutter="200">
                <a-col :span="12">
                  <div class="row">
                    <img class="canvas" :src="svpLineBeforeImg"/>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="row">
                    <img  class="canvas" :src="svpLineAfterImg"/>
                  </div>
                </a-col>
              </a-row> 
            </div>

            <!-- 拓展方案 -->
            <div class="solution-children">
              <div class="title"><span>拓展方案对比</span></div>
              <a-row :gutter="200">
                <a-col :span="12">
                  <div class="row">
                    <img class="canvas" :src="svpCatterBeforeImg"/>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="row">
                    <img  class="canvas" :src="svpCatterAfterImg"/>
                  </div>
                </a-col>
              </a-row> 
            </div>

            <!-- 备注 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="24">
                  <div class="title"><span>编制备注</span></div>
                  <div class="row">
                    <div class="RichText">
                      {{remark}}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
          <!-- 基础方案数据 -->
          <div class="solution-children">
            <div class="title"><span>基础方案对比数据明细</span></div>
            <div class="row" id="baseTable">
              
            </div>
          </div>
          <!-- 拓展方案数据 -->
          <div class="solution-children">
            <div class="title"><span>拓展方案对比数据明细</span></div>
            <div class="row">
              <a-row :gutter="80">
                <a-col :span="12" id="scatterTableDataBefore">
                </a-col>
                <a-col :span="12" id="scatterTableDataAfter">
                </a-col>
              </a-row>
            </div>
          </div>

          <!-- 拓展方案数据 -->
          <div class="solution-children">
            <div class="title"><span>拓展方案对比差异明细</span></div>
            <div class="row" id="scatterTableCompareDiff">
              
            </div>
          </div>

        </div>
      </div>
    </a-watermark>    
  </div>
  
</template>

<script>
import VipTag from '@/components/VipTag.vue'
import SliderInputBlock from '@/components/SliderInputBlock.vue'

import { message } from 'ant-design-vue'
import { htmlToPDF } from '@/utils/html2pdf'
import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'
//打印模板
export default {
  name: 'SolutionTemplatePDFCompare',
  components: {
    VipTag,SliderInputBlock,
  },
  props: {
    type:{
      type: String,
      default:"",
    },
    detail:{
      type: Object,
      default:function(){
        return {}
      }
    },
    detailCompare:{
      type: Object,
      default:function(){
        return {}
      }
    },
    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    },
    remark: {
      type: String,
      default: ''
    },
    
    planResult_extra: {
      type: String,
      default: ''
    },
  },
	data() {
    return { 
      svpCatterBeforeImg:'',
      svpCatterAfterImg:'',
      svpLineBeforeImg:'',
      svpLineAfterImg:'',
    }
  },
  computed: {
    getWaterName() {
      return this.detail.water_name || ''
    },
    getTitle() {
      return this.detail.name + '（基准） VS ' + this.detailCompare.name
    },

    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    }
  },
  created() {
   
  },
  mounted() {
    
   
  },

 
  methods: {
    
    async downPDF(pageObj) {
      await this.svpLineBefore(pageObj)
      await this.svpLineAfter(pageObj)
      await this.svpCatterBefore(pageObj)
      await this.svpCatterAfter(pageObj)
      await this.baseTable(pageObj)
      await this.scatterTableData(pageObj)
      await this.scatterTableCompareDiff(pageObj)
    },

    //保存图-折线图加成前
    async svpLineBefore(pageObj){
      this.svpLineBeforeImg =  pageObj.$refs.svpLineBefore.generateImage();
    },
    //保存图-折线图加成后
    async svpLineAfter(pageObj){
      this.svpLineAfterImg =  pageObj.$refs.svpLineAfter.generateImage();
    },

    //保存图-折线图加成前
    async svpCatterBefore(pageObj){
      this.svpCatterBeforeImg =  pageObj.$refs.svpCatterBefore.generateImage();
    },
    //保存图-折线图加成后
    async svpCatterAfter(pageObj){
      this.svpCatterAfterImg =  pageObj.$refs.svpCatterAfter.generateImage();
    },
    //基础数据
    async baseTable(pageObj){
      document.getElementById("baseTable").appendChild(pageObj.$refs.baseTable.$el)
    },
    //对比表格数据
    async scatterTableData(pageObj){
      document.getElementById("scatterTableDataBefore").appendChild(pageObj.$refs.svpCatterBefore.$refs.tableScatter)
      document.getElementById("scatterTableDataAfter").appendChild(pageObj.$refs.svpCatterAfter.$refs.tableScatter)
    },
    //对比表格数据
    async scatterTableCompareDiff(pageObj){
      
      document.getElementById("scatterTableCompareDiff").appendChild(pageObj.$refs.scatterTableCompareDiff)
    },
    
  }
}
</script>


<style lang="less" >
#SolutionTemplatePDFCompare {
  .solution-box {
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
      padding: 6px 12px;
      font-size: 18px;
    }
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
      padding: 6px 5px;
    }
    .ant-table .ant-table-container .ant-table-cell {
      font-size: 16px;
    }
  }
}
</style>
