<template>
  <div class="import scroll">
    <div class="body">
      <div class="vabTabsBox">
        <div class="title">{{ detail.name }}-导入文件</div>
      </div>
      <div class="children">
        <a-alert message="请下载 服务器导入模板（.xlsx）然后按照模板的要求依次顺序上传文件" type="info" />

        <div class="import-box">
          <a-row :gutter="48">
            <template v-for="(item, index) in stepList">
              <a-col class="gutter-row" :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <ToolsImport
                  type="two"
                  :item="item"
                  :index="index"
                  :uploadData="getUploadInfo"
                  @handleChange="handleChange($event, item)"
                  @handleHisFile="handleHisFile(item, index)"
                  :beforeUpload="(file, fileList) => onBeforeUpload(file, fileList, item)"
                />
              </a-col>
            </template>
          </a-row>
        </div>

        <div class="import-btns">
          <!-- <router-link class="tools-item" :to="{ path: '/dashboard/cloud/solution' }"> -->
          <!-- <a-button type="primary"  :disabled="!getHasToolAuth('tools2_base')"  :loading="createPlanLoading" @click="handleSavePlan('tools2_base')">
                            获取基础方案
                        </a-button> -->
          <OperateLimit type="primary" :loading="createPlanLoading" @doClick="handleSavePlan('tools2_base')" useTool="cvp2"> 获取基础方案 </OperateLimit>
          <!-- </router-link> -->
          <!-- <a-button type="primary" :disabled="!getHasToolAuth('tools2_extra')"  :loading="createPlanLoading" @click="handleSavePlan('tools2_extra')">
                        获取扩展方案
                    </a-button> -->

          <OperateLimit type="primary" :loading="createPlanLoading" @doClick="handleSavePlan('tools2_extra')" useTool="cvp2"> 获取扩展方案 </OperateLimit>
          <!-- <router-link class="tools-item" :to="{ path: '/history',query:{tool_type:'two'} }" v-if="getHasToolAuth('tools2_compare')">
                        <a-button type="primary" >获取对比方案</a-button>
                    </router-link> -->
        </div>
      </div>
    </div>

    <HistoryFiles ref="historyFiles" @selectItem="onSelectItem" />
  </div>
</template>

<script>
import { message, notification } from 'ant-design-vue'

import HistoryFiles from '../components/HistoryFiles.vue'
import ToolsImport from '@/components/ToolsImport.vue'
import { mapGetters } from 'vuex'

import { baseURL, download } from '@/utils/util'

export default {
  components: {
    HistoryFiles,
    ToolsImport
  },
  data() {
    return {
      detail: {},
      stepList: [
        { name: '销售预测表', temppath: 'tools2/销售预测表.xlsx', temp: 'step2-yc', file: {}, verifyIndex: -1, uploading: '' },
        { name: '存货期初结存表', temppath: 'tools2/存货期初结存表.xlsx', temp: 'step2-stock', file: {}, verifyIndex: 0, uploading: '' },
        { name: '单位产品工序用量与标准成本表', temppath: 'tools2/单位产品工序用量与标准成本表.xlsx', temp: 'step2-serial-stand', file: {}, verifyIndex: 1, uploading: '' },
        { name: '成本中心档案', temppath: 'tools2/成本中心档案.xlsx', temp: 'step2-cost', file: {}, verifyIndex: 2, uploading: '' },
        { name: '工序投入产出明细表', temppath: 'tools2/工序投入产出明细表.xlsx', temp: 'step2-serial-production', file: {}, verifyIndex: 3, uploading: '' },
        { name: '非工序投入表', temppath: 'tools2/非工序投入表.xlsx', temp: 'step2-n-serial', file: {}, verifyIndex: 4, uploading: '' }
      ],
      selectItemIndex: 0,

      createPlanLoading: false
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getUploadInfo() {
      let params = {}
      for (let i = 0; i < this.stepList.length; i++) {
        const info = this.stepList[i]
        if (!info.file || !('id' in info.file)) {
        } else {
          params[info.temp] = info.file.id
        }
      }
      return params
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$network('/api/tools/typeDetail', { type: 'two' }).then((res) => {
        const data = res.data || {}
        this.detail = data.detail || {}
      })
    },
    onBeforeUpload(file, fileList, item) {
      const verifyIndex = item.verifyIndex
      if (verifyIndex > -1) {
        const verifyItem = this.stepList[verifyIndex]
        if (!verifyItem.file.id) {
          notification.error({ description: `请先上传方案【${verifyItem.name}】` })
          return Promise.reject()
        }
      }
    },
    handleChange(info, item) {
      const status = info.file.status
      item.uploading = status

      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        const response = info.file.response || {}
        const responseData = response.data || {}
        console.log(response)
        if (!response.code) {
          notification.error({ description: response.msg })
          // message.error(response.msg);
        } else {
          item.file = responseData.info || {}
          notification.success({ description: `${item.name} 上传成功.` })
          // message.success(`${info.file.name} file uploaded successfully.`);
        }
      } else if (status === 'error') {
        notification.error({ description: `${item.name} 上传失败.` })
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    //选中了哪个文件
    onSelectItem(file) {
      this.stepList[this.selectItemIndex].file = file
    },
    // 历史文件
    handleHisFile(item, index) {
      this.selectItemIndex = index
      this.$refs.historyFiles.open(item)
    },
    handleSavePlan(planType) {
      const params = []
      for (let i = 0; i < this.stepList.length; i++) {
        const info = this.stepList[i]
        if (!info.file || !('id' in info.file)) {
          notification.error({ description: `请上传方案【${info.name}】` })
          // message.error(`请上传方案【${info.name}】`);
          return
        }
        params.push({ type: info.temp, id: info.file.id })
      }

      this.createPlan(params, planType)
    },
    createPlan(params, planType) {
      this.createPlanLoading = true
      this.$network('/api/tools/createPlan', { plan: params, tool_type: 'two' })
        .then((res) => {
          const data = res.data || {}
          this.$router.push({ path: '/dashboard/cloud2/solution', query: { id: data.id, planType: planType } })
        })
        .catch((err) => {})
        .finally(() => {
          this.createPlanLoading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
