<template>
  <div class="card-table">
    <div :class="isMobile ? 'card-table-box' : ''">
      <a-table ref="tableRef" class="cross-table" :columns="columns_analysis" :scroll="{ x: 500, y: gettableYheight }" :data-source="analysisData" :pagination="false" size="small" bordered>
        <template #bodyCell="{ column, record, text }"> </template>
      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SolutionAnalysis',
  components: {},
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    analysisData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columns_analysis: [
        { title: '象限', dataIndex: 'no', align: 'center', width: 50 },
        { title: '单位边际收益', dataIndex: 'come_in', align: 'center', ellipsis: true },
        { title: '销售变化率', dataIndex: 'per', align: 'center', ellipsis: true },
        { title: '规模', dataIndex: 'money', align: 'center', ellipsis: true, width: 50 },
        { title: '策略参考', dataIndex: 'intro', align: 'left', ellipsis: true, width: '54%' }
      ],
      tableData_analysis: []
    }
  },
  computed: {
    gettableYheight() {
      return document.body.clientWidth < 1441 ? 300 : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 420 : 440
    }
  },
  watch: {},
  mounted() {},
  methods: {}
}
</script>

<style></style>
