<template>
   <a-button :size="size" class="btn" @click="tip"> {{ content }} </a-button>
</template>

<script>
import { notification,Button  } from 'ant-design-vue';
import { h } from 'vue';
export default {
  props: {
    content:{
      type:String,
      default:'',
    },
    moduleName:{
      type:String,
      default:''
    },
    field:{
      type:String,
      default:''
    },
    size:{
      type:String,
      default:''
    }
  },
  computed: {
    
  },
  methods:{
    tip(){
      notification.error({ 
         description:  `请先开通vip`,
         btn: () =>
          h(
            Button,
            {
              type: 'primary',
              size: 'small',
              onClick: () => {
                this.$router.push({path:'/cost',query:{}})
              },
            },
            { default: () => '前往开通' },
          ),
      });
    }
  }
  
}
</script>

<style lang="less" scoped>
.btn{
  background: #f7f7f7;
}

</style>
