<template>
  <div class="history">
    <div class="header">
      <div class="title">历史方案</div>
      <div class="subtitle">支持查看本地的历史方案</div>
    </div>
    <div class="vabTabsBox">
      <div class="nav-tabs">
        <div class="nav-tabs_item active">
          <span>历史方案</span>
        </div>
        <div class="nav-tabs_item" v-if="getAuthList.indexOf('compare')>-1 && ['one', 'seven'].indexOf(listQuery.tool_type) > -1" @click="$router.push({ path: '/history/compare', query: {} })">
          <span>对比方案</span>
        </div>
        <div class="nav-tabs_item" @click="$router.push({ path: '/history/recovery', query: { moduleName: 'plan' } })" v-if="['company', 'person'].indexOf($store.state.user.user_ident) > -1">
          <span>回收站</span>
        </div>
      </div>
    </div>
    <div class="body">
      <div id="table-container" class="children">
        <div class="searchBox">
          <div>
            <a-space :size="16">
              <a-radio-group button-style="solid" v-model:value="listQuery.tool_type" @change="selectTypeChange">
                <template v-for="(item, index) in selectTab" :key="index">
                  <a-radio-button :value="item.type">{{ item.name }}</a-radio-button>
                </template>
              </a-radio-group>

              <!-- <a-button type="primary" v-if="['one','seven'].indexOf(listQuery.tool_type)>-1"  :disabled="!hasCompared " @click="handleCompared">方案对比</a-button> -->
              <OperateLimit
                type="primary"
                :loading="createPlanLoading"
                v-if="getAuthList.indexOf('compare')>-1 && ['one', 'seven'].indexOf(listQuery.tool_type) > -1"
                :disabled="!hasCompared"
                @doClick="handleCompared"
                :useTool="getUseToolKey"
              >
                方案对比
              </OperateLimit>
              <!-- <a-button type="primary" :disabled="!hasSelected">批量下载</a-button> -->
            </a-space>
          </div>
          <div>
            <a-space :size="16">
              <!-- <a-select ref="select" style="width: 200px" placeholder="类型"
                                :options="[...Array(5)].map((_, i) => ({ value: '类型' + (i + 1) }))"></a-select> -->
              <a-range-picker v-model:value="listQuery.date_range" @change="getData(true)" />
              <a-input-search v-model:value="listQuery.keyword" placeholder="请输入方案名称" @search="getData(true)"> </a-input-search>
            </a-space>
          </div>
        </div>

        <div class="tableWrapper">
          <a-table
            :scroll="{ y: '100%' }"
            rowKey="id"
            :pagination="false"
            :row-selection="{
              checkStrictly: false,
              columnWidth: 80,
              selectedRowKeys: selectedRowKeys,
              onChange: onTableChange,
              getCheckboxProps: (item) => ({ disabled: item.disabled, name: item.name })
            }"
            :data-source="tableData"
            :columns="columns"
          >
            <template #bodyCell="{ column, text, record, index }">
              <template v-if="column.dataIndex === 'name'">
                <div class="editable-cell">
                  <div v-if="record.is_edit" class="editable-cell-input-wrapper">
                    <a-input v-model:value="record.name" @pressEnter="saveItem(record, 'name')" @blur="saveItem(record, 'name')" />
                    <check-outlined class="editable-cell-icon-check" @click="saveItem(record, 'name')" />
                  </div>
                  <div v-else class="editable-cell-text-wrapper">
                    {{ text || ' ' }}
                    <edit-outlined class="editable-cell-icon" @click="record.is_edit = true" />
                  </div>
                </div>
              </template>
              <template v-if="column.dataIndex === 'operation'">
                <a-button type="primary" @click="handleDetail(record)">查看</a-button>

                <a-button type="text" danger @click="handleDel(record, index)">删除</a-button>
              </template>
            </template>
          </a-table>
        </div>
        <MyTablePagination @change="onPageChange" :total="total" />
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationCircleOutlined, CaretUpOutlined, SearchOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
import { cloneDeep } from 'lodash-es'
import { createVNode } from 'vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    CaretUpOutlined,
    SearchOutlined,
    CheckOutlined,
    EditOutlined,
    ExclamationCircleOutlined
  },
  data() {
    return {
      listQuery: {
        tool_type: 'one',
        keyword: '',
        date_range: []
      },
      selectTab: [],
      columns: [
        { title: '方案名称', dataIndex: 'name' },
        { title: '方案保存时间', dataIndex: 'save_time', align: 'center', width: 200 },
        { title: '操作', dataIndex: 'operation', align: 'center', width: 200 }
      ],
      total: 0,
      tableData: [],
      selectedRowKeys: [],
      authObject:[],
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getAuthList(){
      return this.authObject[this.listQuery.tool_type]||[]
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
    hasCompared() {
      return this.selectedRowKeys.length > 1
    },
    getUseToolKey() {
      let key = ''
      switch (this.listQuery.tool_type) {
        case 'one':
          key = 'CVP1_compare'
          break
        case 'seven':
          key = 'TZHB_1_dxmjc'
          break
      }
      return key
    }
  },
  watch: {
    'listQuery.tool_type': function (value) {
      let columns = [
        { title: '方案名称', dataIndex: 'name' },
        { title: '方案保存时间', dataIndex: 'save_time', align: 'center', width: 200 },
        { title: '操作', dataIndex: 'operation', align: 'center', width: 200 }
      ]

      if (value === 'five') {
        columns = [
          { title: '方案名称', dataIndex: 'name' },
          { title: '对比方式', dataIndex: 'base_name', width: 200 },
          { title: '方案保存时间', dataIndex: 'save_time', align: 'center', width: 200 },
          { title: '操作', dataIndex: 'operation', align: 'center', width: 200 }
        ]
      }
      this.columns = columns
    }
  },
  created() {
    this.getTools(true)
    this.getData(true)
  },
  methods: {
    getTools(refresh) {
      this.$network('/api/tools/tools', {showAuthList:1}).then((res) => {
        const data = res.data || []
        this.selectTab = data.list || []
        this.authObject = data.authTools||{}
      })
    },
    selectTypeChange() {
      this.selectedRowKeys = []
      this.getData(true)
    },
    getData(refresh) {
      if (refresh === true) {
        this.listQuery.page = 1
      }
      this.$network('/api/tools/planHistory', this.listQuery).then((res) => {
        console.log(res)
        const data = res.data || {}
        const list = data.list || []
        list.map(function (item) {
          item.is_edit = false
          item.disabled = false
        })
        this.tableData = list
        this.total = data.total || 0
      })
    },
    onPageChange(page, pageSize) {
      this.listQuery.page = page
      this.listQuery.limit = pageSize
      this.getData()
    },
    onTableChange(e) {
      this.selectedRowKeys = e

      if (this.listQuery.tool_type === 'seven' && e.length > 4) {
        this.tableData.map(function (item) {
          if (e.indexOf(item.id) == -1) {
            item.disabled = true
          }
        })
      } else if (this.listQuery.tool_type === 'one' && e.length == 2) {
        this.tableData.map(function (item) {
          if (e.indexOf(item.id) == -1) {
            item.disabled = true
          }
        })
      } else {
        this.tableData.map(function (item) {
          item.disabled = false
        })
      }
    },
    saveItem(item, field) {
      item.is_edit = false
      const obj = {}
      obj.id = item.id
      obj[field] = item[field]
      this.$network('/api/tools/planHistoryEdit', obj).then((res) => {})
    },
    handleCompared() {
      const id1 = this.selectedRowKeys[0] || ''
      const id2 = this.selectedRowKeys[1] || ''
      let url = ''
      let query = {}
      if (this.listQuery.tool_type == 'one') {
        url = '/dashboard/cloud/compare'
        query = { id: id1, compareId: id2 }
      } else if (this.listQuery.tool_type == 'two') {
        url = '/dashboard/cloud2/compare'
        query = { id: id1, compareId: id2 }
      } else if (this.listQuery.tool_type == 'seven') {
        const id3 = this.selectedRowKeys[2] || ''
        const id4 = this.selectedRowKeys[3] || ''
        const id5 = this.selectedRowKeys[4] || ''
        url = '/dashboard/cloud7/compare'
        query = { id: id1, id1: id2, id2: id3, id3: id4, id4: id5 }
      }
      this.$router.push({ path: url, query: query })
    },
    handleDetail(item) {
      let url = ''
      if (item.tool_type == 'one') {
        url = '/dashboard/cloud/solution'
      } else if (item.tool_type == 'two') {
        url = '/dashboard/cloud2/solution'
      } else if (item.tool_type == 'three') {
        url = '/dashboard/cloud3/solution'
      } else if (item.tool_type == 'four') {
        url = '/dashboard/cloud4/solution'
      } else if (item.tool_type == 'five') {
        url = '/dashboard/cloud5/solution'
      } else if (item.tool_type == 'six') {
        url = '/dashboard/cloud6/solution'
      } else if (item.tool_type == 'seven') {
        url = '/dashboard/cloud7/solution'
      }

      this.$router.push({ path: url, query: { id: item.id } })
    },
    handleDel(item, index) {
      this.handleRemove([item.id], () => {
        this.tableData.splice(index, 1)
      })
    },
    handleRemove() {
      this.handleRemove([item.id], () => {
        this.getData()
      })
    },
    handleRemove(ids, fnc) {
      Modal.confirm({
        title: '删除提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '确定删除吗？ 删除后不可撤销',
        okText: '确定删除',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.$network('/api/tools/planHistoryRemove', { ids: ids })
            .then((res) => {
              fnc()
            })
            .catch((err) => {
              console.error(err)
            })
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    }
  }
}

// import { ref, reactive, onMounted, computed } from 'vue';
// import type { Ref, UnwrapRef } from 'vue';
// import { CaretUpOutlined, SearchOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons-vue';
// import { cloneDeep } from 'lodash-es';

// const datetime = ref<string>('');
// const keywords = ref<string>('');

// const onSearch = (searchValue: string) => {
//     console.log('use value', searchValue);
//     console.log('or use this.value', keywords.value);
// };

// interface DataItem {
//     key: string;
//     name: string;
//     file_format: string;
//     file_size: number;
//     create_time: string;
// }

// const columns = [
//     {
//         title: '方案名称',
//         dataIndex: 'name',
//         width: 350,
//     },
//     {
//         title: '文件格式',
//         dataIndex: 'file_format',
//         width: 200,
//         align: 'center'
//     },
//     {
//         title: '文件大小',
//         dataIndex: 'file_size',
//         width: 200,
//         align: 'center'
//     },
//     {
//         title: '方案创建时间',
//         dataIndex: 'create_time',
//         align: 'center'
//     },
//     {
//         title: '操作',
//         dataIndex: 'operation',
//         align: 'center'
//     },

// ];

// const dataSource: Ref<DataItem[]> = ref([
//     {
//         key: '0',
//         name: '“翎谋”运算方案说明书 ',
//         file_format: 'PDF',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     },
//     {
//         key: '1',
//         name: '“翎谋”运算方案说明书 ',
//         file_format: 'PDF',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     },
//     {
//         key: '2',
//         name: '“翎谋”运算方案说明书 ',
//         file_format: 'PDF',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     }, {
//         key: '3',
//         name: '“翎谋”运算方案说明书 ',
//         file_format: 'PDF',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     }, {
//         key: '4',
//         name: '“翎谋”运算方案说明书 ',
//         file_format: 'PDF',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     }, {
//         key: '5',
//         name: '“翎谋”运算方案说明书 ',
//         file_format: 'PDF',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     }, {
//         key: '6',
//         name: '“翎谋”运算方案说明书 ',
//         file_format: 'PDF',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     }, {
//         key: '7',
//         name: '“翎谋”运算方案说明书 ',
//         file_format: 'PDF',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     }, {
//         key: '8',
//         name: '“翎谋”运算方案说明书 ',
//         file_format: 'PDF',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     }, {
//         key: '9',
//         name: '“翎谋”运算方案说明书 ',
//         file_format: 'PDF',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     }, {
//         key: '10',
//         name: '“翎谋”运算方案说明书 ',
//         file_format: 'PDF',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     },
// ]);
// const editableData: UnwrapRef<Record<string, DataItem>> = reactive({});

// const edit = (key: string) => {
//     editableData[key] = cloneDeep(dataSource.value.filter(item => key === item.key)[0]);
// };
// const save = (key: string) => {
//     Object.assign(dataSource.value.filter(item => key === item.key)[0], editableData[key]);
//     delete editableData[key];
// };

// const state = reactive<{
//     selectedRowKeys: Key[];
//     loading: boolean;
// }>({
//     selectedRowKeys: [], // Check here to configure the default column
//     loading: false,
// });
// const hasSelected = computed(() => state.selectedRowKeys.length > 0);
// const hasCompared = computed(() => state.selectedRowKeys.length >= 2);

// const start = () => {
//     state.loading = true;
//     // ajax request after empty completing
//     setTimeout(() => {
//         state.loading = false;
//         state.selectedRowKeys = [];
//     }, 1000);
// };
// const onSelectChange = (selectedRowKeys: Key[]) => {
//     console.log('selectedRowKeys changed: ', selectedRowKeys);
//     state.selectedRowKeys = selectedRowKeys;
// };
</script>

<style scoped lang="less">
.editable-cell {
  position: relative;

  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    cursor: pointer;
    margin: auto 0;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}

.ant-btn + .ant-btn {
  margin-left: 10px;
}
</style>
