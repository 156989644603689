<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Register',
})
</script>
<template>
    <div class="main user-layout-register">
        <div class="user-register-box">
            <div class="register-steps">
                <a-steps :current="2" label-placement="vertical" :items="items" />
            </div>
            <div class="register-result">
                <a-result :isSuccess="true" :content="false" :title="title" :sub-title="description">

                    <template #extra>
                            <a-button class="btn-vip"  @click="handleShowVerify">会员权益</a-button>
                            <a-button  type="primary" @click="goHomeHandle">立即体验</a-button>
                       
                    </template>

                </a-result>
            </div>
        </div>
    </div>
    <PopupVipBox ref="popupVipBox"  moduleName="registerComplete"/>
</template>

<script lang="ts" setup name="Register">
import PopupVipBox from '@/components/PopupVipBox.vue'
import { ref, reactive, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()

const items = ref([
    {
        title: '填写企业信息',
    },
    {
        title: '注册成功',
    }
]);

const title = '恭喜，注册成功'
const description = '温馨提示：注册成功请等待官方认证授权，我们将在三个工作日内审核您的资料，认证成功后将可以使用所有模块工具。'

const goHomeHandle = () => {
    router.push({ path: '/user/login' })
};

//版本差异
const popupVipBox = ref()
const handleShowVerify = () => {
    popupVipBox.value.open()
}
onMounted(()=>{
    handleShowVerify()
})
</script>

<style lang="less" scoped>
.user-layout-register {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-vip{
        margin-bottom: 10px;
    }
    .user-register-box {
        width: 100%;
        padding: 12px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .register-steps {
            width: 100%;
            padding: 24px 16%;
            border-bottom: 1px solid #d9d9d9;
        }

        .register-result {
            width: 640px;
            padding: 120px 0px;

            .ant-btn {
                width: 100%;
                height: 54px;
                font-size: 16px;
            }
        }


    }
}
</style>
