<template>
  <a-spin v-if="loading" :spinning="loading" size="large" class="full-page-loading" :tip="content">
     
  </a-spin>
</template>

<script>
export default {
    name:'ProjectOpening',
    data(){
        return {
            content:'项目开发中~',
            loading:false,
			time: 3000,
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            this.$network('/api/index/defInfo',{type:'project_state'}).then((res)=>{
                const data = res.data||{};
                this.loading = data.open_state === 1
				this.time = data.time || 3000
				this.content = data.intro || '项目开发中~'

                setTimeout(()=>{
					this.loading = false
				},this.time)
            })
        },
        open(content){
            this.loading = true
            this.content = content
        },
        close(){
            this.loading = false
        },
        text(content){
            this.content = content
        }
    }
}
</script>

<style scoped>
.full-page-loading{
    position: absolute;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
    z-index: 9999;
    font-size: 32px;
    color: white;
}
</style>