<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserLevel',
})
</script>
<template>
    <div class="UserLevel level_9">
        <span>{{ $store.state.user.level_name }}</span>
    </div>
</template>

<script lang="ts" setup name="UserLevel">
import SvgIcon from '@/components/SvgIcon/index.vue'


</script>
<style lang="less" scoped>
.UserLevel {
    width: 68px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('/icons/level-9.png');
    text-align: center;
    padding: 0px 6px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: #ffffff;
        font-size: 12px;
    }
}
</style>
