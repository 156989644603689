<template>
  <div class="solution-children">
    <div class="head">基本表</div>


    <div class="row">
      <div class="a-space-table">
        <a-space :size="16">
          <a-table :columns="columns" :data-source="tools2_excelData_yc" :pagination="pagination" size="small" bordered>
           
          </a-table>
        </a-space>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters  } from 'vuex'

export default {
  name: 'SolutionBaseTable',
  components: {
  },
  props: {
    planId: {
      type: [Number, String],
      default: 0
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    }
  },
  data() {
    return {
    
      //加成前
      columns: [
        { title: '产品编码ID', dataIndex: 'no', align: 'center' },
        { title: '产品规格/描述', dataIndex: 'attr', align: 'center' },
        { title: '产品线', dataIndex: 'line', align: 'center' },
        { title: '产品类别', dataIndex: 'type', align: 'center' },
        { title: '客户', dataIndex: 'customer', align: 'center' },
        { title: '不含税售价', dataIndex: 'price', align: 'center' },
        { title: '税率(%)', dataIndex: 'tax_per', align: 'center' },
        { title: '含税售价', dataIndex: 'price_add_tax', align: 'center' },
        { title: '计量单位', dataIndex: 'unit', align: 'center' },
        { title: '销量', dataIndex: 'sold_num', align: 'center' },
        { title: '销售收入', dataIndex: 'price_come_in', align: 'center' },
        { title: '区域', dataIndex: 'area', align: 'center' },
        { title: '业务组', dataIndex: 'group', align: 'center' },
        { title: '产品合格率(%)', dataIndex: 'pass_per', align: 'center' },
        
        
      ],
    }
  },
  computed: {
    ...mapGetters([
      
    ]),
		tools2_excelData_yc(){
			return this.$store.state.tools2.tools2_excelData_yc;
		},
    

    
  },
  created() {},
  mounted() {
    this.getDetail()
  },
  watch: {
    
   
   
  },
  methods: {
    getDetail() {
      return this.$network('/api/tools/planResultData', {
        id: this.planId,
        type: 'step2-yc'
      })
        .then((res) => {
          const data = res.data || {}
          const list = data.list || []
          let pcData = {}
          list.map((item)=>{
            const customerNo = item.customerNo
            // if(!(no in excelNoAndCustom)){
              pcData[customerNo] = item
            // }

          })

          this.$store.commit('updateTools2State',{field:'tools2_excelData_yc',value: list }) 

        })
        .catch((err) => {
          console.error(err)
        })
    },
   
  }
}
</script>
