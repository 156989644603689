import ls from '@/utils/Storage'
import { ACCESS_TOKEN, PERMISSION, USER_INFO, MENU_NAV } from '@/store/mutation-types'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { isMobile, isTablet } from './device';
import { store } from '@/main'

dayjs.extend(utc)
dayjs.extend(timezone)

//表格显示行数
export const tableDefautlLine = 10;
export const tableDefaultPage = () => { return { pageSize: tableDefautlLine, hideOnSinglePage: true, disabled: true } };

export const tableDefautlLine2 = 20;
export const tableDefaultPage2 = () => { return { pageSize: tableDefautlLine2, hideOnSinglePage: true, disabled: true } };

// 工具某些特殊显示2行
export const tableCloudLine = 3;
export const tableCloudPage = () => { return { pageSize: tableCloudLine, hideOnSinglePage: true, disabled: true } };

export function clearUserInfo() {
    ls.remove(ACCESS_TOKEN)
    ls.remove(PERMISSION)
    ls.remove(USER_INFO)
    ls.remove(MENU_NAV)
}

export function timeFix() {
    const time = new Date()
    const hour = time.getHours()
    console.log(hour);

    return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

// aes encryption key
export const encryptKeys = {
    // key最少4位,否则报错
    key: '1111',
    iv: '1',
};

export const getQueryParameters = (options) => {
    const url = options.url
    const search = url.split('?')[1]
    if (!search) {
        return {}
    }
    return JSON.parse('{"' + decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') + '"}')
}

export const getBody = (options) => {
    return options.body && JSON.parse(options.body)
}


export const firstLetterIsUpperCase = function (str) {
    var reg = /^[A-Z][A-z0-9]*$/;
    return reg.test(str);
};

//大数值转换
export const bigNumberTransform = function (content, hasUnit = true, suffixSymbol = ' 万元', fixedNumber = 2, isDealThousands = true) {
    const symbol = parseFloat(content) < 0 ? '-' : '';
    const value = (symbol == '-' ? -1 : 1) * content;

    const newValue = ['', '', '']
    let fr = 1000
    let num = 3
    let text1 = ''
    let fm = 1
    while (value / fr >= 1) {
        fr *= 10
        num += 1
        // console.log('数字', value / fr, 'num:', num)
    }
    var handleValue = numberToFixed(value, fixedNumber)

    //   if (num <= 4) { // 千
    //     newValue[0] = (isDealThousands ? dealThousands(handleValue) : handleValue ) + ''
    //     // newValue[0] = parseInt(value / 1000) + ''
    //     newValue[1] = ''
    //   }else{
    // text1 =  ' 万元'
    fm = 10000
    handleValue = numberToFixed(value / fm, fixedNumber)

    newValue[0] = (isDealThousands ? dealThousands(handleValue) : handleValue) + ''
    newValue[1] = hasUnit ? suffixSymbol : ''
    //   } 
    //   else if (num <= 8) { // 万
    //     text1 = parseInt(num - 4) / 3 > 1 ? '千万' : '万'
    //     // tslint:disable-next-line:no-shadowed-variable
    //     fm = text1 === '万' ? 10000 : 10000000
    //     // if (value % fm === 0) {
    //     //   newValue[0] = parseInt(value / fm) + ''
    //     // } else {
    //       newValue[0] = parseFloat(value / fm).toFixed(2) + ''
    //     // }
    //     newValue[1] = text1
    //   } else if (num <= 16) { // 亿
    //     text1 = (num - 8) / 3 > 1 ? '千亿' : '亿'
    //     text1 = (num - 8) / 4 > 1 ? '万亿' : text1
    //     text1 = (num - 8) / 7 > 1 ? '千万亿' : text1
    //     // tslint:disable-next-line:no-shadowed-variable
    //     fm = 1
    //     if (text1 === '亿') {
    //       fm = 100000000
    //     } else if (text1 === '千亿') {
    //       fm = 100000000000
    //     } else if (text1 === '万亿') {
    //       fm = 1000000000000
    //     } else if (text1 === '千万亿') {
    //       fm = 1000000000000000
    //     }
    //     // if (value % fm === 0) {
    //     //   newValue[0] = parseInt(value / fm) + ''
    //     // } else {
    //       newValue[0] = parseFloat(value / fm).toFixed(2) + ''
    //     // }
    //     newValue[1] = text1
    //   }
    //   if (value < 1000) {
    //     newValue[0] =  value + ''
    //     newValue[1] = ''
    //   }
    return symbol + newValue.join(' ')
};

export const separator = ';'

export const divisionStringToArray = (string, customSeparator) => {
    return string ? string.split(customSeparator || separator) : []
}

export const getWeek = (week: number, useZhou) => {
    let txt = ''
    switch (week) {
        case 1:
            txt = '一'
            break
        case 2:
            txt = '二'
            break
        case 3:
            txt = '三'
            break
        case 4:
            txt = '四'
            break
        case 5:
            txt = '五'
            break
        case 6:
            txt = '六'
            break
        case 0:
            txt = '日'
            break
        default:
            return 'getWeekError'
    }
    return useZhou ? '周' : '星期' + txt
}

const sitUrl = 'xxxxx'
export const isDev = import.meta.env.DEV
export const baseURL = PLATFORM_REQUEST_URL

export function toLocalTimeStr({ date, format = 'YYYY-MM-DD HH:mm:ss' }) {
    if (!date) return null;
    return dayjs(date).format(format)
}

export function objToArr(obj) {
    let arr: any = []
    for (const o in obj) {
        arr.push({ label: o, txt: obj[o] })
    }
    return arr
}

export function clearObj(obj) {
    for (const key in obj) {
        obj[key] = undefined
    }
}

export function delArrItem(arr, item) {
    const index = arr.indexOf(item);
    arr.splice(index, 1);
}

export const useImageUrl = (name: string, type: string = 'png'): string => {
    /**
     * @method vite动态引入图片
     * @params folder 文件夹名称 name 文件名称 type 文件格式 一般为png/jpg/webp/gif等...
     * @returns 图片
     */
    return new URL(`../assets/images/${name}.${type}`, import.meta.url).href
}

export const batchDispatch = (dispatch, arr) => {
    arr.forEach(item => {
        dispatch(item)
    });
}

export const createFormData = (formDatas, file) => {
    const formData = new FormData()
    if (file.length) {
        file.map(item => {
            formData.append('file', item)
        })
    } else {
        formData.append('file', file)
    }
    for (const item in formDatas) {
        formData.append(item, formDatas[item] || '')
    }
    return formData
}


/**
 * 格式化文件大小
 *
 * @param {String} value 文件大小(字节)
 */
export function formateSize(value) {
    if (null == value || value == '') {
        return '0'
    }
    let unitArr = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let index = 0
    let srcsize = parseFloat(value)
    index = Math.floor(Math.log(srcsize) / Math.log(1000))
    let size = srcsize / Math.pow(1000, index)
    size = size.toFixed(2) //保留的小数位数
    return size + unitArr[index]
}


/**
 * 格式化文件大小
 *
 * @param {String} value 文件大小(字节)
 */
export function getFileSize(path) {
    if (!path) {
        return 0
    }
    let fsize = 0;
    const pathArr = path.split('_')
    if (pathArr.length >= 3) {
        fsize = path[pathArr.length - 2] || 0
    }
    return fsize
}

export const priceLadderPriceKey = function (item) {
    const group = [""];
    group.push(item.no || '');
    group.push(item.line || '');
    group.push(item.type || '');
    group.push(item.customer || '');
    group.push(item.area || '');
    group.push(item.group || '');
    return group.join("_+_")
};
//获取价格
// export const priceLadderItemPrice = function(item,way='after'){
//     const ogItemPrice = item.price||0
//     const ogItem__price = item.__price||0
//     let itemPrice = ogItemPrice
//     let itemSoldNum = parseFloat(item.sold_num||0)
//     if(way=='before'){
//         itemPrice = parseFloat(ogItem__price);
//         itemSoldNum = parseFloat(item.__sold_num||0);
//     }
//     //选中的是
//     // full = 保本后促销 是指对满足条件量以后的数据起作用；
//     // '' = 足量促销 是指对满足特定条件数量的下的所有可能量起作用（通杀），注意可能有多个特定触发条件
//     const plan_base =  store.state.tools.plan_base||''
//     const ycPrice = store.state.tools.ycPrice||{}
//     const ycPriceKeys = Object.keys(ycPrice)
//     const ycPriceKeysLen = ycPriceKeys.length
//     if(ycPrice && ycPriceKeysLen){

//         //查找数据
//         const checkFields = ['no', 'line', 'type', 'customer', 'area', 'group']
//         const checkCondItemFnc = function(item){
//             let selectItem = {}
//             let selectRange = []
//             for(let i=0;i<ycPriceKeysLen;i++){
//                 const ycInfo = ycPrice[ycPriceKeys[i]]
//                 const ycItem = ycInfo.item
//                 const ycRange = ycInfo.range

//                 //查找是否满足条件
//                 const state = checkFields.every((field)=>{
//                     const ycItemFieldValue = ycItem[field]||''
//                     return !ycItemFieldValue || ycItemFieldValue==item[field]
//                 })
//                 if(state){
//                     selectItem = ycItem
//                     selectRange = ycRange
//                     break;
//                 }

//             }
//             return { selectItem, selectRange }
//         }

//         const {selectItem,selectRange} = checkCondItemFnc(item);
//         // const itemKey = priceLadderPriceKey(item)
//         // const priceRange = ycPrice[itemKey]||[];
//         // console.log('>>>>>>>>>>> selectItem,selectRange <<<<<<<<<<<<<<<')
//         // console.log(item,selectItem,selectRange)
//         selectRange.map((priceItem)=>{
//             const num_start = parseFloat(priceItem.num_start)
//             const num_end = parseFloat(priceItem.num_end)
//             const price = parseFloat(priceItem.price)  //折扣
//             if(itemSoldNum>=num_start){
//                 itemPrice = ogItemPrice * price
//                 if(way=='before'){
//                     itemPrice = ogItem__price * price
//                 }
//             }
//         })
//     }
//     return itemPrice
//  };
//初始化模板变量
//初始化模板变量
export const tempDataInit = function (temp, obj, item) {
    for (let key in temp) {

        let value_type = typeof temp[key]
        let value = item[key];
        let default_value;

        if (temp[key] instanceof Object) {
            var jsonObject = JSON.stringify(temp[key])
            if (jsonObject[0] === '[') {
                default_value = []
            } else if (jsonObject[0] === '{') {
                value = Object.assign({}, value || {})
                let tempObj = tempDataInit(temp[key], Object.assign({}, obj[key] || {}), value);
                if (Object.keys(tempObj).length) {
                    default_value = tempObj
                    value = tempObj
                } else {
                    default_value = Object.assign({}, temp[key] || {})
                }
            } else {
                default_value = ""
            }
        } else if (value_type === 'string') {
            default_value = temp[key] + ''
            value = (value || '') + ""
        } else if (value === 0) {
            default_value = temp[key] || 0
        }
        obj[key] = value || default_value
    }
    return obj;
};


/**
 * 文件下载方法
 *
 * @param {String} path
 */
export const download = (path) => {
    try {
        let link = document.createElement('a')
        link.target = "_blank"
        link.href = path
        link.click()
    } catch (e) { }
}

/**
 * 格式化数字
 * @param {String} number
 * @param {number} position
 */
export const numberToFixed = (number, decimal = 6) => {
    const money = (number * 1).toFixed(decimal)
    return money
}
/**
 * 格式化数字
 * @param {String} number
 * @param {number} position
 */
export const roundToDotNumber = (number, decimal = 2) => {
    const oneZer = Math.pow(10, decimal);
    const num = Math.floor(number * oneZer) / oneZer
    return num.toFixed(2)
}


//处理千分位使用
export const dealThousands = (value, decimal = 2) => {
    if (decimal === 0) {
        value = parseInt(value) + ""
    } else {
        value = numberToFixed(value, decimal)
    }
    // return value;
    if (value === 0) {
        return parseFloat(value).toFixed(decimal);
    }
    if (value != "") {
        var num = "";
        value += "";//转化成字符串
        value = parseFloat(value.replace(/,/g, '')).toFixed(decimal);//若需要其他小数精度，可将2改成变量
        if (value.indexOf(".") == -1) {
            num = value.replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                return s + ',';
            });
        } else {
            num = value.replace(/(\d)(?=(\d{3})+\.)/g, function (s) {
                return s + ',';
            });
        }
    } else {
        num = ""
    }
    return num;
}
/**
 * 检查数字是否是0
 * @param {String} number
 */
export const checkZero = (number) => {
    if (!number) {
        return true;
    }
    return parseFloat(number) == 0
}


/**
 * 节流原理：在一定时间内，只能触发一次
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
export const throttle = (func, wait = 500, immediate = true) => {
    let throttleTimer; let throttleFlag
    return function () {
        if (immediate) {
            if (!throttleFlag) {
                throttleFlag = true
                // 如果是立即执行，则在wait毫秒内开始时执行
                typeof func === 'function' && func()
                throttleTimer = setTimeout(() => {
                    throttleFlag = false
                }, wait)
            }
        } else if (!throttleFlag) {
            throttleFlag = true
            // 如果是非立即执行，则在wait毫秒内的结束处执行
            throttleTimer = setTimeout(() => {
                throttleFlag = false
                typeof func === 'function' && func()
            }, wait)
        }
    }

}


/**
 * 防抖原理：一定时间内，只有最后一次操作，再过wait毫秒后才执行函数
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */

export const debounce = (func, wait = 500, immediate = false) => {
    let debounceTimeout
    return () => {
        // 清除定时器
        if (debounceTimeout !== null) clearTimeout(debounceTimeout)
        // 立即执行，此类情况一般用不到
        if (immediate) {
            const callNow = !debounceTimeout
            debounceTimeout = setTimeout(() => {
                debounceTimeout = null
            }, wait)
            if (callNow) typeof func === 'function' && func()
        } else {
            // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
            debounceTimeout = setTimeout(() => {
                typeof func === 'function' && func()
            }, wait)
        }
    }

}

//计算excel额外数据
const fnc_excelData_yc_extraData = (no, item, newExcelData) => {
    if (!(no in newExcelData)) {
        item['has_more'] = 1
        newExcelData[no] = item
    } else {
        item['has_more'] += 1

        newExcelData[no].sold_num = newExcelData[no].sold_num * 1 + item.sold_num * 1
        if ('__sold_num' in newExcelData[no]) { newExcelData[no].__sold_num = newExcelData[no].__sold_num * 1 + item.__sold_num * 1 }
        //总含税收入
        newExcelData[no].sold_come_in = newExcelData[no].sold_come_in * 1 + item.sold_come_in * 1
        if ('__sold_num' in newExcelData[no]) { newExcelData[no].__sold_come_in = newExcelData[no].__sold_come_in * 1 + item.__sold_come_in * 1 }

        //不含税总收入
        newExcelData[no].price_come_in = newExcelData[no].price_come_in * 1 + item.price_come_in * 1
        if ('__sold_num' in newExcelData[no]) { newExcelData[no].__price_come_in = newExcelData[no].__price_come_in * 1 + item.__price_come_in * 1 }

        //产量
        newExcelData[no].pass_sold_num = newExcelData[no].pass_sold_num * 1 + item.pass_sold_num * 1
        if ('__sold_num' in newExcelData[no]) { newExcelData[no].__pass_sold_num = newExcelData[no].__pass_sold_num * 1 + item.__pass_sold_num * 1 }

        newExcelData[no].price = newExcelData[no].price * 1 + item.price * 1
        if ('__sold_num' in newExcelData[no]) { newExcelData[no].__price = newExcelData[no].__price * 1 + item.__price * 1 }
    }


}

//计算excel新数据
const fnc_excelData_yc_newData = (newExcelData) => {

    let newData = []

    let sum_sold_num = 0;
    let sum_material_cost_money = 0;
    let sum_price_come_in = 0;
    let sum_side_money = 0;

    let sum_change_sold_num = 0;
    let sum_change_material_cost_money = 0;
    let sum_change_price_come_in = 0;
    let sum_change_side_money = 0;

    for (let key in newExcelData) {
        const info = newExcelData[key]


        newExcelData[key].sold_num = numberToFixed(info.sold_num)
        if ('__sold_num' in newExcelData[key]) newExcelData[key].__sold_num = numberToFixed(info.__sold_num)

        newExcelData[key].sold_come_in = numberToFixed(info.sold_come_in)
        if ('__sold_num' in newExcelData[key]) newExcelData[key].__sold_come_in = numberToFixed(info.__sold_come_in)

        newExcelData[key].pass_sold_num = numberToFixed(info.pass_sold_num)
        if ('__sold_num' in newExcelData[key]) newExcelData[key].__pass_sold_num = numberToFixed(info.__pass_sold_num)

        newExcelData[key].price = numberToFixed(info.price)
        if ('__sold_num' in newExcelData[key]) newExcelData[key].__price = numberToFixed(info.__price)

        newExcelData[key].tax_per = numberToFixed(info.tax_per)
        if ('__sold_num' in newExcelData[key]) newExcelData[key].__tax_per = numberToFixed(info.__tax_per)

        //合格率
        newExcelData[key].pass_per = numberToFixed(checkZero(info.pass_sold_num) ? 0 : (info.sold_num / info.pass_sold_num) * 100)
        if ('__sold_num' in newExcelData[key]) newExcelData[key].__pass_per = numberToFixed(checkZero(info.__pass_sold_num) ? 0 : (info.__sold_num / info.__pass_sold_num) * 100)

        //平均含税价格
        newExcelData[key].price_add_tax = numberToFixed(checkZero(info.sold_num) ? 0 : info.sold_come_in / info.sold_num)
        if ('__sold_num' in newExcelData[key]) newExcelData[key].__price_add_tax = numberToFixed(checkZero(info.__sold_num) ? 0 : info.__sold_come_in / info.__sold_num)

        //平均含税价格
        newExcelData[key].price_come_in = numberToFixed(info.price_come_in)
        if ('__sold_num' in newExcelData[key]) newExcelData[key].__price_come_in = numberToFixed(info.__price_come_in)

        //原总变动成本
        newExcelData[key].material_cost_money = numberToFixed(info.material_cost * info.pass_sold_num)
        if ('__sold_num' in newExcelData[key]) newExcelData[key].__material_cost_money = numberToFixed(info.__material_cost * info.__pass_sold_num)

        //原边际贡献 = 销售收入 - 原总变动成本
        newExcelData[key].side_money = numberToFixed(newExcelData[key].price_come_in - newExcelData[key].material_cost_money)
        if ('__sold_num' in newExcelData[key]) newExcelData[key].__side_money = numberToFixed(newExcelData[key].__price_come_in - newExcelData[key].__material_cost_money)

        //原单位边际贡献 = 原边际贡献 / 总销量
        newExcelData[key].side_unit_money = numberToFixed(newExcelData[key].side_money / info.sold_num)
        if ('__sold_num' in newExcelData[key]) newExcelData[key].__side_unit_money = numberToFixed(newExcelData[key].__side_money / info.__sold_num)

        sum_sold_num += newExcelData[key].sold_num * 1
        sum_material_cost_money += newExcelData[key].material_cost_money * 1
        sum_price_come_in += newExcelData[key].price_come_in * 1
        sum_side_money += newExcelData[key].side_money * 1

        if ('__sold_num' in newExcelData[key]) {
            sum_change_sold_num += newExcelData[key].__sold_num * 1
            sum_change_material_cost_money += newExcelData[key].__material_cost_money * 1
            sum_change_price_come_in += newExcelData[key].__price_come_in * 1
            sum_change_side_money += newExcelData[key].__side_money * 1
        }


        // const sum_coll_total = newExcelData[key].side_money*1 + newExcelData[key].material_cost_money*1 + newExcelData[key].price_come_in*1 + newExcelData[key].sold_num*1;
        // newExcelData[key].side_money__per = checkZero(sum_coll_total) ? 0 : numberToFixed((newExcelData[key].side_money/sum_coll_total)*100)
        // newExcelData[key].material_cost_money__per = checkZero(sum_coll_total) ? 0 : numberToFixed((newExcelData[key].material_cost_money/sum_coll_total)*100)
        // newExcelData[key].price_come_in__per = checkZero(sum_coll_total) ? 0 : numberToFixed((newExcelData[key].price_come_in/sum_coll_total)*100)
        // newExcelData[key].sold_num__per = checkZero(sum_coll_total) ? 0 : numberToFixed((newExcelData[key].sold_num/sum_coll_total)*100)

        // if('__sold_num' in newExcelData[key]){
        //     const __sum_coll_total = newExcelData[key].__side_money*1 + newExcelData[key].__material_cost_money*1 + newExcelData[key].__price_come_in*1 + newExcelData[key].__sold_num*1;
        //     newExcelData[key].__side_money__per = checkZero(__sum_coll_total) ? 0 : numberToFixed((newExcelData[key].__side_money/__sum_coll_total)*100)
        //     newExcelData[key].__material_cost_money__per = checkZero(__sum_coll_total) ? 0 : numberToFixed((newExcelData[key].__material_cost_money/__sum_coll_total)*100)
        //     newExcelData[key].__price_come_in__per = checkZero(__sum_coll_total) ? 0 : numberToFixed((newExcelData[key].__price_come_in/__sum_coll_total)*100)
        //     newExcelData[key].__sold_num__per = checkZero(__sum_coll_total) ? 0 : numberToFixed((newExcelData[key].__sold_num/__sum_coll_total)*100)
        // }

        newData.push(newExcelData[key])
    }
    newData.map((item) => {
        item.side_money__per = checkZero(sum_side_money) ? 0 : numberToFixed((item.side_money / sum_side_money) * 100)
        item.material_cost_money__per = checkZero(sum_material_cost_money) ? 0 : numberToFixed((item.material_cost_money / sum_material_cost_money) * 100)
        item.price_come_in__per = checkZero(sum_price_come_in) ? 0 : numberToFixed((item.price_come_in / sum_price_come_in) * 100)
        item.sold_num__per = checkZero(sum_sold_num) ? 0 : numberToFixed((item.sold_num / sum_sold_num) * 100)

        if ('__sold_num' in item) {
            item.__side_money__per = checkZero(sum_change_side_money) ? 0 : numberToFixed((item.__side_money / sum_change_side_money) * 100)
            item.__material_cost_money__per = checkZero(sum_change_material_cost_money) ? 0 : numberToFixed((item.__material_cost_money / sum_change_material_cost_money) * 100)
            item.__price_come_in__per = checkZero(sum_change_price_come_in) ? 0 : numberToFixed((item.__price_come_in / sum_change_price_come_in) * 100)
            item.__sold_num__per = checkZero(sum_change_sold_num) ? 0 : numberToFixed((item.__sold_num / sum_change_sold_num) * 100)
        }

    })



    return newData
}

// excel预测以前变更后的数据
export const fnc_excelData_yc_line = (paramData, {
    params_sold_per = 0,
    params_pass_per = 0,
    params_tax_per = 0,
    params_cost_per = 0,
    handleStepPriceState = 0, //处理阶梯价格问题
    addLine = true,//增加行参数问题处理
}) => {
    // console.log('------------------------------ycPrice-')
    // console.log(store.state.tools.ycPrice    )




    const value = JSON.parse(JSON.stringify(paramData))
    return value.map((itemData) => {
        const item = { ...itemData }
        const params_line_tax_per_extra = (item.params_line_tax_per_extra || 0) / 100
        const params_line_sold_per_extra = (item.params_line_sold_per_extra || 0) / 100
        const params_line_cost_per_extra = (item.params_line_cost_per_extra || 0) / 100
        const params_line_pass_per_extra = item.params_line_pass_per_extra || 0

        const no = item.no

        //销量加成
        item.sold_num = numberToFixed(item.sold_num * (1 + params_sold_per / 100 + (addLine ? item.params_line_sold_per / 100 : 0) + params_line_sold_per_extra))
        //合格率
        let pass_per = item.pass_per * 1 + params_pass_per * 1 + (addLine ? item.params_line_pass_per * 1 : 0) + params_line_pass_per_extra;
        // pass_per = pass_per >= 100 ? 100 : (pass_per <= 0 ? 0 : pass_per)
        item.pass_per = numberToFixed(pass_per)
        //产量
        item.pass_sold_num = numberToFixed(checkZero(item.pass_per) ? 0 : item.sold_num / (item.pass_per / 100))
        //不含税价

        item.price = item.price * (1 + params_tax_per / 100 + (addLine ? item.params_line_tax_per / 100 : 0) + params_line_tax_per_extra)

        item.price_come_in = item.price * item.sold_num

        //加成后税费
        item.price_add_tax = numberToFixed(item.price_add_tax * (1 + params_tax_per / 100 + (addLine ? item.params_line_tax_per / 100 : 0) + params_line_tax_per_extra))
        item.sold_come_in = numberToFixed(item.price_add_tax * item.sold_num)

        //变动成本加成
        item.material_cost = numberToFixed(item.material_cost * (1 + params_cost_per / 100 + (addLine ? item.params_line_cost_per / 100 : 0) + params_line_cost_per_extra))

        return item
    })
}



// 按行测试数据 excel 预测以前的表
export const fnc_excelData_yc_line_test = (paramData, {
    params_sold_per = 0,
    params_pass_per = 0,
    params_tax_per = 0,
    params_cost_per = 0,
}) => {
    const value = JSON.parse(JSON.stringify(paramData))
    let newExcelData = []

    value.map((item) => {
        const no = item.no

        //销量加成
        item.sold_num = numberToFixed(item.sold_num * (1 + params_sold_per / 100))
        if ("__sold_num" in item) { }
        //合格率
        item.pass_per = numberToFixed(item.pass_per * 1 + params_pass_per * 1)
        //产量
        item.pass_sold_num = numberToFixed(checkZero(item.pass_per) ? 0 : item.sold_num / (item.pass_per / 100))
        //不含税价
        item.price = item.price * (1 + params_tax_per / 100)

        //不含税收入
        item.price_come_in = item.price * item.sold_num
        if ("__sold_num" in item) { item.__price_come_in = item.__price * item.__sold_num }

        //加成后税费
        item.price_add_tax = numberToFixed(item.price_add_tax * (1 + params_tax_per / 100))
        if ("__sold_num" in item) { }

        item.sold_come_in = numberToFixed(item.price_add_tax * item.sold_num)
        if ("__sold_num" in item) { item.__sold_come_in = numberToFixed(item.__price_add_tax * item.__sold_num) }

        //变动成本加成
        item.material_cost = numberToFixed(item.material_cost * (1 + params_cost_per / 100))

        if ("__sold_num" in item) { }

        newExcelData.push(item)
        // fnc_excelData_yc_extraData(no, item, newExcelData)

    })
    // console.error(newExcelData)

    return fnc_excelData_yc_newData(newExcelData)
}

// excel预测以前的表
export const fnc_excelData_yc_before = (paramData, {
    params_sold_per = 0,
    params_pass_per = 0,
    params_tax_per = 0,
    params_cost_per = 0,
}) => {
    const value = JSON.parse(JSON.stringify(paramData))
    let newExcelData = {}

    value.map((item) => {
        const no = item.no

        //销量加成
        item.sold_num = numberToFixed(item.sold_num * (1 + params_sold_per / 100))
        if ("__sold_num" in item) { }
        //合格率
        item.pass_per = numberToFixed(item.pass_per * 1 + params_pass_per * 1)
        //产量
        item.pass_sold_num = numberToFixed(checkZero(item.pass_per) ? 0 : item.sold_num / (item.pass_per / 100))
        //不含税价
        item.price = item.price * (1 + params_tax_per / 100)

        //不含税收入
        item.price_come_in = item.price * item.sold_num
        if ("__sold_num" in item) { item.__price_come_in = item.__price * item.__sold_num }

        //加成后税费
        item.price_add_tax = numberToFixed(item.price_add_tax * (1 + params_tax_per / 100))
        if ("__sold_num" in item) { }

        item.sold_come_in = numberToFixed(item.price_add_tax * item.sold_num)
        if ("__sold_num" in item) { item.__sold_come_in = numberToFixed(item.__price_add_tax * item.__sold_num) }

        //变动成本加成
        item.material_cost = numberToFixed(item.material_cost * (1 + params_cost_per / 100))

        if ("__sold_num" in item) { }

        fnc_excelData_yc_extraData(no, item, newExcelData)

    })


    return fnc_excelData_yc_newData(newExcelData)
}



// 处理excel预测之后的表
export const fnc_excelData_yc_after = (paramData, {
    params_sold_per = 0,
    params_pass_per = 0,
    params_tax_per = 0,
    params_cost_per = 0,
}) => {
    const value = JSON.parse(JSON.stringify(paramData))
    let newExcelData = {}
    // console.log('?>>>>>>>>>>>> excelDataChange <<<<<<<<<<<<<<')
    value.map((itemData) => {
        const item = { ...itemData }
        const no = item.no

        //销量加成
        item.sold_num = numberToFixed(item.sold_num * (1 + params_sold_per / 100 + item.params_line_sold_per / 100))
        //合格率
        item.pass_per = numberToFixed(item.pass_per * 1 + params_pass_per * 1 + item.params_line_pass_per * 1)
        //产量
        item.pass_sold_num = numberToFixed(checkZero(item.pass_per) ? 0 : item.sold_num / (item.pass_per / 100))
        //不含税价
        item.price = item.price * (1 + params_tax_per / 100 + item.params_line_tax_per / 100)
        // console.log(item.price)
        item.price_come_in = item.price * item.sold_num

        //加成后税费
        item.price_add_tax = numberToFixed(item.price_add_tax * (1 + params_tax_per / 100 + item.params_line_tax_per / 100))
        item.sold_come_in = numberToFixed(item.price_add_tax * item.sold_num)

        //变动成本加成
        item.material_cost = numberToFixed(item.material_cost * (1 + params_cost_per / 100 + item.params_line_cost_per / 100))

        fnc_excelData_yc_extraData(no, item, newExcelData)

    })

    return fnc_excelData_yc_newData(newExcelData)
}

//数组取索引转对象
export const arrayToObj = (data, field) => {
    let obj = {}
    data.map(function (item) {
        obj[item[field]] = item
    })
    return obj;
}


export const getMindValue = (xAxisCP, min, max) => {
    const xAxis = JSON.parse(JSON.stringify(xAxisCP))
    let letMin = false
    let letMax = false

    let forminIndex = 0;

    for (var i = forminIndex; i < xAxis.length; i++) {
        const xValue = xAxis[i]
        if (letMin === false && parseFloat(xValue) >= parseFloat(min)) {
            letMin = xValue
        }
        if (letMax === false && parseFloat(xValue) >= parseFloat(max)) {
            letMax = xAxis[i]
            forminIndex = i
        }

    }

    if (!letMax && !letMin) {
        return { letMax: 0, letMin: 0 };
    }
    if (letMax === false) {
        letMax = xAxis[i - 1]
    }
    return { letMax, letMin }
}


//取掉空格
export const trimStr = (content) => {
    if (!content) {
        return ""
    }
    return content.replace(/^\s+|\s+$/g)
}

//echart图标
export const echartGraphic = (isMobile = false) => {
    return isMobile ? [
        {
            type: 'group',
            rotation: Math.PI / 4,
            bounding: 'raw',
            right: 20,
            bottom: 20,
            z: 100,
            children: [
                {
                    type: 'rect',
                    left: 'center',
                    top: 'center',
                    z: 100,
                    shape: {
                        width: 100,
                        height: 20
                    },
                    style: {
                        fill: 'rgba(0,0,0,0.1)'
                    }
                },
                {
                    type: 'text',
                    left: 'center',
                    top: 'center',
                    z: 100,
                    style: {
                        fill: '#fff',
                        text: '翎 谋',
                        font: 'bold 8px sans-serif'
                    }
                }
            ]
        },
    ] : [
        {
            type: 'group',
            rotation: Math.PI / 4,
            bounding: 'raw',
            right: 20,
            bottom: 20,
            z: 100,
            children: [
                {
                    type: 'rect',
                    left: 'center',
                    top: 'center',
                    z: -100,
                    shape: {
                        width: 150,
                        height: 24
                    },
                    style: {
                        fill: 'rgba(0,0,0,0.1)'
                    }
                },
                {
                    type: 'text',
                    left: 'center',
                    top: 'center',
                    z: -100,
                    style: {
                        fill: '#fff',
                        text: '翎 谋',
                        font: 'bold 14px sans-serif'
                    }
                }
            ]
        },

    ];
}

//排序
export const tableSortFnc = (field, symbol = "number") => {
    return {
        sorter: {
            compare: (a, b) => {
                if (symbol != "number") {
                    const strFnc = (str) => {
                        return (str || '') + ''
                    }
                    return (strFnc(b[field])).localeCompare(strFnc(a[field]))
                } else {
                    return parseFloat(b[field]) - parseFloat(a[field])
                }
            },
            // multiple: 3,
        }
    }
}

//检查两组数据是否一样
export const checkArrayIdentical = (arr1, arr2) => {
    return arr1.length > 0 && arr1.length === arr2.length && arr1.every((v, i) => arr2.indexOf(v) > -1)
}

//使用平台
export const getUsePlatform = () => {

    let platform = "pc";
    var userAgentInfo = navigator.userAgent;
    var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var screenHeight = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    //判断是否是手机
    // /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isMobile = /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isTablet = /iPad|Macintosh/i.test(userAgentInfo);
    console.log('>>>', userAgentInfo, '///', isMobile, isTablet)
    // 判断是否包含"iPad"关键词（表示平板）
    if (isTablet) {
        platform = "tablet";

    } else {
        platform = "mobile";
    }

    return platform
}
//横竖屏
export const getUseScreen = () => {

    var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    let origin = "vertical"
    if (screenWidth > screenHeight) {
        origin = "landscape";

    }

    return origin
}
// 时间格式化
export const dateFormatter = (formatter, date) => {
    date = (date ? new Date(date) : new Date)
    const Y = date.getFullYear() + '',
        M = date.getMonth() + 1,
        D = date.getDate(),
        H = date.getHours(),
        m = date.getMinutes(),
        s = date.getSeconds()
    return formatter.replace(/YYYY|yyyy/g, Y)
        .replace(/YY|yy/g, Y.substr(2, 2))
        .replace(/MM/g, (M < 10 ? '0' : '') + M)
        .replace(/DD/g, (D < 10 ? '0' : '') + D)
        .replace(/HH|hh/g, (H < 10 ? '0' : '') + H)
        .replace(/mm/g, (m < 10 ? '0' : '') + m)
        .replace(/ss/g, (s < 10 ? '0' : '') + s)
}

/**
 * pc 根据不同显示屏做chart字体大小
 * yAxis name标题 x轴 y轴 legend toolbox图标 tooltip markLine
 */
export const chartNowSize = (val, initWidth = 1920) => { //基配是1920*1080，initWidth值可根据自己需求进行修改
    var nowClientWidth = document.documentElement.clientWidth
    return val * (nowClientWidth / initWidth);
} 