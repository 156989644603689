<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SvgIcon',
})
</script>
<template>
    <span class="anticon">
        <svg class="iconfont" aria-hidden="true" :width="size" :height="size" :color="color">
            <use :xlink:href="'#' + name"></use>
        </svg>

    </span>
</template>

<script lang="ts" setup name="SvgIcon">
// 注意svg文件的<svg> 标签上必须有 fill="currentColor" 字段,这样才能从外部的span等标签复制颜色,否则颜色不可变
// import { computed } from 'vue'
import '../../assets/iconfont/iconfont.js';
const props = defineProps({
    prefix: {
        type: String,
        default: '#'
    },
    name: {
        type: String,
        required: true
    },
    color: {
        type: String,
        default: ''
    },
    size: {
        type: String,
        default: '20px'
    }
})
// const symbolId = computed(() => `#${props.prefix}${props.name}`)
</script>
<style>
.iconfont {
    fill: currentColor;
}
</style>
