<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Login',
})
</script>
<template>
  <div class="login">
    <div class="login-intro">
      <div class="login-intro-box">
        <!-- <a-carousel>
          <div class="slide" v-for="(item, index) in adImages" :key="index">
            <img :src="item.img" />
          </div>
        </a-carousel>
        <div class="login-intro-text">
          {{ adImages.length }}
          {{ contentIntro }}
        </div> -->
      </div>
      <div style="color: #666" class="login-copyright">
        <div v-html="copyright"></div>
        <!-- Copyright © 2023 翎谋  &nbsp;&nbsp;<a class="text-white" href="http://beian.miit.gov.cn" target="_blank" rel="nofollow">粤ICP备2023099763号-1</a> -->
      </div>
    </div>
    <div class="login-form">
      <div class="login-box">
        <!-- <div class="header">
          <img src="/logo.png" class="logo" alt="logo" />
        </div> -->
        <div v-if="isLogin === 'loginIn'">
          <a-form id="formLogin" class="user-layout-login" @submit="handleSubmit" :model="formRef">
            <div class="header">
              <img src="@/assets/images/logo.png" class="logo" alt="logo" />
            </div>
            <div class="login-title">
              <div class="h4">欢迎来到翎谋管理系统</div>
              <!-- <div class="h6">请使用您的常用手机号作为注册账号</div> -->
              <div>
                没有账号？

                <span style="color: #1e6dff; cursor: pointer" @click="changeIsLogin('signIn')">免费注册</span>
              </div>
            </div>

            <a-tabs :activeKey="customActiveKey" :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }" @change="handleTabClick">
              <!-- 企业用户登录 -->
              <a-tab-pane key="company" tab="企业用户">
                <a-form-item v-bind="validateInfos.username">
                  <a-input type="text" placeholder="请输入手机号" v-model:value="formRef.username">
                    <template #prefix>
                      <UserOutlined :style="{ color: 'rgba(0,0,0,.25)', fontSize: '24px' }" />
                    </template>
                  </a-input>
                </a-form-item>

                <a-form-item v-bind="validateInfos.password">
                  <a-input-password placeholder="请输入密码" v-model:value="formRef.password" autocomplete="new-password">
                    <template #prefix>
                      <LockOutlined :style="{ color: 'rgba(0,0,0,.25)', fontSize: '24px' }" />
                    </template>
                  </a-input-password>
                </a-form-item>
              </a-tab-pane>

              <!-- 个人用户登录 -->
              <a-tab-pane key="person" tab="个人用户">
                <a-form-item v-bind="validateInfos.mobile">
                  <a-input type="text" placeholder="请输入手机号" v-model:value="formRef.mobile">
                    <template #prefix>
                      <UserOutlined :style="{ color: 'rgba(0,0,0,.25)', fontSize: '24px' }" />
                    </template>
                  </a-input>
                </a-form-item>

                <a-form-item v-bind="validateInfos.password">
                  <a-input-password placeholder="请输入密码" v-model:value="formRef.password">
                    <template #prefix>
                      <LockOutlined :style="{ color: 'rgba(0,0,0,.25)', fontSize: '24px' }" />
                    </template>
                  </a-input-password>
                </a-form-item>
              </a-tab-pane>
            </a-tabs>

            <a-form-item>
              <a-checkbox v-model:checked="formRef.agree">
                我已阅读并同意
                <a @click.prevent="$refs.popupContent.open('serverProtocol')">《服务协议》</a>和
                <a @click.prevent="$refs.popupContent.open('protectProtocol_company')" v-if="customActiveKey === 'company'">《企业信息保护政策》</a>
                <a @click.prevent="$refs.popupContent.open('protectProtocol')" v-else>《个人信息保护政策》</a>
              </a-checkbox>
            </a-form-item>

            <a-form-item style="margin-top: 24px">
              <a-button size="large" type="primary" htmlType="submit" class="login-button" :loading="state.loginBtn" :disabled="state.loginBtn">登录</a-button>
            </a-form-item>
            <!-- <div class="user-login-other">
                    <a href="javascript:;" @click="handleShowVerify">
                        会员差异
                    </a>
                    
                </div> -->
            <a-form-item v-bind="validateInfos.rememberMe">
              <!-- <a-checkbox v-model:checked="formRef.rememberMe" style="float:left">
                        下次自动登录
                    </a-checkbox> -->
              <!-- <router-link class="forge-password" style="float: right" :to="{ path: '/user/forgotPassword' }">忘记密码？</router-link> -->
              <div class="forge-password" @click="changeIsLogin('forgotPassword')" style="float: right; color: #1e6dff; cursor: pointer">忘记密码？</div>
            </a-form-item>
          </a-form>
        </div>
        <div v-else-if="isLogin === 'signIn'" class="user-layout-register">
          <div class="user-register-box">
            <div class="register-form">
              <a-form ref="signInFormRef" class="user-layout-login" :rules="rules" layout="vertical" :model="temp">
                <div class="header">
                  <img src="@/assets/images/logo.png" class="logo" alt="logo" />
                </div>
                <div class="login-title" style="margin-bottom: 20px">
                  <div class="h4">欢迎来到翎谋管理系统</div>
                  <div>
                    已有账户？

                    <span style="color: #1e6dff; cursor: pointer" @click="changeIsLogin('loginIn')">直接登录</span>
                  </div>
                </div>
                <a-form-item name="type">
                  <a-input-group compact>
                    <div class="ant-input-group-label">注 册 类 型</div>
                    <a-select v-model:value="temp.type" :bordered="false" placeholder="请选择注册类型">
                      <a-select-option value="0">个人用户</a-select-option>
                      <a-select-option value="1">企业用户</a-select-option>
                    </a-select>
                  </a-input-group>
                </a-form-item>

                <a-form-item name="phone">
                  <a-input :bordered="false" v-model:value="temp.phone" :maxlength="11" placeholder="输入法人手机号">
                    <template #prefix>
                      <div class="ant-input-label addon">中 国 +86</div>
                    </template>
                  </a-input>
                </a-form-item>

                <a-form-item name="email">
                  <a-input :bordered="false" type="text" placeholder="输入公司邮箱" v-model:value="temp.email">
                    <template #prefix>
                      <div class="ant-input-label">邮 箱</div>
                    </template>
                  </a-input>
                </a-form-item>

                <a-form-item name="verify">
                  <a-input-group>
                    <a-input :bordered="false" v-model:value="temp.verify" :maxlength="6" placeholder="输入验证码" style="width: calc(100% - 100px)">
                      <template #prefix>
                        <div class="ant-input-label">手机验证码</div>
                      </template>
                    </a-input>
                    <a-button type="primary" class="getCaptcha" :disabled="signInState.time > 0" @click.stop.prevent="handleSendVerify">{{
                      (!signInState.time && '获取验证码') || signInState.time + ' s'
                    }}</a-button>
                  </a-input-group>
                </a-form-item>

                <a-form-item name="password">
                  <a-input :bordered="false" type="password" placeholder="输入密码" v-model:value="temp.password" autocomplete="new-password">
                    <template #prefix>
                      <div class="ant-input-label">密码</div>
                    </template>
                  </a-input>
                </a-form-item>

                <a-form-item class="noStyle">
                  <a-checkbox v-model:checked="checkNick"
                    >我已阅读并同意 <a @click.prevent="$refs.popupContent.open('serverProtocol')">《服务协议》</a>和
                    <a @click.prevent="$refs.popupContent.open('protectProtocol_company')" v-if="temp.type === '1'">《企业信息保护政策》</a>
                    <a @click.prevent="$refs.popupContent.open('protectProtocol')" v-else>《个人信息保护政策》</a>
                  </a-checkbox>
                </a-form-item>

                <a-button type="primary" htmlType="submit" class="register-button" :loading="registerBtn" @click.stop.prevent="handleSubmitSignIn" :disabled="registerBtn">注 册</a-button>

                <!-- <router-link class="login" :to="{ path: '/user/login' }">使用已有账户登录</router-link> -->
              </a-form>
            </div>
          </div>
        </div>
        <div v-else class="user-layout-register">
          <div class="user-register-box">
            <div class="register-form">
              <a-form id="formRegister" layout="vertical" :model="forgotPasswordForm" class="user-layout-login">
                <div class="login-title" style="margin-bottom: 20px">
                  <div class="h4">找回密码</div>
                  <div>
                    成功找回？

                    <span style="color: #1e6dff; cursor: pointer" @click="changeIsLogin('loginIn')">返回登录</span>
                  </div>
                </div>
                <a-form-item>
                  <a-input-group compact>
                    <div class="ant-input-group-label">账 户 类 型</div>
                    <a-select v-model:value="forgotPasswordForm.account_type" :bordered="false" placeholder="请选择账户类型">
                      <a-select-option value="0">个人用户</a-select-option>
                      <a-select-option value="1">企业用户</a-select-option>
                    </a-select>
                  </a-input-group>
                </a-form-item>
                <a-form-item v-bind="validateFp.validateInfos.phone">
                  <a-input :bordered="false" v-model:value="forgotPasswordForm.phone" placeholder="输入手机号">
                    <template #prefix>
                      <div class="ant-input-label addon">中 国 +86</div>
                    </template>
                  </a-input>
                </a-form-item>

                <a-form-item v-bind="validateFp.validateInfos.verify">
                  <a-input-group>
                    <a-input :bordered="false" v-model:value="forgotPasswordForm.verify" placeholder="输入验证码" style="width: calc(100% - 100px)">
                      <template #prefix>
                        <div class="ant-input-label">手机验证码</div>
                      </template>
                    </a-input>
                    <a-button type="primary" class="getCaptcha" :disabled="forgotPasswordState.smsSendBtn" @click.stop.prevent="getCaptcha">{{
                      (!forgotPasswordState.smsSendBtn && '获取验证码') || forgotPasswordState.time + ' s'
                    }}</a-button>
                  </a-input-group>
                </a-form-item>

                <a-form-item v-bind="validateFp.validateInfos.password">
                  <a-input-password
                    :bordered="false"
                    @click="handlePasswordInputClick"
                    placeholder="请使用英文、符号、数字以上8-16位字符组合"
                    v-model:value="forgotPasswordForm.password"
                    autocomplete="new-password"
                  >
                    <template #prefix>
                      <div class="ant-input-label">设 置 密 码</div>
                    </template>
                  </a-input-password>
                </a-form-item>

                <a-form-item v-bind="validateFp.validateInfos.password2">
                  <a-input-password :bordered="false" placeholder="请再次输入密码" v-model:value="forgotPasswordForm.password2" autocomplete="new-password">
                    <template #prefix>
                      <div class="ant-input-label">确 认 密 码</div>
                    </template>
                  </a-input-password>
                </a-form-item>

                <a-button type="primary" htmlType="submit" class="register-button" @click.stop.prevent="handleSubmitFP">确 认</a-button>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PopupContent ref="popupContent" />
  <PopupVipBox ref="popupVipBox" moduleName="login" />
  <ProjectOpening />
</template>

<script lang="ts" setup name="Login">
import PopupVipBox from '@/components/PopupVipBox.vue'
// import { encryptByMd5 } from '@/utils/encrypt'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { ref, reactive, UnwrapRef, onMounted, getCurrentInstance, watch, nextTick } from 'vue'
import { Form, message, notification } from 'ant-design-vue'
import { loginSuccess, requestFailed, sendSms, checkPasswordLevel, validateEmail, validatePhone, useGetCaptcha } from '@/store/modules/helper'
import { useRouter, useRoute } from 'vue-router'

import ProjectOpening from '@/components/ProjectOpening.vue'
import Reg from './Reg.vue'

import baseService from '@/utils/http/axios'

import ls from '@/utils/Storage'
import dayjs from 'dayjs'

onMounted(() => {
  getAdImage()
})

const { proxy } = getCurrentInstance()!

const isLogin = ref('loginIn')
const checkNick = ref(false)
const registerBtn = ref(false)
const second = ref(0)
const signInFormRef = ref(null) // 引用表单实例

const signInState = reactive({
  time: 0
})

const rules = reactive({
  phone: [{ required: true, message: '请输入手机号码' }, { validator: validatePhone }, { validateTrigger: ['change', 'blur'] }],
  email: [{ required: true, message: '请输邮箱' }, { validator: validateEmail }, { validateTrigger: ['change', 'blur'] }],
  verify: [{ required: true, message: '请输验证码' }, { validateTrigger: ['change', 'blur'] }],
  password: [{ required: true, message: '请输密码' }, { validator: checkPasswordLevel }, { validateTrigger: ['change', 'blur'] }]
})

const temp = reactive({
  type: '0',
  phone: '',
  emial: '',
  verify: '',
  password: ''
})

//广告图事件
const adImages = ref([])
const contentIntro = ref('')
const copyright = ref('')
const getAdImage = () => {
  baseService
    .post('/api/index/defInfo', { type: 'imageLogin' })
    .then((res) => {
      const data = res.data || {}
      adImages.value = data.list || []
      contentIntro.value = data.content_intro || ''
      copyright.value = data.copyright || ''
    })
    .catch((err) => {
      console.error(err)
    })
}

//版本差异
const popupVipBox = ref()
const handleShowVerify = () => {
  popupVipBox.value.open()
}

const useForm = Form.useForm
const router = useRouter()

const route = useRoute()
// temp.type = route.query.type == 'company' ? '1' : '0'

//#region 切换tab
const customActiveKey = ref<string>('company')
const handleTabClick = (key: string) => {
  customActiveKey.value = key
}
//#endregion

const state = reactive({
  time: 60,
  loginBtn: false,
  // login type: 0 email, 1 username, 2 telephone
  loginType: 0,
  smsSendBtn: false
})

// #region 表单相关
const formRef = reactive({
  rememberMe: false,
  username: '',
  password: '',
  mobile: '',
  captcha: '',
  agree: false
})

// 忘记密码表单相关
const forgotPasswordForm = reactive({
  phone: '',
  verify: '',
  type: 'forget',
  account_type: '',
  password: '',
  password2: '',
  checkNick: false
})

const handlePasswordLevel = (rule, value) => {
  if (value === '') {
    return Promise.resolve('请使用三种或三种以上8-16位字符组合')
  }
  if (value.length >= 6) {
  } else {
    return Promise.reject(new Error('密码强度不够'))
  }
  return Promise.resolve()
}

const handlePasswordCheck = (rule, value) => {
  if (value === '') {
    return Promise.resolve('密码需为8-16位字符，包含英文、符号、数字')
  } else if (value !== forgotPasswordForm.password) {
    return Promise.reject('两次密码不一致')
  }
  return Promise.resolve()
}

const forgotPasswordRules = reactive({
  phone: [{ required: true, message: '请输入手机号码' }, { validator: validatePhone }, { validateTrigger: ['change', 'blur'] }],
  verify: [{ required: true, message: '请输验证码' }, { validateTrigger: ['change', 'blur'] }],
  password: [{ required: true, message: '密码需为8-16位字符，包含英文、符号、数字' }, { validator: handlePasswordLevel }, { validateTrigger: ['change', 'blur'] }],
  password2: [{ required: true, message: '请再次输入密码' }, { validator: handlePasswordCheck }, { validateTrigger: ['change', 'blur'] }]
})

const forgotPasswordState = reactive({
  time: 60,
  level: 0,
  smsSendBtn: false,
  percent: 10,
  progressColor: '#FF0000'
})
//

const handleUsernameOrEmail = (rule, value: string) => {
  const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
  if (regex.test(value)) {
    state.loginType = 0
  } else {
    state.loginType = 1
  }
  return Promise.resolve()
}
const rulesRef = reactive({
  rememberMe: [{ trigger: 'checked' }],
  username: [
    { required: true, message: '' },
    { validator: handleUsernameOrEmail, trigger: 'change' }
  ],
  password: [{ required: true, message: '' }, {}],
  mobile: [{ required: true, pattern: /^1[34578]\d{9}$/, message: '', validateTrigger: 'change' }],
  captcha: [{ required: true, message: '', validateTrigger: 'blur' }]
})

const { validate, validateInfos } = useForm(formRef, rulesRef)

const handleSubmit = (e: Event) => {
  if (!formRef.agree) {
    message.error('请先同意协议')
    return
  }

  let url = '/api/account/userLogin'
  let requestBody = {
    account: formRef.mobile,
    password: formRef.password
  }
  if (customActiveKey.value == 'company') {
    url = '/api/account/companyLogin'
    requestBody = {
      account: formRef.username,
      password: formRef.password
    }
  }
  baseService
    .post(url, requestBody)
    .then((res) => {
      const data = res.data || {}

      //清空广告弹窗效果
      const cacheName = ('popVipTypeShow' + dayjs().format('YYYY-MM-DD')).toLocaleUpperCase()
      ls.remove(cacheName)

      loginSuccess(data.access_token || '', data.userInfo || {}, router)
      console.log(data)
    })
    .catch((err) => {
      console.error(err)
    })
  return
}
// #endregion 注册

//切换登录/注册
const changeIsLogin = (e) => {
  isLogin.value = e
  temp.type = customActiveKey.value == 'company' ? '1' : '0'
  forgotPasswordForm.account_type = customActiveKey.value == 'company' ? '1' : '0'
}

//获取验证码
const handleSendVerify = (e) => {
  sendSms(temp.phone, 'reg' + temp.type, signInState)
}

const handleSubmitSignIn = () => {
  if (!checkNick.value) {
    message.error('请同意协议')
    return
  }
  signInFormRef.value
    ?.validate()
    .then((res) => {
      registerBtn.value = true

      baseService
        .post('/api/account/reg', temp)
        .then((res) => {
          message.success('注册成功')
          setTimeout(() => {
            router.replace({ path: '/user/login', params: {} })
          }, 800)
        })
        .finally(() => {
          registerBtn.value = false
        })
    })
    .catch((error) => {
      console.log('error', error)
    })
}

//忘记密码 --- 获取验证码
const getCaptcha = (e) => {
  useGetCaptcha(e, validateFp.validate, forgotPasswordState, forgotPasswordForm)
}

const validateFp = useForm(forgotPasswordForm, forgotPasswordRules)

const handleSubmitFP = () => {
  // fPFormRef
  validateFp.validate().then((res) => {
    baseService
      .post('/api/account/forgetPwd', {
        phone: forgotPasswordForm.phone,
        verify: forgotPasswordForm.verify,
        password: forgotPasswordForm.password,
        password2: forgotPasswordForm.password2,
        account_type: forgotPasswordForm.account_type
      })
      .then((res) => {
        message.success('修改密码成功')
        // changeIsLogin('loginIn')
      })
      .catch((err) => {
        message.error(err)
      })
    // router.push({ name: 'registerResult', params: { ...form } })
  })
}
</script>

<style lang="less" >
@import '../../style/index.less';

.login {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;

  &-intro {
    flex: 1;
    overflow: hidden;
    height: 100%;
    padding: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-carousel {
      width: 100%;

      .slick-slide img {
        max-width: 100%;
        margin: auto;
      }

      .slick-dots {
        position: static;
        overflow: hidden;

        li {
          width: 8px !important;
          height: 8px;

          button {
            height: 100%;
            border-radius: 100px;
          }
        }
      }
    }

    .login-intro-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .login-intro-text {
        width: 70%;
        margin: 40px auto;
        color: #ffffff;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
      }
    }

    .login-copyright {
      position: absolute;
      left: 0;
      bottom: 20px;
      z-index: 9;
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 14px;
    }
  }

  &-form {
    width: 700px;
    // padding: 40px;
    // background: #ffffff;
    // box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-box {
      width: 450px;
      height: auto;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
      position: relative;
      // padding: 20px 0;
    }

    .header {
      // position: absolute;
      // top: 40px;
      // left: 40px;
      margin-top: 40px;
      img.logo {
        // width: 126px;
        width: 86px;
      }
    }
  }
}

.user-layout-login {
  width: 380px;
  margin: 0 auto;

  .login-title {
    // text-align: center;

    .h4 {
      color: #1e6dff;
      font-size: 24px;
      font-weight: bold;
    }

    .h6 {
      color: #666666;
      font-size: 12px;
    }
  }

  .ant-tabs {
    // margin-top: 50px;

    .ant-tabs-nav {
      margin: 0px 0px 32px 0px;
      // margin: 0px 0px 22px 0px;

      &::before {
        display: none;
      }

      .ant-tabs-nav-wrap {
        justify-content: center;

        .ant-tabs-tab {
          font-size: 16px;

          & + .ant-tabs-tab {
            margin: 0px 0px 0px 64px;
          }
        }
      }
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 5px;
  }

  .ant-input {
    // height: 44px !important;
    height: 34px !important;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 54px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    // height: 54px;
    height: 44px;
    width: 100%;
  }

  .user-login-other {
    text-align: center;
    margin-top: 24px;
    line-height: 22px;

    .anticon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }

    .register {
      float: right;
    }
  }
}

.user-register {
  &.error {
    color: #ff0000;
  }

  &.warning {
    color: #ff7e05;
  }

  &.success {
    color: #52c41a;
  }
}

.user-layout-register {
  .ant-input-group-addon:first-child {
    // background-color: #fff;
  }

  .register-form {
    .ant-form-item {
      border: 1px solid #d9d9d9;
      border-radius: 5px;

      &.noStyle {
        border: none;
      }

      .ant-select {
        flex: 1;
        width: 100%;
        padding: 8px 0px;

        .ant-select-selector {
          padding: 0px 16px;
        }
      }

      .ant-input-group {
        display: flex;
        align-items: center;
      }

      .ant-input-group-compact {
        padding: 3px 0px;
      }

      .ant-input {
        padding: 12px 16px;
      }
    }
  }

  .picture-upload {
    display: flex;

    .ant-upload-wrapper.ant-upload-picture-card-wrapper {
      width: auto;

      .ant-upload.ant-upload-select {
        width: auto;
        height: auto;
      }
    }

    .picture-example {
      width: 210px;

      .picture-example-thumbnail {
        border: 1px dashed #d9d9d9;
        border-radius: 2px;
      }

      .picture-example-name {
        text-align: center;
        margin-top: 8px;
      }
    }

    .picture-button {
      width: 210px;
      height: 145px;
      background-color: #f6f9ff;

      .picture-button-cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>



<style lang="less" scoped>
.user-layout-register {
  // width: 1200px;
  min-height: 100%;
  padding: 2.5% 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .user-register-box {
    width: 100%;
    padding: 12px;
    // background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .register-steps {
      width: 100%;
      padding: 24px 16%;
      border-bottom: 1px solid #d9d9d9;
    }

    .register-form {
      // width: 640px;
      // padding: 48px 0px 24px;
    }
  }

  .ant-input-label {
    min-width: 78px;
    text-align: justify;
    height: 100%;
    display: flex;
    align-items: center;

    &.addon {
      border-right: 1px solid #d9d9d9;
    }
  }

  .ant-input-group-label {
    min-width: 72px;
    text-align: justify;
    padding: 0px 0px 0px 11px;
    margin-inline-end: 4px;
  }

  .getCaptcha {
    display: block;
    width: 200px;
    height: 54px;
  }

  .register-button {
    width: 100%;
    height: 54px;
    font-size: 16px;
  }

  .login {
    float: right;
    line-height: 40px;
  }
}
</style>