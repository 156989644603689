<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserLayout',
})
</script>
<template>
  <div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
    <div class="container" style="margin: 0">
      <div class="user-layout-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup name="UserLayout">
import { isMobile } from '@/utils/device'
import { onMounted, onBeforeUnmount } from 'vue'

onMounted(() => {
  document.body.classList.add('userLayout')
})
onBeforeUnmount(() => {
  document.body.classList.remove('userLayout')
})
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98% !important;
      }
    }
  }

  .container {
    width: 100%;
    height: 100vh;
    background: #f0f2f5 url(../assets/login_images/login_background4.png) no-repeat;
    background-size: cover;
    //padding: 50px 0 84px;
    position: relative;

    .user-layout-content {
      height: 100%;
      overflow-y: auto;

      .footer {
        // position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0 16px;
        margin: 48px 0 24px;
        text-align: center;

        .links {
          margin-bottom: 8px;
          font-size: 14px;

          a {
            color: rgba(0, 0, 0, 0.45);
            transition: all 0.3s;

            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }

        .copyright {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
        }
      }
    }

    a {
      text-decoration: none;
    }
  }
}
</style>
