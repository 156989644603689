<template>
  <a-modal v-model:open="show" title="版本差异"  :footer="null" width="75%" style="top: 4%" :bodyStyle="{maxHeight:'none'}" :keyboard="false" :maskClosable="false">
    <div class="moadl-box_btns">
        <a-button type="text" @click="handleCancel">不再提示</a-button>
    </div>
    <PopupVipTable :limitVipType="getLimitVipType" :moduleName="moduleName" @close="close"/>
    
  </a-modal>
</template>

<script>
import PopupVipTable from '@/components/PopupVipTable.vue'
import { notification } from 'ant-design-vue';
import ls from '@/utils/Storage'
import dayjs from 'dayjs';
export default {
  props:{
    moduleName:{
      type:String,
      default:''
    },
  },
  components:{
    PopupVipTable
    },
  data() {
    return {
      title: '',
      content: '',
      showWay: '',
      show: false
    }
  },
  computed:{
    getLimitVipType(){
      let limitType = ['vip_diff','vip_company_master','vip_company_common','vip_person','vip_free'];
      // if(['company','company_member'].indexOf(this.$store.state.user.user_ident)>-1){
      //   limitType = ['vip_diff','vip_company_master','vip_company_common'];
      // }else if(this.$store.state.user.user_ident=='person'){
      //   limitType = ['vip_diff','vip_person','vip_free'];
      // }
      return limitType;
    },
  },
  mounted(){

    // this.$network('/api/user/setting',).then(res => {
    //     const data = res.data||{}
    //     this.show = data.ad_state_vip==1
    // });

    // if(!this.moduleName){
    //   const cacheName = ("popVipTypeShow"+dayjs().format('YYYY-MM-DD')).toLocaleUpperCase();
    //   if(['vip_person','vip_company_common','vip_company_master'].indexOf(this.$store.state.user.vip_type)>-1){
    //     //vip用户

    //   }else{
    //     //今天是否弹出过
    //     var cacheValue = ls.get(cacheName);
    //     if(!cacheValue){
    //       this.show = true
    //       ls.set(cacheName,1,86400)
    //     }
    //   }
    // }
  },
  methods: {
    open(){
      this.show = true
    },
    close(){
      this.show = false
      this.$emit('close')
    },
    handleCancel(){
      let obj = {ad_state_vip: 0};
      this.$network('/api/user/modifyInfo',obj).then(res => {
        notification.success({  description: '可前往账户设置再次开启vip提醒'});
        this.close()
      }).catch(err => {
          console.error(err)
      })
    },
    actionDialog(){
      this.open()
    },
  }
}
</script>


<style lang="less" scoped>
    .moadl-box_btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 12px;
        right: 60px;
        .ant-btn {
            width: 110px;
            height: 34px;
        }

        .ant-btn+.ant-btn {
            margin-top: 12px;
        }
    }
</style>
