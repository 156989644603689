<template>
  <div class="setting-drawer">
    <a-drawer
      width="300"
      placement="right"
      @close="close"
      :closable="false"
      :visible="visible"
    >
        <div class="setting-drawer-index-content">
            <!-- 主题色 -->
            <ThemeColor />
        </div>

        <div class="setting-theme-color"></div>

    </a-drawer>
  </div>
</template>

<script>
import ThemeColor from './components/ThemeColor.vue'
export default {
  components:{
    ThemeColor
  },
  data(){
    return {
      visible:false,
    }
  },
  methods:{
    open(){
      this.visible = true
    },
    close(){
      this.visible = false
    }
  }
}

// import { ref, reactive, computed, onMounted, toRaw } from 'vue'

// import ThemeColor from './components/ThemeColor.vue'

// const visible = ref(false)
// const open = () => {
//     visible.value = true
// }
// const close = () => {
//     visible.value = false
// }

// defineExpose({
//     open
// })
</script>

<style lang="less" scoped>

  .setting-drawer-index-content {

    .setting-drawer-index-blockChecbox {
      display: flex;

      .setting-drawer-index-item {
        margin-right: 16px;
        position: relative;
        border-radius: 4px;
        cursor: pointer;

        img {
          width: 48px;
        }

        .setting-drawer-index-selectIcon {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          padding-top: 15px;
          padding-left: 24px;
          height: 100%;
          color: #1890ff;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
    .setting-drawer-theme-color-colorBlock {
      width: 20px;
      height: 20px;
      border-radius: 2px;
      float: left;
      cursor: pointer;
      margin-right: 8px;
      padding-left: 0px;
      padding-right: 0px;
      text-align: center;
      color: #fff;
      font-weight: 700;

      i {
        font-size: 14px;
      }
    }
  }

  .setting-drawer-index-handle {
    position: absolute;
    top: 240px;
    background: #1890ff;
    width: 48px;
    height: 48px;
    right: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: auto;
    z-index: 1001;
    text-align: center;
    font-size: 16px;
    border-radius: 4px 0 0 4px;

    i {
      color: rgb(255, 255, 255);
      font-size: 20px;
    }
  }


</style>
