<template>
  <a-spin v-if="loading" :spinning="loading" size="large" class="full-page-loading" :tip="content">
     
  </a-spin>
</template>

<script>
export default {
    name:'PageLoading',
    data(){
        return {
            content:'loading',
            loading:false,
        }
    },
    methods:{
        open(content){
            this.loading = true
            this.content = content
        },
        close(){
            this.loading = false
        },
        text(content){
            this.content = content
        }
    }
}
</script>

<style scoped>
.full-page-loading{
    position: fixed;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
    z-index: 9999 !important;
    font-size: 22px;
    color: white;
}
</style>