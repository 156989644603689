<template>
    <a-modal title="扩增成员申请表" :width="900" :open="visible" :confirmLoading="loading" :footer="null" @ok="handleSubmit"
        @cancel="close">
        <a-spin :spinning="loading">
            <div class="tips">
                {{ $store.state.project.company_member_req_intro }}
            </div>

            <a-form ref="formRef" :model="form" :rules="rules" v-bind="formItemLayout">
                <a-form-item label="联系人" name="name">
                    <a-input v-model:value="form.name" placeholder="必填" />
                </a-form-item>
                <a-form-item label="联系电话" name="tel">
                    <a-input v-model:value="form.tel" placeholder="必填" />
                </a-form-item>
                <a-form-item label="扩增人数" name="num">
                    <a-input v-model:value="form.num" placeholder="必填" />
                </a-form-item>

                <a-form-item label="申请理由" name="content">
                    <a-textarea v-model:value="form.content" placeholder="请详细输入扩增理由，方便工作人员审核..."
                        :auto-size="{ minRows: 5, maxRows: 8 }" />
                </a-form-item>

                <a-form-item v-bind="formTailLayout">
                    <a-button type="primary" @click="handleSubmit">提交申请</a-button>
                </a-form-item>
            </a-form>


        </a-spin>
    </a-modal>
</template>

<script lang="ts" setup>
import { ref, reactive, computed, onMounted, toRaw } from 'vue'
import baseService from '@/utils/http/axios'

import { message } from 'ant-design-vue';

import type { UnwrapRef } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';

const loading = ref(true)
const visible = ref(false)

const open = () => {
    visible.value = true
    loading.value = false
}
const close = () => {
    visible.value = false
}

defineExpose({
    open
})

// 表单
interface form {
    name: string;
    tel: string;
    num: string;
    content: string
}
const formRef = ref();
const form: UnwrapRef<form> = reactive({
    name: '',
    tel: '',
    num: '',
    content: '',
});
const rules: Record<string, Rule[]> = {
    name: [
        { required: true, message: '请填写联系人', trigger: 'change' },
    ],
    tel: [
        { required: true, message: '请填写联系电话', trigger: 'change' },
    ],
    num: [
        { required: true, message: '请填写扩增人数', trigger: 'change' },
    ],
    content: [
        { required: true, message: '请填写申请理由', trigger: 'change' },
    ],
};
const formItemLayout = {
    labelCol: { span: 3 },
};
const formTailLayout = {
    labelCol: { span: 3 },
    wrapperCol: { offset: 3 },
};


const handleSubmit = () => {
    formRef.value.validate().then(() => {
        baseService.post('/api/company/reqMember', form).then(res => {
            message.success(res.msg)
            setTimeout(() => {
                close()
            }, 700);
        }).catch(err => {
            console.error(err)
        })
    })
};

</script>
<style lang="less" scoped>
.ant-form-text {
    font-size: 14px;
    font-weight: bold;
}
</style>
