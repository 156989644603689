<template>
  <div id="solutionTemplatePDFExtra" class="solutionTemplatePDF">
    <a-watermark :content="getWaterName">
      <div class="solution-box">
        <div class="headBox">
          <div class="headBoxBg">
            <img src="@/assets/images/solution_img.png" />
            <div class="headBoxLogo"><img :src="getPlanLogo" class="logo" alt="logo" /></div>
          </div>
          <div class="headBoxContent">
            <div class="headTitle">
              <div class="title">{{ getPlanName }}</div>
              <div class="subtitle">{{ getPlanName_en }}</div>
            </div>
            <div class="author">
              <div class="author_name">{{ getWaterName }}</div>
              <div class="author_info">
                <span>编制人：{{ userInfo.name }}</span>
                <span>编制时间：{{ detail.created_at }}</span>
                <span>打印次数：{{ detail.times }}次</span>
              </div>
            </div>
          </div>
          <div class="headTypeText">
            <VipTag :isText="true" moduleName="printPDF" />
            <span>扩展方案</span>
          </div>
        </div>

        <div class="solution-wrap">
          <div class="solution-first">
            <div class="solution-children">
              <div class="title"><span>工具指引</span></div>
              <div class="row">
                <div class="RichText">
                  <tools-item-intro type="two" field="intro_extra" moduleName="background" />
                </div>
              </div>
            </div>
            <!-- 1 整体参数 -->
            <div class="solution-children">
              <a-row :gutter="100">
                <a-col :span="9">
                  <div class="title"><span>参数调整</span><em>*行参数调整详见附表明细</em></div>
                  <div class="row">
                    <a-descriptions :column="1" size="small" bordered :labelStyle="{ width: '240px' }">
                      <a-descriptions-item label="含税售价整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_tax_per" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                      <a-descriptions-item label="销量整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_sold_per" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                      <a-descriptions-item label="单位变动成本整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_cost_per" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                      <a-descriptions-item label="产品合格率整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_pass_per" :max="100" :step="0.5" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                    </a-descriptions>
                  </div>
                </a-col>
                <a-col :span="15">
                  <div class="subtitle"><span>工具假设</span></div>
                  <div class="row">
                    <div class="RichText">
                      <tools-item-intro type="two" field="intro_extra" moduleName="background" />
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <!-- 3 散点图 -->
            <div class="solution-children">
              <div class="title"><span>散点图</span></div>
              <a-row :gutter="200">
                <a-col :span="12">
                  <div class="subtitle nobo"><span>计划</span></div>
                  <div class="row">
                    <img class="canvas" :src="svpScatterBeforeImg" />
                  </div>
                </a-col>
                <a-col :span="12" v-show="hasActualityData">
                  <div class="subtitle nobo"><span>实际</span></div>
                  <div class="row">
                    <img class="canvas" :src="svpScatterAfterImg" />
                  </div>
                </a-col>
              </a-row>
            </div>

            <!-- 6.0成本中心多级利润表 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="12">
                  <div class="row" id="planMLLR-descriptions"></div>
                </a-col>
                <a-col :span="12">
                  <div class="row" id="planMLLR-tableList"></div>
                </a-col>
              </a-row>
            </div>

            <!-- 备注 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="24">
                  <div class="title"><span>编制备注</span></div>
                  <div class="row">
                    <div class="RichText">
                      {{ remark_extra }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>

            <!-- 方案解读 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="24">
                  <div class="title"><span>方案解读</span></div>
                  <div class="row">
                    <div class="RichText">
                      {{ planResult_extra }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div class="solution-children">
              <p class="tips">*附表见下页</p>
            </div>
          </div>

          <!-- 收发存报表（客户产品级） -->
          <div class="solution-children">
            <p class="tip">附表</p>
            <a-row :gutter="200">
              <a-col :span="24">
                <div class="title"><span>收发存报表（客户产品级）</span></div>
                <div class="row" id="planSFCBB"></div>
              </a-col>
            </a-row>
          </div>

          <!-- 齐套率明细表（内部运营指标） -->
          <div class="solution-children">
            <a-row :gutter="200">
              <a-col :span="24">
                <div class="title"><span>齐套率明细表（内部运营指标）</span></div>
                <div class="row" id="planQTLMX"></div>
              </a-col>
            </a-row>
          </div>

          <!-- 投入产出表（成本） -->
          <div class="solution-children">
            <a-row :gutter="200">
              <a-col :span="24">
                <div class="title"><span>投入产出表（成本）</span></div>
                <div class="row" id="planTRCC"></div>
              </a-col>
            </a-row>
          </div>

          <!-- 基础数据 -->
          <div class="solution-children">
            <a-row :gutter="100">
              <a-col :span="12">
                <div class="title"><span>计划数据表</span></div>
                <div class="row">
                  <div id="scatterTableDataPlan"></div>
                </div>
              </a-col>
              <a-col :span="12" v-show="hasActualityData">
                <div class="title"><span>实际数据表</span></div>
                <div class="row">
                  <div id="scatterTableDataActuality"></div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </a-watermark>
  </div>
</template>

<script>
import VipTag from '@/components/VipTag.vue'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import { mapGetters } from 'vuex'
import { message } from 'ant-design-vue'
import { htmlToPDF } from '@/utils/html2pdf'
import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'
//打印模板
export default {
  name: 'solutionTemplatePDF',
  components: {
    VipTag,
    SliderInputBlock,
    ToolsItemIntro
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    detail: {
      type: Object,
      default: function () {
        return {}
      }
    },
    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    },
    remark_extra: {
      type: String,
      default: ''
    },
    planResult_extra: {
      type: String,
      default: ''
    },
    planType: {
      type: String,
      default: ''
    },
    hasActualityData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      svpScatterBeforeImg: '',
      svpScatterAfterImg: ''
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    }
  },
  created() {},
  mounted() {},

  methods: {
    async downPDF(pageObj) {
      await this.svpScatterBefore(pageObj)
      if (this.hasActualityData) {
        await this.svpScatterAfter(pageObj)
      }
      await this.planSFCBB(pageObj)
      await this.planQTLMX(pageObj)
      await this.planTRCC(pageObj)
      await this.planMLLR(pageObj)

      await this.solutionBaseTable(pageObj)
    },
    //保存图-散点图加成前
    async svpScatterBefore(pageObj) {
      this.svpScatterBeforeImg = pageObj.$refs.svpScatterPlan.generateImage()
    },
    //保存图-散点图加成后
    async svpScatterAfter(pageObj) {
      this.svpScatterAfterImg = pageObj.$refs.svpScatterActuality.generateImage()
    },
    //收发存报表（客户产品级）
    async planSFCBB(pageObj) {
      document.getElementById('planSFCBB').appendChild(pageObj.$refs.planSFCBB.$refs.tableList.$el)
    },
    //齐套率明细表（内部运营指标）
    async planQTLMX(pageObj) {
      document.getElementById('planQTLMX').appendChild(pageObj.$refs.planQTLMX.$refs.tableList.$el)
    },
    //投入产出表
    async planTRCC(pageObj) {
      document.getElementById('planTRCC').appendChild(pageObj.$refs.planTRCC.$refs.tableList.$el)
    },
    //成本中心多级利润表
    async planMLLR(pageObj) {
      document.getElementById('planMLLR-descriptions').appendChild(pageObj.$refs.planMLLR_plan.$el)
      if (this.hasActualityData) {
        document.getElementById('planMLLR-tableList').appendChild(pageObj.$refs.planMLLR_actuality.$el)
      }
    },
    //表格
    async solutionBaseTable(pageObj) {
      document.getElementById('scatterTableDataPlan').appendChild(pageObj.$refs.svpScatterPlan.$refs.tableScatter)
      document.getElementById('scatterTableDataActuality').appendChild(pageObj.$refs.svpScatterActuality.$refs.tableScatter)
    }
  }
}
</script>

<style lang="less" >
#solutionTemplatePDFExtra {
  .solution-box {
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
      padding: 6px 12px;
      font-size: 18px;
    }
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
      padding: 6px 5px;
    }
    .ant-table .ant-table-container .ant-table-cell {
      font-size: 16px;
    }
    .solution-wrap {
      .solution-children {
        .row {
          > img.canvas {
            height: 600px;
            margin: auto;
          }
        }
      }
    }
  }
}
</style>
