<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'works',
})
</script>
<template>
    <div class="works scroll">
        <div class="vabTabsBox">
            <div class="nav-tabs">
                <div class="nav-tabs_item active">
                    <router-link :to="{ path: '/works/level' }">
                        <span>我的等级</span>
                    </router-link>
                </div>
                <div class="nav-tabs_item">
                    <router-link :to="{ path: '/works/list/asks' }">
                        <span>内容创作</span>
                    </router-link>
                </div>
                <!-- <div class="nav-tabs_item">
                    <router-link :to="{ path: '/works/income' }">
                        <span>收益变现</span>
                    </router-link>
                </div> -->
            </div>
            <div class="group">
                <div class="label">职衔方向：</div>
                <template v-if="!(store.state.user || {}).ident">
                    <a-radio-group v-model:value="ident" @change="handleChange">
                        <a-radio value="manager">管理方向</a-radio>
                        <a-radio value="skill">技术方向</a-radio>
                    </a-radio-group>
                    <a-button type="primary" @click="handleSubmit">保存</a-button>
                </template>
                <template v-else>
                    <a-radio-group v-model:value="ident" disabled>
                        <a-radio value="manager">管理方向</a-radio>
                        <a-radio value="skill">技术方向</a-radio>
                    </a-radio-group>
                    <em>说明：职衔方向变更需要填写 <a @click="handleChangeJob">变更申请</a> </em>
                </template>

            </div>
            <div class="action"  @click="$refs.popupContent.open('userLevelUpIntro')">
                <a>升级规则</a>
            </div>
        </div>
        <div class="body">
            <div class="children">
                <div class="updateCard">
                    <div class="updateCont">
                        <img src="~@/assets/images/level-update_img.jpg" alt="">
                        <div class="updateCont_inner">
                            <div class="updateCont_inner_head">
                                <div class="action">
                                    <p>当前创作等级</p>
                                    <em>LV{{ $store.state.user.level }}</em>
                                </div>
                                <div class="action">
                                    <p>当前职衔</p>
                                    <em>{{ $store.state.user.level_name }}</em>
                                </div>
                            </div>
                            <div class="updateCont_inner_level">
                                <a-progress :percent="$store.state.user.level_per" :size="20" stroke-color="#ffffff"
                                    trail-color="#0163E7" :show-info="false" />
                                <div class="info">
                                    <div class="action">距离下一级还需{{ $store.state.user.reduce_exp }}积分</div>
                                    <div class="action">{{ $store.state.user.level_exp }}/{{ $store.state.user.total_exp }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="updateTable">
                        <div class="title">
                            经验记录
                        </div>
                        <div class="tableWrapper">
                            <a-table bordered :columns="columns" :data-source="list">
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PopupContent ref="popupContent"/>
        <JobModal ref="jobModal" />
    </div>
</template>

<script lang="ts" setup name="works">
import { ref, reactive, computed, onMounted } from 'vue'
import baseService from '@/utils/http/axios'
import { message } from 'ant-design-vue';

import { CaretUpOutlined, SearchOutlined } from '@ant-design/icons-vue';
import SvgIcon from '@/components/SvgIcon/index.vue'

import JobModal from './components/JobModal.vue'
import { useStore } from 'vuex'
const store = useStore()

onMounted(() => {
    getData()
    store.dispatch('userLevel')
})
const ident = ref<string>((store.state.user || {}).ident || '');
const handleChange = (e) => {
    console.log('>>>>>>>>>>>>>>', e, e.target.value)
    ident.value = e.target.value
};

const handleSubmit = () => {

    baseService.post('/api/user/bindUserType', {
        ident: ident.value
    }).then(res => {
        message.success(res.msg);
        store.commit('updateUserIdent', ident.value)
    }).catch(err => {
        console.error(err)
    })
    return
};
// 必须和模板里的 ref 同名
const jobModal = ref(null)
const handleChangeJob = () => {
    jobModal.value.open()
};


const columns = [
    {
        title: '时间',
        dataIndex: 'created_at',
        align: 'center',
        width: '30%'
    },
    {
        title: '积分',
        dataIndex: 'num',
        align: 'center',
        width: '30%'
    },
    {
        title: '原因',
        dataIndex: 'content',
        align: 'center'
    },

];

const list = ref([])
const getData = () => {
    baseService.post('/api/logs/level',).then(res => {
        const data = res.data || {}
        list.value = data.list || []
    }).catch(err => {
        console.error(err)
    })
}

</script>


<style lang="less" scoped>
.updateCard {
    .updateCont {
        position: relative;

        img {
            display: block;
            width: 100%;
            max-height: 350px;
            min-height: 300px;
            object-fit: cover;
        }

        .updateCont_inner {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            padding: 0px 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .updateCont_inner_head {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 40px;

                .action {
                    flex: 1;
                    color: #fff;
                    text-align: center;
                    line-height: 1;

                    p {
                        font-size: 16px;
                    }

                    em {
                        font-size: 34px;
                        font-weight: bold;
                    }
                }
            }

            .updateCont_inner_level {
                width: 100%;

                .info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 10px;

                    .action {
                        color: #fff;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .updateTable {
        .title {
            font-size: 16px;
            font-weight: bold;
            margin: 30px 0 20px;
        }
    }
}
</style>
