<template>
  <div class="pie_echart" :class="isMobile ? 'pie_echart_mobile' : 'pie_echart_pc'">
    <!-- <a-row :gutter="8">
      <a-col :span="8" v-if="usePlan.indexOf('step3-material') > -1">
        <SolutionPieCycle ref="step3Material" isMobile :list="tableList_material" planType="step3-material" />
      </div>
      <div class="pie_echart_item" v-if="usePlan.indexOf('step3-stock') > -1">
        <SolutionPieCycle ref="step3Stock" isMobile :list="tableList_stock" planType="step3-stock" />
      </div>
      <div class="pie_echart_item" v-if="usePlan.indexOf('step3-send-product') > -1">
        <SolutionPieCycle ref="step3SendProduct" isMobile :list="tableList_send_product" planType="step3-send-product" />
      </a-col>
    </a-row> -->
    <!-- <a-row :gutter="8">
      <a-col :span="8" class="pie_echart_item" v-if="usePlan.indexOf('step3-material') > -1">
        <SolutionPieCycle ref="step3Material" isMobile :list="tableList_material" planType="step3-material" />
      </a-col>
      <a-col :span="8" class="pie_echart_item" v-if="usePlan.indexOf('step3-stock') > -1">
        <SolutionPieCycle ref="step3Stock" isMobile :list="tableList_stock" planType="step3-stock" />
      </a-col>
      <a-col :span="8" class="pie_echart_item" v-if="usePlan.indexOf('step3-send-product') > -1">
        <SolutionPieCycle ref="step3SendProduct" isMobile :list="tableList_send_product" planType="step3-send-product" />
      </a-col>
    </a-row> -->
    <SolutionPieCycle ref="step3Material" isMobile :list="tableList_material" planType="step3-material" v-if="usePlan.indexOf('step3-material') > -1" />
    <SolutionPieCycle ref="step3Stock" isMobile :list="tableList_stock" planType="step3-stock" v-if="usePlan.indexOf('step3-stock') > -1" />
    <SolutionPieCycle ref="step3SendProduct" isMobile :list="tableList_send_product" planType="step3-send-product" v-if="usePlan.indexOf('step3-send-product') > -1" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
import { numberToFixed, checkZero, debounce, echartGraphic } from '@/utils/util'

import SolutionPieCycle from './SolutionPieCycle.vue'

const defaultEchartData = []

export default {
  name: 'SolutionPie',
  props: {
    className: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    },

    isPrint: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    },
    planType: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    usePlan: {
      type: Array,
      default: () => []
    },
    initDataState: {
      //数据初始化状态
      type: String,
      default: ''
    },
    tableList_material: {
      type: Array,
      default: () => []
    },
    tableList_stock: {
      type: Array,
      default: () => []
    },
    tableList_send_product: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SolutionPieCycle
  },
  data() {
    return {
      showMixEchart: false,
      allTotal: 0,
      echartData: [],
      tableData: [],
      showField: 'num',
      echartObj: () => {
        return {}
      },
      pageParams: {}
    }
  },
  computed: {},
  watch: {
    initDataState: {
      immediate: true,
      handler(value) {
        if (value === 'complete') {
          //数据初始化完成
          this.tableData = [].concat(this.tableList_material, this.tableList_stock, this.tableList_send_product)
          //this.initEchart()
          this.$nextTick(() => {
            this.setPageParams()

            this.onShowFieldChange()
          })
        }
      }
    }
  },
  mounted() {},
  methods: {
    setPageParams(params, initParams = false) {
      if (initParams) {
        this.pageParams = params
      } else {
        if (this.$refs.step3Material) {
          this.$refs.step3Material.setPageParams('selectDataRange', this.pageParams.material_selectDataRange)
        }
        if (this.$refs.step3Stock) {
          this.$refs.step3Stock.setPageParams('selectDataRange', this.pageParams.stock_selectDataRange)
        }
        if (this.$refs.step3SendProduct) {
          this.$refs.step3SendProduct.setPageParams('selectDataRange', this.pageParams.send_product_selectDataRange)
        }
      }
    },
    getPageParams() {
      const obj = {}
      if (this.$refs.step3Material) {
        obj.material_selectDataRange = this.$refs.step3Material.getPageParams('selectDataRange')
      }
      if (this.$refs.step3Stock) {
        obj.stock_selectDataRange = this.$refs.step3Stock.getPageParams('selectDataRange')
      }
      if (this.$refs.step3SendProduct) {
        obj.send_product_selectDataRange = this.$refs.step3SendProduct.getPageParams('selectDataRange')
      }
      return obj
    },
    initEchart() {
      if (!this.showMixEchart) {
        this.showMixEchart = this.usePlan.length > 1
        this.$nextTick(() => {
          if (this.showMixEchart) {
            this.echartObj = () => {
              return {
                echart: echarts.init(document.getElementById('echart-pie'), null, {
                  renderer: 'canvas',
                  useDirtyRect: false
                })
              }
            }
          }
        })
      }
    },
    onShowFieldChange() {
      if (!this.showMixEchart) {
        return
      }
      // this.calData()
    },
    calData() {
      const dataTypeList = {
        'step3-material': { name: '材料', num: 0, money: 0, children: {} },
        'step3-stock': { name: '成品', num: 0, money: 0, children: {} },
        'step3-send-product': { name: '发出商品', num: 0, money: 0, children: {} }
      }

      const showType = this.showField
      let total = 0
      this.tableData.map((item) => {
        const dataType = item.dataType
        const type = item.type
        if (dataType in dataTypeList) {
          if (!(type in dataTypeList[dataType].children)) {
            dataTypeList[dataType]['children'][type] = {
              name: type,
              num: 0,
              money: 0,
              children: {
                date30: { name: '<30天', value: 0, money: 0, num: 0 },
                date60: { name: '<60天', value: 0, money: 0, num: 0 },
                date90: { name: '<90天', value: 0, money: 0, num: 0 },
                date180: { name: '<180天', value: 0, money: 0, num: 0 },
                date360: { name: '<360天', value: 0, money: 0, num: 0 },
                more360: { name: '>=360天', value: 0, money: 0, num: 0 }
              }
            }
          }
          let date30Field = 'date_30_num'
          let date60Field = 'date_60_num'
          let date90Field = 'date_90_num'
          let date180Field = 'date_180_num'
          let date360Field = 'date_360_num'
          let more360Field = 'date_360_more_num'
          if (showType == 'num') {
            //数量
          } else if (showType === 'money') {
            //金额

            date30Field = 'date_30_money'
            date60Field = 'date_60_money'
            date90Field = 'date_90_money'
            date180Field = 'date_180_money'
            date360Field = 'date_360_money'
            more360Field = 'date_360_more_money'
          }

          total += item[date30Field] * 1 || 0
          total += item[date60Field] * 1 || 0
          total += item[date90Field] * 1 || 0
          total += item[date180Field] * 1 || 0
          total += item[date360Field] * 1 || 0
          total += item[more360Field] * 1 || 0

          const num_30 = item.date_30_num * 1 || 0
          const money_30 = item.date_30_money * 1 || 0

          const num_60 = item.date_60_num * 1 || 0
          const money_60 = item.date_60_money * 1 || 0

          const num_90 = item.date_90_num * 1 || 0
          const money_90 = item.date_90_money * 1 || 0

          const num_180 = item.date_180_num * 1 || 0
          const money_180 = item.date_180_money * 1 || 0

          const num_360 = item.date_360_num * 1 || 0
          const money_360 = item.date_360_money * 1 || 0

          const num_more_360 = item.date_360_more_num * 1 || 0
          const money_more_360 = item.date_360_more_money * 1 || 0
          dataTypeList[dataType].num += num_30 + num_60 + num_90 + num_180 + num_360 + num_more_360
          dataTypeList[dataType].money += money_30 + money_60 + money_90 + money_180 + money_360 + money_more_360
          dataTypeList[dataType]['children'][type].num += num_30 + num_60 + num_90 + num_180 + num_360 + num_more_360
          dataTypeList[dataType]['children'][type].money += money_30 + money_60 + money_90 + money_180 + money_360 + money_more_360
          dataTypeList[dataType]['children'][type]['children'].date30.value += item[date30Field] * 1 || 0
          dataTypeList[dataType]['children'][type]['children'].date30.num += num_30
          dataTypeList[dataType]['children'][type]['children'].date30.money += money_30

          dataTypeList[dataType]['children'][type]['children'].date60.value += item[date60Field] * 1 || 0
          dataTypeList[dataType]['children'][type]['children'].date60.num += num_60
          dataTypeList[dataType]['children'][type]['children'].date60.money += money_60

          dataTypeList[dataType]['children'][type]['children'].date90.value += item[date90Field] * 1 || 0
          dataTypeList[dataType]['children'][type]['children'].date90.num += num_90
          dataTypeList[dataType]['children'][type]['children'].date90.money += money_90

          dataTypeList[dataType]['children'][type]['children'].date180.value += item[date180Field] * 1 || 0
          dataTypeList[dataType]['children'][type]['children'].date180.num += num_180
          dataTypeList[dataType]['children'][type]['children'].date180.money += money_180

          dataTypeList[dataType]['children'][type]['children'].date360.value += item[date360Field] * 1 || 0
          dataTypeList[dataType]['children'][type]['children'].date360.num += num_360
          dataTypeList[dataType]['children'][type]['children'].date360.money += money_360

          dataTypeList[dataType]['children'][type]['children'].more360.value += item[more360Field] * 1 || 0
          dataTypeList[dataType]['children'][type]['children'].more360.num += num_more_360
          dataTypeList[dataType]['children'][type]['children'].more360.money += money_more_360
        }
      })
      this.allTotal = total
      //构造echart数据
      const echartData = []
      Object.values(dataTypeList).map((item) => {
        const itemData = []
        Object.values(item.children).map((itemChild) => {
          const childrenData = Object.values(itemChild.children)
          delete itemChild['children']
          itemData.push({ ...itemChild, children: childrenData })
        })
        delete item['children']
        echartData.push({ ...item, children: itemData })
      })
      console.log(echartData)
      this.echartData = echartData
      this.$nextTick(() => {
        this.drawEchart()
      })
    },
    drawEchart() {
      const labelFormater = (params, dot = 0) => {
        const data = params.data || {}
        const value = params.value
        const money = data.money || '0.00'
        const per = !this.allTotal ? 0 : (value / this.allTotal) * 100
        return params.name + ' (' + per.toFixed(dot) + '%)'
      }
      const option = {
        // title: {
        // text: 'WORLD COFFEE RESEARCH SENSORY LEXICON',
        // subtext: 'Source: https://worldcoffeeresearch.org/work/sensory-lexicon/',
        // textStyle: {
        //   fontSize: 14,
        //   align: 'center'
        // },
        // subtextStyle: {
        //   align: 'center'
        // },
        // sublink: 'https://worldcoffeeresearch.org/work/sensory-lexicon/'
        // },
        tooltip: {
          formatter: (params, ticket, callback) => {
            if (!params.name) {
              return ''
            } else {
              const data = params.data || {}
              return `<div>
                  ${params.name}:<br/>
                    数量：${data.num}<br/>
                    金额：${data.money}<br/>
                </div>`
            }
          }
        },
        series: {
          type: 'sunburst',
          data: this.echartData,
          radius: [0, '95%'],
          sort: undefined,
          emphasis: {
            focus: 'ancestor'
          },
          levels: [
            {},
            {
              r0: '15%',
              r: '35%',
              itemStyle: {
                borderWidth: 2
              },
              label: {
                rotate: 'tangential',
                formatter: (e) => labelFormater(e, 2)
              }
            },
            {
              r0: '35%',
              r: '70%',
              label: {
                align: 'right',
                formatter: labelFormater
              }
            },
            {
              r0: '70%',
              r: '72%',
              label: {
                position: 'outside',
                padding: 3,
                silent: false,
                formatter: labelFormater
              },
              itemStyle: {
                borderWidth: 3
              }
            }
          ]
        }
      }

      // 释放资源
      this.echartObj().echart.setOption(option)
    },
    getPrintInfo() {
      const obj = {}
      if (this.$refs.step3Material) {
        obj.material = this.$refs.step3Material.getPrintInfo()
      }
      if (this.$refs.step3Stock) {
        obj.stock = this.$refs.step3Stock.getPrintInfo()
      }
      if (this.$refs.step3SendProduct) {
        obj.send_product = this.$refs.step3SendProduct.getPrintInfo()
      }
      return obj
    }
  }
}
</script>

<style lang="less">
.pie_echart {
  width: 100%;
  display: flex;
  flex-direction: row;
  &.pie_echart_pc {
    .pie_echart_row {
      display: flex;
      gap: 20px;
      .pie_echart_item {
        width: 25%;
      }
    }
    .pie_cycle_echart {
      width: 100%;
      .pie {
        height: 140px;
      }
    }
  }
  &.pie_echart_mobile {
    .pie_cycle_echart {
      width: 100%;
      .pie {
        height: 100px;
      }
    }
    .pie_echart_row {
      display: flex;
      .pie_echart_item {
        width: 33%;
      }
      &.md {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow-y: auto;
        .pie_echart_item {
          display: inline-block;
          width: 40%;
        }
      }
      &.xs {
        display: block;
        .pie_echart_item {
          width: 100%;
        }
        .pie_cycle_echart {
          width: 100%;
          white-space: nowrap;
          overflow-y: auto;
          .col {
            display: inline-block;
            width: 60%;
          }
        }
      }
    }
  }
}
</style>
