<template>
  <div class="row bom-table">
    <div class="table-children">
      <a-table :pagination="false" bordered :columns="columns" :scroll="{ y: gettableYheight }" :data-source="excelData_yc.slice((currentPage - 1) * pageSize, currentPage * pageSize)" size="small">
        <template #bodyCell="{ column, text, record, index }">
          <template v-if="column.dataIndex.indexOf('bom_money') > -1">
            <div>{{ !text ? '' : $dealThousands(text) }}</div>
          </template>
        </template>
      </a-table>
      <MyTablePagination @change="onPageChange" :total="excelData_yc.length" />
    </div>
  </div>
</template>
  
  <script>
export default {
  name: 'TableBom',
  props: {
    excelData_yc: {
      type: Array,
      required: true
    }
  },
  mixins: [],
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      columns: [
        { title: '产品编码', dataIndex: 'no', width: 80, align: 'center' },
        { title: '产品规格/描述', dataIndex: 'attr', width: 200, align: 'left', resizable: true, ellipsis: true },
        { title: 'BOM卷积成本', dataIndex: 'bom_money', width: 100, align: 'right', resizable: true }
      ]
    }
  },
  watch: {
    excelData_yc(value) {}
  },
  computed: {
    gettableYheight() {
      return document.body.clientWidth < 1441 ? 180 : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 250 : 280
    }
  },
  methods: {
    onPageChange(page, pageSize) {
      this.currentPage = page
      this.pageSize = pageSize
    }
  }
}
</script>
  
  <style lang="less"></style>