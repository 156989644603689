<template>
  <div ref="solutionAnalysisVal" class="regulate-box">
    <div class="row" ref="canvas">
      <a-row :gutter="isPrint ? 48 : 30" style="display: flex; align-items: center; justify-content: center">
        <a-col :span="8">
          <div class="line-box" ref="node1">
            <a-descriptions :column="1" size="small" title="整体目标" bordered :labelStyle="isPrint ? { width: '160px' } : { width: '160px' }">
              <a-descriptions-item label="目标周转率">
                <!-- <div class="descriptions-span"> -->
                <a-input type="number" v-model:value="totalPer" />
                <!-- </div> -->
              </a-descriptions-item>
              <a-descriptions-item label="周转率">
                <!-- <div class="descriptions-span"> -->
                <a-input type="number" readonly :value="$numberToFixed(totalInfo.per_money_out, 2)" />
                <!-- </div> -->
              </a-descriptions-item>
              <a-descriptions-item label="变动后周转率">
                <!-- <div class="descriptions-span"> -->
                <a-input type="number" readonly :value="$numberToFixed(totalInfo.per_money_out_after, 2)" />
                <!-- </div> -->
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="isPrint ? 48 : 30" style="display: flex; align-items: center; justify-content: center">
        <a-col :span="16" class="structure-line-box">
          <div class="line0" :style="usePlan.indexOf('step3-stock') > -1 ? '' : 'height:20px;margin-bottom:0px'"></div>
          <div class="line1">
            <div class="line-left"></div>
            <div :class="usePlan.indexOf('step3-send-product') > -1 ? 'line-right' : ''"></div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="isPrint ? 48 : 30">
        <a-col :span="8" v-if="usePlan.indexOf('step3-material') > -1">
          <div class="line-box" ref="node2">
            <a-descriptions :column="1" size="small" title="材料" bordered :labelStyle="isPrint ? { width: '120px' } : { width: '120px' }">
              <a-descriptions-item label="目标周转率">
                <div class="descriptions-span">
                  <a-input type="number" v-model:value="totalPer_material" />
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="调整前材料周转率">
                <div class="descriptions-span">
                  <a-input-group compact>
                    <a-input type="number" readonly :value="$numberToFixed(material.per_money_out, 2)">
                      <template #addonBefore>
                        <a-select v-model:value="material.selectDataRange" :options="zzOptions" @change="(e) => handleChange_zz(material)" style="width: 130px" />
                      </template>
                    </a-input>
                  </a-input-group>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动后周转率">
                <div class="descriptions-span">
                  <a-input type="number" readonly :value="$numberToFixed(material.per_money_out_after, 2)">
                    <template #addonBefore>
                      <a-select v-model:value="material.selectDataRange_after" :options="zzOptions" @change="(e) => handleChange_zz(material, '_after')" style="width: 130px" />
                    </template>
                  </a-input>
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-col>
        <a-col :span="8" v-if="usePlan.indexOf('step3-stock') > -1">
          <div class="line-box">
            <a-descriptions :column="1" size="small" title="成品" bordered :labelStyle="isPrint ? { width: '120px' } : { width: '120px' }">
              <a-descriptions-item label="目标周转率">
                <div class="descriptions-span">
                  <a-input type="number" v-model:value="totalPer_stock" />
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="调整前成品周转率">
                <div class="descriptions-span">
                  <a-input type="number" readonly :value="$numberToFixed(stock.per_money_out, 2)">
                    <template #addonBefore>
                      <a-select v-model:value="stock.selectDataRange" :options="zzOptions" @change="(e) => handleChange_zz(stock)" style="width: 130px" />
                    </template>
                  </a-input>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动后周转率">
                <div class="descriptions-span">
                  <a-input type="number" readonly :value="$numberToFixed(stock.per_money_out_after, 2)">
                    <template #addonBefore>
                      <a-select v-model:value="stock.selectDataRange_after" :options="zzOptions" @change="(e) => handleChange_zz(stock, '_after')" style="width: 130px" />
                    </template>
                  </a-input>
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-col>
        <a-col :span="8" v-if="usePlan.indexOf('step3-send-product') > -1">
          <div class="line-box">
            <a-descriptions :column="1" size="small" title="发出商品" bordered :labelStyle="isPrint ? { width: '120px' } : { width: '120px' }">
              <a-descriptions-item label="目标周转率">
                <div class="descriptions-span">
                  <a-input type="number" v-model:value="totalPer_sendProduct" />
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="调整发出商品周转率">
                <div class="descriptions-span">
                  <a-input type="number" readonly :value="$numberToFixed(sendProduct.per_money_out, 2)">
                    <template #addonBefore>
                      <a-select v-model:value="sendProduct.selectDataRange" :options="zzOptions" @change="(e) => handleChange_zz(sendProduct)" style="width: 130px" />
                    </template>
                  </a-input>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="变动后周转率">
                <div class="descriptions-span">
                  <a-input type="number" readonly :value="$numberToFixed(sendProduct.per_money_out_after, 2)">
                    <template #addonBefore>
                      <a-select v-model:value="sendProduct.selectDataRange_after" :options="zzOptions" @change="(e) => handleChange_zz(sendProduct, '_after')" style="width: 130px" />
                    </template>
                  </a-input>
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-col>
      </a-row>
      <svg version="1.1" id="svg-clip">
        <defs>
          <rect id="clip-rect" />
          <clipPath id="clip-1">
            <use xlink:href="#clip-rect" />
          </clipPath>
          <clipPath id="clip-2">
            <use xlink:href="#clip-rect" />
          </clipPath>
        </defs>
      </svg>
    </div>

    <div class="row">
      <a-form>
        <a-form-item label="预计天数">
          <a-select v-model:value="estimate" :options="estimateOptions" @change="handleCalData" style="width: 100px"></a-select>
        </a-form-item>
      </a-form>

      <a-table class="cross-table cross-small-table" :scroll="{ x: 400, y: gettableYheight }" :columns="getTableColumnsCal" :data-source="tableDataCal" :pagination="false" size="small" bordered>
        <template #bodyCell="{ column, record, text }">
          <template v-if="(column.inputType == 'dot2' && !record.itemWrite) || column.dataIndex == 'sum_money_balance'">
            {{ $dealThousands(text, 2) }}
          </template>
          <template v-else-if="column.inputType == 'number' || (column.dataIndex != 'name' && record.itemWrite)">
            <a-input type="number" v-model:value="record[column.dataIndex]" @change="handleCalData" />
          </template>
        </template>

        <template #summary>
          <a-table-summary fixed>
            <a-table-summary-row class="gray-bg-def-deep">
              <a-table-summary-cell align="center"> 总额 </a-table-summary-cell>
              <a-table-summary-cell align="center"> {{ $dealThousands(tableDataCalSummary.money_balance, 2) }} </a-table-summary-cell>
              <template v-for="(item, index) in estimate">
                <a-table-summary-cell align="center"> {{ $dealThousands(tableDataCalSummary['money' + index], 2) }} </a-table-summary-cell>
                <a-table-summary-cell align="center"> {{ $dealThousands(tableDataCalSummary['money_out' + index], 2) }} </a-table-summary-cell>
              </template>
              <a-table-summary-cell align="center"> {{ $dealThousands(tableDataCalSummary.sum_money_balance, 2) }} </a-table-summary-cell>
            </a-table-summary-row>
          </a-table-summary>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
import { ref } from 'vue'
import { numberToFixed, checkZero, debounce, echartGraphic } from '@/utils/util'
import { newInstance, BrowserJsPlumbInstance } from '@jsplumb/browser-ui'
export default {
  name: 'SolutionAnalysis',
  components: {
    SliderInputBlock
  },
  props: {
    isPrint: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    },
    planType: {
      type: String,
      default: ''
    },
    materialStartMonth: {
      type: String,
      default: ''
    },
    materialAfterMonth: {
      type: String,
      default: ''
    },
    stockStartMonth: {
      type: String,
      default: ''
    },
    stockAfterMonth: {
      type: String,
      default: ''
    },
    sendProductStartMonth: {
      type: String,
      default: ''
    },
    sendProductAfterMonth: {
      type: String,
      default: ''
    },
    materialMonth: {
      type: Number,
      default: 0
    },
    stockMonth: {
      type: Number,
      default: 0
    },
    sendProductMonth: {
      type: Number,
      default: 0
    },
    initDataState: {
      type: String,
      default: ''
    },
    usePlan: {
      type: Array,
      default: () => []
    },
    tableList_material: {
      type: Array,
      default: () => []
    },
    tableList_stock: {
      type: Array,
      default: () => []
    },
    tableList_send_product: {
      type: Array,
      default: () => []
    },

    material_origin: {
      type: Array,
      default: () => []
    },

    stock_origin: {
      type: Array,
      default: () => []
    },

    send_product_origin: {
      type: Array,
      default: () => []
    },
    solutionAnalysisData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      lines: [],
      estimateTableData: {},
      zzOptions: [
        { label: '过去1个月日均', value: 'month1' },
        { label: '过去2个月日均', value: 'month2' },
        { label: '过去3个月日均', value: 'month3' },
        { label: '过去6个月日均', value: 'month6' },
        { label: '过去12个月日均', value: 'month12' }
      ],
      totalPer: '',
      totalPer_material: '',
      totalPer_stock: '',
      totalPer_sendProduct: '',
      handleCalDataTimer: 0,
      estimate: 1,
      estimateOptions: [
        { label: '请选择', value: '' },
        { label: '未来30天', value: 1 },
        { label: '未来60天', value: 2 },
        { label: '未来90天', value: 3 }
      ],
      rules: {},
      form: {
        calWay: 'month1'
      },
      other: {
        //生产成本结存
        product_money: 0,
        //委托成本结存
        delegatee_money: 0
      },
      param_cycle_total: 0,
      param_cycle_total_before: 0,
      param_cycle_total_after: 0,
      param_cycle_material: 0,
      param_cycle_material_before: 0,
      param_cycle_material_after: 0,

      param_cycle_stock: 0,
      param_cycle_stock_before: 0,
      param_cycle_stock_after: 0,

      param_cycle_send_product: 0,
      param_cycle_send_product_before: 0,
      param_cycle_send_product_after: 0,

      //总结存金额

      totalInfo: {
        day: 360,
        sum_money_balance: 0,
        sum_money_out: 0,
        day_money_out: 0,
        sold_day_money_out: 0,
        per_money_out: 0,
        per_money_out_after: 0
      },
      material: {
        tableField: 'material_origin',
        //周转月份
        selectDataRange: 'month12',
        selectDataRange_after: 'month12',
        //使用天数
        day: 360,
        //结存
        sum_money_balance: 0,
        //销售
        sum_money_out: 0,
        //每天销售金额
        day_money_out: 0,
        //结存可卖多少天
        sold_day_money_out: 0,
        //excel导入的连续月份
        excelMonth: 0,
        //开始月份
        startMonth: '',
        //结束月份
        afterMonth: '',
        //周转率
        per_money_out: 0,
        sum_money_balance_after: 0,
        sum_money_out_after: 0,
        day_money_out_after: 0,
        sold_day_money_out_after: 0,
        per_money_out_after: 0
      },

      stock: {
        tableField: 'stock_origin',
        selectDataRange: 'month12',
        selectDataRange_after: 'month12',
        day: 360,
        sum_money_balance: 0,
        sum_money_out: 0,
        day_money_out: 0,
        sold_day_money_out: 0,
        excelMonth: 0,
        startMonth: '',
        afterMonth: '',
        per_money_out: 0,
        per_money_out_after: 0,

        sum_money_balance_after: 0,
        sum_money_out_after: 0,
        day_money_out_after: 0,
        sold_day_money_out_after: 0,
        per_money_out_after: 0
      },

      sendProduct: {
        tableField: 'send_product_origin',
        selectDataRange: 'month12',
        selectDataRange_after: 'month12',
        day: 360,
        sum_money_balance: 0,
        sum_money_out: 0,
        day_money_out: 0,
        sold_day_money_out: 0,
        excelMonth: 0,
        startMonth: '',
        afterMonth: '',
        per_money_out: 0,
        per_money_out_after: 0,

        sum_money_balance_after: 0,
        sum_money_out_after: 0,
        day_money_out_after: 0,
        sold_day_money_out_after: 0,
        per_money_out_after: 0
      },

      tableDataCal: [],
      tableDataCalSummary: {},

      material_origin_lastMonth: '',
      stock_origin_lastMonth: '',
      send_product_origin_lastMonth: '',

      //总结存额度
      material_origin_total_balance_money: 0,
      stock_origin_total_balance_money: 0,
      send_product_origin_total_balance_money: 0
    }
  },
  computed: {
    gettableYheight() {
      return document.body.clientWidth < 1441 ? 150 : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 150 : 200
    },
    getTableColumnsCal() {
      const columns = [
        { title: '', dataIndex: 'name', align: 'center', width: 120 },
        {
          title: '期初',
          children: [
            { title: '期末结存', dataIndex: 'money_balance', align: 'center', width: 140, inputType: 'dot2' }
            // { title: '入库', dataIndex: 'money', align: 'center', width: 140,inputType:'dot2',  },
            // { title: '出库', dataIndex: 'money_out', align: 'center', width: 140,inputType:'dot2',  },
          ]
        }
      ]
      for (let i = 0; i < this.estimate; i++) {
        const estimateInfo = this.estimateOptions[i + 1] || {}
        columns.push({
          title: estimateInfo.label,
          children: [
            { title: '入库金额', dataIndex: 'money' + i, align: 'center', width: 140, inputType: 'number' },
            { title: '出库金额', dataIndex: 'money_out' + i, align: 'center', width: 140, inputType: 'number' }
          ]
        })
      }
      columns.push({ title: '结存', dataIndex: 'sum_money_balance', align: 'center', width: 140, inputType: 'dot2' })
      return columns
    }
  },
  watch: {
    solutionAnalysisData: {
      handler(value) {
        this.setParams(value || {})
      }
    },
    initDataState: {
      immediate: true,
      handler(value) {
        if (value === 'complete') {
          this.material.excelMonth = this.materialMonth || 0
          this.material.startMonth = this.materialStartMonth
          this.material.afterMonth = this.materialAfterMonth

          this.stock.excelMonth = this.stockMonth || 0
          this.stock.startMonth = this.stockStartMonth
          this.stock.afterMonth = this.stockAfterMonth

          this.sendProduct.excelMonth = this.sendProductMonth || 0
          this.sendProduct.startMonth = this.sendProductStartMonth
          this.sendProduct.afterMonth = this.sendProductAfterMonth
          //初始表格数据
          this.resetPageData()
        }
      }
    },
    other: {
      deep: true,
      handler() {
        console.log('++++++>>>>>>>>>')
        this.resetPageData()
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const canvas = this.$refs.canvas
      const node1 = this.$refs.node1
      const node2 = this.$refs.node2
      console.log(canvas)
      let jsPlumb = newInstance({
        container: canvas
      })

      jsPlumb.connect({
        source: node1,
        target: node2,
        connector: 'Flowchart'
      })
    },

    getSaveData() {
      const estimateTableData = {}
      this.tableDataCal.map((item) => {
        const tableField = item.tableField || ''
        const obj = { money_balance: item.money_balance }
        for (let i = 0; i < this.estimate; i++) {
          const moneyKey = 'money' + i
          const moneyOutKey = 'money_out' + i
          const itemMoney = (item[moneyKey] || 0) * 1
          const itemMoneyOut = (item[moneyOutKey] || 0) * 1
          obj[moneyKey] = itemMoney
          obj[moneyOutKey] = itemMoneyOut
        }
        estimateTableData[tableField] = obj
      })

      return {
        estimateTableData: estimateTableData,
        totalPer: this.totalPer || '',
        estimate: this.estimate || 1,

        material_selectDataRange: this.material.selectDataRange || '',
        material_selectDataRange_after: this.material.selectDataRange_after || '',
        totalPer_material: this.totalPer_material || '',

        stock_selectDataRange: this.stock.selectDataRange || '',
        stock_selectDataRange_after: this.stock.selectDataRange_after || '',
        totalPer_stock: this.totalPer_stock || '',

        sendProduct_selectDataRange: this.sendProduct.selectDataRange || '',
        sendProduct_selectDataRange_after: this.sendProduct.selectDataRange_after || '',
        totalPer_sendProduct: this.totalPer_sendProduct || ''
      }
    },

    setParams(obj) {
      this.estimateTableData = obj.estimateTableData || {}
      this.totalPer = obj.totalPer || ''
      this.estimate = obj.estimate || 1

      this.material.selectDataRange = obj.material_selectDataRange || 'month12'
      this.material.selectDataRange_after = obj.material_selectDataRange_after || 'month12'
      this.totalPer_material = obj.totalPer_material || ''

      this.stock.selectDataRange = obj.stock_selectDataRange || 'month12'
      this.stock.selectDataRange_after = obj.stock_selectDataRange_after || 'month12'
      this.totalPer_stock = obj.totalPer_stock || ''

      this.sendProduct.selectDataRange = obj.sendProduct_selectDataRange || 'month12'
      this.sendProduct.selectDataRange_after = obj.sendProduct_selectDataRange_after || 'month12'
      this.totalPer_sendProduct = obj.totalPer_sendProduct || ''

      //初始表格数据
      this.resetPageData()
    },

    handleChange_zz(row, subffix = '') {
      const tableField = row.tableField || ''
      // const tableList = this[tableField]||[]

      const monthField = row['selectDataRange' + subffix]

      //开始月份-要往前推
      let startMonth = row.afterMonth

      //结存金额
      let sum_money_balance = row.sum_money_balance
      let after_sum_money_out = 0

      let month = -1
      let estimateTimes = 0
      switch (monthField) {
        case 'month1':
          month = 1
          estimateTimes = 1
          break
        case 'month2':
          month = 2
          estimateTimes = 2
          break
        case 'month3':
          month = 3
          estimateTimes = 3
          break
        case 'month6':
          month = 6
          estimateTimes = 6
          break
        case 'month12':
          month = 12
          estimateTimes = 12
          break
      }

      if (subffix === '_after') {
        if (this.estimate) {
          startMonth = this.$decMonth(startMonth, -this.estimate)

          const tableFieldIndex = this.tableDataCal.findIndex((item) => item.tableField === tableField)
          const tableInfo = this.tableDataCal[tableFieldIndex] || {}
          for (let i = 0; i < this.estimate; i++) {
            const moneyKey = 'money' + i
            const moneyOutKey = 'money_out' + i
            const itemMoney = (tableInfo[moneyKey] || 0) * 1
            const itemMoneyOut = (tableInfo[moneyOutKey] || 0) * 1
            if (estimateTimes > i) {
              after_sum_money_out += itemMoneyOut
              sum_money_balance += itemMoney - itemMoneyOut
            }
          }
        }
      }

      // console.log('--tableField',row,tableField,startMonth,subffix,sum_money_balance)
      if (!startMonth) {
        row['day_money_out' + subffix] = 0
        row['per_money_out' + subffix] = 0
      }

      const groupMonth = !startMonth ? [] : this.$getBeforeMonthGroup(startMonth, month)
      //总天数

      let costDay = 0
      let sum_money_out = 0

      groupMonth.map((monthItem) => {
        costDay += this.$getMonthDays(monthItem)
        const monthSumMoneyOut = this.calListData(tableField, 'money_out', [monthItem])
        sum_money_out += monthSumMoneyOut
      })
      sum_money_out += after_sum_money_out
      let total_other_money_out = 0
      let monthObj = true
      if (subffix === '_after') {
        this.tableDataCal.map((item) => {
          for (let i = 0; i < this.estimate; i++) {
            const moneyKey = 'money' + i
            const moneyOutKey = 'money_out' + i
            const itemMoney = (item[moneyKey] || 0) * 1
            const itemMoneyOut = (item[moneyOutKey] || 0) * 1
            if (item.tableField === row.tableField) {
              total_other_money_out += itemMoneyOut
            }
          }
        })
      }
      // console.log(tableField,sum_money_out,costDay)
      //总销售金额
      // const sum_money_out = this.calListData(tableField,'money_out',groupMonth)*1 + total_other_money_out
      //每天消费金额
      const dayCostMoney = !costDay ? 0 : sum_money_out / costDay
      //结存可以卖多少天
      const cycleDay = !dayCostMoney ? 0 : sum_money_balance / dayCostMoney
      row['day_money_out' + subffix] = numberToFixed(cycleDay, 2)
      row['per_money_out' + subffix] = !cycleDay ? 0 : numberToFixed(360 / cycleDay, 2)
      // if(subffix==='_after'){
      // console.log('--',tableField,costDay,groupMonth,sum_money_balance,sum_money_out,dayCostMoney,cycleDay)
      // }
    },
    handleZZ_total(subffix = '') {
      //计算周转总数
      let sumMoneyOut = 0
      let sumMoneyBalance = 0
      let total_other_money_out = 0
      let costDay = 0

      let startMonth = ''
      let tableField = ''

      let after_sum_money_out = 0

      if (this.usePlan.indexOf('step3-send-product') > -1) {
        startMonth = this.sendProduct.afterMonth
        tableField = 'send_product_origin'
      } else if (this.usePlan.indexOf('step3-stock') > -1) {
        startMonth = this.stock.afterMonth
        tableField = 'stock_origin'
      } else if (this.usePlan.indexOf('step3-material') > -1) {
        startMonth = this.material.afterMonth
        tableField = 'material_origin'
      }

      if (subffix === '_after') {
        sumMoneyBalance = (this.tableDataCalSummary.money_balance || 0) * 1
        total_other_money_out = this.tableDataCalSummary.money_out
        if (this.estimate) {
          startMonth = this.$decMonth(startMonth, -this.estimate)
        }
      } else {
        this.tableDataCal.map((item) => {
          sumMoneyBalance += item.money_balance * 1
        })
      }

      const groupMonth = !startMonth ? [] : this.$getBeforeMonthGroup(startMonth, 12)
      groupMonth.map((monthItem) => {
        costDay += this.$getMonthDays(monthItem)
        const monthSumMoneyOut = this.calListData(tableField, 'money_out', [monthItem])
        sumMoneyOut += monthSumMoneyOut
      })

      sumMoneyOut += total_other_money_out + after_sum_money_out
      const totalInfoField = 'per_money_out' + subffix
      this.totalInfo[totalInfoField] = !sumMoneyBalance && !sumMoneyBalance ? 0 : sumMoneyOut / sumMoneyBalance
      this.totalInfo['day_money_out' + subffix] = !this.totalInfo[totalInfoField] ? 0 : numberToFixed(costDay / this.totalInfo[totalInfoField], 2)

      // console.log('----',startMonth,tableField,subffix,sumMoneyOut,sumMoneyBalance)
    },
    handleCalData(e) {
      clearTimeout(this.handleCalDataTimer)
      this.handleCalDataTimer = setTimeout(() => {
        let tableDataCalSummary = { money_balance: 0, money: 0, money_out: 0, sum_money_balance: 0 }
        this.tableDataCal.map((item) => {
          //结存
          let money_balance = item.money_balance
          //入库
          let money = 0 //item.money
          //出库
          let money_out = 0 //item.money_out

          for (let i = 0; i < this.estimate; i++) {
            const moneyKey = 'money' + i
            const moneyOutKey = 'money_out' + i
            const itemMoney = (item[moneyKey] || 0) * 1
            const itemMoneyOut = (item[moneyOutKey] || 0) * 1
            money += itemMoney
            money_out += itemMoneyOut
            if (!(moneyKey in tableDataCalSummary)) {
              tableDataCalSummary[moneyKey] = 0
            }
            if (!(moneyOutKey in tableDataCalSummary)) {
              tableDataCalSummary[moneyOutKey] = 0
            }
            tableDataCalSummary[moneyKey] += itemMoney
            tableDataCalSummary[moneyOutKey] += itemMoneyOut
          }
          item.sum_money_balance = money_balance * 1 + money * 1 - money_out

          tableDataCalSummary.money_balance += money_balance * 1
          tableDataCalSummary.money += money * 1
          tableDataCalSummary.money_out += money_out * 1
          tableDataCalSummary.sum_money_balance += item.sum_money_balance * 1

          if (item.tableField === 'tableList_material') {
            this.material.sum_money_balance_after = item.sum_money_balance
          } else if (item.tableField === 'tableList_stock') {
            this.stock.sum_money_balance_after = item.sum_money_balance
          } else if (item.tableField === 'tableList_send_product') {
            this.sendProduct.sum_money_balance_after = item.sum_money_balance
          }
        })
        this.tableDataCalSummary = tableDataCalSummary

        this.$nextTick(() => {
          // this.calData_zz_after()
          this.handleCalZZ()
        })
      }, 200)
    },

    initTableData() {
      const tableData = []
      if (this.usePlan.indexOf('step3-material') > -1) {
        tableData.push({
          tableField: 'material_origin',
          inputeType: 'dot2',
          name: '材料',
          money_balance: this.material.sum_money_balance,
          money: 0, //this.calListData('tableList_material','money',[this.materialAfterMonth]),
          money_out: 0, //this.calListData('tableList_material','money_out',[this.materialAfterMonth]),
          sum_money_balance: 0
        })
      }
      if (this.usePlan.indexOf('step3-stock') > -1) {
        tableData.push({
          tableField: 'stock_origin',
          inputeType: 'dot2',
          name: '成品',
          money_balance: this.stock.sum_money_balance,
          money: 0, //this.calListData('tableList_stock','money',[this.stockAfterMonth]),
          money_out: 0, //this.calListData('tableList_stock','money_out',[this.stockAfterMonth]),
          sum_money_balance: 0
        })
      }
      if (this.usePlan.indexOf('step3-send-product') > -1) {
        tableData.push({
          tableField: 'send_product_origin',
          inputeType: 'dot2',
          name: '发出商品',
          money_balance: this.sendProduct.sum_money_balance,
          money: 0, //this.calListData('tableList_send_product','money',[this.sendProductAfterMonth]),
          money_out: 0, //this.calListData('tableList_send_product','money_out',[this.sendProductAfterMonth]),
          sum_money_balance: 0
        })
      }
      const costDb = this.estimateTableData['cost'] || {}
      const delegateeDb = this.estimateTableData['delegatee'] || {}
      tableData.push({ name: '生产成本结存', tableField: 'cost', money_balance: costDb.money_balance || 0, money: 0, money_out: 0, itemWrite: 1 })
      tableData.push({ name: '委托加工物资', tableField: 'delegatee', money_balance: delegateeDb.money_balance || 0, money: 0, money_out: 0, itemWrite: 1 })

      tableData.map((item) => {
        const tableField = item.tableField || ''
        const obj = this.estimateTableData[tableField] || {}
        for (let i = 0; i < this.estimate; i++) {
          const moneyKey = 'money' + i
          const moneyOutKey = 'money_out' + i
          item[moneyKey] = (obj[moneyKey] || 0) * 1
          item[moneyOutKey] = (obj[moneyOutKey] || 0) * 1
        }
      })

      this.tableDataCal = tableData
      this.handleCalData()
    },
    handleCalZZ() {
      this.handleChange_zz(this.material)
      this.handleChange_zz(this.stock)
      this.handleChange_zz(this.sendProduct)

      this.handleChange_zz(this.material, '_after')
      this.handleChange_zz(this.stock, '_after')
      this.handleChange_zz(this.sendProduct, '_after')

      this.handleZZ_total()
      this.handleZZ_total('_after')
    },
    //重新计算金额 -这里注意函数执行顺序不要乱搞
    resetPageData() {
      this.initPageData()
      this.$nextTick(() => {
        this.initTableData()
      })
    },
    calListData(tableField, moneyField, monthObj) {
      //计算出库金额
      let total = 0
      const tableData = this[tableField] || []
      const dataLen = tableData.length

      for (let i = 0; i < dataLen; i++) {
        const info = tableData[i]
        const month = info.month
        if (monthObj === true || (typeof monthObj === 'string' && monthObj && month >= monthObj) || (Array.isArray(monthObj) && monthObj.indexOf(month) > -1)) {
          total += (info[moneyField] || 0) * 1
        }
        // else{
        //   break;
        // }
      }
      return total
    },

    initPageData() {
      //结存-除以用到的月份
      this.material.sum_money_balance = this.calListData('material_origin', 'money_balance', [this.material.afterMonth])
      this.stock.sum_money_balance = this.calListData('stock_origin', 'money_balance', [this.stock.afterMonth])
      this.sendProduct.sum_money_balance = this.calListData('send_product_origin', 'money_balance', [this.sendProduct.afterMonth])
      this.totalInfo.sum_money_balance =
        this.material.sum_money_balance * 1 + this.stock.sum_money_balance * 1 + this.sendProduct.sum_money_balance * 1 + this.other.product_money * 1 + this.other.delegatee_money * 1
    }
  }
}
</script>

<style lang="less" scoped>
.line-box-a,
.line-box-b,
.line-box-c,
.line-box-d {
  width: 420px;
  border: 1px solid #d9d9d9;
  // border: 2px solid var(--themeColor);
}

.line-box-a {
  margin-bottom: 30px;
}

.line-box-b,
.line-box-c {
  margin-right: 20px;
}
#svg-clip {
  width: 1px;
  height: 1px;
}

.ant-col-8 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.structure-line-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.line0 {
  height: 50px;
  width: 2px;
  background: #d9d9d9;
  margin-bottom: -30px;
}
.line1 {
  display: flex;
  height: 30px;
  width: 100%;
  // border: 2px solid #d9d9d9;
  // border-bottom: none;
}

.line-left {
  height: 30px;
  width: 50%;
  border: 2px solid #d9d9d9;
  border-bottom: none;
  border-right: none;
}

.line-right {
  height: 30px;
  width: 50%;
  border: 2px solid #d9d9d9;
  border-bottom: none;
  border-left: none;
}
</style>
