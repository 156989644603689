import { checkZero, numberToFixed } from "@/utils/util"
import { constants } from "crypto";

export default {
	state: {
        //预测表原数据
        tools2_excelData_yc:[],
        tools2_excelData_yc_pc_data:[],
        //存货期初结存表
        tools2_excelData_stock:[],
        //预测与存货结存数据
        ycStockData:[],
        ycStockData_customerNo:{},

        // 存货期初结存
        tools2_excelData_serial_stand:[],
        // 存货期初结存(产品编码+工序组ID作为键名)
        tools2_excelData_serial_stand__no_and_serial_group: {},
        // 产品编码标准变动成本
        tools2_excelData_serial_stand__no_cost: {},

        // 成本中心档案
        tools2_excelData_cost:[],
        // 非工序投入表
        tools2_excelData_n_serial:[],
        // 工序投入产出明细表
        tools2_excelData_serial_product:[],
        tools2_excelData_tableData:[],
        tools2_serialProductDataObj:[],

        tools2_excelData_tableData_customerNo_mind:{},
        // 通过 SolutionExtraPlan_TRCC.vue 计算的表结果
        tools2_serial_group_object:{},


    },
    getters:{
        //非工序投入表(非期间费用)
        tools2_sum_n_serial_money(state,getters){
            let money = 0;
            state.tools2_excelData_n_serial.map(function(item){
                if(item.money_center_name=='否'){
                    money += item.amount*1
                }
            })
            return numberToFixed(money);
        },
        //总销量
        tools2_sum_sold_num(state,getters){
            return getters.tools2_calYcTableData_fumField('sold_num');
        },
        //产量总数
		tools2_sum_pass_sold_num(state,getters) {
			return getters.tools2_calYcTableData_fumField('pass_sold_num')
		},
        //总存
        tools2_sum_stock(state,getters){
            return getters.tools2_calYcTableData_fumField('stock');
        },
        //总结存金额
        tools2_sum_stock_money(state,getters){
            return getters.tools2_calYcTableData_fumField('money');
        },
        //均 单位成本(期初)
        tools2_avg_unit_price_before(state,getters){
            return numberToFixed( checkZero(getters.tools2_sum_stock) ? 0 : getters.tools2_sum_stock_money / getters.tools2_sum_stock );
        },
        //均 合格率 =  销量合计 / 产量合计
        tools2_avg_pass_per_before(state,getters){
            return numberToFixed( checkZero(getters.tools2_sum_stock) ? 0 : getters.tools2_sum_sold_num / getters.tools2_sum_pass_sold_num );
        },
        //不含税总收入
		tools2_sum_price_come_in(state,getters) {
			return getters.tools2_calYcTableData_fumField('price_come_in')
		},
        tools2_avg_price_no_tax_unit_money(state,getters){
            let money = checkZero(getters.tools2_sum_sold_num) ? 0 : getters.tools2_sum_price_come_in / getters.tools2_sum_sold_num
			return numberToFixed(money)
        },
        tools2_sum_fixed_total_money(state,getters){
			return getters.tools2_tableData_step_fixed_field('cost')
        },

        //计算产量
        tools2_calYcTableData_productNum:(state,getters)=>(num)=>{
            const diffSoldNum = num - getters.tools2_sum_stock 
            let stock = num
            if(diffSoldNum > 0){
                //需要完成的销量
                let productNum = checkZero( getters.tools2_avg_pass_per_before) ? 0 : diffSoldNum / getters.tools2_avg_pass_per_before
                stock = productNum*1 + getters.tools2_sum_stock *1
            }
            return numberToFixed(stock)
        },
		//预测汇总-函数
		tools2_calYcTableData_fumField:(state,getters)=>(field)=>{
			let money = 0
			state.ycStockData.map(function (item) {
					money += (item[field] ?? 0) * 1
			})
			return numberToFixed(money)
		},
        //固定成本-函数
		tools2_tableData_step_fixed_field:(state,getters)=>(field)=>{
			let money = 0;
			state.tools2_excelData_n_serial.map(function(item){
                money += item.amount*1
			})
			return numberToFixed(money)
		},
        //固定成本-函数
		tools2_ycStockData_soldNumToMoney:(state,getters)=>(soldNum)=>{
            const serialProductCustomerMind= state.tools2_excelData_tableData_customerNo_mind
            let totalMoneyBegin = 0;
            let totalMoneyCurrent = 0;
            let totalSoldNum = 0;

			state.ycStockData.map((item)=>{
                const sold_num = item.sold_num*soldNum;
                totalSoldNum += sold_num ;
                const customerNo = item.customerNo
                const serialProductCustomerMindInfo = serialProductCustomerMind[customerNo]||{}
                const stock = item.stock||0;
                const cost_price = item.cost_price; //期初成本
                const money = item.money; //期初存货销售成本
                const pass_per = item.pass_per; //期初存货销售成本
                //期初成本
                let money_begin = 0
                
                //当期成本
                const price_current = serialProductCustomerMindInfo.serial_stand_actuality_cost_total_current||0; 

                //计算是否需要额外生产
                const diffProductNum = sold_num - stock;
                let productNum=0
                let needProductNum = 0;
                if(diffProductNum > 0) { //需要额外生产
                    money_begin = stock*cost_price
                    needProductNum = diffProductNum
                    productNum = numberToFixed( diffProductNum/(pass_per/100) )
                }else{
                    money_begin = sold_num*cost_price
                }

                totalMoneyBegin = totalMoneyBegin*1 + money_begin*1
                //当前成本
                const moneyCurrent = numberToFixed( productNum * price_current)
                totalMoneyCurrent =totalMoneyCurrent*1 + moneyCurrent*1
                // if(soldNum==0.15){
                //     console.log(`${customerNo}销量${sold_num};期初成本${cost_price};期初变动成本${money_begin};需要销量${needProductNum};需要生成数量${productNum};生产单价${price_current};完工价格${moneyCurrent}`)
                // }
               
            })
            const totalMoney = totalMoneyCurrent*1 + totalMoneyBegin*1;

            // console.log(soldNum,totalSoldNum,totalMoneyCurrent,totalMoneyBegin,totalMoney,totalMoney/totalSoldNum)
            return numberToFixed(checkZero(totalSoldNum) ? 0 : totalMoney/totalSoldNum)
		},
    },
    mutations: {
		updateTools2State(state,{field,value}){
			state[field] = value
		}
	},
	actions: {

	}
}