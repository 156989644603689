export default{
    methods:{
        getDataTypeName(type){
          let name = "";
          switch(type){
            case 'step3-material':
              name = '材料'
              break;
            case 'step3-material_total':
              name = '材料小计'
              break;
            case 'step3-stock':
              name = '成品'
              break;
            case 'step3-stock_total':
              name = '成品小计'
              break;
            case 'step3-send-product':
              name = '发出商品'
              break;
            case 'step3-send-product_total':
              name = '发出商品小计'
              break;
          }
          return name;
        },
        getDataTypeName(type){
          let name = "";
          switch(type){
            case 'step3-material':
              name = '材料'
              break;
            case 'step3-material_total':
              name = '材料小计'
              break;
            case 'step3-stock':
              name = '成品'
              break;
            case 'step3-stock_total':
              name = '成品小计'
              break;
            case 'step3-send-product':
              name = '发出商品'
              break;
            case 'step3-send-product_total':
              name = '发出商品小计'
              break;
          }
          return name;
        },
        getTypeName(type){
          let name = "";
          switch(type){
            case 'step3-material':
              name = '材料'
              break;
            case 'step3-stock':
              name = '成品'
              break;
            case 'step3-send-product':
              name = '发出商品'
              break;
          }
          return name;
        },
    
        
    handleClearData(items, dataType,options={}){
        const data = items||[]
        const typeName = this.getTypeName(dataType)
  
        if(!data.length){
          return {}
        }
  
  
  
        const getMonthStr = (item)=>{
          return (item.month||'').substr(0,7)
        };
        //数据唯一key 
        const getKey = (item)=>{
          let arr = [];
          arr.push(item.month)
          arr.push(item.type)
          arr.push(item.store)
          arr.push(item.abc)
          arr.push(item.customer_supper)
          arr.push(item.no)
          return arr.join('_')
        };
  
        //产品数据唯一key 
        const getProductKey = (item)=>{
          let arr = [];
          arr.push(item.store)
          arr.push(item.customer_supper)
          arr.push(item.no)
          return arr.join('_')
        };
  
        //最后一个月的数据
        const lastMonthData =  data.length>0  ? data[data.length-1] : []
        
        //记录最后一个月份
        const lastMonth = lastMonthData.month
        
        const productData = {};
      
  
  
  
        //日期
        const verifyDate = this.$getMonthDate(lastMonthData.month||'');//this.$formatTime(new Date(),'YYYY-MM-DD');
        // console.log('>>>>>>>>>>>>>>>>>>>>>lastMonth',lastMonthData,this.lastMonth,verifyDate)
        const groupData = {};
  
        const recordGroupRangeDate  = (diffDay,groupDataItem,num,price)=>{
          if(diffDay<=30){
  
            groupDataItem.date_30_num += num
            groupDataItem.date_30_money += price
  
          // totalItem.date_30_num += num
          // totalItem.date_30_money += price
  
          }else if(diffDay<=60){
            groupDataItem.date_60_num += num
            groupDataItem.date_60_money += price
  
          // totalItem.date_60_num += num
          // totalItem.date_60_money += price
  
  
          }else if(diffDay<=90){
            groupDataItem.date_90_num += num
            groupDataItem.date_90_money += price
  
          // totalItem.date_90_num += num
          // totalItem.date_90_money += price
  
          }else if(diffDay<=180){
  
            groupDataItem.date_180_num += num
            groupDataItem.date_180_money += price
  
          // totalItem.date_180_num += num
          // totalItem.date_180_money += price
  
          }else if(diffDay<360){
  
            groupDataItem.date_360_num += num
            groupDataItem.date_360_money += price
  
          // totalItem.date_360_num += num
          // totalItem.date_360_money += price
  
          }else if(diffDay>=360){
  
            groupDataItem.date_360_more_num += num
            groupDataItem.date_360_more_money += price
  
          // totalItem.date_360_more_num += num
          // totalItem.date_360_more_money += price
  
          }
          return groupDataItem;
        }
  
       
        data.reverse().map((item)=>{
          const key = getProductKey(item)
          const getMonth = getMonthStr(item)
          const datetime = item.month//item.datetime
          item.showMonth = datetime.substr(0,7)
          // const month = item.month
          const diffDay = this.$diffDay30(datetime,verifyDate);
          if(!(key in groupData)){
  
            if(lastMonth!=datetime){ //只记录保留最后一个月份的数据
              return;
            }
  
            //保留最新条目记录
            item.afterMonth = datetime
            item.beforeMonth = ''
            item.dataType = dataType
            item.dataTypeName = this.getDataTypeName(dataType)
            item.dataTypeName = this.getDataTypeName(dataType)
            item.date_30_num = 0
            item.date_30_money = 0
            item.date_60_num = 0
            item.date_60_money = 0
            item.date_90_num = 0
            item.date_90_money = 0
            item.date_180_num = 0
            item.date_180_money = 0
            item.date_360_num = 0
            item.date_360_money = 0
            item.date_360_more_num = 0
            item.date_360_more_money = 0
            //结存总数
            item.sum_num_balance = item.num_balance
            item.sum_money_balance = item.money_balance
            //结存单价
            item.price_balance = item.num_balance*1!=0 ? item.money_balance/item.num_balance : 0;
            //结存总额
            // item.sum_money_balance = item.sum_num_balance * item.price_balance
  
            item.item_name = typeName
  
            groupData[key] = item;
  
          
            // totalItem.sum_num_balance +=  item.sum_num_balance*1, 2
            // totalItem.sum_money_balance +=  item.sum_money_balance*1
          }
          //结存数量
          const num_balance = groupData[key].num_balance*1
  
          //这个月入库数量
          let num = item.num*1;
          // if(key.indexOf('816603000101')>-1){
          //   console.log('++++',key,num)
          // }
          if(num_balance>0){
            //剩余数量
            const reduceNum = num_balance - num;
            if(reduceNum<0){
              num = num_balance
              groupData[key].num_balance = 0
            }else{
              groupData[key].num_balance = reduceNum
            }
          }else{
            groupData[key].num_balance = 0
            num = 0;
          }
  
          //表格首次创建时间
          groupData[key].beforeMonth = datetime 
          
  
          //结存金额
          const price = num * groupData[key].price_balance*1;
  
          // groupData[key].sum_num_balance += num
          // groupData[key].sum_money_balance += price
  
          // totalItem.sum_num_balance += num
          // totalItem.sum_money_balance += price
  
  
          groupData[key] = recordGroupRangeDate(diffDay, groupData[key],num,price)
        })
        let hasDataType = false;
        if(Object.keys(groupData).length>0){
          hasDataType = true;
          // totalItem.dataType = dataType+'_total'
          // totalItem.dataTypeName = typeName+'合计'
          // totalItem.item_name = typeName+'小计'
          // groupData['total_item'] = totalItem;
  
          //再算一次结存可能找不到入库记录的情况
          Object.keys(groupData).map((groupKey)=>{
            const num_balance = groupData[groupKey].num_balance *1
           
            if(num_balance>1){ //计算相差月份
              const price = num_balance*groupData[groupKey].price_balance
              const beforeMonth = groupData[groupKey].beforeMonth //最开始入库时间
              const afterMonth = groupData[groupKey].afterMonth //最后入库时间
              const diffDay = this.$diffDay30(beforeMonth,afterMonth);
              // console.log(groupKey,num_balance,diffDay,beforeMonth,afterMonth)
                
              groupData[groupKey] = recordGroupRangeDate(diffDay, groupData[groupKey],num_balance,price)
            }
          })
  
        }
        
        return {
            lastMonth ,
            groupData ,
            hasDataType ,
        };
      },
    }
}