<template>
  <div class="printPDF-word-block" v-if="moduleName=='printPDF'">{{ getVipTypeName }}</div>
  <a-tag :color="getColor" v-else-if="!isText">{{ getVipTypeName }}</a-tag>
  <div class="vip_type" v-else>
    <span class="tag"><img :src="getVipImg"></span>
    <span>{{ getVipTypeName }}</span>
  </div>
</template>

<script>
export default {
  props: {
    isText: {
      type: Boolean,
      default: false
    },
    moduleName:{
      type:String,
      default:''
    }
  },
  computed: {
    getVipType() {
      return this.$store.state.user.vip_type
    },
    getIsVip() {
      return this.$store.state.user.is_vip
    },
    getVipImg() {
      let src = ''
      // if (this.getVipType == 'vip_company_master') {
      //   src = '/icons/vvip_icon.png'
      // } else if (this.getVipType == 'vip_company_common') {
      //   src = '/icons/svip_icon.png'
      // } else if (this.getVipType == 'vip_company_normal') {
      //   src = ''
      // } else if (this.getVipType == 'vip_person') {
      //   src = '/icons/vip_icon.png'
      // } else if (this.getVipType == 'vip_normal') {
      //   src = ''
      // }  
      if (this.getVipType == 'person') {
        src = '/icons/vip_icon.png'
      } else if (this.getVipType == 'company') {
        src = '/icons/svip_icon.png'
      }
      return src
    },
    getVipTypeName() {
      let name = '个人版'
      // if (this.getVipType == 'vip_company_master') {
      //   name = '企业大师版'
      // } else if (this.getVipType == 'vip_company_common') {
      //   name = '企业普通版'
      // } else if (this.getVipType == 'vip_company_normal') {
      //   name = '企业账号'
      // } else if (this.getVipType == 'vip_person') {
      //   name = '个人专业版'
      // } else if (this.getVipType == 'vip_normal') {
      //   name = '个人免费版'
      // }
      if (this.getVipType == 'person') {
        name = '个人版'
      } else if (this.getVipType == 'company') {
         name = '企业版'
      }
      return name
    },
    getColor() {
      let name = 'default'
      // if (this.getVipType == 'vip_company_master') {
      //   name = 'success'
      // } else if (this.getVipType == 'vip_company_common') {
      //   name = 'processing'
      // } else if (this.getVipType == 'vip_company_normal') {
      //   name = 'default'
      // } else if (this.getVipType == 'vip_person') {
      //   name = 'warning'
      // } else if (this.getVipType == 'vip_normal') {
      //   name = 'default'
      // }
      if(this.getIsVip){
        if (this.getVipType == 'person') {
          name = 'warning'
        } else if (this.getVipType == 'company') {
          name = 'success'
        }
      }
      

      return name
    }
  }
}
</script>

<style lang="less" scoped>
.printPDF-word-block {
  width: 160px;
  font-size: px;
  padding: 5px 10px;
  border: 4px solid #ffffff;
  color:white;
  margin-bottom: 10px;
  font-size: 22px;
  text-align: center;
}
.vip_type {
  font-size: 11px;
  display: flex;
  align-items: center;
  background-color: #232323;
  border-radius: 24px;
  height: 18px;
  padding: 2px 12px 0px;
  span.tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;    
    img {
      display: block;
      height: 11px;
    }
  }
}

</style>
