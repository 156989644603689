import { UserLayout } from '@/layouts'
import { Router } from './types'
import { example } from './exampleRouterMap'
import errorPage from '@/views/Exception/500.vue'

// info:todo:1.如果使用服务端获取路由,path: '/',这块路由再写就会被覆盖
// 2.router.addRoute(parent, routeObj),添加parent,它就会自动加上/parent/xxx,直接写parent即可,就算嵌套多层也没事,自动变成/xxxx/xxxx/parent/xxxxx
// 3.以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;
// 在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
export default [
    // 示例用路由,可删
    example,
    {
        path: '/user',
        name: 'user',
        component: UserLayout,
        redirect: '/user/login',
        hidden: true,
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login.vue'),
                meta: { title: '登录' }
            },
            {
                path: 'reg',
                name: 'reg',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/Reg.vue'),
                meta: { title: '注册' }
            },
            {
                path: 'register',
                name: 'register',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register.vue'),
                meta: { title: '注册' }
            }, {
                path: 'registerPerson',
                name: 'registerPerson',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterPerson.vue'),
                meta: { title: '个人注册' }
            }, {
                path: 'registerPersonSet',
                name: 'registerPersonSet',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterPersonSet.vue'),
                meta: { title: '个人注册' }
            }, {
                path: 'registerResult',
                name: 'registerResult',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult.vue'),
                meta: { title: '注册完成' }
            }, {
                path: 'forgotPassword',
                name: 'forgotPassword',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/ForgotPassword.vue'),
                meta: { title: '找回密码' }
            },
        ]
    },

    {
        path: '/web',
        name: 'web',
        component: () => import('@/views/exception/web.vue'),
    },
    {
        path: '/exception/500',
        name: 'error',
        component: errorPage,
    },
    {
        path: '/:path(.*)',
        name: 'NoMatch',
        component: () => import('@/views/exception/404.vue'),
    },
    {
        path: '/mobile',
        name: 'Mobile',
        component: () => import('@/views/dashboard/cloud/mobile.vue'),
    },
    {
        path: '/mobile2',
        name: 'Mobile2',
        component: () => import('@/views/dashboard/cloud2/mobile.vue'),
    },
    {
        path: '/mobile3',
        name: 'Mobile3',
        component: () => import('@/views/dashboard/cloud3/mobile.vue'),
    },
    {
        path: '/mobile4',
        name: 'Mobile4',
        component: () => import('@/views/dashboard/cloud4/mobile.vue'),
    },
    {
        path: '/mobile5',
        name: 'Mobile5',
        component: () => import('@/views/dashboard/cloud5/mobile.vue'),
    },
    {
        path: '/mobile6',
        name: 'Mobile6',
        component: () => import('@/views/dashboard/cloud6/mobile.vue'),
    },
    {
        path: '/mobile7',
        name: 'Mobile7',
        component: () => import('@/views/dashboard/cloud7/mobile.vue'),
    },
    {
        path: '/mobile8',
        name: 'Mobile8',
        component: () => import('@/views/dashboard/cloud8/mobile.vue'),
    }
    
] as Router[]
