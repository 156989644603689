<template>
  <div class="profit-table">
    <!-- <div class="table-children-box"> -->
    <a-row :gutter="8">
      <a-col :xs="24" :md="24" :lg="11" :xl="11" :xxl="11">
        <div>
          <div class="subtitle">库龄</div>
          <div class="table-children-box">
            <a-table :class="isMobile ? '' : 'cross-table'" :scroll="{ x: 300, y: gettableYheight }" :columns="columns_kl" :data-source="tableData_kl" :pagination="false" size="small" bordered>
              <template #bodyCell="{ column, record, text }">
                <template v-if="column.dataIndex === 'time' && record.dataTable !== 'total'">
                  <a-select v-model:value="record.selectDataRange" :options="dateOptions" @change="(e) => handleChange(record)" style="width: 100%"> </a-select>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </a-col>

      <a-col :xs="24" :md="24" :lg="13" :xl="13" :xxl="13">
        <div>
          <div class="subtitle">周转率</div>
          <div class="table-children-box">
            <a-table :class="isMobile ? '' : 'cross-table'" :scroll="{ x: 300, y: gettableYheight }" :columns="columns_zz" :data-source="tableData_zz" :pagination="false" size="small" bordered>
              <template #bodyCell="{ column, record, text }">
                <template v-if="column.dataIndex === 'time' && record.dataTable !== 'total'">
                  <a-select v-model:value="record.selectDataRange" :options="zzOptions" @change="(e) => handleChange_zz(record)" style="width: 100%"> </a-select>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- </div> -->
  </div>
</template>

<script>
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import { mapGetters } from 'vuex'
import { numberToFixed, checkZero, tableDefaultPage, tableDefautlLine, dateFormatter } from '@/utils/util'

export default {
  name: 'SolutionPlan',
  components: {
    SliderInputBlock
  },

  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isPrint: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    },
    initDataState: {
      //数据初始化状态
      type: String,
      default: ''
    },
    usePlan: {
      //使用哪些方案
      type: Array,
      default: () => []
    },
    tableList_material: {
      type: Array,
      default: () => []
    },
    tableList_stock: {
      type: Array,
      default: () => []
    },
    tableList_send_product: {
      type: Array,
      default: () => []
    },

    material_origin: {
      type: Array,
      default: () => []
    },

    stock_origin: {
      type: Array,
      default: () => []
    },

    send_product_origin: {
      type: Array,
      default: () => []
    }
  },
  mixins: [],

  data() {
    // const bodyWidth = document.body.offsetWidth
    return {
      bodyWidth: document.body.offsetWidth,
      pageParams: {},
      rules: {},
      form: {
        cycleDay: '',
        calWay: 'month1'
      },
      dateOptions: [
        { label: '>30天占比', value: 'date_30_money' },
        { label: '>60天占比', value: 'date_60_money' },
        { label: '>90天占比', value: 'date_90_money' },
        { label: '>180天占比', value: 'date_180_money' },
        { label: '>360天占比', value: 'date_360_money' }
        // {label:'>=360天占比',value:'date_360_more_money'},
      ],
      zzOptions: [
        { label: '过去1个月日均', value: 'month1' },
        { label: '过去2个月日均', value: 'month2' },
        { label: '过去3个月日均', value: 'month3' },
        { label: '过去6个月日均', value: 'month6' },
        { label: '过去12个月日均', value: 'month12' }
      ],
      //库龄
      columns_kl: [
        { title: '', dataIndex: 'name', align: 'center', width: 80 },
        { title: '呆滞占比', dataIndex: 'per', align: 'right', width: 60 }, //总占比 = 选中的结存金额/总结存金额
        { title: '时间范围', dataIndex: 'time', align: 'center', width: 140 }
      ],

      tableData_kl: [],
      //周转
      columns_zz: [
        { title: '', dataIndex: 'name', align: 'center', width: 80 },
        { title: '天数', dataIndex: 'day', align: 'right', width: 50 }, //
        { title: '周转率', dataIndex: 'per', align: 'right', width: 50 }, //总占比 = 选中的结存金额/总结存金额
        { title: '时间范围', dataIndex: 'time', align: 'center', width: 140 } //总占比 = 选中的结存金额/总结存金额
      ],
      tableData_zz: [],

      totalSumObj: {
        sum_num_balance: 0,
        sum_money_balance: 0,
        date_30_num: 0,
        date_30_money: 0,
        date_60_num: 0,
        date_60_money: 0,
        date_90_num: 0,
        date_90_money: 0,
        date_180_num: 0,
        date_180_money: 0,
        date_360_num: 0,
        date_360_money: 0,
        date_360_more_num: 0,
        date_360_more_money: 0
      },

      //各组数据最后一个月
      tableList_material_lastMonth: '',
      tableList_stock_lastMonth: '',
      tableList_send_product_lastMonth: '',

      material_origin_lastMonth: '',
      stock_origin_lastMonth: '',
      send_product_origin_lastMonth: '',

      //各组数据汇总
      tableList_material_sumObj: {},
      tableList_stock_sumObj: {},
      tableList_send_product_sumObj: {},

      //总销售额度
      tableList_material_total_sold_money_out: 0,
      tableList_stock_total_sold_money_out: 0,
      tableList_send_product_total_sold_money_out: 0,
      //总结存额度
      tableList_material_total_balance_money: 0,
      tableList_stock_total_balance_money: 0,
      tableList_send_product_total_balance_money: 0,

      //总结存额度
      material_origin_total_balance_money: 0,
      stock_origin_total_balance_money: 0,
      send_product_origin_total_balance_money: 0
    }
  },
  computed: {
    gettableYheight() {
      return document.body.clientWidth < 1441 ? 104 : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 96 : 83
    },
    computedColumns_kl() {
      return this.columns_kl.map((column) => {
        if (column.dataIndex === 'name') {
          column.width = this.bodyWidth < 1441 ? 60 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 80 : 80
        } else if (column.dataIndex === 'per') {
          column.width = this.bodyWidth < 1441 ? 60 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 80 : 80
        } else {
          column.width = this.bodyWidth < 1441 ? 80 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 100 : 120
        }
        return column
      })
    }
  },
  created() {},
  mounted() {
    // this.updateColumnWidths()
    // window.addEventListener('resize', this.handleResize)
  },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.handleResize)
  // },
  watch: {
    initDataState: {
      immediate: true,
      handler(value) {
        if (value === 'complete') {
          //数据初始化完成

          this.initPageData()
        }
      }
    }
  },
  methods: {
    // handleResize() {
    //   this.bodyWidth = document.body.offsetWidth
    // },
    // updateColumnWidths() {
    //   this.columns_kl = this.computedColumns_kl
    // },
    setPageParams(params) {
      this.pageParams = params
    },
    getPageParams() {
      const obj = {}

      this.tableData_kl.map((item) => {
        const dataTable = item.dataTable || ''
        obj[dataTable] = item.selectDataRange
      })
      this.tableData_zz.map((item) => {
        const dataTable = item.dataTable || ''
        obj[dataTable] = item.selectDataRange
      })

      return obj
    },

    handleChange_zz(row) {
      const tableField = row.dataTable || ''
      const tableList = this[tableField] || []

      const monthField = row.selectDataRange

      //开始月份-要往前推
      let startMonth = this[tableField + '_lastMonth'] || ''
      let sumObj = this[tableField + '_sumObj'] || {}
      //结存金额
      const sum_money_balance = this[tableField + '_total_balance_money']
      if (!startMonth) {
        row.per = 0
        row.day = 0
      }

      let month = -1
      switch (monthField) {
        case 'month1':
          month = 1
          break
        case 'month2':
          month = 2
          break
        case 'month3':
          month = 3
          break
        case 'month6':
          month = 6
          break
        case 'month12':
          month = 12
          break
      }
      // console.log('>>>>>>>>>>>>>>>>')
      const groupMonth = this.$getBeforeMonthGroup(startMonth, month)
      // console.log(startMonth,month,tableList)
      //总天数

      let costDay = 0
      let sum_money_out = 0

      groupMonth.map((monthItem) => {
        costDay += this.$getMonthDays(monthItem)
        // costDay += 30
        const monthSumMoneyOut = this.calSoldMoney(tableList, [monthItem])
        sum_money_out += monthSumMoneyOut
        console.log(monthItem, monthSumMoneyOut)
      })
      //总销售金额
      // const sum_money_out = this.calSoldMoney(tableList,groupMonth)
      //每天消费金额
      const dayCostMoney = !costDay ? 0 : sum_money_out / costDay
      //结存可以卖多少天
      const cycleDay = !dayCostMoney ? 0 : sum_money_balance / dayCostMoney
      row.day = numberToFixed(cycleDay, 2)
      row.per = !cycleDay ? 0 : numberToFixed(360 / cycleDay, 2)
      // console.log(costDay,sum_money_out,tableField,row.day,sum_money_balance)
    },
    handleZZ_total() {
      //计算周转总数
      let sumMoneyOut = 0
      let sumMoneyBalance = this.material_origin_total_balance_money * 1 + this.stock_origin_total_balance_money * 1 + this.send_product_origin_total_balance_money * 1
      let startMonth = ''
      let tableList = []
      if (this.usePlan.indexOf('step3-send-product') > -1) {
        startMonth = this.send_product_origin_lastMonth
        // sumMoneyOut = this.send_product_origin_total_balance_money*1
        tableList = this.send_product_origin
        // sumMoneyBalance = this.tableList_send_product_total_balance_money*1
      } else if (this.usePlan.indexOf('step3-stock') > -1) {
        startMonth = this.stock_origin_lastMonth
        // sumMoneyOut = this.stock_origin_total_balance_money*1
        tableList = this.stock_origin
        // sumMoneyBalance = this.tableList_stock_total_balance_money*1
      } else if (this.usePlan.indexOf('step3-material') > -1) {
        startMonth = this.material_origin_lastMonth
        // sumMoneyOut = this.material_origin_total_balance_money*1
        tableList = this.material_origin
        // sumMoneyBalance = this.tableList_material_total_balance_money*1
      }

      const groupMonth = this.$getBeforeMonthGroup(startMonth, 12)
      //总天数

      let costDay = 0
      let sum_money_out = 0

      groupMonth.map((monthItem) => {
        costDay += this.$getMonthDays(monthItem)
        const monthSumMoneyOut = this.calSoldMoney(tableList, [monthItem])
        sumMoneyOut += monthSumMoneyOut
      })

      const totalIndex = this.tableData_zz.findIndex((e) => e.dataTable == 'total')
      // console.log('++++++++++++++++++++++++')
      // console.log(sumMoneyBalance, sumMoneyOut)
      if (totalIndex > -1) {
        const per = !sumMoneyBalance ? 0 : sumMoneyOut / sumMoneyBalance
        this.tableData_zz[totalIndex].per = numberToFixed(per, 2)
        this.tableData_zz[totalIndex].day = numberToFixed(!per ? 0 : 360 / per, 2)
      }
    },

    handleChangeRowMoney(row) {
      const sumObj = this[row.dataTable] || {}
      let selectItemMoney = 0 // (sumObj[row.selectDataRange]||0)*1

      switch (row.selectDataRange) {
        case 'date_30_money':
          selectItemMoney += row.selectDataRange == 'date_30_money' ? 0 : (sumObj.date_30_money || 0) * 1
        case 'date_60_money':
          selectItemMoney += row.selectDataRange == 'date_60_money' ? 0 : (sumObj.date_60_money || 0) * 1
        case 'date_90_money':
          selectItemMoney += row.selectDataRange == 'date_90_money' ? 0 : (sumObj.date_90_money || 0) * 1
        case 'date_180_money':
          selectItemMoney += row.selectDataRange == 'date_180_money' ? 0 : (sumObj.date_180_money || 0) * 1
        case 'date_360_money':
          selectItemMoney += row.selectDataRange == 'date_360_money' ? 0 : (sumObj.date_360_money || 0) * 1
          break
      }
      return selectItemMoney
    },

    handleChange(row) {
      const sumObj = this[row.dataTable] || {}
      const sumMoney = (sumObj.sum_money_balance || 0) * 1
      let selectItemMoney = this.handleChangeRowMoney(row) // (sumObj[row.selectDataRange]||0)*1

      row.per = numberToFixed(sumMoney != 0 ? (selectItemMoney / sumMoney) * 100 : 0, 2)
      this.$nextTick(() => {
        this.handleTotalPer()
      })
    },
    handleTotalPer() {
      let totalIndex = -1
      let sumMoney = 0
      let itemMoney = 0
      this.tableData_kl.map((item, index) => {
        if (item.dataTable === 'total') {
          totalIndex = index
        } else {
          const sumObj = this[item.dataTable] || {}
          sumMoney += (sumObj.sum_money_balance || 0) * 1
          itemMoney += this.handleChangeRowMoney(item)
        }
      })
      console.log(totalIndex, sumMoney, itemMoney)
      if (totalIndex > -1) {
        this.tableData_kl[totalIndex].per = numberToFixed(sumMoney != 0 ? (itemMoney / sumMoney) * 100 : 0, 2)
      }
    },
    calSoldMoney(tableData, monthArr) {
      //计算出库金额
      let money_out = 0
      const dataLen = tableData.length
      for (let i = 0; i < dataLen; i++) {
        const info = tableData[i]
        const month = info.month
        if (monthArr === true || monthArr.indexOf(month) > -1) {
          money_out += info.money_out * 1
        }
      }
      return money_out
    },
    calBalanceMoney(tableData, monthArr) {
      //计算出库金额
      let money_out = 0
      const dataLen = tableData.length
      for (let i = 0; i < dataLen; i++) {
        const info = tableData[i]
        const month = info.month
        if (monthArr === true || monthArr.indexOf(month) > -1) {
          money_out += info.money_balance * 1
        }
      }
      return money_out
    },
    calTotalSum(currentDataSource, sumField) {
      const totalObj = {}
      for (let key in this.totalSumObj) {
        const field = key
        const topSumObj_field = key
        let total = 0
        currentDataSource.map((item) => {
          if (item.dataType.indexOf('_total') > -1) {
            return
          }
          total += item[field] * 1
        })
        totalObj[topSumObj_field] = total
      }
      this[sumField] = totalObj
    },
    calTotalSoldMoney(tableField) {
      //总销售金额
      this[tableField + '_total_sold_money_out'] = this.calSoldMoney(this[tableField], true)
      this[tableField + '_total_balance_money'] = this.calBalanceMoney(this[tableField], [this[tableField + '_lastMonth'] || ''])
    },
    initPageData() {
      const tableData_kl = []
      const tableData_zz = []
      const selectDataRange = 'date_360_money'
      const selectZzRange = 'month12'
      if (this.tableList_material.length) {
        //最后一个月
        this.tableList_material_lastMonth = this.tableList_material[0].month
        this.material_origin_lastMonth = this.tableList_material[0].month
        this.calTotalSum(this.tableList_material, 'tableList_material_sumObj')
        //计算总销售额度
        this.calTotalSoldMoney('tableList_material')
        this.calTotalSoldMoney('material_origin')
        tableData_kl.push({ name: '材料', per: 0, dataTable: 'tableList_material_sumObj', selectDataRange: this.pageParams.tableList_material_sumObj || selectDataRange })
        tableData_zz.push({ name: '材料', day: 0, per: 0, dataTable: 'material_origin', selectDataRange: this.pageParams.material_origin || selectZzRange })
      }
      if (this.tableList_stock.length) {
        //最后一个月
        this.tableList_stock_lastMonth = this.tableList_stock[0].month
        this.stock_origin_lastMonth = this.tableList_stock[0].month
        this.calTotalSum(this.tableList_stock, 'tableList_stock_sumObj') //计算总销售额度
        this.calTotalSoldMoney('tableList_stock')
        this.calTotalSoldMoney('stock_origin')
        tableData_kl.push({ name: '成品', per: 0, dataTable: 'tableList_stock_sumObj', selectDataRange: this.pageParams.tableList_stock_sumObj || selectDataRange })
        tableData_zz.push({ name: '成品', day: 0, per: 0, dataTable: 'stock_origin', selectDataRange: this.pageParams.stock_origin || selectZzRange })
      }
      if (this.tableList_send_product.length) {
        //最后一个月
        this.tableList_send_product_lastMonth = this.tableList_send_product[0].month
        this.send_product_origin_lastMonth = this.tableList_send_product[0].month
        this.calTotalSum(this.tableList_send_product, 'tableList_send_product_sumObj')
        this.calTotalSoldMoney('tableList_send_product')
        this.calTotalSoldMoney('send_product_origin')
        tableData_kl.push({ name: '发出商品', per: 0, dataTable: 'tableList_send_product_sumObj', selectDataRange: this.pageParams.tableList_send_product_sumObj || selectDataRange })
        tableData_zz.push({ name: '发出商品', day: 0, per: 0, dataTable: 'send_product_origin', selectDataRange: this.pageParams.send_product_origin || selectZzRange })
      }
      tableData_kl.push({ name: '总', per: 0, dataTable: 'total' })
      tableData_zz.push({ name: '总', day: 0, per: 0, dataTable: 'total' })
      this.tableData_kl = tableData_kl
      this.tableData_zz = tableData_zz
      this.$nextTick(() => {
        this.tableData_kl.map((item) => {
          if (item.dataTable !== 'total') {
            this.handleChange(item)
          }
        })
      })
      this.$nextTick(() => {
        this.tableData_zz.map((item) => {
          if (item.dataTable !== 'total') {
            this.handleChange_zz(item)
            this.handleZZ_total(item)
          }
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.card-table {
  .table-children {
    margin-bottom: 10px;
    .card-table-box {
      margin-top: 0px !important;
      display: flex;
    }
  }
}
</style>
