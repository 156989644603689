<template>
  <div>
    <div style="width: 100%; height: 750px">
      <div id="SolutionSvpScatterBefore" style="height: 750px"></div>
    </div>
    <div>
      <a-table :columns="columns" :data-source="tableData" :pagination="pagination" size="small" bordered> </a-table>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { mapGetters, mapState } from 'vuex'
import { numberToFixed, debounce } from '@/utils/util'
// echart变量
let myChart

const DIENSIION_CLUSTER_INDEX = 2
const CLUSTER_COUNT = 6

const groupTypeName = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }
const groupTypeArray = Object.keys(groupTypeName)
const groupTypeValues = Object.values(groupTypeName)

export default {
  name: 'SolutionsSVPScatterBefore',
  props: {
    pagination: {
      type: [Boolean, Object],
      default: false
    }
  },
  data() {
    return {
      groupArray: [],
      tableData: [],
      columns: [
        { title: '纬度', dataIndex: 'name', align: 'center' },
        { title: '项', dataIndex: 'type_name', align: 'center' },
        { title: '收入', dataIndex: 'price_come_in', align: 'center' },
        { title: '变动成本', dataIndex: 'material_cost_money', align: 'center' },
        { title: '销量', dataIndex: 'sold_num', align: 'center' },
        { title: '边际收益', dataIndex: 'slid_money', align: 'center' }
      ],
      debounce: () => {}
    }
  },
  computed: {
    excelData_yc() {
      return this.$store.state.tools2.ycStockData
    }
  },
  watch: {
    excelData_yc(value) {
      let groupObject = {}
      value.map(function (item) {
        for (let key in groupTypeName) {
          const itemValue = item[key]
          const name = groupTypeName[key]
          if (!(name in groupObject)) {
            groupObject[name] = {}
          }
          if (!(itemValue in groupObject[name])) {
            groupObject[name][itemValue] = {
              name: name,
              type_name: itemValue,
              //销售收入
              price_come_in: 0,
              //变动成本
              material_cost_money: 0,
              //销量
              sold_num: 0,
              //边际收益
              slid_money: 0
            }
          }
          const sold_num = item.sold_num * 1
          //产量
          const pass_sold_num = item.pass_sold_num * 1

          groupObject[name][itemValue]['price_come_in'] += item.price_come_in * 1
          groupObject[name][itemValue]['material_cost_money'] += item.cost_price * 1 * pass_sold_num
          groupObject[name][itemValue]['sold_num'] += sold_num
          //边际贡献
          const slid_money = numberToFixed(item.price_come_in - item.cost_price)
          // groupObject[name][itemValue]['slid_money'] +=  groupObject[name][itemValue]['price_come_in'] - groupObject[name][itemValue]['material_cost_money'];
        }
      })

      for (let _key in groupObject) {
        for (let _member in groupObject[_key]) {
          groupObject[_key][_member]['price_come_in'] = numberToFixed(groupObject[_key][_member]['price_come_in'])
          groupObject[_key][_member]['material_cost_money'] = numberToFixed(groupObject[_key][_member]['material_cost_money'])
          groupObject[_key][_member]['sold_num'] = numberToFixed(groupObject[_key][_member]['sold_num'])
          groupObject[_key][_member]['slid_money'] = numberToFixed(groupObject[_key][_member]['price_come_in'] - groupObject[_key][_member]['material_cost_money'])
        }
      }

      let tableData = []
      let echartObject = {}
      for (let typeKey in groupTypeName) {
        const name = groupTypeName[typeKey]
        echartObject[name] = {
          name: name,
          type: 'scatter',
          emphasis: {
            focus: 'series'
          },
          markArea: {
            silent: true,
            itemStyle: {
              color: 'transparent',
              borderWidth: 1,
              borderType: 'dashed'
            },
            data: [
              [
                { name: name, xAxis: 'min', yAxis: 'min' },
                { xAxis: 'max', yAxis: 'max' }
              ]
            ]
          },
          markPoint: {
            data: [
              { groupName: name, type: 'max', name: 'Max' },
              { groupName: name, type: 'min', name: 'Min' }
            ]
          },
          markLine: {},
          data: []
        }

        //表格数据

        for (let memberKey in groupObject[name]) {
          const item = groupObject[name][memberKey]
          echartObject[name].data.push([numberToFixed(item.sold_num), numberToFixed(item.slid_money)])

          tableData.push({
            name: name,
            type_name: memberKey,
            price_come_in: item.price_come_in,
            material_cost_money: item.material_cost_money,
            sold_num: item.sold_num,
            slid_money: item.slid_money
          })
        }

        //x轴均值
        let xAVG = 0
        const dataLen = echartObject[name].data.length
        echartObject[name].data.map((xAxisItem) => (xAVG += xAxisItem[0] * 1))
        xAVG = dataLen == 0 ? 0 : xAVG / dataLen
        echartObject[name].markLine = {
          lineStyle: { type: 'solid' },
          data: [{ type: 'average', name: 'AVG' }, { xAxis: xAVG }]
        }
      }

      this.tableData = tableData
      this.groupArray = Object.values(echartObject)
    },
    groupArray: {
      deep: true,
      handler: function () {
        this.drawEchart()
      }
    }
  },
  mounted() {
    this.debounce = debounce(this._drawEchart)
    var chartDom = document.getElementById('SolutionSvpScatterBefore')
    myChart = echarts.init(chartDom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    })
  },
  methods: {
    drawEchart() {
      this.debounce()
    },
    _drawEchart() {
      const option = {
        title: {
          text: '',
          subtext: ''
        },
        grid: {
          left: '3%',
          right: '7%',
          bottom: '7%',
          containLabel: true
        },
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          formatter: function (params) {
            if (params.value.length > 1) {
              return params.seriesName + ' :<br/>' + params.value[0] + '销量 ' + params.value[1] + '边际贡献 '
            } else {
              return (
                // JSON.stringify(params) +
                params.data.groupName + ' :<br/>' + params.name + ' : ' + params.value + '边际贡献 '
              )
            }
          },
          axisPointer: {
            show: true,
            type: 'cross',
            lineStyle: { type: 'dashed', width: 1 }
          }
        },
        toolbox: {
          feature: {
            dataZoom: {},
            brush: {
              type: ['rect', 'polygon', 'clear']
            }
          }
        },
        brush: {},
        legend: {
          data: this.groupArray.map((item) => item.name)
        },
        xAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              formatter: '{value} 销量'
            },
            splitLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              formatter: '{value} 边际贡献'
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: this.groupArray
      }
      // 释放资源
      myChart.setOption(option)
    }
  }
}
</script>

<style>
</style>