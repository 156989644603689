<script>
import { message } from 'ant-design-vue'
import { numberToFixed, debounce, fnc_excelData_yc_after, checkZero,tableSortFnc,roundToDotNumber,tableDefaultPage  } from '@/utils/util'
import { mapGetters } from 'vuex'
import  CVPCalMixIn from '../../cloud/calMixIn.vue'

import SliderInputBlock from '@/components/SliderInputBlock.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import dataTable from './dataTable.vue'

const groupTypeName = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }
export default {
  components: {
    SliderInputBlock,
    ToolsItemIntro,
    dataTable,
  },
  data(){
    return {
      params_tax_per:0,
      params_sold_per:0,
      params_cost_per:0,
      params_pass_per:0,
      initDataState:'',


      downLoading:false,
      planType:'base',
      planBase:'',
      baseSvp:'',
      detail: {},
      listQuery:{
        planType:'seven',
      },

      tableList:[],

      onChangeParamsTimer:0,
      hasActualityData:true,
      isPrint:false,

      remark_base: '',
      remark_extra: '',
      remark_base_time: '',
      remark_base_mobile:'',
      remark_extra_mobile:'',
      remark_extra_time: '',


      //// CVP工具I（基于BOM）已保存方案1
      cvpDetail:{},
      svp_tableData:[],
      svp_tableData_fixed:[],
      svp_tableData_mix:[],
      svp_tableData_mix_columns:[],
      scatter_tableData:[],
      //对比方案数据
      ycTableList:[],

    }
  },
  mixins:[CVPCalMixIn],
  created() {
    this.listQuery.id = this.$route.query.id || 0
    this.planType = this.$route.query.planType == 'extra' ? 'extra' : 'base'

    this.getPlanDetail()
  },
  computed:{
    ...mapGetters(['getHasToolAuth']),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    }, userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    },
    getTitle() {
      return ''
    },

  },

  methods: {
    getPlanDetail(){
      this.$network('/api/tools/planDetail', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const pageParams = detail.page_params || {}
          this.detail = detail
          this.planBase = this.detail.base||'step4_cvp';
          this.$nextTick(()=>{
            this.$refs.dataTable.setTableData(pageParams.cycleTValue,pageParams.dataTableData)
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },
  }
}
</script>
