<template>
    <a-modal title="修改手机" :width="640" :open="visible" :confirmLoading="loading" :footer="null" @ok="handleSubmit"
        @cancel="close">
        <a-spin :spinning="loading">
            <div class="tips">
                <div>企业修改绑定手机号</div>
                <div>需通过发送邮箱的方式申请方可通过审核样本案例。（具体文字要求由翎谋提供）</div>
            </div>

            <template v-if="!resultSuccess">
                <a-form ref="formAuth" :rules="rules" :model="auth" layout="vertical">
                    <a-form-item label="手机号">
                        <a-input v-model:value="auth.phone" addon-before="+86" disabled></a-input>
                    </a-form-item>
                    <a-form-item label="验证码" name="verify">
                        <a-input-group compact>
                            <a-input v-model:value="auth.verify" placeholder="验证码" style="width: calc(100% - 102px)">
                            </a-input>
                            <SmsCaptcha :phone="auth.phone" type="change-phone-verify" />
                        </a-input-group>

                    </a-form-item>

                    <!-- 保存按钮 -->
                    <a-form-item>
                        <a-button class="submit" type="primary" block @click="handleNext">下一步</a-button>
                    </a-form-item>
                </a-form>
            </template>

            <template v-else>
                <a-form ref="formRef" :rules="rules" :model="form" layout="vertical">
                    <a-form-item label="手机号" v-bind="validateInfos.phone" name="phone">
                        <a-input v-model:value="form.phone" addon-before="+86"></a-input>
                    </a-form-item>
                    <a-form-item label="验证码" v-bind="validateInfos.verify" name="verify">
                        <a-input-group compact>
                            <a-input v-model:value="form.verify" placeholder="验证码" style="width: calc(100% - 102px)">
                            </a-input>
                            <SmsCaptcha :phone="form.phone" type="change-phone" />
                        </a-input-group>
                    </a-form-item>


                    <!-- 保存按钮 -->
                    <a-form-item>
                        <a-button class="submit" type="primary" block @click="handleSubmit">确认修改</a-button>
                    </a-form-item>
                </a-form>
            </template>
        </a-spin>
    </a-modal>
</template>

<script lang="ts" setup>
import { ref, reactive, computed, onMounted, toRaw } from 'vue'
import baseService from '@/utils/http/axios'

import { Form, message } from 'ant-design-vue';
import SmsCaptcha from '@/components/tools/SmsCaptcha.vue'
import emitter from '@/utils/eventBus'
import { useStore } from 'vuex'
const store = useStore()

const loading = ref(false)
const visible = ref(false)

const open = () => {
    visible.value = true
}
const close = () => {
    visible.value = false
}
defineExpose({
    open
})

// 表单
const useForm = Form.useForm
const formAuth = ref();
const auth = reactive({
    phone: store.state.user.phone,
    verify: '',
    type: 'change-phone-verify'
});

const formRef = ref();
const form = reactive({
    phone: '',
    verify: '',
});
const formItemLayout = {
    labelCol: { span: 3 },
};
const formTailLayout = {
    labelCol: { span: 3 },
    wrapperCol: { offset: 3 },
};

const rules = reactive({
    phone: [
        { required: true, message: '请输入手机号' },
        { validateTrigger: ['change', 'blur'] }
    ],
    verify: [
        { required: true, message: '请输入短信验证码' },
        { validateTrigger: ['change', 'blur'] }
    ],
})


const { validate, validateInfos } = useForm(form, rules)

const resultSuccess = ref(false);
const handleNext = () => {
    baseService.post('/api/sms/verify', auth).then(res => {
        message.success(res.msg)
        setTimeout(() => {
            resultSuccess.value = true;
            formAuth.value.resetFields();
        }, 700);
    }).catch(err => {
        console.error(err)
    })
}
const handleSubmit = () => {
    validate().then((res) => {
        baseService.post('/api/user/modPhone', form).then(res => {
            message.success(res.msg)
            setTimeout(() => {
                formRef.value.resetFields();
                resultSuccess.value = false;
                close()
                emitter.emit('flush_user_info')
            }, 700);
        }).catch(err => {
            console.error(err)
        })
    })
};
</script>
<style lang="less" scoped>
.ant-form-text {
    font-size: 14px;
    font-weight: bold;
}

.ant-btn.submit {
    height: 40px;
}
</style>
