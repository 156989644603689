<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'App',
})
</script>
<template>
  <div id="app-box">
    <a-config-provider
      :locale="lang[locale]"
      :theme="{
        token: {
          colorPrimary: systemConfig.state.color,
          borderRadius: 2
        }
      }"
    >
      <!-- algorithm: theme.darkAlgorithm,夜间主题 -->
      <router-view />
    </a-config-provider>
  </div>
</template>

<script lang="ts" setup name="App">
import { onErrorCaptured, h, onMounted } from 'vue'
import zh_CN from 'ant-design-vue/es/locale/zh_CN'
import en_US from 'ant-design-vue/es/locale/en_US'
import { setDeviceType } from '@/utils/device'
// import LockScreen from '@/components/LockScreen/index.vue'
import emitter from '@/utils/eventBus'
import { useRouter } from 'vue-router'
import { Modal } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'
import { systemConfig } from '@/store/reactiveState'
// import { theme } from 'ant-design-vue';
import { useStore } from 'vuex'

const store = useStore()

const { locale } = useI18n()
const lang = { 'en-US': en_US, 'zh-CN': zh_CN }

window.onresize = setDeviceType
setDeviceType()

const router = useRouter()

//项目初始化配置
store.dispatch('initConfig')

//初始化各种工具说明
store.dispatch('handleToolsIntro')

//退出登录监听
emitter.once('axios_goto_login', () => {
  router.push({ name: 'login' })
})
//绑定用户信息
emitter.once('bind_user_info', (userInfo) => {
  store.commit('updateUserInfo', userInfo)
})

//刷新用户数据
// emitter.once('flush_user_info', () => {
//     store.dispatch('flushUserInfo')
// })

// if (!store.state.user.id) {
//     emitter.emit('flush_user_info')
// }

emitter.once('axios_goto_login', () => {
  router.push({ name: 'login' })
})

// emitter.once('table_10_js', (id) => {

// })

//全局错误处理
onErrorCaptured((err, instance, info) => {
  if (window.env !== 'localhost') {
    // debugger
    console.log(err, instance, info)
    Modal.error({
      title: 'System Error',
      content: h('pre', err.stack),
      class: 'errorCapturedModal',
      width: '60%'
    })
  }
})

// const setRem = () => {
//     const reScreenSize = (wid) => {
//       // 当设备宽度小于1400px时，不在改变rem的值
//       if (wid <= 1400) return;
//       let w = document.documentElement.clientWidth; // 获取设备的宽度
//       let n =
//         10 * (w / 1920) > 40
//           ? 40 + 'px'
//           : 10 * (w / 1920) >= 7.4
//           ? 10 * (w / 1920) + 'px'
//           : 7.4 + 'px';
//       document.documentElement.style.fontSize = n;
//     };
//     reScreenSize();
//     console.log(document.documentElement.clientWidth);

//     window.addEventListener('load', reScreenSize);
//     window.addEventListener('resize', () => reScreenSize(document.documentElement.clientWidth));
//   };
//等比缩放
function setResize() {
  let box = document.getElementById('app-box')
  box.style.transform = `scale(${getScale()}) translate(-50%,-50%)`
  //计算缩放的比例
  function getScale(w = 1920, h = 945) {
    //window.innerWidth 并不是1080 而是945
    const ww = window.innerWidth / w
    const wh = window.innerHeight / h
    return ww < wh ? ww : wh
  }
  // 防抖
  window.onresize = () => {
    box.style.transform = `scale(${getScale()}) translate(-50%,-50%)`
  }
}
onMounted(() => {
  // setRem();
  // setResize();
})
</script>

<style lang="less">
#body-contaner {
  --themeColor: #1e6dff;
  --themebgColor: #f8f9fd;
}
// .solution-container {
//     --themeColor:#1e6dff;
// }
#app-box {
  //   position: fixed;
  //   left:50%;
  //   top:50%;
  //   transform-origin:left top;
  //   width: 1920px;
  //   height: 945px; //window.innerWidth 并不是1080
  width: 100%;
  height: 100%;
}
</style>
