<template>
  <div class="statistics">
    <div class="tag">
      <span>{{ moduleName === 'plan' ? '计划' : '实际' }}</span>
      <em>{{ moduleName === 'plan' ? '1' : '2' }}/{{ hasActualityData ? 1 : 2 }}</em>
    </div>

    <div class="descriptions">
      <div class="descriptions-item">
        <div class="label">收入</div>

        <div class="content">{{ handleNumber(sum_price_come_in) }}</div>
      </div>
      <div class="descriptions-item">
        <div class="label">期初转<br />销售成本</div>
        <div class="content">{{ handleNumber(sold_cost_begin) }}</div>
      </div>
      <div class="descriptions-item flex-col">
        <!-- <div class="label">本期转销售成本</div> -->
        <div class="content">
          <div class="descriptions-item_table">
            <div class="table-row">
              <div class="table-cell" style="text-align: left">
                <a-space @click="clickIsShowDetailCell">
                  <PlusSquareOutlined v-if="!isShowDetailCell" />
                  <MinusSquareOutlined v-else />
                </a-space>
              </div>
              <div class="table-cell" style="text-align: center">变动成本</div>
              <div class="table-cell" style="text-align: center">固定成本</div>
            </div>

            <div class="summary-row">
              <div class="summary-cell table-first-row-title">
                <!-- <div class="table-row-icon" style="margin-right: 5px">
                  <a-space @click="clickIsShowDetailCell">
                    <PlusSquareOutlined v-if="!isShowDetailCell" />
                    <MinusSquareOutlined v-else />
                  </a-space>
                </div> -->
                <span style="text-align: left">本期转<br />销售成本</span>
              </div>
              <div class="summary-cell">{{ handleNumber(sum_cost_product_fifo) }}</div>
              <div class="summary-cell">{{ handleNumber(sum_amount) }}</div>
            </div>
            <div v-show="isShowDetailCell" class="table-content">
              <div class="table-row" v-for="(item, index) in tableData" :key="index">
                <div class="table-cell">{{ item.cost_name }}</div>
                <div class="table-cell">{{ handleNumber(item.cost_product_fifo__cost) }}</div>
                <div class="table-cell">{{ handleNumber(item.amount) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="descriptions-item">
        <div class="label">管理利润</div>

        <div class="content">{{ handleNumber(manager_profit) }}</div>
      </div>
      <div class="descriptions-item">
        <div class="label">管理利润率</div>

        <div class="content">{{ manager_profit_per }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { numberToFixed, roundToDotNumber, debounce, checkZero } from '@/utils/util'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons-vue'

export default {
  name: 'SolutionExtraPlan_MLLR',
  components: {
    ExclamationCircleOutlined,
    ToolsItemIntro,
    PlusSquareOutlined,
    MinusSquareOutlined
  },
  props: {
    pagination: {
      type: [Boolean, Object],
      default: false
    },
    moduleName: {
      type: String,
      default: ''
    },
    numberFnc: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      columns: [
        { title: '', dataIndex: 'cost_name', align: 'center' },
        { title: '变动成本', dataIndex: 'cost_product_fifo__cost', align: 'right' },
        { title: '固定成本', dataIndex: 'amount', align: 'right' }
      ],
      tableData: [],

      sum_price_come_in: 0,
      sold_cost_begin: 0,
      manager_profit: 0,
      manager_profit_per: 0,
      sum_cost_product_fifo: 0,
      sum_amount: 0,

      isShowDetailCell: true
    }
  },
  computed: {},
  mounted() {
    // this.createWatch_tableData()
  },
  methods: {
    clickIsShowDetailCell() {
      this.isShowDetailCell = !this.isShowDetailCell
    },
    handleNumber(number) {
      if (this.numberFnc) {
        return this.numberFnc(number)
      } else {
        return this.$dealThousands(number)
      }
    },
    createTableData(fncResult_stockAndYc, fncResult_serialStand, fncResult_SerialProduction, fncResult_NSerial, step2Stock_data) {
      const list = fncResult_SerialProduction.newTableData || []
      let sum_sotck_money = 0
      step2Stock_data.map((item) => {
        sum_sotck_money = numberToFixed(sum_sotck_money * 1 + item.money * 1)
      })
      let sum_cost = 0
      let sum_cost_product_fifo = 0
      const tableDataObj = {}
      // console.log(list)
      list.map((item) => {
        const key = item.cost_name || ''
        if (!key) {
          return
        }
        if (!(key in tableDataObj)) {
          tableDataObj[key] = {
            name: key,
            //总投入额
            cost: 0,
            //净投入额
            cost_jing: 0,
            //工序完成成本
            cost_serial: 0,
            //本期产品完工成本
            cost_product_begin: 0,
            //本期产品销售成本(FIFO)
            cost_product_fifo: 0,
            //本期产品销售成本(FIFO) 标准
            cost_product_fifo_stand: 0,
            //本期产品销售成本(AVCO)
            cost_product_avco: '',
            //完工产品投入产出（当期)
            cost_product_complete_begin: 0,
            //完工产品投入产出（滚动)
            cost_product_complete_current: 0,
            //销售投入产出（当期）
            cost_sold_begin: 0,
            //销售投入产出（滚动）
            cost_sold_current: 0,
            //投入产出1工序级
            serial_per_1: 0,
            //投入产出2产品级
            serial_per_2: 0,
            //期初库存
            sum_sotck_money: sum_sotck_money,
            summary_market_per: 0
          }
        }
        const cost = item.balance_start * 1 + item.deadline_material * 1 + item.deadline_worker * 1 + item.deadline_money * 1
        const product_num_sold_fifo = item.product_num_sold_fifo
        const product_num_sold_fifo_stand = item.product_num_sold_fifo_stand
        tableDataObj[key].cost = numberToFixed(tableDataObj[key].cost * 1 + cost * 1)

        tableDataObj[key].cost_jing = numberToFixed(tableDataObj[key].cost_jing * 1 + item.amount * 1)
        tableDataObj[key].cost_serial = numberToFixed(tableDataObj[key].cost_serial * 1 + item.amount * 1)
        tableDataObj[key].cost_product_begin = numberToFixed(tableDataObj[key].cost_product_begin * 1 + item.serial_product_in_stock_money * 1)
        tableDataObj[key].cost_product_fifo = numberToFixed(tableDataObj[key].cost_product_fifo * 1 + product_num_sold_fifo * 1)
        tableDataObj[key].cost_product_fifo_stand = numberToFixed(tableDataObj[key].cost_product_fifo_stand * 1 + product_num_sold_fifo_stand * 1)

        tableDataObj[key].cost_product_complete_begin = numberToFixed(checkZero(tableDataObj[key].cost_jing) ? 0 : (tableDataObj[key].cost_product_begin / tableDataObj[key].cost_jing) * 100)
        tableDataObj[key].cost_product_complete_current = numberToFixed(checkZero(tableDataObj[key].cost) ? 0 : (tableDataObj[key].cost_product_begin / tableDataObj[key].cost) * 100)
        tableDataObj[key].cost_sold_begin = numberToFixed(checkZero(tableDataObj[key].cost_jing) ? 0 : (tableDataObj[key].cost_product_fifo / tableDataObj[key].cost_jing) * 100)
        tableDataObj[key].cost_sold_current = numberToFixed(checkZero(tableDataObj[key].cost) ? 0 : (tableDataObj[key].cost_product_fifo / tableDataObj[key].cost) * 100)

        tableDataObj[key].serial_per_1 = numberToFixed(checkZero(tableDataObj[key].cost) ? 0 : (tableDataObj[key].cost_serial / tableDataObj[key].cost) * 100)

        tableDataObj[key].serial_per_2 = numberToFixed(checkZero(tableDataObj[key].cost) ? 0 : (tableDataObj[key].cost_product_begin / tableDataObj[key].cost) * 100)

        sum_cost = sum_cost * 1 + cost * 1
        sum_cost_product_fifo = sum_cost_product_fifo * 1 + product_num_sold_fifo * 1
      })
      // const summary_market_per = numberToFixed(checkZero((sum_cost*1 + sum_sotck_money*1)) ? 0 : sum_cost_product_fifo / (sum_cost*1 + sum_sotck_money*1)*100)

      // this.summary_market_per = summary_market_per;

      // const tableData = Object.values(tableDataObj);
      // this.tableData = tableData.map((item)=>{
      //   item.summary_market_per = summary_market_per
      //   return item;
      // });

      this._createTableData(fncResult_stockAndYc, fncResult_serialStand, fncResult_SerialProduction, fncResult_NSerial, tableDataObj)
    },
    _createTableData(fncResult_stockAndYc, fncResult_serialStand, fncResult_SerialProduction, fncResult_NSerial, serialGroupObject) {
      const serialProductDataObj = fncResult_SerialProduction.colSpan || {}
      const serialProductCummaryObject = fncResult_SerialProduction.summaryObject || {}
      this.sum_price_come_in = fncResult_stockAndYc.sum_price_come_in
      // console.log(serialGroupObject)
      // console.log('>>>>>>>>>>>>>>>>')
      // console.log(serialProductDataObj)
      // console.log(fncResult_NSerial.step2NSerial_data)
      const fixedMoney = fncResult_NSerial.totalCost || 0
      // 期初存货转出销售成本
      let sold_cost_begin = 0
      let sum_slide_money = 0 //边际收益
      for (let key in serialProductDataObj) {
        const info = serialProductDataObj[key]
        sold_cost_begin += (info.stock_cost_to_sold_money_begin || 0) * 1
        if (this.moduleName == 'plan') {
          sum_slide_money += (info.slide_money_plan || 0) * 1
        } else {
          sum_slide_money += (info.slide_money || 0) * 1
        }
      }
      this.sold_cost_begin = sold_cost_begin

      let sum_cost_product_fifo = 0
      // if(this.moduleName=='plan'){
      //     sum_cost_product_fifo = serialProductCummaryObject.stock_cost_to_sold_money_current;
      //   }else{
      //     sum_cost_product_fifo = serialProductCummaryObject.stock_cost_to_sold_money_current_actuality;
      //   }
      let sum_amount = 0
      const tableData = []
      fncResult_NSerial.step2NSerial_data.map((obj) => {
        const item = Object.assign({}, obj)
        const cost_name = item.cost_name || ''
        const groupInfo = serialGroupObject[cost_name] || {}
        sum_amount += item.amount * 1
        if (this.moduleName == 'plan') {
          item.cost_product_fifo__cost = groupInfo.cost_product_fifo_stand || 0
        } else {
          item.cost_product_fifo__cost = groupInfo.cost_product_fifo || 0
        }
        sum_cost_product_fifo += item.cost_product_fifo__cost * 1

        tableData.push(item)
        // return item
      })
      // console.log(tableData)
      // console.log(sum_slide_money)
      this.tableData = tableData
      this.sum_amount = roundToDotNumber(sum_amount)
      this.sum_cost_product_fifo = roundToDotNumber(sum_cost_product_fifo)
      // 管理利润
      this.manager_profit = roundToDotNumber(sum_slide_money - fixedMoney)
      // 管理利润比例
      this.manager_profit_per = roundToDotNumber(checkZero(this.sum_price_come_in) ? 0 : (this.manager_profit / this.sum_price_come_in) * 100) + '%'
    }
  }
}
</script>

<style lang="less" scoped>
.table-col {
  height: 90px;
  min-height: 90px;
  overflow: auto;
}
.table-first-row-title {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.table-row-icon {
  // display: flex;
  /* flex-direction: row; */
  // justify-content: space-between;
}
</style>