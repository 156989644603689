<script>
import { message } from 'ant-design-vue'
import { numberToFixed, debounce, fnc_excelData_yc_after, checkZero, tableSortFnc, roundToDotNumber, tableDefaultPage } from '@/utils/util'
import { mapGetters } from 'vuex'
import CVPCalMixIn from '../cloud/calMixIn.vue'
import { htmlToPDF } from '@/utils/html2pdf'

const groupTypeName = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }
export default {
  data() {
    return {
      params_tax_per: 0,
      params_sold_per: 0,
      params_cost_per: 0,
      params_pass_per: 0,
      initDataState: '',

      downLoading: false,
      planType: 'base',
      planBase: '',
      baseSvp: '',
      detail: {},
      listQuery: {
        planType: 'seven'
      },

      tableList: [],

      onChangeParamsTimer: 0,
      hasActualityData: true,
      isPrint: false,

      remark_base: '',
      remark_extra: '',
      remark_base_time: '',
      remark_base_mobile: '',
      remark_extra_mobile: '',
      remark_extra_time: '',

      //// CVP工具I（基于BOM）已保存方案1
      cvpDetail: {},
      svp_tableData: [],
      svp_tableData_fixed: [],
      svp_tableData_mix: [],
      svp_tableData_mix_columns: [],
      scatter_tableData: [],
      //对比方案数据
      ycTableList: [],
      auth_share_state:0,
      authTools:[],
    }
  },
  mixins: [CVPCalMixIn],
  created() {
    this.listQuery.id = this.$route.query.id || 0
    this.planType = this.$route.query.planType == 'extra' ? 'extra' : 'base'

    this.getPlanDetail()
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    },
    getTitle() {
      return ''
    }
  },

  methods: {
    getPlanDetail() {
      this.$network('/api/tools/planDetail', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const pageParams = detail.page_params || {}
          this.detail = detail
          
          this.auth_share_state = data.auth_share_state||0
          this.authTools = data.authTools||[]
          // console.log('=====================this.detail', this.detail)
          this.planBase = this.detail.base || 'step4_cvp'
          this.$nextTick(() => {
            this.$refs.dataTable.setTableData(pageParams.cycleTValue, pageParams.dataTableData)
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },

    handleShare() {
      message.error(`保存后才能进行分享`)
      this.handleSave(() => {
        this.$refs.savePlan.close()
        this.$nextTick(() => {
          this.$refs.shareLink.open()
        })
      })
    },
    handleBack() {
      this.$confirm({
        content: '是否保存方案',
        okText: '是',
        cancelText: '否',
        onOk: () => {
          this.handleSave(() => {
            this.$router.back()
          })
        },
        onCancel: () => {
          this.$router.back()
        }
      })
    },
    handleSave(callback) {
      const tableData = this.$refs.dataTable.tableData || []
      this.$refs.savePlan.open(
        this.listQuery.id,
        {
          name: this.detail.name || '',
          remark_base: this.remark_base,
          tool_type: 'seven',
          params: {
            cycleTValue: this.$refs.dataTable.cycleTValue,
            dataTableData: tableData,
            //投资总现金流入
            compare_tzzxjlr: this.$refs.dataTable.getCompareData('tzzxjlr'),
            //投资总规模
            compare_tzzkm: this.$refs.dataTable.getCompareData('tzzgm'),
            //前期投资
            compare_qqtz: this.$refs.dataTable.getCompareData('qqtz'),
            //净现金流量
            compare_jxjll: this.$refs.dataTable.getCompareData('jxjll'),
            //累计净现金流量
            compare_ljjxjll: this.$refs.dataTable.getCompareData('ljjxjll'),
            //折现净现金流量
            compare_zxjxjll: this.$refs.dataTable.getCompareData('zxjxjll'),
            //折现累计净现金流量
            compare_zxljjxjll: this.$refs.dataTable.getCompareData('zxljjxjll'),
            //净利润
            compare_jlr: this.$refs.dataTable.getCompareData('jlr'),

            //内部收益率
            compare_nbsyl: this.$refs.dataTable.getCompareData('nbsyl'),
            //投资回收期 (动态)
            compare_tzhsq_dt: this.$refs.dataTable.getCompareData('tzhsq_dt')
          }
        },
        () => {
          this.detail.is_save = 1
          if (callback) {
            callback()
          } else {
            this.$router.replace({ path: '/history' })
          }
        }
      )
    },

    async downPDF() {
      this.downLoading = true
      this.isPrint = true
      this.$refs.pageLoading.open('正在进行方案下载')
      //增加打印次数
      this.handlePrint()

      setTimeout(() => {
        this.$nextTick(async () => {
          this.$refs.pageLoading.text('方案构件中...')

          let elId = 'solutionTemplatePDF'
          let pdfName = '基础方案'
          await this.$refs.solutionTemplatePDF.downPDF(this)
          this.$refs.pageLoading.text('正在生成PDF文件')
          await htmlToPDF(elId, pdfName, 'PDF-foot')
          this.$refs.pageLoading.text('下载成功')
          setTimeout(() => {
            location.reload()
          }, 800)
        })
      }, 200)

      return
    },
    handlePrint() {
      this.$network('/api/tools/planPrintTimes', { id: this.listQuery.id, tool_type: 'two' })
    }
  }
}
</script>
