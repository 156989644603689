<template>
  <!-- 去掉swiper-no-swiping类名 -->
  <div class="SliderInputBlock">
    <div class="slider" :order="1">
      <a-slider v-model:value="number" :min="min" :max="max" :step="step" @change="debounce()" />
    </div>
    <div class="input" :order="order" v-if="!order == 0">
      <a-input-number v-model:value="number" :min="min" :max="max" :step="step" @change="debounce()" />
    </div>
  </div>
</template>

<script >
import { message } from 'ant-design-vue'
import { debounce, numberToFixed } from '@/utils/util'
export default {
  name: 'SliderInputBlock',
  props: {
    value: { type: [Number, String], default: '' },
    checkBase: { type: Boolean, default: false },
    fixedValue: { type: [Number, String], default: 0 },
    baseValue: { type: [Number, String], default: 0 },
    min: { type: Number, default: -100 },
    merMin: { type: [Number, String], default: '' },
    max: { type: Number, default: 300 },
    step: { type: Number, default: 1 },
    order: { type: Number, default: 1 },
    planType: { type: String, default: '' }
  },
  data() {
    return {
      number: 0,
      debounce: () => {}
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (value, oldValue) {
        this.number = value
        // console.log('>>>',value,oldValue)
        if (value != oldValue && oldValue instanceof Number) {
          // this.checkRange(true,false)
          this.onChange(false)
        }
      }
    },
    baseValue: {
      immediate: true,
      handler: function (value) {
        this.number = 0
        this.$nextTick(() => {
          this.onChange(false)
          // this.checkRange(false)
        })
      }
    }
  },
  emits: ['update:value', 'change'],
  mounted() {
    this.number = this.value
    this.debounce = debounce(this.onChange)
  },
  methods: {
    checkRange(showErr = true) {
      let fixedNumber = parseFloat(this.fixedValue)
      let init_value = parseFloat(this.number) + fixedNumber
      if (this.checkBase) {
        fixedNumber += parseFloat(this.baseValue)
        init_value += parseFloat(this.baseValue)
      }
      if (init_value > this.max) {
        this.number = this.max - fixedNumber
        showErr && message.error('变动参数之和不得高于【' + this.max + '】')
        this.$nextTick(() => {
          this.onChange(false)
        })
      } else {
        if (this.merMin === 0) {
          const minLimit = this.merMin - fixedNumber
          if (this.number < minLimit) {
            this.number = numberToFixed(minLimit)
            showErr && message.error('变动参数之和不得低于【' + this.merMin + '】')
            this.$nextTick(() => {
              this.onChange(false)
            })
          }
        }
      }
    },
    onChange(checked = true) {
      // console.log('>>>>>>>>>> onChange <<<<<<<')
      // console.log(this.number)
      // checked && this.checkRange()
      this.$nextTick(() => {
        this.$emit('update:value', this.number)
        this.$emit('change', this.number)
      })
    }
  }
}
</script>
<style lang="less">
.SliderInputBlock {
  display: flex;
  width: 100%;
  .slider {
    flex: 1;
    margin: 0px 2px;
  }
  .input {
    min-width: 45px;
  }
}
</style>
