<template>
  <div class="">
    <div class="row">
      <a-radio-group v-model:value="showWay" name="radioGroup" optionType="button" button-style="solid">
        <a-radio value="1">全部</a-radio>
        <a-radio value="2">计划与标准</a-radio>
        <a-radio value="3" v-if="hasActualityData">实际</a-radio>
      </a-radio-group>
    </div>

    <div class="row">
      <div class="table-children">
        <a-table
          class="cross-table"
          @change="onTableChange"
          :columns="getTableColumns"
          :data-source="getTableData"
          :pagination="pagination"
          size="small"
          bordered
          :scroll="isPrint ? { y: '100%' } : { y: 500 }"
          @resizeColumn="(w, col) => (col.width = w)"
        >
          <template #bodyCell="{ column, record, text }">
            <template v-if="['is_need_stock_div', 'is_need_stock'].indexOf(column.dataIndex) > -1">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {})[column.dataIndex] || $numberToFixed(0) }}</a>
            </template>
            <template v-else-if="column.tableCustomCellMerger">
              <text :class="{ 'text-red': text * 1 >= 100 }" v-if="'pass_per' == column.dataIndex">{{ $dealThousands(text) }}</text>
              <a href="javascript:;" v-else>{{ $dealThousands((colSpan[record.customerNo] || {})[column.dataIndex] || $numberToFixed(0)) }}</a>
            </template>
            <template v-else-if="['no', 'customer', 'attr', 'unit', 'serial_id', 'serial_name', 'cost_name', 'cost_attr'].indexOf(column.dataIndex) === -1">
              <a href="javascript:;">{{ $dealThousands(text) }}</a>
            </template>
          </template>

          <template #summary>
            <a-table-summary fixed>
              <a-table-summary-row class="gray-bg-def-deep">
                <a-table-summary-cell :index="0" align="center" v-show="getTableColumnsKeys.indexOf('no') > -1">
                  <a-tooltip @click="showMoreTableList">
                    <template #title>{{ pagination ? '点击查看全部' : '点击收起' }}</template>
                    其它
                    <CaretDownOutlined v-if="pagination" />
                    <CaretUpOutlined v-else />
                  </a-tooltip>
                </a-table-summary-cell>
                <a-table-summary-cell :index="1" align="center" v-show="getTableColumnsKeys.indexOf('customer') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="2" align="center" v-show="getTableColumnsKeys.indexOf('attr') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="3" align="center" v-show="getTableColumnsKeys.indexOf('unit') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="4" align="center" v-show="getTableColumnsKeys.indexOf('serial_id') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="5" align="center" v-show="getTableColumnsKeys.indexOf('serial_name') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="6" align="center" v-show="getTableColumnsKeys.indexOf('cost_name') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="7" align="center" v-show="getTableColumnsKeys.indexOf('cost_attr') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('serial_stand_cost') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('serial_stand_stand') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('product_stand_cost_total') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('serial_stand_product_num') > -1">
                  {{ $dealThousands(summaryObject.serial_stand_product_num - topSumObj.serial_stand_product_num) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('product_num_div_serial_stand') > -1">
                  {{ $dealThousands(summaryObject.product_num_div_serial_stand - topSumObj.product_num_div_serial_stand) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('min_div_product_num') > -1"
                  >/
                  <!-- {{$dealThousands(summaryObject.min_div_product_num||0 - topSumObj.min_div_product_num)}}-->
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('pass_per') > -1">
                  /
                  <!--{{$dealThousands(summaryObject.pass_per||0 - topSumObj.pass_per)}}-->
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('stand_in_stock_div') > -1">
                  /
                  <!--{{$dealThousands(summaryObject.stand_in_stock_div||0 - topSumObj.stand_in_stock_div)}}-->
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('is_need_stock_div') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('balance_start') > -1">
                  {{ $dealThousands(summaryObject.balance_start - topSumObj.balance_start) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('deadline_material') > -1">
                  {{ $dealThousands(summaryObject.deadline_material - topSumObj.deadline_material) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('deadline_worker') > -1">
                  {{ $dealThousands(summaryObject.deadline_worker - topSumObj.deadline_worker) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('deadline_money') > -1">
                  {{ $dealThousands(summaryObject.deadline_money - topSumObj.deadline_money) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('balance_end') > -1">
                  {{ $dealThousands(summaryObject.balance_end - topSumObj.balance_end) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('amount') > -1"> {{ $dealThousands(summaryObject.amount - topSumObj.amount) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('serial_product_num') > -1">
                  {{ $dealThousands(summaryObject.serial_product_num - topSumObj.serial_product_num) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('serial_stand_actuality_cost') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('product_stand_actuality_product_num') > -1">
                  {{ summaryObject.product_stand_actuality_product_num || 0 }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_reduce') > -1"> {{ summaryObject.sold_num_reduce || 0 }}</a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('sold_num_reduce_cost') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('serial_stand_actuality_cost_total_current') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('serial_stand_product_num_stand_actuality_min') > -1">
                  /
                  <!--{{$dealThousands(summaryObject.serial_stand_product_num_stand_actuality_min - topSumObj.serial_stand_product_num_stand_actuality_min)}}-->
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('trans_in_stock_money') > -1">
                  /
                  <!--{{$dealThousands(summaryObject.trans_in_stock_money - topSumObj.trans_in_stock_money)}}-->
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('sold_num') > -1">
                  /
                  <!--{{$dealThousands(summaryObject.sold_num - topSumObj.sold_num)}}-->
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('need_stock') > -1">
                  /
                  <!--{{$dealThousands(summaryObject.need_stock - topSumObj.need_stock)}}-->
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('is_need_stock') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('stock') > -1">
                  /
                  <!--{{$dealThousands(summaryObject.stock - topSumObj.stock)}}-->
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('sold_num_begin') > -1">
                  /
                  <!--{{$dealThousands(summaryObject.sold_num_begin - topSumObj.sold_num_begin)}}-->
                </a-table-summary-cell>
                <!--<a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('need_stock') > -1">
                  /
                  {{$dealThousands(summaryObject.need_stock - topSumObj.need_stock)}}
                </a-table-summary-cell>-->
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('sold_num_end') > -1">
                  /
                  <!--{{$dealThousands(summaryObject.sold_num_end - topSumObj.sold_num_end)}}-->
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('sum_price_come_in') > -1"> / </a-table-summary-cell>

                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('stock_cost_price_begin') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('stock_cost_to_sold_money_begin') > -1">
                  /
                  <!--{{$dealThousands(summaryObject.stock_cost_to_sold_money_begin - topSumObj.stock_cost_to_sold_money_begin)}}-->
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('stock_cost_to_sold_money_current') > -1">
                  /
                  <!--{{$dealThousands(summaryObject.stock_cost_to_sold_money_current - topSumObj.stock_cost_to_sold_money_current)}}-->
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('sold_money_fifo') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('stock_cost_to_sold_money_current_actuality') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('sold_money_fifo_actuality') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('cost_avco') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('price_avco') > -1"> / </a-table-summary-cell>
              </a-table-summary-row>
            </a-table-summary>
            <a-table-summary fixed>
              <a-table-summary-row class="gray-bg-def-deep">
                <a-table-summary-cell :index="0" align="center" v-show="getTableColumnsKeys.indexOf('no') > -1"> 总计 </a-table-summary-cell>
                <a-table-summary-cell :index="1" align="center" v-show="getTableColumnsKeys.indexOf('customer') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="2" align="center" v-show="getTableColumnsKeys.indexOf('attr') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="3" align="center" v-show="getTableColumnsKeys.indexOf('unit') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="4" align="center" v-show="getTableColumnsKeys.indexOf('serial_id') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="5" align="center" v-show="getTableColumnsKeys.indexOf('serial_name') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="6" align="center" v-show="getTableColumnsKeys.indexOf('cost_name') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="7" align="center" v-show="getTableColumnsKeys.indexOf('cost_attr') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('serial_stand_cost') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('serial_stand_stand') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('product_stand_cost_total') > -1"> / </a-table-summary-cell>

                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('serial_stand_product_num') > -1">
                  {{ $dealThousands(summaryObject.serial_stand_product_num || 0) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('product_num_div_serial_stand') > -1">
                  {{ $dealThousands(summaryObject.product_num_div_serial_stand) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('min_div_product_num') > -1">
                  {{ $dealThousands(summaryObject.min_div_product_num || 0) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('pass_per') > -1">
                  <text :class="{ 'text-red': (summaryObject.pass_per || 0) * 1 >= 100 }">{{ $dealThousands(summaryObject.pass_per || 0) }}</text>
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stand_in_stock_div') > -1"> {{ $dealThousands(summaryObject.stand_in_stock_div || 0) }} </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('is_need_stock_div') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('balance_start') > -1"> {{ $dealThousands(summaryObject.balance_start || 0) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('deadline_material') > -1"> {{ $dealThousands(summaryObject.deadline_material || 0) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('deadline_worker') > -1"> {{ $dealThousands(summaryObject.deadline_worker || 0) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('deadline_money') > -1"> {{ $dealThousands(summaryObject.deadline_money || 0) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('balance_end') > -1"> {{ $dealThousands(summaryObject.balance_end || 0) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('amount') > -1"> {{ $dealThousands(summaryObject.amount || 0) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('serial_product_num') > -1"> {{ $dealThousands(summaryObject.serial_product_num || 0) }} </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('serial_stand_actuality_cost') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('product_stand_actuality_product_num') > -1">
                  {{ summaryObject.product_stand_actuality_product_num || 0 }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_reduce') > -1"> {{ $dealThousands(summaryObject.sold_num_reduce || 0) }} </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('sold_num_reduce_cost') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('serial_stand_actuality_cost_total_current') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('serial_stand_product_num_stand_actuality_min') > -1">
                  {{ summaryObject.serial_stand_product_num_stand_actuality_min }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('trans_in_stock_money') > -1"> {{ $dealThousands(summaryObject.trans_in_stock_money) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num') > -1"> {{ $dealThousands(summaryObject.sold_num) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('need_stock') > -1"> {{ $dealThousands(summaryObject.need_stock) }} </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('is_need_stock') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock') > -1"> {{ $dealThousands(summaryObject.stock) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_begin') > -1"> {{ $dealThousands(summaryObject.sold_num_begin) }} </a-table-summary-cell>
                <!-- <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('need_stock') > -1"> {{ summaryObject.need_stock }} </a-table-summary-cell> -->
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_end') > -1"> {{ $dealThousands(summaryObject.sold_num_end) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sum_price_come_in') > -1"> {{ $dealThousands(summaryObject.sum_price_come_in) }} </a-table-summary-cell>

                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('stock_cost_price_begin') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock_cost_to_sold_money_begin') > -1">
                  {{ $dealThousands(summaryObject.stock_cost_to_sold_money_begin) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock_cost_to_sold_money_current') > -1">
                  {{ $dealThousands(summaryObject.stock_cost_to_sold_money_current) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_money_fifo') > -1"> {{ $dealThousands(summaryObject.sold_money_fifo) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock_cost_to_sold_money_current_actuality') > -1">
                  {{ $dealThousands(summaryObject.stock_cost_to_sold_money_current_actuality) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_money_fifo_actuality') > -1">
                  {{ $dealThousands(summaryObject.sold_money_fifo_actuality) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-show="getTableColumnsKeys.indexOf('cost_avco') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('price_avco') > -1"> {{ $dealThousands(summaryObject.price_avco) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('slide_money_plan') > -1"> {{ $dealThousands(summaryObject.slide_money_plan) }} </a-table-summary-cell>
                <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('slide_money') > -1"> {{ $dealThousands(summaryObject.slide_money) }} </a-table-summary-cell>
              </a-table-summary-row>
            </a-table-summary>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ExclamationCircleOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue'
import { numberToFixed, checkZero, tableDefaultPage, tableDefautlLine } from '@/utils/util'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import tableSort from '../../components/tableSort.vue'

//隐藏排序功能
const tableSortFnc = () => {}

export default {
  name: 'SolutionTable',
  components: {
    ExclamationCircleOutlined,
    ToolsItemIntro,
    CaretDownOutlined,
    CaretUpOutlined
  },
  props: {
    planId: {
      type: [Number, String],
      default: 0
    },
    isPrint: {
      type: Boolean,
      default: false
    },
    hasActualityData: {
      type: Boolean,
      default: false
    },

    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    }
  },
  mixins: [tableSort],
  data() {
    return {
      showWay: '1',
      tableData: [],
      colSpan: {},
      summaryObject: {},
      tableColumns: [
        { _showWay: 'fixed', title: '产品编码ID', dataIndex: 'no', align: 'center', width: 100, fixed: 'left', resizable: true, ellipsis: true, ...tableSortFnc('no') },
        { _showWay: 'fixed', title: '客户', dataIndex: 'customer', align: 'center', width: 120, fixed: 'left', resizable: true, ellipsis: true, ...tableSortFnc('customer') },
        { _showWay: 'fixed', title: '产品规格/描述', dataIndex: 'attr', align: 'center', width: 80, fixed: 'left', resizable: true, ellipsis: true },
        { _showWay: 'fixed', title: '计量单位', dataIndex: 'unit', align: 'center', width: 100, fixed: 'left', resizable: true, ellipsis: true },
        { _showWay: 'fixed', title: '工序（组）ID', dataIndex: 'serial_id', align: 'center', width: 80, fixed: 'left', resizable: true, ellipsis: true },
        { _showWay: 'fixed', title: '工序（组）名称', dataIndex: 'serial_name', align: 'center', width: 80, fixed: 'left', resizable: true, ellipsis: true },
        { _showWay: 'fixed', title: '基本成本中心', dataIndex: 'cost_name', align: 'center', ellipsis: true, width: 80, fixed: 'left', resizable: true },
        { _showWay: 'fixed', title: '成本中心属性', dataIndex: 'cost_attr', align: 'center', ellipsis: true, width: 80, fixed: 'left', resizable: true },

        { _showWay: '2', title: '工序标准单位变动成本', dataIndex: 'serial_stand_cost', align: 'right', width: 100, resizable: true, ellipsis: true },
        { _showWay: '2', title: '产品标准用量', dataIndex: 'serial_stand_stand', align: 'right', width: 100, resizable: true, ellipsis: true },
        {
          _showWay: '2',
          title: '产品标准成本',
          dataIndex: 'product_stand_cost_total',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true
        },
        {
          _showWay: '2',
          title: '工序（组）计划产量',
          dataIndex: 'serial_stand_product_num',
          align: 'right',
          width: 120,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('serial_stand_product_num')
        },
        // { _showWay: '2', title: '工序（组）计划产量折合产品产量', dataIndex: 'product_num_div_serial_stand', align: 'right', width: 100, resizable: true, ellipsis: true },
        {
          _showWay: '2',
          title: '产品计划产量',
          dataIndex: 'min_div_product_num',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('min_div_product_num')
        },
        // {
        //   _showWay: '2',
        //   title: '客户产品合格率(%)',
        //   dataIndex: 'pass_per',
        //   align: 'right',
        //   width: 100,
        //   customCell: this.tableCustomCell,
        //   tableCustomCellMerger: true,
        //   resizable: true,
        //   ellipsis: true,
        //   ...tableSortFnc('pass_per')
        // },
        {
          _showWay: '2',
          title: '产品计划入库量',
          dataIndex: 'stand_in_stock_div',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('stand_in_stock_div')
        },
        // {
        //   _showWay: '2',
        //   title: '计划是否满足销售',
        //   dataIndex: 'is_need_stock_div',
        //   align: 'right',
        //   width: 100,
        //   customCell: this.tableCustomCell,
        //   tableCustomCellMerger: true,
        //   resizable: true,
        //   ellipsis: true
        // },

        { _showWay: '3', title: '期初余额', dataIndex: 'balance_start', align: 'right', width: 80, resizable: true, ellipsis: true, ...tableSortFnc('balance_start') },
        { _showWay: '3', title: '本期投入-料', dataIndex: 'deadline_material', align: 'right', width: 80, resizable: true, ellipsis: true, ...tableSortFnc('deadline_material') },
        { _showWay: '3', title: '本期投入-工', dataIndex: 'deadline_worker', align: 'right', width: 80, resizable: true, ellipsis: true, ...tableSortFnc('deadline_worker') },
        { _showWay: '3', title: '本期投入-费', dataIndex: 'deadline_money', align: 'right', width: 80, resizable: true, ellipsis: true, ...tableSortFnc('deadline_money') },
        { _showWay: '3', title: '期末余额', dataIndex: 'balance_end', align: 'right', width: 100, resizable: true, ellipsis: true, ...tableSortFnc('balance_end') },
        { _showWay: '3', title: '净投入额', dataIndex: 'amount', align: 'right', width: 100, resizable: true, ellipsis: true, ...tableSortFnc('amount') },
        { _showWay: '3', title: '工序（组）实际产量', dataIndex: 'serial_product_num', align: 'right', width: 100, resizable: true, ellipsis: true, ...tableSortFnc('serial_product_num') },
        { _showWay: '3', title: '工序实际单位成本', dataIndex: 'serial_stand_actuality_cost', align: 'right', width: 100, resizable: true, ellipsis: true },
        // { _showWay: '3', title: '工序（组）实际产量折合产品产量', dataIndex: 'product_stand_actuality_product_num', align: 'right', width: 100, resizable: true, ellipsis: true },
        { _showWay: '3', title: '剩余工序产量', dataIndex: 'sold_num_reduce', align: 'right', ellipsis: true, width: 80, resizable: true },
        { _showWay: '3', title: '剩余工序实际单位成本', dataIndex: 'sold_num_reduce_cost', align: 'right', ellipsis: true, width: 80, resizable: true },

        {
          _showWay: '3',
          title: '完工产品单位成本',
          dataIndex: 'serial_stand_actuality_cost_total_current',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true
        },
        {
          _showWay: '3',
          title: '产品入库量',
          dataIndex: 'serial_stand_product_num_stand_actuality_min',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('serial_stand_product_num_stand_actuality_min')
        },
        {
          _showWay: '3',
          title: '产品入库金额',
          dataIndex: 'trans_in_stock_money',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('trans_in_stock_money')
        },
        {
          _showWay: '3',
          title: '销量',
          dataIndex: 'sold_num',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('sold_num')
        },
        // {
        //   _showWay: '3',
        //   title: '销售净需求',
        //   dataIndex: 'need_stock',
        //   align: 'right',
        //   width: 100,
        //   customCell: this.tableCustomCell,
        //   tableCustomCellMerger: true,
        //   resizable: true,
        //   ellipsis: true,
        //   ...tableSortFnc('need_stock')
        // },
        // {
        //   _showWay: '3',
        //   title: '实际是否满足销售',
        //   dataIndex: 'is_need_stock',
        //   align: 'right',
        //   width: 100,
        //   customCell: this.tableCustomCell,
        //   tableCustomCellMerger: true,
        //   resizable: true,
        //   ellipsis: true
        // },
        // {
        //   _showWay: 'fixed',
        //   title: '需销售的产品期初库存',
        //   dataIndex: 'stock',
        //   align: 'right',
        //   width: 100,
        //   customCell: this.tableCustomCell,
        //   tableCustomCellMerger: true,
        //   resizable: true,
        //   ellipsis: true,
        //   ...tableSortFnc('stock')
        // },
        //_showWay:'3', { title: '工序完工成本', dataIndex: 'serial_stand_actuality_cost_total', align: 'center', width: 100,resizable: true },
        {
          _showWay: 'fixed',
          title: '期初转销售出库量',
          dataIndex: 'sold_num_begin',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('sold_num_begin')
        },
        {
          _showWay: 'fixed',
          title: '入库转销售出库量',
          dataIndex: 'need_stock',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('need_stock')
        },
        //_showWay:'3', { title: '完工入库实际单位变动成本', dataIndex: 'trans_in_money', align: 'center', width: 100 , customCell: this.tableCustomCell, tableCustomCellMerger:true,resizable: true},
        // {
        //   _showWay: 'fixed',
        //   title: '需销售的产品期末结存量',
        //   dataIndex: 'sold_num_end',
        //   align: 'right',
        //   width: 100,
        //   customCell: this.tableCustomCell,
        //   tableCustomCellMerger: true,
        //   resizable: true,
        //   ellipsis: true,
        //   ...tableSortFnc('sold_num_end')
        // },

        {
          _showWay: 'fixed',
          title: '收入',
          dataIndex: 'sum_price_come_in',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('sum_price_come_in')
        },
        // {
        //   _showWay: 'fixed',
        //   title: '期初单位成本',
        //   dataIndex: 'stock_cost_price_begin',
        //   align: 'right',
        //   width: 100,
        //   customCell: this.tableCustomCell,
        //   tableCustomCellMerger: true,
        //   resizable: true,
        //   ellipsis: true
        // },
        {
          _showWay: 'fixed',
          title: '期初转销售成本（FIFO）',
          dataIndex: 'stock_cost_to_sold_money_begin',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('stock_cost_to_sold_money_begin')
        },
        {
          _showWay: '2',
          title: '完工转销售成本-计划（FIFO）',
          dataIndex: 'stock_cost_to_sold_money_current',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('stock_cost_to_sold_money_current')
        },
        {
          _showWay: '2',
          title: '转销售成本合计-计划（FIFO）',
          dataIndex: 'sold_money_fifo',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('sold_money_fifo')
        },

        {
          _showWay: '3',
          title: '完工转销售成本-实际（FIFO）',
          dataIndex: 'stock_cost_to_sold_money_current_actuality',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('stock_cost_to_sold_money_current_actuality')
        },

        {
          _showWay: '3',
          title: '转销售成本合计-实际（FIFO）',
          dataIndex: 'sold_money_fifo_actuality',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('sold_money_fifo_actuality')
        },
        {
          _showWay: 'fixed',
          title: '加权平均单位变动成本（AVCO）',
          dataIndex: 'cost_avco',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true
        },
        {
          _showWay: 'fixed',
          title: '转销售成本合计（AVCO）',
          dataIndex: 'price_avco',
          align: 'right',
          width: 100,
          customCell: this.tableCustomCell,
          tableCustomCellMerger: true,
          resizable: true,
          ellipsis: true,
          ...tableSortFnc('price_avco')
        }

        // {
        //   _showWay: 'fixed',
        //   title: '边际收益（计划）',
        //   dataIndex: 'slide_money_plan',
        //   align: 'right',
        //   width: 100,
        //   customCell: this.tableCustomCell,
        //   tableCustomCellMerger: true,
        //   resizable: true,
        //   ellipsis: true,
        //   ...tableSortFnc('slide_money_plan')
        // },
        // {
        //   _showWay: 'fixed',
        //   title: '边际收益（实际）',
        //   dataIndex: 'slide_money',
        //   align: 'right',
        //   width: 100,
        //   customCell: this.tableCustomCell,
        //   tableCustomCellMerger: true,
        //   resizable: true,
        //   ellipsis: true,
        //   ...tableSortFnc('slide_money')
        // },
      ],

      topSumObj: {
        // 工序（组）计划产量
        serial_stand_product_num: 0,
        //工序（组）计划产量折合产品产量
        product_num_div_serial_stand: 0,
        //产品计划产量
        min_div_product_num: 0,
        //产品计划入库量
        stand_in_stock_div: 0,
        //产品计划产量
        pass_per: 0,
        //期初余额
        balance_start: 0,
        //本期投入-料
        deadline_material: 0,
        //本期投入-工
        deadline_worker: 0,
        //    本期投入-费
        deadline_money: 0,
        //期末余额
        balance_end: 0,
        //净投入额
        amount: 0,
        //工序（组）实际产量
        serial_product_num: 0,
        //工序（组）实际产量折合产品产量
        product_stand_actuality_product_num: 0,
        //剩余工序产量
        sold_num_reduce: 0,
        //产品入库量
        serial_stand_product_num_stand_actuality_min: 0,
        //入库量金额
        trans_in_stock_money: 0,
        //销量
        sold_num: 0,
        //销售净需求
        need_stock: 0,
        //需销售的产品期初库存
        stock: 0,
        //期初转销售出库量
        sold_num_begin: 0,
        //入库转销售出库量
        // need_stock: 0,
        //需销售的产品期末结存量
        sold_num_end: 0,
        //期初转销售成本（FIFO）
        stock_cost_to_sold_money_begin: 0,
        //完工转销售成本（FIFO）
        stock_cost_to_sold_money_current: 0,
        //转销售成本合计（FIFO）
        sold_money_fifo: 0,
        //转销售成本合计（AVCO）
        price_avco: 0,

        upSortData: []
      }
    }
  },
  computed: {
    getTableColumns() {
      const data = []
      // if (this.showWay == '1') {
      //   return this.tableColumns
      // }
      this.tableColumns.map((item) => {
        if (item._showWay == 'fixed') {
          data.push(item)
        } else if (item._showWay == this.showWay) {
          data.push(item)
        } else if (this.showWay == '1') {
          if (this.hasActualityData) {
            data.push(item)
          } else {
            if (item._showWay != '3') {
              data.push(item)
            }
          }
        }
      })
      return data
    },
    getTableColumnsKeys() {
      return this.getTableColumns.map((item) => {
        return item.dataIndex
      })
    },
    getTableData() {
      const data = []
      this.tableData.map((item) => {
        if (item.showBase !== 0) {
          data.push(item)
        }
      })
      return data
    }
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    initTableData(options) {
      const newSort = options.tableData || [] //newData.sort(tableSortFnc("side_money").sorter.compare)
      this.tableData = newSort
      this.colSpan = options.colSpan || {}
      this.summaryObject = options.summaryObject || {}
      //计算其它加个
      this.calOtherSum(newSort)
    },
    // showMoreTableList(){
    //   if(this.pagination===false){
    //     this.pagination = tableDefaultPage();
    //     this.calOtherSum(this.topSumObj.upSortData,false)

    //   }else{
    //     this.pagination = false;
    //   }
    // },
    // getTableSum(field){
    //   let number = 0;
    //   this.tableData.map((item)=>{
    //     number += item[field]*1
    //   })
    //   return numberToFixed(number);
    // },
    // showMoreTableList(){
    //   if(this.pagination===false){
    //     this.pagination = tableDefaultPage();
    //     this.calOtherSum(this.topSumObj.upSortData,false)

    //   }else{
    //     this.pagination = false;
    //   }

    // },
    // //计算其它加个
    // calOtherSum(currentDataSource,recordUpData=true){
    //   const totalObj ={

    //   }
    //   for(let key in this.topSumObj){
    //     if(key=="upSortData") continue;

    //     const pageSize = tableDefautlLine;//this.pagination.pageSize||0
    //     const field = key
    //     const topSumObj_field = key;
    //     totalObj[topSumObj_field] = 0;
    //     let total = 0;
    //     for(let i=0;i<currentDataSource.length;i++){
    //       const info = currentDataSource[i];

    //       if(i>=pageSize){
    //         break;
    //       }
    //       totalObj[topSumObj_field] = totalObj[topSumObj_field] + info[field]*1;
    //     }
    //   }
    //   if(recordUpData){
    //     totalObj.upSortData = currentDataSource;
    //   }else{
    //     totalObj.upSortData = this.topSumObj.upSortData
    //   }
    //   // console.log(totalObj)
    //   this.topSumObj = totalObj;
    // },
    // onTableChange(pagination, filters, sorter, {currentDataSource,action}){
    //   // console.log('>>>>>>>>>>>>>>>>>> onTableChange <<<<<<<<<<<<<<<<<<<')
    //   if(action=='sort'){
    //     return this.calOtherSum(currentDataSource)
    //   }

    // },

    tableCustomCell(item, index) {
      const customerNo = item.customerNo || ''
      const customerNoObj = this.colSpan[customerNo] || {}
      const arr = customerNoObj.index || []
      const len = arr.length || 0
      const colSpanIndex = arr.indexOf(index)
      return { rowSpan: colSpanIndex === 0 ? len : 0 }
    }
  }
}
</script>
