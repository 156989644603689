<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserInfo',
})
</script>
<template>
    <div class="AuthorInfo">
        <div class="AuthorInfo-avatar">
            <router-link :to="{ path: '/account/people/answers', query: { user_id: info.id } }">
                <a-avatar :size="44" :src="info.avatar" />
            </router-link>
        </div>
        <div class="AuthorInfo-content">
            <div class="AuthorInfo-head">
                <router-link class="UserLink AuthorInfo-name" :to="{ path: '/account/people/answers', query: { user_id: info.id } }">
                    {{ info.name || '用户KDIGHEI'}}
                </router-link>
                <div class="AuthorInfo-tag">
                    <UserLevel />
                </div>
            </div>
            <div class="AuthorInfo-detail">
                <span>{{ info.ident_name }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup name="UserInfo">
import { reactive, watch, onMounted, toRefs } from 'vue'
import baseService from '@/utils/http/axios'

import UserLevel from './UserLevel.vue'

const props = defineProps({
    info: {
        type: Object,
        default: () => { }
    },
})


</script>
<style lang="less" >
.AuthorInfo {
    display: flex;
    align-items: center;

    .AuthorInfo-content {
        margin-left: 15px;

        .AuthorInfo-head {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            a {
                color: #333333;
                font-weight: bold;
            }

            .AuthorInfo-tag {
                margin-left: 15px;

            }
        }

        .AuthorInfo-detail {
            display: flex;
            align-items: center;

            span {
                display: inline-flex;
                align-items: center;
                color: #666;
                font-size: 12px;

                &+span {
                    &::before {
                        content: '';
                        display: block;
                        width: 1px;
                        height: 12px;
                        background-color: #999;
                        margin: 0px 6px;

                    }
                }
            }
        }
    }
}
</style>
