import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/style/global.less';
import '@/style/solution.less';
import i18n from '@/locales/useI18n'
import 'virtual:svg-icons-register';
import setupDefaultSetting from '@/utils/setupDefaultSetting'
import initConfig from '@/utils/initConfig'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css'

import "@/utils/default-passive-events"
 // ...纯表格
 import VxeTable from 'vxe-table'
 import 'vxe-table/lib/style.css'
 // ...

 // ...可选 UI
 import VxeUI from 'vxe-pc-ui'
 import 'vxe-pc-ui/lib/style.css'

//默认组件
import PopupContent from '@/components/PopupContent.vue'
import TipsContent from '@/components/TipsContent.vue'
import MyTablePagination from '@/components/MyTablePagination.vue'
import OperateLimit from '@/components/OperateLimit.vue'

import drag from "v-drag"
const app = createApp(App)
app.use(store)

app.use(drag,{});

app.use(Antd)
app.use(router)
app.use(i18n)
//表格
app.use(VxeUI)
.use(VxeTable)
app.component('MyTablePagination',MyTablePagination)
app.component('PopupContent',PopupContent)
app.component('TipsContent',TipsContent)
app.component('OperateLimit',OperateLimit)

//初始化配置
initConfig(app,store,router)

app.mount('#app');

setupDefaultSetting()

window.env = import.meta.env.MODE

export {
    app,
    store
};
