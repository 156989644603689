import baseService from '@/utils/http/axios'
import ls from '@/utils/Storage'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'

import emitter from '@/utils/eventBus'


export default {
    state: {
        id: 0,
        name: '',
        avatar: '',
        type: 0,
        is_vip:0,
        vip_type: '',
        user_ident: '',
        is_auth: 0,
        phone: '',
        hide_phone: '',
        email: '',
        //消息數量
        noticeNum: 0,
        // 等级
        ident: '',
        level: 0,
        level_name: '',
        level_exp: 0,
        total_exp: 0,
        reduce_exp: 0,
        level_per: '',
        vipUseTool:[],
    },
    getters:{
        userVipLevel(state){
            return state.vip_type||''
        },
        //可以使用的工具栏目
        userVipUseTools(state, getters){
            // toolsFull                                                - 所有工具
            //工具一所有权限
            // tools1_base                                              - 基本数据
            // tools1_extra                                             - 扩展数据
            // tools1_compare                                           - 对比方案

            // tools1_import_mix                                        - 混合表导入
            // tools1_import_fixed                                      - 混合表导入
            // tools1_base_params                                      - 行参数修改
            // tools1_table_line_mod                                    - 行参数修改
            // tools1_table_before                                      - 表格显示行-加成前
            // tools1_table_after                                       - 表格显示行-加成后

            // tools1_fixed                                             - 固定成本
            // tools1_mixed                                             - 混合成本

            // tools1_analysis_yunying                                  - 运营指标
            // tools1_analysis_cost                                     - 成本结构
            // tools1_analysis_side                                     - 边际
            // tools1_analysis_risk                                     - 风险

            // tools1_cvp_before                                        - 折线图-加成前
            // tools1_cvp_after                                         - 折线图-加成后

            // tools1_cvp_line_priceComeInPrice                         - 折线图-总收入
            // tools1_cvp_line_materialCostMixRagePrice                 - 折线图-总成本
            // tools1_cvp_line_managerProfitMoeny                       - 折线图-管理利润
            // tools1_cvp_line_prediction                               - 折线图-预测
            // tools1_cvp_line_materialCostPrice                        - 折线图-变动成本
            // tools1_cvp_line_fixedMoeny                               - 折线图-固定成本
            // tools1_cvp_line_sideMoeny                                - 折线图-边际贡献
            // tools1_cvp_line_profitMoeny                              - 折线图-毛利润
            // tools1_cvp_line_mixMoeny                                 - 折线图-混合成本

            // tools1_scatter_before                                    - 散点图-加成前
            // tools1_scatter_after                                     - 散点图-加成后
            // tools1_scatter_normal                                    - 散点图-纬度（客户/类别）
            // tools1_scatter_full                                      - 散点图-纬度（全部）
            // tools1_data_one                                          - 敏感性-单变量
            // tools1_data_one_before                                   - 敏感性-单变量
            // tools1_data_one_after                                    - 敏感性-单变量
            // tools1_data_multi                                        - 敏感性-多变量
            // tools1_data_multi_before                                 - 敏感性-多变量
            // tools1_data_multi_after                                  - 敏感性-多变量
            
            //工具二所有权限
            // tools2_base                                              - 基本数据
            // tools2_extra                                             - 扩展数据

            //工具三所有权限
            // tools3_base                                              - 基本数据
            
            const data = new Set()
           
         
            // switch(getters.userVipLevel){
            //     case 'vip_company_master':  //企业大师
            //         data.add('toolsFull')
            //         break;
                
            //     case 'vip_company_common':  //企业普通版本
            //         data.add('tools1_extra')
            //         data.add('tools1_mixed')
            //         data.add('tools1_table_after')
            //         data.add('tools1_cvp_after')
            //         data.add('tools1_scatter_after')
            //         data.add('tools1_scatter_normal')
            //         data.add('tools1_data_one')
            //         data.add('tools1_base_params')
            //         data.add('tools1_data_one_after')
            //         data.add('tools1_compare')

            //     case 'vip_person': //个人vip
            //         data.add('tools1_fixed')
            //         data.add('tools1_analysis_cost')
            //         data.add('tools1_cvp_line_managerProfitMoeny')
            //         data.add('tools1_cvp_line_materialCostPrice')
            //         data.add('tools1_cvp_line_profitMoeny')

                    
            //     case 'vip_normal': //普通用户
            //         data.add('tools1_base')
            //         data.add('tools1_table_before')
            //         data.add('tools1_analysis_yunying')
            //         data.add('tools1_analysis_side')
            //         if(!data.has('tools1_cvp_after')){
            //             data.add('tools1_cvp_before')
            //         }

        
            //         data.add('tools1_cvp_line_priceComeInPrice')
            //         data.add('tools1_cvp_line_materialCostMixRagePrice')
            //         data.add('tools1_cvp_line_prediction')
            //         data.add('tools1_cvp_line_fixedMoeny')
            //         data.add('tools1_cvp_line_sideMoeny')
            //         break;
                
            // } 
            return data
        },
        //是否拥有节点权限
        getHasToolAuth:(state,getters)=>(field)=>{
            return true;
            const data = state.vipUseTool
            // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>> getHasToolAuth <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
            // console.log(getters.userVipLevel)
            // console.log(data)
            if(data.indexOf('*')>-1){
                return true;
            }else{
                return data.indexOf(field)>-1
            }
        },
        //检查使用工具权限
        checkToolPromise:(state,getters)=>(field)=>{
            const data = state.vipUseTool
            if(data.indexOf('*')>-1){
                return true;
            }else{
                return data.indexOf(field)>-1
            }
        }
    },
    mutations: {
        updateUserInfo(state, payload) {
            state.id = payload.id || 0
            state.name = payload.name || ''
            state.avatar = payload.avatar || ''
            state.type = payload.type || 0
            state.is_vip = payload.is_vip||0
            state.vip_type = payload.vip_type || 0
            state.user_ident = payload.user_ident || 0
            state.ident = payload.ident || 0
            state.is_auth = payload.is_auth || 0
            state.phone = payload.phone || ''
            state.hide_phone = payload.hide_phone || ''
            state.email = payload.email || ''
            state.vipUseTool = payload.vipUseTool||[]
        },
        updateUserIdent(state, payload) {
            state.ident = payload
        },
        updateVipType(state,payload){
            state.vip_type = payload.vip_type
        },
    },
    actions: {
        noticeNum(context) {
            baseService.post('/api/notice/info',).then(res => {
                const data = res.data || {}
                context.state.noticeNum = data.num || 0
                console.log(data.num, 'noticeNum>>>>>>>>>>>>>')
            }).catch(err => {
                console.error(err)
            })
        },
        // 等級
        userLevel(context) {
            baseService.post('/api/user/level').then(res => {
                const data = res.data || {}
                context.state.level = data.level || 0
                context.state.level_name = data.level_name || ''
                context.state.level_exp = data.level_exp || 0
                context.state.total_exp = data.total_exp || 0
                context.state.reduce_exp = data.reduce_exp || 0
                context.state.level_per = data.level_per || ''
            }).catch(err => {
                console.error(err)
            })
        },
        flushUserInfo(context) {
            const token = ls.get(ACCESS_TOKEN)
            if (!token) {
                return;
            }
            return baseService.post('/api/user/flush', { token: token }).then(res => {
                const data = res.data || {}
                const userInfo = data.userInfo || {}
                context.commit('updateUserInfo', userInfo)
            }).catch(err => {
                console.error(err)
                if (err.code === -2) { //凭证有误,需要重新登录
                    emitter.emit('axios_goto_login')
                }
            })
        }
    }
}
