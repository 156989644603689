<template>
    <!-- <div class="guide-box" v-if="moduleName">
        <div class="subtitle">假设背景</div>
        <div class="RichText" >{{typeContent}}</div>
    </div> -->
   <div class="guide-module">
        <div class="subtitle">{{moduleName}}</div>
        <pre class="RichText">{{typeContent}}</pre>
    </div>
    
</template>

<script>
export default {
    name:'ToolsItemIntro',
    props:{
        moduleName:{
            type:String,
            default:''
        },
        type:{
            type:String,
            default:''
        },
        field:{
            type:String,
            default:''
        },
    },
    data(){
        return {
            
        }
    },
    computed:{
        itemObj(){
            return this.$store.state.tools.itemIntro[this.type]||{}
        },
        typeContent(){
            return this.itemObj[this.field]||''
        }
    }
}
</script>
