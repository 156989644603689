<template>
  <a-modal :title="title" :width="640" v-model:open="visible" :confirmLoading="loading"  @ok="handleSubmit"
        @cancel="close">
            <a-form ref="formAuth" :rules="rules" :model="form" layout="vertical">
                <a-form-item label="方案名称">
                    <a-input v-model:value.trim="form.name"  ></a-input>
                </a-form-item>
              
            </a-form>


    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
export default {
    name:"SavePlan",
    props:{
        id:{
            type:[String,Number],
            default:0,
        },
        title:{
            type:String,
            default:"方案名称"
        }
    },
    data(){
        return{
            visible: false,
            loading: false,
            form:{
                id:0,
                name:"",
                is_save:1,
            },
            callback:null,
            rules:{},
        }
    },
    methods:{
        open(id,params,callback){
            this.form.id = id;
            this.form.name = params.name||""
            this.form.remark_base = params.remark_base||""
            this.form.remark_extra = params.remark_extra||""
            this.form.tool_type = params.tool_type||""
            
            this.form.page_params = params.params||{}

            this.visible = true;
            this.callback = callback;
        },
        close(){
            this.visible = false;
        },
        handleSubmit(){
            if(!this.form.name){
                message.error('请输入名称')
                return;
            }
            this.$network('/api/tools/planSave',this.form).then((res)=>{
                message.success('保存成功')
                setTimeout(()=>{
                    this.callback()
                },800)
            })
        },
    }
}
</script>

<style>

</style>