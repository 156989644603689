<template>
  <div id="solutionTemplatePDFExtra" class="solutionTemplatePDF">
    <a-watermark :content="getWaterName">
      <div class="solution-box">
        <div class="headBox">
          <div class="headBoxBg">
            <img src="@/assets/images/solution_img.png" />
            <div class="headBoxLogo"><img :src="getPlanLogo" class="logo" alt="logo" /></div>
          </div>
          <div class="headBoxContent">
            <div class="headTitle">
              <div class="title">{{ getPlanName }}</div>
              <div class="subtitle">{{ getPlanName_en }}</div>
            </div>
            <div class="author">
              <div class="author_name">{{ getWaterName }}</div>
              <div class="author_info">
                <span>编制人：{{ userInfo.name }}</span>
                <span>编制时间：{{ detail.created_at }}</span>
                <span>打印次数：{{ detail.times }}次</span>
              </div>
            </div>
          </div>
          <div class="headTypeText">
            <VipTag :isText="true" moduleName="printPDF" />
            <span>扩展方案</span>
          </div>
        </div>

        <div class="solution-wrap">
          <div class="solution-first">
            <div class="solution-children">
              <div class="title"><span>工具指引</span></div>
              <div class="row">
                <div class="RichText">
                  <tools-item-intro type="five" field="intro_print_extra" moduleName="background" />
                </div>
              </div>
            </div>
            <!-- 1 整体参数 -->
            <div class="solution-children">
              <a-row :gutter="100">
                <a-col :span="9" v-show="getHasToolAuth('tools1_base_params')">
                  <div class="title"><span>参数调整</span></div>
                  <div class="row">
                    <a-descriptions :column="1" size="small" bordered :labelStyle="{ width: '240px' }">
                      <a-descriptions-item label="含税售价整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_tax_per" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                      <a-descriptions-item label="销量整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_sold_per" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                      <a-descriptions-item label="单位变动成本整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_cost_per" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                      <a-descriptions-item label="产品合格率整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_pass_per" :max="100" :step="0.5" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                    </a-descriptions>
                  </div>
                </a-col>
                <a-col :span="15">
                  <div class="subtitle"><span>工具假设</span></div>
                  <div class="row">
                    <div class="RichText">
                      <tools-item-intro type="five" field="intro_extra" moduleName="background" />
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <!-- 2 CVP折线图 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="12" v-show="getHasToolAuth('tools1_scatter_before')">
                  <div class="subtitle"><span>原始数据</span></div>
                  <div class="row">
                    <img class="canvas" :src="svpCatterBeforeImg" />
                  </div>
                </a-col>
                <a-col :span="12" v-show="getHasToolAuth('tools1_cvp_after')">
                  <div class="subtitle"><span>调整后</span></div>
                  <div class="row">
                    <img class="canvas" :src="svpCatterAfterImg" />
                  </div>
                </a-col>
              </a-row>
            </div>

            <!-- 3.1 敏感性分析 -->
            <div class="solution-children">
              <div class="title"><span>敏感性分析</span></div>
              <a-row :gutter="100">
                <a-col :span="12" v-show="getHasToolAuth('tools1_data_one')">
                  <div class="subtitle nobo"><span>单变量分析</span></div>
                  <div id="solutionAnalysisVal">
                    <!-- <solution-struct-operate-table :planId="listQuery.id" :pagination="pagination" :planType="planType" :isPrint="isPrint" /> -->
                  </div>
                </a-col>

                <!-- 3.2 敏感性分析 -->
                <a-col :span="12" v-show="getHasToolAuth('tools1_data_multi')">
                  <div class="subtitle nobo"><span>多变量组合影响</span></div>
                  <div id="solutionAnalysisValMulti">
                    <!-- <solution-struct-operate-table :planId="listQuery.id" :pagination="pagination" :planType="planType" :isPrint="isPrint" /> -->
                  </div>
                </a-col>
              </a-row>
            </div>

            <!-- 5 备注 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="24">
                  <div class="title"><span>编制备注</span></div>
                  <div class="row">
                    <div class="RichText">
                      {{ remark_extra }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>

            <!-- 6 方案解读 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="24">
                  <div class="title"><span>方案解读</span></div>
                  <div class="row">
                    <div class="RichText">
                      {{ planResult_extra }}
                    </div>
                  </div>
                </a-col>
              </a-row>
              <p class="tips">*附表见下页</p>
            </div>
            <div class="solution-children"></div>
          </div>

          <!-- 基础数据 -->
          <div class="solution-children">
            <p class="tip">附表</p>
            <a-row :gutter="100">
              <a-col :span="12">
                <div class="title"><span>加成前数据表</span></div>
                <div class="row">
                  <div id="scatterTableDataBefore"></div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="title"><span>加成后数据表</span></div>
                <div class="row">
                  <div id="scatterTableDataAfter"></div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </a-watermark>
  </div>
</template>

<script>
import VipTag from '@/components/VipTag.vue'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'

import { mapGetters } from 'vuex'

import { message } from 'ant-design-vue'
import { htmlToPDF } from '@/utils/html2pdf'
import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'
//打印模板
export default {
  name: 'solutionTemplatePDF',
  components: {
    VipTag,
    SliderInputBlock,
    ToolsItemIntro
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    detail: {
      type: Object,
      default: function () {
        return {}
      }
    },
    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    },
    remark_extra: {
      type: String,
      default: ''
    },

    planResult_extra: {
      type: String,
      default: ''
    },
    planType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      svpCatterBeforeImg: '',
      svpCatterAfterImg: ''
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    }
  },
  created() {},
  mounted() {},

  methods: {
    async downPDF(pageObj) {
      if (this.getHasToolAuth('tools1_scatter_before')) {
        await this.svpCatterBefore(pageObj)
      }
      if (this.getHasToolAuth('tools1_scatter_after')) {
        await this.svpCatterAfter(pageObj)
      }

      await this.svpAnalysis(pageObj)
      await this.tableData(pageObj)
    },

    //保存图-折线图加成前
    async svpCatterBefore(pageObj) {
      this.svpCatterBeforeImg = pageObj.$refs.svpCatterBefore.generateImage()
    },
    //保存图-折线图加成后
    async svpCatterAfter(pageObj) {
      this.svpCatterAfterImg = pageObj.$refs.svpCatterAfter.generateImage()
    },
    //指标
    async svpAnalysis(pageObj) {
      console.log(pageObj.$refs.solutionAnalysis.$refs.solutionAnalysisVal)
      document.getElementById('solutionAnalysisVal').appendChild(pageObj.$refs.solutionAnalysis.$refs.solutionAnalysisVal)
      document.getElementById('solutionAnalysisValMulti').appendChild(pageObj.$refs.solutionAnalysis.$refs.solutionAnalysisValMulti)
    },
    //表格数据
    async tableData(pageObj) {
      console.log(pageObj.$refs.svpCatterBefore.$refs.tableScatter)

      document.getElementById('scatterTableDataBefore').appendChild(pageObj.$refs.svpCatterBefore.$refs.tableScatter)
      document.getElementById('scatterTableDataAfter').appendChild(pageObj.$refs.svpCatterAfter.$refs.tableScatter)
    }
  }
}
</script>

<style lang="less" >
#solutionTemplatePDFExtra {
  .solution-box {
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
      padding: 6px 12px;
      font-size: 18px;
    }
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
      padding: 6px 5px;
    }
    .ant-table .ant-table-container .ant-table-cell {
      font-size: 16px;
    }
    .solution-wrap {
      .solution-children {
        .row {
          > img.canvas {
            height: 650px;
            margin: auto;
          }
        }
      }
    }
  }
}
</style>
