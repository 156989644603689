import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { ACCESS_TOKEN, PERMISSION, THEME_COLOR } from '@/store/mutation-types'
import { hasPermission } from './permission'
import ls from '@/utils/Storage'
import { setDocumentTitle } from '@/utils/domUtil'
import type { Router } from 'vue-router';
import { notification } from 'ant-design-vue';
import storeUserInfo from '@/store/modules/user';
import baseService from '@/utils/http/axios'
import emitter from '@/utils/eventBus'
import { loginSuccess } from '@/store/modules/helper'
import { systemConfig } from '@/store/reactiveState'
NProgress.configure({ showSpinner: false }) // NProgress Configuration
import config from '@/config/defaultSettings'
const whiteList = ['/mobile','/mobile2','/mobile3','/mobile4','/mobile5','/mobile6','/mobile7','/mobile8','/user/login', '/user/reg','/user/register', '/user/registerResult', '/user/registerPerson', '/user/registerPersonSet', '/user/forgotPassword','/exception/500'] // 不进行拦截的路由名称集合
const defaultRoutePath = '/dashboard/dashboard'

export const setupBeforeEach = (router: Router) => {
   
    router.beforeEach((to, from, next) => {
        // next()
        NProgress.start() // 加载进度条
        if (ls.get(ACCESS_TOKEN)) {
            if(!storeUserInfo.state.id){
                baseService.post('/api/user/flush', { token: ls.get(ACCESS_TOKEN) }).then(res => {
                    const data = res.data || {}
                    const userInfo = data.userInfo || {}
                    emitter.emit('bind_user_info',userInfo);
                    //设置工具主题色
                    systemConfig.commit(THEME_COLOR, userInfo.plan_theme_color ?? config.themeColor)
                    NProgress.done()
                    next()
                }).catch(err => {
                    console.error(err)
                    if (err.code === -2) { //凭证有误,需要重新登录
 
                        ls.clear()
                        // emitter.emit('axios_goto_login')
                        NProgress.done()
                        next({ path: '/user/login', query: { } })
                    }else{
                        next()
                    }
                })
            }else{
                NProgress.done()
                next()
            }
            
        }else{
            if (whiteList.includes(to.path as any)) {
                // 在免登录白名单，直接进入
                next()
            } else {
                next({ path: '/user/login', query: { redirect: to.fullPath } })
                NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
            }
        }
        
    })

    // 路由懒加载失败时的提示
    router.onError((error) => {
        console.log(">>>>>>>>>>>>>>>>>>>>>> router.onError <<<<")
        console.error(error)
        if (window.env === 'localhost') {
            notification.error({
                message: 'Dynamic import error',
                description: error.stack,
            });
        } else {
            router.push({ path: '/exception/500', params: { errorMsg: error.stack } })
        }
    })
}

export const setupAfterEach = (router: Router) => {
    router.afterEach(() => {
        NProgress.done() // finish progress bar
    })
}
