<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FileUpload',
})
</script>
<template>
    <a-button type="primary" class="getCaptcha" :disabled="state.smsSendBtn" @click.stop.prevent="getCaptcha">
        {{ !state.smsSendBtn && '获取验证码' || (state.time + ' s') }}
    </a-button>
</template>

<script lang="ts" setup name="FileUpload">
import { reactive, watch, onMounted, toRefs } from 'vue'
import baseService from '@/utils/http/axios'

const props = defineProps({
    phone: {
        type: String,
        default: '',
    },
    email: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: ''
    },
    mold: {
        type: String,
        default: 'sms'
    }

})
console.log(props, "<<")
const state = reactive({
    time: 60,
    smsSendBtn: false,
})

// const getCaptcha = (e) => {
//     useGetCaptcha(e, state, phone, type)
// }

const getCaptcha = () => {
    state.smsSendBtn = true;
    const interval = window.setInterval(() => {
        if (state.time-- <= 0) {
            state.time = 60;
            state.smsSendBtn = false;
            window.clearInterval(interval);
        }
    }, 1000);

    let url = "/api/sms/send";
    let requestBody = {
        phone: props.phone,
        type: props.type
    }
    if (props.mold == "email") {
        url = "/api/email/send";
        requestBody = {
            email: props.email,
            type: props.type
        }
    }

    baseService.post(url, requestBody).then(res => {

    }).catch((err) => {
        clearInterval(interval);
        state.time = 60;
        state.smsSendBtn = false;
    });
};
</script>
<style lang="less" scoped>
.getCaptcha {
    width: 100px;
}
</style>
