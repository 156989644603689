<script>
import { message } from 'ant-design-vue'
import { numberToFixed, debounce, fnc_excelData_yc_after,fnc_excelData_yc_line, checkZero,tableSortFnc,roundToDotNumber,tableDefaultPage  } from '@/utils/util'
import { mapGetters } from 'vuex'
import  CVPCalMixIn from '../../cloud/calMixIn.vue'

import SliderInputBlock from '@/components/SliderInputBlock.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import SolutionSvp from './SolutionSvp.vue'
import SolutionAnalysis from './SolutionAnalysis.vue'

const groupTypeName = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }
export default {
  components: {
    SliderInputBlock,
    ToolsItemIntro,
    SolutionSvp,
    SolutionAnalysis    
  },
  data(){
    return {
      params_tax_per:0,
      params_sold_per:0,
      params_cost_per:0,
      params_pass_per:0,
      initDataState:'',


      downLoading:false,
      planType:'base',
      planBase:'',
      baseSvp:'',
      detail: {},
      listQuery:{},

      tableList:[],

      onChangeParamsTimer:0,
      hasActualityData:true,
      isPrint:false,

      remark_base: '',
      remark_extra: '',
      remark_base_time: '',
      remark_base_mobile:'',
      remark_extra_mobile:'',
      remark_extra_time: '',


      //// CVP工具I（基于BOM）已保存方案1
      cvpDetail:{},
      svp_tableData:[],
      svp_tableData_fixed:[],
      svp_tableData_mix:[],
      svp_tableData_mix_columns:[],
      scatter_tableData:[],
      //对比方案数据
      ycTableList:[],

    }
  },
  mixins:[CVPCalMixIn],
  created() {
    this.listQuery.id = this.$route.query.id || 0
    this.planType = this.$route.query.planType == 'extra' ? 'extra' : 'base'

    this.getPlanDetail()
  },
  computed:{
    ...mapGetters(['getHasToolAuth']),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    }, userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    },
    getTitle() {
      return ''
    },

  },

  methods: {
    getPlanDetail(){
      this.$network('/api/tools/planDetail', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          this.detail = data.detail || {}
          this.planBase = this.detail.base||'step4_cvp';
          console.log(this.detail)
          this.$nextTick(()=>{
            this.getData()
            this.getCvpData()
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },

    getCvpData(){ // CVP工具I（基于BOM）已保存方案1
      //确定基表--只有历史方案vs历史方案 才会又这项过滤
      let planId = this.detail.step4_cvp||''
      if(this.detail.step4_cvp2){
        //确定基表--只有历史方案vs历史方案 才会又这项过滤
        planId = this.detail[this.planBase]
      }
      this.$network('/api/tools/planDetail', {id:planId})
        .then((res) => {
          const data = res.data || {}
          this.cvpDetail = data.detail || {}

          const mixColumnsFnc = (values) => {
            const data = []
            values.map(function (item, index) {
              if (index > 0) {
                item.diffMoney = 0
                data.push(item)
              }
            })
            return data
          }
          this.$nextTick(()=>{
            let tableData = null
            let tableData_fixed = null
            let tableData_mix = null
            let tableData_mix_columns = null
            let hasMixData = false


            //固定成本
            this.typePlanData_fixed(planId, (data) => {
                tableData_fixed = data.list || []
                this.diffTableSum(
                  tableData,
                  tableData_fixed,
                  hasMixData,
                  tableData_mix,
                  tableData_mix_columns,
                )
              })

            //混合成本
            this.typePlanData_mix(planId, (data) => {
                hasMixData = true
                tableData_mix_columns = mixColumnsFnc(data.columns || [])
                tableData_mix = data.list || []
                this.diffTableSum(
                  tableData,
                  tableData_fixed,
                  hasMixData,
                  tableData_mix,
                  tableData_mix_columns,
                )
              })

            this.typePlanData('',  this.cvpDetail, (data) => {
                tableData = data
                this.diffTableSum(
                  tableData,
                  tableData_fixed,
                  hasMixData,
                  tableData_mix,
                  tableData_mix_columns,
                )
              })


          })
          
          
        })
        .catch((err) => {
          console.error(err)
        })
    },
    
    typePlanData(type, detail = {}, callback) {
      const pageParams = detail.page_params || {}
      const params_tax_per = pageParams.params_tax_per || 0
      const params_sold_per = pageParams.params_sold_per || 0
      const params_cost_per = pageParams.params_cost_per || 0
      const params_pass_per = pageParams.params_pass_per || 0
      const solutionBaseTable = pageParams.solutionBaseTable || {}

      this.$network('/api/tools/planResultData', { id: detail.id, type: 'step-yc' })
        .then((res) => {
          const data = res.data || {}
          const list = data.list || []
          const excelDataChange = []
          list.map(function (item) {
            const no = item.no || ''
            const noPerInfo = solutionBaseTable[no] || {}
            item.params_line_tax_per = noPerInfo.params_line_tax_per || 0
            item.params_line_sold_per = noPerInfo.params_line_sold_per || 0
            item.params_line_cost_per = noPerInfo.params_line_cost_per || 0
            item.params_line_pass_per = noPerInfo.params_line_pass_per || 0
            const afterItem = { ...item }
            for (let key in item) {
              afterItem['__' + key] = item[key]
            }
            excelDataChange.push(afterItem)
          })

          const dataAfter = fnc_excelData_yc_line(list, { params_tax_per, params_sold_per, params_cost_per, params_pass_per })

          callback(dataAfter)
          return dataAfter
        })
        .catch((err) => {
          console.error(err)
        })
    },
    
    
    typePlanData_fixed(id, callback) {
      return this.$network('/api/tools/planResultData', {
        id: id,
        type: 'step-fixed'
      })
        .then((res) => {
          const data = res.data || {}
          callback(data)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    typePlanData_mix(id, callback) {
      return this.$network('/api/tools/planResultData', {
        id: id,
        type: 'step-mix'
      })
        .then((res) => {
          const data = res.data || {}
          callback(data)
        })
        .catch((err) => {
          console.error(err)
        })
    },

    diffTableSum(tableData,  tableData_fixed,  hasMixData, tableData_mix,  tableData_mix_columns ) {
      if (!tableData || !tableData_fixed  || !hasMixData) {
        return
      }
      
      this.svp_tableData = tableData;

      this.svp_tableData_fixed = tableData_fixed;

      this.svp_hasMixData = hasMixData;

      this.svp_tableData_mix = tableData_mix;

      this.svp_tableData_mix_columns = tableData_mix_columns;
      this.noticeTableComplete()
    },
    getData(){
      let type = 'step4-yc'

      let queryId = this.listQuery.id
      if(this.detail.step4_cvp2){
        type = 'step-yc-origin'

        //确定基表--只有历史方案vs历史方案 才会又这项过滤
        if(this.planBase!='step4_cvp2'){
          queryId = this.detail.step4_cvp2
        }else{
          queryId = this.detail.step4_cvp
        }
      }
      this.$network('/api/tools/planResultData', { id: queryId, type: type }).then((res) => {
        const data = res.data||{}
        const list = data.list || []
        // console.log(list)
        this.ycTableList = list;
        this.noticeTableComplete()
      })
      
    },
    noticeTableComplete(){
      this.$nextTick(()=>{
        if(this.svp_tableData.length && this.ycTableList.length){
          this.initDataState = 'complete'
        }
      })
    },
    onChangeParams(e) {
      if(this.detail.id){
        clearTimeout(this.onChangeParamsTimer)
        this.onChangeParamsTimer =  setTimeout(()=>{
          this.fncSettingOptions({
            params_tax_per: this.params_tax_per || 0,
            params_sold_per: this.params_sold_per || 0,
            params_cost_per: this.params_cost_per || 0,
            params_pass_per: this.params_pass_per || 0
          })
        },300)
      }
    },
    fncSettingOptions(params,returnData=false) {
      console.log(params)
    },
  }
}
</script>
