<script>
import { numberToFixed, debounce, fnc_excelData_yc_after, checkZero } from '@/utils/util'

export default {
  methods: {
    calYcTableData_fumField(field, values) {
      let money = 0

      if (values && values.length > 0) {
        values.map(function (item) {
          money += (item[field] ?? 0) * 1
        })
      }

      return numberToFixed(money)
    },
    //固定成本-函数
    tableData_step_fixed_field(field, values) {
      let money = 0
      if (values && values.length > 0) {
        ;(values || []).map(function (item) {
          if (item._type === field) {
            money = item.cost
          }
        })
      }
      return numberToFixed(money)
    },

    //混合成本-函数
    tableData_step_mix_field(field, sold_num, values, columns_step_mix) {
      // console.log(field, sold_num, values, columns_step_mix)
      let money = 0
      if (values && values.length > 0) {
        values.map((item) => {
          if (item._type === field) {
            let index = -1
            for (let i = 0; i < columns_step_mix.length; i++) {
              const info = columns_step_mix[i]
              if ('range_min' in info) {
                index++
                if (info['range_min'] <= sold_num && sold_num < info['range_max']) {
                  money = item['range_' + index]
                  break
                }
              }
            }
          }
        })
      }
      return money
    },
    //处理数据
    handleData(newData, fixData, mixData, columns_step_mix, handleParams) {
      //总成本
      const sum_material_cost_money = this.calYcTableData_fumField('material_cost_money', newData)
      //含税总价
      const sum_price_add_tax = this.calYcTableData_fumField('price_add_tax', newData)
      //总产量
      const sum_pass_sold_num = this.calYcTableData_fumField('pass_sold_num', newData)
      //变动成本
      const sum_material_cost = this.calYcTableData_fumField('material_cost', newData)
      //销量
      const sum_sold_num = this.calYcTableData_fumField('sold_num', newData)

      //含税总收入
      const sum_price_come_in = this.calYcTableData_fumField('price_come_in', newData)
      // console.log('-sum_price_come_in-')
      // console.log(newData)
      // console.log(sum_price_come_in)
      //原边际贡献
      const sum_side_money = this.calYcTableData_fumField('side_money', newData)

      //含税均单价
      // console.log(`sum_price_come_in=${sum_price_come_in};sum_sold_num=${sum_sold_num}`)
      const avg_price_no_tax_unit_money = sum_sold_num * 1 == 0 ? 0 : sum_price_come_in / sum_sold_num
      //制造环节固定成本
      let sum_fixed_zhizao_money = this.tableData_step_fixed_field('zhizao_sum', fixData)
      //制造环节固定成本
      let sum_fixed_yunying_money = this.tableData_step_fixed_field('yunying_sum', fixData)
      let sum_fixed_total_money = this.tableData_step_fixed_field('total', fixData)
      if (handleParams && 'params_fixed_per' in handleParams) {
        sum_fixed_zhizao_money = sum_fixed_zhizao_money * (1 + handleParams['params_fixed_per'] / 100)
        sum_fixed_yunying_money = sum_fixed_yunying_money * (1 + handleParams['params_fixed_per'] / 100)
      }
      //制造环节混合成本
      let sum_mix_zhizao_money = this.tableData_step_mix_field('zhizao_sum', sum_sold_num * 1, mixData, columns_step_mix)
      //运营环节混合成本
      let sum_mix_yunying_money = this.tableData_step_mix_field('yunying_sum', sum_sold_num * 1, mixData, columns_step_mix)

      let sum_mix_total_money = this.tableData_step_mix_field('total', sum_sold_num * 1, mixData, columns_step_mix)
      // console.log(sum_sold_num * 1, mixData, columns_step_mix, sum_mix_zhizao_money, sum_mix_yunying_money, sum_mix_total_money)
      if (handleParams && 'params_mix_per' in handleParams) {
        sum_mix_zhizao_money = sum_mix_zhizao_money * (1 + handleParams['params_mix_per'] / 100)
        sum_mix_yunying_money = sum_mix_yunying_money * (1 + handleParams['params_mix_per'] / 100)
      }
      //毛利（原导入）= 原边际贡献 -  制造环节固定成本 - 制造环节对应销量下的混合成本
      const maoli = numberToFixed(sum_side_money - sum_fixed_zhizao_money - sum_mix_zhizao_money)
      //管理利润
      const manager_rate = numberToFixed(maoli - sum_fixed_yunying_money - sum_mix_yunying_money)

      return {
        avg_price_no_tax_unit_money: numberToFixed(avg_price_no_tax_unit_money),
        sum_price_add_tax: numberToFixed(sum_price_add_tax),
        maoli: numberToFixed(maoli),
        manager_rate: numberToFixed(manager_rate),
        sum_sold_num: numberToFixed(sum_sold_num),
        sum_price_come_in: numberToFixed(sum_price_come_in),
        sum_side_money: numberToFixed(sum_side_money),
        sum_fixed_total_money: numberToFixed(sum_fixed_total_money),
        sum_mix_total_money: numberToFixed(sum_mix_total_money),
        sum_material_cost: numberToFixed(sum_material_cost),
        sum_material_cost_money: numberToFixed(sum_material_cost_money),
        sum_pass_per: checkZero(sum_pass_sold_num) ? 0 : numberToFixed((sum_sold_num/ sum_pass_sold_num )*100),
        sum_pass_sold_num: numberToFixed(sum_pass_sold_num),
        sum_pass_sold_num_price: checkZero(sum_pass_sold_num) ? 0 : numberToFixed(sum_material_cost_money/ sum_pass_sold_num)
      }
    }
  }
}
</script>
