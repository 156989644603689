<template>
  <div class="solution-children">
    <div class="head">存货期初结存</div>


    <div class="row">
      <div class="a-space-table">
        <a-space :size="16">
          <a-table :columns="columns" :data-source="tools2_excelData_serial_stand" :pagination="pagination" size="small" bordered>
            <template #bodyCell="{ column,record, text }">
              <template v-if="column.dataIndex === 'stand_cost'">
                <a href="javascript:;">{{ (colSpan[record.no]||{}).total_stand_cost||$numberToFixed(0) }}</a>
              </template>
            </template>
          </a-table>
        </a-space>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters  } from 'vuex'
import { numberToFixed } from "@/utils/util"

export default {
  name: 'SolutionSerialStand',
  components: {
  },
  props: {
    planId: {
      type: [Number, String],
      default: 0
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    }
  },
  data() {
    return {
      columns: [
        { title: '产品编码ID', dataIndex: 'no', align: 'center' },
        { title: '产品规格/描述', dataIndex: 'attr', align: 'center' },
        { title: '计量单位', dataIndex: 'unit', align: 'center' },
        { title: '工序（组）ID', dataIndex: 'serial_id', align: 'center' },
        { title: '工序（组）名称', dataIndex: 'serial_name', align: 'center' },
        { title: '产品标准用量', dataIndex: 'stand', align: 'center' },
        { title: '工序标准单位变动成本', dataIndex: 'cost', align: 'center' },
        { title: '产品标准变动成本', dataIndex: 'stand_cost', align: 'center',customCell:this.tableCustomCell },
        
      ],
      colSpan:[],
    }
  },
  computed: {
    ...mapGetters([
      
    ]),
		tools2_excelData_serial_stand(){
			return this.$store.state.tools2.tools2_excelData_serial_stand;
		},
    

    
  },
  created() {},
  mounted() {
    this.getDetail()
  },
  watch: {
    
   
   
  },
  methods: {
    getDetail() {
      return this.$network('/api/tools/planResultData', {
        id: this.planId,
        type: 'step2-serial-stand'
      })
        .then((res) => {
          const data = res.data || {}
          const list = data.list || []

          let serialGroupList = {}

          let colSpan = {}
          list.map((item,index)=>{
            const no = item.no
            const serial_id_no = item.serial_id_no
            if(!(no in colSpan)){
              colSpan[no] = {
                index:[],
                total_stand_cost:0,
              }
            }

            colSpan[no].index.push(index)
            colSpan[no].total_stand_cost = numberToFixed(colSpan[no].total_stand_cost*1 + item.stand_cost*1)

            if(!(serial_id_no in serialGroupList)){
              serialGroupList[serial_id_no] = item;
            }

          })
          

          //表格数据
          this.$store.commit('updateTools2State',{field:'tools2_excelData_serial_stand',value: list }) 
          //组合对象数据
          this.$store.commit('updateTools2State',{field:'tools2_excelData_serial_stand__no_and_serial_group',value: serialGroupList }) 
          //产品编码标准变动成本
          this.$store.commit('updateTools2State',{field:'tools2_excelData_serial_stand__no_cost',value: colSpan }) 

          this.colSpan = colSpan
        })
        .catch((err) => {
          console.error(err)
        })
    },
    tableCustomCell(item,index){
      const no = item.no||''
      const noObj = this.colSpan[no]||{}
      const arr = noObj.index||[]
      const len = arr.length||0
      const colSpanIndex = arr.indexOf(index)
      return {rowSpan:colSpanIndex===0?len:0}
    },
   
  }
}
</script>
