<template>
  <div class="solution-children">
    <div class="head">存货期初结存</div>


    <div class="row">
      <div class="a-space-table">
        <a-space :size="16">
          <a-table :columns="columns" :data-source="tools2_excelData_stock" :pagination="pagination" size="small" bordered>
           
          </a-table>
        </a-space>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters  } from 'vuex'

export default {
  name: 'SolutionStock',
  components: {
  },
  props: {
    planId: {
      type: [Number, String],
      default: 0
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    }
  },
  data() {
    return {
     
      //加成前
      columns: [
        { title: '产品编码ID', dataIndex: 'no', align: 'center' },
        { title: '产品规格/描述', dataIndex: 'attr', align: 'center' },
        { title: '计量单位', dataIndex: 'unit', align: 'center' },
        { title: '客户', dataIndex: 'customer', align: 'center' },
        { title: '结存数量', dataIndex: 'stock', align: 'center' },
        { title: '结存金额', dataIndex: 'money', align: 'center' },
        { title: '单位成本', dataIndex: 'price', align: 'center' },
      ],
    }
  },
  computed: {
    ...mapGetters([
      
    ]),
		tools2_excelData_stock(){
			return this.$store.state.tools2.tools2_excelData_stock;
		},
    

    
  },
  created() {},
  mounted() {
    this.getDetail()
  },
  watch: {
    
   
   
  },
  methods: {
    getDetail() {
      return this.$network('/api/tools/planResultData', {
        id: this.planId,
        type: 'step2-stock'
      })
        .then((res) => {
          const data = res.data || {}
          const list = data.list || []
          this.$store.commit('updateTools2State',{field:'tools2_excelData_stock',value: list }) 

        })
        .catch((err) => {
          console.error(err)
        })
    },
   
  }
}
</script>
