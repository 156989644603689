<template>
<a-modal v-model:open="open" :title="getTitle" @ok="handleOk" @cancel="handleCancel" width="90%" cancelText="打印">
    <div class="solution">
    <a-watermark :content="getWaterName">
        <div class="solution-box">
            <div class="solution-wrap">
                <a-table                         
                    class="cross-table"
                    :columns="columns"
                    :pagination="false"
                    :data-source="getList"
                    :scroll="{ x: 400 }"
                    centered 
                    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                    size="small" bordered  @resizeColumn="(w, col)=>{col.width = w}"
                    @change="(pagination, filters, sorter,e)=>onTableChange(item,pagination, filters, sorter,e)"                        
                >
                    <template #bodyCell="{ column, text, record, index }">
                        <!-- 边际收益 -->
                        <template v-if="['line','type','customer','area','group'].indexOf(column.dataIndex)>-1 ">
                            <text>{{text}}</text>
                        </template>
                        <template v-else-if="column.dataIndex === 'slid_money' ">
                            <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                                <text :class="{'text-red':text*1<0}">{{$dealThousands(text)}}</text>
                            </a-tooltip>
                        </template>
                        <template v-else>
                            <div>{{ $dealThousands(text) }}</div>
                        </template>
                    </template>
                    
                    <!-- 空数据问题处理 -->
                    <template #emptyText>
                    </template>
                </a-table>
            </div>
        </div>
        <div class="solutionTemplatePDF" v-show="isPrint" id="DialogToolsSvpTableCompare">
            <div class="solution-box">
                <div class="headBox">
                    <div class="headBoxBg">
                    <img src="@/assets/images/solution_img.png" />
                    <div class="headBoxLogo"><img :src="logo" class="logo" alt="logo" /></div>
                    </div>
                    <div class="headBoxContent">
                    <div class="headTitle">
                        <div class="title">{{ title }}</div>
                    </div>
                    <div class="author">
                        <div class="author_name">{{ subTitle }}</div>
                        <div class="author_info"></div>
                    </div>
                    </div>
                    <div class="headTypeText">
                        <span>{{ subName }}</span>
                    </div>
                </div>
                <div class="solution-wrap">
                    <div class="solution-children">
                        <div class="title"><span>{{getTitle}}</span></div>
                        <div class="row">
                            <a-table                         
                                class="cross-table"
                                :columns="columns"
                                :pagination="false"
                                :data-source="getList"
                                :scroll="{ x: 400 }"
                                centered 
                                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                                size="small" bordered  @resizeColumn="(w, col)=>{col.width = w}"
                                @change="(pagination, filters, sorter,e)=>onTableChange(item,pagination, filters, sorter,e)"                        
                            >
                            <template #bodyCell="{ column, text, record, index }">
                                <!-- 边际收益 -->
                                <template v-if="['line','type','customer','area','group'].indexOf(column.dataIndex)>-1 ">
                                    <text>{{text}}</text>
                                </template>
                                <template v-else-if="column.dataIndex === 'slid_money' ">
                                    <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                                        <text :class="{'text-red':text*1<0}">{{$dealThousands(text)}}</text>
                                    </a-tooltip>
                                </template>
                                <template v-else>
                                    <div>{{ $dealThousands(text) }}</div>
                                </template>
                            </template>
                            
                            <!-- 空数据问题处理 -->
                            <template #emptyText>
                            </template>
                        </a-table>
                    </div>
                </div>
            </div>
        </div>
           
        </div>
    </a-watermark>
    </div>
    <template #footer>
        <a-button  @click="handlePrint">打印</a-button>
    </template>
</a-modal>
<page-loading ref="pageLoading" />
</template>

<script>
import PageLoading from '@/components/PageLoading.vue'
import { tableSortFnc, tableDefaultPage } from '@/utils/util'
import { htmlToPDF } from '@/utils/html2pdf'

import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'

export default {
    name:'DialogToolsSvpTableCompare',
    components:{
        PageLoading,
    },
    props: {
        getWaterName: {
            type: String,
            default:'',
        },
        title: {
            type: String,
            default:'',
        },
        subTitle: {
            type: String,
            default:'',
        },
        planName: {
            type: String,
            default:'',
        },
        logo: {
            type: String,
            default:'',
        },
        subName: {
            type: String,
            default:'',
        }
    },
    data(){
        return{
            open:false,
            isPrint: false,
            payload:{},
            columns:[
                {width:90,   title: '产品线', dataIndex: 'line', align: 'center',resizable: true ,ellipsis: true,fixed: 'left'},
                {width:90,   title: '产品类别', dataIndex: 'type', align: 'center',resizable: true ,ellipsis: true,fixed: 'left'},
                {width:90,   title: '客户', dataIndex: 'customer', align: 'center',resizable: true ,ellipsis: true,fixed: 'left'},
                {width:90,   title: '区域', dataIndex: 'area', align: 'center',resizable: true ,ellipsis: true,fixed: 'left'},
                {width:90,   title: '业务组', dataIndex: 'group', align: 'center',resizable: true ,ellipsis: true,fixed: 'left'},

                {width:100,   title: '销量基础', dataIndex: 'sold_num', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('sold_num')},
                {width:100,   title: '收入基础', dataIndex: 'price_come_in', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('price_come_in')},
                {width:100,   title: '变动成本基础', dataIndex: 'material_cost_money', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('material_cost_money')},
                {width:100,   title: '边际收益基础', dataIndex: 'slid_money', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('slid_money')},

                {width:100,   title: '销量', dataIndex: 'sold_num_compare', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('sold_num_compare')},
                {width:100,   title: '收入', dataIndex: 'price_come_in_compare', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('price_come_in_compare')},
                {width:100,   title: '变动成本', dataIndex: 'material_cost_money_compare', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('material_cost_money_compare')},
                {width:100,   title: '边际收益', dataIndex: 'slid_money_compare', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('slid_money_compare')},

                {width:100,   title: '销量差异', dataIndex: 'sold_num_result', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('sold_num_result')},
                {width:100,   title: '收入差异', dataIndex: 'price_come_in_result', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('price_come_in_result')},
                {width:100,   title: '变动成本差异', dataIndex: 'material_cost_money_result', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('material_cost_money_result')},
                {width:100,   title: '边际收益差异', dataIndex: 'slid_money_result', align: 'right',resizable: true ,ellipsis: true, ...tableSortFnc('slid_money_result')},
            ],
        }
    },
    computed:{
        getTitle(){
            return `${this.payload.no}-${this.payload.attr}`
        },
        getList(){
            return [this.payload]
        }
    },
    mounted() {
       
    },
    methods:{
        handlePrint() {
            this.$refs.pageLoading.open('正在进行方案下载')
            this.isPrint = true

            setTimeout(() => {
                this.$nextTick(() => {
                this.downLoading = true
                this.generatePrintHtmlBlock()
                })
            },200)
        },
        generatePrintHtmlBlock() {
            this.$refs.pageLoading.text('方案构件中...')
            this.$nextTick(() => {
            
                this.$refs.pageLoading.text('正在生成PDF文件')
                this.generatePdfFile()
            })
        },
      
        async generatePdfFile() {
            await htmlToPDF('DialogToolsSvpTableCompare', this.getTitle,'PDF-foot')
            this.downLoading = false
            this.isPrint = false
            this.$refs.pageLoading.text('下载成功')
            this.$refs.pageLoading.close()
            // setTimeout(() => {
            //     location.reload()
            // }, 800)
        },
        onTableChange(item,pagination, filters, sorter, {currentDataSource,action}){
            console.log('>>>>>>>>>>>>>>>>>> onTableChange <<<<<<<<<<<<<<<<<<<')
        },
        showDialog(payload){
            console.log(payload)
            this.payload = payload
            this.open = true;
            console.log(payload)

        },
        handleOk(){
            this.open = false;
        },
        handleCancel(e){
            console.log(e)
        }
    }
}
</script>

<style>
#DialogToolsSvpTableCompare {
    position: absolute;
    top:-100vh;
}
</style>
