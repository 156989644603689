<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Login',
})
</script>
<template>
  <div class="login">
    <div class="login-intro">
      <div class="login-intro-box">
        <!-- <a-carousel>
          <div class="slide" v-for="(item, index) in adImages" :key="index">
            <img :src="item.img" />
          </div>
        </a-carousel>
        <div class="login-intro-text">
          {{ adImages.length }}
          {{ contentIntro }}
        </div> -->
      </div>
      <div style="color: #666" class="login-copyright">
        <div v-html="copyright"></div>
        <!-- Copyright © 2023 翎谋  &nbsp;&nbsp;<a class="text-white" href="http://beian.miit.gov.cn" target="_blank" rel="nofollow">粤ICP备2023099763号-1</a> -->
      </div>
    </div>
    <div class="login-form">
      <div class="login-box">
        <!-- <div class="header">
          <img src="/logo.png" class="logo" alt="logo" />
        </div> -->

        <a-form id="formLogin" class="user-layout-login" @submit="handleSubmit" :model="formRef">
          <div class="header">
            <img src="/logo.png" class="logo" alt="logo" />
          </div>
          <div class="login-title">
            <div class="h4">欢迎来到翎谋管理系统</div>
            <!-- <div class="h6">请使用您的常用手机号作为注册账号</div> -->
            <div>
              没有账号？

              <span style="color: #1e6dff; cursor: pointer">免费注册</span>
            </div>
          </div>
          <div v-if="false">
            <a-tabs :activeKey="customActiveKey" :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }" @change="handleTabClick">
              <!-- 企业用户登录 -->
              <a-tab-pane key="company" tab="企业用户">
                <a-form-item v-bind="validateInfos.username">
                  <a-input type="text" placeholder="请输入手机号" v-model:value="formRef.username">
                    <template #prefix>
                      <UserOutlined :style="{ color: 'rgba(0,0,0,.25)', fontSize: '24px' }" />
                    </template>
                  </a-input>
                </a-form-item>

                <a-form-item v-bind="validateInfos.password">
                  <a-input-password placeholder="请输入密码" v-model:value="formRef.password">
                    <template #prefix>
                      <LockOutlined :style="{ color: 'rgba(0,0,0,.25)', fontSize: '24px' }" />
                    </template>
                  </a-input-password>
                </a-form-item>
              </a-tab-pane>

              <!-- 个人用户登录 -->
              <a-tab-pane key="person" tab="个人用户">
                <a-form-item v-bind="validateInfos.mobile">
                  <a-input type="text" placeholder="请输入手机号" v-model:value="formRef.mobile">
                    <template #prefix>
                      <UserOutlined :style="{ color: 'rgba(0,0,0,.25)', fontSize: '24px' }" />
                    </template>
                  </a-input>
                </a-form-item>

                <a-form-item v-bind="validateInfos.password">
                  <a-input-password placeholder="请输入密码" v-model:value="formRef.password">
                    <template #prefix>
                      <LockOutlined :style="{ color: 'rgba(0,0,0,.25)', fontSize: '24px' }" />
                    </template>
                  </a-input-password>
                </a-form-item>
              </a-tab-pane>
            </a-tabs>

            <a-form-item>
              <a-checkbox v-model:checked="formRef.agree">
                我已阅读并同意
                <a @click.prevent="$refs.popupContent.open('serverProtocol')">《服务协议》</a>和
                <a @click.prevent="$refs.popupContent.open('protectProtocol_company')" v-if="customActiveKey === 'company'">《企业信息保护政策》</a>
                <a @click.prevent="$refs.popupContent.open('protectProtocol')" v-else>《个人信息保护政策》</a>
              </a-checkbox>
            </a-form-item>

            <a-form-item style="margin-top: 24px">
              <a-button size="large" type="primary" htmlType="submit" class="login-button" :loading="state.loginBtn" :disabled="state.loginBtn">登录</a-button>
            </a-form-item>
            <!-- <div class="user-login-other">
                    <a href="javascript:;" @click="handleShowVerify">
                        会员差异
                    </a>
                    
                </div> -->
            <a-form-item v-bind="validateInfos.rememberMe">
              <!-- <a-checkbox v-model:checked="formRef.rememberMe" style="float:left">
                        下次自动登录
                    </a-checkbox> -->

              <router-link class="forge-password" style="float: right" :to="{ path: '/user/forgotPassword' }">忘记密码？</router-link>
            </a-form-item>
          </div>
          <div v-else>
            <Reg />
          </div>
          <div class="user-login-other">
            没有账号？

            <router-link :to="{ path: '/user/reg', query: { type: customActiveKey } }"> 免费注册 </router-link>
            <!-- <router-link v-else :to="{ path: '/user/registerPerson' }"> 免费注册 </router-link> -->
          </div>
        </a-form>
      </div>
    </div>
  </div>
  <PopupContent ref="popupContent" />
  <PopupVipBox ref="popupVipBox" moduleName="login" />
  <ProjectOpening />
</template>

<script lang="ts" setup name="Login">
import PopupVipBox from '@/components/PopupVipBox.vue'
// import { encryptByMd5 } from '@/utils/encrypt'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { ref, reactive, UnwrapRef, onMounted } from 'vue'
import { Form, message, notification } from 'ant-design-vue'
import { loginSuccess, requestFailed } from '@/store/modules/helper'
import { useRouter } from 'vue-router'

import ProjectOpening from '@/components/ProjectOpening.vue'
import Reg from './Reg.vue'

import baseService from '@/utils/http/axios'

import ls from '@/utils/Storage'
import dayjs from 'dayjs'

onMounted(() => {
  getAdImage()
})

//广告图事件
const adImages = ref([])
const contentIntro = ref('')
const copyright = ref('')
const getAdImage = () => {
  baseService
    .post('/api/index/defInfo', { type: 'imageLogin' })
    .then((res) => {
      const data = res.data || {}
      adImages.value = data.list || []
      contentIntro.value = data.content_intro || ''
      copyright.value = data.copyright || ''
    })
    .catch((err) => {
      console.error(err)
    })
}

//版本差异
const popupVipBox = ref()
const handleShowVerify = () => {
  popupVipBox.value.open()
}

const useForm = Form.useForm
const router = useRouter()

//#region 切换tab
const customActiveKey = ref<string>('company')
const handleTabClick = (key: string) => {
  customActiveKey.value = key
}
//#endregion

const state = reactive({
  time: 60,
  loginBtn: false,
  // login type: 0 email, 1 username, 2 telephone
  loginType: 0,
  smsSendBtn: false
})

// #region 表单相关
const formRef = reactive({
  rememberMe: false,
  username: '',
  password: '',
  mobile: '',
  captcha: '',
  agree: false
})

const handleUsernameOrEmail = (rule, value: string) => {
  const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
  if (regex.test(value)) {
    state.loginType = 0
  } else {
    state.loginType = 1
  }
  return Promise.resolve()
}
const rulesRef = reactive({
  rememberMe: [{ trigger: 'checked' }],
  username: [
    { required: true, message: '' },
    { validator: handleUsernameOrEmail, trigger: 'change' }
  ],
  password: [{ required: true, message: '' }, {}],
  mobile: [{ required: true, pattern: /^1[34578]\d{9}$/, message: '', validateTrigger: 'change' }],
  captcha: [{ required: true, message: '', validateTrigger: 'blur' }]
})
const { validate, validateInfos } = useForm(formRef, rulesRef)

const handleSubmit = (e: Event) => {
  if (!formRef.agree) {
    message.error('请先同意协议')
    return
  }

  let url = '/api/account/userLogin'
  let requestBody = {
    account: formRef.mobile,
    password: formRef.password
  }
  if (customActiveKey.value == 'company') {
    url = '/api/account/companyLogin'
    requestBody = {
      account: formRef.username,
      password: formRef.password
    }
  }
  baseService
    .post(url, requestBody)
    .then((res) => {
      const data = res.data || {}

      //清空广告弹窗效果
      const cacheName = ('popVipTypeShow' + dayjs().format('YYYY-MM-DD')).toLocaleUpperCase()
      ls.remove(cacheName)

      loginSuccess(data.access_token || '', data.userInfo || {}, router)
      console.log(data)
    })
    .catch((err) => {
      console.error(err)
    })
  return
}
// #endregion
</script>

<style lang="less" >
@import '../../style/index.less';

.login {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;

  &-intro {
    flex: 1;
    overflow: hidden;
    height: 100%;
    padding: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-carousel {
      width: 100%;

      .slick-slide img {
        max-width: 100%;
        margin: auto;
      }

      .slick-dots {
        position: static;
        overflow: hidden;

        li {
          width: 8px !important;
          height: 8px;

          button {
            height: 100%;
            border-radius: 100px;
          }
        }
      }
    }

    .login-intro-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .login-intro-text {
        width: 70%;
        margin: 40px auto;
        color: #ffffff;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
      }
    }

    .login-copyright {
      position: absolute;
      left: 0;
      bottom: 20px;
      z-index: 9;
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 14px;
    }
  }

  &-form {
    width: 700px;
    // padding: 40px;
    // background: #ffffff;
    // box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-box {
      width: 450px;
      height: 600px;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
      position: relative;
    }

    .header {
      // position: absolute;
      // top: 40px;
      // left: 40px;
      margin-top: 40px;
      img.logo {
        // width: 126px;
        width: 86px;
      }
    }
  }
}

.user-layout-login {
  width: 400px;
  margin: 0 auto;

  .login-title {
    // text-align: center;

    .h4 {
      color: #1e6dff;
      font-size: 24px;
      font-weight: bold;
    }

    .h6 {
      color: #666666;
      font-size: 12px;
    }
  }

  .ant-tabs {
    // margin-top: 50px;

    .ant-tabs-nav {
      margin: 0px 0px 32px 0px;
      // margin: 0px 0px 22px 0px;

      &::before {
        display: none;
      }

      .ant-tabs-nav-wrap {
        justify-content: center;

        .ant-tabs-tab {
          font-size: 16px;

          & + .ant-tabs-tab {
            margin: 0px 0px 0px 64px;
          }
        }
      }
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 5px;
  }

  .ant-input {
    // height: 44px !important;
    height: 34px !important;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 54px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    // height: 54px;
    height: 44px;
    width: 100%;
  }

  .user-login-other {
    text-align: center;
    margin-top: 24px;
    line-height: 22px;

    .anticon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
