import { BasicLayout, RouteView } from '@/layouts'
import { Router } from './types'

const filterMenus = []

const menus = [
    // 工作台
    {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/dashboard',
        component: RouteView,
        meta: { title: '工作台', icon: 'icon-desk', keepAlive: true, permission: ['admin'] },
        hideChildrenInMenu: true,
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/dashboard/dashboard.vue'),
                meta: { title: '工作台', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'tools',
                name: 'tools',
                component: () => import('@/views/dashboard/tools.vue'),
                meta: { title: '工具', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'tools-detail',
                name: 'toolsDetail',
                component: () => import('@/views/dashboard/tools-detail.vue'),
                meta: { title: '工具介绍', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud/import',
                name: 'Import',
                component: () => import('@/views/dashboard/cloud/import.vue'),
                meta: { title: '导入文件', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud/solution',
                name: 'Solution',
                component: () => import('@/views/dashboard/cloud/solution.vue'),
                meta: { title: '方案', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud/compare',
                name: 'compare',
                component: () => import('@/views/dashboard/cloud/compare.vue'),
                meta: { title: '方案对比', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud2/import',
                name: 'Import2',
                component: () => import('@/views/dashboard/cloud2/import.vue'),
                meta: { title: '导入文件', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud2/solution',
                name: 'Solution2',
                component: () => import('@/views/dashboard/cloud2/solution.vue'),
                meta: { title: '方案', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            // // 外部链接
            // {
            //     path: 'monitor',
            //     name: 'Monitor',
            //     meta: { title: '外部链接', target: 'http://www.baidu.com', permission: ['admin'], hidden: true, blank: true }
            // },

            {
                path: 'cloud3/import',
                name: 'Import3',
                component: () => import('@/views/dashboard/cloud3/import.vue'),
                meta: { title: '导入文件', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud3/solution',
                name: 'Solution3',
                component: () => import('@/views/dashboard/cloud3/solution.vue'),
                meta: { title: '方案', keepAlive: false, permission: ['admin'], hidden: true, }
            },

            {
                path: 'cloud4/import',
                name: 'Import4',
                component: () => import('@/views/dashboard/cloud4/import.vue'),
                meta: { title: '导入文件', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud4/solution',
                name: 'Solution4',
                component: () => import('@/views/dashboard/cloud4/solution.vue'),
                meta: { title: '方案', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud5/import',
                name: 'Import5',
                component: () => import('@/views/dashboard/cloud5/import.vue'),
                meta: { title: '导入文件', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud5/solution',
                name: 'Solution5',
                component: () => import('@/views/dashboard/cloud5/solution.vue'),
                meta: { title: '方案', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud6/import',
                name: 'Import6',
                component: () => import('@/views/dashboard/cloud6/import.vue'),
                meta: { title: '导入文件', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud6/solution',
                name: 'Solution6',
                component: () => import('@/views/dashboard/cloud6/solution.vue'),
                meta: { title: '方案', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud7/solution',
                name: 'Solution7',
                component: () => import('@/views/dashboard/cloud7/solution.vue'),
                meta: { title: '项目投资（产品型）', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud7/compare',
                name: 'Solution7Compare',
                component: () => import('@/views/dashboard/cloud7/compare.vue'),
                meta: { title: '项目投资对比', keepAlive: false, permission: ['admin'], hidden: true, }
            },
            {
                path: 'cloud8/solution',
                name: 'Solution8',
                component: () => import('@/views/dashboard/cloud8/solution.vue'),
                meta: { title: '产品路径图', keepAlive: false, permission: ['admin'], hidden: true, }
            },

        ]
    },
    // 常用工具
    {
        toolAlias: 'tools',
        path: '/tool',
        name: 'tool',
        component: RouteView,
        redirect: '/tool/tool',
        meta: { title: '常用工具', icon: 'icon-editor' },
        children: [
          
        ]
    },
    // 历史方案
    {
        toolAlias: 'tools',
        path: '/history',
        name: 'history',
        component: RouteView,
        meta: { title: '历史方案', icon: 'icon-zhishi' },
        hideChildrenInMenu: true,
        children: [
            {
                path: '/history',
                name: 'history',
                component: () => import('@/views/history/index.vue'),

                meta: { title: '历史方案', keepAlive: false, hidden: true, }
            },
            {
                path: '/history/compare',
                name: 'history-compare',
                component: () => import('@/views/history/compare.vue'),
                meta: { title: '历史对比方案', keepAlive: false, hidden: true, }
            },
            {
                path: '/history/recovery',
                name: 'history-recovery',
                component: () => import('@/views/history/recovery.vue'),
                meta: { title: '回收站', keepAlive: false, hidden: true, }
            },
          
        ]
    },
    // 知识库
    {
        toolAlias: 'community',
        path: '/knowledge',
        name: 'knowledge',
        redirect: '/knowledge/knowledge',
        component: RouteView,
        meta: { title: '知识库', icon: 'icon-study',hidden:true },
        hideChildrenInMenu: true,
        children: [
            {
                path: '/knowledge/knowledge',
                name: 'knowledge',
                component: () => import('@/views/knowledge/index.vue'),
                meta: { title: '知识库', keepAlive: false, hidden: true, }
            },
            {
                path: '/knowledge/detail',
                name: 'knowledgeDetail',
                component: () => import('@/views/knowledge/detail.vue'),
                meta: { title: '知识库详情', keepAlive: false, hidden: true, }
            },
        ]
    },
    // 创作中心
    {
        toolAlias: 'community',
        path: '/works',
        name: 'works',
        component: RouteView,
        redirect: '/works/level',
        meta: { title: '创作中心', icon: 'icon-works' },
        children: [
            {
                path: '/works/level',
                name: 'works-level',
                component: () => import('@/views/works/level.vue'),
                meta: { title: '我的等级' }
            },
            {
                path: '/works/list',
                name: 'works-list',
                component: () => import('@/views/works/list.vue'),
                redirect: '/works/list/asks',
                meta: { title: '内容创作', keepAlive: false },
                hideChildrenInMenu: true,
                children: [
                    {
                        path: '/works/list/asks',
                        name: 'asks-list',
                        component: () => import('@/views/works/asks-list.vue'),
                        meta: { title: '我的提问', keepAlive: false, hidden: true, }
                    },
                    {
                        path: '/works/list/article',
                        name: 'article-list',
                        component: () => import('@/views/works/article-list.vue'),
                        meta: { title: '发布文章', keepAlive: false, hidden: true, }
                    },
                    {
                        path: '/works/list/drafts',
                        name: 'drafts-list',
                        component: () => import('@/views/works/drafts-list.vue'),
                        meta: { title: '草稿箱', keepAlive: false, hidden: true, }
                    },
                ]
            },

            // {
            //     path: '/works/income',
            //     name: 'works-income',
            //     component: () => import('@/views/works/income.vue'),
            //     meta: { title: '收益变现' }
            // },
            {
                path: '/works/withdrawal',
                name: 'works-withdrawal',
                component: () => import('@/views/works/withdrawal.vue'),
                meta: { title: '提现中心', keepAlive: false, hidden: true, }

            }

        ]
    },
    // 学习平台
    {
        toolAlias: 'article',
        path: '/study/study',
        name: 'study',
        redirect: '/study/study',
        component: RouteView,
        meta: { title: '学习平台', icon: 'icon-book' },
        hideChildrenInMenu: true,
        children: [
            {
                path: '/study/study',
                name: 'study',
                component: () => import('@/views/study/index.vue'),

                meta: { title: '学习平台', keepAlive: false, hidden: true, }
            },
            {
                path: '/study/detail',
                name: 'studyDetail',
                component: () => import('@/views/study/study-detail.vue'),
                meta: { title: '学习详情', keepAlive: false, hidden: true, }
            },
            {
                path: '/study/center',
                name: 'studyCenter',
                component: () => import('@/views/study/study-center.vue'),
                meta: { title: '我的教程', keepAlive: false, hidden: true, }
            },
            {
                path: '/study/upload',
                name: 'studyUpload',
                component: () => import('@/views/study/study-upload.vue'),
                meta: { title: '教程上传', keepAlive: false, hidden: true, }
            },
        ]
    },
    // 模版下载
    {
        toolAlias: 'template',
        path: '/template',
        name: 'template',
        meta: { title: '模版下载', icon: 'icon-down' },
        hideChildrenInMenu: true,
        children: [
            {
                path: '/template',
                name: 'template',
                component: () => import('@/views/template/index.vue'),
                meta: { title: '模版下载', keepAlive: false, hidden: true, }
            },
            {
                path: '/template/detail',
                name: 'templateDetail',
                component: () => import('@/views/template/template-detail.vue'),
                meta: { title: '模版详情', keepAlive: false, hidden: true, }
            },
            {
                path: '/template/center',
                name: 'templateCenter',
                component: () => import('@/views/template/template-center.vue'),
                meta: { title: '我的模版', keepAlive: false, hidden: true, }
            },
            {
                path: '/template/upload',
                name: 'templateUpload',
                component: () => import('@/views/template/template-upload.vue'),
                meta: { title: '上传模版', keepAlive: false, hidden: true, }
            },

        ]
    },
    // 账户设置
    {
        path: '/account/person',
        component: () => import('@/views/account/person.vue'),
        name: 'account-user',
        meta: { title: '个人设置', icon: 'icon-user', keepAlive: true, hidden: true, },
        hideChildrenInMenu: true,
    },
    {
        path: '/account',
        component: RouteView,
        redirect: '/account/information',
        name: 'account-company',
        meta: { title: '企业设置', icon: 'icon-user', keepAlive: true, hidden: true },
        hideChildrenInMenu: true,
        children: [
            {
                path: '/account/member',
                name: 'member',
                component: () => import('@/views/account/member.vue'),
                meta: { title: '成员管理', keepAlive: true,  }
            },
            {
                path: '/account/department',
                name: 'department',
                component: () => import('@/views/account/department.vue'),
                meta: { title: '部门管理', keepAlive: true,   }
            },
            {
                path: '/account/information',
                name: 'information',
                component: () => import('@/views/account/information.vue'),
                meta: { title: '企业信息', keepAlive: true, }
            },
            // {
            //     path: '/account/information',
            //     name: 'information',
            //     component: () => import('@/views/account/information.vue'),
            //     meta: { title: '个人信息', keepAlive: true }
            // },

        ]
    },
    // 学习平台
    {
        toolAlias: 'cost',
        path: '/cost',
        name: 'cost',
        component: () => import('@/views/cost/index.vue'),
        meta: { title: 'VIP服务', icon: 'icon-cost' },
    },
    // 模版下载
    {
        toolAlias: '_invoice_index',
        path: '/invoice',
        name: 'invoice',
        component: () => import('@/views/invoice/index.vue'),
        meta: { title: '发票管理', icon: 'icon-invoice' },
    },
    // 设置
    {
        path: '/account/setting',
        name: 'settings',
        meta: { title: '会员中心', hidden: true, },
        redirect: '/account/setting',
        hideChildrenInMenu: true,
        children: [
            {
                path: '/account/setting',
                name: 'Settings',
                component: () => import('@/views/account/setting.vue'),
                meta: {
                    title: '账户设置',
                    hidden: true,
                }
            },
            {
                path: '/account/notice',
                name: 'AccountNotices',
                component: () => import('@/views/account/notice.vue'),
                meta: {
                    title: '消息通知',
                    hidden: true,
                    keepAlive: true,
                }
            },
            {
                path: '/notice/detail',
                name: 'NoticeDetail',
                component: () => import('@/views/account/notice-detail.vue'),
                meta: {
                    title: '消息通知详情',
                    hidden: true,
                    keepAlive: true,
                }
            },
            {
                path: '/account/question',
                name: 'AccountQuestion',
                component: () => import('@/views/account/question.vue'),
                meta: {
                    title: '常见问题',
                    hidden: true,
                    keepAlive: true
                }
            },
            {
                path: '/account/people',
                name: 'Homepage',
                component: () => import('@/views/account/homepage/homepage.vue'),
                redirect: '/account/people/answers',
                meta: { title: 'Ta的主页', keepAlive: false },
                hideChildrenInMenu: true,
                children: [
                    {
                        path: '/account/people/answers',
                        name: 'peopleAnswers',
                        component: () => import('@/views/account/homepage/answers.vue'),
                        meta: { title: 'Ta的主页', keepAlive: false, hidden: true, }
                    },
                    {
                        path: '/account/people/asks',
                        name: 'peopleAsks',
                        component: () => import('@/views/account/homepage/asks.vue'),
                        meta: { title: 'Ta的主页', keepAlive: false, hidden: true, }
                    },
                    {
                        path: '/account/people/article',
                        name: 'peopleArticle',
                        component: () => import('@/views/account/homepage/article.vue'),
                        meta: { title: 'Ta的主页', keepAlive: false, hidden: true, }
                    },
                ]
            },
        ]
    },
    // 订单中心
    {
        path: '/order',
        name: 'order-page',
        component: RouteView,
        toolAlias: 'order',
        meta: { title: '订单管理', icon: 'icon-invoice',hidden:true },
        hideChildrenInMenu: true,
        redirect: '/order/index',
        children: [
            {
                path: '/order/index',
                name: 'order-index',
                component: () => import('@/views/order/index.vue'),
                meta: { title: '订单列表', keepAlive: false, hidden: true, }
            },
            {
                path: '/order/req',
                name: 'order-req',
                component: () => import('@/views/order/req.vue'),
                meta: { title: '订单申请', keepAlive: false, hidden: true, }
            },
            {
                path: '/order/progress',
                name: 'order-progress',
                component: () => import('@/views/order/progress.vue'),
                meta: { title: '申请进度', keepAlive: false, hidden: true, }
            },
            
        ]
    },
    // list
    // {
    //     path: '/list',
    //     name: 'list',
    //     component: RouteView,
    //     redirect: '/list/table-list',
    //     meta: { title: 'menu.list.title', icon: 'bx-analyse', permission: ['table'] },
    //     children: [
    //         {
    //             path: '/list/table-list/:pageNo([1-9]\\d*)?',
    //             name: 'TableListWrapper',
    //             hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
    //             component: () => import('@/views/Home.vue'),
    //             meta: { title: 'menu.list.table-list', keepAlive: true }
    //         },
    //         {
    //             path: '/list/basic-list',
    //             name: 'BasicList',
    //             component: () => import('@/views/Home.vue'),
    //             meta: { title: 'menu.list.basic-list', keepAlive: true }
    //         },
    //         {
    //             path: '/list/card',
    //             name: 'CardList',
    //             component: () => import('@/views/Home.vue'),
    //             meta: { title: 'menu.list.card-list', keepAlive: true }
    //         },
    //         {
    //             path: '/list/search',
    //             name: 'SearchList',
    //             component: () => import('@/views/Home.vue'),
    //             redirect: '/list/search/article',
    //             meta: { title: 'menu.list.search-list.title', keepAlive: true },
    //             children: [
    //                 {
    //                     path: '/list/search/article',
    //                     name: 'SearchArticles',
    //                     component: () => import('@/views/Home.vue'),
    //                     meta: { title: 'menu.list.search-list.articles' }
    //                 },
    //                 {
    //                     path: '/list/search/project',
    //                     name: 'SearchProjects',
    //                     component: () => import('@/views/Home.vue'),
    //                     meta: { title: 'menu.list.search-list.projects' }
    //                 },
    //                 {
    //                     path: '/list/search/application',
    //                     name: 'SearchApplications',
    //                     component: () => import('@/views/Home.vue'),
    //                     meta: { title: 'menu.list.search-list.applications' }
    //                 }
    //             ]
    //         }
    //     ]
    // },



    // {
    //     path: '/dynamicForm',
    //     name: 'dynamicForm',
    //     component: () => import('@/views/dynamicForm/index.vue'),
    //     meta: { title: 'menu.dynamicForm', icon: 'bx-analyse' }
    // },
    // {
    //     path: '/logicflow',
    //     name: 'logicflow',
    //     component: () => import('@/views/logicflow/index.vue'),
    //     meta: { title: 'menu.logicflow', icon: 'bx-analyse' }
    // },
    // {
    //     path: '/makeChart',
    //     name: 'makeChart',
    //     component: () => import('@/views/makeChart/index.vue'),
    //     meta: { title: 'menu.makeChart', icon: 'bx-analyse' }
    // },

];

menus.map((item)=>{
    if(PLATFORM_MENU_CONTENT!==false && 'toolAlias' in item){
        const key = ','+item.toolAlias+','
        if(PLATFORM_MENU_CONTENT.indexOf(key)>-1){
            filterMenus.push(item)   
        }
    }else{
        filterMenus.push(item)   
    }
})

// console.log(filterMenus)


export const example: Router = {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/dashboard',
    children: filterMenus
}
