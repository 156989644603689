<template>
    <a-modal title="历史CVP方案选择" :width="1024" :open="visible" :confirmLoading="loading" :footer="null" @ok="handleSubmit"
        @cancel="close">
        <a-spin :spinning="loading">
            <div class="tableWrapper">

                <a-table :scroll="{ y: '100%' }" :data-source="tableData" :columns="columns" :pagination="false" :loading="tableLoading">
                    <template #bodyCell="{ column, text, record }">

                        <template v-if="column.dataIndex === 'operation'">

                            <a-button type="primary" @click="handleSelect(record)">选择上传</a-button>
                        </template>
                    </template>
                </a-table>

                <a-pagination v-model:current="listQuery.page" :total="tableTotal" show-less-items @change="onPageChange"/>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import baseService from '@/utils/http/axios';
import list from 'ant-design-vue/es/list';
import { ref, reactive, onMounted, computed, nextTick } from 'vue';


export default {
    props:{

    },
    data(){
        return {
            listQuery:{
                page:1,
                limit:null,
            },
            loading:false,
            visible:false,

            tableTotal:0,
            tableLoading:false,
            tableData:[],
            columns : [
                { title: '方案名称', dataIndex: 'name',  },
                // { title: '文件格式', dataIndex: 'file_format', width: 200, align: 'center' },
                // { title: '文件大小', dataIndex: 'file_size', width: 200, align: 'center' },
                { title: '方案保存时间', dataIndex: 'save_time', align: 'center', width: 200 },
                { title: '操作', dataIndex: 'operation', align: 'center', width: 200 },

            ],
            
        }
    },

    methods:{
        open(params){
            this.tableData =[]
            this.listQuery.page = 1
            this.params = params||{}
            
            this.visible = true
            this.getData()
        },
        close(){  
            this.visible = false

        },
        getData(){
            this.tableLoading = true
            this.$network('/api/tools/planHistory',{...this.listQuery,...this.params}).then(res => {
                const data = res.data || {}
                this.tableData = data.list || []
                this.tableTotal = data.total || []
            }).catch(err => {
                console.error(err)
            }).finally(()=>{
                this.tableLoading = false
            })
        },
        onPageChange (page,pageSize){
            this.listQuery.page = page
            this.listQuery.limit = pageSize
            this.getData()
        },
        handleSelect(item){
            this.close()
            this.$emit('selectItem',item)
        },
        handleSubmit(){
            this.close()
        }
    }
}


const tableTotal = ref(0)

const getData = () => {
    tableLoading.value = true
    baseService.post('/api/tools/uploadHistory',listQuery).then(res => {
        const data = res.data || {}
        tableData.value = data.list || []
        tableTotal.value = data.total || []
    }).catch(err => {
        console.error(err)
    }).finally(()=>{
        tableLoading.value = false
    })
}
 

</script>
<style lang="less">
</style>
