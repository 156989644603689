<template>    
    <div class="row">
        <a-row :gutter="48">
            <a-col  :sm="10" :lg="6" v-if="getHasToolAuth('tools1_fixed')">
                <div class="table-children">
                    <div class="subtitle">固定成本：</div>
                    <a-table  :columns="columns_step_fixed"
                        :data-source="tableData_step_fixed"
                        :rowClassName="(_record, index) => (['zhizao_sum','yunying_sum'].indexOf(_record._type)>-1 ? 'gray-bg-def' :  (_record._type=='total'?'gray-bg-def-deep':null))"
                        :pagination="pagination" :scroll="isPrint ? {x:240 } : {x:240, y: 222 }" size="small" bordered  @resizeColumn="(w, col)=>{col.width = w}">
                        <template #bodyCell="{ column, text, record,index }">

                            <template v-if="column.dataIndex=='cost'">
                                <div> {{ $dealThousands(text) }}</div>
                            </template>
                            <template v-if="record._type && record._type.indexOf('_sum')>-1 && record._type=='total'">
                                <div :class="{'text-bold':['zhizao_sum','yunying_sum','total'].indexOf(record._type)>-1}"> {{ $dealThousands(text) }}</div>
                            </template>

                        </template>
                    </a-table>
                </div>
            </a-col>
            <a-col  :sm="14" :lg="18"  v-if="getHasToolAuth('tools1_mixed') && columns_step_mix.length>0">
                <div class="table-children">
                    <div class="subtitle">混合成本：</div>
                    <a-table class="cross-table" :columns="columns_step_mix"
                        :data-source="tableData_step_mix" :pagination="pagination"
                        :rowClassName="(_record, index) => (['zhizao_sum','yunying_sum'].indexOf(_record._type)>-1 ? 'gray-bg-def' : (_record._type=='total'?'gray-bg-def-deep':null))"
                        :scroll="isPrint ? { } : { y: 222 }" size="small" bordered  @resizeColumn="(w, col)=>{col.width = w}">
                        <template #bodyCell="{ column, text, record,index }">

                            <template v-if="column.dataIndex === 'item_name'">
                                <div :class="{'text-bold':['zhizao_sum','yunying_sum','total'].indexOf(record._type)>-1}">{{text}}</div>
                            </template>
                            <template v-else >
                                <div :class="{'text-bold':['zhizao_sum','yunying_sum','total'].indexOf(record._type)>-1}">{{$dealThousands(text)}}</div>
                            </template>
                            <!--  -->

                        </template>
                    </a-table>
                </div>
            </a-col>
        </a-row>
    </div>    
</template>

<script>

import { mapGetters  } from 'vuex'
export default {
    
    name:'SolutionCostMixTable',
    props:{
        isPrint: {
            type: Boolean,
            default: false
        },
        planId:{
			type:[Number,String],
			default:0,
		},
        pagination:{
            type:[Boolean,Object],
            default:false,
        }
    },
    data() {
        return {
            columns_step_fixed:[
                { title: '环节', dataIndex: 'item_name', align: 'center',  width: 120,ellipsis: true,resizable: false  },
                { title: '金额', dataIndex: 'cost',  align: 'right', width: 120, ellipsis: true,resizable: false  },
            ],
        }
    },
    computed:{
        ...mapGetters([
            'getHasToolAuth',
        ]),
        tableData_step_fixed(){
            return this.$store.state.tools.tableData_step_fixed
        },
        tableData_step_mix(){
            return this.$store.state.tools.tableData_step_mix
        },
        columns_step_mix(){
            return this.$store.state.tools.columns_step_mix
        },
    },
    watch:{
        planId:{
            immediate:true,
            handler(value){
                if(!value){
                    return;
                }
                this.getStepFixedData()
                this.getStepMixData()
            }
        }
    },
    mounted(){
        
    },
    methods:{
        getStepFixedData(){
            return this.$network('/api/tools/planResultData', {
                id: this.planId,
                type:'step-fixed',
            }).then(res => {
                const data = res.data || {}
                this.$store.commit('updateToolsState',{field:'tableData_step_fixed',value: data.list||[]})
            }).catch(err => {
                console.error(err)
            })
        },
        getStepMixData(){
            return this.$network('/api/tools/planResultData', {
                id: this.planId,
                type:'step-mix',
            }).then(res => {
                const data = res.data || {}
                const columns = data.columns||[];
                const columnsStruct = [];
                columns.map(function(item,index){
                    if(index==0){
                        columnsStruct.push({...item, align:'center',  width: 160,ellipsis: true,resizable: true,fixed:'left' })
                    }else{
                        columnsStruct.push({...item, align:'right',  width: 200,ellipsis: true,resizable: true })
                    }
                })
                this.$store.commit('updateToolsState',{field:'columns_step_mix',value: columnsStruct })
                this.$store.commit('updateToolsState',{field:'tableData_step_mix',value: data.list||[]})
                this.$store.commit('updateToolsState',{field:'mix_origin_data',value: data.originData||[]})
            }).catch(err => {
                console.error(err)
            })
        },
    }
}
</script>
