<template>
      
  <div id="solutionTemplatePDF" class="solutionTemplatePDF">
    <a-watermark :content="getWaterName">
      
      <div class="solution-box">
        <div class="headBox">
          <div class="headBoxBg">
            <img src="@/assets/images/solution_img.png" />
            <div class="headBoxLogo"><img :src="getPlanLogo" class="logo" alt="logo" /></div>
          </div>
          <div class="headBoxContent">
            <div class="headTitle">
              <div class="title">{{ getPlanName }}</div>
              <div class="subtitle">{{ getPlanName_en }}</div>
            </div>
            <div class="author">
              <div class="author_name">{{ getWaterName }} {{ getDetailName }}</div>
              <div class="author_info">
                <span>编制人：{{ userInfo.name }}</span>
                <span>编制时间：{{ detail.created_at }}</span>
                <span>打印次数：{{ detail.times }}次</span>
              </div>
            </div>
          </div>
          <div class="headTypeText">
            <VipTag :isText="true" moduleName="printPDF"/>
            <span>基本方案</span>
          </div>
        </div>

        <div class="solution-wrap">
          <div class="solution-first">
            <div class="solution-children">
              <div class="title"><span>工具指引</span></div>
              <div class="row">
                <div class="RichText">
                  <tools-item-intro type="three" field="intro_print_base" moduleName="background"/>

                </div>
              </div>
            </div>
            <!-- 1 整体参数 -->
            <div class="solution-children">
              <a-row :gutter="100">
                <a-col :span="24">
                  <div class="title"><span>工具假设</span></div>
                  <div class="row">
                    <div class="RichText">
                      <tools-item-intro type="three" field="intro_base" moduleName="background"/>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <!-- 2.0 饼图 -->
            <div class="solution-children">
              <a-row :gutter="100">
                <a-col :span="24">
                  <div class="title"><span>饼图</span></div>
                  <div class="row">
                    <a-row :gutter="100">
                      <a-col :span="8" v-if="solutionPieObj.material">
                        <div>
                          <div>{{ solutionPieObj.material.name }}</div>
                          <div><img :src="solutionPieObj.material.echartdataRange"/></div>
                          <div>{{ solutionPieObj.material.selectName }}</div>
                          <div><img :src="solutionPieObj.material.echarttype"/></div>
                        </div>
                      </a-col>
                      <a-col :span="8" v-if="solutionPieObj.stock">
                        <div>
                          <div>{{ solutionPieObj.stock.name }}</div>
                          <div><img :src="solutionPieObj.stock.echartdataRange"/></div>
                          <div>{{ solutionPieObj.stock.selectName }}</div>
                          <div><img :src="solutionPieObj.stock.echarttype"/></div>
                        </div>
                      </a-col>
                      <a-col :span="8" v-if="solutionPieObj.send_product">
                        <div>
                          <div>{{ solutionPieObj.send_product.name }}</div>
                          <div><img :src="solutionPieObj.send_product.echartdataRange"/></div>
                          <div>{{ solutionPieObj.send_product.selectName }}</div>
                          <div><img :src="solutionPieObj.send_product.echarttype"/></div>
                        </div>
                      </a-col>
                      
                    </a-row>
                  </div>
                </a-col>
              </a-row>
            </div>
            <!-- 4.0 高级功能 -->
            <div class="solution-children" v-if="barEchartObj.name">
              <a-row :gutter="100">
                <a-col :span="24">
                  <div class="title"><span>高级功能</span></div>
                  <div class="row">
                    <a-row :gutter="100">
                      <a-col :span="24" >
                        <div>
                          <div>{{ barEchartObj.name }}</div>
                          <div v-if="barEchartObj.materialName">{{ barEchartObj.materialName }} : {{ barEchartObj.materialSelectName }}</div>
                          <div v-if="barEchartObj.stockName">{{ barEchartObj.stockName }} : {{ barEchartObj.stockSelectName }}</div>
                          <div v-if="barEchartObj.sendProductName">{{ barEchartObj.sendProductName }} : {{ barEchartObj.sendProductSelectName }}</div>
                          <div><img :src="barEchartObj.img"/></div>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                </a-col>
              </a-row>
            </div>
            
            <!-- 5 备注 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="24">
                  <div class="title"><span>编制备注</span></div>
                  <div class="row">
                    <div class="RichText">
                      {{remark_base}}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>

            <!-- 6 方案解读 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="24">
                  <div class="title"><span>方案解读</span></div>
                  <div class="row">
                    <tools-item-intro type="three" field="intro5" moduleName="background"/>
                    <div class="RichText">
                     
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div class="solution-children">
             <p class="tips">*附表见下页</p>
            </div>
          </div>

          <!-- 基础数据 -->
          <div class="solution-children">            
            <p class="tip">附表</p>
            <a-row :gutter="200">
              <a-col :span="24">
                <div class="title"><span>基础数据表</span></div>
                <div class="row" id ="solutionBaseTable">
                  <!-- <solution-struct-operate-table :planId="listQuery.id" :pagination="pagination" :planType="planType" :isPrint="isPrint" /> -->
                </div>
              </a-col>
            </a-row>
          </div>
        </div>

      </div>
    </a-watermark>
  </div>
  
</template>

<script>
import VipTag from '@/components/VipTag.vue'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import { mapGetters  } from 'vuex'
import { message } from 'ant-design-vue'
import { htmlToPDF } from '@/utils/html2pdf'
import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'
//打印模板
export default {
  name: 'solutionTemplatePDF',
  components: {
    VipTag,SliderInputBlock,ToolsItemIntro

  },
  props: {
    type:{
      type: String,
      default:"",
    },
    detail:{
      type: Object,
      default:function(){
        return {}
      }
    },
    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    },
    remark_base: {
      type: String,
      default: ''
    },
    planResult_base: {
      type: String,
      default: ''
    },
    planType: {
      type: String,
      default: ''
    },
  },
	data() {
    return { 

      solutionPieObj: {},
      barEchartObj: {},
    }
  },
  computed: {
    ...mapGetters([
      'getHasToolAuth'
    ]),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    }
  },
  created() {
   
  },
  mounted() {
    
   
  },

 
  methods: {
    
    async downPDF(pageObj) {

      await this.solutionPie(pageObj)
      await this.barEchart(pageObj)
      await this.solutionBaseTable(pageObj)

    },

    //2.0 饼图
    async solutionPie(pageObj){
      this.solutionPieObj =  pageObj.$refs.solutionPie.getPrintInfo();
      console.log('>>>>>>>>>>>',this.solutionPieObj)
    },
    
    //2.0 饼图
    async barEchart(pageObj){
      this.barEchartObj =  !pageObj.$refs.barEchart ? {} : pageObj.$refs.barEchart.getPrintInfo();
      console.log('>>>>>>>>>>>',this.barEchartObj)
    },
    //数据
    async solutionBaseTable(pageObj){
      document.getElementById("solutionBaseTable").appendChild(pageObj.$refs.solutionTable.$refs.tableRef.$el)
    },
    
  }
}
</script>

<style lang="less" >
#solutionTemplatePDF {
  .solution-box {
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
      padding: 6px 12px;
      font-size: 18px;
    }
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
      padding: 6px 5px;
    }
    .ant-table .ant-table-container .ant-table-cell {
      font-size: 16px;
    }
  }
}
</style>
