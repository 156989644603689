import { timeFix } from '@/utils/util';
import ls from '@/utils/Storage';
import { ACCESS_TOKEN, PERMISSION, USER_INFO } from '@/store/mutation-types';
import { message, notification } from 'ant-design-vue';
import { Router } from 'vue-router'
// import { getSmsCaptcha } from './service'
import baseService from '@/utils/http/axios'
import emitter from '@/utils/eventBus'

export const loginSuccess = (token, userInfo, router: Router) => {
    const noticeMsg = userInfo.noticeInfo||'';
    // 延迟 1 秒显示欢迎信息
    setTimeout(() => {
        notification.success({
            message: `${timeFix()}，欢迎回来`,
            description: `${noticeMsg}`,
        });
    });
    ls.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000);
    // ls.set(PERMISSION, Array.isArray(res.permission) ? res.permission : res.permission.split(','));
    ls.set(PERMISSION, []);
    ls.set(USER_INFO, userInfo);
    emitter.emit('bind_user_info',userInfo);
    // emitter.emit('flush_user_info')
    router.replace({ path: '/' });
};

export const requestFailed = (err) => {
    notification['error']({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4,
    });
};

export const useGetCaptcha = (e, validate, state, form,) => {
    e.preventDefault();
    validate(['phone']).then((res) => {
        state.smsSendBtn = true;
        const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
                state.time = 60;
                state.smsSendBtn = false;
                window.clearInterval(interval);
            }
        }, 1000);

        baseService.post('/api/sms/send', {
            phone: form.phone,
            type: form.type
        }).then(res => {
            // notification['success']({
            //     message: '提示',
            //     description: res.msg,
            //     duration: 8,
            // });
        }).catch((err) => {
            clearInterval(interval);
            state.time = 60;
            state.smsSendBtn = false;
        });
    });
};


export const sendSms = (phone, type,state ) => {
    if(state.time>0){
        return;
    }
    if(!phone){
        return message.error('请输入手机号');
    }
    state.time = 60
    const interval = window.setInterval(() => {
        state.time--;
        if (state.time <= 0) {
            window.clearInterval(interval);
        }
    }, 1000);

    baseService.post('/api/sms/send', {
        phone: phone,
        type: type
    }).then(res => {
        // notification['success']({
        //     message: '提示',
        //     description: res.msg,
        //     duration: 8,
        // });
    }).catch((err) => {
        
    });
    
};
export const validate_email = (value)=>{
    let reg = /^[.a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    return reg.test(value)
}

export const validate_phone = (value)=>{
    let reg = /^1\d{10}$/;
    return reg.test(value)
}

export const checkPasswordLevel = (rule, value) => {
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
    if (value === '') {
        return Promise.resolve('密码必须得含有字母+符号+数字且长度在8-16位之间')
    }
    if (value.length >= 8) {
        if (!regex.test(value)) {
            return Promise.reject('密码必须得含有字母+符号+数字且长度在8-16位之间');
        }
        return Promise.resolve();
    } else {
        return Promise.reject(new Error('密码强度不够'))

    }
    return Promise.resolve()
}
export const validateEmail = (rule, value) => {
    if (value === '') {
        return Promise.resolve('请输入邮箱')
    }
    if (!validate_email(value)) {
        return Promise.reject('邮箱格式错误');
    }
    return Promise.resolve();
}
export const validatePhone = (rule, value) => {
    if (!value || value === '') {
        return Promise.resolve('请输入手机号')
    }
    if (!validate_phone(value+'')) {
        return Promise.reject('手机号格式错误');
    }
    return Promise.resolve();
}