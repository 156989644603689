import { checkZero, numberToFixed, roundToDotNumber } from "@/utils/util"
import ls from '@/utils/Storage'
import baseService from '@/utils/http/axios'

const importFiles_tools_name = "importFiles_tools"
const importFiles_tools = ls.get(importFiles_tools_name) || {}

export default {
	state: {
		//基础方案类型-目前只有 CVP场景化-阶梯售价 用到
		plan_base: '',

		//各种工具说明
		itemIntro: {},
		//调整预测价格
		ycPrice: {},
		ycPriceNum: {},//预售累计数量
		//预测表原数据数据
		excelData_yc: [],
		excelData_ycOrigin: [],
		excelData_ycChange: [],
		//预测表数据
		tableData_step_yc: [],
		//行参数数据
		tableData_changeData: [],
		//行参数数据工具5需要此字段支持
		tableData_changeData_origin: [],
		//固定成本
		tableData_step_fixed: [],
		//混合成本表头
		columns_step_mix: [],
		//混合成本
		tableData_step_mix: [],
		//混合成本数据
		mix_origin_data: [],

		//最大值
		svpYMaxValue: 0,
		svpXMap: [],

		//预处理文件
		importFiles: importFiles_tools,
		spinning1: null
	},
	getters: {


		//总销量
		sum_sold_num(state, getters) {
			return getters.calYcTableData_fumField('sold_num');
		},
		//含税价
		sum_price_add_tax(state, getters) {
			return getters.calYcTableData_fumField('price_add_tax')
		},
		//含税价（合计）
		sum_price_add_tax_per_sum_sold_num(state, getters) {
			let money = getters.sum_sold_num <= 0 ? 0 : getters.sum_sold_come_in / getters.sum_sold_num
			return numberToFixed(money)
		},

		//含税总收入
		sum_sold_come_in(state, getters) {
			return getters.calYcTableData_fumField('sold_come_in')
		},
		//不含税总收入
		sum_price_come_in(state, getters) {
			return getters.calYcTableData_fumField('price_come_in')
		},
		//不含税均单价
		avg_price_no_tax_unit_money(state, getters) {
			let money = getters.sum_sold_num <= 0 ? 0 : getters.sum_price_come_in / getters.sum_sold_num
			return numberToFixed(money)
		},
		//不含税均单价
		avg_change_price_no_tax_unit_money(state, getters) {
			let money = getters.sum_change_sold_num <= 0 ? 0 : getters.sum_change_price_come_in / getters.sum_change_sold_num
			return numberToFixed(money)
		},
		//合格率应为 = 销量合计/产量合计
		sum_pass_per(state, getters) {
			return parseFloat(getters.sum_pass_sold_num) == 0 ? 0 : numberToFixed((getters.sum_sold_num / getters.sum_pass_sold_num) * 100)
		},
		//产量总数
		sum_pass_sold_num(state, getters) {
			return getters.calYcTableData_fumField('pass_sold_num')
		},
		//原单位变动成本
		sum_pass_sold_num_price(state, getters) {
			return parseFloat(getters.sum_pass_sold_num) == 0 ? 0 : numberToFixed(getters.sum_material_cost_money / getters.sum_pass_sold_num)
		},
		//单位变动成本
		sum_material_cost(state, getters) {
			return getters.calYcTableData_fumField('material_cost')
		},
		//总变动成本
		sum_material_cost_money(state, getters) {
			return getters.calYcTableData_fumField('material_cost_money')
		},
		//原单位边际贡献
		sum_side_unit_money(state, getters) {
			return parseFloat(getters.sum_sold_num) == 0 ? 0 : numberToFixed(getters.sum_side_money / getters.sum_sold_num)
		},
		//原边际贡献
		sum_side_money(state, getters) {
			return getters.calYcTableData_fumField('side_money')
		},

		//变动
		//不含税总收入
		sum_change_price_come_in(state, getters) {
			return getters.tableData_changeData_field('price_come_in')
		},
		sum_change_price_add_tax_per_sum_sold_num(state, getters) {
			let money = getters.sum_change_sold_num <= 0 ? 0 : getters.sum_change_sold_come_in / getters.sum_change_sold_num
			return numberToFixed(money)
		},
		price_change_come_in_unit_price(state, getters) {
			const money = getters.sum_change_sold_num * 1 == 0 ? 0 : getters.sum_change_price_come_in / getters.sum_change_sold_num
			return numberToFixed(money)
		},
		sum_change_sold_num(state, getters) {
			return getters.tableData_changeData_field('sold_num')
		},
		sum_change_price_add_tax(state, getters) {
			return getters.tableData_changeData_field('price_add_tax')
		},
		sum_change_sold_come_in(state, getters) {
			return getters.tableData_changeData_field('sold_come_in')
		},
		sum_change_pass_per(state, getters) {
			return parseFloat(getters.sum_change_pass_sold_num) == 0 ? 0 : numberToFixed((getters.sum_change_sold_num / getters.sum_change_pass_sold_num) * 100)
		},
		sum_change_pass_sold_num(state, getters) {
			return getters.tableData_changeData_field('pass_sold_num')
		},
		//原单位变动成本
		sum_change_pass_sold_num_price(state, getters) {
			return parseFloat(getters.sum_change_pass_sold_num) == 0 ? 0 : numberToFixed(getters.sum_change_material_cost_money / getters.sum_change_pass_sold_num)
		},
		sum_change_material_cost(state, getters) {
			return getters.tableData_changeData_field('material_cost')
		},
		sum_change_material_cost_money(state, getters) {
			return getters.tableData_changeData_field('material_cost_money')
		},
		sum_change_side_unit_money(state, getters) {
			return parseFloat(getters.sum_change_sold_num) == 0 ? 0 : numberToFixed(getters.sum_change_side_money / getters.sum_change_sold_num)
		},
		sum_change_side_money(state, getters) {
			return getters.tableData_changeData_field('side_money')
		},
		////制造环节混合成本
		sum_mix_zhizao_money(state, getters) {
			return getters.tableData_step_mix_field('zhizao_sum', getters.sum_sold_num * 1)
		},

		//制造环节加成混合成本
		sum_add_mix_zhizao_money(state, getters) {
			return getters.tableData_step_mix_field('zhizao_sum', getters.sum_change_sold_num * 1)
		},

		//运营环节混合成本
		sum_mix_yunying_money(state, getters) {
			return getters.tableData_step_mix_field('yunying_sum', getters.sum_sold_num)
		},

		//运营环节加成混合成本
		sum_add_mix_yunying_money(state, getters) {
			return getters.tableData_step_mix_field('yunying_sum', getters.sum_change_sold_num * 1)
		},

		//环节固定总成本
		sum_mix_total_money(state, getters) {
			return getters.tableData_step_mix_field('total', getters.sum_sold_num * 1)
		},

		//环节加成固定总成本
		sum_add_mix_total_money(state, getters) {
			return getters.tableData_step_mix_field('total', getters.sum_change_sold_num * 1)
		},
		//制造环节固定成本
		sum_fixed_zhizao_money(state, getters) {
			return getters.tableData_step_fixed_field('zhizao_sum')
		},
		//制造环节固定成本
		sum_fixed_yunying_money(state, getters) {
			return getters.tableData_step_fixed_field('yunying_sum')
		},
		//环节固定总成本
		sum_fixed_total_money(state, getters) {
			return getters.tableData_step_fixed_field('total')
		},
		//总固定成本
		sum_total_money(state, getters) {
			return getters.sum_mix_total_money * 1 + getters.sum_fixed_total_money * 1
		},
		//总固定成本
		sum_change_total_money(state, getters) {
			return getters.sum_add_mix_total_money * 1 + getters.sum_fixed_total_money * 1
		},
		//毛利（原导入）= 原边际贡献 -  制造环节固定成本 - 制造环节对应销量下的混合成本
		maoli(state, getters) {
			// console.log(getters.sum_side_money , getters.sum_fixed_zhizao_money , getters.sum_mix_zhizao_money)
			const money = getters.sum_side_money - getters.sum_fixed_zhizao_money - getters.sum_mix_zhizao_money;
			return roundToDotNumber(money);
		},
		//毛利率（原导入）
		maoli_per(state, getters) {
			// console.log(getters.maoli , getters.sum_price_come_in)
			const money = getters.sum_price_come_in * 1 == 0 ? 0 : (getters.maoli / getters.sum_price_come_in * 100);
			return roundToDotNumber(money) + ' %';
		},

		//毛利（导入后）= 原边际贡献 -  制造环节固定成本 - 制造环节对应销量下的混合成本
		maoli_after(state, getters) {
			// console.log(getters.sum_change_side_money , getters.sum_fixed_zhizao_money , getters.sum_add_mix_zhizao_money)
			const money = getters.sum_change_side_money - getters.sum_fixed_zhizao_money - getters.sum_add_mix_zhizao_money;
			return roundToDotNumber(money);
		},
		//毛利率（原导入）
		maoli_per_after(state, getters) {
			const money = getters.sum_change_price_come_in * 1 == 0 ? 0 : (getters.maoli_after / getters.sum_change_price_come_in * 100);
			return roundToDotNumber(money) + ' %';
		},


		//管理利润/净利润 = 毛利原导入- 运营固定成本 - 运营混合成本
		manager_rate(state, getters) {
			const money = getters.maoli - getters.sum_fixed_yunying_money - getters.sum_mix_yunying_money
			return roundToDotNumber(money)
		},
		// 管理利润率/净利率 = (管理利润/净利润) / 原销售收入
		manager_rate_per(state, getters) {
			const money = getters.sum_sold_come_in * 1 == 0 ? 0 : getters.manager_rate / getters.sum_price_come_in
			return roundToDotNumber(money * 100) + ' %'
		},

		//管理利润/净利润 (加成后) = 毛利原导入- 运营固定成本 - 运营混合成本
		manager_rate_after(state, getters) {
			const money = getters.maoli_after - getters.sum_fixed_yunying_money - getters.sum_add_mix_yunying_money
			return roundToDotNumber(money)
		},
		// 管理利润率/净利率(加成后)  = ( 管理利润/净利润 (加成后) ) / 原销售收入
		manager_rate_after_per(state, getters) {
			const money = getters.sum_change_sold_come_in * 1 == 0 ? 0 : getters.manager_rate_after / getters.sum_change_price_come_in
			return roundToDotNumber(money * 100) + ' %'
		},

		//总成本
		sum_cost_money(state, getters) {
			const money = getters.sum_material_cost_money * 1 + getters.sum_total_money * 1
			return roundToDotNumber(money)
		},
		//总成本
		sum_change_cost_money(state, getters) {
			const money = getters.sum_change_material_cost_money * 1 + getters.sum_change_total_money * 1
			return roundToDotNumber(money)
		},

		// 成本结构（变动）= 变动/总成本
		rate_cost_per_before(state, getters) {
			return roundToDotNumber(checkZero(getters.sum_cost_money) ? 0 : (getters.sum_material_cost_money / getters.sum_cost_money) * 100) + '%'
		},
		// 成本结构（固定） = 固定/总成本
		rate_fixed_per_before(state, getters) {
			return roundToDotNumber(checkZero(getters.sum_cost_money) ? 0 : (getters.sum_total_money / getters.sum_cost_money) * 100) + '%'
		},

		// 成本结构（变动）= 变动/总成本
		rate_cost_per_after(state, getters) {
			return roundToDotNumber(checkZero(getters.sum_change_cost_money) ? 0 : (getters.sum_change_material_cost_money / getters.sum_change_cost_money) * 100) + '%'
		},
		// 成本结构（固定） = 固定/总成本
		rate_fixed_per_after(state, getters) {
			return roundToDotNumber(checkZero(getters.sum_change_cost_money) ? 0 : (getters.sum_change_total_money / getters.sum_change_cost_money) * 100) + '%'
		},


		//边际贡献率 = 原边际贡献 / 原销售收入
		bj_per(state, getters) {
			const per = getters.sum_price_come_in * 1 == 0 ? 0 : getters.sum_side_money / getters.sum_price_come_in
			return roundToDotNumber(per * 100) + ' %'
		},

		bj_per_after(state, getters) {
			const per = getters.sum_change_price_come_in * 1 == 0 ? 0 : getters.sum_change_side_money / getters.sum_change_price_come_in
			return roundToDotNumber(per * 100) + ' %'
		},

		// //经营杠杆系数 = 管理利润/收入
		// risk_per(state,getters){
		// 	const per = getters.sum_price_come_in * 1 == 0 ? 0: getters.manager_rate / getters.sum_price_come_in
		// 	return numberToFixed(per*100)+' %'
		// },

		// risk_after (state,getters){
		// 	const per = getters.sum_change_price_come_in * 1 == 0 ? 0: getters.manager_rate_after / getters.sum_change_price_come_in
		// 	return numberToFixed(per*100)+' %'
		// },

		//变动成本率 = 原总变动成本 / 原销售收入
		bj_cost_per(state, getters) {
			const per = getters.sum_price_come_in * 1 == 0 ? 0 : getters.sum_material_cost_money / getters.sum_price_come_in
			return roundToDotNumber(per * 100) + ' %'
		},

		//变动成本率(加成后) = 原总变动成本 / 原销售收入
		bj_cost_per_after(state, getters) {
			const per = getters.sum_change_sold_come_in * 1 == 0 ? 0 : getters.sum_change_material_cost_money / getters.sum_change_price_come_in
			return roundToDotNumber(per * 100) + ' %'
		},



		//预测汇总-函数
		calYcTableData_fumField: (state, getters) => (field) => {
			let money = 0
			state.tableData_step_yc.map(function (item) {
				money += (item[field] ?? 0) * 1
			})
			return numberToFixed(money)
		},
		//预测变动汇总-函数
		tableData_changeData_field: (state, getters) => (field) => {
			let money = 0
			state.tableData_changeData.map(function (item) {
				money += (item[field] ?? 0) * 1
			})
			return numberToFixed(money)
		},
		//固定成本-函数
		tableData_step_fixed_field: (state, getters) => (field) => {
			let money = 0;
			if (state.tableData_step_fixed && state.tableData_step_fixed.length) {
				state.tableData_step_fixed.map(function (item) {
					if (item._type === field) {
						money = item.cost
					}
				})
			}

			return numberToFixed(money)
		},

		//混合成本-函数
		tableData_step_mix_field: (state, getters) => (field, sold_num) => {
			let money = 0;
			// const _sum_sold_num = sum_sold_num.value - 0;
			if (state.tableData_step_mix && state.tableData_step_mix.length) {
				state.tableData_step_mix.map(function (item) {
					if (item._type === field) {
						let index = -1;
						for (let i = 0; i < state.columns_step_mix.length; i++) {
							const info = state.columns_step_mix[i]
							if ('range_min' in info) {
								index++;
								if (info['range_min'] <= sold_num && sold_num < info['range_max']) {
									money = item['range_' + index]
									break;
								}
							}
						}
					}
				})
			}
			return money
		},
		//混合成本区间范围计算
		mixRangeData(state, getters) {
			const range = []
			for (let i = 0; i < state.columns_step_mix.length; i++) {
				const info = state.columns_step_mix[i]
				if ('range_min' in info) {
					range.push({
						min: info.range_min,
						max: info.range_max,
						money: 0,
					})
				}
			}
			return range.map(function (item) {
				const min = parseInt(item.min);
				if (state.mix_origin_data && state.mix_origin_data.length) {
					state.mix_origin_data.map(function (originItem) {
						const range_min = parseInt(originItem.range_min || 0)
						const range_max = parseInt(originItem.range_max || 0)
						const cost = parseFloat(originItem.cost || 0)
						if (range_min <= min && range_max > min) {
							item.money += cost
						}
					})
				}
				return item
			})
		},
	},
	mutations: {
		//清空数据
		clearTools(state,) {
			console.log('>>>> 工具一数据清空 <<<<<')
			state.excelData_yc = [];
			state.excelData_ycChange = [];
			state.excelData_ycOrigin = [];
			state.tableData_step_yc = [];
			state.tableData_changeData = [];
			state.tableData_changeData_origin = [];
			state.tableData_step_fixed = [];
			state.columns_step_mix = [];
			state.tableData_step_mix = [];
			state.mix_origin_data = [];
			state.ycPrice = [];
			state.spinning1 = null;
		},
		updateToolsState(state, { field, value }) {

			state[field] = value
		},
		saveToolsState(state, { field, value }) {
			let importFiles = state.importFiles || {}
			importFiles[field] = value
			state.importFiles = importFiles
			ls.set(importFiles_tools_name, importFiles)
		},
		clearToolsState(state) {
			state.importFiles = {}
			ls.set(importFiles_tools_name, {})
		},
	},
	actions: {
		handleToolsIntro(context) {
			baseService.get("/api/tools/toolsIntro").then((res) => {
				const data = res.data || {}
				context.state.itemIntro = data.data || {}
			}).catch(err => {
				setTimeout(() => {
					context.dispatch('handleToolsIntro')
				}, 800)
			})
		},
	}
}
