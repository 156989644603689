export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE'
export const TOGGLE_LAYOUT = 'TOGGLE_LAYOUT'
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER'
export const TOGGLE_CONTENT_WIDTH = 'TOGGLE_CONTENT_WIDTH'
export const TOGGLE_COLOR = 'TOGGLE_COLOR'
export const THEME_COLOR = 'THEME_COLOR'
export const THEME_BG_COLOR = 'THEME_BG_COLOR'
export const TOGGLE_WEAK = 'TOGGLE_WEAK'
export const TOGGLE_MULTI_TAB = 'TOGGLE_MULTI_TAB'
export const USER_INFO = 'USER_INFO'
export const PERMISSION = 'PERMISSION'
export const TOGGLE_DEVICE = 'TOGGLE_DEVICE'
export const SET_SETTING_DRAWER = 'SET_SETTING_DRAWER'
export const SET_SIDEBAR_TYPE = 'SET_SIDEBAR_TYPE'
export const TOGGLE_FIXED_SIDERBAR = 'TOGGLE_FIXED_SIDERBAR'
export const TOGGLE_FIXED_HEADER_HIDDEN = 'TOGGLE_FIXED_HEADER_HIDDEN'
export const TOGGLE_GRAY = 'TOGGLE_GRAY'
export const TOGGLE_THEME = 'TOGGLE_THEME'
export const TOGGLE_LAYOUT_MODE = 'TOGGLE_LAYOUT_MODE'
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR'
export const SET_DARK_MODE = 'SET_DARK_MODE'
export const SITE_SETTINGS = 'SITE_SETTINGS'
export const MENU_NAV = 'MENU_NAV'
export const SET_LOCK_SCREEN = 'SET_LOCK_SCREEN'
export const CACHE_PHONE = 'CACHE_PHONE'

export const DEVICE_TYPE = {
    DESKTOP: 'desktop',
    TABLET: 'tablet',
    MOBILE: 'mobile'
}

export const CONTENT_WIDTH_TYPE = {
    Fluid: 'Fluid',
    Fixed: 'Fixed'
}

export const NAV_THEME = {
    LIGHT: 'light',
    DARK: 'dark'
}
