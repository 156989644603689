<template>
  <a-button @click="handleClick"> <slot></slot> </a-button>
</template>
  
  <script>
import { notification, Button } from 'ant-design-vue'
import { h } from 'vue'
export default {
  name: 'OperateLimit',
  components: {},
  props: {
    useTool: {
      type: String,
      default: ''
    },
    showErr: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  methods: {
    handleClick() {
      this.$network('/api/user/checkToolPromise', { use_tool: this.useTool }).then((res) => {
        const data = res.data || {}
        const state = data.state || 0
        const msg = data.msg || ''
        const limit_times = data.limit_times || 0
        const use_times = data.use_times || 0
        const reduce_times = data.reduce_times || 0
        if (state === 1) {
          this.$emit('doClick')
        } else {
          if (this.showErr) {
            notification.error({
              description: msg
              // btn: () =>
              // h(
              //     Button,
              //     {
              //     type: 'primary',
              //     size: 'small',
              //     onClick: () => {
              //         this.$router.push({path:'/cost',query:{}})
              //     },
              //     },
              //     { default: () => '前往开通' },
              // ),
            })
            this.$router.push({ path: '/cost', query: {} })
          } else {
            this.$emit('errClick')
          }
        }
      })
    }
  }
}
</script>
  
  <style lang="less" scoped>
</style>