<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Register',
})
</script>
<template>
    <div class="main user-layout-register">
        <div class="user-register-box">
            <div class="register-steps">
                <a-steps :current="1" label-placement="vertical" :items="items" />
            </div>
            <div class="register-form">
                <a-form id="formRegister" layout="vertical" :model="form">

                    <a-form-item v-bind="validateInfos.account">
                        <a-input :bordered="false" v-model:value="form.account" placeholder="账户唯一识别，可用来登录">
                            <template #prefix>
                                <div class="ant-input-label">
                                    账　号　名
                                </div>
                            </template>
                        </a-input>
                    </a-form-item>

                    <a-form-item v-bind="validateInfos.password">
                        <a-input-password :bordered="false" placeholder="请使用三种或三种以上8-16位字符组合" v-model:value="form.password">
                            <template #prefix>
                                <div class="ant-input-label">
                                    设 置 密 码
                                </div>
                            </template>
                        </a-input-password>
                    </a-form-item>

                    <a-form-item v-bind="validateInfos.password2">
                        <a-input-password :bordered="false" placeholder="请再次输入密码" v-model:value="form.password2">
                            <template #prefix>
                                <div class="ant-input-label">
                                    确 认 密 码
                                </div>
                            </template>
                        </a-input-password>
                    </a-form-item>

                    <a-form-item class="noStyle">
                        <a-checkbox>我已阅读并同意
                            <a @click.prevent="$refs.popupContent.open('userProtocol')">《服务协议》</a>和
                            <a @click.prevent="$refs.popupContent.open('tipProtocol')">《个人信息保护政策》</a>
                        </a-checkbox>
                    </a-form-item>

                    <a-button type="primary" htmlType="submit" class="register-button"
                        @click.stop.prevent="handleSubmit">下一步</a-button>

                    <router-link class="login" :to="{ path: '/user/login' }">使用已有账户登录</router-link>
                </a-form>
            </div>
        </div>
        <PopupContent ref="popupContent"/>
    </div>
</template>

<script lang="ts" setup name="Register">
import { ref, reactive, computed } from 'vue'
import baseService from '@/utils/http/axios'

import { Form } from 'ant-design-vue'
import { MailOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { isMobile } from '@/utils/device'

import ls from '@/utils/Storage'
import { CACHE_PHONE, } from '@/store/mutation-types'

const items = ref([
    {
        title: '验证手机号',
    },
    {
        title: '填写账号信息',
    },
    {
        title: '注册成功',
    }
]);
const router = useRouter()
const useForm = Form.useForm

// 表单相关
const form = reactive({
    phone: ls.get(CACHE_PHONE) || '',
    account: '',
    password: '',
})
const handlePasswordLevel = (rule, value) => {
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
    if (value === '') {
        return Promise.resolve('密码必须得含有字母+符号+数字且长度在8-16位之间')
    }
    if (value.length >= 8) {
        if (!regex.test(value)) {
            return Promise.reject('密码必须得含有字母+符号+数字且长度在8-16位之间');
        }
        return Promise.resolve();
    } else {
        return Promise.reject(new Error('密码强度不够'))

    }
    return Promise.resolve()
}
const handlePasswordCheck = (rule, value) => {
    if (value === '') {
        return Promise.resolve('密码必须得含有字母+符号+数字且长度在8-16位之间');
    } else if (value !== form.password) {
        return Promise.reject('两次密码不一致');
    }
    return Promise.resolve()
}

const rules = reactive({
    account: [
        { required: true, message: '请填写账户名' },
        { validateTrigger: ['change', 'blur'] }
    ],
    password: [
        { required: true, message: '密码必须得含有字母+符号+数字且长度在8-16位之间' },
        { validator: handlePasswordLevel },
        { validateTrigger: ['change', 'blur'] }
    ],
    password2: [
        { required: true, message: '密码必须得含有字母+符号+数字且长度在8-16位之间' },
        { validator: handlePasswordCheck },
        { validateTrigger: ['change', 'blur'] }
    ],
})
const { validate, validateInfos } = useForm(form, rules)
const handleSubmit = () => {
    validate().then((res) => {
        baseService.post('/api/account/phoneReg', {
            phone: form.phone,
            account: form.account,
            password: form.password,
        }).then(res => {

            ls.set(CACHE_PHONE, form.phone)

            router.push({ path: '/user/registerResult', params: {} })
            console.log(res)
        }).catch(err => {
            console.error(err)
        })
        // router.push({ name: 'registerResult', params: { ...form } })
    })
}

</script>
<style lang="less">
.user-register {
    &.error {
        color: #ff0000;
    }

    &.warning {
        color: #ff7e05;
    }

    &.success {
        color: #52c41a;
    }
}

.user-layout-register {
    .ant-input-group-addon:first-child {
        background-color: #fff;
    }

    .register-form {
        .ant-form-item {
            border: 1px solid #d9d9d9;

            &.noStyle {
                border: none;
            }

            .ant-select {
                flex: 1;
                width: 100%;
                padding: 8px 0px;

                .ant-select-selector {
                    padding: 0px 16px;
                }
            }

            .ant-input-group {
                display: flex;
                align-items: center;
            }

            .ant-input-group-compact {
                padding: 3px 0px;
            }

            .ant-input {
                padding: 12px 16px;
            }
        }
    }

    .picture-upload {
        display: flex;

        .ant-upload-wrapper.ant-upload-picture-card-wrapper {
            width: auto;

            .ant-upload.ant-upload-select {
                width: auto;
                height: auto;
            }
        }

        .picture-example {
            width: 210px;

            .picture-example-thumbnail {
                border: 1px dashed #d9d9d9;
                border-radius: 2px;
            }

            .picture-example-name {
                text-align: center;
                margin-top: 8px;
            }

        }

        .picture-button {
            background-color: #f6f9ff;
        }
    }
}
</style>
<style lang="less" scoped>
.user-layout-register {
    width: 1200px;
    min-height: 100%;
    padding: 2.5% 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .user-register-box {
        width: 100%;
        padding: 12px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .register-steps {
            width: 100%;
            padding: 24px 16%;
            border-bottom: 1px solid #d9d9d9;
        }

        .register-form {
            width: 640px;
            padding: 48px 0px 24px;
        }


    }

    .ant-input-label {
        min-width: 78px;
        text-align: justify;
        height: 100%;
        display: flex;
        align-items: center;

        &.addon {
            border-right: 1px solid #d9d9d9;
        }
    }

    .ant-input-group-label {
        min-width: 72px;
        text-align: justify;
        padding: 0px 0px 0px 11px;
        margin-inline-end: 4px;
    }

    .getCaptcha {
        display: block;
        width: 200px;
        height: 54px;
    }

    .register-button {
        width: 100%;
        height: 54px;
        font-size: 16px;
    }

    .login {
        float: right;
        line-height: 40px;
    }
}
</style>
