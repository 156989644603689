<template>
    <div class="import scroll">

        <div class="body">
            <div class="vabTabsBox">
                <div class="title">{{detail.name}}-导入文件</div>
            </div>
            <div class="children">
                <a-alert message="请下载 服务器导入模板（.xlsx）然后按照模板的要求依次顺序上传文件" type="info" />

                <div class="import-box">
               
                    <a-row :gutter="48">
                        <a-col class="gutter-row" :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                            <ToolsImportSelect
                                type="five"
                                :item="stepCVP"
                                :index="0"
                                @handleHisFile="handleSelectPlan('stepCVP')"
                            />
                        </a-col>
                      
                       
                    </a-row>
                </div>

                <div class="import-btns">
                    <!-- <OpenVipBtn content="获取基础方案" v-if="!getHasToolAuth('tools4_base')" /> 
                    <a-button type="primary" :loading="createPlanLoading" @click="handleSavePlan('base','基础方案')" v-else> 获取基础方案 </a-button> -->
                        
                    <OperateLimit type="primary" :loading="createPlanLoading" 
                        @doClick="handleSavePlan('base','基础方案')"
                        useTool="cjjzcl"
                        > 获取基础方案 </OperateLimit>

                </div>
            </div>
        </div>

        <HistoryFiles ref="historyFiles" @selectItem="onSelectItem"/>
        <planSelect ref="planSelect" @selectItem="onPlanSelectItem"/>
    </div>
</template>

<script>
import { message,notification } from 'ant-design-vue';

import planSelect from '../components/planSelect.vue'
import HistoryFiles from '../components/HistoryFiles.vue'
import ToolsImport from '@/components/ToolsImport.vue'
import ToolsImportSelect from '@/components/ToolsImportSelect.vue'
import OpenVipBtn from '@/components/OpenVipBtn.vue'
import { mapGetters } from 'vuex'

import { baseURL, download } from '@/utils/util';

export default{
    components:{
        HistoryFiles,ToolsImport,OpenVipBtn,ToolsImportSelect,planSelect
    },
    data(){
        return {
            detail:{},
            stepCVP:{name:'CVP工具I（基于BOM）已保存方案',temppath:'', temp:'step6-cvp',file:{}, verifyIndex:-1,uploading:''},
            // stepImport:{name:'销售预测阶梯售价表',temppath:'tools5/销售预测阶梯售价表.xlsx', temp:'step5-ladder',file:{}, verifyIndex:0,uploading:''},
          
            selectItemIndex:0,

            createPlanLoading:false,
            selectPlanItem:'',
        }
    },
    computed:{
        ...mapGetters(['getHasToolAuth']),
        getStepImport(){
            let params = {}
            if(this.stepImport.file && this.stepImport.file.id){
                params[this.stepImport.temp] = this.stepImport.file.id
            }
            return params
        },
    
    },
    watch:{
        
    },
    created(){
        this.getData()
    },
    methods:{
        getData(){
            this.$network('/api/tools/typeDetail',{type:'six'}).then(res=>{
                const data =res.data||{}
                this.detail = data.detail||{}
            })
        },
        //选中了哪个文件
        onPlanSelectItem(item){
            item.filename = item.name||''
            this[this.selectPlanItem].file = item
        },
      
        handleSelectPlan(item){
            console.log(item)
            this.selectPlanItem = item
            this.$refs.planSelect.open({tool_type:'one'})
        },
      
        handleSavePlan(planType){
            const params = []
            if(!this.stepCVP.file || !this.stepCVP.file.id){
                return notification.error({  description:  `请先选择【${this.stepCVP.name}】`});
            }else{
                params.push({type:this.stepCVP.temp,id:this.stepCVP.file.id})
            }
           

            this.createPlan(params,planType)
        },
        createPlan(params,planType){
            this.createPlanLoading = true
            this.$network('/api/tools/createPlan',{way:this.way,plan:params,tool_type:'six'}).then(res => {
                const data = res.data || {}
                this.$router.push({path:'/dashboard/cloud6/solution',query:{id:data.id,planType:planType}})
                
            }).catch(err => {
                
            }).finally(()=>{
                this.createPlanLoading = false
            })
        },
    }
}



</script>
<style lang="less" scoped>

</style>
