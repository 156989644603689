<template>
    <a-modal title="历史上传文件" :width="1024" :open="visible" :confirmLoading="loading" :footer="null" @ok="handleSubmit"
        @cancel="close">
        <a-spin :spinning="loading">
            <div class="tableWrapper">

                <a-table :scroll="{ y: '100%' }" :data-source="tableData" :columns="columns" :pagination="false" :loading="tableLoading">
                    <template #bodyCell="{ column, text, record }">

                        <template v-if="column.dataIndex === 'operation'">

                            <a-button type="primary" @click="handleSelect(record)">选择上传</a-button>
                        </template>
                    </template>
                </a-table>

                <a-pagination v-model:current="listQuery.page" :total="tableTotal" show-less-items @change="onPageChange"/>
            </div>
        </a-spin>
    </a-modal>
</template>

<script lang="ts" setup>
import baseService from '@/utils/http/axios';
import list from 'ant-design-vue/es/list';
import { ref, reactive, onMounted, computed, nextTick } from 'vue';

const loading = ref(false)
const visible = ref(false)
const listQuery = reactive({
    type:'',
    page:1,
    limit:null,
})

const open = (item) => {
    tableData.value =[]
    listQuery.type = item.temp
    getData()
    visible.value = true
}
const close = () => {
    visible.value = false
}

defineExpose({
    open
})

const emit = defineEmits()

let tableData = ref([]);
const tableLoading = ref(false)
const tableTotal = ref(0)

const getData = () => {
    tableLoading.value = true
    baseService.post('/api/tools/uploadHistory',listQuery).then(res => {
        const data = res.data || {}
        tableData.value = data.list || []
        tableTotal.value = data.total || []
    }).catch(err => {
        console.error(err)
    }).finally(()=>{
        tableLoading.value = false
    })
}

const onPageChange = (page,pageSize)=>{
    listQuery.page = page
    listQuery.limit = pageSize
    getData()
}

//选择使用哪个文件
const handleSelect = (item)=>{
    close()
    emit('selectItem',item)
}

const handleSubmit = () => {
    visible.value = false
};

const columns = [
    {
        title: '文件名称',
        dataIndex: 'filename',
    },
    {
        title: '文件格式',
        dataIndex: 'format',
        align: 'center'
    },
    {
        title: '文件大小',
        dataIndex: 'fsize',
        align: 'center'
    },
    {
        title: '创建时间',
        dataIndex: 'created_at',
        align: 'center'
    },
    {
        title: '操作',
        dataIndex: 'operation',
        align: 'center'
    },

];

// const data = [];
// for (let i = 0; i < 46; i++) {
//     data.push({
//         key: i,
//         name: `历史上传表格 ${i}`,
//         file_format: 'EXCEL',
//         file_size: '20MB',
//         create_time: '2023-07-05 11:58:58'
//     });
// }

</script>
<style lang="less">
</style>
