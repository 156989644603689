<template>
  <div class="import scroll">
    <div class="body">
      <div class="vabTabsBox">
        <div class="title">{{ detail.name }}-导入文件</div>
      </div>
      <div class="children">
        <a-alert message="请下载 服务器导入模板（.xlsx）然后按照模板的要求依次顺序上传文件" type="info" />

        <div class="import-box">
          <a-row :gutter="48">
            <template v-for="(item, index) in getStepList">
              <a-col class="gutter-row" :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <ToolsImport
                  type="one"
                  :item="item"
                  :index="index"
                  :uploadData="getUploadInfo"
                  @handleChange="handleChange($event, item)"
                  @handleHisFile="handleHisFile(item, index)"
                  :beforeUpload="(file, fileList) => onBeforeUpload(file, fileList, item)"
                />
              </a-col>
            </template>
          </a-row>
        </div>

        <div class="import-btns">
          <!-- <router-link class="tools-item" :to="{ path: '/dashboard/cloud/solution' }"> -->
          <!-- <OpenVipBtn content="获取基础方案" v-if="!getHasToolAuth('tools1_base')" />  -->
          <!-- <a-button type="primary" :loading="createPlanLoading" @click="handleSavePlan('base','基础方案')" v-else> 获取基础方案 </a-button> -->

          <OperateLimit type="primary" :loading="createPlanLoading" @doClick="handleSavePlan('base', '基础方案')" useTool="cvp1" v-if="authTools.indexOf('base')>-1"> 获取基础方案 </OperateLimit>
          <!-- </router-link> -->
          <!-- <router-link class="tools-item" :to="{ path: '/dashboard/cloud/solution' }"> -->
          <!-- <OpenVipBtn content="获取扩展方案"  v-if="!getHasToolAuth('tools1_extra')" /> 
                        <a-button type="primary" :loading="createPlanLoading" @click="handleSavePlan('extra','扩展方案')"  v-else> 获取扩展方案 </a-button> -->

          <OperateLimit type="primary" :loading="createPlanLoading" @doClick="handleSavePlan('extra', '扩展方案')" useTool="cvp1" v-if="authTools.indexOf('extra')>-1"> 获取扩展方案 </OperateLimit>

          <!-- </router-link> -->
          <!-- <router-link class="tools-item" :to="{ path: '/history',query:{tool_type:'one'} }"> -->
          <!-- <OpenVipBtn content="获取对比方案"  v-if="!getHasToolAuth('tools1_compare')" />  -->
          <a-button type="primary" @click="handleCompare" v-if="authTools.indexOf('compare')>-1">获取对比方案</a-button>
          <!-- </router-link> -->
        </div>
      </div>
    </div>

    <HistoryFiles ref="historyFiles" @selectItem="onSelectItem" />
  </div>
</template>

<script>
import { InboxOutlined, DownloadOutlined, DownCircleFilled, CheckCircleOutlined } from '@ant-design/icons-vue'
import { message, notification } from 'ant-design-vue'

import HistoryFiles from '../components/HistoryFiles.vue'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import ls from '@/utils/Storage'

import { baseURL, download } from '@/utils/util'
import ToolsImport from '@/components/ToolsImport.vue'
import OpenVipBtn from '@/components/OpenVipBtn.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    InboxOutlined,
    DownCircleFilled,
    DownloadOutlined,
    CheckCircleOutlined,
    HistoryFiles,
    ToolsImport,
    OpenVipBtn
  },
  data() {
    return {
      detail: {},
      stepList: [
        { name: '销售预测表', temppath: 'tools1/销售预测表.xlsx', temp: 'step-yc', file: {}, verifyIndex: -1, uploading: '', uploadDef: true },
        { name: 'BOM信息表', temppath: 'tools1/BOM信息表.xlsx', temp: 'step-bom', file: {}, verifyIndex: 0, uploading: '', uploadDef: true },
        { name: '材料价格表', temppath: 'tools1/材料价格表.xlsx', temp: 'step-material', file: {}, verifyIndex: 1, uploading: '', uploadDef: true },
        { name: '单位变动成本表', temppath: 'tools1/单位变动成本表.xlsx', temp: 'step-cost', file: {}, verifyIndex: -1, uploading: '', uploadDef: true },
        { name: '固定成本表', temppath: 'tools1/固定成本表.xlsx', temp: 'step-fixed', file: {}, verifyIndex: -1, uploading: '', uploadDef: true },
        { name: '混合成本表(非必选)', temppath: 'tools1/混合成本表.xlsx', temp: 'step-mix', file: {}, verifyIndex: -1, uploading: '', uploadDef: false, vipType: 'tools1_cvp_line_mixMoeny' }
      ],
      selectItemIndex: 0,

      createPlanLoading: false,
      authTools:[],
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getStepList() {
      const list = []
      this.stepList.map((item) => {
        if (item.vipType && !this.getHasToolAuth(item.vipType)) {
          return
        }
        list.push(item)
      })
      return list
    },
    getUploadInfo() {
      let params = {}
      for (let i = 0; i < this.stepList.length; i++) {
        const info = this.stepList[i]
        if (!info.file || !('id' in info.file)) {
        } else {
          params[info.temp] = info.file.id
        }
      }
      return params
    }
  },
  created() {
    const stateFiles = this.$store.state.tools.importFiles || {}
    this.stepList.map((item) => {
      item.file = stateFiles[item.temp] || {}
    })
    this.getData()
  },
  methods: {
    getData() {
      this.$network('/api/tools/typeDetail', { type: 'one' }).then((res) => {
        const data = res.data || {}
        this.detail = data.detail || {}
        this.authTools = data.authTools||[]
      })
    },
    onBeforeUpload(file, fileList, item) {
      // console.log('>>>>>>>>>>> onBeforeUpload <<<<<<<<<<<<<<')
      const verifyIndex = item.verifyIndex
      if (verifyIndex > -1) {
        const verifyItem = this.stepList[verifyIndex]
        if (!verifyItem.file.id) {
          notification.error({ description: `请先上传方案【${verifyItem.name}】` })
          return Promise.reject()
        }
      }
    },
    handleChange(info, item) {
      const status = info.file.status
      item.uploading = status
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        const response = info.file.response || {}
        const responseData = response.data || {}
        const file = responseData.info || {}
        if (!response.code) {
          notification.error({ description: response.msg })
          // message.error(response.msg);
        } else {
          item.file = file
          this.$store.commit('saveToolsState', { field: item.temp, value: file })
          notification.success({ description: `${item.name} 上传成功.` })
          // message.success(`${info.file.name} file uploaded successfully.`);
        }
      } else if (status === 'error') {
        notification.error({ description: `${item.name} 上传失败.` })
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    //选中了哪个文件
    onSelectItem(file) {
      this.stepList[this.selectItemIndex].file = file
      this.$store.commit('saveToolsState', { field: this.stepList[this.selectItemIndex].temp, value: file })
    },
    // 历史文件
    handleHisFile(item, index) {
      this.selectItemIndex = index
      this.$refs.historyFiles.open(item)
    },

    handleSavePlan(planType = 'base', planName) {
      const params = []
      for (let i = 0; i < this.stepList.length; i++) {
        const info = this.stepList[i]

        if (!info.file || !('id' in info.file)) {
          if (info.uploadDef) {
            notification.error({ description: `请上传方案【${info.name}】` })
            // message.error(`请上传方案【${info.name}】`);
            return
          }
        }
        params.push({ type: info.temp, id: info.file.id })
      }

      if (!this.getHasToolAuth('tools1_' + planType)) {
        notification.error({ description: `需提升vip等级才能获取【${planName}】` })
        return
      }

      this.createPlan(params, planType)
    },
    handleCompare() {
      if (!this.getHasToolAuth('tools1_compare')) {
        notification.error({ description: `需提升vip等级才能获取【对比方案】` })
        return
      }
      this.$router.push({ path: '/history', query: {} })
    },
    createPlan(params, planType = 'base') {
      this.createPlanLoading = true
      this.$network('/api/tools/createPlan', { plan: params, tool_type: 'one' })
        .then((res) => {
          const data = res.data || {}
          this.$router.push({ path: '/dashboard/cloud/solution', query: { id: data.id, planType: planType } })
        })
        .catch((err) => {})
        .finally(() => {
          this.createPlanLoading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
</style>

