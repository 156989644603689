<template>
  <div class="table-children">
    <a-row>
      <a-col :sm="24" :lg="18">
        <div class="head" v-show="getHasToolAuth('tools1_data_one')">单变量分析</div>
        <div ref="solutionAnalysisVal" v-show="getHasToolAuth('tools1_data_one')">
          <div class="row">
            <a-row :gutter="isPrint ? 48 : 100">
              <a-col :span="12" v-show="getHasToolAuth('tools1_data_one_before')">
                <a-descriptions :column="1" size="small" title="加成前" bordered :labelStyle="isPrint ? { width: '170px' } : { width: '150px' }">
                  <a-descriptions-item label="售价(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_tax_per" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="销量(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_sold_per" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="单位变动成本(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_cost_per" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="合格率(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_pass_per" :max="100" :step="0.5" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="固定成本(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_fixed_per" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="混合成本(%)" v-if="columns_step_mix.length > 0">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_mix_per" :max="100" />
                    </div>
                  </a-descriptions-item>
                </a-descriptions>
              </a-col>
              <a-col :span="12" v-show="getHasToolAuth('tools1_data_one_after')">
                <a-descriptions :column="1" size="small" title="加成后" bordered :labelStyle="isPrint ? { width: '170px' } : { width: '150px' }">
                  <a-descriptions-item label="售价(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_tax_per_after" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="销量(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_sold_per_after" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="单位变动成本(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_cost_per_after" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="合格率(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_pass_per_after" :max="100" :step="0.5" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="固定成本(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_fixed_per_after" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="混合成本(%)" v-if="columns_step_mix.length > 0">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_mix_per_after" :max="100" />
                    </div>
                  </a-descriptions-item>
                </a-descriptions>
              </a-col>
            </a-row>
          </div>
          <div class="row">
            <a-row :gutter="isPrint ? 48 : 100">
              <a-col :sm="24" :lg="24">
                <a-table
                  :columns="columns"
                  :data-source="tableData_auth"
                  :pagination="false"
                  size="small"
                  bordered
                  @resizeColumn="
                    (w, col) => {
                      col.width = w
                    }
                  "
                >
                </a-table>
              </a-col>
            </a-row>
          </div>
        </div>

        <div class="head" v-show="getHasToolAuth('tools1_data_multi')">多变量组合影响</div>
        <div ref="solutionAnalysisValMulti" v-show="getHasToolAuth('tools1_data_multi')">
          <div class="row">
            <a-row :gutter="isPrint ? 48 : 100">
              <a-col :sm="24" :lg="12" v-show="getHasToolAuth('tools1_data_multi_before')">
                <a-descriptions :column="1" size="small" title="加成前" bordered :labelStyle="isPrint ? { width: '170px' } : { width: '150px' }">
                  <a-descriptions-item label="售价(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_tax_per_mix" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="销量(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_sold_per_mix" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="单位变动成本(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_cost_per_mix" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="合格率(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_pass_per_mix" :max="100" :step="0.5" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="固定成本(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_fixed_per_mix" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="混合成本(%)" v-if="columns_step_mix.length > 0">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_mix_per_mix" :max="100" />
                    </div>
                  </a-descriptions-item>
                </a-descriptions>
              </a-col>
              <a-col :sm="24" :lg="12" v-show="getHasToolAuth('tools1_data_multi_after')">
                <a-descriptions :column="1" size="small" title="加成后" bordered :labelStyle="isPrint ? { width: '170px' } : { width: '150px' }">
                  <a-descriptions-item label="售价(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_tax_per_mix_after" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="销量(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_sold_per_mix_after" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="单位变动成本(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_cost_per_mix_after" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="合格率(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_pass_per_mix_after" :max="100" :step="0.5" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="固定成本(%)">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_fixed_per_mix_after" :max="100" />
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="混合成本(%)" v-if="columns_step_mix.length > 0">
                    <div class="descriptions-span">
                      <SliderInputBlock v-model:value="params_mix_per_mix_after" :max="100" />
                    </div>
                  </a-descriptions-item>
                </a-descriptions>
              </a-col>
            </a-row>
          </div>

          <div class="row">
            <a-row :gutter="isPrint ? 48 : 100">
              <a-col :sm="24" :lg="24">
                <a-table
                  :columns="columnsMix"
                  :data-source="tableDataMix_auth"
                  :pagination="false"
                  size="small"
                  bordered
                  @resizeColumn="
                    (w, col) => {
                      col.width = w
                    }
                  "
                >
                </a-table>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>

  <!-- <div class="table-children">
      
    </div> -->
</template>

<script>
import { numberToFixed, roundToDotNumber, fnc_excelData_yc_before, fnc_excelData_yc_after, checkZero } from '@/utils/util'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import { mapGetters } from 'vuex'
//敏感度分析
export default {
  name: 'SolutionAnalysis',
  components: {
    SliderInputBlock
  },
  props: {
    isPrint: {
      type: Boolean,
      default: false
    },
    // pagination: {
    //   type: [Boolean, Object],
    //   default: false
    // },
    paramsTaxPer: {
      type: Number,
      default: 0
    },
    paramsSoldPer: {
      type: Number,
      default: 0
    },
    paramsCostPer: {
      type: Number,
      default: 0
    },
    paramsPassPer: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters([
      'getHasToolAuth',
      'avg_price_no_tax_unit_money',
      'avg_price_no_tax_unit_money',
      'avg_change_price_no_tax_unit_money',
      'sum_sold_num',
      'sum_price_come_in',
      'avg_price_no_tax_unit_money',
      'manager_rate',
      'tableData_step_fixed',
      'tableData_step_mix'
    ]),
    tableData_step_fixed() {
      return this.$store.state.tools.tableData_step_fixed
    },
    tableData_step_mix() {
      return this.$store.state.tools.tableData_step_mix
    },
    columns_step_mix() {
      return this.$store.state.tools.columns_step_mix
    },
    tableData_step_yc() {
      return this.$store.state.tools.tableData_step_yc
    },
    tableData_changeData() {
      return this.$store.state.tools.tableData_changeData
    },
    excelData_yc() {
      return this.$store.state.tools.excelData_yc
    },
    columns_step_mix() {
      return this.$store.state.tools.columns_step_mix
    },
    excelData_ycChange() {
      return this.$store.state.tools.excelData_ycChange
    },
    tableDataMix_auth() {
      if (this.getHasToolAuth('tools1_data_multi')) {
        return this.tableDataMix
      } else if (this.getHasToolAuth('tools1_data_multi_before')) {
        return [this.tableDataMix[0]]
      } else if (this.getHasToolAuth('tools1_data_multi_after')) {
        return [this.tableDataMix[1]]
      }
    },
    tableData_auth() {
      if (this.getHasToolAuth('tools1_data_multi')) {
        return this.tableData
      } else if (this.getHasToolAuth('tools1_data_one_before')) {
        return [this.tableData[0]]
      } else if (this.getHasToolAuth('tools1_data_one_after')) {
        return [this.tableData[1]]
      }
    }
  },
  data() {
    return {
      params_tax_per: 0,
      params_sold_per: 0,
      params_cost_per: 0,
      params_pass_per: 0,
      params_fixed_per: 0,
      params_mix_per: 0,

      params_tax_per_after: 0,
      params_sold_per_after: 0,
      params_cost_per_after: 0,
      params_pass_per_after: 0,
      params_fixed_per_after: 0,
      params_mix_per_after: 0,

      params_tax_per_mix: 0,
      params_sold_per_mix: 0,
      params_cost_per_mix: 0,
      params_pass_per_mix: 0,
      params_fixed_per_mix: 0,
      params_mix_per_mix: 0,

      params_tax_per_mix_after: 0,
      params_sold_per_mix_after: 0,
      params_cost_per_mix_after: 0,
      params_pass_per_mix_after: 0,
      params_fixed_per_mix_after: 0,
      params_mix_per_mix_after: 0,

      groupArray: [],
      tableData: [
        { name: '加成前', tax_per: '0.00', cost_per: '0.00', sold_num: '0.00', pass_per: '0.00', fixed_money: '0.00', mix_money: '0.00' },
        { name: '加成后', tax_per: '0.00', cost_per: '0.00', sold_num: '0.00', pass_per: '0.00', fixed_money: '0.00', mix_money: '0.00' }
      ],
      columns: [
        { width: 90, title: '', dataIndex: 'name', align: 'center', resizable: false, ellipsis: true },
        { width: 110, title: '售价(%)', dataIndex: 'tax_per', align: 'right', resizable: false, ellipsis: true },
        { width: 110, title: '销量(%)', dataIndex: 'sold_num', align: 'right', resizable: false, ellipsis: true },
        { width: 140, title: '单位变动成本(%)', dataIndex: 'cost_per', align: 'right', resizable: false, ellipsis: true },
        { width: 110, title: '合格率(%)', dataIndex: 'pass_per', align: 'right', resizable: false, ellipsis: true },
        { width: 110, title: '固定成本(%)', dataIndex: 'fixed_money', align: 'right', resizable: false, ellipsis: true },
        { width: 120, title: '混合成本(%)', dataIndex: 'mix_money', align: 'right', resizable: false, ellipsis: true }
      ],
      tableDataMix: [
        { width: 110, name: '加成前', per_before: '0.00', per_after: '0.00', per_diff: 0 },
        { width: 110, name: '加成后', per_before: '0.00', per_after: '0.00', per_diff: 0 }
      ],
      columnsMix: [
        { width: 90, title: '', dataIndex: 'name', align: 'center', resizable: false, ellipsis: true },
        { width: 110, title: '新管理利润', dataIndex: 'per_after', align: 'center', resizable: false, ellipsis: true },
        { width: 110, title: '新管理利润率', dataIndex: 'rate_after', align: 'center', resizable: false, ellipsis: true },
        { width: 110, title: '原管理利润', dataIndex: 'per_before', align: 'center', resizable: false, ellipsis: true },
        { width: 110, title: '原管理利润率', dataIndex: 'rate_before', align: 'center', resizable: false, ellipsis: true },
        { width: 110, title: '变化量', dataIndex: 'per_diff', align: 'center', resizable: false, ellipsis: true }
      ],
      newData_before: []
    }
  },
  mounted() {
    this.createWatch_params('tax_per', 'params_tax_per', ['params_tax_per', 'params_tax_per_after', 'paramsTaxPer'], 1)
    this.createWatch_params('cost_per', 'params_cost_per', ['params_cost_per', 'params_cost_per_after', 'paramsCostPer'], -1)
    this.createWatch_params('sold_num', 'params_sold_per', ['params_sold_per', 'params_sold_per_after', 'paramsSoldPer'], 1)
    this.createWatch_params('pass_per', 'params_pass_per', ['params_pass_per', 'params_pass_per_after', 'paramsPassPer'], 1)
    this.createWatch_params('fixed_money', 'params_fixed_per', ['params_fixed_per', 'params_fixed_per_after', 0], 1)
    this.createWatch_params('mix_money', 'params_mix_per', ['params_mix_per', 'params_mix_per_after', 0], -1)

    this.createWatchMix_params()

    // this.createWatchFnc(0,'params_tax_per',[
    //   ['params_tax_per','paramsTaxPer'],
    //   ['params_cost_per','paramsCostPer'],
    //   ['params_sold_per','paramsSoldPer'],
    //   ['params_pass_per','paramsPassPer'],
    //   ['params_fixed_per', 0],
    //   ['params_mix_per', 0],
    // ])
  },
  watch: {
    newData_before(value) {
      // console.log(value)
      this.$nextTick(() => {
        this.tableData[0].price = this.avg_price_no_tax_unit_money_before
        this.tableData[1].price = this.manager_rate_before
      })
      // 不含税均单价
    }
  },
  methods: {
    setParams(params) {
      this.params_tax_per = params.params_tax_per || 0
      this.params_sold_per = params.params_sold_per || 0
      this.params_cost_per = params.params_cost_per || 0
      this.params_pass_per = params.params_pass_per || 0
      this.params_fixed_per = params.params_fixed_per || 0
      this.params_mix_per = params.params_mix_per || 0
      this.params_tax_per_after = params.params_tax_per_after || 0
      this.params_sold_per_after = params.params_sold_per_after || 0
      this.params_cost_per_after = params.params_cost_per_after || 0
      this.params_pass_per_after = params.params_pass_per_after || 0
      this.params_fixed_per_after = params.params_fixed_per_after || 0
      this.params_mix_per_after = params.params_mix_per_after || 0
      this.params_tax_per_mix = params.params_tax_per_mix || 0
      this.params_sold_per_mix = params.params_sold_per_mix || 0
      this.params_cost_per_mix = params.params_cost_per_mix || 0
      this.params_pass_per_mix = params.params_pass_per_mix || 0
      this.params_fixed_per_mix = params.params_fixed_per_mix || 0
      this.params_mix_per_mix = params.params_mix_per_mix || 0
      this.params_tax_per_mix_after = params.params_tax_per_mix_after || 0
      this.params_sold_per_mix_after = params.params_sold_per_mix_after || 0
      this.params_cost_per_mix_after = params.params_cost_per_mix_after || 0
      this.params_pass_per_mix_after = params.params_pass_per_mix_after || 0
      this.params_fixed_per_mix_after = params.params_fixed_per_mix_after || 0
      this.params_mix_per_mix_after = params.params_mix_per_mix_after || 0
    },
    getSaveData() {
      return {
        params_tax_per: this.params_tax_per,
        params_sold_per: this.params_sold_per,
        params_cost_per: this.params_cost_per,
        params_pass_per: this.params_pass_per,
        params_fixed_per: this.params_fixed_per,
        params_mix_per: this.params_mix_per,
        params_tax_per_after: this.params_tax_per_after,
        params_sold_per_after: this.params_sold_per_after,
        params_cost_per_after: this.params_cost_per_after,
        params_pass_per_after: this.params_pass_per_after,
        params_fixed_per_after: this.params_fixed_per_after,
        params_mix_per_after: this.params_mix_per_after,
        params_tax_per_mix: this.params_tax_per_mix,
        params_sold_per_mix: this.params_sold_per_mix,
        params_cost_per_mix: this.params_cost_per_mix,
        params_pass_per_mix: this.params_pass_per_mix,
        params_fixed_per_mix: this.params_fixed_per_mix,
        params_mix_per_mix: this.params_mix_per_mix,
        params_tax_per_mix_after: this.params_tax_per_mix_after,
        params_sold_per_mix_after: this.params_sold_per_mix_after,
        params_cost_per_mix_after: this.params_cost_per_mix_after,
        params_pass_per_mix_after: this.params_pass_per_mix_after,
        params_fixed_per_mix_after: this.params_fixed_per_mix_after,
        params_mix_per_mix_after: this.params_mix_per_mix_after
      }
    },
    createWatch_params(tableField, changeFiled, watchField, defaultPer) {
      this.$watch(
        () => [
          this.excelData_yc,
          this.tableData_step_yc,
          this.tableData_changeData,
          this.tableData_step_fixed,
          this.tableData_step_mix,
          this.columns_step_mix,
          this.excelData_ycChange,
          this[watchField[0]],
          this[watchField[1]],
          watchField[2] ? this[watchField[2]] : 0
        ],
        (info) => {
          const excelData_yc = info[0]
          const tableData_step_yc = info[1]
          const tableData_changeData = info[2]
          const fixedData = info[3]
          const mixData = info[4]
          const columnsData = info[5]
          const excelData_ycChange = info[6]

          let per1 = info[7]
          if (checkZero(per1)) {
            per1 = per1 + defaultPer * 1
          }
          let per1_after = info[8]
          if (checkZero(per1_after)) {
            per1_after = per1_after + defaultPer * 1
          }
          const per2 = info[9]

          let beforePerObj = {}
          let afterPerObj = {}
          beforePerObj[changeFiled] = per1
          afterPerObj[changeFiled] = per1_after * 1
          const newData_before = fnc_excelData_yc_before(excelData_yc, beforePerObj)
          const newData_after = fnc_excelData_yc_before(excelData_ycChange, afterPerObj)
          let watchField_before = {}
          let watchField_after = {}
          if (watchField.indexOf('params_fixed_per') > -1) {
            watchField_before['params_fixed_per'] = per1
          }
          if (watchField.indexOf('params_fixed_per_after') > -1) {
            watchField_after['params_fixed_per'] = per1_after
          }

          if (watchField.indexOf('params_mix_per') > -1) {
            watchField_before['params_mix_per'] = per1
          }

          if (watchField.indexOf('params_mix_per_after') > -1) {
            watchField_after['params_mix_per'] = per1_after
          }

          let symbol = 1
          if (['cost_per', 'fixed_money', 'mix_money'].indexOf(tableField) > -1) {
            symbol = -1
          }
          //原导入
          const manager_rate_origin = this.handleData(tableData_step_yc, fixedData, mixData)
          const manager_rate_origin_change = this.handleData(newData_before, fixedData, mixData, watchField_before)
          const per_before = parseFloat(manager_rate_origin) == 0 ? 0 : (manager_rate_origin_change - manager_rate_origin) / manager_rate_origin
          // console.log(per_before,per1)
          this.tableData[0][tableField] = per_before == 0 || parseFloat(per1) == 0 ? 0 : Math.abs(roundToDotNumber(per_before / per1 / 0.01)) * symbol
          //变更后
          // console.log('>>>>>>>>>>>>>>>after')
          // console.log(afterPerObj,watchField_after)
          const manager_rate_after = this.handleData(tableData_changeData, fixedData, mixData)
          // console.log(manager_rate_after)
          const manager_rate_after_change = this.handleData(newData_after, fixedData, mixData, watchField_after)
          // console.log(manager_rate_after_change)
          const per_after = parseFloat(manager_rate_after) == 0 ? 0 : (manager_rate_after_change - manager_rate_after) / manager_rate_after
          this.tableData[1][tableField] = per_after == 0 || parseFloat(per1_after) == 0 ? 0 : Math.abs(roundToDotNumber(per_after / per1_after / 0.01)) * symbol
        }
      )
    },
    createWatchMix_params() {
      this.$watch(
        () => [
          this.excelData_yc,
          this.tableData_step_yc,
          this.tableData_changeData,
          this.tableData_step_fixed,
          this.tableData_step_mix,
          this.columns_step_mix,
          this.excelData_ycChange,

          [this.params_tax_per_mix, this.params_tax_per_mix_after],
          [this.params_sold_per_mix, this.params_sold_per_mix_after],
          [this.params_cost_per_mix, this.params_cost_per_mix_after],
          [this.params_pass_per_mix, this.params_pass_per_mix_after],
          [this.params_fixed_per_mix, this.params_fixed_per_mix_after],
          [this.params_mix_per_mix, this.params_mix_per_mix_after]
        ],
        (info) => {
          const excelData_yc = info[0]
          const tableData_step_yc = info[1]
          const tableData_changeData = info[2]

          const excelData_ycChange = info[6]

          const fixedData = info[3]
          const mixData = info[4]
          const defaultPerUp = 0
          const defaultPerDown = 0
          const params_tax_per = info[7][0] + defaultPerUp
          const params_tax_per_after = info[7][1] + defaultPerUp

          const params_sold_per = info[8][0] + defaultPerUp
          const params_sold_per_after = info[8][1] + defaultPerUp

          const params_cost_per = info[9][0] + defaultPerDown
          const params_cost_per_after = info[9][1] + defaultPerDown

          const params_pass_per = info[10][0] + defaultPerUp
          const params_pass_per_after = info[10][1] + defaultPerUp

          const params_fixed_per = info[11][0] + defaultPerUp
          const params_fixed_per_after = info[11][1] + defaultPerUp

          const params_mix_per = info[12][0] + defaultPerDown
          const params_mix_per_after = info[12][1] + defaultPerDown

          const watchField_before = { params_fixed_per, params_mix_per }
          const watchField_after = {
            params_fixed_per: params_fixed_per_after,
            params_mix_per: params_mix_per_after
            // params_fixed_per:params_mix_per_after,
            // params_mix_per :params_fixed_per_after,
          }

          const newData_before = fnc_excelData_yc_before(excelData_yc, {
            params_tax_per,
            params_sold_per,
            params_cost_per,
            params_pass_per
          })
          const newData_after = fnc_excelData_yc_before(excelData_ycChange, {
            params_tax_per: params_tax_per_after,
            params_sold_per: params_sold_per_after,
            params_cost_per: params_cost_per_after,
            params_pass_per: params_pass_per_after
          })
          let symbol = 1
          // if(['cost_per','fixed_money','mix_money'].indexOf(tableField)>-1){
          //   symbol = -1
          // }
          //原导入
          const manager_rate_origin = this.handleData(tableData_step_yc, fixedData, mixData)
          const manager_rate_origin_change = this.handleData(newData_before, fixedData, mixData, watchField_before)
          // const per_before = parseFloat(manager_rate_origin)==0 ? 0 : ( (manager_rate_origin_change - manager_rate_origin) / manager_rate_origin )
          // this.tableDataMix[0]['per_before']  = Math.abs( roundToDotNumber(per_before*100) ) * symbol
          this.tableDataMix[0]['per_before'] = roundToDotNumber(manager_rate_origin)
          this.tableDataMix[0]['per_after'] = roundToDotNumber(manager_rate_origin_change)
          this.tableDataMix[0]['per_diff'] = roundToDotNumber(this.tableDataMix[0]['per_after'] * 1 - this.tableDataMix[0]['per_before'] * 1)

          //含税总收入
          const sum_price_come_in_before = this.calYcTableData_fumField('price_come_in', tableData_step_yc)
          this.tableDataMix[0]['rate_before'] = roundToDotNumber(checkZero(sum_price_come_in_before) ? 0 : (manager_rate_origin / sum_price_come_in_before) * 100) + '%'

          const sum_price_come_in_after = this.calYcTableData_fumField('price_come_in', newData_before)
          this.tableDataMix[0]['rate_after'] = roundToDotNumber(checkZero(sum_price_come_in_after) ? 0 : (manager_rate_origin_change / sum_price_come_in_after) * 100) + '%'

          //变更后
          const manager_rate_after = this.handleData(tableData_changeData, fixedData, mixData)
          const manager_rate_after_change = this.handleData(newData_after, fixedData, mixData, watchField_after)
          // const per_after = parseFloat(manager_rate_after)==0 ? 0 : (manager_rate_after_change - manager_rate_after) / manager_rate_after;
          // this.tableDataMix[1]['per_before']  = Math.abs( roundToDotNumber(per_after*100) ) * symbol

          this.tableDataMix[1]['per_before'] = roundToDotNumber(manager_rate_after)
          this.tableDataMix[1]['per_after'] = roundToDotNumber(manager_rate_after_change)
          this.tableDataMix[1]['per_diff'] = roundToDotNumber(this.tableDataMix[1]['per_after'] * 1 - this.tableDataMix[1]['per_before'] * 1)
          this.tableDataMix[1]['rate'] = roundToDotNumber(this.tableDataMix[1]['per_after'] * 1 - this.tableDataMix[1]['per_before'] * 1)

          //含税总收入
          const sum_price_come_in_change_before = this.calYcTableData_fumField('price_come_in', tableData_changeData)
          this.tableDataMix[1]['rate_before'] = roundToDotNumber(checkZero(sum_price_come_in_change_before) ? 0 : (manager_rate_after / sum_price_come_in_change_before) * 100) + '%'

          const sum_price_come_in_change_after = this.calYcTableData_fumField('price_come_in', newData_after)
          this.tableDataMix[1]['rate_after'] = roundToDotNumber(checkZero(sum_price_come_in_change_after) ? 0 : (manager_rate_after_change / sum_price_come_in_change_after) * 100) + '%'
        }
      )
    },

    // createWatchFnc(tableIndex,tableField,changeFiled, watchField){

    //   this.$watch(
    //     () => [
    //       this.excelData_yc,
    //       this.tableData_step_yc,
    //       this.tableData_changeData,
    //       this.tableData_step_fixed,
    //       this.tableData_step_mix,
    //       this.columns_step_mix,
    //       [this[watchField[0][0]],watchField[0][1]?this[watchField[0][1]]:0],
    //       [this[watchField[1][0]],watchField[1][1]?this[watchField[1][1]]:0],
    //       [this[watchField[2][0]],watchField[2][1]?this[watchField[2][1]]:0],
    //       [this[watchField[3][0]],watchField[3][1]?this[watchField[3][1]]:0],
    //       [this[watchField[4][0]],watchField[4][1]?this[watchField[4][1]]:0],
    //       [this[watchField[5][0]],watchField[5][1]?this[watchField[5][1]]:0],
    //     ],
    //     (info) => {
    //       const excelData_yc = info[0];
    //       const tableData_step_yc = info[1];
    //       const tableData_changeData = info[2];
    //       const fixedData = info[3];
    //       const mixData = info[4];
    //       const columnsData = info[5];

    //       let perObj = {}

    //       for(let i=0;i<watchField.length;i++){
    //         const info = watchField[i]
    //         const name = info[0];
    //         const infoPer1 = this[name];
    //         const infoPer2 = info[1] ? this[info[1]] : 0;
    //         perObj[name] = 0
    //         if(tableIndex===0){
    //           perObj[name] = infoPer1
    //         }else if(tableIndex===1){
    //           perObj[name] = infoPer1 + infoPer2
    //         }
    //       }

    //       let newData_origin = [];
    //       let tableData = []
    //       if(tableIndex===0){
    //         tableData = tableData_step_yc
    //         newData_origin = fnc_excelData_yc_before(excelData_yc, perObj)
    //       }else{
    //         tableData = tableData_changeData
    //         newData_origin = fnc_excelData_yc_after(excelData_yc, perObj)
    //       }
    //       let symbol = 1;
    //       if(['cost_per','fixed_money','mix_money'].indexOf(tableField)>-1){
    //         symbol = -1
    //       }

    //       const manager_rate_origin = this.handleData(tableData,fixedData,mixData)
    //       const manager_rate_origin_change = this.handleData(newData_origin,fixedData,mixData)
    //       const per_before = parseFloat(manager_rate_origin)==0 ? 0 : ( (manager_rate_origin_change - manager_rate_origin) / manager_rate_origin )
    //       this.tableData[tableIndex][tableField]  = per_before == 0 || parseFloat(per1) ==0 ? 0 : Math.abs( numberToFixed(per_before/per1*100) ) * symbol

    //     }
    //   )
    // },

    createWatch_price() {
      this.$watch(
        () => [this.avg_price_no_tax_unit_money, this.avg_change_price_no_tax_unit_money],
        (info) => {
          const price_before = info[0]
          const price_after = info[1]
          this.tableData[0].price = price_before
          this.tableData[1].price = price_after
        }
      )
    },
    calYcTableData_fumField(field, values) {
      let money = 0

      if (values && values.length > 0) {
        values.map(function (item) {
          money += (item[field] ?? 0) * 1
        })
      }

      return roundToDotNumber(money)
    },
    //固定成本-函数
    tableData_step_fixed_field(field, values) {
      let money = 0
      if (values && values.length > 0) {
        ;(values || []).map(function (item) {
          if (item._type === field) {
            money = item.cost
          }
        })
      }
      return roundToDotNumber(money)
    },

    //混合成本-函数
    tableData_step_mix_field(field, sold_num, values) {
      let money = 0
      if (values && values.length > 0) {
        values.map((item) => {
          if (item._type === field) {
            let index = -1
            for (let i = 0; i < this.columns_step_mix.length; i++) {
              const info = this.columns_step_mix[i]
              if ('range_min' in info) {
                index++
                if (info['range_min'] <= sold_num && sold_num < info['range_max']) {
                  money = item['range_' + index]
                  break
                }
              }
            }
          }
        })
      }
      return money
    },
    //处理数据
    handleData(newData, fixData, mixData, handleParams) {
      //销量
      const sum_sold_num = this.calYcTableData_fumField('sold_num', newData)

      //含税总收入
      const sum_price_come_in = this.calYcTableData_fumField('price_come_in', newData)
      //原边际贡献
      const sum_side_money = this.calYcTableData_fumField('side_money', newData)

      //含税均单价
      const avg_price_no_tax_unit_money = sum_sold_num * 1 == 0 ? 0 : sum_price_come_in / sum_sold_num
      //制造环节固定成本
      let sum_fixed_zhizao_money = this.tableData_step_fixed_field('zhizao_sum', fixData)
      //制造环节固定成本
      let sum_fixed_yunying_money = this.tableData_step_fixed_field('yunying_sum', fixData)
      if (handleParams && 'params_fixed_per' in handleParams) {
        sum_fixed_zhizao_money = sum_fixed_zhizao_money * (1 + handleParams['params_fixed_per'] / 100)
        sum_fixed_yunying_money = sum_fixed_yunying_money * (1 + handleParams['params_fixed_per'] / 100)
      }
      //制造环节混合成本
      let sum_mix_zhizao_money = this.tableData_step_mix_field('zhizao_sum', sum_sold_num * 1, mixData)
      //运营环节混合成本
      let sum_mix_yunying_money = this.tableData_step_mix_field('yunying_sum', sum_sold_num * 1, mixData)
      if (handleParams && 'params_mix_per' in handleParams) {
        sum_mix_zhizao_money = sum_mix_zhizao_money * (1 + handleParams['params_mix_per'] / 100)
        sum_mix_yunying_money = sum_mix_yunying_money * (1 + handleParams['params_mix_per'] / 100)
      }
      //毛利（原导入）= 原边际贡献 -  制造环节固定成本 - 制造环节对应销量下的混合成本
      const maoli = roundToDotNumber(sum_side_money - sum_fixed_zhizao_money - sum_mix_zhizao_money)
      //管理利润
      const manager_rate = roundToDotNumber(maoli - sum_fixed_yunying_money - sum_mix_yunying_money)

      return manager_rate
    }
  }
}
</script>
<style lang="scss">
.ant-descriptions .ant-descriptions-header {
  margin-bottom: 10px;
}
.ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
  padding: 8px;
}
</style>