<template>
  <div class="row">
    <a-row :gutter="20">
      <a-col :sm="24" :lg="6" v-if="getHasToolAuth('tools1_analysis_yunying')">
        <div class="table-children">
          <div class="subtitle">盈利指标：</div>
          <a-table class="cross-table" :columns="columns_operate" :data-source="tableData_operate" :pagination="pagination" size="small" bordered>
            <template #bodyCell="{ column, text, record, index }">
              <template v-if="column.dataIndex === 'before_data'">
                <template v-if="text.indexOf('%') > -1">
                  {{ text }}
                </template>
                <template v-else>
                  {{ $dealThousands(text) }}
                </template>
              </template>
              <template v-if="column.dataIndex === 'after_data'">
                <template v-if="text.indexOf('%') > -1">
                  {{ text }}
                </template>
                <template v-else>
                  {{ $dealThousands(text) }}
                </template>
              </template>
            </template>
          </a-table>
        </div>
      </a-col>
      <a-col :sm="24" :lg="6" v-if="getHasToolAuth('tools1_analysis_cost')">
        <div class="table-children">
          <div class="subtitle">成本结构类指标：</div>
          <a-table
            class="cross-table"
            :columns="columns_cost"
            :data-source="tableData_cost"
            :pagination="pagination"
            size="small"
            bordered
            @resizeColumn="
              (w, col) => {
                col.width = w
              }
            "
          >
          </a-table>
        </div>
      </a-col>
      <a-col :sm="24" :lg="6" v-if="getHasToolAuth('tools1_analysis_risk')">
        <div class="table-children">
          <div class="subtitle">风险类指标:</div>
          <a-table
            class="cross-table"
            :columns="columns_risk"
            :data-source="tableData_risk"
            :pagination="pagination"
            size="small"
            bordered
            @resizeColumn="
              (w, col) => {
                col.width = w
              }
            "
          >
          </a-table>
        </div>
      </a-col>
      <a-col :sm="24" :lg="6" v-if="getHasToolAuth('tools1_analysis_side')">
        <div class="table-children">
          <div class="subtitle">边际类指标：</div>
          <a-table
            class="cross-table"
            :columns="columns_bj"
            :data-source="tableData_bj"
            :pagination="pagination"
            size="small"
            bordered
            @resizeColumn="
              (w, col) => {
                col.width = w
              }
            "
          >
          </a-table>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { checkZero,fnc_excelData_yc_before,numberToFixed,roundToDotNumber } from '@/utils/util'
import { mapGetters } from 'vuex'
import calMixIn from '../calMixIn.vue'
export default {
  name: 'SolutionStructOperateTable',
  props: {
    isPrint: {
      type: Boolean,
      default: false
    },
    planId: {
      type: [Number, String],
      default: 0
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    },
    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    },
    // excelData_yc: {
    //     type: Array,
    //     required: true
    // },
    // columns_step_mix: {
    //     type: Array,
    //     required: true
    // },
    // tableData_step_fixed: {
    //     type: Array,
    //     required: true
    // },

    // tableData_step_mix: {
    //     type: Array,
    //     required: true
    // },
  },
  data() {
    return {
      columns_operate: [
        { title: '', dataIndex: 'name', align: 'center', width: 150 },
        { title: '原导入', dataIndex: 'before_data', align: 'right',width:110 },
        { title: '加成后', dataIndex: 'after_data', align: 'right',width:110 }
      ],
      columns_cost: [
        { title: '', dataIndex: 'name', align: 'center', width: 150 },
        { title: '原导入', dataIndex: 'rate_before', align: 'right',width:110 },
        { title: '加成后', dataIndex: 'rate_after', align: 'right',width:110 }
      ],
      columns_bj: [
        { title: '', dataIndex: 'name', align: 'center', width: 150 },
        { title: '原导入', dataIndex: 'bj_before', align: 'right',width:110 },
        { title: '加成后', dataIndex: 'bj_after', align: 'right',width:110 }
      ],
      columns_risk: [
        { title: '', dataIndex: 'name', align: 'center', width: 150 },
        { title: '原导入', dataIndex: 'risk_before', align: 'right',width:110 },
        { title: '加成后', dataIndex: 'risk_after', align: 'right',width:110 }
      ],
      tableData_risk:[
        { name: '经营杠杆系数', risk_before: '0.00%', risk_after: '0.0%' }
    ],
    }
  },
  mixins: [calMixIn],
  computed: {
    ...mapGetters([
      'getHasToolAuth',
      'maoli',
      'maoli_per',
      'maoli_after',
      'maoli_per_after',
      'manager_rate',
      'manager_rate_after',
      'manager_rate_per',
      'manager_rate_after_per',
      'bj_per',
      'bj_per_after',
      'bj_cost_per',
      'bj_cost_per_after',
      'rate_cost_per_before',
      'rate_cost_per_after',
      'rate_fixed_per_before',
      'rate_fixed_per_after',
      'risk_per',
      'risk_after'
    ]),
    tableData_step_fixed() {
      return this.$store.state.tools.tableData_step_fixed
    },
    tableData_step_mix() {
      return this.$store.state.tools.tableData_step_mix
    },
    columns_step_mix() {
      return this.$store.state.tools.columns_step_mix
    },
    tableData_step_yc() {
      return this.$store.state.tools.tableData_step_yc
    },
    tableData_changeData() {
      return this.$store.state.tools.tableData_changeData
    },
    excelData_yc() {
      return this.$store.state.tools.excelData_yc
    },
    columns_step_mix() {
      return this.$store.state.tools.columns_step_mix
    },
    excelData_ycChange() {
      return this.$store.state.tools.excelData_ycChange
    },
    tableData_operate() {
      return [
        // 毛利(原导入) = 原边际贡献 -  制造环节固定成本 - 制造环节对应销量下的混合成本
        { name: '毛利', before_data: this.maoli, after_data: this.maoli_after },
        // 毛利(加成后) = 原边际贡献 -  制造环节固定成本 - 制造环节对应销量下的混合成本
        { name: '毛利率', before_data: this.maoli_per, after_data: this.maoli_per_after },
        { name: '管理利润/净利润', before_data: this.manager_rate, after_data: this.manager_rate_after },
        { name: '管理利润率/净利率', before_data: this.manager_rate_per, after_data: this.manager_rate_after_per }
      ]
    },

    tableData_cost() {
      return [
        { name: '变动成本', rate_before: this.rate_cost_per_before, rate_after: this.rate_cost_per_after },
        { name: '固定成本', rate_before: this.rate_fixed_per_before, rate_after: this.rate_fixed_per_after }
      ]
    },
    tableData_bj() {
      return [
        { name: '边际贡献率', bj_before: this.bj_per, bj_after: this.bj_per_after },
        { name: '变动成本率', bj_before: this.bj_cost_per, bj_after: this.bj_cost_per_after }
      ]
    },
    
  },
  mounted() {
    this.createWatchMix_params()
  },
  methods: {
    createWatchMix_params() {
      this.$watch(
        () => [
          this.excelData_yc,
          this.tableData_step_yc,
          this.tableData_changeData,
          this.tableData_step_fixed,
          this.tableData_step_mix,
          this.columns_step_mix,
          this.excelData_ycChange,

          [this.params_tax_per],
          [this.params_sold_per],
          [this.params_cost_per],
          [this.params_pass_per],
        //   [this.params_fixed_per_mix],
        //   [this.params_mix_per_mix]
        ],
        (info) => {
          const excelData_yc = info[0]
          const tableData_step_yc = info[1]
          const tableData_changeData = info[2]

          const excelData_ycChange = info[6]

          const fixedData = info[3]
          const mixData = info[4]

          const defaultPerUp = 1
          const defaultPerDown = 1

          const params_tax_per = defaultPerUp // info[7][0] + defaultPerUp
        //   const params_tax_per_after = info[7][1] + defaultPerUp

          const params_sold_per = defaultPerUp// info[8][0] + defaultPerUp
        //   const params_sold_per_after = info[8][1] + defaultPerUp

          const params_cost_per = info[9][0] //+ defaultPerDown
        //   const params_cost_per_after = info[9][1] + defaultPerDown

          const params_pass_per = info[10][0] //+ defaultPerUp
        //   const params_pass_per_after = info[10][1] + defaultPerUp

          const params_fixed_per = 0;//info[11][0] + defaultPerUp
        //   const params_fixed_per_after = info[11][1] + defaultPerUp

          const params_mix_per = 0;//info[12][0] + defaultPerDown
        //   const params_mix_per_after = info[12][1] + defaultPerDown

          // const watchField_before = { params_fixed_per, params_mix_per }
       
          
          const newData_before = fnc_excelData_yc_before(excelData_yc, {
            params_tax_per,
            params_sold_per,
            // params_cost_per,
            // params_pass_per
          })

          const newData_after = fnc_excelData_yc_before(excelData_ycChange, {
            params_tax_per,
            params_sold_per,
          })
          let symbol = 1
          // if(['cost_per','fixed_money','mix_money'].indexOf(tableField)>-1){
          //   symbol = -1
          // }
          //管理利润(原）
          const manager_rate_before = this.handleData(tableData_step_yc, fixedData, mixData)

          //管理利润(原新）
          const manager_rate_before_new = this.handleData(newData_before, fixedData, mixData)
          const manager_rate_before_diff = manager_rate_before_new - manager_rate_before
          //管理百分比
          const manager_rate_before_per = checkZero(manager_rate_before) ?0 : manager_rate_before_diff / manager_rate_before

          //含税总收入(原)
          const sum_price_come_in_before = this.calYcTableData_fumField('price_come_in', tableData_step_yc)
          //含税总收入(原新)
          const sum_price_come_in_before_new = this.calYcTableData_fumField('price_come_in', newData_before)
          const diff_sum_price_come_in_before = sum_price_come_in_before_new - sum_price_come_in_before
          //收入百分比
          const price_come_in_before_per = checkZero(sum_price_come_in_before) ?0 : diff_sum_price_come_in_before / sum_price_come_in_before
          this.tableData_risk[0]['risk_before'] = roundToDotNumber( checkZero(price_come_in_before_per) ?0 : Math.abs( manager_rate_before_per / price_come_in_before_per) )+' %'

          // console.log('>>>>>----------- 加成前 -----------<<<<')
          // console.log('总收入',sum_price_come_in_before)
          // console.log('加成总收入',sum_price_come_in_before_new)
          // console.log('加成差异',diff_sum_price_come_in_before)
          // console.log('加成比例',price_come_in_before_per)
          // console.log('管理利润',manager_rate_before)
          // console.log('加成管理利润',manager_rate_before_new)
          // console.log('管理利润差异',manager_rate_before_diff)
          // console.log('管理利润比例',manager_rate_before_per)
          // console.log('杠杆',manager_rate_before_per / price_come_in_before_per)
          
          //管理利润(原）
          const manager_rate_after = this.handleData(tableData_changeData, fixedData, mixData)

          //管理利润(原新）
          const manager_rate_after_new = this.handleData(newData_after, fixedData, mixData)
          const manager_rate_after_diff = manager_rate_after_new - manager_rate_after
          //管理百分比
          const manager_rate_after_per = checkZero(manager_rate_after) ?0 : manager_rate_after_diff / manager_rate_after

          //含税总收入(原)
          const sum_price_come_in_after = this.calYcTableData_fumField('price_come_in', tableData_changeData)
          //含税总收入(原新)
          const sum_price_come_in_after_new = this.calYcTableData_fumField('price_come_in', newData_after)
          const sum_price_come_in_after_diff = sum_price_come_in_after_new - sum_price_come_in_after
          //收入百分比
          const price_come_in_after_per = checkZero(sum_price_come_in_after) ? 0 : sum_price_come_in_after_diff / sum_price_come_in_after
        
          this.tableData_risk[0]['risk_after'] = roundToDotNumber( checkZero(sum_price_come_in_after) ?0 : Math.abs( manager_rate_after_per / price_come_in_after_per ) ) +' %'

          // console.log('>>>>>----------- 加成后 -----------<<<<')
          // console.log('总收入',sum_price_come_in_after)
          // console.log('加成总收入',sum_price_come_in_after_new)
          // console.log('加成差异',sum_price_come_in_after_diff)
          // console.log('加成比例',price_come_in_after_per)
          // console.log('管理利润',manager_rate_after)
          // console.log('加成管理利润',manager_rate_after_new)
          // console.log('管理利润差异',manager_rate_after_diff)
          // console.log('管理利润比例',manager_rate_after_per)
          // console.log('杠杆',manager_rate_after_per / price_come_in_after_per)



          // this.tableData_risk[0]['risk_before'] = numberToFixed(checkZero(sum_price_come_in_before) ? 0 : (manager_rate_origin / sum_price_come_in_before) * 100) + '%'

          // const sum_price_come_in_after = this.calYcTableData_fumField('price_come_in', newData_before)
          // this.tableData_risk[0]['risk_after'] = numberToFixed(checkZero(sum_price_come_in_after) ? 0 : (manager_rate_origin_change / sum_price_come_in_after) * 100) + '%'

        }
      )
    },
    calYcTableData_fumField(field, values) {
      let money = 0

      if (values && values.length > 0) {
        values.map(function (item) {
          money += (item[field] ?? 0) * 1
        })
      }

      return numberToFixed(money)
    },
    //固定成本-函数
    tableData_step_fixed_field(field, values) {
      let money = 0
      if (values && values.length > 0) {
        ;(values || []).map(function (item) {
          if (item._type === field) {
            money = item.cost
          }
        })
      }
      return numberToFixed(money)
    },

    //混合成本-函数
    tableData_step_mix_field(field, sold_num, values) {
      let money = 0
      if (values && values.length > 0) {
        values.map((item) => {
          if (item._type === field) {
            let index = -1
            for (let i = 0; i < this.columns_step_mix.length; i++) {
              const info = this.columns_step_mix[i]
              if ('range_min' in info) {
                index++
                if (info['range_min'] <= sold_num && sold_num < info['range_max']) {
                  money = item['range_' + index]
                  break
                }
              }
            }
          }
        })
      }
      return money
    },
    //处理数据
    handleData(newData, fixData, mixData, handleParams) {
      //销量
      const sum_sold_num = this.calYcTableData_fumField('sold_num', newData)

      //含税总收入
      const sum_price_come_in = this.calYcTableData_fumField('price_come_in', newData)
      //原边际贡献
      const sum_side_money = this.calYcTableData_fumField('side_money', newData)

      //含税均单价
      const avg_price_no_tax_unit_money = sum_sold_num * 1 == 0 ? 0 : sum_price_come_in / sum_sold_num
      //制造环节固定成本
      let sum_fixed_zhizao_money = this.tableData_step_fixed_field('zhizao_sum', fixData)
      //制造环节固定成本
      let sum_fixed_yunying_money = this.tableData_step_fixed_field('yunying_sum', fixData)
      if (handleParams && 'params_fixed_per' in handleParams) {
        sum_fixed_zhizao_money = sum_fixed_zhizao_money * (1 + handleParams['params_fixed_per'] / 100)
        sum_fixed_yunying_money = sum_fixed_yunying_money * (1 + handleParams['params_fixed_per'] / 100)
      }
      //制造环节混合成本
      let sum_mix_zhizao_money = this.tableData_step_mix_field('zhizao_sum', sum_sold_num * 1, mixData)
      //运营环节混合成本
      let sum_mix_yunying_money = this.tableData_step_mix_field('yunying_sum', sum_sold_num * 1, mixData)
      if (handleParams && 'params_mix_per' in handleParams) {
        sum_mix_zhizao_money = sum_mix_zhizao_money * (1 + handleParams['params_mix_per'] / 100)
        sum_mix_yunying_money = sum_mix_yunying_money * (1 + handleParams['params_mix_per'] / 100)
      }
      //毛利（原导入）= 原边际贡献 -  制造环节固定成本 - 制造环节对应销量下的混合成本
      const maoli = numberToFixed(sum_side_money - sum_fixed_zhizao_money - sum_mix_zhizao_money)
      //管理利润
      const manager_rate = numberToFixed(maoli - sum_fixed_yunying_money - sum_mix_yunying_money)

      return manager_rate
    }
  }
}
</script>
