<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'works',
})
</script>
<template>
    <div class="works-list scroll">
        <div class="vabTabsBox">
            <div class="nav-tabs">
                <div class="nav-tabs_item">
                    <router-link :to="{ path: '/works/level' }">
                        <span>我的等级</span>
                    </router-link>
                </div>
                <div class="nav-tabs_item active">
                    <router-link :to="{ path: '/works/list/asks' }">
                        <span>内容创作</span>
                    </router-link>
                </div>
                <!-- <div class="nav-tabs_item">
                    <router-link :to="{ path: '/works/income' }">
                        <span>收益变现</span>
                    </router-link>
                </div> -->
            </div>
        </div>
        <div class="body">
            <div class="children">

                <route-view></route-view>
            </div>
        </div>

    </div>
</template>

<script lang="ts" setup name="works">
import { RouteView } from '@/layouts'
import { ref, reactive, computed, onMounted, nextTick } from 'vue'
import baseService from '@/utils/http/axios'

</script>

<style lang="less"  >
.children {
    .ant-menu-light.ant-menu-horizontal {
        border-bottom: 0;

        >.ant-menu-item {
            padding-inline: 0;
            margin-right: 34px;

            .ant-menu-title-content {
                font-size: 15px;
            }
        }
    }
}
</style>
