import { checkZero, numberToFixed } from "@/utils/util"

export default {
	state: {
        step2YC_data:[],
        step2Stock_data:[],
    },
    getters:{
        
        getter_step2YC_data:(state,getters)=>{
            //构造数据



            return ()=>{

            }
        }
    },
    mutations: {
		updateTools2PState(state,{field,value}){
			state[field] = value
		}
	},
	actions: {

	}
}