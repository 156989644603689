export default {
    methods: {
        //膨胀数据
        handleCalData(value) {
            const plan_base = this.$store.state.tools.plan_base || ''
            const ycPrice = JSON.parse(JSON.stringify(this.$store.state.tools.ycPrice || {}))
            const ycPriceKeys = Object.keys(ycPrice)
            const ycPriceKeysLen = ycPriceKeys.length
            if (!ycPriceKeysLen) {
                return value;
            }
            //查找数据
            const checkFields = ['no', 'line', 'type', 'customer', 'area', 'group']
            const checkCondItemFnc = function (item) {
                let selectItem = {}
                let selectRange = []
                for (let i = 0; i < ycPriceKeysLen; i++) {
                    const ycInfo = ycPrice[ycPriceKeys[i]]
                    const ycItem = ycInfo.item
                    const ycRange = ycInfo.range

                    //查找是否满足条件
                    const state = checkFields.every((field) => {
                        const ycItemFieldValue = ycItem[field] || ''
                        return !ycItemFieldValue || ycItemFieldValue == item[field]
                    })
                    if (state) {
                        selectItem = ycItem
                        selectRange = ycRange
                        break;
                    }

                }
                return { selectItem, selectRange }
            }

            //默认处理流程
            const handleItemFnc = (item) => {
                const ogItemPrice = item.price || 0
                let itemPrice = ogItemPrice
                let itemSoldNum = parseFloat(item.sold_num || 0)
                const { selectItem, selectRange } = checkCondItemFnc(item);

                selectRange.map((priceItem) => {
                    const num_start = parseFloat(priceItem.num_start)
                    const num_end = parseFloat(priceItem.num_end)
                    const price = parseFloat(priceItem.price)  //折扣
                    if (itemSoldNum >= num_start) {
                        itemPrice = ogItemPrice * price
                    }
                })
                return itemPrice;
            }


            //保本促销
            const handleItemFnc_full = (item) => {
                const itemKeys = Object.keys(item);
                const ogItemPrice = item.price || 0
                let itemPrice = ogItemPrice
                let itemSoldNum = parseFloat(item.sold_num || 0)
                const { selectItem, selectRange } = checkCondItemFnc(item);
                // console.log(item.no, selectRange,itemSoldNum)
                const list = [];
                // 每次添加需要清除加成前数据影响，
                // 保留第一次添加的内容 后面新增全部清0
                let addIndex = 0;
                const addListItem = function (resultItem) {
                    if (addIndex > 0) {
                        for (let i = 0; i < itemKeys.length; i++) {
                            const field = itemKeys[i]
                            if (field.indexOf('__') > -1) {
                                resultItem[field] = "";
                            }
                        }
                    }
                    list.push(resultItem)
                    addIndex++;
                }

                if (!selectRange.length) {
                    addListItem(item)
                    // list.push(item)
                } else {
                    // console.log('--------handleItemFnc_full-----------', item.no, itemSoldNum, selectRange)

                    for (let i = 0; i < selectRange.length; i++) {
                        const itemCopy = JSON.parse(JSON.stringify(item))
                        const itemCopy_bc = JSON.parse(JSON.stringify(item))

                        const priceItem = selectRange[i] || {}
                        const num_start = parseFloat(priceItem.num_start)
                        const price = parseFloat(priceItem.price)  //折扣
                        const use_num = priceItem.use_num || 0
                        let num_end = parseFloat(priceItem.num_end)
                        // console.log(1, 'itemSoldNum', itemSoldNum, num_start, num_end, !num_end)
                        if (!num_end) { //表示无穷大
                            if(i===0){
                                const diffSoldNum = itemSoldNum - num_start;
                                itemCopy.price = ogItemPrice * 1
                                itemCopy.sold_num = num_start
                                addListItem(itemCopy)
                                if(diffSoldNum>0){
                                    itemCopy_bc.price = ogItemPrice * price
                                    itemCopy_bc.sold_num = diffSoldNum
                                    addListItem(itemCopy_bc)
                                }
                            }else{
                                itemCopy.price = ogItemPrice * price
                                itemCopy.sold_num = itemSoldNum
                                addListItem(itemCopy)
                                    // list.push(itemCopy)
                            }
                            itemSoldNum = 0
                           
                            
                        } else {
                            //剩余要填充的数量
                            num_end = num_end - use_num;
                            if (num_end <= 0) { continue; }
                            const diffSoldNum = itemSoldNum - num_end;
                            // console.log(2, 'diffSoldNum', diffSoldNum)
                            if (diffSoldNum > 0) {
                                itemCopy.sold_num = num_end //对应销售数量
                                itemCopy.price = ogItemPrice * price
                                // console.log(3, 'diffSoldNum', ogItemPrice, price, num_end)
                                priceItem.use_num += num_end
                                addListItem(itemCopy)
                                // list.push(itemCopy)
                                itemSoldNum = diffSoldNum//剩余销售数量
                            } else {
                                priceItem.use_num += itemSoldNum
                                itemCopy.sold_num = itemSoldNum//对应销售数量
                                itemCopy.price = ogItemPrice * price
                                // console.log(4, 'diffSoldNum', ogItemPrice, price, num_end)
                                addListItem(itemCopy)
                                // list.push(itemCopy)
                                itemSoldNum = 0
                                break;
                            }
                        }
                    }
                }

                return list;
            }


            let data = [];
            value.map((item) => {
                if (plan_base === 'full') {
                    //保本促销
                    const list = handleItemFnc_full(item)
                    // if (item.no == '16010017') {
                    //     console.log('<< 16010017 >>')
                    //     console.log(list)
                    //     console.log(JSON.stringify(ycPrice))
                    // }
                    data = data.concat(list)
                } else {
                    //默认处理流程
                    item.price = handleItemFnc(item)
                    data.push(item)
                }

            })
            return data

        },
    }
}