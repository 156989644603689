<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Breadcrumb',
})
</script>
<template>
    <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item v-for="(item, index) in state.breadList" :key="item.name">
            <router-link v-if="item.name !== state.name" :to="{ path: item.path === '' ? '/' : item.path }">
                {{ item.meta.title }}
            </router-link>
            <span v-else>{{ item.meta.title }}</span>
        </a-breadcrumb-item>
    </a-breadcrumb>
</template>

<script lang="ts" setup name="Breadcrumb">
import { reactive, watch, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const state = reactive<any>({ name: '', breadList: [] })

const getBreadcrumb = () => {
    const route = router.currentRoute.value
    state.breadList = []
    state.name = route.name
    // 去重, 只有一级菜单，拿取最后一层面包屑
    const result = route.matched.reduce((pre, cur) => {
        var exists = pre.find(item => JSON.stringify(item.name) === JSON.stringify(cur.name));
        if(!exists){
           pre.push(cur);
        }
        return pre;
    }, []);

    result.forEach((item) => {      
        state.breadList.push(item)
    })
    
}

watch(
    () => router.currentRoute.value,
    (newVal) => {
        getBreadcrumb()
    }
)

onMounted(() => {
    getBreadcrumb()
})
</script>
