<template>
  <div class="card-table cloud7_table" :class="isMobile ? 'cloud7_table_mobile' : 'cloud7_table_pc'">
    <div class="cloud7_table_head">
      <div class="select-group">
        投资周期:
        <a-select v-model:value="cycleTValue" style="width: 100px" :options="cycleTselectList" @change="handleCalData({})" />
      </div>
      <div class="unit-group">单位：万元</div>
    </div>
    <div class="card-table-box">
      <a-table
        :class="cycleTValue < 5 ? 'cross-table' : ''"
        ref="tableRef"
        :pagination="false"
        bordered
        :columns="columns"
        :data-source="tableData"
        size="small"
        :row-class-name="rowClassName"
        @resizeColumn="(w, col) => (col.width = w)"
      >
        <template #bodyCell="{ column, text, record, index, ...others }">
          <template v-if="['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7', 't8', 't9', 't10'].indexOf(column.dataIndex) > -1">
            <template
              v-if="
                (record.inputType == 'number' || (record.extraForm && record.extraForm.inputType === 'selectper')) && (!('children' in record) || ('children' in record && !record.children.length))
              "
            >
              <template v-if="record.extraForm && record.extraForm.inputType === 'selectper' && record.extraForm.value === 'per'">
                {{ $dealThousands(text) }}
              </template>
              <a-input v-else v-model:value.number="record[column.dataIndex]" style="width: 80px; text-align: right" @change="handleCalData(record)" />
            </template>
            <template v-else-if="column.dataIndex != 't0' && 'data_intro' in record">
              {{ record.data_intro }}
            </template>
            <template v-else> {{ $dealThousands(text) }} {{ record.unit || '' }} </template>
          </template>
          <template v-else-if="column.dataIndex === 'project' && !record.autoLine">
            <a-space>
              <PlusCircleOutlined @click="handleAddItem(record)" v-if="'children' in record && record.addEl" />

              <div class="cell">{{ text }}</div>
              <template v-if="record.extraForm">
                <template v-if="record.extraForm.inputType == 'number'">
                  <a-input
                    v-model:value.number="record.extraForm.value"
                    :min="record.extraForm.min"
                    :max="record.extraForm.max"
                    style="width: 60px; text-align: right"
                    @change="handleCalData(record)"
                  />
                  {{ record.extraForm.unit }}
                </template>
                <template v-else-if="record.extraForm.inputType == 'selectper'">
                  <div style="display: flex; align-items: center">
                    <a-select
                      v-model:value="record.extraForm.value"
                      style="margin-right: 5px"
                      :style="bodyWidth < 1441 ? 'width: 80px' : body_width > 1441 && body_width < 1919 ? 'width: 100px' : 'width: 100px'"
                      :options="record.extraForm.selectList"
                      @change="handleCalData(record)"
                    />
                    <template v-if="record.extraForm.value === 'per'">
                      <a-input v-model:value="record.per" style="width: 50px; text-align: right" @change="handleCalData(record)" />
                      %
                    </template>
                  </div>
                </template>
                <template v-else-if="record.extraForm.inputType == 'per'">
                  <span v-if="record.extraForm.valueType === 'jxzlc'">({{ record.per }})</span>
                  <a-input v-else v-model:value="record.per" style="width: 50px; text-align: right" @change="handleCalData(record)" :disabled="record.extraForm.disabled === true" />
                  %
                </template>
              </template>
            </a-space>
          </template>
          <template v-else-if="column.dataIndex === 'project' && record.autoLine">
            <div>
              <a-space>
                <MinusCircleOutlined :style="{ color: '#ff0000' }" @click="handleRemoveItem(record, others)" />
                <a-input type="text" v-model:value="record.project" />
              </a-space>
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'sum'">
            {{ text === '' ? '--' : $dealThousands(text) }}
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ExclamationCircleOutlined, CaretDownOutlined, CaretUpOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons-vue'
import { numberToFixed, checkZero, tableDefaultPage, tableDefautlLine } from '@/utils/util'

import SliderInputBlock from '@/components/SliderInputBlock.vue'
import project from '@/store/modules/project'
import useSiteSettings from '@/store/useSiteSettings'
const { themeColor, themebgColor } = useSiteSettings()

export default {
  name: 'SolutionTable',
  components: {
    ExclamationCircleOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    PlusCircleOutlined,
    SliderInputBlock,
    MinusCircleOutlined
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    initDataState: {
      //数据初始化状态
      type: String,
      default: ''
    },
    planId: {
      type: [Number, String],
      default: 0
    },
    isPrint: {
      type: Boolean,
      default: false
    }
  },
  mixins: [],
  data() {
    return {
      bodyWidth: document.body.offsetWidth,
      rowThemeColor: '',
      cycleTValue: '5',
      cycleTselectList: [
        { label: '1年', value: '1' },
        { label: '2年', value: '2' },
        { label: '3年', value: '3' },
        { label: '4年', value: '4' },
        { label: '5年', value: '5' },
        { label: '6年', value: '6' },
        { label: '7年', value: '7' },
        { label: '8年', value: '8' },
        { label: '9年', value: '9' },
        { label: '10年', value: '10' }
      ],
      columns: [],

      tableData: [
        {
          key: 1,
          index: '1',
          project: '现金流入',
          t0: 0,
          t1: 0,
          t2: 0,
          t3: 0,
          t4: 0,
          t5: 0,
          t6: 0,
          t7: 0,
          t8: 0,
          t9: 0,
          t10: 0,
          sum: 0,
          addEl: 1,
          inputType: 'number',
          children: [
            { key: 1.1, index: '1.1', project: '销售收入', t0: 0, t1: 0, t2: 0, t3: 0, t4: 0, t5: 0, t6: 0, t7: 0, t8: 0, t9: 0, t10: 0, sum: 0, inputType: 'number' },
            { key: 1.2, index: '1.2', project: '回收固定（长期）资产余值', t0: 0, t1: 0, t2: 0, t3: 0, t4: 0, t5: 0, t6: 0, t7: 0, t8: 0, t9: 0, t10: 0, sum: 0, inputType: 'number' },
            { key: 1.3, index: '1.3', project: '回收流动资金', t0: 0, t1: 0, t2: 0, t3: 0, t4: 0, t5: 0, t6: 0, t7: 0, t8: 0, t9: 0, t10: 0, sum: 0, inputType: 'number' }
          ]
        },
        {
          key: 2,
          index: '2',
          project: '现金流出',
          extraForm: {
            valueType: 'xjlr',
            linkEl: [
              [1, 0],
              [1, 1],
              [1, 2],
              [1, 3]
            ]
          },
          t0: 0,
          t1: 0,
          t2: 0,
          t3: 0,
          t4: 0,
          t5: 0,
          t6: 0,
          t7: 0,
          t8: 0,
          t9: 0,
          t10: 0,
          sum: 0,
          inputType: 'number',
          children: [
            {
              key: 2.1,
              index: '2.1',
              project: '固定（长期）资产投资',
              t0: 0,
              t1: 0,
              t2: 0,
              t3: 0,
              t4: 0,
              t5: 0,
              t6: 0,
              t7: 0,
              t8: 0,
              t9: 0,
              t10: 0,
              sum: 0,
              addEl: 1,
              inputType: 'number',
              children: [
                { index: '', project: '固定资产', t0: 0, t1: 0, t2: 0, t3: 0, t4: 0, t5: 0, t6: 0, t7: 0, t8: 0, t9: 0, t10: 0, sum: 0, inputType: 'number' },
                { index: '', project: '研发支出', t0: 0, t1: 0, t2: 0, t3: 0, t4: 0, t5: 0, t6: 0, t7: 0, t8: 0, t9: 0, t10: 0, sum: 0, inputType: 'number' }
              ]
            },
            { key: 2.2, index: '2.2', project: '流动资金', t0: 0, t1: 0, t2: 0, t3: 0, t4: 0, t5: 0, t6: 0, t7: 0, t8: 0, t9: 0, t10: 0, sum: 0, addEl: 1, inputType: 'number', children: [] },
            { key: 2.3, index: '2.3', project: '营业成本（付现）', t0: 0, t1: 0, t2: 0, t3: 0, t4: 0, t5: 0, t6: 0, t7: 0, t8: 0, t9: 0, t10: 0, sum: 0, addEl: 1, inputType: 'number', children: [] },
            {
              key: 2.4,
              index: '2.4',
              project: '期间费用（付现）',
              t0: 0,
              t1: 0,
              t2: 0,
              t3: 0,
              t4: 0,
              t5: 0,
              t6: 0,
              t7: 0,
              t8: 0,
              t9: 0,
              t10: 0,
              sum: 0,
              addEl: 1,
              inputType: 'number',
              children: [
                {
                  index: '',
                  project: '销售费用',
                  extraForm: {
                    valueType: 'per',
                    value: 'per',
                    linkEl: [0, 0],
                    inputType: 'selectper',
                    selectList: [
                      { label: '固定金额', value: 'text' },
                      { label: '百分比', value: 'per' }
                    ]
                  },
                  per: 0,
                  t0: 0,
                  t1: 0,
                  t2: 0,
                  t3: 0,
                  t4: 0,
                  t5: 0,
                  t6: 0,
                  t7: 0,
                  t8: 0,
                  t9: 0,
                  t10: 0,
                  sum: 0,
                  inputType: 'number'
                },
                {
                  index: '',
                  project: '管理费用',
                  extraForm: {
                    valueType: 'per',
                    value: 'per',
                    linkEl: [0, 0],
                    inputType: 'selectper',
                    selectList: [
                      { label: '固定金额', value: 'text' },
                      { label: '百分比', value: 'per' }
                    ]
                  },
                  per: 0,
                  t0: 0,
                  t1: 0,
                  t2: 0,
                  t3: 0,
                  t4: 0,
                  t5: 0,
                  t6: 0,
                  t7: 0,
                  t8: 0,
                  t9: 0,
                  t10: 0,
                  sum: 0,
                  inputType: 'number'
                },
                {
                  index: '',
                  project: '财务费用',
                  extraForm: {
                    valueType: 'per',
                    value: 'per',
                    linkEl: [0, 0],
                    inputType: 'selectper',
                    selectList: [
                      { label: '固定金额', value: 'text' },
                      { label: '百分比', value: 'per' }
                    ]
                  },
                  per: 0,
                  t0: 0,
                  t1: 0,
                  t2: 0,
                  t3: 0,
                  t4: 0,
                  t5: 0,
                  t6: 0,
                  t7: 0,
                  t8: 0,
                  t9: 0,
                  t10: 0,
                  sum: 0,
                  inputType: 'number'
                }
              ]
            },
            {
              index: '2.5',
              project: '销售税金(销售收入*%)',
              extraForm: {
                valueType: 'per',
                value: 'per',
                linkEl: [0, 0],
                inputType: 'selectper',
                selectList: [
                  { label: '固定金额', value: 'text' },
                  { label: '百分比', value: 'per' }
                ]
              },
              per: 0,
              t0: 0,
              t1: 0,
              t2: 0,
              t3: 0,
              t4: 0,
              t5: 0,
              t6: 0,
              t7: 0,
              t8: 0,
              t9: 0,
              t10: 0,
              sum: 0
            },

            {
              index: '2.6',
              project: '所得税(税率*%)',
              extraForm: {
                valueType: 'tax',
                value: 'tax',
                linkEl: [[0, 0], [1, 2], [8], [1, 3]],
                inputType: 'per',
                selectList: [
                  { label: '固定金额', value: 'text' },
                  { label: '百分比', value: 'per' }
                ]
              },
              per: 0,
              t0: 0,
              t1: 0,
              t2: 0,
              t3: 0,
              t4: 0,
              t5: 0,
              t6: 0,
              t7: 0,
              t8: 0,
              t9: 0,
              t10: 0,
              sum: 0
            }
          ]
        },
        { key: 3, index: '3', project: '净现金流量', extraForm: { valueType: 'jxjll', linkEl: [[0], [1]] }, t0: 0, t1: 0, t2: 0, t3: 0, t4: 0, t5: 0, t6: 0, t7: 0, t8: 0, t9: 0, t10: 0, sum: 0 },
        { key: 4, index: '4', project: '累计净现金流量', extraForm: { valueType: 'ljjxjll', linkEl: [2] }, t0: 0, t1: 0, t2: 0, t3: 0, t4: 0, t5: 0, t6: 0, t7: 0, t8: 0, t9: 0, t10: 0, sum: 0 },
        {}, //删除此行注意索引
        {
          key: 5,
          index: '5',
          project: '折现净现金流量（lc=%）',
          extraForm: { inputType: 'per', valueType: 'zxjxjll', linkEl: [2] },
          per: 0,
          t0: 1,
          t1: 0,
          t2: 0,
          t3: 0,
          t4: 0,
          t5: 0,
          t6: 0,
          t7: 0,
          t8: 0,
          t9: 0,
          t10: 0,
          sum: 0
        },
        { key: 6, index: '6', project: '折现累计净现金流量', extraForm: { valueType: 'zxljjxjll', linkEl: [5] }, t0: 0, t1: 0, t2: 0, t3: 0, t4: 0, t5: 0, t6: 0, t7: 0, t8: 0, t9: 0, t10: 0, sum: 0 },
        {}, //删除此行注意索引
        {
          key: 7,
          index: '7',
          project: '折旧及摊销',
          extraForm: {
            valueType: 'zjjtx',
            value: '5',
            linkEl: [1, 0],
            inputType: 'number',
            unit: '年',
            min: 0,
            max: 100
          },
          t0: 0,
          t1: 0,
          t2: 0,
          t3: 0,
          t4: 0,
          t5: 0,
          t6: 0,
          t7: 0,
          t8: 0,
          t9: 0,
          t10: 0,
          sum: 0
        },
        {
          key: 8,
          index: '8',
          project: '净利润',
          extraForm: {
            valueType: 'jlr',
            linkEl: [[0, 0], [1, 2], [1, 3], [1, 4], [1, 5], [8]]
          },
          t0: 0,
          t1: 0,
          t2: 0,
          t3: 0,
          t4: 0,
          t5: 0,
          t6: 0,
          t7: 0,
          t8: 0,
          t9: 0,
          t10: 0,
          sum: 0
        },
        { key: 9, index: '', project: '净现值(lc=%)', extraForm: { inputType: 'per', valueType: 'jxzlc', linkEl: [], disabled: true }, per: 0, t0: 0, colSpan: 5, data_intro: '' },
        { key: 10, index: '', project: '内部收益率', t0: 0, colSpan: 5, data_intro: '', unit: '%' },
        { key: 11, index: '', project: '投资回收期（静态）', t0: 0, colSpan: 5, data_intro: '', unit: '年' },
        { key: 12, index: '', project: '投资回收期（动态）', t0: 0, colSpan: 5, data_intro: '', unit: '年' }
      ],

      calTimer: 0,
      calTimerIRR: 0,
      scrollXWidth: null,
      alpha: 0.5
    }
  },
  computed: {
    rgbaBackground() {
      const hex = this.rowThemeColor.replace('#', '')
      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)
      return `rgba(${r}, ${g}, ${b}, 0.1)` // 这里的alpha值可以根据需要调整
    },

    rgbaBackground2() {
      const hex = this.rowThemeColor.replace('#', '')
      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)
      return `rgba(${r}, ${g}, ${b}, 0.04)` // 这里的alpha值可以根据需要调整
    }
  },
  created() {
    console.log('=============this.rowThemeColor', this.rowThemeColor)
  },
  mounted() {},
  watch: {
    cycleTValue: {
      immediate: true, //首次加载的时候执行函数
      handler: function (value) {
        const len = parseInt(value)
        let defaultColumn = [
          { title: '序号', dataIndex: 'index', width: 100, customCell: this.tableCustomCell, resizable: true },
          { title: '项目', dataIndex: 'project', width: 340, customCell: this.tableCustomCell, resizable: true },
          { title: 'T0', dataIndex: 't0', width: 120, align: 'right', customCell: this.tableCustomCell, resizable: true },
          // { title: 'T1', dataIndex: 't1', width: 120, customCell: this.tableCustomCell },
          // { title: 'T2', dataIndex: 't2', width: 120, customCell: this.tableCustomCell },
          // { title: 'T3', dataIndex: 't3', width: 120, customCell: this.tableCustomCell },
          // { title: 'T4', dataIndex: 't4', width: 120, customCell: this.tableCustomCell },
          // { title: 'T5', dataIndex: 't5', width: 120, customCell: this.tableCustomCell },
          // { title: 'T6', dataIndex: 't6', width: 120, customCell: this.tableCustomCell },
          // { title: 'T7', dataIndex: 't7', width: 120, customCell: this.tableCustomCell },
          // { title: 'T8', dataIndex: 't8', width: 120, customCell: this.tableCustomCell },
          // { title: 'T9', dataIndex: 't9', width: 120, customCell: this.tableCustomCell },
          // { title: 'T10', dataIndex: 't10', width: 120, customCell: this.tableCustomCell },
          { title: '合计', dataIndex: 'sum', width: 200, align: 'right', customCell: this.tableCustomCell, resizable: true }
        ]

        const fncItem = (i) => {
          return { title: 'T' + i, dataIndex: 't' + i, width: 120, align: 'right', customCell: this.tableCustomCell, resizable: true }
        }

        for (let i = 1; i <= len; i++) {
          defaultColumn.splice(i + 2, 0, fncItem(i))
        }
        this.columns = defaultColumn
      }
    }
  },
  methods: {
    //给表格特定行设置背景色
    rowClassName(record, index) {
      this.rowThemeColor = themeColor.value || '#1e6dff'
      // console.log('===============record', record.key)
      if (record.key && record.key < 9) {
        if (Number.isInteger(record.key)) {
          return 'table-row-background'
        } else {
          return 'table-row-background2'
        }
      }
      return ''
    },
    getCompareData(field) {
      let value = 0
      switch (field) {
        case 'tzzxjlr': //投资总现金流入
          value = this.tableData[0].sum
          break
        case 'tzzgm': //投资总规模
          value = this.tableData[1].sum
          break
        case 'qqtz': //前期投资
          value = this.tableData[1].t0
          break
        case 'jxjll': //净现金流量
          value = this.tableData[2].sum
          break
        case 'ljjxjll': //累计净现金流量
          value = this.tableData[3].sum_plus
          break
        case 'zxjxjll': //折现净现金流量
          value = this.tableData[5].sum
          break
        case 'zxljjxjll': //折现累计净现金流量
          value = this.tableData[6].sum_plus
          break
        case 'jlr': //净利润
          value = this.tableData[9].sum
          break
        case 'nbsyl': //内部收益率
          value = this.tableData[11].t0
          break
        case 'tzhsq_dt': //投资回收期 (动态)
          value = this.tableData[13].t0
          break
      }
      return value
    },
    setTableData(cycle, data) {
      this.cycleTValue = cycle || 5

      if (data && data.length > 0) {
        this.tableData = data
        // console.log('=========================', this.tableData)
        this.$nextTick(() => {
          this.handleCalData({})
        })
      }
    },
    handleAddItem(item) {
      item.children.push({
        project: '',
        inputType: 'number', //允许输入
        autoLine: 1,
        t0: 0,
        t1: 0,
        t2: 0,
        t3: 0,
        t4: 0,
        t5: 0,
        t6: 0,
        t7: 0,
        t8: 0,
        t9: 0,
        t10: 0,
        sum: 0
      })
    },
    getDataRow(flagTableIndex = [], type) {
      let calItem = this.tableData[flagTableIndex[0]] || {}
      for (let i = 1; i < flagTableIndex.length; i++) {
        const index = flagTableIndex[i]
        calItem = calItem.children[index] || {}
      }

      return calItem
    },

    handleQueryIndex(item) {
      let level0 = -1
      let level1 = -1
      let level2 = -1

      for (let i = 0; i < this.tableData.length; i++) {
        const tableItem = this.tableData[i]
        const children = tableItem.children || []
        for (let j = 0; j < children.length; j++) {
          const childrenItem = children[j]
          const childrenCh = childrenItem.children || []
          if (childrenItem == item) {
            level0 = i
            level1 = j
            break
          }
          for (let ij = 0; ij < childrenCh.length; ij++) {
            if (childrenCh[ij] == item) {
              level0 = i
              level1 = j
              level2 = ij
              break
            }
          }
        }
        if (level0 > -1 || level1 > -1 || level2 > -1) {
          break
        }
      }
      return { level0, level1, level2 }
    },
    handleRemoveItem(item, others) {
      let { level0, level1, level2 } = this.handleQueryIndex(item)
      //移除
      if (level0 > -1 && level1 > -1 && level2 > -1) {
        this.tableData[level0].children[level1].children.splice(level2, 1)
      } else if (level0 > -1 && level1 > -1) {
        this.tableData[level0].children.splice(level1, 1)
      }
    },
    mapElFnc(item, useData) {
      // console.log('=============================item', item)
      // console.log('=============================useData', useData)
      const calColumn = useData.calColumn || {} //{t0:0,t1:0,t2:0,t3:0,t4:0};
      const calColumnKeys = Object.keys(calColumn)
      // let AllSum = useData.sum||0
      let sum = 0
      const tCount = {}

      calColumnKeys.map((elKey, elIndex) => {
        let value = (item[elKey] || 0) * 1

        if ('extraForm' in item) {
          if (item.extraForm.valueType === 'per') {
            if (item.extraForm.value === 'per') {
              const linkElItem = this.getDataRow(item.extraForm.linkEl)
              value = linkElItem[elKey] * (item.per / 100)
              item[elKey] = value
            }
          } else if (item.extraForm.valueType === 'zjjtx') {
            //折旧及摊销
            const linkElItem = this.getDataRow(item.extraForm.linkEl)
            value = 0
            for (let i = 0; i < elIndex; i++) {
              const elKey_i = calColumnKeys[i]
              if (!(elKey_i in tCount)) {
                tCount[elKey_i] = 0
              }
              tCount[elKey_i]++ //计算周期
              if (tCount[elKey_i] <= item.extraForm.value) {
                value += !(item.extraForm.value * 1) ? 0 : linkElItem[elKey_i] / item.extraForm.value
              }
            }
            item[elKey] = value
          } else if (item.extraForm.valueType === 'tax') {
            //所得税
            //销售收入
            const linkElItem0 = this.getDataRow(item.extraForm.linkEl[0])
            //固定（长期）资产投资
            const linkElItem1 = this.getDataRow(item.extraForm.linkEl[1])
            // 折旧及摊销
            const linkElItem2 = this.getDataRow(item.extraForm.linkEl[2])
            //期间费用（付现）
            const linkElItem3 = this.getDataRow(item.extraForm.linkEl[3])
            value = (linkElItem0[elKey] * 1 - linkElItem1[elKey] * 1 - linkElItem2[elKey] * 1 - linkElItem3[elKey] * 1) * (item.per / 100)
            item[elKey] = value
          } else if (item.extraForm.valueType === 'jxjll') {
            //净现金流量
            //销售收入
            const linkElItem0 = this.getDataRow(item.extraForm.linkEl[0])
            //现金流出
            const linkElItem1 = this.getDataRow(item.extraForm.linkEl[1])
            value = linkElItem0[elKey] * 1 - linkElItem1[elKey] * 1
            item[elKey] = value
          } else if (item.extraForm.valueType === 'xjlr') {
            //现金流出
            //固定（长期）资产投资
            const linkElItem0 = this.getDataRow(item.extraForm.linkEl[0])
            //流动资金
            const linkElItem1 = this.getDataRow(item.extraForm.linkEl[1])
            //营业成本（付现）
            const linkElItem2 = this.getDataRow(item.extraForm.linkEl[2])
            //期间费用（付现）
            const linkElItem3 = this.getDataRow(item.extraForm.linkEl[3])
            value = linkElItem0[elKey] * 1 + linkElItem1[elKey] * 1 + linkElItem2[elKey] * 1 + linkElItem3[elKey] * 1
            item[elKey] = value
          } else if (item.extraForm.valueType === 'ljjxjll') {
            //累计现金
            //净现金流量
            const linkElItem = this.getDataRow(item.extraForm.linkEl)
            value = 0
            for (let i = 0; i <= elIndex; i++) {
              const elKey_i = calColumnKeys[i]
              value += linkElItem[elKey_i] * 1
            }
            item[elKey] = value
          } else if (item.extraForm.valueType === 'zxjxjll') {
            //折现净现金流量
            //净现金流量
            const linkElItem = this.getDataRow(item.extraForm.linkEl)
            value = linkElItem[elKey] / Math.pow(1 + item.per / 100, elIndex)
            item[elKey] = value
          } else if (item.extraForm.valueType === 'zxljjxjll') {
            //折现累计净现金流量
            //净现金流量
            const linkElItem = 'fixedData' in item.extraForm ? item.extraForm.fixedData : this.getDataRow(item.extraForm.linkEl)
            value = 0
            for (let i = 0; i <= elIndex; i++) {
              const elKey_i = calColumnKeys[i]
              value += linkElItem[elKey_i] * 1
            }
            item[elKey] = value
          } else if (item.extraForm.valueType === 'jlr') {
            //净利润
            //销售收入
            const linkElItem0 = this.getDataRow(item.extraForm.linkEl[0])
            //营业成本（付现）
            const linkElItem1 = this.getDataRow(item.extraForm.linkEl[1])
            //期间费用（付现）
            const linkElItem2 = this.getDataRow(item.extraForm.linkEl[2])
            //销售税金
            const linkElItem3 = this.getDataRow(item.extraForm.linkEl[3])
            //所得税
            const linkElItem4 = this.getDataRow(item.extraForm.linkEl[4])
            //折旧及摊销
            const linkElItem5 = this.getDataRow(item.extraForm.linkEl[5])
            value = linkElItem0[elKey] * 1 - linkElItem1[elKey] * 1 - linkElItem2[elKey] * 1 - linkElItem3[elKey] * 1 - linkElItem4[elKey] * 1 - linkElItem5[elKey] * 1
            item[elKey] = value
          }
        }
        calColumn[elKey] += value
        sum += value
      })
      item.sum = sum
      item.sum_plus = sum
      useData.sum += sum
      return item
    },
    getCalUseData() {
      const column = { t0: 0 }
      for (let i = 1; i <= this.cycleTValue; i++) {
        column['t' + i] = 0
      }
      return {
        calColumn: column, //{t0:0,t1:0,t2:0,t3:0, t4:0, t5:0, t6: 0, t7: 0, t8: 0, t9: 0, t10: 0 },
        sum: 0
      }
    },
    handleCalItem(el, ignoreChildren = false, options = {}) {
      const calSumState = options.calSum
      let useData = this.getCalUseData()

      if (ignoreChildren || !('children' in el) || !el.children.length) {
        this.mapElFnc(el, useData)
      } else {
        el.children.map((item) => this.mapElFnc(item, useData))
      }
      // console.log(useData)

      Object.assign(el, useData.calColumn, { sum_plus: useData.sum, sum: calSumState === false ? '' : useData.sum })
      // Object.assign(el,calColumn,{sum:AllSum})
    },
    handleCalData(itemRow) {
      //计算数据
      if (itemRow && itemRow.extraForm) {
        if (itemRow.extraForm.valueType === 'jxzlc') {
          return this.calNPV()
        }
      }
      clearTimeout(this.calTimer)
      this.calTimer = setTimeout(() => {
        //计算第一组
        this.handleCalItem(this.tableData[0])
        //计算第二组
        const second = this.tableData[1].children
        this.handleCalItem(second[0])
        this.handleCalItem(second[1])
        this.handleCalItem(second[2])
        this.handleCalItem(second[3])
        this.handleCalItem(second[4])
        //现金流出
        console.log(this.tableData[1])
        this.handleCalItem(this.tableData[1], true)
        //折旧及摊销
        this.handleCalItem(this.tableData[8])
        //所得税率
        this.handleCalItem(second[5])
        //净现金流量
        this.handleCalItem(this.tableData[2])
        //累计净现金流量
        this.handleCalItem(this.tableData[3], false, { calSum: false })
        //折现净现金流量
        this.handleCalItem(this.tableData[5])
        //净现值(lc=%) = 折现净现金流量（lc=%）百分比同步
        this.tableData[10].per = this.tableData[5].per
        //折现累计净现金流量
        this.handleCalItem(this.tableData[6], false, { calSum: false })
        //净利润
        this.handleCalItem(this.tableData[9])

        //净现值(lc=%)
        this.calNPV()
        this.calIRR()

        let useData = this.getCalUseData()
        const calColumn = useData.calColumn || {} //{t0:0,t1:0,t2:0,t3:0,t4:0};
        const calColumnKeys = Object.keys(calColumn)
        let selectLen = calColumnKeys.length - 1

        const calYearPer = (index, state, data) => {
          const key1 = calColumnKeys[index] || ''
          const otherIndex = state ? index + 1 : index - 1
          const key2 = calColumnKeys[otherIndex] || ''
          if (!key1 || !key2) {
            return 0
          }
          let datakey1 = data[key1] * 1
          let datakey2 = data[key2] * 1
          let div = datakey2 - datakey1

          if (datakey1 < 0 && datakey2 > 0) {
            div = datakey1 - datakey2
            datakey2 = datakey1
          }

          console.log('datakey1=', datakey1)
          console.log('datakey2=', datakey2)
          console.log('div=', div)

          return !div ? 0 : datakey2 / div
        }

        const useYear = (data) => {
          let index = calColumnKeys.length - 1
          let state = false
          for (let i = 0; i < calColumnKeys.length; i++) {
            const calColumnName = calColumnKeys[i]
            if (data[calColumnName] >= 0) {
              index = i - 1
              state = true
              break
            }
          }
          return { index, state }
        }

        //投资回收期（静态）
        const { index: statics_yearLen, state: statics_state } = useYear(this.tableData[3])
        // console.log('--statics_yearLen', statics_yearLen, statics_state)
        if (statics_yearLen > -1) {
          const statics_year = calYearPer(statics_yearLen, statics_state, this.tableData[3])
          this.tableData[12].t0 = statics_yearLen + statics_year
          this.tableData[12].data_intro = ''
        } else {
          this.tableData[12].t0 = 0
          this.tableData[12].data_intro = '未在投资周期内盈利'
        }

        //投资回收期（动态）
        const { index: dynamic_yearLen, state: dynamic_state } = useYear(this.tableData[6])
        // console.log('--dynamic_yearLen', dynamic_yearLen, dynamic_state)
        if (dynamic_yearLen > -1) {
          const dynamic_year = calYearPer(dynamic_yearLen, dynamic_state, this.tableData[6])
          this.tableData[13].t0 = dynamic_yearLen + dynamic_year
          this.tableData[13].data_intro = ''
        } else {
          this.tableData[13].t0 = 0
          this.tableData[13].data_intro = '未在投资周期内盈利'
        }

        // console.log(this.tableData)
      }, 200)
      // const el0 = this.tableData[0]
      // const el1 = this.tableData[1]
    },
    calIRR() {
      //内部收益率
      clearTimeout(this.calTimerIRR)
      this.calTimerIRR = setTimeout(() => {
        let upTo = 0
        let flag = false
        let flagPer = 0
        let flagValue = 0
        let flag_zxjxjllData = {}
        let flag_zxljjxjllData = {}
        let upInfo = {
          flagPer: 0,
          flagValue: 0,
          flag_zxjxjllData: {},
          flag_zxljjxjllData: {}
        }
        const startI = -100
        const endI = 100
        const step = 0.01
        for (let i = startI; i <= endI; i += step) {
          const { zxjxjllData, zxljjxjllData, value } = this.calNPV(i, 2)
          if (i === startI) {
            upTo = value
            continue
          }
          // console.log(i,value,upTo)

          if (value != Infinity && value <= 0 && upTo >= 0) {
            flag = true
            flagPer = upInfo.flagPer
            flagValue = upInfo.flagValue
            flag_zxjxjllData = upInfo.flag_zxjxjllData
            flag_zxljjxjllData = upInfo.flag_zxljjxjllData
            break
          }

          upInfo.flagPer = i
          upInfo.flagValue = value
          upInfo.flag_zxjxjllData = zxjxjllData
          upInfo.flag_zxljjxjllData = zxljjxjllData
          upTo = value
        }

        if (!flag) {
          this.tableData[11].t0 = 0
          return
        }

        //找到了值
        this.tableData[11].t0 = flagPer

        // console.log('flag=', flag)
        // console.log('value=', flagValue)
        // console.log('upTo=', upTo)
        // console.log('flagPer=', flagPer)
        // console.log('flag_zxljjxjllData=', flag_zxljjxjllData)
      }, 500)
    },
    calNPV(calPer = false, checkList = false) {
      //净现值(lc=%)
      let useData = this.getCalUseData()
      const calColumn = useData.calColumn || {} //{t0:0,t1:0,t2:0,t3:0,t4:0};
      const calColumnKeys = Object.keys(calColumn)
      let per = calPer || 0
      let linkElItem = null
      let lastTname = null
      let checkIndex = calColumnKeys.length - 1

      lastTname = calColumnKeys[checkIndex]
      let checkColumn = lastTname

      if (calPer === false) {
        linkElItem = this.tableData[10]
        per = (linkElItem.per || 0) * 1
      }

      //折现净现金流量
      const zxjxjllData = JSON.parse(JSON.stringify(this.tableData[5]))
      zxjxjllData.per = per
      this.handleCalItem(zxjxjllData, useData)

      useData = this.getCalUseData()
      //折现累计净现金流量
      const zxljjxjllData = JSON.parse(JSON.stringify(this.tableData[6]))
      zxljjxjllData.extraForm.fixedData = zxjxjllData
      this.handleCalItem(zxljjxjllData, useData)
      let value = zxljjxjllData[lastTname] || 0
      if (linkElItem && lastTname) {
        linkElItem.t0 = value
      }

      return {
        checkIndex: checkIndex,
        checkColumn: checkColumn,
        value: value,
        zxjxjllData,
        zxljjxjllData
      }
    },
    tableCustomCell(item, index, column) {
      const itemLen = Object.keys(item).length
      let colSpan = 1
      if (!itemLen) {
        if (column.dataIndex === 'index') {
          colSpan = this.columns.length
        } else {
          colSpan = 0
        }
      } else {
        if ('colSpan' in item) {
          if (column.dataIndex === 't1') {
            colSpan = item.colSpan
          } else if (['index', 'project', 't0'].indexOf(column.dataIndex) > -1) {
            colSpan = 1
          } else {
            colSpan = 0
          }
        }
      }
      return {
        colSpan: colSpan
        // colSpan : colSpanLen
      }
    }
  }
}
</script>

<style lang="less">
.cloud7_table {
  .cloud7_table_head {
    margin-bottom: 12px;
  }
  &.cloud7_table_pc {
    .cloud7_table_head {
      display: flex;
      align-items: center;
      .unit-group {
        margin-left: 30px;
        &::before {
          display: none;
        }
      }
    }
  }
  &.cloud7_table_mobile {
    .cloud7_table_head {
      display: block;
      .select-group {
        font-size: 14px;
      }
      .unit-group {
        width: 100%;
        color: #1e6dff;
        font-size: 12px;
      }
    }
    .card-table-box {
      .ant-table-wrapper {
        .ant-table-cell {
          &.ant-table-cell-with-append {
            display: flex;
            align-items: center;
          }
          .ant-table-row-indent {
            display: none;
          }
          .ant-table-row-expand-icon-spaced {
            display: none;
          }
          .ant-table-row-indent + .ant-table-row-expand-icon {
            margin-top: 0px;
          }
        }
        .ant-table-thead > tr > th {
          padding: 4px 6px;
        }

        .ant-table.ant-table-small tfoot > tr > td,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
          padding: 4px 6px;
        }
      }
      .ant-input-number .ant-input-number-input {
        height: 20px;
      }
    }
  }
}

.table-row-background {
  background-color: v-bind(rgbaBackground); /* 使用Vue的绑定语法 */
  font-weight: bold;
}

.table-row-background2 {
  background-color: v-bind(rgbaBackground2); /* 使用Vue的绑定语法 */
}
</style>