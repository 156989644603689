<template>
  <div class="solution scroll" id="solution-tools">
    <!-- 设置主题色 -->
    <setting-drawer ref="settingDrawer" />

    <!-- 保存方案模板 基础版本-->
    <div v-show="isPrint && planType == 'base'">
      <SolutionTemplatePDF
        ref="solutionTemplatePDF"
        :hasActualityData="hasActualityData"
        :detail="detail"
        :params_tax_per="params_tax_per"
        :params_sold_per="params_sold_per"
        :params_cost_per="params_cost_per"
        :params_pass_per="params_pass_per"
        :remark_base="remark_base"
        :planResult_base="planResult_base"
      />
    </div>
    <!-- 保存方案模板 扩展版本 -->
    <div v-show="isPrint && planType == 'extra'">
      <SolutionTemplatePDFExtra
        ref="solutionTemplatePDFExtra"
        :hasActualityData="hasActualityData"
        :detail="detail"
        :params_tax_per="params_tax_per"
        :params_sold_per="params_sold_per"
        :params_cost_per="params_cost_per"
        :params_pass_per="params_pass_per"
        :remark_extra="remark_extra"
        :planResult_extra="planResult_extra"
      />
    </div>
    <div class="solution-foot" id="PDF-foot">
      <div class="solution-foot-icon"><img src="/logo.png" class="logo" alt="logo" /></div>
      <div class="solution-foot-text">
        {{ getWaterName }}
      </div>
    </div>

    <div class="bodys">
      <a-watermark :content="getWaterName">
        <div class="solution-box">
          <div class="solution-head">
            <div class="headBox-c">
              <setting-drawer ref="settingDrawer" />
              <div class="headBoxBg"></div>
              <div class="headBoxContent">
                <div class="headTitle">
                  <a-row :gutter="0">
                    <a-col :sm="24" :lg="18" class="headTitle-left">
                      <img src="/public/logo_text.png" />
                      <div class="title">{{ detail.plan_name }}</div>
                      <div class="author_name">
                        {{ detail.name }} <text>{{ detail.detail_name }}</text>
                      </div>
                    </a-col>

                    <a-col :sm="24" :lg="6">
                      <div>
                        <img src="@/assets/images/solution_img.png" />
                        <div class="headBoxLogo"><img :src="detail.plan_logo" class="logo" alt="logo" /></div>
                      </div>
                    </a-col>
                  </a-row>
                  <div class="line"></div>
                </div>
              </div>
              <div class="a-setting-btn">
                <span @click="$refs.settingDrawer.open()">主题色</span>
              </div>
            </div>
            <div class="import-btns">
              <div style="border: 1px solid #ccc; display: inline-block">
                <a-button size="small" :type="planType == 'base' ? 'primary' : ''" @click="planType = 'base'" v-if="authTools.indexOf('base')>-1"> 基础方案 </a-button>
                <a-button size="small" :type="planType == 'extra' ? 'primary' : ''" @click="planType = 'extra'" v-if="authTools.indexOf('extra')>-1"> 扩展方案 </a-button>
              </div>

              <div class="author">
                <div class="author_info">
                  <span>编制人：{{ userInfo.name }}</span>
                  <span>编制时间：{{ detail.created_at }}</span>
                  <span>打印次数：{{ detail.times }}次</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 基础方案 -->
          <div class="solution-wrap print-pdf-base" v-show="planType === 'base'">
            <!-- 折线图 -->
            <div class="solution-children">
              <!-- <div class="head">5.0 CVP折线图</div>
              <tools-item-intro type="two" field="intro4" /> -->
              <div class="row">
                <a-row :gutter="10" class="flex-row-local">
                  <a-col :sm="24" :lg="15" class="flex-col-local">
                    <!-- <div class="subtitle">整体参数联动</div> -->
                    <box-card>
                      <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
                        <SvpLine ref="tools2SvpPlan" showZeroPoint :planType="planType" echartId="tools2-svp-line-plan" :XPoints="echartXPoints" :soldNum="sum_sold_num" moduleName="plan" />

                        <SvpLine
                          ref="tools2SvpActuality"
                          showZeroPoint
                          :planType="planType"
                          echartId="tools2-svp-line-actuality"
                          :XPoints="echartXPoints"
                          :soldNum="sum_sold_num"
                          moduleName="actuality"
                          @xAxisPointChange="onXAxisPointChange_actuality"
                        />
                      </div>
                    </box-card>
                  </a-col>
                  <a-col :sm="24" :lg="9" class="flex-col-local">
                    <!-- <div class="subtitle">整体参数联动说明</div>
                    <div class="row">
                      <tools-item-intro type="two" field="intro_param1" moduleName="RichText" />
                    </div> -->
                    <box-card>
                      <div class="">
                        <a-descriptions :column="1" size="small" bordered :labelStyle="getTransferWidth">
                          <a-descriptions-item label="含税售价整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_tax_per" @change="onChangeParams" />
                            </div>
                          </a-descriptions-item>
                          <a-descriptions-item label="销量整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_sold_per" @change="onChangeParams" />
                            </div>
                          </a-descriptions-item>
                          <a-descriptions-item label="单位变动成本整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_cost_per" @change="onChangeParams" />
                            </div>
                          </a-descriptions-item>
                          <a-descriptions-item label="产品合格率整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_pass_per" :max="100" :step="0.5" @change="onChangeParams" />
                            </div>
                          </a-descriptions-item>
                        </a-descriptions>
                      </div>
                    </box-card>

                    <box-card style="flex: 1">
                      <!-- 齐套率明细表（内部运营指标） -->
                      <SolutionExtraPlan_FXZB ref="planFXZB" :hasActualityData="hasActualityData" />
                    </box-card>
                  </a-col>
                </a-row>
              </div>
            </div>
            <!-- 1.0 整体参数 -->
            <div class="solution-children">
              <a-row :gutter="8">
                <a-col :sm="24" :lg="7">
                  <box-card>
                    <guide-item-intro type="two" field="intro1" moduleName="本节导引" />
                  </box-card>
                </a-col>
                <a-col :sm="24" :lg="17">
                  <box-card>
                    <guide-item-intro type="two" field="intro_base" moduleName="假设背景" />
                  </box-card>
                </a-col>
              </a-row>
            </div>

            <div class="solution-children">
              <box-card>
                <div class="head">基本表</div>
                <tools-item-intro type="two" field="intro2" />
                <SolutionTable ref="solutionTable" :isPrint="isPrint" :hasActualityData="hasActualityData" />
              </box-card>
            </div>

            <div class="solution-children">
              <box-card>
                <div class="head">扩展表</div>
                <tools-item-intro type="two" field="intro3" />
                <SolutionExtraPlan_KZFA ref="planKZFA" :isPrint="isPrint" :hasActualityData="hasActualityData" />
              </box-card>
            </div>

            <!-- 分析指标 -->
            <!-- <div class="solution-children">
              <div class="head">4.0 分析指标</div>

              <tools-item-intro type="two" field="intro5"/>

              <div class="row">
                // 齐套率明细表（内部运营指标）
                <SolutionExtraPlan_FXZB ref="planFXZB"  :hasActualityData="hasActualityData"/>
              </div>
            </div> -->

            <!-- 方案解读1 -->
            <div class="solution-children">
              <box-card>
                <!-- <div class="head">方案解读</div> -->
                <tools-item-intro type="two" field="intro6" />
                <div class="row">
                  <div class="RichText"></div>
                </div>
              </box-card>
            </div>

            <!-- 编制备注 -->
            <div class="solution-children">
              <box-card>
                <div class="thead">编制备注</div>

                <!-- <tools-item-intro type="two" field="intro7" /> -->

                <div class="message" v-if="remark_base_mobile">
                  <div class="head">回复意见</div>
                  <div class="content">
                    <div class="item">
                      <div class="item-main">
                        <div class="item-main-desc">
                          {{ remark_base_mobile }}
                        </div>
                        <div class="item-main-action">回复时间：{{ remark_base_time }}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <a-textarea :maxlength="1000" v-model:value="remark_base" placeholder="备注" :auto-size="{ minRows: 8, maxRows: 16 }" />
                </div>
              </box-card>
            </div>
          </div>

          <!-- 扩展方案 -->
          <div class="solution-wrap print-pdf-extra" v-show="planType === 'extra'">
            <!-- 散点图 -->
            <div class="solution-children">
              <!-- <div class="head">2.0 边际收益散点图</div>
              <tools-item-intro type="two" field="intro9" /> -->
              <div class="row">
                <a-row :gutter="10">
                  <a-col :sm="24" :lg="15">
                    <box-card>
                      <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
                        <!-- 计划 -->
                        <SvpScatter ref="svpScatterPlan" subTips="计划" :planType="planType" echartId="tools2-svp-scatter-plan" moduleName="plan" />
                        <!-- 实际 -->
                        <SvpScatter
                          ref="svpScatterActuality"
                          v-show="hasActualityData"
                          :hasActualityData="hasActualityData"
                          subTips="实际"
                          :planType="planType"
                          echartId="tools2-svp-scatter-actuality"
                          moduleName="actuality"
                        />
                      </div>
                    </box-card>
                  </a-col>
                  <a-col :sm="24" :lg="9" class="flex-col-local">
                    <box-card>
                      <div class="">
                        <a-descriptions :column="1" size="small" bordered :labelStyle="getTransferWidth">
                          <a-descriptions-item label="含税售价整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_tax_per" @change="onChangeParams" />
                            </div>
                          </a-descriptions-item>
                          <a-descriptions-item label="销量整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_sold_per" @change="onChangeParams" />
                            </div>
                          </a-descriptions-item>
                          <a-descriptions-item label="单位变动成本整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_cost_per" @change="onChangeParams" />
                            </div>
                          </a-descriptions-item>
                          <a-descriptions-item label="产品合格率整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_pass_per" :max="100" :step="0.5" @change="onChangeParams" />
                            </div>
                          </a-descriptions-item>
                        </a-descriptions>
                      </div>
                    </box-card>

                    <box-card style="flex: 1">
                      <div class="solution-children">
                        <div class="head">成本中心多级利润表</div>

                        <!-- <tools-item-intro type="two" field="intro13" /> -->
                        <div class="row plan-center-box">
                          <!-- 5.管理利润多级报表 -->
                          <!-- 计划 -->
                          <div class="table-children">
                            <SolutionExtraPlan_MLLR ref="planMLLR_plan" moduleName="plan" />
                          </div>
                          <!-- 实际 -->
                          <div class="table-children" v-show="hasActualityData">
                            <SolutionExtraPlan_MLLR ref="planMLLR_actuality" moduleName="actuality" />
                          </div>
                        </div>
                      </div>
                    </box-card>

                    <!-- <box-card>
                      <SolutionExtraPlan_MLLR ref="planMLLR_plan" moduleName="plan" />
                      <SolutionExtraPlan_MLLR ref="planMLLR_actuality"  moduleName="actuality" v-show="hasActualityData"/>
                    </box-card> -->
                  </a-col>
                </a-row>
              </div>
            </div>
            <div class="solution-children">
              <a-row :gutter="8">
                <a-col :sm="24" :lg="7">
                  <box-card>
                    <guide-item-intro type="two" field="intro8" moduleName="本节导引" />
                  </box-card>
                </a-col>
                <a-col :sm="24" :lg="17">
                  <box-card>
                    <guide-item-intro type="two" field="intro_extra" moduleName="假设背景" />
                  </box-card>
                </a-col>
              </a-row>
            </div>

            <div class="solution-children">
              <box-card>
                <div class="thead">收发存报表（客户产品级）</div>
                <tools-item-intro type="two" field="intro10" />
                <div class="row">
                  <SolutionExtraPlan_SFCBB ref="planSFCBB" :hasActualityData="hasActualityData" />
                </div>
              </box-card>
            </div>

            <div class="solution-children">
              <box-card>
                <div class="thead">齐套率明细表（内部运营指标）</div>

                <tools-item-intro type="two" field="intro11" />
                <div class="row">
                  <!-- 齐套率明细表（内部运营指标） -->
                  <SolutionExtraPlan_QTLMX ref="planQTLMX" :hasActualityData="hasActualityData" />
                </div>
              </box-card>
            </div>

            <div class="solution-children">
              <box-card>
                <div class="thead">投入产出表（成本）</div>

                <tools-item-intro type="two" field="intro12" />
                <div class="row">
                  <!-- 投入产出表 -->
                  <SolutionExtraPlan_TRCC ref="planTRCC" :hasActualityData="hasActualityData" />
                </div>
              </box-card>
            </div>

            <!-- <div class="solution-children">
              <box-card>
              <div class="head">成本中心多级利润表</div>
              <tools-item-intro type="two" field="intro13" />
              <div class="row">
                <a-row :gutter="100">
                  <a-col :span="12">
                    <div class="table-children">
                      <SolutionExtraPlan_MLLR ref="planMLLR_plan" moduleName="plan" />
                    </div>

                  </a-col>
                  <a-col :span="12" v-show="hasActualityData">
                    <div class="table-children">
                      <SolutionExtraPlan_MLLR ref="planMLLR_actuality" moduleName="actuality" />
                    </div>
                  </a-col>
                </a-row>
              </div>
              </box-card>
            </div> -->

            <!-- 方案解读1 -->
            <div class="print-pdf" data-index="6">
              <div class="solution-children">
                <box-card>
                  <!-- <div class="thead">方案解读</div> -->

                  <tools-item-intro type="two" field="intro14" />

                  <div class="row">
                    <div class="RichText"></div>
                  </div>
                </box-card>
              </div>
            </div>

            <!-- 编制备注 -->
            <div class="solution-children">
              <box-card>
                <div class="thead">编制备注</div>

                <!-- <tools-item-intro type="one" field="intro15" /> -->

                <div class="message" v-if="remark_extra_mobile">
                  <div class="head">回复意见</div>
                  <div class="content">
                    <div class="item">
                      <div class="item-main">
                        <div class="item-main-desc">
                          {{ remark_extra_mobile }}
                        </div>
                        <div class="item-main-action">回复时间：{{ remark_extra_time }}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <a-textarea :maxlength="1000" v-model:value="remark_extra" placeholder="备注" :auto-size="{ minRows: 8, maxRows: 16 }" />
                </div>
              </box-card>
            </div>
          </div>

          <div class="button-group">
            <!-- <a-button type="primary" ghost size="large" @click="handleBack()"> 返回 </a-button> -->
            <OperateLimit type="primary" ghost size="large" :showErr="false" @doClick="handleBack()" @errClick="$router.back()" useTool="cvp2"> 返回 </OperateLimit>
            <!-- <a-button type="primary" size="large" @click="handleSave()"> 保存 </a-button> -->
            <OperateLimit type="primary" size="large" @doClick="handleSave()" useTool="cvp2"> 保存 </OperateLimit>

            <!-- <a-button :loading="downLoading" type="primary" size="large" @click="downPDF"> 下载方案 </a-button> -->
            <OperateLimit type="primary" size="large" @doClick="downPDF" useTool="cvp2"> 下载方案 </OperateLimit>
            <template v-if="auth_share_state==1">
              <!-- <a-button type="primary" size="large" @click="handleShare()" v-if="!getIsSave"> 分享 </a-button> -->
              <OperateLimit type="primary" size="large" v-if="!getIsSave" @doClick="handleShare()" useTool="cvp2"> 分享 </OperateLimit>
              <ShareLink ref="shareLink" v-else :href="$baseURL + '/#/mobile2?id=' + listQuery.id" />
            </template>
          </div>
        </div>
      </a-watermark>
    </div>

    <page-loading ref="pageLoading" />
    <SavePlan ref="savePlan" />
  </div>
</template>

<script>
import SettingDrawer from '@/components/SettingDrawer/SettingDrawer.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import guideItemIntro from '@/components/guideItemIntro.vue'
import ShareLink from '@/components/ShareLink.vue'

// 打印模板
import SolutionTemplatePDF from './components/SolutionTemplatePDF.vue'
import SolutionTemplatePDFExtra from './components/SolutionTemplatePDFExtra.vue'
import PageLoading from '@/components/PageLoading.vue'

import SolutionBaseTable from './components/SolutionBaseTable.vue'
import SolutionTable from './components/SolutionTable.vue'
import SolutionStock from './components/SolutionStock.vue'
import SolutionSerialStand from './components/SolutionSerialStand.vue'
import SolutionCost from './components/SolutionCost.vue'
import SolutionNSerial from './components/SolutionNSerial.vue'
import SolutionSerialProduction from './components/SolutionSerialProduction.vue'
import SolutionSVPLineBefore from './components/SolutionSVPLineBefore.vue'
import SvpLine from './components/SvpLine.vue'
import SvpScatter from './components/SvpScatter.vue'
import SolutionsSVPScatterBefore from './components/SolutionsSVPScatterBefore.vue'
import SolutionExtraPlan_SFCBB from './components/SolutionExtraPlan_SFCBB.vue'
import SolutionExtraPlan_QTLMX from './components/SolutionExtraPlan_QTLMX.vue'
import SolutionExtraPlan_TRCC from './components/SolutionExtraPlan_TRCC.vue'
import SolutionExtraPlan_MLLR from './components/SolutionExtraPlan_MLLR.vue'
import SolutionExtraPlan_FXZB from './components/SolutionExtraPlan_FXZB.vue'
import SolutionExtraPlan_KZFA from './components/SolutionExtraPlan_KZFA.vue'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import { numberToFixed, arrayToObj } from '@/utils/util'
import { structData_stock_and_yc, structData_serialStand, structData_serialProduction, structData_NSerial } from '@/utils/UtilToos2P'
import { htmlToPDF } from '@/utils/html2pdf'
import { message } from 'ant-design-vue'
import { mapGetters } from 'vuex'
import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'
import SavePlan from '../components/SavePlan.vue'
import boxCard from '@/components/tools/boxCard.vue'
export default {
  components: {
    PageLoading,
    SolutionTable,
    SvpLine,
    SvpScatter,
    SettingDrawer,
    ToolsItemIntro,
    SavePlan,

    SolutionBaseTable,
    SolutionStock,
    SolutionSerialStand,
    SolutionCost,
    SolutionNSerial,
    SolutionSerialProduction,
    SliderInputBlock,
    SolutionSVPLineBefore,
    SolutionsSVPScatterBefore,
    SolutionExtraPlan_QTLMX,
    SolutionExtraPlan_TRCC,
    SolutionExtraPlan_MLLR,
    SolutionExtraPlan_SFCBB,
    SolutionExtraPlan_FXZB,
    SolutionExtraPlan_KZFA,

    SolutionTemplatePDF,
    SolutionTemplatePDFExtra,
    ShareLink,
    boxCard,
    guideItemIntro
  },
  data() {
    return {
      planType: 'base',
      isPrint: false,
      downLoading: false,
      pagination: false, // { pageSize: 10, hideOnSinglePage: true, };
      listQuery: {
        id: ''
      },
      //整体加成参数设置
      params_tax_per: 0,
      params_sold_per: 0,
      params_cost_per: 0,
      params_pass_per: 0,
      //方案详情
      detail: {},
      //原导入数据
      tableData_ycStock: [],
      tableData: [],
      colSpan: {},
      summaryObject: {},
      remark_base: '',
      remark_extra: '',
      remark_base_time: '',
      remark_base_mobile: '',
      remark_extra_mobile: '',
      remark_extra_time: '',
      //总销量
      sum_sold_num: 0,
      //x轴所有点
      echartXPoints: [],

      //保存数据
      step2YC_data: [],
      step2Stock_data: [],
      step2SerialStand_data: [],
      step2Cost_data: [],
      step2NSerial_data: [],
      step2SerialProduction_data: [],

      onChangeParamsTimer: 0,
      // fncSettingOptions: (params) => {}
      hasActualityData: true,

      auth_share_state:0,
      authTools:[],
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    },
    getTransferWidth() {
      if (document.body.clientWidth < 1441) {
        return { width: '118px', minWidth: '118px', fontSize: '9px', textAlign: 'left', padding: 1 }
      } else if (document.body.clientWidth > 1441 && document.body.clientWidth < 1919) {
        return { width: '160px', minWidth: '160px', fontSize: '12px', textAlign: 'left', padding: 1 }
      } else {
        return { width: '200px', minWidth: '200px' }
      }
    }
  },
  created() {
    this.listQuery.id = this.$route.query.id || 0
    this.planType = this.$route.query.planType == 'extra' ? 'extra' : 'base'

    this.getPlanDetail()
    // this.createWatch_stock_and_yc()
  },
  mounted() {
    //项目初始化主題色
    let themeColor = (ls.get(SITE_SETTINGS) || {}).THEME_COLOR
    if (themeColor) {
      systemConfig.commit(THEME_COLOR, (ls.get(SITE_SETTINGS) || {}).THEME_COLOR)
    }
  },
  methods: {
    handleShare() {
      message.error(`保存后才能进行分享`)
      this.handleSave(() => {
        this.$refs.savePlan.close()
        this.$nextTick(() => {
          this.$refs.shareLink.open()
        })
      })
    },
    onXAxisPointChange_actuality(points, yMax) {
      // console.log(points,yMax)
      this.$refs.tools2SvpPlan.resetEchartInfo({ points, yMax })
    },
    onChangeParams(e) {
      if (this.detail.id) {
        clearTimeout(this.onChangeParamsTimer)
        this.onChangeParamsTimer = setTimeout(() => {
          this.fncSettingOptions({
            params_tax_per: this.params_tax_per || 0,
            params_sold_per: this.params_sold_per || 0,
            params_cost_per: this.params_cost_per || 0,
            params_pass_per: this.params_pass_per || 0
          })
        }, 300)
      }
    },
    //方案详情
    getPlanDetail() {
      this.$network('/api/tools/planDetail', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const pageParams = detail.page_params || {}

          this.detail = detail

          this.auth_share_state = data.auth_share_state||0
          this.authTools = data.authTools||[]
          
          this.$nextTick(() => {
            this.params_tax_per = pageParams.params_tax_per || 0
            this.params_sold_per = pageParams.params_sold_per || 0
            this.params_cost_per = pageParams.params_cost_per || 0
            this.params_pass_per = pageParams.params_pass_per || 0
            this.remark_base = detail.remark_base || ''
            this.remark_extra = detail.remark_extra || ''

            this.remark_base_mobile = detail.remark_base_mobile || ''
            this.remark_extra_mobile = detail.remark_extra_mobile || ''

            this.remark_base_time = detail.remark_base_time || ''
            this.remark_extra_time = detail.remark_extra_time || ''

            this.$nextTick(() => {
              this.getData()
            })
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },
    //处理数据
    getData() {
      Promise.all([
        //销售预测表
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-yc' }),
        //存货期初结存表
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-stock' }),
        //单位产品工序用量与标准成本表
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-serial-stand' }),
        //成本中心档案
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-cost' }),
        //非工序投入表
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-n-serial' }),
        //工序投入产出明细表
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-serial-production' })
      ]).then((res) => {
        const [
          {
            data: { list: step2YC_data }
          },
          {
            data: { list: step2Stock_data }
          },
          {
            data: { list: step2SerialStand_data }
          },
          {
            data: { list: step2Cost_data }
          },
          {
            data: { list: step2NSerial_data }
          },
          {
            data: { list: step2SerialProduction_data, otherData: step2SerialProduction_otherData }
          }
        ] = res
        this.step2YC_data = step2YC_data
        this.step2Stock_data = step2Stock_data
        this.step2SerialStand_data = step2SerialStand_data
        this.step2Cost_data = step2Cost_data
        this.step2NSerial_data = step2NSerial_data
        this.step2SerialProduction_data = step2SerialProduction_data
        this.hasActualityData = step2SerialProduction_otherData.has_actuality == 1
        // const step2YC_data_before = fnc_excelData_yc_line(step2YC_data,{})
        // console.log(step2YC_data_before)
        //构造数据
        // this.$store.commit('updateTools2PState',{field:'step2YC_data',value: step2YC_data })
        // this.$store.commit('updateTools2PState',{field:'step2Stock_data',value: step2Stock_data })
        // this.fncSettingOptions = (params = {}) => {

        // }

        this.onChangeParams({
          params_tax_per: this.params_tax_per || 0,
          params_sold_per: this.params_sold_per || 0,
          params_cost_per: this.params_cost_per || 0,
          params_pass_per: this.params_pass_per || 0
        })
        // this.fncSettingOptions({

        // })
      })
    },
    fncSettingOptions(params, returnData = false) {
      const step2YC_data = JSON.parse(JSON.stringify(this.step2YC_data))
      const step2Stock_data = JSON.parse(JSON.stringify(this.step2Stock_data))
      const step2SerialStand_data = JSON.parse(JSON.stringify(this.step2SerialStand_data))
      const step2Cost_data = JSON.parse(JSON.stringify(this.step2Cost_data))
      const step2NSerial_data = JSON.parse(JSON.stringify(this.step2NSerial_data))
      const step2SerialProduction_data = JSON.parse(JSON.stringify(this.step2SerialProduction_data))

      // const params_cost_per = params.params_cost_per || 0
      const fncResult_stockAndYc = structData_stock_and_yc(step2YC_data, step2Stock_data, params)()
      const fncResult_serialStand = structData_serialStand(step2SerialStand_data, params)()

      const fncResult_SerialProduction = structData_serialProduction(step2SerialProduction_data, fncResult_serialStand, fncResult_stockAndYc, params)()

      if (returnData) {
        return { fncResult_stockAndYc, fncResult_serialStand, fncResult_SerialProduction, step2Stock_data }
      }
      // console.log('11111111111111111111111111111111111111111111----setTimeout')

      const fncResult_NSerial = structData_NSerial(step2NSerial_data)()

      //画x轴

      //基本表数据
      const colSpan = fncResult_SerialProduction.colSpan || {}
      this.$refs.solutionTable.initTableData({
        tableData: fncResult_SerialProduction.newTableData,
        colSpan: fncResult_SerialProduction.colSpan,
        summaryObject: fncResult_SerialProduction.summaryObject
      })

      // setTimeout(() => {
      //预测线条

      const svpLines = ['tools2SvpPlan', 'tools2SvpActuality']
      svpLines.map((item) => {
        this.$refs[item].drawLines({
          soldNum: fncResult_stockAndYc.sum_sold_num,
          tableList: fncResult_stockAndYc.newYcStockData || [],
          avg_pass_per_product: fncResult_stockAndYc.avg_pass_per_product,
          avg_price_no_tax_unit_money: fncResult_stockAndYc.avg_price_no_tax_unit_money,
          fixedMoney: fncResult_NSerial.totalCost,
          fixedNoMoney: fncResult_NSerial.totalCostNo,
          colSpan: colSpan,
          fncColSpanPrice: fncResult_stockAndYc.fncColSpanPrice
        })
      })
      const svpScatter = ['svpScatterPlan', 'svpScatterActuality']
      svpScatter.map((item) => {
        this.$refs[item].drawLines({
          soldNum: fncResult_stockAndYc.sum_sold_num,
          tableList: fncResult_stockAndYc.newYcStockData || [],
          avg_price_no_tax_unit_money: fncResult_stockAndYc.avg_price_no_tax_unit_money,
          avg_pass_per_product: fncResult_stockAndYc.avg_pass_per_product,
          colSpan: colSpan,
          fncColSpanPrice: fncResult_stockAndYc.fncColSpanPrice,
          fncColSpanPriceTableList: fncResult_stockAndYc.fncColSpanPriceTableList
        })
      })
      // }, 1000)
      //扩展方案
      this.$refs.planKZFA.createTableData({
        step2NSerial_data
      })
      //分析指标
      this.$refs.planFXZB.createTableData(
        {
          sum_price_come_in: fncResult_stockAndYc.sum_price_come_in,
          soldNum: fncResult_stockAndYc.sum_sold_num,
          tableList: fncResult_stockAndYc.newYcStockData || [],
          avg_pass_per_product: fncResult_stockAndYc.avg_pass_per_product,
          avg_price_no_tax_unit_money: fncResult_stockAndYc.avg_price_no_tax_unit_money,
          ycStock_customerNo: fncResult_stockAndYc.newYcStockData_obj || {},
          fixedMoney: fncResult_NSerial.totalCost,
          fixedNoMoney: fncResult_NSerial.totalCostNo,
          colSpan: colSpan,
          summaryObject: fncResult_SerialProduction.summaryObject,
          fncColSpanPrice: fncResult_stockAndYc.fncColSpanPrice
        },
        this
      )

      //收发成本表
      this.$refs.planSFCBB.createTableData(fncResult_SerialProduction.newTableData || [], fncResult_serialStand.colSpan || {}, fncResult_SerialProduction.colSpan || {})

      //齐套率
      this.$refs.planQTLMX.createTableData({}, this)

      //投入产出表
      const grouTableObj = this.$refs.planTRCC.createTableData({}, this)

      //管理利润
      this.$refs.planMLLR_plan.createTableData(fncResult_stockAndYc, fncResult_serialStand, fncResult_SerialProduction, fncResult_NSerial, step2Stock_data)
      this.$refs.planMLLR_actuality.createTableData(fncResult_stockAndYc, fncResult_serialStand, fncResult_SerialProduction, fncResult_NSerial, step2Stock_data)
    },

    handleBack() {
      this.$confirm({
        content: '是否保存方案',
        okText: '是',
        cancelText: '否',
        onOk: () => {
          this.handleSave(() => {
            this.$router.back()
          })
        },
        onCancel: () => {
          this.$router.back()
        }
      })
    },
    handleSave(callback) {
      this.$refs.savePlan.open(
        this.listQuery.id,
        {
          name: this.detail.name || '',
          remark_base: this.remark_base,
          params: {
            params_tax_per: this.params_tax_per,
            params_sold_per: this.params_sold_per,
            params_cost_per: this.params_cost_per,
            params_pass_per: this.params_pass_per
          }
        },
        () => {
          this.detail.is_save = 1
          if (callback) {
            callback()
          } else {
            this.$router.replace({ path: '/history' })
          }
        }
      )
    },

    async downPDF() {
      this.downLoading = true
      this.isPrint = true
      this.$refs.pageLoading.open('正在进行方案下载')
      //增加打印次数
      this.handlePrint()

      setTimeout(() => {
        this.$nextTick(async () => {
          this.$refs.pageLoading.text('方案构件中...')

          let elId = 'solutionTemplatePDF'
          let pdfName = '基础方案'
          if (this.planType == 'extra') {
            pdfName = '扩展方案'
            elId = 'solutionTemplatePDFExtra'
            await this.$refs.solutionTemplatePDFExtra.downPDF(this)
          } else {
            await this.$refs.solutionTemplatePDF.downPDF(this)
          }

          this.$refs.pageLoading.text('正在生成PDF文件')
          await htmlToPDF(elId, pdfName, 'PDF-foot')
          this.$refs.pageLoading.text('下载成功')
          // this.$refs.pageLoading.close()
          // this.isPrint = false
          setTimeout(() => {
            location.reload()
          }, 800)
        })
      }, 200)

      // setTimeout(()=>{
      //   htmlToPDF(elId, pdfName,'PDF-foot')
      // },1000)

      return
    },
    handlePrint() {
      this.$network('/api/tools/planPrintTimes', { id: this.listQuery.id, tool_type: 'two' })
    },
    generatePdfFile() {
      const hide = message.loading('文件构件中...', 0)
      ;(async () => {
        await htmlToPDF('solution-tools2', '工具二')
        this.downLoading = false
        hide()
      })()
    }
  }
}
</script>
<style lang="less" scoped>
.solution {
  background: #f8f9fd;
  .text-bold {
    font-weight: bold;
  }

  .body {
    height: auto !important;
    background: #eff7ff;

    .children {
      padding: 0px 24px 16px;
      background-color: transparent !important;
    }
  }
}

.flex-row-local {
  display: flex;
}

.flex-col-local {
  display: flex; /* 使a-col成为flex容器 */
  flex-direction: column; /* a-col内的内容垂直排列 */
  // height: 100%; /* 使a-col填满其父元素的高度 */
  // justify-content: space-between;
}
</style>
