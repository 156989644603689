<template>
  <div class="row">
    <a-row :gutter="20">
      <a-col :sm="24" :lg="6">
        <div class="table-children">
          <div class="subtitle">固定成本：</div>
          <a-table  ref="tableList" :columns="columns" :data-source="tableData" :scroll="isPrint ? { } : { y: 222 }" 
          :pagination="pagination" 
          size="small" bordered
          :rowClassName="(_record, index) => (['zhizao_sum','yunying_sum'].indexOf(_record._type)>-1 ? 'gray-bg-def' : (_record._type=='total'?'gray-bg-def-deep':null))"
          >
          <template #bodyCell="{ column, record, text }">
            <template v-if="column.dataIndex=='amount'">
              {{ $dealThousands(text) }}
            </template>
          </template>
          </a-table>
        </div>
      </a-col>
      
    </a-row>
  </div>
</template>

<script>
import { checkZero, numberToFixed } from '@/utils/util'
import { mapGetters } from 'vuex'
export default {
  name: 'SolutionExtraPlan_KZFA',
  props: {
    isPrint: {
      type: Boolean,
      default: false
    },
    hasActualityData: {
      type: Boolean,
      default: false
    },
    planId: {
      type: [Number, String],
      default: 0
    },
  },
  data() {
    return {
      pagination: false,
      tableData:[],
      columns: [
        { title: '环节', dataIndex: 'cost_name', align: 'center',  width: 140,ellipsis: true,resizable: false  },
        { title: '金额', dataIndex: 'amount',  align: 'right', width: 140, ellipsis: true,resizable: false  },
      ],
      
    }
  },
  computed: {
   
  },
  mounted() {},
  methods: {
    createTableData(options) {
      const tableData = [];
      let sumObj = {};
      let sumMoney = 0;
      (options.step2NSerial_data||[]).map((item)=>{
        sumMoney += item.amount*1;
        if(!(item.cost_attr in sumObj)){
          sumObj[item.cost_attr] = {
            name: item.cost_attr,
            money: 0,
            list: [],
          };
        }
        sumObj[item.cost_attr].list.push(item);
        sumObj[item.cost_attr].money += item.amount*1;
        // tableData.push(item)
      })
      for(let key in sumObj){
        const info = sumObj[key]
        tableData.push(...info.list)
        tableData.push({
          '_type':'zhizao_sum',
          cost_name:info.name + '小计',
          amount: numberToFixed(info.money),
        })
      }
      tableData.push({
        cost_name:'合计',
        '_type':'total',
        amount: numberToFixed(sumMoney),
      })
      this.tableData = tableData
    }
  }
}
</script>
