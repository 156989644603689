<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'history',
})
</script>
<template>
    <div class="setting">
        <div class="header">
            <div class="title">账户设置</div>
            <div class="subtitle">绑定手机和邮箱，并设置密码，账户更安全。</div>
        </div>

        <div class="body">
            <div class="children">
                <div class="setting-item">
                    <div class="setting-item-icon">
                        <SvgIcon name="icon-mima" size="58" color="#dbecff" />

                    </div>
                    <div class="setting-item-meta">
                        <div class="setting-item-meta-title">
                            <div class="title">密码设置</div>
                            <span class="value">已设置</span>
                        </div>
                        <div class="setting-item-meta-description">
                            {{$store.state.project.password_setting_intro}}
                        </div>
                    </div>
                    <div class="setting-item-action">
                        <a-button type="primary" @click="handlPassword">修改密码</a-button>
                    </div>
                </div>
                <div class="setting-item">
                    <div class="setting-item-icon">
                        <SvgIcon name="icon-shoujihao" size="58" color="#dbecff" />

                    </div>
                    <div class="setting-item-meta">
                        <div class="setting-item-meta-title">
                            <div class="title">绑定手机</div>
                            <span class="value">{{ ($store.state.user || {}).hide_phone }}</span>
                        </div>
                        <div class="setting-item-meta-description">
                            {{$store.state.project.bind_phone_intro}}
                        </div>
                    </div>
                    <div class="setting-item-action">
                        <a-button type="primary" @click="handlPhone">修改手机</a-button>
                    </div>
                </div>
                <div class="setting-item">
                    <div class="setting-item-icon">
                        <SvgIcon name="icon-youxiang" size="58" color="#dbecff" />
                    </div>
                    <div class="setting-item-meta">
                        <div class="setting-item-meta-title">
                            <div class="title">绑定邮箱</div>
                            <span class="value">{{ ($store.state.user || {}).email }}</span>
                        </div>
                        <div class="setting-item-meta-description">
                            {{$store.state.project.bind_email_intro}}
                        </div>
                    </div>
                    <div class="setting-item-action">
                        <a-button type="primary" @click="handlEmail">{{ ($store.state.user || {}).email ? '修改邮箱' : '绑定邮箱'
                        }}</a-button>
                    </div>
                </div>
                <div class="setting-item">
                    <div class="setting-item-icon">
                        <SvgIcon name="icon-lingdang" size="58" color="#dbecff" />
                    </div>
                    <div class="setting-item-meta">
                        <div class="setting-item-meta-title">
                            <div class="title">每周tips</div>
                            <span class="value"></span>
                        </div>
                        <div class="setting-item-meta-description">
                            {{$store.state.project.bind_ad_tips_intro}}
                        </div>
                    </div>
                    <div class="setting-item-action">
                        <a-switch v-model:checked="ad_state_today" @change="onSwitch($event,'ad_state_today')"/>
                    </div>
                </div>
                <div class="setting-item">
                    <div class="setting-item-icon"><BellOutlined />
                        <SvgIcon name="icon-setting" size="58" color="#dbecff" />
                    </div>
                    <div class="setting-item-meta">
                        <div class="setting-item-meta-title">
                            <div class="title">vip广告</div>
                            <span class="value"></span>
                        </div>
                        <div class="setting-item-meta-description">
                            {{$store.state.project.bind_vip_intro}}
                        </div>
                    </div>
                    <div class="setting-item-action">
                        <a-switch v-model:checked="ad_state_vip" @change="onSwitch($event,'ad_state_vip')"/>
                    </div>
                </div>
            </div>
        </div>

        <ChangePassword ref="changePassword" />

        <ChangePhone ref="changePhone" />

        <ChangeEmail ref="changeEmail" />

    </div>
</template>

<script lang="ts" setup name="history">
import { computed, onMounted, reactive, ref } from 'vue';

import SvgIcon from '@/components/SvgIcon/index.vue'
import TipsContent from '@/components/TipsContent.vue'

import ChangePassword from './components/ChangePassword.vue'
import ChangePhone from './components/ChangePhone.vue'
import ChangeEmail from './components/ChangeEmail.vue'
import baseService from '@/utils/http/axios'

onMounted(()=>{
    getSetting()

})


const changePassword = ref(null)
const handlPassword = () => {
    console.log(changePassword.value)
    changePassword.value.open()
};

const changePhone = ref(null)
const handlPhone = () => {
    console.log(changePhone.value)
    changePhone.value.open()
};


const changeEmail = ref(null)
const handlEmail = () => {
    console.log(changeEmail.value)
    changeEmail.value.open()
};


const ad_state_today = ref(true)
const ad_state_vip = ref(true)
const getSetting = ()=>{
    baseService.post('/api/user/setting',{}).then(res => {
        const data = res.data||{}
        ad_state_today.value = data.ad_state_today==1
        ad_state_vip.value = data.ad_state_vip==1
    }).catch(err => {
        console.error(err)
    })
}

const onSwitch = (e,field)=>{
    console.log(e,field)
    let obj = {};
    obj[field] = e ? 1 : 0;
    baseService.post('/api/user/modifyInfo',obj).then(res => {
       
    }).catch(err => {
        console.error(err)
    })
}

</script>

<style lang="less" scoped>
.setting-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    padding: 24px 0px;

    .setting-item-icon {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .setting-item-meta {
        flex: 1;
        padding: 0px 50px;
        display: flex;

        .setting-item-meta-title {
            width: 280px;

            .title {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .value {
                color: #999999;
            }
        }

        .setting-item-meta-description {
            flex: 1;
            max-width: 750px;
            color: #666666;
            line-height: 1.75;
        }
    }
}
</style>
