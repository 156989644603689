<template>
      
  <div id="solutionTemplatePDFExtra" class="solutionTemplatePDF">
    <a-watermark :content="getWaterName">
      
      <div class="solution-box">
        <div class="headBox">
          <div class="headBoxBg">
            <img src="@/assets/images/solution_img.png" />
            <div class="headBoxLogo"><img :src="getPlanLogo" class="logo" alt="logo" /></div>
          </div>
          <div class="headBoxContent">
            <div class="headTitle">
              <div class="title">{{ getPlanName }}</div>
              <div class="subtitle">{{ getPlanName_en }}</div>
            </div>
            <div class="author">
              <div class="author_name">{{ getWaterName }} {{ getDetailName }}</div>
              <div class="author_info">
                <span>编制人：{{ userInfo.name }}</span>
                <span>编制时间：{{ detail.created_at }}</span>
                <span>打印次数：{{ detail.times }}次</span>
              </div>
            </div>
          </div>
          <div class="headTypeText">
            <VipTag :isText="true"  moduleName="printPDF"/>
            <span>扩展方案</span>
          </div>
        </div>

        <div class="solution-wrap">
          <div class="solution-first">
            <div class="solution-children">
              <div class="title"><span>工具指引</span></div>
              <div class="row">
                <div class="RichText">
                  <tools-item-intro type="three" field="intro_print_extra" moduleName="background"/>
                </div>
              </div>
            </div>
            <!-- 1 整体参数 -->
            <div class="solution-children">
              <div class="title"><span>信息</span></div>
              <div class="row">
                <div id="pdfExtraPageInfo"></div>
              </div>
            </div>
          </div>
            
        </div>
      </div>
    </a-watermark>
    
  </div>

</template>

<script>
import VipTag from '@/components/VipTag.vue'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'

import { mapGetters  } from 'vuex'

import { message } from 'ant-design-vue'
import { htmlToPDF } from '@/utils/html2pdf'
import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'
//打印模板
export default {
  name: 'solutionTemplatePDF',
  components: {
    VipTag,SliderInputBlock,ToolsItemIntro
  },
  props: {
    type:{
      type: String,
      default:"",
    },
    detail:{
      type: Object,
      default:function(){
        return {}
      }
    },
    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    },
    remark_extra: {
      type: String,
      default: ''
    },
    
    planResult_extra: {
      type: String,
      default: ''
    },
    planType: {
      type: String,
      default: ''
    },
  },
	data() {
    return { 
      svpCatterBeforeImg:'',
      svpCatterAfterImg:'',
    }
  },
  computed: {
    ...mapGetters([
      'getHasToolAuth'
    ]),
    getWaterName() {
      return this.detail.water_name || ''
    },
    getDetailName() {
      return this.detail.name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    }
  },
  created() {
   
  },
  mounted() {
    
   
  },

 
  methods: {
    
    async downPDF(pageObj) {
      await this.pageInfo(pageObj)
    },

    async pageInfo(pageObj){
      document.getElementById("pdfExtraPageInfo").appendChild(pageObj.$refs.solutionAnalysis.$refs.solutionAnalysisVal)
    },
    
  }
}
</script>

<style lang="less" >
#solutionTemplatePDFExtra {
  .solution-box {
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
      padding: 6px 12px;
      font-size: 18px;
    }
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
      padding: 6px 5px;
    }
    .ant-table .ant-table-container .ant-table-cell {
      font-size: 16px;
    }
    .solution-wrap {
      .solution-children {
        .row {
          > img.canvas {
            height: 650px;
            margin: auto;
          }
        }
      }
    } 
  }
  
}
</style>
