<template>
  <div class="table-children">
    <div style="width: 100%; height: 700px">
      <div id="SolutionSvpScatterAfter" style="height: 700px"></div>
    </div>
    <div class="subtitle">
      相关维度数据明细表请勾选
    </div>
    <div class="checkbox-group">
      <a-checkbox-group v-model:value="checkedList" :options="checkedOptions" />
    </div>

    <div>
      <a-cascader
          multiple
          :fieldNames="{ label: 'title', value: 'dataIndex', children: 'children' }"
          placeholder="Please select"
          v-model:value="columns_filter"
          :options="columns_select"
          @change="onTableFieldChange($event,'columns')"
        >
          <a href="#">选择列</a>
      </a-cascader>

      <template v-for="(item,key) in tableDataList">
          <div  class="table-children-box" v-show="checkedList.indexOf(item.key)>-1">
            <a-button @click="showMoreTableList(item)">show more</a-button>
            <a-table class="cross-table" :columns="columns"
              :data-source="item.data"
              :scroll="{ x: 400 }"
              :pagination="topSumObj[item.key].pagination"
              :rowClassName="(_record, index) => (['sum_line'].indexOf(_record.lineType)>-1 ? 'gray-bg-def' : null)"
              size="small" bordered  @resizeColumn="(w, col)=>{col.width = w}"
              @change="(pagination, filters, sorter,e)=>onTableChange(item,pagination, filters, sorter,e)"
              >
                <template #bodyCell="{ column, text, record,index }">
                    <template v-if="column.dataIndex === 'name' || column.dataIndex === 'type_name'">
                      <div @click="handleShowData(record)"> <ZoomInOutlined />{{ text }}</div>
                    </template>
                    <template v-else >
                      <div :class="{'text-bold':record.lineType=='sum_line'}"> {{ $dealThousands(text) }}</div>
                    </template>
                </template>
                <template #summary>
                  <a-table-summary fixed >
                    <a-table-summary-row class="gray-bg-def-deep">
                      <a-table-summary-cell :index="0" align="center" v-if="columns_filter_items.indexOf('name')>-1" >其它</a-table-summary-cell>

                      <a-table-summary-cell :index="1" align="center" v-if="columns_filter_items.indexOf('type_name')>-1"   > / </a-table-summary-cell>

                      <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('sold_num')>-1" > {{ $dealThousands(topSumObj[item.key].pagination===false ? 0 : item.sold_num - (topSumObj[item.key] || {}).sum_sold_num||0 ) }} </a-table-summary-cell>
                      <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('sold_num__per')>-1"   > / </a-table-summary-cell>
                      <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('price_come_in')>-1" > {{ $dealThousands(topSumObj[item.key].pagination===false ? 0 : item.price_come_in - (topSumObj[item.key] || {}).sum_price_come_in||0 ) }} </a-table-summary-cell>
                      <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('price_come_in__per')>-1"   > / </a-table-summary-cell>
                      <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('material_cost_money')>-1" > {{ $dealThousands(topSumObj[item.key].pagination===false ? 0 : item.material_cost_money - (topSumObj[item.key] || {}).sum_material_cost_money||0 )  }} </a-table-summary-cell>
                      <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('material_cost_money__per')>-1"   > / </a-table-summary-cell>
                      <a-table-summary-cell align="right" v-if="columns_filter_items.indexOf('slid_money')>-1" > {{ $dealThousands(topSumObj[item.key].pagination===false ? 0 : item.slid_money - (topSumObj[item.key] || {}).sum_slid_money||0 ) }} </a-table-summary-cell>
                      <a-table-summary-cell align="center" v-if="columns_filter_items.indexOf('slid_money__per')>-1"   > / </a-table-summary-cell>
                    </a-table-summary-row>
                  </a-table-summary>
                </template>
                <!-- 空数据问题处理 -->
                <template #emptyText>
                </template>
            </a-table>
          
          </div>

              
      </template>
    </div>
      <DialogToolsSvpTable ref="DialogToolsSvpTable"/>
  </div>
</template>

<script>
import { ZoomInOutlined,CaretDownOutlined, CaretUpOutlined  } from '@ant-design/icons-vue';
import * as echarts from 'echarts'
import { mapGetters,mapState } from 'vuex'
import { numberToFixed,debounce,echartGraphic, tableSortFnc,tableDefaultPage,checkZero  } from '@/utils/util'
import DialogToolsSvpTable from '@/components/DialogTools/DialogToolsSvpTable.vue'
// echart变量
let myChart

const DIENSIION_CLUSTER_INDEX = 2;
const CLUSTER_COUNT = 6;

const groupTypeName = {'line':'产品线','type':'产品类别','customer':'客户','area':'区域','group':'业务组'}
const groupTypeArray = Object.keys(groupTypeName);
const groupTypeValues = Object.values(groupTypeName);

const columns_select = [
        {width:90,   title: '纬度', dataIndex: 'name', align: 'center',resizable: true ,ellipsis: true,fixed: 'left'},
        {width:100,   title: '项', dataIndex: 'type_name', align: 'center',resizable: true ,ellipsis: true,fixed: 'left'},
        {width:120,   title: '销量', dataIndex: 'sold_num', align: 'right',resizable: true ,ellipsis: true,...tableSortFnc('sold_num') },
        {width:120,   title: '销量占比', dataIndex: 'sold_num__per', align: 'right',resizable: true ,ellipsis: true,...tableSortFnc('sold_num__per') },
        {width:120,   title: '收入', dataIndex: 'price_come_in', align: 'right',resizable: true ,ellipsis: true,...tableSortFnc('price_come_in') },
        {width:120,   title: '收入占比', dataIndex: 'price_come_in__per', align: 'right',resizable: true ,ellipsis: true,...tableSortFnc('price_come_in__per') },
        {width:120,   title: '变动成本', dataIndex: 'material_cost_money', align: 'right',resizable: true ,ellipsis: true,...tableSortFnc('material_cost_money') },
        {width:120,   title: '成本占比', dataIndex: 'material_cost_money__per', align: 'right',resizable: true ,ellipsis: true,...tableSortFnc('material_cost_money__per') },
        {width:120,   title: '边际收益', dataIndex: 'slid_money', align: 'right',resizable: true ,ellipsis: true,...tableSortFnc('slid_money') },
        {width:120,   title: '边际收益占比', dataIndex: 'slid_money__per', align: 'right',resizable: true ,ellipsis: true,...tableSortFnc('slid_money__per') },
      ];

export default {
	name: 'SolutionSvpScatterAfter',
  components:{
    ZoomInOutlined, DialogToolsSvpTable,CaretDownOutlined,CaretUpOutlined 
  },
  props: {
    
    isPrint: {
      type: Boolean,
      default: false
    },
    pagination:{
        type:[Boolean,Object],
        default:false,
    },
    planType:{
        type: String,
        default:"",
    },
  },
	data(){
    let checkedOptions = []
    for(let key in groupTypeName){
      checkedOptions.push( {label:groupTypeName[key],value:key})
    }
		return {
      checkedOptions:checkedOptions,
      checkedList:[],
      groupArray:[],
      tableDataList:[],
     
      debounce:()=>{},
      topSumObj:{},
      legendSelectNames : [],
      legendSelectNamesSelected : {},

      columns_filter: [['name'],['type_name'],['sold_num'],['price_come_in'],['material_cost_money'],['slid_money']],
      columns_select:columns_select,
      columns:[],
    }
	},
	computed:{
		excelData_yc(){
			return this.$store.state.tools.excelData_ycChange
		},
    sum_sold_num(){
      return this.getSum_tableData('sold_num');
    },
    sum_price_come_in(){
      return this.getSum_tableData('price_come_in');
    },
    sum_material_cost_money(){
      return this.getSum_tableData('material_cost_money');
    },
    sum_slid_money(){
      return this.getSum_tableData('slid_money');
    },
    conditionTableData(){
      return this.tableData.filter( e=> this.checkedList.indexOf(e.key)>-1)
    },
    columns_filter_items(){
      return  this.columns_filter.map(item=>{
        return item[0]
      });
    },
	},
	watch : {
    checkedList(value){
      var selected = {}
      for(let key in groupTypeName){
          selected[groupTypeName[key]] = value.indexOf(key)>-1
      }
      //更新图标
      this.legendSelectNamesSelected = selected;
      this.setEchart({legend:{selected:selected}})
    },
    planType(value){
      if(value=='extra'){
        this.$nextTick(()=>{
          setTimeout(()=>{
            myChart.resize()
          },200)
        })
      }
    },
		excelData_yc(value) {
      let groupObject = {}
      value.map(function(item){
        for(let key in groupTypeName){
          const itemValue = item[key]
          const name = groupTypeName[key]
          if (!(name in groupObject)){
            groupObject[name] = {}
          }
          if(!(itemValue in groupObject[name])){
            groupObject[name][itemValue] = {
              name: name,
              key: key,
              type_name: itemValue,
              //销售收入
              price_come_in: 0,
              //变动成本
              material_cost_money: 0,
              //销量
              sold_num: 0,
              //边际收益
              slid_money: 0,
              //记录组合的数据
              list:[],
            };
          }
          const sold_num =  item.sold_num*1;
          //产量
          const pass_sold_num =  item.pass_sold_num*1;
          item.material_cost_money = numberToFixed( item.material_cost*1 * pass_sold_num);

          item.slid_money = numberToFixed( item.price_come_in*1 -  item.material_cost_money);
          item.price_come_in = numberToFixed( item.price_come_in);


          groupObject[name][itemValue]['price_come_in'] += item.price_come_in*1;
          groupObject[name][itemValue]['material_cost_money'] +=  item.material_cost_money*1;
          groupObject[name][itemValue]['sold_num'] += sold_num;
          groupObject[name][itemValue]['list'].push(item);
          //边际贡献
          const slid_money = numberToFixed(item.price_come_in - item.material_cost);
          // groupObject[name][itemValue]['slid_money'] +=  groupObject[name][itemValue]['price_come_in'] - groupObject[name][itemValue]['material_cost_money'];
        }
      });

      for(let _key in groupObject){
        for(let _member in groupObject[_key]){
          groupObject[_key][_member]['price_come_in'] = numberToFixed(groupObject[_key][_member]['price_come_in'])
          groupObject[_key][_member]['material_cost_money'] = numberToFixed(groupObject[_key][_member]['material_cost_money'])
          groupObject[_key][_member]['sold_num'] = numberToFixed(groupObject[_key][_member]['sold_num'])
          groupObject[_key][_member]['slid_money'] = numberToFixed(groupObject[_key][_member]['price_come_in'] - groupObject[_key][_member]['material_cost_money'])
        }
      }

      let tableDataList = [];
      let topSumObj = {};

      let echartObject = {}
      for(let typeKey in groupTypeName){
        const name = groupTypeName[typeKey]
        echartObject[name] = {
            name: name,
            type: 'scatter',
            emphasis: {
              focus: 'series'
            },
            markArea: {
              silent: true,
              itemStyle: {
                color: 'transparent',
                borderWidth: 0.5,
                borderType: 'dashed'
              },
              data: [
                [
                  { name: name,  xAxis: 'min',  yAxis: 'min' },
                  { xAxis: 'max', yAxis: 'max' }
                ]
              ]
            },
            markPoint: {
              data: [
                { groupName: name,type: 'max', name: 'Max' },
                { groupName: name,type: 'min', name: 'Min' }
              ]
            },
            markLine: {},
            data:[],

        }

        //表格数据
        let sum_price_come_in=0;
        let sum_material_cost_money=0;
        let sum_sold_num=0;
        let sum_slid_money=0;

        let tableData = [];

        for(let memberKey in groupObject[name]){
          const item = groupObject[name][memberKey]
          echartObject[name].data.push([numberToFixed(item.sold_num),  numberToFixed(item.slid_money) ,memberKey])
          sum_price_come_in = sum_price_come_in + item.price_come_in*1;
          sum_material_cost_money = sum_material_cost_money + item.material_cost_money*1;
          sum_sold_num = sum_sold_num + item.sold_num*1;
          sum_slid_money = sum_slid_money + item.slid_money*1;

          const sum_coll_number = sum_price_come_in*1 + sum_material_cost_money*1 + sum_sold_num*1 + sum_slid_money*1

          tableData.push({
            name : name,
            key :  typeKey,
            list :  item.list,
            lineType : 'line',
            type_name : memberKey,

            price_come_in   :item.price_come_in,
            price_come_in__per   : numberToFixed(checkZero(sum_coll_number) ? 0 : (item.price_come_in/sum_coll_number)*100),

            material_cost_money : item.material_cost_money,
            material_cost_money__per : numberToFixed(checkZero(sum_coll_number) ? 0 : (item.material_cost_money/sum_coll_number)*100),

            sold_num : item.sold_num,
            sold_num__per : numberToFixed(checkZero(sum_coll_number) ? 0 : (item.sold_num/sum_coll_number)*100),

            slid_money : item.slid_money,
            slid_money__per : numberToFixed(checkZero(sum_coll_number) ? 0 : (item.slid_money/sum_coll_number)*100),

          })
        }
        const sortTableData = tableData.sort(tableSortFnc("slid_money").sorter.compare)
        tableDataList.push({
          name : '合计',
          key :  typeKey,
          price_come_in   :sum_price_come_in,
          material_cost_money : sum_material_cost_money,
          sold_num : sum_sold_num,
          slid_money : sum_slid_money,
          sum_coll_number : sum_price_come_in*1 + sum_slid_money*1  + sum_sold_num*1  + sum_slid_money*1 ,
          data:sortTableData,
        }) 
        topSumObj[typeKey]={
          sum_price_come_in : 0,
          sum_material_cost_money: 0,
          sum_sold_num: 0,
          sum_slid_money: 0,
          price_come_in__per:'/',
          material_cost_money__per:'/',
          sold_num__per:'/',
          slid_money__per:'/',
          upSortData:[],
          pagination : tableDefaultPage(),
        };
        // tableData.push({
        //   'name':name+'合计',
        //   'key': typeKey,
        //   'lineType':'sum_line',
        //   'type_name':'/',
        //   'price_come_in' :sum_price_come_in,
        //   'material_cost_money':sum_material_cost_money,
        //   'sold_num':sum_sold_num,
        //   'slid_money':sum_slid_money,
        // })

        //x轴均值
        let xAVG = 0
        const dataLen = echartObject[name].data.length;
        echartObject[name].data.map((xAxisItem)=>xAVG += xAxisItem[0]*1)
        xAVG = dataLen ==0 ? 0 : xAVG / dataLen
        echartObject[name].markLine={
          lineStyle: { type: 'solid' },
          data: [{ type: 'average', name: 'AVG' }, { xAxis: xAVG }]
        }
      }
      this.topSumObj = topSumObj
			this.tableDataList = tableDataList
      const groupArray = Object.values(echartObject);
      if(!this.legendSelectNames.length){
        const legendSelectNames = []
        const legendSelectNamesSelected = {}
        groupArray.map(function(item){
          legendSelectNames.push(item.name)
          legendSelectNamesSelected[item.name] = false
        })
        this.legendSelectNames =  legendSelectNames
        this.legendSelectNamesSelected = legendSelectNamesSelected
      }
      

      this.groupArray = groupArray;


       //计算其它加个
       this.$nextTick(()=>{
        tableDataList.map((item)=>{
          this.calOtherSum(item,item.data)

        })

       })

		},
    groupArray:{
      deep:true,
      handler:function(){
        
        this.drawEchart()
      },
    },
    columns_filter:{
      immediate:true,//首次加载的时候执行函数
      // deep:true, // 深入观察,监听数组值,对象属性值的变化
      handler(value){
        const columnsSelected = value.map(item=> item[0]);
        const columns = []
        columns_select.map((item,columnIndex)=>{
          if( columnsSelected.indexOf(item.dataIndex)>-1){
            columns.push(item)
          }
        })
        this.columns = columns;
      }
    },
	},
	mounted(){
    this.debounce = debounce(this._drawEchart)
		var chartDom = document.getElementById('SolutionSvpScatterAfter')
    myChart = echarts.init(chartDom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    })


	},
	methods:{
    handleShowData(row){
      this.$refs.DialogToolsSvpTable.showDialog(row)
    },
    onTableFieldChange(value,field){
      this[field+'_filter'] = value
    },
    showMoreTableList(item){
      const selectKey = item.key||''
      // const selectBlock = this.topSumObj[selectKey]||{}

      if(this.topSumObj[selectKey].pagination===false){
        this.topSumObj[selectKey].pagination = tableDefaultPage();
        this.calOtherSum(item, this.topSumObj[selectKey].upSortData,false)

      }else{
        this.topSumObj[selectKey].pagination = false;
      }
      
    },
    //计算其它加个
    calOtherSum(item,currentDataSource,recordUpData=true){
      const selectKey = item.key||''
      const selectBlock = this.topSumObj[selectKey]||{}
      const totalObj ={}
      for(let key in selectBlock){
        if(key=="upSortData") continue;

        const pageSize = this.pagination.pageSize||0
        const field = key.replace("sum_","")
        const topSumObj_field = key;
        totalObj[topSumObj_field] = 0;
          let total = 0;
          for(let i=0;i<currentDataSource.length;i++){
            const info = currentDataSource[i];
            
            if(i>=pageSize){
              break;
            }
            totalObj[topSumObj_field] = numberToFixed( totalObj[topSumObj_field]*1 + info[field]*1);
          }
      }
      if(recordUpData){
        totalObj.upSortData = currentDataSource;
      }else{
        totalObj.upSortData = selectBlock.upSortData
      }

      this.topSumObj[selectKey] = totalObj;
    },
    onTableChange(item,pagination, filters, sorter, {currentDataSource,action}){
      // console.log('>>>>>>>>>>>>>>>>>> onTableChange <<<<<<<<<<<<<<<<<<<')
      if(action=='sort'){
        return this.calOtherSum(item,currentDataSource)
      }
      
    },

    getSum_tableData(field){
      let money = 0 ;
      this.conditionTableData.map(function(item){
          if(item.lineType!=='sum_line'){
          money += +item[field]
        }
      })
      return numberToFixed(money)
    },
		drawEchart(){

      this.debounce()
    },
    setEchart(options={}){
      // 释放资源
      myChart.setOption(options)
    },
    _drawEchart() {

      const option =  {
        title: {
          text: '',
          subtext: ''
        },
        grid: {
          left: '3%',
          right: '7%',
          bottom: '7%',
          containLabel: true
        },
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          formatter: function (params) {
            const data = params.data||[];
            const showName =data[2]||''
            if (params.value.length > 1) {
              return (
                params.seriesName +  ':'+showName+' <br/>' +
                params.value[0] + '销量 ' +
                params.value[1] + '边际贡献 '
              );
            } else {
              if(params.componentType==='markLine'){
                  return (
                  'Avg <br/>' +
                  '边际贡献: ' + params.value + ' '
                );
              }else{
                return (
                  params.data.groupName +' <br/>' +
                  '边际贡献: ' + params.value + ' '
                );
              }
            }
          },
          axisPointer: {
            show: true,
            type: 'cross',
            lineStyle: { type: 'dashed', width: 1 }
          }
        },
        toolbox: {
          feature: {
            dataZoom: {},
            brush: {
              type: ['rect', 'polygon', 'clear']
            }
          }
        },
        brush: {},
        legend: {
          data: this.legendSelectNames,
          selected: this.legendSelectNamesSelected,
          selectedMode:false,
        },
        xAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              formatter: '{value} 销量'
            },
            splitLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              formatter: '{value} 边际贡献'
            },
            splitLine: {
              show: false
            }
          }
        ],
        
        graphic: echartGraphic(),
        series: this.groupArray,
      }
      // 释放资源
      myChart.setOption(option)
    }
	}
}
</script>

<style scoped>
.checkbox-group {
    margin-bottom: 12px;
}
</style>
