<template>
  <div class="solution-children">
    <div class="head">成本中心档案</div>


    <div class="row">
      <div class="a-space-table">
        <a-space :size="16">
          <a-table :columns="columns" :data-source="tools2_excelData_cost" :pagination="pagination" size="small" bordered>
           
          </a-table>
        </a-space>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters  } from 'vuex'

export default {
  name: 'SolutionCost',
  components: {
  },
  props: {
    planId: {
      type: [Number, String],
      default: 0
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    }
  },
  data() {
    return {
     
      //加成前
      columns: [
        { title: '基本成本中心', dataIndex: 'cost_name', align: 'center' },
        { title: '属性', dataIndex: 'attr', align: 'center' },
        { title: '上级成本中心', dataIndex: 'cost_name_up', align: 'center' },
        { title: '期间费用中心', dataIndex: 'money_center_name', align: 'center' },
      ],
    }
  },
  computed: {
    ...mapGetters([
      
    ]),
		tools2_excelData_cost(){
			return this.$store.state.tools2.tools2_excelData_cost;
		},
    

    
  },
  created() {},
  mounted() {
    this.getDetail()
  },
  watch: {
    
   
   
  },
  methods: {
    getDetail() {
      return this.$network('/api/tools/planResultData', {
        id: this.planId,
        type: 'step2-cost'
      })
        .then((res) => {
          const data = res.data || {}
          const list = data.list || []
          this.$store.commit('updateTools2State',{field:'tools2_excelData_cost',value: list }) 

        })
        .catch((err) => {
          console.error(err)
        })
    },
   
  }
}
</script>
