<template>
  <div class="table-vip-box" v-if="user_ident !== 'person'">
    <div class="set-bag-box">
      <div class="bag" v-for="(item, index) in useToolList" :key="index">
        <div class="img-box1"><img :src="item.tools_img" /></div>
        <div class="text-box">
          <div class="text1">
            <div class="text">
              ￥<span class="span1">{{ updateNum(item.price_year) }}</span
              >元/年
            </div>
            <div class="text">折合￥{{ updateNum(item.price_month) }}元/月</div>
            <div class="text" v-html="item.year_per_intro"></div>
          </div>
          <div class="text2">
            <template v-for="(toolItem, toolIndex) in item.tools" :key="toolIndex">
              <div style="display: flex; align-items: center">
                <div style="color: #67c200; margin-right: 5px">√</div>
                <div class="text" v-html="toolItem.name"></div>
              </div>
            </template>
          </div>
          <div class="text3">
            <div class="text" v-html="item.use_range"></div>
          </div>
        </div>
        <div class="img-box2"><img :src="item.tools_img_btn" @click="handleOpen(item)" /></div>
      </div>
    </div>
    <table>
      <tbody>
        <tr>
          <td style="text-align: left"><a-checkbox v-model:checked="isShowAllType" @change="changeIsShowAllType(isShowAllType)">展开功能分类</a-checkbox></td>
          <template v-for="(item, index) in useToolList" :key="index">
            <th></th>
          </template>
        </tr>
        <tr>
          <td style="text-align: left; background: #f2faff; font-weight: bold"><span>套餐明细</span></td>
          <template v-for="(item, index) in useToolList" :key="index">
            <td style="background: #f2faff; font-weight: bold">
              <span>{{ item.name }}</span>
            </td>
          </template>
        </tr>
        <template v-for="(toolItem, toolIndex) in toolsList" :key="toolIndex">
          <tr>
            <td class="td-row-1" style="text-align: left">
              <div>
                <img :src="toolItem.img" style="padding: 0 5px" width="40"/><span style="font-weight: bold">{{ toolItem.name }}</span>
                <a-space @click="handleSwitch(toolItem)" style="padding-left: 5px">
                  <DownOutlined v-if="!toolItem.show" />
                  <UpOutlined v-else />
                </a-space>

                <div v-show="toolItem.show" style="padding-left: 20px">
                  <div v-for="(childItem, childIndex) in toolItem.children" :key="childIndex">
                    <div v-for="(mapItem, mapIndex) in childItem.maps" :key="mapIndex">
                      {{ mapItem.name }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <template v-for="(item, index) in useToolList" :key="index">
              <td style="background: #f9fafc">
                <div :class="handleSelectState(item, toolItem)[2]">{{ handleSelectState(item, toolItem)[1] }}</div>
                <div v-show="toolItem.show">
                  <div v-for="(childItem, childIndex) in toolItem.children" :key="childIndex">
                    <div v-for="(mapItem, mapIndex) in childItem.maps" :key="mapIndex">
                      <span :class="handleSelectChildState(item, childItem)[1]">{{ handleSelectChildState(item, childItem)[1] }}</span>
                    </div>
                  </div>
                </div>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import ContentSelected from '@/components/ContentSelected.vue'
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
export default {
  props: {
    moduleName: {
      type: String,
      default: ''
    }
  },
  components: {
    ContentSelected,
    DownOutlined,
    UpOutlined
  },
  emits: ['openWay'],
  data() {
    return {
      compayList: [],
      personList: [],
      toolsList: [],
      //开通的工具集合
      useToolList: [],
      setBagColums: [
        {
          title: '',
          dataIndex: 'name',
          width: 200,
          align: 'left'
        }
      ],
      newToolsList: [],
      isShowAllType: false,
      user_ident: ''
    }
  },
  computed: {},
  mounted() {
    this.getData()
    this.user_ident = this.$store.state.user.user_ident
  },
  methods: {
    //去除小数点后两位
    updateNum(strNumber) {
      let withoutDecimal = strNumber.replace(/\..+$/, '')
      return withoutDecimal
    },
    //展开/关闭所有行
    changeIsShowAllType(e) {
      this.toolsList.forEach((item) => {
        item.show = e
      })
    },
    handleSwitch(item) {
      item.show = !item.show
    },
    getData() {
      this.$network('/api/platform/getVipInfo')
        .then((res) => {
          const data = res.data || {}
          this.compayList = data.company.children || []
          this.personList = data.person.children || []
          const toolsList = data.tools || []
          toolsList.map((item) => {
            item.show = false
          })
          this.toolsList = toolsList

          let useToolList = this.personList
          if (this.$store.state.user.type === 1) {
            useToolList = this.compayList
          }
          this.useToolList = useToolList
          if (!this.$store.state.user.type) {
            //普通会员
            this.handleOpen(useToolList[0] || {})
          }

          // this.newToolsList = this.addKeyToObjects(this.toolsList)
          // this.getSetBagColums()
          // console.log('========================this.useToolList', this.useToolList)
        })
        .catch((err) => {
          console.error(err)
        })
    },

    handleOpen(item) {
      if (this.$store.state.user.type) {
        this.$router.push({ path: '/order/req', query: { id: item.id, type: item.type } })
      } else {
        this.$emit('openWay', item)
      }
    },

    handleSelectState(item, toolItem) {
      const use_tool = item.use_tool || []
      const toolItems = toolItem.children.map((mapItem) => {
        return mapItem.value
      })
      const diffItems = toolItems.filter((vo) => use_tool.includes(vo))

      let state = 0
      let content = 'x'
      let cssClass = 'none'
      // console.log(toolItem.name,use_tool,toolItems,diffItems)
      if (diffItems.length) {
        if (diffItems.length != toolItems.length) {
          content = '√'
          cssClass = 'some'
          state = 1 //部分包含
        } else if (diffItems.length == toolItems.length) {
          content = '√'
          cssClass = 'all'
          state = 2 //全包含
        }
      }

      return [state, content, cssClass]
    },
    handleSelectChildState(item, toolItem) {
      const use_tool = item.use_tool || []
      const toolItemValue = toolItem.value
      let state = use_tool.includes(toolItemValue) ? 1 : 0
      let content = state == 1 ? '√' : 'x'
      let cssClass = state == 1 ? 'all' : 'none'
      return [state, content, cssClass]
    }
  }
}
</script>

<style lang="less" scoped>
.all,
.some,
.√ {
  color: #67c200;
  font-size: 16px;
  // font-weight: bolder;
}
.none,
.x {
  font-size: 16px;
  // font-weight: bolder;
  color: #ff0000;
}
</style>
