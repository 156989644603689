import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import { Exception } from 'sass';
export const htmlToPDF = async (htmlId: string, title: string = "报表", footerId: string="",bgColor = "#fff", orientation="p") => {
    let pdfDom: HTMLElement | null = document.getElementById(htmlId) as HTMLElement
    pdfDom.style.padding = '0 10px !important'
    let A4Width = 595.28;
    let A4Height = 841.89;
    if(orientation=="l"){
        A4Width = 841.89;
        A4Height = 595.28;
    }

    let canvas = await html2canvas(pdfDom, {
        scale: 2,
        useCORS: true,
        backgroundColor: bgColor,
    });
    
   

    const footerHeight = 160
    const headerHeight = 80
    let A4Per =  (canvas.width / A4Width)
    let pageHeight = A4Per * A4Height;
    let leftHeight = canvas.height;
    let canvasFooterHeight = footerHeight/A4Per
    let canvasHeaderHeight = headerHeight/A4Per
    
    let position = 0;
    let imgPer = (A4Width / canvas.width)
    let imgWidth = A4Width;
    let imgHeight = imgPer * canvas.height;
    let A4FooterHeight = imgPer*footerHeight;
    let A4HeaderHeight = imgPer*headerHeight;


    let canvasFooterDataUrl = null
    if(footerId){
        const canvasFooter = await html2canvas(document.getElementById(footerId), {
            scale: 2,
            useCORS: true,
            backgroundColor: bgColor,
        });
        canvasFooterDataUrl = canvasFooter.toDataURL("image/jpeg", 1.0);
    }

    // console.log(pageHeight,pageFooterHeight)
    // console.log(imgHeight,imgFooterHeight)
    //  1 / 0
    // return
    /*
     根据自身业务需求  是否在此处键入下方水印代码
    */
   
    //  const ctx: any = canvas.getContext('2d');
    //  ctx.textAlign = 'center';
    //  ctx.textBaseline = 'middle';
    //  ctx.rotate((20 * Math.PI) / 180);
    //  ctx.font = '20px Microsoft Yahei';
    //  ctx.fillStyle = 'rgba(184, 184, 184, 0.8)';
    //  for (let i = canvas.width * -1; i < canvas.width; i += 240) {
    //      for (let j = canvas.height * -1; j < canvas.height; j += 200) {
    //          // 填充文字，x 间距, y 间距
    //          ctx.fillText('水印名', i, j);
    //      }
    //  }
    //添加页脚
    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages()
      
        doc.setFont('helvetica', 'italic')
        doc.setFontSize(8)
        //画白条
        const drawWhiteBlack=(position,height)=>{
            doc.setFillColor("#ffffff")
            doc.rect(0, position, doc.internal.pageSize.width , height,'F')
        };
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          
            if(canvasFooterDataUrl){
                // console.log(canvasFooterDataUrl)
                doc.addImage(canvasFooterDataUrl, "JPEG", 0, doc.internal.pageSize.height-A4FooterHeight, imgWidth, A4FooterHeight);
            }else{
                drawWhiteBlack(doc.internal.pageSize.height-A4FooterHeight,A4FooterHeight )
            }

          doc.setTextColor(255,255,255)
          doc.setFontSize(5)
          doc.text( '  '+String(i)+'/'+String(pageCount) + ' ',  doc.internal.pageSize.width-20, doc.internal.pageSize.height - A4FooterHeight/6 )

          if(i>1){ //页头
            drawWhiteBlack(0, A4HeaderHeight)
          }

        //   doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, A4Width, {
        //     align: 'center',
        //     background: 'red',
        //   })
        }
      }

    let pageData = canvas.toDataURL("image/jpeg", 1.0);

    

    let PDF = new jsPDF(orientation, 'pt', 'a4',true);
    
    if (leftHeight < pageHeight) {
        PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
    } else {
        console.log(leftHeight)
        let pageIndex =0
        while (leftHeight > 0) {
            pageIndex++;
            if(pageIndex > 1){
                PDF.addImage(pageData, "JPEG", 0, position + A4HeaderHeight, imgWidth, imgHeight);
                leftHeight = (leftHeight - pageHeight) + (footerHeight - headerHeight);
                position -= (A4Height - (A4FooterHeight - A4HeaderHeight));
            }else{
                //首页
                PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
                leftHeight -= (pageHeight );
                position -= (A4Height - A4FooterHeight);

            }
            if (leftHeight > 0) PDF.addPage();
            // console.log(pageIndex, leftHeight,canvasFooterHeight,canvasHeaderHeight,canvasFooterHeight - canvasHeaderHeight)
        }
    }
    addFooters(PDF)

    PDF.save(title + ".pdf");
}