<template>
  <div class="tips" v-if="content.length">
    <template v-for="(item,index) in content">
        <div>{{item}}</div>
    </template>
    
  </div>
</template>

<script>
export default {
    props:{
        type:{
            type:String,
            default:'',
        }
    },
    data(){
        return {
            content: [],
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            if(this.$store.state.app.tipsContent[this.type]){
                this.content = this.$store.state.app.tipsContent[this.type]
                return 
            }
            this.$network('/api/index/tipsContent', { type: this.type }).then((res) => {
                const data = res.data || {}
                const content = data.content
                this.content = content
                this.$store.state.app.tipsContent = {}
                this.$store.state.app.tipsContent[this.type] = content
            })
        },
    }
}
</script>

<style>
</style>