<template>
  <div id="solutionTemplatePDF" class="solutionTemplatePDF">
    <a-watermark :content="getWaterName">
      <div class="solution-box">
        <div class="headBox">
          <div class="headBoxBg">
            <img src="@/assets/images/solution_img.png" />
            <div class="headBoxLogo"><img :src="getPlanLogo" class="logo" alt="logo" /></div>
          </div>
          <div class="headBoxContent">
            <div class="headTitle">
              <div class="title">{{ getPlanName }}</div>
              <div class="subtitle">{{ getPlanName_en }}</div>
            </div>
            <div class="author">
              <div class="author_name">{{ getWaterName }}</div>
              <div class="author_info">
                <span>编制人：{{ userInfo.name }}</span>
                <span>编制时间：{{ detail.created_at }}</span>
                <span>打印次数：{{ detail.times }}次</span>
              </div>
            </div>
          </div>
          <div class="headTypeText">
            <VipTag :isText="true" moduleName="printPDF" />
            <span>基本方案</span>
          </div>
        </div>

        <div class="solution-wrap">
          <div class="solution-first">
            <div class="solution-children">
              <div class="title"><span>工具指引</span></div>
              <div class="row">
                <div class="RichText">
                  <tools-item-intro type="six" field="intro_print_base" moduleName="background" />
                </div>
              </div>
            </div>
            <!-- 1 整体参数 -->
            <div class="solution-children">
              <a-row :gutter="100">
                <a-col :span="9" v-show="getHasToolAuth('tools1_base_params')">
                  <div class="title"><span>参数调整</span><em>*行参数调整详见附表明细</em></div>
                  <div class="row">
                    <a-descriptions :column="1" size="small" bordered :labelStyle="{ width: '240px' }">
                      <a-descriptions-item label="含税售价整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_tax_per" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                      <a-descriptions-item label="销量整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_sold_per" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                      <a-descriptions-item label="单位变动成本整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_cost_per" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                      <a-descriptions-item label="产品合格率整体加成(%)">
                        <div class="descriptions-span">
                          <SliderInputBlock :value="params_pass_per" :max="100" :step="0.5" :planType="planType" />
                        </div>
                      </a-descriptions-item>
                    </a-descriptions>
                  </div>
                </a-col>
                <a-col :span="15">
                  <div class="subtitle"><span>工具假设</span></div>
                  <div class="row">
                    <div class="RichText">
                      <tools-item-intro type="six" field="intro_base" moduleName="background" />
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <!-- 2 CVP折线图 -->
            <div class="solution-children">
              <a-row :gutter="200" v-show="getHasToolAuth('tools1_cvp_before')">
                <a-col :span="12">
                  <div class="subtitle"><span>原始数据</span></div>
                  <div class="row">
                    <img class="canvas" :src="svpLineBeforeImg" />
                    <!-- <SolutionSVPLineBefore :planType="planType" :isPrint="isPrint" /> -->
                  </div>
                </a-col>
                <a-col :span="12" v-show="getHasToolAuth('tools1_cvp_after')">
                  <div class="subtitle"><span>调整后</span></div>
                  <div class="row">
                    <img class="canvas" :src="svpLineAfterImg" />
                    <!-- <SolutionSVPLineAfter :planType="planType" :isPrint="isPrint" /> -->
                  </div>
                </a-col>
              </a-row>
            </div>
            <!-- 3 盈亏平衡点 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="12">
                  <div class="title"><span>盈亏平衡点</span></div>
                  <div class="row">
                    <div class="RichText">
                      <div v-if="!svpLineBefore_zeroPintValues.length">加成前：在当前销售结构下,无盈亏平衡点</div>
                      <template v-for="(item, index) in svpLineBefore_zeroPintValues" :key="index">
                        <div>
                          加成前：在当前销售结构下,盈亏点销量：{{ $dealThousands(item.soldNum) }}；总收入:{{ $dealThousands(item.comeIn) }} ；总成本{{ $dealThousands(item.materialCost) }} ；管理利润{{
                            $dealThousands(item.profitManager)
                          }}
                        </div>
                        <!-- <div>盈亏点销量：{{item.soldNum}}；总收入:{{item.comeIn}} ；总成本{{item.materialCost}} ；管理利润{{item.profitManager}}</div> -->
                      </template>
                    </div>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="title"><span>盈亏平衡点</span></div>
                  <div class="row">
                    <div class="RichText">
                      <div v-if="!svpLineAfter_zeroPintValues.length">加成后：在当前销售结构下,无盈亏平衡点</div>
                      <template v-for="(item, index) in svpLineAfter_zeroPintValues" :key="index">
                        <div>
                          加成后：在当前销售结构下,盈亏点销量：{{ $dealThousands(item.soldNum) }}；总收入:{{ $dealThousands(item.comeIn) }} ；总成本{{ $dealThousands(item.materialCost) }} ；管理利润{{
                            $dealThousands(item.profitManager)
                          }}
                        </div>
                        <!-- <div>盈亏点销量：{{item.soldNum}}；总收入:{{item.comeIn}} ；总成本{{item.materialCost}} ；管理利润{{item.profitManager}}</div> -->
                      </template>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <!-- 4 指标一览 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="24">
                  <div class="title"><span>指标一览</span></div>
                  <div class="row" id="solutionStructOperateTable">
                    <!-- <solution-struct-operate-table :planId="listQuery.id" :pagination="pagination" :planType="planType" :isPrint="isPrint" /> -->
                  </div>
                </a-col>
              </a-row>
            </div>

            <!-- 5 备注 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="24">
                  <div class="title"><span>编制备注</span></div>
                  <div class="row">
                    <div class="RichText">
                      {{ remark_base }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>

            <!-- 6 方案解读 -->
            <div class="solution-children">
              <a-row :gutter="200">
                <a-col :span="24">
                  <div class="title"><span>方案解读</span></div>
                  <div class="row">
                    <div class="RichText">
                      {{ planResult_base }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div class="solution-children">
              <p class="tips">*附表见下页</p>
            </div>
          </div>

          <!-- 基础数据 -->
          <div class="solution-children">
            <p class="tip">附表</p>
            <a-row :gutter="200">
              <a-col :span="24">
                <div class="title"><span>基础数据表</span></div>
                <div class="row" id="solutionBaseTable">
                  <!-- <solution-struct-operate-table :planId="listQuery.id" :pagination="pagination" :planType="planType" :isPrint="isPrint" /> -->
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </a-watermark>
  </div>
</template>

<script>
import VipTag from '@/components/VipTag.vue'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import { mapGetters } from 'vuex'
import { message } from 'ant-design-vue'
import { htmlToPDF } from '@/utils/html2pdf'
import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'
//打印模板
export default {
  name: 'solutionTemplatePDF',
  components: {
    VipTag,
    SliderInputBlock,
    ToolsItemIntro
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    detail: {
      type: Object,
      default: function () {
        return {}
      }
    },
    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    },
    remark_base: {
      type: String,
      default: ''
    },
    planResult_base: {
      type: String,
      default: ''
    },
    planType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      svpLineBeforeImg: '',
      svpLineBefore_zeroPintValues: [],
      svpLineAfterImg: '',
      svpLineAfter_zeroPintValues: []
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    }
  },
  created() {},
  mounted() {},

  methods: {
    async downPDF(pageObj) {
      if (this.getHasToolAuth('tools1_cvp_before')) {
        await this.svpLineBefore(pageObj)
      }
      if (this.getHasToolAuth('tools1_cvp_after')) {
        await this.svpLineAfter(pageObj)
      }

      await this.solutionStructOperateTable(pageObj)
      await this.solutionBaseTable(pageObj)
    },

    //保存图-折线图加成前
    async svpLineBefore(pageObj) {
      this.svpLineBeforeImg = pageObj.$refs.svpLineBefore.generateImage()
      this.svpLineBefore_zeroPintValues = pageObj.$refs.svpLineBefore.zeroPintValues || []
    },
    //保存图-折线图加成后
    async svpLineAfter(pageObj) {
      this.svpLineAfterImg = pageObj.$refs.svpLineAfter.generateImage()
      this.svpLineAfter_zeroPintValues = pageObj.$refs.svpLineAfter.zeroPintValues || []
    },
    //指标
    async solutionStructOperateTable(pageObj) {
      console.log(pageObj.$refs.solutionStructOperateTable.$el)
      document.getElementById('solutionStructOperateTable').appendChild(pageObj.$refs.solutionStructOperateTable.$el)
    },
    //表格数据
    async solutionBaseTable(pageObj) {
      document.getElementById('solutionBaseTable').appendChild(pageObj.$refs.solutionBaseTable.$refs.solutionBaseTable.$el)
    }
  }
}
</script>

<style lang="less" >
#solutionTemplatePDF {
  .solution-box {
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
      padding: 6px 12px;
      font-size: 18px;
    }
    .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
      padding: 6px 5px;
    }
    .ant-table .ant-table-container .ant-table-cell {
      font-size: 16px;
    }
  }
}
</style>
