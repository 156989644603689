<template>
  <div style="width: 100%">
    <a-row>
      <a-col :span="12">
        <SliderInputBlock v-model:value="xAxisNum" :min="1" :max="3" :step="0.1" />
      </a-col>
    </a-row>

    <div id="svp-line-before" style="height: 768px"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
import { numberToFixed, checkZero, debounce,getMindValue } from '@/utils/util'
import SliderInputBlock from '@/components/SliderInputBlock.vue'

const defaultEchartData = [
  //收入=0*加权平均单位不含税售价     1.7*加权平均单位不含税售价   3.4*加权平均单位不含税售价  5.1*加权平均单位不含税售价...
  { name: 'priceComeInPrice', data: { name: '总收入', type: 'line', stack: 'priceComeInPrice', data: [] } },
   //总成本＝变动成本总额+固定成本总额 + 对应销量区间的混合成本总额
   {name:'materialCostMixRagePrice',data:{ name: '总成本', type: 'line', stack: 'materialCostMixRagePrice', data:[]}},

  //变动成本总额
  { name: 'materialCostPrice', data: { name: '变动成本', type: 'line', stack: 'materialCostPrice', data: [] } },
  //总固定成本=固定成本总额
  { name: 'fixedMoeny', data: { name: '总固定成本', type: 'line', stack: 'fixedMoeny', data: [] } },
  //加成后的总边际贡献 = 总收入-总变动成本
  { name: 'sideMoeny', data: { name: '总边际贡献', type: 'line', stack: 'sideMoeny', data: [] } },

  //管理利润 = 总收入-变动成本总额-固定成本总额-对应销售区间混合成本总额
  { name: 'managerProfitMoeny', data: { name: '管理利润', type: 'line', stack: 'managerProfitMoeny', data: [] } },

  //毛利润 = 总收入-变动成本总额-制造环节固定成本总额-制造环节对应销量所在区间的混合成本总额
  { name: 'profitMoeny', data: { name: '毛利润', type: 'line', stack: 'profitMoeny', data: [] } },
  //预测X轴点 = 管理利润为0的点的销量
  { name: 'prediction', data: { name: '预测', type: 'line', stack: 'prediction', data: [] } },

  //这一条总保持再最后一条记录不然再画混合线条会出问题
  //总混合成本=对应销量区间混合成本总额
  { name: 'mixMoeny', data: { name: '总混合成本', type: 'line', stack: 'mixMoeny', data: [] } }
]

// echart变量
let myChart

export default {
  name: 'SolutionSVPLineBefore',
  props: {},
  components: {
		SliderInputBlock
	},
  data() {
    return {
      xAxisNum: 1.5,

      //x坐标轴
      xAxisData: [],

      echartData: defaultEchartData,

      debounce: () => {}
    }
  },
  computed: {
    ...mapGetters([
			'tools2_sum_sold_num',
			'tools2_avg_price_no_tax_unit_money',
			'tools2_sum_fixed_total_money',
			'tools2_avg_unit_price_before',
			'tools2_sum_n_serial_money',


			'tools2_calYcTableData_productNum',
			'tools2_ycStockData_soldNumToMoney',

		]),
    tableData_step_mix() {
      return this.$store.state.tools2.tableData_step_mix
    },
    tableData_step_fixed() {
      return this.$store.state.tools2.tableData_step_fixed
    },
    ycStockData() {
      return this.$store.state.tools2.ycStockData
    },
    tools2_excelData_tableData_customerNo_mind() {
      return this.$store.state.tools2.tools2_excelData_tableData_customerNo_mind
    },
  },
	watch: {
    echartData: {
      //重新画图
      deep: true,
      handler: function (value) {
        this.$nextTick(() => {
          this.debounce()
        })
      }
    }
  },
  mounted() {
    this.debounce = debounce(this._drawEchart)

    // this.createWatch_sum_sold_num_and_xAxisNum()
    // this.createWatch_materialCostMixRagePrice()
    // this.createWatch_priceComeInPrice()
		// this.createWatch_prediction()
		// this.createWatch_fixedMoeny()
		// this.createWatch_materialCostPrice()
		// this.createWatch_sideMoeny()
		// this.createWatch_managerProfitMoeny()
		// this.createWatch_profitMoeny()


    var chartDom = document.getElementById('svp-line-before')
    myChart = echarts.init(chartDom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    })

    this.$nextTick(() => {
      this.debounce()
    })
  },
  methods: {
    createWatch_sum_sold_num_and_xAxisNum() { //x轴
      this.$watch(
        () => [this.tools2_sum_sold_num, this.xAxisNum],
        (info) => {
          const value = info[0]
          const xAxisNum = info[1] * 1
          //平分等分
          const xAxis_divide = 1000

          const xMaxNum = Math.ceil(value ?? 0)
          const step = xMaxNum / xAxis_divide
          const xAxisDataGroup = []

          for (let i = 0; i <= xAxis_divide; i=i) {
            xAxisDataGroup.push(numberToFixed(i*step ))
          }
          this.xAxisData = xAxisDataGroup
          this.$nextTick(() => {
            //重新画图
            this.debounce()
          })
        }
      )
    },
		createWatch_materialCostMixRagePrice() {
      //总收入 = 不含税售价✖销量再合计所有的产品
      this.$watch(
        () => [this.xAxisData,this.tools2_sum_fixed_total_money,  this.ycStockData,this.tools2_excelData_tableData_customerNo_mind ],
        (info) => {
          const xAxis = info[0]
          const fixed_money = info[1]
          const data = []
          xAxis.map( (item) => {
            data.push(fixed_money*1+this.tools2_ycStockData_soldNumToMoney(item)*1)
          })
          this.echartData[this.checkEchartDataIndex('materialCostMixRagePrice')].data.data = data
        }
      )
    },
		createWatch_priceComeInPrice() {
      //总收入 = 不含税售价✖销量再合计所有的产品
      this.$watch(
        () => [this.xAxisData, this.tools2_avg_price_no_tax_unit_money],
        (info) => {
          const xAxis = info[0]
          const value = info[1]
          const data = []
          xAxis.map( (item) => {
            data.push(numberToFixed(item * value))
          })
          this.echartData[this.checkEchartDataIndex('priceComeInPrice')].data.data = data
        }
      )
    },
		createWatch_prediction() { //预测线条
      this.$watch(
        () => [this.xAxisData, this.tools2_sum_sold_num],
        (info) => {
          const xAxis = info[0]
          const value = info[1]*this.xAxisNum;
          const obj =getMindValue(xAxis,0,value)
          const letMax = obj.letMax
          this.echartData[this.checkEchartDataIndex('prediction')].data = {
            name: '预测',
            type: 'line',
            markLine: {
              yAxisIndex: 0,
              symbol: 'none', //去掉箭头
              data: [
                { name: '预测', xAxis: letMax }
              ]
            }
          }
        }
      )
    },
		createWatch_fixedMoeny() {
      this.$watch(
        () => [this.xAxisData, this.tools2_sum_fixed_total_money],
        (info) => {
          const xAxis = info[0]
          const value = info[1]
          const data = []
          xAxis.map( (item) => {
            data.push(numberToFixed(value * 1))
          })
          this.echartData[this.checkEchartDataIndex('fixedMoeny')].data.data = data
        }
      )
    },
		createWatch_materialCostPrice() {
      //变动成本
      this.$watch(
        () => [this.xAxisData, this.ycStockData,this.tools2_excelData_tableData_customerNo_mind ],
        (info) => {
          const xAxis = info[0]
          const data = []
          xAxis.map( (soldNum) =>{
            data.push(this.tools2_ycStockData_soldNumToMoney(soldNum))
          })
          this.echartData[this.checkEchartDataIndex('materialCostPrice')].data.data = data
        }
      )
    },
		createWatch_sideMoeny() {
      this.$watch(
        () => [this.xAxisData, this.tools2_avg_price_no_tax_unit_money, this.ycStockData,this.tools2_excelData_tableData_customerNo_mind ],
        (info) => {
          const xAxis = info[0]
          const value = info[1]
          const data = []
          xAxis.map( (item) =>{
            data.push(numberToFixed(item * value - this.tools2_ycStockData_soldNumToMoney(item)))
          })
          this.echartData[this.checkEchartDataIndex('sideMoeny')].data.data = data
        }
      )
    },
		createWatch_managerProfitMoeny() {
      this.$watch(
        () => [this.xAxisData, this.tools2_avg_price_no_tax_unit_money, this.tools2_sum_fixed_total_money, this.ycStockData,this.tools2_excelData_tableData_customerNo_mind ],
        (info) => {
          const xAxis = info[0]
          const value = info[1]
          const fixed_money = info[2]
          const data = []
          xAxis.map( (item) =>{
            data.push(numberToFixed(item * value - this.tools2_ycStockData_soldNumToMoney(item) - fixed_money))
          })
          this.echartData[this.checkEchartDataIndex('managerProfitMoeny')].data.data = data
        }
      )
    },
		createWatch_profitMoeny() {
      this.$watch(
        () => [this.xAxisData, this.tools2_avg_price_no_tax_unit_money, this.tools2_sum_n_serial_money,this.ycStockData,this.tools2_excelData_tableData_customerNo_mind ],
        (info) => {
          const xAxis = info[0]
          const value = info[1]
          const serialMoneyNot = info[2]
          const data = []
          xAxis.map( (item) =>{
            data.push(numberToFixed(item * value - this.tools2_ycStockData_soldNumToMoney(item) - serialMoneyNot))
          })
          this.echartData[this.checkEchartDataIndex('profitMoeny')].data.data = data
        }
      )
    },


		checkEchartDataIndex(name) {
      let index = -1
      for (let i = 0; this.echartData.length; i++) {
        const info = this.echartData[i]
        if (info && info.name === name) {
          index = i
          break
        }
      }
      return index
    },

    _drawEchart() {
      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: this.echartData.map((item) => item.data.name)
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: this.echartData.map((item) => item.data)
      }
      // 释放资源
      myChart.setOption(option)
    }
  }
}
</script>

<style>
</style>
