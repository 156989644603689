import { arrayToObj, checkArrayIdentical, checkZero, numberToFixed, roundToDotNumber } from "./util"

//x轴点位
export const echartXPointsFnc = (value, per = 1) => {
    //平分等分
    const xAxis_divide = 1000

    const xMaxNum = Math.ceil(value ?? 0)
    const step = xMaxNum / xAxis_divide
    const data = []
    const len = 1500//xMaxNum < 1000 ? 1200 : xMaxNum
    for (let i = 0; i <= len; i++) {
        data.push(numberToFixed(i * step * per))
    }
    return data
}

//基本表+库存
export const structData_stock_and_yc = (step2YC_data, step2Stock_data, {
    params_tax_per = 0,
    params_sold_per = 0,
    params_cost_per = 0,
    params_pass_per = 0,
}) => {
    // console.log('>>>>>>>>>>>>>>>>>>>>>> structData_stock_and_yc <<<<<<<<<<<<<<<<<<<<<<<<<')
    // console.log(structData_stock_and_yc)
    const stockDataObj = arrayToObj(step2Stock_data, 'customerNo')
    // console.log(stockDataObj)
    const newYcStockData = []
    const newYcStockData_obj = {}
    let sum_sold_num = 0;
    let sum_pass_sold_num = 0;
    let sum_stock = 0;
    let sum_stock_money = 0;
    let sum_price_come_in = 0;
    let sum_cost_price = 0;
    step2YC_data.map((item) => {
        const customerNo = item.customerNo
        const stockDataInfo = stockDataObj[customerNo] || {}
        const stock = stockDataInfo.stock || 0
        const money = stockDataInfo.money || 0
        let pass_per = item.pass_per * 1 + (params_pass_per || 0) * 1
        // pass_per = pass_per>=100 ? 100 : (pass_per<=0?0:pass_per)
        //销量
        item.sold_num = numberToFixed(item.sold_num * (1 + params_sold_per / 100))
        //合格率

        item.__pass_per = numberToFixed(pass_per)
        // console.log('>>>>><<<<<<<<<<<<<<<nononnononononon',item.no,item.__pass_per)
        item.pass_per = numberToFixed(pass_per)
        //产量
        item.pass_sold_num = checkZero(item.pass_per) ? 0 : item.sold_num / item.pass_per
        //含税价格
        item.price_add_tax = numberToFixed(item.price_add_tax * (1 + params_tax_per / 100))
        //不含税价格
        item.price = numberToFixed(item.price_add_tax / (1 + item.tax_per / 100))
        //不含税价格 
        item.price_come_in = numberToFixed(item.price_add_tax / (1 + item.tax_per / 100) * item.sold_num)
        // console.log('--',item.customerNo)
        // console.log('含税价',item.price_add_tax)
        // console.log('不含税价格',item.price)
        //成本
        const cost_price = (stockDataInfo.price || 0)
        const sold_num = item.sold_num || 0
        const diffStock = sold_num * 1 - stock * 1


        // 结存数量
        item.stock = stock
        //结存金额
        item.money = money
        item.cost_price = cost_price || 0
        //所需*产品实际完工入库量
        item.need_stock = diffStock <= 0 ? 0 : diffStock

        newYcStockData.push(item)
        newYcStockData_obj[customerNo] = item


        sum_sold_num += 1 * item.sold_num || 0
        sum_pass_sold_num += 1 * item.pass_sold_num || 0
        sum_stock += 1 * item.stock || 0
        sum_stock_money += 1 * item.money || 0
        sum_price_come_in += 1 * item.price_come_in || 0
        sum_cost_price += 1 * item.cost_price || 0
    })
    // console.log('>>>>>>>>>>>>>>>> newYcStockData_obj ///////////////////////////')
    // console.log(newYcStockData_obj)
    // 不含税单价
    const avg_price_no_tax_unit_money = checkZero(sum_sold_num) ? 0 : sum_price_come_in / sum_sold_num
    // //均 单位成本(期初)
    const avg_unit_price_before = checkZero(sum_stock) ? 0 : sum_stock_money / sum_stock
    //均 合格率 =  销量合计 / 产量合计
    const avg_pass_per_before = checkZero(sum_stock) ? 0 : sum_sold_num / sum_stock
    //产品合格率
    const avg_pass_per_product = checkZero(sum_pass_sold_num) ? 0 : sum_sold_num / sum_pass_sold_num;


    return () => {
        return {
            newYcStockData,
            newYcStockData_obj,

            //总收入
            sum_price_come_in: numberToFixed(sum_price_come_in),

            ////不含税单价
            avg_price_no_tax_unit_money: numberToFixed(avg_price_no_tax_unit_money),
            ////总销量
            sum_sold_num: numberToFixed(sum_sold_num),
            //产量总数
            sum_pass_sold_num: numberToFixed(sum_pass_sold_num),
            //总存
            sum_stock: numberToFixed(sum_stock),
            //总结存金额
            sum_stock_money: numberToFixed(sum_stock_money),
            //均 单位成本(期初)
            avg_unit_price_before: numberToFixed(avg_unit_price_before),
            //均 合格率 =  销量合计 / 产量合计
            avg_pass_per_before: numberToFixed(avg_pass_per_before),
            //产品合格率
            avg_pass_per_product: numberToFixed(avg_pass_per_product),
            //总成本
            sum_cost_price: numberToFixed(sum_cost_price),

            //计算产量
            fncCalProductNum: (num) => {
                const diffSoldNum = num - sum_stock
                let stock = num
                if (diffSoldNum > 0) {
                    //需要完成的销量
                    let productNum = checkZero(avg_pass_per_before) ? 0 : diffSoldNum / avg_pass_per_before
                    stock = productNum * 1 + sum_stock * 1
                }
                return numberToFixed(stock)
            },
            //汇总函数
            sumField: (field) => {
                let money = 0
                newYcStockData.map(function (item) {
                    money += (item[field] ?? 0) * 1
                })
                return numberToFixed(money)
            },
            //销量转产出
            fncColSpanPriceTableList: fncColSpanPriceTableList,
            //销量转产出总额
            fncColSpanPrice: (tableList, soldNum, serialProductCustomerMind = {}, plan = '') => {
                // const serialProductCustomerMind= state.tools2_excelData_tableData_customerNo_mind
                let totalMoney = 0;
                // let soldNumToProductNum = checkZero(product_per) ? soldNum: soldNum/product_per
                fncColSpanPriceTableList(tableList, soldNum, serialProductCustomerMind, plan).map((item) => {
                    totalMoney += item.costPriceTotal_bd * 1
                })

                // console.log(soldNum,totalSoldNum,totalMoneyCurrent,totalMoneyBegin,totalMoney,totalMoney/totalSoldNum)
                return totalMoney
            }
        }
    }
}

// 单位产品工序用量与标准成本表
export const structData_serialStand = (step2SerialStand_data, {
    params_tax_per = 0,
    params_sold_per = 0,
    params_cost_per = 0,
    params_pass_per = 0,
}) => {

    let serialGroupList = {}

    let colSpan = {}
    step2SerialStand_data.map((item, index) => {
        const no = item.no
        const serial_id_no = item.serial_id_no
        if (!(no in colSpan)) {
            colSpan[no] = {
                index: [],
                group: [],
                total_stand_cost: 0,
            }
        }

        colSpan[no].index.push(index)
        colSpan[no].group.push(item.serial_id)
        colSpan[no].total_stand_cost = numberToFixed(colSpan[no].total_stand_cost * 1 + item.stand_cost * 1)

        if (!(serial_id_no in serialGroupList)) {
            serialGroupList[serial_id_no] = item;
        }

    })

    //计算
    for (let no in colSpan) {
        colSpan[no].total_stand_cost = numberToFixed(colSpan[no].total_stand_cost * (1 + params_cost_per / 100))
    }

    return () => {
        return {
            colSpan,
            serialGroupList
        }
    }
}


//非序列化
export const structData_NSerial = (step2NSerial_data) => {
    let totalCost = 0;
    let totalCostNo = 0;
    step2NSerial_data.map((item) => {
        totalCost += item.amount * 1
        if (item.money_center_name == '否') {
            totalCostNo += item.amount * 1
        }
    })
    return () => {
        return {
            step2NSerial_data,
            totalCost,
            totalCostNo
        }

    }
}


//序列化产品
export const structData_serialProduction = (excelData, serial_groupObj, ycStock_customerNoBoj, paramPer = {}) => {
    //单位变动成本整体加成(%)
    const params_cost_per = ((paramPer.params_cost_per || 0) / 100)
    //统计总 -
    const summaryObject = {
        // 工序（组）计划产量
        serial_stand_product_num: 0,
        //产品计划产量
        min_div_product_num: 0,
        //工序（组）计划产量折合产品产量
        product_num_div_serial_stand: 0,
        //产品计划入库量
        stand_in_stock_div: 0,
        //产品计划产量
        pass_per: 100,
        //期初余额
        balance_start: 0,
        //本期投入-料
        deadline_material: 0,
        //本期投入-工
        deadline_worker: 0,
        //    本期投入-费 
        deadline_money: 0,
        //期末余额
        balance_end: 0,
        //净投入额
        amount: 0,
        //工序（组）实际产量
        serial_product_num: 0,
        //工序（组）实际产量折合产品产量
        product_stand_actuality_product_num: 0,
        //剩余工序产量
        sold_num_reduce: 0,
        //产品入库量
        serial_stand_product_num_stand_actuality_min: 0,
        //入库量金额
        trans_in_stock_money: 0,
        //销量
        sold_num: 0,
        //销售净需求
        need_stock: 0,
        //需销售的产品期初库存
        stock: 0,
        //期初转销售出库量
        sold_num_begin: 0,
        //入库转销售出库量
        // need_stock: 0,
        //需销售的产品期末结存量
        sold_num_end: 0,
        //期初转销售成本（FIFO）
        stock_cost_to_sold_money_begin: 0,
        //完工转销售成本（FIFO）
        stock_cost_to_sold_money_current: 0,
        //转销售成本合计（FIFO）
        sold_money_fifo: 0,
        //转销售成本合计（FIFO）
        stock_cost_to_sold_money_current_actuality: 0,
        //转销售成本合计（FIFO）
        sold_money_fifo_actuality: 0,
        //转销售成本合计（AVCO）
        price_avco: 0,
        //边际收益计划
        slide_money_plan: 0,
        //边际收益实际
        slide_money: 0,
        //收入
        sum_price_come_in: 0,


    }
    //原数据
    // const excelData = info[0] || []
    //单位产品工序用量与标准成本表
    const serial_group = serial_groupObj.serialGroupList || {}
    //生产工序步骤
    const serial_groupObjColSpan = serial_groupObj.colSpan || {}
    //预测表
    const ycStock_customerNo = ycStock_customerNoBoj.newYcStockData_obj || {}
    //实际最小产量
    const serial_stand_product_num_stand_actuality_min_obj = {}

    //导入表的数据生产工序 - 主要看工序是否完整
    //构造数据
    let excelDataNoToProductGroup = {}
    excelData.map((item) => {
        const no = item.no;
        if (!(no in excelDataNoToProductGroup)) {
            excelDataNoToProductGroup[no] = []
        }
        if (excelDataNoToProductGroup[no].indexOf(item.serial_id) === -1) {
            excelDataNoToProductGroup[no].push(item.serial_id)
        }

    })

    const tableData = excelData.map((item) => {
        const no = item.no;
        const serial_groupObjColSpanInfo = serial_groupObjColSpan[no] || {}
        //验证是否是完整的工序
        item.isFullSerial = checkArrayIdentical(serial_groupObjColSpanInfo.group || [], excelDataNoToProductGroup[no] || [])
        //客户分组
        const customerNo = item.customerNo || ''
        const ycStock_customerNoInfo = ycStock_customerNo[customerNo] || {}

        // 产品编码Id + 工序组 ID
        const serial_id_no = item.serial_id_no || ''
        if (!(customerNo in serial_stand_product_num_stand_actuality_min_obj)) {
            serial_stand_product_num_stand_actuality_min_obj[customerNo] = {}
        }
        if (!(serial_id_no in serial_stand_product_num_stand_actuality_min_obj[customerNo])) {
            serial_stand_product_num_stand_actuality_min_obj[customerNo][serial_id_no] = {
                serial_stand_product_num_stand_actuality: 0,
            }
        }


        //净投入额
        const amount = item.amount
        // 标准成本表
        const serial_stand_info = serial_group[serial_id_no] ?? {}
        //工序标准单位变动成本
        const serial_stand_cost = numberToFixed(serial_stand_info.cost || 0)
        //产品标准用量
        const serial_stand_stand = numberToFixed(serial_stand_info.stand || 0)
        item.serial_stand_stand = serial_stand_stand
        item.serial_stand_cost = serial_stand_cost

        //工序（组）实际产量折合产品产量 = 工序实际产量/该产品标准用量
        item.product_stand_actuality_product_num = numberToFixed(checkZero(item.serial_stand_stand) ? 0 : item.serial_product_num / item.serial_stand_stand);
        //工序（组）标准产量 （未考虑工序废品影响，含废品）
        const serial_stand_product_num = (checkZero(serial_stand_cost) ? 0 : numberToFixed(amount / serial_stand_cost))
        item.serial_stand_product_num = serial_stand_product_num

        //工序（组）计划产量折合产品产量 = 工序标准产量/产品标准用量
        item.product_num_div_serial_stand = checkZero(serial_stand_stand) ? 0 : numberToFixed(serial_stand_product_num / serial_stand_stand)
        //实际折合产品产量
        const serial_stand_product_num_stand_actuality = checkZero(serial_stand_stand) ? 0 : numberToFixed(item.serial_product_num / serial_stand_stand)
        item.serial_stand_product_num_stand_actuality = serial_stand_product_num_stand_actuality
        serial_stand_product_num_stand_actuality_min_obj[customerNo][serial_id_no].serial_stand_product_num_stand_actuality = serial_stand_product_num_stand_actuality

        //产品标准产量
        item.min_product_num = 0
        //产品计划产量
        item.min_div_product_num = 0

        //产品客户合格率
        item.pass_per = !item.isFullSerial ? 100 : numberToFixed(ycStock_customerNoInfo.pass_per || 0)
        item.__pass_per = !item.isFullSerial ? 100 : numberToFixed(ycStock_customerNoInfo.__pass_per || 0)
        //产品标准完工入库量（标准产量×合格率）
        item.stand_in_stock = 0
        //销售净需求（同中间表1)
        item.need_stock = numberToFixed(ycStock_customerNoInfo.need_stock || 0)
        //标准产出是否满足销售
        item.is_need_stock = 'Y'
        //工序实际单位成本
        const serial_stand_actuality_cost = checkZero(item.serial_product_num) ? 0 : numberToFixed(amount / item.serial_product_num)
        item.serial_stand_actuality_cost = serial_stand_actuality_cost
        //工序完工成本
        item.serial_stand_actuality_cost_total = checkZero(item.serial_product_num) ? 0 : numberToFixed(serial_stand_actuality_cost * item.serial_product_num)
        //当期完工产品 实际单位变动成本
        item.serial_stand_actuality_cost_total_current = 0
        //产品标准成本
        item.product_stand_cost_total = 0;



        return item
    })

    let colSpan = {}
    tableData.map((item, index) => {
        const no = item.no;
        const customerNo = item.customerNo
        const serial_id_no = item.serial_id_no || ''
        const serial_groupObjColSpanInfo = serial_groupObjColSpan[no] || {}
        const ycStock_customerNoInfo = ycStock_customerNo[customerNo] || {}




        const stand_actuality_min_obj = serial_stand_product_num_stand_actuality_min_obj[customerNo] || {}
        let serial_stand_product_num_stand_actuality_min = 0;
        for (let key in stand_actuality_min_obj) {
            const minInfo = stand_actuality_min_obj[key]
            const minValue = minInfo.serial_stand_product_num_stand_actuality
            if (serial_stand_product_num_stand_actuality_min === 0 || serial_stand_product_num_stand_actuality_min * 1 > minValue * 1) {
                serial_stand_product_num_stand_actuality_min = !item.isFullSerial ? 0 : minValue
            }
        }

        if (!(customerNo in colSpan)) {
            const sold_num = ycStock_customerNoInfo.sold_num || 0
            //不含税价
            const price = ycStock_customerNoInfo.price || 0
            const pass_per = ycStock_customerNoInfo.pass_per || 0
            const stock = ycStock_customerNoInfo.stock || 0
            const __stock = ycStock_customerNoInfo.__stock || 0
            const serial_stand_product_num_stand_actuality_min_ceil = numberToFixed(serial_stand_product_num_stand_actuality_min);
            const sold_num_begin = stock * 1 > sold_num * 1 ? sold_num : stock;
            const stock_cost_price_begin = ycStock_customerNoInfo.cost_price || 0;
            const stock_cost_to_sold_money_begin = numberToFixed(sold_num_begin * stock_cost_price_begin);

            const need_stock_plan = numberToFixed(checkZero(pass_per) ? 0 : item.need_stock / (pass_per / 100))
            const product_stand_cost_total = serial_groupObjColSpanInfo.total_stand_cost || 0;
            // console.log('>>>>><<<<<<<<<<<<<<<nononnononononon',customerNo,ycStock_customerNoInfo.__pass_per,item.__pass_per)

            colSpan[customerNo] = {
                isFullSerial: item.isFullSerial,
                index: [],
                //产品标准产量
                min_product_num: item.serial_stand_product_num_stand,
                stand_in_stock: item.serial_stand_product_num_stand * item.pass_per,
                is_need_stock: 'Y',
                min_div_product_num: !item.isFullSerial ? 0 : item.product_num_div_serial_stand,
                stand_in_stock_div: !item.isFullSerial ? 0 : (item.product_num_div_serial_stand * item.pass_per),
                is_need_stock_div: 'Y',
                pass_per: item.pass_per,
                __pass_per: ycStock_customerNoInfo.__pass_per,
                need_stock: item.need_stock,
                //含税价格
                price_add_tax: ycStock_customerNoInfo.price_add_tax,
                //税率
                tax_per: ycStock_customerNoInfo.tax_per,
                //计划需求
                need_stock_plan: need_stock_plan,
                //实际需求
                need_stock_actuality: item.need_stock,
                //收入
                sum_price_come_in: numberToFixed(sold_num * price),
                sold_num: sold_num,
                stock: stock,

                sold_num_begin: sold_num_begin,
                //期初金额
                sold_num_begin_cost_money: numberToFixed(stock * sold_num_begin),

                sold_num_end: numberToFixed(stock * 1 + serial_stand_product_num_stand_actuality_min_ceil * 1 - sold_num),
                //投入成本
                cost_sum: 0,
                //期初存货单位成本
                cost_begin: 0,
                serial_stand_actuality_cost_total_current: 0,
                //计划产品单位成本
                product_stand_cost_total: serial_groupObjColSpanInfo.total_stand_cost || 0,
                //实际完工产品产量（实际入库，不含废品）
                serial_stand_product_num_stand_actuality_min: serial_stand_product_num_stand_actuality_min_ceil,
                //期初存货单位成本
                stock_cost_price_begin: stock_cost_price_begin,
                //期初存货转销售
                stock_cost_to_sold_money_begin: stock_cost_to_sold_money_begin,
                //转入存货变动金额
                trans_in_money: 0,
                //产品入库金额
                trans_in_stock_money: 0,
                //当期完工转销售
                stock_cost_to_sold_money_current: 0,
                //转入销售成本（FIFO）
                sold_money_fifo: 0, //计划
                sold_money_fifo_actuality: 0, //实际
                //加权单位变动成本
                cost_avco: 0,
                //转入销售成本（AVCO）
                price_avco: 0,
                price_avco_plan: 0,
                //期末余额
                sum_balance_end: 0,

                //边际收益-实际=不含税价*销量
                slide_money: 0,
                //边际收益-计划
                slide_money_plan: 0,
            }
        }

        colSpan[customerNo].index.push(index)


        // colSpan[customerNo].slide_money = numberToFixed(ycStock_customerNoBoj.price * colSpan[customerNo].sold_num_begin)
        colSpan[customerNo].cost_sum = numberToFixed(colSpan[customerNo].cost_sum * 1 + item.balance_start * 1 + item.deadline_material * 1 + item.deadline_worker * 1 + item.deadline_money * 1)

        colSpan[customerNo].serial_stand_actuality_cost_total_current = !item.isFullSerial ? 0 : numberToFixed(colSpan[customerNo].serial_stand_actuality_cost_total_current * 1 + item.serial_stand_actuality_cost * item.serial_stand_stand * (1 + params_cost_per))
        // colSpan[customerNo].product_stand_cost_total =  numberToFixed(colSpan[customerNo].product_stand_cost_total * 1 + item.serial_stand_cost * item.serial_stand_stand)

        colSpan[customerNo].trans_in_money = numberToFixed(colSpan[customerNo].trans_in_money * 1 + item.serial_stand_actuality_cost * item.serial_stand_stand)
        colSpan[customerNo].trans_in_stock_money = numberToFixed(colSpan[customerNo].serial_stand_actuality_cost_total_current * colSpan[customerNo].serial_stand_product_num_stand_actuality_min)

        colSpan[customerNo].stock_cost_to_sold_money_current_actuality = numberToFixed(colSpan[customerNo].serial_stand_actuality_cost_total_current * colSpan[customerNo].need_stock)

        colSpan[customerNo].stock_cost_to_sold_money_current = numberToFixed(colSpan[customerNo].product_stand_cost_total * colSpan[customerNo].need_stock_plan)

        colSpan[customerNo].sold_money_fifo = numberToFixed(colSpan[customerNo].stock_cost_to_sold_money_current * 1 + colSpan[customerNo].stock_cost_to_sold_money_begin * 1)

        colSpan[customerNo].sold_money_fifo_actuality = numberToFixed(colSpan[customerNo].stock_cost_to_sold_money_current_actuality * 1 + colSpan[customerNo].stock_cost_to_sold_money_begin * 1)

        colSpan[customerNo].sum_balance_end = numberToFixed(colSpan[customerNo].sum_balance_end * 1 + item.balance_end * 1)


        //期初耗费
        const cost_price_begin = numberToFixed(colSpan[customerNo].stock_cost_price_begin * colSpan[customerNo].stock);
        //实际完工耗费
        const cost_price_actuality = numberToFixed(colSpan[customerNo].serial_stand_actuality_cost_total_current * colSpan[customerNo].serial_stand_product_num_stand_actuality_min);
        //消耗数量
        const product_num_begin_actuality = colSpan[customerNo].stock * 1 + colSpan[customerNo].serial_stand_product_num_stand_actuality_min * 1
        const cost_avco = checkZero(product_num_begin_actuality) ? 0 : numberToFixed((cost_price_begin * 1 + cost_price_actuality * 1) / product_num_begin_actuality)
        colSpan[customerNo].cost_avco = cost_avco
        colSpan[customerNo].price_avco = numberToFixed(cost_avco * (colSpan[customerNo].sold_num_begin * 1 + colSpan[customerNo].need_stock * 1))
        colSpan[customerNo].slide_money = numberToFixed(colSpan[customerNo].sum_price_come_in - colSpan[customerNo].sold_money_fifo_actuality)
        colSpan[customerNo].slide_money_plan = numberToFixed(colSpan[customerNo].sum_price_come_in - colSpan[customerNo].sold_money_fifo)

        if (colSpan[customerNo].min_product_num * 1 > item.serial_stand_product_num_stand * 1) {
            //取最小值
            item.min_product_num = item.serial_stand_product_num_stand
            colSpan[customerNo].min_product_num = item.min_product_num
            const stand_in_stock = numberToFixed((item.min_product_num * item.pass_per) / 100)
            colSpan[customerNo].stand_in_stock = stand_in_stock
            colSpan[customerNo].is_need_stock = stand_in_stock * 1 > item.need_stock * 1 ? 'Y' : 'N'
        }

        if (colSpan[customerNo].min_div_product_num * 1 > item.product_num_div_serial_stand * 1) {
            // console.log('>>>>>取最小值>>>>>>>')
            // console.log(item.min_div_product_num)
            //取最小值
            item.min_div_product_num = item.product_num_div_serial_stand
            colSpan[customerNo].min_div_product_num = !item.isFullSerial ? 0 : item.min_div_product_num
            const stand_in_stock_div = !item.isFullSerial ? 0 : numberToFixed((item.product_num_div_serial_stand * item.pass_per) / 100)
            colSpan[customerNo].stand_in_stock_div = !item.isFullSerial ? 0 : stand_in_stock_div
            colSpan[customerNo].is_need_stock_div = !item.isFullSerial ? 'Y' : (stand_in_stock_div * 1 > item.need_stock * 1 ? 'Y' : 'N')
        }
    })

    //再次计算
    const serialProductDataObj = {};
    const newTableData = []
    tableData.map(function (tableItem) {
        const item = { ...tableItem }
        const no = item.no
        const customerNo = item.customerNo

        const serial_id_no = item.serial_id_no || ''

        if (!(no in serialProductDataObj)) {
            serialProductDataObj[no] = {}
        }



        const colSpanInfo = colSpan[customerNo] || {}
        const serial_stand_product_num_stand_actuality_min = colSpanInfo.serial_stand_product_num_stand_actuality_min || 0
        // const trans_in_money = colSpanInfo.trans_in_money;//numberToFixed(serial_stand_product_num_stand_actuality_min*colSpanInfo.serial_stand_actuality_cost_total_current)


        // colSpanInfo.trans_in_money = trans_in_money
        const stand_in_stock = colSpanInfo.stand_in_stock || 0
        const need_stock = colSpanInfo.need_stock || 0
        item.serial_stand_product_num_stand_actuality_min = !item.isFullSerial ? 0 : serial_stand_product_num_stand_actuality_min
        //工序折合产品转存货金额
        item.serial_product_in_stock_money = numberToFixed(item.serial_stand_actuality_cost * serial_stand_product_num_stand_actuality_min * item.serial_stand_stand)
        //工序折合产品转销售成本（FIFO)
        item.product_num_sold_fifo = numberToFixed(item.serial_stand_actuality_cost * (1 + params_cost_per) * item.need_stock * item.serial_stand_stand)
        // console.log(item.customerNo,'实际', item.cost_name, item.need_stock * item.serial_stand_stand,item.serial_stand_actuality_cost,params_cost_per, roundToDotNumber(1+ params_cost_per),item.serial_stand_actuality_cost*(1+params_cost_per/100))
        //工序折合产品转销售成本（FIFO) 标准 
        item.product_num_sold_fifo_stand = numberToFixed(item.serial_stand_cost * (1 + params_cost_per) * (item.need_stock / item.pass_per * 100) * item.serial_stand_stand)
        // console.log(item.customerNo,'标准', item.cost_name, (item.need_stock/item.pass_per*100) * item.serial_stand_stand,item.serial_stand_cost,(1+params_cost_per/100),item.serial_stand_cost*(1+params_cost_per/100))
        //工序折合产品转销售成本（AVCO)
        item.product_num_sold_avco = ''
        //折算实际产品产量（未取整，完工入库量)
        item.product_num_actuality = checkZero(item.serial_stand_stand) ? 0 : numberToFixed(item.serial_product_num / item.serial_stand_stand)


        //剩余工序产量
        const sold_num_reduce = numberToFixed(item.serial_product_num - serial_stand_product_num_stand_actuality_min * item.serial_stand_stand)
        item.sold_num_reduce = sold_num_reduce
        //剩余工序实际单位成本
        item.sold_num_reduce_cost = numberToFixed(checkZero(sold_num_reduce) ? 0 : item.balance_end / sold_num_reduce)


        //产品完整度判断（略）
        item.product_is_whole = 'Y'

        //满足销售所需
        item.is_sold_state = 'Y'



        serialProductDataObj[no][serial_id_no] = item
        //实际完工产品产量（实际入库，不含废品）
        //取最小值
        if (serialProductDataObj[no][serial_id_no].product_num_actuality === 0 || serialProductDataObj[no][serial_id_no].product_num_actuality * 1 > item.product_num_actuality * 1) {
            serialProductDataObj[no][serial_id_no].product_num_actuality = item.product_num_actuality
            serialProductDataObj[no][serial_id_no].is_sold_state = item.product_num_actuality * 1 > item.need_stock * 1 ? 'Y' : 'N'
        }
        newTableData.push(item)



        //汇总
        summaryObject.serial_stand_product_num = numberToFixed(summaryObject.serial_stand_product_num * 1 + item.serial_stand_product_num * 1);
        summaryObject.product_num_div_serial_stand = numberToFixed(summaryObject.product_num_div_serial_stand * 1 + item.product_num_div_serial_stand * 1);
        summaryObject.balance_start = numberToFixed(summaryObject.balance_start * 1 + item.balance_start * 1);
        summaryObject.deadline_material = numberToFixed(summaryObject.deadline_material * 1 + item.deadline_material * 1);
        summaryObject.deadline_worker = numberToFixed(summaryObject.deadline_worker * 1 + item.deadline_worker * 1);
        summaryObject.deadline_money = numberToFixed(summaryObject.deadline_money * 1 + item.deadline_money * 1);
        summaryObject.balance_end = numberToFixed(summaryObject.balance_end * 1 + item.balance_end * 1);
        summaryObject.amount = numberToFixed(summaryObject.amount * 1 + item.amount * 1);
        summaryObject.serial_product_num = numberToFixed(summaryObject.serial_product_num * 1 + item.serial_product_num * 1);
        summaryObject.product_stand_actuality_product_num = numberToFixed(summaryObject.product_stand_actuality_product_num * 1 + item.product_stand_actuality_product_num * 1);
        summaryObject.sold_num_reduce = numberToFixed(summaryObject.sold_num_reduce * 1 + item.sold_num_reduce * 1);

    })




    // console.log(serialProductDataObj)
    // this.colSpan = colSpan
    // this.$store.commit('updateTools2State', { field: 'tools2_excelData_tableData_customerNo_mind', value: colSpan })
    // this.$store.commit('updateTools2State', { field: 'tools2_excelData_tableData', value: newTableData })
    // this.$store.commit('updateTools2State', { field: 'tools2_serialProductDataObj', value: serialProductDataObj })

    for (let key in colSpan) {
        const colSpanInfo = colSpan[key];
        summaryObject.min_div_product_num = numberToFixed(summaryObject.min_div_product_num * 1 + colSpanInfo.min_div_product_num * 1)
        summaryObject.stand_in_stock_div = numberToFixed(summaryObject.stand_in_stock_div * 1 + colSpanInfo.stand_in_stock_div * 1)
        summaryObject.pass_per = numberToFixed(checkZero(summaryObject.min_div_product_num) ? 0 : summaryObject.stand_in_stock_div / summaryObject.min_div_product_num * 100)
        summaryObject.serial_stand_product_num_stand_actuality_min = numberToFixed(summaryObject.serial_stand_product_num_stand_actuality_min * 1 + colSpanInfo.serial_stand_product_num_stand_actuality_min * 1)
        summaryObject.trans_in_stock_money = numberToFixed(summaryObject.trans_in_stock_money * 1 + colSpanInfo.trans_in_stock_money * 1)
        summaryObject.sold_num = numberToFixed(summaryObject.sold_num * 1 + colSpanInfo.sold_num * 1)
        // summaryObject.need_stock = numberToFixed( summaryObject.need_stock*1 + colSpanInfo.need_stock*1 )
        summaryObject.stock = numberToFixed(summaryObject.stock * 1 + colSpanInfo.stock * 1)
        summaryObject.sold_num_begin = numberToFixed(summaryObject.sold_num_begin * 1 + colSpanInfo.sold_num_begin * 1)
        summaryObject.need_stock = numberToFixed(summaryObject.need_stock * 1 + colSpanInfo.need_stock * 1)
        summaryObject.sold_num_end = numberToFixed(summaryObject.sold_num_end * 1 + colSpanInfo.sold_num_end * 1)
        summaryObject.stock_cost_to_sold_money_begin = numberToFixed(summaryObject.stock_cost_to_sold_money_begin * 1 + colSpanInfo.stock_cost_to_sold_money_begin * 1);
        summaryObject.stock_cost_to_sold_money_current = numberToFixed(summaryObject.stock_cost_to_sold_money_current * 1 + colSpanInfo.stock_cost_to_sold_money_current * 1);
        summaryObject.sold_money_fifo = numberToFixed(summaryObject.sold_money_fifo * 1 + colSpanInfo.sold_money_fifo * 1);
        summaryObject.sold_money_fifo_actuality = numberToFixed(summaryObject.sold_money_fifo_actuality * 1 + colSpanInfo.sold_money_fifo_actuality * 1);
        summaryObject.price_avco = numberToFixed(summaryObject.price_avco * 1 + colSpanInfo.price_avco * 1);
        summaryObject.stock_cost_to_sold_money_current_actuality = numberToFixed(summaryObject.stock_cost_to_sold_money_current_actuality * 1 + colSpanInfo.stock_cost_to_sold_money_current_actuality * 1);
        summaryObject.slide_money_plan = numberToFixed(summaryObject.slide_money_plan * 1 + colSpanInfo.slide_money_plan * 1);
        summaryObject.slide_money = numberToFixed(summaryObject.slide_money * 1 + colSpanInfo.slide_money * 1);
        summaryObject.sum_price_come_in = numberToFixed(summaryObject.sum_price_come_in * 1 + colSpanInfo.sum_price_come_in * 1);

    }


    return () => {
        return {
            newTableData,
            colSpan,
            summaryObject,
            serialProductDataObj,
        }
    }
}



//根据产量计算表格数据
export const fncColSpanPriceTableList = (tableList, soldNum, serialProductCustomerMind = {}, plan = '') => {
    return tableList.map((item) => {
        const itemResult = fncColSpanPriceItem(item, soldNum, serialProductCustomerMind, plan)
        // console.log(item.customerNo,itemResult)
        //变动总成本
        item.costPriceTotal_bd = itemResult.total
        return item;
    })
}


export const fncColSpanPriceItem = (item, soldNum, serialProductCustomerMind = {}, plan = '') => {
    let totalMoneyBegin = 0;
    let totalMoneyCurrent = 0;
    let totalSoldNum = 0;

    const sold_num_weight = item.sold_num_weight; //销售权重

    const sold_num = soldNum * sold_num_weight || 0;
    // totalSoldNum += sold_num*1 ;
    const customerNo = item.customerNo
    const serialProductCustomerMindInfo = serialProductCustomerMind[customerNo] || {}
    const stock = (item.stock || 0) * 1;
    let cost_price = serialProductCustomerMindInfo.stock_cost_price_begin || 0; //期初成本
    const money = item.money; //期初存货销售成本
    const pass_per = item.pass_per; //期初存货销售成本
    //期初成本
    let money_begin = 0

    //当期成本
    //实际
    let price_current = serialProductCustomerMindInfo.serial_stand_actuality_cost_total_current || 0;
    switch (plan) {
        case 'plan': //计划
            price_current = serialProductCustomerMindInfo.product_stand_cost_total || 0
            break;
        case 'stand': //标准
            price_current = serialProductCustomerMindInfo.product_stand_cost_total || 0
            break;
    }
    //计算是否需要额外生产
    const diffProductNum = sold_num - stock;
    let productNum = 0
    // let needProductNum = 0;
    if (diffProductNum > 0) { //需要额外生产
        money_begin = stock * cost_price
        // needProductNum = diffProductNum
        productNum = numberToFixed(diffProductNum)
        //标准和计划的需要 除以产品合格率。
        if (['stand', 'plan'].indexOf(plan) > -1) {
            productNum = numberToFixed(diffProductNum / (pass_per / 100))
        }
        totalSoldNum += productNum * 1 + stock;
    } else {
        money_begin = sold_num * cost_price
        totalSoldNum += soldNum * 1;
    }

    totalMoneyBegin += money_begin * 1
    //当前成本
    const moneyCurrent = numberToFixed(productNum * price_current)
    totalMoneyCurrent += moneyCurrent * 1
    // if(soldNum=='332'){
    //     console.log(`${customerNo}，期初成本${cost_price} ;;库存${stock};实际销量${soldNum}；权重${sold_num_weight};单个产品销量${sold_num};库存价格${money_begin}；成本数量；${productNum}；成本单价${price_current};成本总价格${moneyCurrent}`)
    // }

    return {
        totalSoldNum,
        soldNum,
        sold_num_weight,
        sold_num,
        cost_price,
        price_current,
        totalMoneyBegin,
        totalMoneyCurrent,
        total: totalMoneyBegin * 1 + moneyCurrent * 1
    }
}