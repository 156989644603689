<template>
    <a-modal title="修改密码" :width="640" :open="visible" :confirmLoading="loading" :footer="null" @ok="handleSubmit"
        @cancel="close">
        <a-spin :spinning="loading">
            <div class="tips">
                <div>企业修改账户密码</div>
                <div>需通过发送邮箱的方式申请方可通过审核样本案例。（具体文字要求由翎谋提供）</div>
            </div>
            <a-form ref="formRef" :rules="rules" :model="form" layout="vertical">
                <a-form-item label="手机号" v-bind="validateInfos.phone">
                    <a-input v-model:value="form.phone" addon-before="+86" disabled></a-input>
                </a-form-item>
                <a-form-item label="验证码" v-bind="validateInfos.verify" name="verify">
                    <a-input-group compact>
                        <a-input v-model:value="form.verify" placeholder="验证码" style="width: calc(100% - 102px)">
                        </a-input>
                        <SmsCaptcha :phone="form.phone" type="change-password" />
                    </a-input-group>
                </a-form-item>

                <a-form-item label="新密码" v-bind="validateInfos.password" name="password">
                    <a-input-password v-model:value="form.password" placeholder="输入新密码" />
                </a-form-item>
                <a-form-item label="确认新密码" v-bind="validateInfos.confirm_password" name="confirm_password">
                    <a-input-password v-model:value="form.confirm_password" placeholder="请重新输入新密码" />
                </a-form-item>


                <!-- 保存按钮 -->
                <a-form-item>
                    <a-button class="submit" type="primary" block @click="handleSubmit">确认修改</a-button>
                </a-form-item>
            </a-form>


        </a-spin>
    </a-modal>
</template>

<script lang="ts" setup>
import { ref, reactive, computed, onMounted, toRaw } from 'vue'
import baseService from '@/utils/http/axios'

import { Form, message } from 'ant-design-vue';
import SmsCaptcha from '@/components/tools/SmsCaptcha.vue'

import { useStore } from 'vuex'
const store = useStore()


const loading = ref(false)
const visible = ref(false)

const open = () => {
    visible.value = true
}
const close = () => {
    visible.value = false
}

defineExpose({
    open
})


// 表单
const useForm = Form.useForm
const formRef = ref();
const form = reactive({
    phone: store.state.user.phone,
    verify: '',
    password: '',
    confirm_password: ''

});
const formItemLayout = {
    labelCol: { span: 3 },
};
const formTailLayout = {
    labelCol: { span: 3 },
    wrapperCol: { offset: 3 },
};

const handlePasswordLevel = (rule, value) => {
    if (value === '') {
        return Promise.resolve('请使用三种或三种以上8-16位字符组合')
    }
    if (value.length < 8) {
        return Promise.reject(new Error('密码强度不够'))
    }
    return Promise.resolve()
}
const handlePasswordCheck = (rule, value) => {
    if (value === '') {
        return Promise.resolve('请使用三种或三种以上8-16位字符组合');
    } else if (value !== form.password) {
        return Promise.reject('两次密码不一致');
    }
    return Promise.resolve()
}
const rules = reactive({
    phone: [
        { required: true, message: '请输入手机号' },
        { validateTrigger: ['change', 'blur'] }
    ],
    verify: [
        { required: true, message: '请输入短信验证码' },
        { validateTrigger: ['change', 'blur'] }
    ],
    password: [
        { required: true, message: '请使用三种或三种以上8-16位字符组合' },
        { validator: handlePasswordLevel },
        { validateTrigger: ['change', 'blur'] }
    ],
    confirm_password: [
        { required: true, message: '请使用三种或三种以上8-16位字符组合' },
        { validator: handlePasswordCheck },
        { validateTrigger: ['change', 'blur'] }
    ],
})


const { validate, validateInfos } = useForm(form, rules)
const handleSubmit = () => {
    validate().then((res) => {
        baseService.post('/api/user/modPassword', form).then(res => {
            message.success(res.msg)
            setTimeout(() => {
                formRef.value.resetFields();
                close()
            }, 700);
        }).catch(err => {
            console.error(err)
        })
    })
};

</script>
<style lang="less" scoped>
.ant-form-text {
    font-size: 14px;
    font-weight: bold;
}

.submit {
    height: 40px;
}
</style>
