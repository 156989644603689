<template>
        <check-outlined :style="{ fontSize: '18px', color: '#08c' }"
            v-if="content == '√'" />
        <close-outlined :style="{ fontSize: '18px', color: '#f00' }"
            v-else-if="content == '×'" />
        <template v-else>
            {{content}}
        </template>
</template>

<script>
import { CloseOutlined, CheckOutlined } from '@ant-design/icons-vue';
export default {
    props:{
        content:{
            type:String,
            default:''
        },
        
    },
    components:{
        CloseOutlined,CheckOutlined
    },
    computed:{
       
    }
}
</script>

<style>

</style>