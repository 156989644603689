<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Register',
})
</script>
<template>
    <div class="main user-layout-register">
        <div class="user-register-box">
            <div class="register-header">
                <h4>找回密码</h4>
            </div>
            <div class="register-form">
                <a-form id="formRegister" layout="vertical" :model="form">

                    <a-form-item v-bind="validateInfos.phone">
                        <a-input :bordered="false" v-model:value="form.phone" placeholder="输入手机号">
                            <template #prefix>
                                <div class="ant-input-label addon">
                                    中 国 +86
                                </div>
                            </template>
                        </a-input>
                    </a-form-item>

                    <a-form-item v-bind="validateInfos.verify">

                        <a-input-group>
                            <a-input :bordered="false" v-model:value="form.verify" placeholder="输入验证码"
                                style="width: calc(100% - 100px)">
                                <template #prefix>
                                    <div class="ant-input-label">
                                        手机验证码
                                    </div>
                                </template>
                            </a-input>
                            <a-button type="primary" class="getCaptcha" :disabled="state.smsSendBtn"
                                @click.stop.prevent="getCaptcha">{{
                                    !state.smsSendBtn && '获取验证码' || (state.time + ' s') }}</a-button>
                        </a-input-group>
                    </a-form-item>

                    <a-form-item v-bind="validateInfos.password">
                        <a-input-password :bordered="false" @click="handlePasswordInputClick"
                            placeholder="请使用英文、符号、数字以上8-16位字符组合" v-model:value="form.password">
                            <template #prefix>
                                <div class="ant-input-label">
                                    设 置 密 码
                                </div>
                            </template>
                        </a-input-password>
                    </a-form-item>

                    <a-form-item v-bind="validateInfos.password2">
                        <a-input-password :bordered="false" placeholder="请再次输入密码" v-model:value="form.password2">
                            <template #prefix>
                                <div class="ant-input-label">
                                    确 认 密 码
                                </div>
                            </template>
                        </a-input-password>
                    </a-form-item>

                    <a-button type="primary" htmlType="submit" class="register-button" @click.stop.prevent="handleSubmit">确
                        认</a-button>

                    <router-link class="login" :to="{ path: '/user/login' }">返回登录</router-link>
                </a-form>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup name="Register">
import { ref, reactive, computed } from 'vue'
import baseService from '@/utils/http/axios'

import { Form } from 'ant-design-vue'
import { scorePassword } from '@/utils/util'
import { MailOutlined } from '@ant-design/icons-vue'
import { useGetCaptcha } from '@/store/modules/helper'
import { useRouter } from 'vue-router'

const router = useRouter()

const useForm = Form.useForm

// 表单相关
const form = reactive({
    phone: '',
    verify: '',
    type: 'forget',
    password: '',
    password2: '',
    checkNick: false
})
const handlePasswordLevel = (rule, value) => {
    if (value === '') {
        return Promise.resolve('请使用三种或三种以上8-16位字符组合')
    }
    if (value.length >= 6) {
        // if (scorePassword(value) >= 30) {
        //     state.level = 1
        // }
        // if (scorePassword(value) >= 60) {
        //     state.level = 2
        // }
        // if (scorePassword(value) >= 80) {
        //     state.level = 3
        // }
    } else {
        // state.level = 0
        return Promise.reject(new Error('密码强度不够'))

    }
    // state.passwordLevel = state.level
    // state.percent = state.level * 33
    return Promise.resolve()
}
const handlePasswordCheck = (rule, value) => {
    if (value === '') {
        return Promise.resolve('密码需为8-16位字符，包含英文、符号、数字');
    } else if (value !== form.password) {
        return Promise.reject('两次密码不一致');
    }
    return Promise.resolve()
}

const rules = reactive({
    account: [
        { required: true, message: '请填写账户名' },
        { validateTrigger: ['change', 'blur'] }
    ],
    password: [
        { required: true, message: '密码需为8-16位字符，包含英文、符号、数字' },
        { validator: handlePasswordLevel },
        { validateTrigger: ['change', 'blur'] }
    ],
    password2: [
        { required: true, message: '请使用三种或三种以上8-16位字符组合' },
        { validator: handlePasswordCheck },
        { validateTrigger: ['change', 'blur'] }
    ],
})
const state = reactive({
    time: 60,
    level: 0,
    smsSendBtn: false,
    percent: 10,
    progressColor: '#FF0000'
})

const getCaptcha = (e) => {
    useGetCaptcha(e, validate, state, form)
}

const { validate, validateInfos } = useForm(form, rules)
const handleSubmit = () => {
    validate().then((res) => {
        baseService.post('/api/account/forgetPwd', {
            phone: form.phone,
            verify: form.verify,
            password: form.password,
        }).then(res => {
            router.push({ path: '/user/login' })
            console.log(res)
        }).catch(err => {
            console.error(err)
        })
        // router.push({ name: 'registerResult', params: { ...form } })
    })
}

</script>
<style lang="less">
.user-register {
    &.error {
        color: #ff0000;
    }

    &.warning {
        color: #ff7e05;
    }

    &.success {
        color: #52c41a;
    }
}

.user-layout-register {
    .ant-input-group-addon:first-child {
        background-color: #fff;
    }

    .register-form {
        .ant-form-item {
            border: 1px solid #d9d9d9;

            &.noStyle {
                border: none;
            }

            .ant-select {
                flex: 1;
                width: 100%;
                padding: 8px 0px;

                .ant-select-selector {
                    padding: 0px 16px;
                }
            }

            .ant-input-group {
                display: flex;
                align-items: center;
            }

            .ant-input-group-compact {
                padding: 3px 0px;
            }

            .ant-input {
                padding: 12px 16px;
            }
        }
    }

}
</style>
<style lang="less" scoped>
.user-layout-register {
    width: 1200px;
    min-height: 100%;
    padding: 2.5% 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .user-register-box {
        width: 100%;
        padding: 12px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .register-steps {
            width: 100%;
            padding: 24px 16%;
            border-bottom: 1px solid #d9d9d9;
        }

        .register-header {
            width: 640px;
            padding: 24px 0px 0px;

            h4 {
                font-size: 24px;
            }
        }

        .register-form {
            width: 640px;
            padding: 48px 0px 24px;
        }


    }

    .ant-input-label {
        min-width: 78px;
        text-align: justify;
        height: 100%;
        display: flex;
        align-items: center;

        &.addon {
            border-right: 1px solid #d9d9d9;
        }
    }

    .ant-input-group-label {
        min-width: 72px;
        text-align: justify;
        padding: 0px 0px 0px 11px;
        margin-inline-end: 4px;
    }

    .getCaptcha {
        display: block;
        width: 200px;
        height: 54px;
    }

    .register-button {
        width: 100%;
        height: 54px;
        font-size: 16px;
    }

    .login {
        float: right;
        line-height: 40px;
    }
}
</style>
