<template>
  <div class="profit-table">
    <a-row :gutter="8" class="four-table">
      <a-col :sm="24" :lg="12" v-if="getHasToolAuth('tools1_analysis_yunying')">
        <div class="table-children">
          <div class="subtitle">盈利指标</div>
          <a-table class="cross-table" :columns="columns_operate" :data-source="tableData_operate" :pagination="pagination" size="small" bordered>
            <template #bodyCell="{ column, text, record, index }">
              <template v-if="column.dataIndex === 'before_data'">
                <template v-if="text.indexOf('%') > -1">
                  {{ text }}
                </template>
                <template v-else>
                  {{ $dealThousands(text) }}
                </template>
              </template>
              <template v-if="column.dataIndex === 'after_data'">
                <template v-if="text.indexOf('%') > -1">
                  {{ text }}
                </template>
                <template v-else>
                  {{ $dealThousands(text) }}
                </template>
              </template>
            </template>
          </a-table>
        </div>
      </a-col>
      <a-col :sm="24" :lg="12" v-if="getHasToolAuth('tools1_analysis_cost')">
        <div class="table-children">
          <div class="subtitle">成本结构类指标</div>
          <a-table
            class="cross-table"
            :columns="columns_cost"
            :data-source="tableData_cost"
            :pagination="pagination"
            size="small"
            bordered
            @resizeColumn="
              (w, col) => {
                col.width = w
              }
            "
          >
          </a-table>
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="8">
      <a-col :sm="24" :lg="12" v-if="getHasToolAuth('tools1_analysis_side')">
        <div class="table-children">
          <div class="subtitle">边际类指标</div>
          <a-table
            class="cross-table"
            :columns="columns_bj"
            :data-source="tableData_bj"
            :pagination="pagination"
            size="small"
            bordered
            @resizeColumn="
              (w, col) => {
                col.width = w
              }
            "
          >
          </a-table>
        </div>
      </a-col>
      <a-col :sm="24" :lg="12" v-if="getHasToolAuth('tools1_analysis_risk')">
        <div class="table-children">
          <div class="subtitle">风险类指标</div>
          <a-table
            class="cross-table"
            :columns="columns_risk"
            :data-source="tableData_risk"
            :pagination="pagination"
            size="small"
            bordered
            @resizeColumn="
              (w, col) => {
                col.width = w
              }
            "
          >
          </a-table>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { checkZero, numberToFixed, roundToDotNumber } from '@/utils/util'
import { mapGetters } from 'vuex'
export default {
  name: 'SolutionExtraPlan_FXZB',
  props: {
    isPrint: {
      type: Boolean,
      default: false
    },
    planId: {
      type: [Number, String],
      default: 0
    },
    hasActualityData: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    }
  },
  data() {
    const body_width = document.body.offsetWidth
    return {
      bodyWidth: body_width,
      // bodyWidth2: document.body.offsetWidth,
      maoli: '0.00',
      maoli_after: '0.00',
      maoli_per: '0.00',
      maoli_per_after: '0.00',
      manager_rate: '0.00',
      manager_rate_after: '0.00',
      manager_rate_per: '0.00',
      manager_rate_per_after: '0.00',
      rate_cost_per_before: '0.00',
      rate_cost_per_after: '0.00',
      rate_fixed_per_before: '0.00',
      rate_fixed_per_after: '0.00',
      bj_per: '0.00',
      bj_per_after: '0.00',
      bj_cost_per: '0.00',
      bj_cost_per_after: '0.00',
      risk_per: '0.00',
      risk_after: '0.00'
      // columns_operate: [
      //   { title: '', dataIndex: 'name', align: 'center', width: 150 },
      //   { title: '计划', dataIndex: 'before_data', align: 'right',width:110 },
      //   { title: '实际', dataIndex: 'after_data', align: 'right',width:110 }
      // ],
      // columns_cost: [
      //   { title: '', dataIndex: 'name', align: 'center', width: 150 },
      //   { title: '计划', dataIndex: 'rate_before', align: 'right',width:110 },
      //   { title: '实际', dataIndex: 'rate_after', align: 'right',width:110 }
      // ],
      // columns_bj: [
      //   { title: '', dataIndex: 'name', align: 'center', width: 150 },
      //   { title: '计划', dataIndex: 'bj_before', align: 'right',width:110 },
      //   { title: '实际', dataIndex: 'bj_after', align: 'right',width:110 }
      // ],
      // columns_risk: [
      //   { title: '', dataIndex: 'name', align: 'center', width: 150 },
      //   { title: '计划', dataIndex: 'risk_before', align: 'right',width:110 },
      //   { title: '实际', dataIndex: 'risk_after', align: 'right',width:110 }
      // ]
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),

    columns_operate() {
      let data = [
        { title: '', dataIndex: 'name', align: 'left', width: this.bodyWidth < 1441 ? 100 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 115 : 115 },
        { title: '计划', dataIndex: 'before_data', align: 'right', width: this.bodyWidth < 1441 ? 85 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 85 : 85 },
        { title: '实际', dataIndex: 'after_data', align: 'right', width: this.bodyWidth < 1441 ? 85 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 85 : 85 }
      ]
      if (!this.hasActualityData) {
        data = [
          { title: '', dataIndex: 'name', align: 'left', width: this.bodyWidth < 1441 ? 85 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 110 : 110 },
          { title: '计划', dataIndex: 'before_data', align: 'right', width: this.bodyWidth < 1441 ? 110 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 130 : 130 }
        ]
      }
      return data
    },
    columns_cost() {
      let data = [
        { title: '', dataIndex: 'name', align: 'left', width: this.bodyWidth < 1441 ? 100 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 115 : 115 },
        { title: '计划', dataIndex: 'rate_before', align: 'right', width: this.bodyWidth < 1441 ? 85 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 85 : 85 },
        { title: '实际', dataIndex: 'rate_after', align: 'right', width: this.bodyWidth < 1441 ? 85 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 85 : 85 }
      ]
      if (!this.hasActualityData) {
        data = [
          { title: '', dataIndex: 'name', align: 'left', width: this.bodyWidth < 1441 ? 85 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 150 : 150 },
          { title: '计划', dataIndex: 'rate_before', align: 'right', width: 110 }
        ]
      }
      return data
    },
    columns_bj() {
      let data = [
        { title: '', dataIndex: 'name', align: 'left', width: 115 },
        { title: '计划', dataIndex: 'bj_before', align: 'right', width: 85 },
        { title: '实际', dataIndex: 'bj_after', align: 'right', width: 85 }
      ]
      if (!this.hasActualityData) {
        data = [
          { title: '', dataIndex: 'name', align: 'left', width: 115 },
          { title: '计划', dataIndex: 'bj_before', align: 'right', width: 110 }
        ]
      }
      return data
    },
    columns_risk() {
      let data = [
        { title: '', dataIndex: 'name', align: 'left', width: 115 },
        { title: '计划', dataIndex: 'risk_before', align: 'right', width: 85 },
        { title: '实际', dataIndex: 'risk_after', align: 'right', width: 85 }
      ]
      if (!this.hasActualityData) {
        data = [
          { title: '', dataIndex: 'name', align: 'left', width: 150 },
          { title: '计划', dataIndex: 'risk_before', align: 'right', width: 110 }
        ]
      }
      return data
    },

    tableData_operate() {
      return [
        // 毛利(原导入) = 原边际贡献 -  制造环节固定成本 - 制造环节对应销量下的混合成本
        { name: '毛利', before_data: this.maoli, after_data: this.maoli_after },
        // 毛利(加成后) = 原边际贡献 -  制造环节固定成本 - 制造环节对应销量下的混合成本
        { name: '毛利率', before_data: this.maoli_per, after_data: this.maoli_per_after },
        { name: '管理利润', before_data: this.manager_rate, after_data: this.manager_rate_after },
        { name: '管理利润率', before_data: this.manager_rate_per, after_data: this.manager_rate_per_after }
      ]
    },

    tableData_cost() {
      return [
        { name: '变动成本', rate_before: this.rate_cost_per_before, rate_after: this.rate_cost_per_after },
        { name: '固定成本', rate_before: this.rate_fixed_per_before, rate_after: this.rate_fixed_per_after }
      ]
    },
    tableData_bj() {
      return [
        { name: '边际贡献率', bj_before: this.bj_per, bj_after: this.bj_per_after },
        { name: '变动成本率', bj_before: this.bj_cost_per, bj_after: this.bj_cost_per_after }
      ]
    },
    tableData_risk() {
      return [{ name: '经营杠杆系数', risk_before: this.risk_per, risk_after: this.risk_after }]
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onWidthChange)
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onWidthChange)
  },
  methods: {
    onWidthChange() {
      this.bodyWidth = document.body.offsetWidth
      // console.log('-----------------this.bodyWidth', this.bodyWidth, this.body_width)
    },
    createTableData(options, pageObj) {
      // console.log(tableData)
      // console.log(standColSpan)
      // console.log(serialProductionColSpan)
      const serialProductDataObj = options.colSpan || {}
      const ycStock_customerNo = options.ycStock_customerNo || {}
      const summaryObject = options.summaryObject || {}
      //加成后的数据
      // console.log('>>>>>>>>>>>> 测试数据 createTableData <<<<<<<<<<<<<<')
      // console.log(serialProductDataObj)

      const upPer = 0.01
      const serialProductDataObjNew = {}
      let __sum_price_come_in = 0
      let __sum_sold_money_fifo = 0
      let __sold_money_fifo_actuality = 0
      for (let key in serialProductDataObj) {
        const serialProductDataObjItem = { ...serialProductDataObj[key] }
        // console.log('--',key)
        const ycStock_customerNoInfo = ycStock_customerNo[key] || {}
        const sold_num = numberToFixed(serialProductDataObjItem.sold_num * (1 + upPer))
        const stock = numberToFixed(serialProductDataObjItem.stock)
        const diffStock = numberToFixed(sold_num - stock)
        const pass_per = numberToFixed(serialProductDataObjItem.pass_per / 100, 3)
        // console.log(pass_per)
        serialProductDataObjItem.price_add_tax = numberToFixed(serialProductDataObjItem.price_add_tax * (1 + upPer))
        serialProductDataObjItem.price = numberToFixed(serialProductDataObjItem.price_add_tax / (1 + serialProductDataObjItem.tax_per / 100))
        serialProductDataObjItem.sold_num = sold_num
        serialProductDataObjItem.price_come_in = numberToFixed(serialProductDataObjItem.price * serialProductDataObjItem.sold_num)
        serialProductDataObjItem.sold_num_begin = stock * 1 > sold_num * 1 ? sold_num : stock
        serialProductDataObjItem.need_stock = diffStock * 1 <= 0 ? 0 : diffStock
        serialProductDataObjItem.stock_cost_to_sold_money_begin = numberToFixed(serialProductDataObjItem.sold_num_begin * ycStock_customerNoInfo.cost_price)
        serialProductDataObjItem.stock_cost_to_sold_money_current = numberToFixed((serialProductDataObjItem.need_stock / pass_per) * serialProductDataObjItem.product_stand_cost_total)
        serialProductDataObjItem.stock_cost_to_sold_money_current_actuality = numberToFixed(serialProductDataObjItem.need_stock * serialProductDataObjItem.serial_stand_actuality_cost_total_current)

        serialProductDataObjItem.sold_money_fifo = numberToFixed(serialProductDataObjItem.stock_cost_to_sold_money_begin * 1 + serialProductDataObjItem.stock_cost_to_sold_money_current * 1)
        serialProductDataObjItem.sold_money_fifo_actuality = numberToFixed(
          serialProductDataObjItem.stock_cost_to_sold_money_begin * 1 + serialProductDataObjItem.stock_cost_to_sold_money_current_actuality * 1
        )
        // console.log('--', key ,serialProductDataObjItem.stock_cost_to_sold_money_begin,serialProductDataObjItem.product_stand_cost_total,serialProductDataObjItem.need_stock/pass_per,serialProductDataObjItem.need_stock,pass_per,serialProductDataObjItem.sold_money_fifo)
        __sum_price_come_in += serialProductDataObjItem.price_come_in * 1 || 0
        __sum_sold_money_fifo += serialProductDataObjItem.sold_money_fifo * 1 || 0
        __sold_money_fifo_actuality += serialProductDataObjItem.sold_money_fifo_actuality * 1 || 0
        serialProductDataObjNew[key] = serialProductDataObjItem
        // console.log(
        //   serialProductDataObjItem.price_add_tax,
        //   serialProductDataObjItem.price,
        //   serialProductDataObjItem.sold_num,
        //   serialProductDataObjItem.price_come_in,
        //   serialProductDataObjItem.sold_num_begin,
        //   serialProductDataObjItem.need_stock,
        //   serialProductDataObjItem.stock_cost_to_sold_money_begin,
        //   serialProductDataObjItem.stock_cost_to_sold_money_current,
        //   serialProductDataObjItem.stock_cost_to_sold_money_current_actuality,
        //   serialProductDataObjItem.sold_money_fifo,
        //   serialProductDataObjItem.sold_money_fifo_actuality,
        // )
        // console.log(serialProductDataObjItem)
      }

      // const {fncResult_stockAndYc,fncResult_serialStand,fncResult_SerialProduction} = pageObj.fncSettingOptions({
      //   params_tax_per : 1 + pageObj.params_tax_per*1,
      //   params_sold_per : 1 + pageObj.params_sold_per*1,
      //   params_cost_per : 0 + pageObj.params_cost_per*1,
      //   params_pass_per : 0 + pageObj.params_pass_per*1,
      // }, true)
      // const serialProductDataObj_after = fncResult_SerialProduction.colSpan||{}
      // const summaryObject_after = fncResult_SerialProduction.summaryObject||{}

      //边际贡献
      let sum_slide_money = summaryObject.slide_money_plan
      let sum_slide_money_new = summaryObject.slide_money
      //边际贡献
      let sum_slide_money_after = numberToFixed(__sum_price_come_in - __sum_sold_money_fifo)
      let sum_slide_money_after_new = numberToFixed(__sum_price_come_in - __sold_money_fifo_actuality)
      // console.log('>>>>>>>>>>><<<<<///////////////')
      // console.log(__sum_price_come_in)
      // console.log(__sum_sold_money_fifo)
      // console.log(__sold_money_fifo_actuality)
      // console.log(sum_slide_money_after)
      // console.log(sum_slide_money_after_new)
      const value = options.tableList || []
      //总销量
      const soldNum = options.soldNum || 0
      //合格率
      const avg_pass_per_product = options.avg_pass_per_product || 0
      //不含税单价
      const avg_price_no_tax_unit_money = options.avg_price_no_tax_unit_money || 0
      //总收入
      const sum_price_come_in = options.sum_price_come_in

      //固定
      const fixedMoney = options.fixedMoney || 0
      //成本中心否的费用
      const fixedNoMoney = options.fixedNoMoney || 0
      //中间表
      const colSpan = options.colSpan || {}

      const fncColSpanPrice = (moduleName) => options.fncColSpanPrice(value, soldNum, colSpan, moduleName)

      // const fncColSpanPriceNew = (moduleName)=>options.fncColSpanPrice(
      //   fncResult_stockAndYc.newYcStockData || [],
      //   fncResult_stockAndYc.sum_sold_num,
      //   fncResult_SerialProduction.colSpan || {},
      //   moduleName
      // )
      const sum_price_come_in_new = __sum_price_come_in

      // console.log('>>>>>>>>>>>>>>>1111111111111111111111111111111111111111111111111>')

      //变动成本（计划）
      const sum_cost_plan = fncColSpanPrice('plan')
      // const sum_cost_plan_new = fncColSpanPriceNew('plan') ;
      //变动成本（实际）
      const sum_actuality_plan = fncColSpanPrice('actuality')
      // const sum_actuality_plan_new = fncColSpanPriceNew('actuality') ;
      // console.log( sum_price_come_in , fixedNoMoney , sum_cost_plan)

      //毛利(计划) = 销量*不含税价格- 成本中心否的费用 - 成本计算
      this.maoli = roundToDotNumber(sum_price_come_in - fixedNoMoney - sum_cost_plan)
      //毛利(实际) = 销量*不含税价格- 成本中心否的费用 - 成本计算
      this.maoli_after = roundToDotNumber(sum_price_come_in - fixedNoMoney - sum_actuality_plan)

      //毛利率(计划) =  毛利/总收入
      this.maoli_per = roundToDotNumber(checkZero(sum_price_come_in) ? 0 : (this.maoli / sum_price_come_in) * 100) + '%'
      //毛利率(实际) =  毛利/总收入
      this.maoli_per_after = roundToDotNumber(checkZero(sum_price_come_in) ? 0 : (this.maoli_after / sum_price_come_in) * 100) + '%'

      //管理利润(计划) = 销量*不含税价格- 固定的费用 - 成本计算
      const manager_rate = numberToFixed(sum_slide_money - fixedMoney) //numberToFixed( sum_price_come_in - fixedMoney - sum_cost_plan )
      const manager_rate_new = numberToFixed(sum_slide_money_new - fixedMoney) //numberToFixed( sum_price_come_in_new - fixedMoney - sum_cost_plan_new )

      this.manager_rate = manager_rate

      this.manager_rate_after = manager_rate_new

      //管理利润(实际) = 销量*不含税价格- 固定的费用 - 成本计算
      const manager_rate_after = numberToFixed(sum_slide_money_after - fixedMoney) //numberToFixed( sum_price_come_in - fixedMoney - sum_actuality_plan )
      const manager_rate_after_new = numberToFixed(sum_slide_money_after_new - fixedMoney) //numberToFixed( sum_price_come_in_new - fixedMoney - sum_actuality_plan_new )

      //管理利润率(计划) = 管理利润/总收入
      this.manager_rate_per = roundToDotNumber(checkZero(sum_price_come_in) ? 0 : (this.manager_rate / sum_price_come_in) * 100) + '%'
      //管理利润率(实际) = 管理利润/总收入
      this.manager_rate_per_after = roundToDotNumber(checkZero(sum_price_come_in) ? 0 : (this.manager_rate_after / sum_price_come_in) * 100) + '%'

      //变动成本 = 0
      const total_sum_cost_plan = sum_cost_plan * 1 + fixedMoney * 1
      const total_sum_actuality_plan = sum_actuality_plan * 1 + fixedMoney * 1
      this.rate_cost_per_before = roundToDotNumber(checkZero(total_sum_cost_plan) ? 0 : (sum_cost_plan / total_sum_cost_plan) * 100) + '%'
      this.rate_cost_per_after = roundToDotNumber(checkZero(total_sum_actuality_plan) ? 0 : (sum_actuality_plan / total_sum_actuality_plan) * 100) + '%'

      // const cost_per_total = this.rate_cost_per_before*1 + this.rate_cost_per_after*1
      // const cost_per_before_per = checkZero(cost_per_total) ? 0 :  this.rate_cost_per_before / cost_per_total;
      //固定成本 = 0
      this.rate_fixed_per_before = roundToDotNumber(checkZero(total_sum_cost_plan) ? 0 : (fixedMoney / total_sum_cost_plan) * 100) + '%'
      //根据占比计算
      this.rate_fixed_per_after = roundToDotNumber(checkZero(total_sum_actuality_plan) ? 0 : (fixedMoney / total_sum_actuality_plan) * 100) + '%'

      //边际贡献
      const sum_plan_bj_cost = sum_price_come_in - sum_cost_plan
      const sum_actuality_bj_cost = sum_price_come_in - sum_actuality_plan

      //边际贡献率 = 原边际贡献 / 原销售收入
      this.bj_per = roundToDotNumber(checkZero(sum_price_come_in) ? 0 : (sum_plan_bj_cost / sum_price_come_in) * 100) + '%'
      this.bj_per_after = roundToDotNumber(checkZero(sum_price_come_in) ? 0 : (sum_actuality_bj_cost / sum_price_come_in) * 100) + '%'
      //变动成本率 = 原总变动成本 / 原销售收入
      this.bj_cost_per = roundToDotNumber(checkZero(sum_price_come_in) ? 0 : (sum_cost_plan / sum_price_come_in) * 100) + '%'
      this.bj_cost_per_after = roundToDotNumber(checkZero(sum_price_come_in) ? 0 : (sum_actuality_plan / sum_price_come_in) * 100) + '%'

      //经营杠杆系数 = 管理利润/收入
      // this.risk_per =  numberToFixed( checkZero(sum_price_come_in) ? 0 : this.manager_rate/sum_price_come_in*100 )+'%'
      // this.risk_after = numberToFixed( checkZero(sum_price_come_in) ? 0 : this.manager_rate_after/sum_price_come_in*100 )+'%'

      //经营杠杆系数 = 管理利润/收入
      //含总收入(计划)
      const risk_sum_price_come_in = sum_price_come_in
      //含总收入(计划新)
      const risk_sum_price_come_in_new = sum_price_come_in_new
      //收入差异(计划新)
      const risk_sum_price_come_in_diff = risk_sum_price_come_in_new - risk_sum_price_come_in
      //收入百分比
      const risk_sum_price_come_in_per = checkZero(risk_sum_price_come_in) ? 0 : (risk_sum_price_come_in_diff / risk_sum_price_come_in) * 100

      //计划
      //管理利润
      const risk_manager_rate = manager_rate
      //管理利润（二次）
      const risk_manager_rate_after = manager_rate_after
      //管理利润差异
      const risk_manager_rate_diff = risk_manager_rate_after - manager_rate
      //管理利润差异比例
      const risk_manager_rate_per = checkZero(risk_manager_rate) ? 0 : (risk_manager_rate_diff / risk_manager_rate) * 100

      //实际

      //管理利润
      const risk_actuality_manager_rate = manager_rate_new
      //管理利润（二次）
      const risk_actuality_manager_rate_after = manager_rate_after_new
      //管理利润差异
      const risk_actuality_manager_rate_diff = risk_actuality_manager_rate_after - risk_actuality_manager_rate
      //管理利润差异比例
      const risk_actuality_manager_rate_per = checkZero(risk_actuality_manager_rate) ? 0 : (risk_actuality_manager_rate_diff / risk_actuality_manager_rate) * 100

      //收入
      // console.log('>>>>>>>>>>>> 收入 <<<<<<<<<<<<<<<')
      // console.log('收入基准',risk_sum_price_come_in)
      // console.log('二次加成后收入',risk_sum_price_come_in_new)
      // console.log('收入差异',risk_sum_price_come_in_diff)
      // console.log('收入变化率',risk_sum_price_come_in_per)

      // console.log('>>>>>>>>>>>> 计划 <<<<<<<<<<<<<<<')

      // console.log('加成后管理利润（基准）',risk_manager_rate)
      // console.log('fifo',summaryObject.sold_money_fifo)
      // console.log('fifo 加成后',__sum_sold_money_fifo)
      // console.log('二次加成后边际收益',sum_slide_money_after)
      // console.log('固定成本',fixedMoney)
      // console.log('二次加成后管理利润',risk_manager_rate_after)
      // console.log('管理利润差异',risk_manager_rate_diff)
      // console.log('管理利润变化率',risk_manager_rate_per)

      // console.log('>>>>>>>>>>>> 实际 <<<<<<<<<<<<<<<')
      // console.log('加成后管理利润（基准）',risk_actuality_manager_rate)
      // console.log('fifo',summaryObject.sold_money_fifo_actuality)
      // console.log('二次加成后边际收益',sum_slide_money_after_new)
      // console.log('fifo 加成后',__sold_money_fifo_actuality)
      // console.log('固定成本',fixedMoney)
      // console.log('二次加成后管理利润',risk_actuality_manager_rate_after)
      // console.log('管理利润差异',risk_actuality_manager_rate_diff)
      // console.log('管理利润变化率',risk_actuality_manager_rate_per)

      this.risk_per = roundToDotNumber(checkZero(risk_sum_price_come_in_per) ? 0 : Math.abs(risk_manager_rate_per / risk_sum_price_come_in_per))
      this.risk_after = roundToDotNumber(checkZero(risk_sum_price_come_in_per) ? 0 : Math.abs(risk_actuality_manager_rate_per / risk_sum_price_come_in_per))
    }
  }
}
</script>
