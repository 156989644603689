<template>
    <div class="my-pagination">
        <a-pagination v-model:current="current" :total="total" @change="onChange" simple v-model:pageSize="pageSize"/>
    </div>
</template>

<script>
export default {
    props:{
        total:{
            type:Number,
            default:0
        },
        pageSizeOptions:{
            type:Array,
            default:[15,30,50,100]
        },
        
    },
    data(){
        return {
            current:1,
            pageSize:10,
        }
    },
    methods:{
        onChange(page, pageSize){
            this.$emit('change',page, pageSize)
        },
    }
}
</script>

<style>

</style>