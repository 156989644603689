<template>
    <div class="import scroll">

        <div class="body">
            <div class="vabTabsBox">
                <div class="title">{{detail.name}}-导入文件</div>
            </div>
            <div class="children">
                <a-alert message="请下载 服务器导入模板（.xlsx）然后按照模板的要求依次顺序上传文件" type="info" />

                <div class="import-box">
                    <a-form>
                        <a-form-item label="对比方式">
                            <!-- 
                                保本后促销 是指对满足条件量以后的数据起作用；
                                足量促销 是指对满足特定条件数量的下的所有可能量起作用（通杀），注意可能有多个特定触发条件
                             -->
                            <a-radio-group v-model:value="base" button-style="solid">
                                <a-radio-button value="">足量促销</a-radio-button>
                                <a-radio-button value="full">保本促销</a-radio-button>
                            </a-radio-group>
                        </a-form-item>
                       
                    </a-form>
                    <a-row :gutter="48">
                        <a-col class="gutter-row" :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                            <ToolsImportSelect
                                type="five"
                                :item="stepCVP"
                                :index="0"
                                @handleHisFile="handleSelectPlan('stepCVP')"
                            />
                        </a-col>
                      
                        <a-col class="gutter-row" :xs="24" :sm="12" :md="12" :lg="8" :xl="6" v-if="base==='full'">
                            <ToolsImport
                                localParse
                                type="five"
                                :item="stepImport_full"
                                introField="import_intro3"
                                :index="1"
                                :uploadData="getStepImport_full"
                                @handleChange="handleChange($event,stepImport_full)"
                                @handleHisFile="handleHisFile(stepImport_full,'stepImport_full')"
                                :beforeUpload="(file,fileList)=>onBeforeUpload(file,fileList,stepImport_full)"
                            />
                        </a-col> 
                        <a-col class="gutter-row" :xs="24" :sm="12" :md="12" :lg="8" :xl="6" v-else>
                            <ToolsImport
                                localParse
                                type="five"
                                :item="stepImport"
                                :index="1"
                                :uploadData="getStepImport"
                                @handleChange="handleChange($event,stepImport)"
                                @handleHisFile="handleHisFile(stepImport,'stepImport')"
                                :beforeUpload="(file,fileList)=>onBeforeUpload(file,fileList,stepImport)"
                            />
                        </a-col> 
                    </a-row>                    
                </div>

                <div class="import-btns">
                    <!-- <OpenVipBtn content="获取基础方案" v-if="!getHasToolAuth('tools4_base')" /> 
                    <a-button type="primary" :loading="createPlanLoading" @click="handleSavePlan('base','基础方案')" v-else> 获取基础方案 </a-button> -->
                        
                    <OperateLimit type="primary" :loading="createPlanLoading" 
                        @doClick="handleSavePlan('base','基础方案')"
                        useTool="cjjtsj"
                        > 获取基础方案 </OperateLimit>
                </div>
            </div>
        </div>

        <HistoryFiles ref="historyFiles" @selectItem="onSelectItem"/>
        <planSelect ref="planSelect" @selectItem="onPlanSelectItem"/>
    </div>
</template>

<script>
import { message,notification } from 'ant-design-vue';

import planSelect from '../components/planSelect.vue'
import HistoryFiles from '../components/HistoryFiles.vue'
import ToolsImport from '@/components/ToolsImport.vue'
import ToolsImportSelect from '@/components/ToolsImportSelect.vue'
import OpenVipBtn from '@/components/OpenVipBtn.vue'
import { mapGetters } from 'vuex'

import { baseURL, download } from '@/utils/util';

export default{
    components:{
        HistoryFiles,ToolsImport,OpenVipBtn,ToolsImportSelect,planSelect
    },
    data(){
        return {
            detail:{},
            stepCVP:{name:'CVP工具I（基于BOM）已保存方案',temppath:'', temp:'step5-cvp',file:{}, verifyIndex:-1,uploading:''},
            stepImport:{name:'足量促销',temppath:'tools5/足量促销.xlsx', temp:'step5-ladder',file:{}, verifyIndex:0,uploading:''},
            stepImport_full:{name:'保本促销',temppath:'tools5/保本促销.xlsx', temp:'step5-ladder',file:{}, verifyIndex:0,uploading:''},
          
            base:'',
            selectItemIndex:0,

            createPlanLoading:false,
            selectPlanItem:'',
        }
    },
    computed:{
        ...mapGetters(['getHasToolAuth']),
        getStepImport(){
            let params = {base:this.base}
            if(this.stepImport.file && this.stepImport.file.id){
                params[this.stepImport.temp] = this.stepImport.file.id
            }
            return params
        },
        getStepImport_full(){
            let params = {base:this.base}
            if(this.stepImport_full.file && this.stepImport_full.file.id){
                params[this.stepImport_full.temp] = this.stepImport_full.file.id
            }
            return params
        },
    
    },
    watch:{
        
    },
    created(){

        this.getData()
    },
    methods:{
        getData(){
            this.$network('/api/tools/typeDetail',{type:'five'}).then(res=>{
                const data =res.data||{}
                this.detail = data.detail||{}
            })
        },
        onBeforeUpload(file,fileList,item){
            if(!this.stepCVP.file || !this.stepCVP.file.id ){
                notification.error({  description:  `请先选择【${this.stepCVP.name}】`});
                return Promise.reject()
            }
            return Promise.resolve()
        },
        handleChange(info,item){
            const status = info.file.status;
            item.uploading = status

            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                const response = info.file.response||{}
                const responseData = response.data||{}
                console.log(response)
                if(!response.code){
                    notification.error({  description: response.msg});
                    // message.error(response.msg);
                }else{
                    item.file = responseData.info||{}
                    notification.success({  description: `${item.name} 上传成功.`});
                    // message.success(`${info.file.name} file uploaded successfully.`);
                }
                
            } else if (status === 'error') {
                notification.error({  description: `${item.name} 上传失败.`});
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
        //选中了哪个文件
        onPlanSelectItem(item){
            item.filename = item.name||''
            this[this.selectPlanItem].file = item
        },
        //选中了哪个文件
        onSelectItem(file){
            // this.stepImport.file = file
            this[this.selectItemIndex].file = file
        },
        handleSelectPlan(item){
            console.log(item)
            this.selectPlanItem = item
            this.$refs.planSelect.open({tool_type:'one'})
        },
        // 历史文件
        handleHisFile(item,index){
            this.selectItemIndex = index
            this.$refs.historyFiles.open(item)
        },
        handleSavePlan(planType){
            const params = []
            if(!this.stepCVP.file || !this.stepCVP.file.id){
                return notification.error({  description:  `请先选择【${this.stepCVP.name}】`});
            }else{
                params.push({type:this.stepCVP.temp,id:this.stepCVP.file.id})
            }
            if(this.base==='full'){
                if(!this.stepImport_full.file || !this.stepImport_full.file.id){
                    return notification.error({  description:  `请先选择【${this.stepImport_full.name}】`});
                }else{
                    params.push({type:this.stepImport_full.temp,id:this.stepImport_full.file.id})
                }

            }else{
                if(!this.stepImport.file || !this.stepImport.file.id){
                    return notification.error({  description:  `请先选择【${this.stepImport.name}】`});
                }else{
                    params.push({type:this.stepImport.temp,id:this.stepImport.file.id})
                }

            }

            this.createPlan(params,planType)
        },
        createPlan(params,planType){
            this.createPlanLoading = true
            this.$network('/api/tools/createPlan',{base:this.base,plan:params,tool_type:'five'}).then(res => {
                const data = res.data || {}
                this.$router.push({path:'/dashboard/cloud5/solution',query:{id:data.id,planType:planType}})
                
            }).catch(err => {
                
            }).finally(()=>{
                this.createPlanLoading = false
            })
        },
    }
}



</script>
<style lang="less" scoped>

</style>
