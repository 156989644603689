<template></template>

<script>
import { numberToFixed, debounce, checkZero, tableSortFnc, tableDefaultPage, tableDefautlLine } from '@/utils/util'
export default {
  data() {
    return {
      pagination: tableDefaultPage(),
      //其它计算字段
      otherSortCal:{},

      topSumObj:{
        upSortData:[],
      },
      tableNumberField:[],
    }
  },
  computed:{
    getTableData(){
      return []
    }
  },
  methods: {
    handleInitLoadData(){ //vxe-table表格数据展示问题

    },
    getTableSum(field) {
      let number = 0
      this.getTableData.map((item) => {
        number += item[field] * 1
      })
      return numberToFixed(number)
    },
    showMoreTableList() {
      if (this.pagination === false) {
        this.pagination = tableDefaultPage()
      } else {
        this.pagination = false

      }
      this.calOtherSum(this.topSumObj.upSortData, false)

      this.$nextTick(()=>{
        this.handleInitLoadData()

      })
    },
    otherSortCalItemFnc(key,item){
        return 0;
    },
    otherSortCalResultFnc(key,item){
        return 0;
    },
    sortTableFilterItem(e){
      return true;
    },
    //计算其它加个
    calOtherSum(currentDataSource, recordUpData = true) {
      const totalObj = {}
      for (let key in this.topSumObj) {
        if (key == 'upSortData') continue

        const pageSize = tableDefautlLine //this.pagination.pageSize||0
        const topSumObj_field = key
        totalObj[topSumObj_field] = 0
        let total = 0
        for (let i = 0; i < currentDataSource.length; i++) {
          const info = currentDataSource[i]

          if (this.pagination!==false && i >= pageSize) {
            break
          }
          let itemVal = info[topSumObj_field] * 1
          if(topSumObj_field in this.otherSortCal){
            itemVal = this.otherSortCalItemFnc(topSumObj_field,info);
          }
          totalObj[topSumObj_field] = totalObj[topSumObj_field] + itemVal
        }
      }
      for(let itemKey in totalObj){
        if(itemKey in this.otherSortCal){
            totalObj[itemKey] = this.otherSortCalResultFnc(itemKey,totalObj)
          }
      }
      

      if (recordUpData) {
        totalObj.upSortData = currentDataSource
      } else {
        totalObj.upSortData = this.topSumObj.upSortData
      }
      // console.log(totalObj)
      this.topSumObj = totalObj
    },
    onTableChange(pagination, filters, sorter, { currentDataSource, action }) {
      // console.log('>>>>>>>>>>>>>>>>>> onTableChange <<<<<<<<<<<<<<<<<<<')
      if (action == 'sort') {
        return this.calOtherSum(currentDataSource)
      }
    },
    
    sortChangeEvent(tableData,{ data, sortList }){
      const sortItem = sortList[0]||{}
      // 取出第一个排序的列
      const { field, order } = sortItem
      let list = []
      if(field){
        if (order === 'asc' || order === 'desc') {
        if (this.tableNumberField.indexOf(field)>-1) {
          list = tableData.filter(this.sortTableFilterItem).sort((a, b) => {
            const aVal = Number(a[field])
            const bVal = Number(b[field])
            return aVal === bVal ? 0 : (aVal > bVal ? 1 : -1)
          })
        } else {
          list = tableData.filter(this.sortTableFilterItem).sort((a, b) => {
            const aVal = a[field]
            const bVal = b[field]
            return aVal === bVal ? 0 : (aVal > bVal ? 1 : -1)
          })
        }
      }
      }else{
        list = this.tableData
      }
      
      if (order === 'desc') {
        list.reverse()
      }
      this.calOtherSum(list)
      this.$refs.tableRef.loadData(this.pagination===false ? list : list.slice(0,this.pagination.pageSize)).then((e) => {
          
      })

    },
    
  }
}
</script>

<style>
</style>