<template>
  <div class="solution scroll" id="solution-tools">
    <!-- 设置主题色 -->
    <setting-drawer ref="settingDrawer" />

    <!-- 保存方案模板 基础版本-->
    <div v-show="isPrint && planType == 'base'">
      <SolutionTemplatePDF
        ref="solutionTemplatePDF"
        :detail="detail"
        :params_tax_per="params_tax_per"
        :params_sold_per="params_sold_per"
        :params_cost_per="params_cost_per"
        :params_pass_per="params_pass_per"
        :remark_base="remark_base"
        :planResult_base="planResult_base"
      />
    </div>
    <!-- 保存方案模板 扩展版本 -->
    <div v-show="isPrint && planType == 'extra'">
      <SolutionTemplatePDFExtra
        ref="solutionTemplatePDFExtra"
        :detail="detail"
        :params_tax_per="params_tax_per"
        :params_sold_per="params_sold_per"
        :params_cost_per="params_cost_per"
        :params_pass_per="params_pass_per"
        :remark_extra="remark_extra"
        :planResult_extra="planResult_extra"
      />
    </div>
    <div class="solution-foot" id="PDF-foot">
      <div class="solution-foot-icon"><img src="/logo.png" class="logo" alt="logo" /></div>
      <div class="solution-foot-text">
        {{ getWaterName }}
      </div>
    </div>

    <div class="bodys">
      <a-watermark :content="getWaterName">
        <div class="solution-box">
          <!-- <div class="headBox">
            <div class="headBoxBg">
              <img src="@/assets/images/solution_img.png" />
              <div class="headBoxLogo"><img :src="getPlanLogo" class="logo" alt="logo" /></div>
            </div>
            <div class="headBoxContent">
              <div class="headTitle">
                <div class="title">{{ getPlanName }}</div>
                <div class="subtitle">{{ getPlanName_en }}</div>
              </div>
              <div class="author">
                <div class="author_name">{{ getWaterName }} <text>{{getDetailName}}</text></div>
                <div class="author_info">
                  <span>编制人：{{ userInfo.name }}</span>
                  <span>编制时间：{{ detail.created_at }}</span>
                  <span>打印次数：{{ detail.times }}次</span>
                </div>
              </div>
            </div>
            <div class="a-setting-btn">
              <a-button type="primary" @click="$refs.settingDrawer.open()">设置主题色</a-button>
            </div>
          </div> -->

          <!-- <div class="import-btns">
            <div style="border:1px solid #ccc;display:inline-block">
              <a-button size="large" :type="planType=='base'?'primary':''"  @click="planType='base'" > 基础方案 </a-button>
              <OpenVipBtn size="large"  content="获取基础方案"  v-if="!getHasToolAuth('tools1_extra')"/> 
              <a-button size="large" :type="planType=='extra'?'primary':''"  @click="planType='extra'" v-else> 扩展方案 </a-button>
            </div>
          </div> -->

          <div class="solution-head">
            <div class="headBox-c">
              <setting-drawer ref="settingDrawer" />
              <div class="headBoxBg"></div>
              <div class="headBoxContent">
                <div class="headTitle">
                  <a-row :gutter="0">
                    <a-col :sm="24" :lg="18" class="headTitle-left">
                      <img src="/public/logo_text.png" />
                      <div class="title">{{ detail.plan_name }}</div>
                      <!-- <div class="author_name">{{ detail.water_name }} <text>{{ detail.detail_name }}</text></div> -->
                      <div class="author_name" style="margin-left: -18px">
                        {{ detail.base_name }}<text style="margin-left: 10px">{{ detail.name }}</text>
                      </div>
                    </a-col>

                    <a-col :sm="24" :lg="6">
                      <div>
                        <img src="@/assets/images/solution_img.png" />
                        <div class="headBoxLogo"><img :src="detail.plan_logo" class="logo" alt="logo" /></div>
                      </div>
                    </a-col>
                  </a-row>
                  <div class="line"></div>
                </div>
              </div>
              <div class="a-setting-btn">
                <span @click="$refs.settingDrawer.open()">主题色</span>
              </div>
            </div>
            <div class="import-btns">
              <div style="border: 1px solid #ccc; display: inline-block">
                <a-button size="small" :type="planType == 'base' ? 'primary' : ''" @click="planType = 'base'" v-if="authTools.indexOf('base')>-1" > 基础方案 </a-button> 
                <a-button size="small" :type="planType == 'extra' ? 'primary' : ''" @click="planType = 'extra'"   v-if="authTools.indexOf('extra')>-1" > 扩展方案 </a-button>
              </div>

              <div class="author">
                <div class="author_info">
                  <span>编制人：{{ userInfo.name }}</span>
                  <span>编制时间：{{ detail.created_at }}</span>
                  <span>打印次数：{{ detail.times }}次</span>
                </div>
              </div>
            </div>
          </div>

          <div class="solution-wrap print-pdf-base" v-show="planType === 'base'">
            <!-- 折线图 -->
            <div class="print-pdf" data-index="1">
              <div class="solution-children">
                <div class="row">
                  <a-row :gutter="10" class="flex-row-local">
                    <a-col :sm="24" :lg="15" class="flex-col-local">
                      <!-- <SolutionSVPLineBefore :planType="planType" :isPrint="isPrint" /> -->
                      <box-card style="flex: 1">
                        <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
                          <SvpLine
                            ref="svpLineBefore"
                            v-show="getHasToolAuth('tools1_cvp_before')"
                            echartId="svp-line-before"
                            showZeroPoint
                            :planType="planType"
                            :excelData_yc="$store.state.tools.tableData_step_yc"
                            :columns_step_mix="$store.state.tools.columns_step_mix"
                            :tableData_step_fixed="$store.state.tools.tableData_step_fixed"
                            :tableData_step_mix="$store.state.tools.tableData_step_mix || []"
                            :svpYMaxValue="$store.state.tools.svpYMaxValue"
                            :svpXMap="$store.state.tools.svpXMap"
                          />
                          <SvpLine
                            ref="svpLineAfter"
                            v-show="getHasToolAuth('tools1_cvp_after')"
                            echartId="svp-line-after"
                            showZeroPoint
                            isWatchXYChannel
                            :planType="planType"
                            :excelData_yc="$store.state.tools.plan_base === 'full' ? $store.state.tools.tableData_changeData_origin : $store.state.tools.tableData_changeData"
                            :columns_step_mix="$store.state.tools.columns_step_mix"
                            :tableData_step_fixed="$store.state.tools.tableData_step_fixed"
                            :tableData_step_mix="$store.state.tools.tableData_step_mix || []"
                          />
                        </div>
                      </box-card>
                    </a-col>
                    <a-col :sm="24" :lg="9" class="flex-col-local">
                      <!-- <SolutionSVPLineAfter :planType="planType" :isPrint="isPrint" /> -->
                      <box-card>
                        <div class="">
                          <a-descriptions :column="1" size="small" bordered :labelStyle="getTransferWidth">
                            <a-descriptions-item label="含税售价整体加成(%)">
                              <div class="descriptions-span">
                                <SliderInputBlock v-model:value="params_tax_per" :planType="planType" />
                              </div>
                            </a-descriptions-item>
                            <a-descriptions-item label="销量整体加成(%)">
                              <div class="descriptions-span">
                                <SliderInputBlock v-model:value="params_sold_per" :planType="planType" />
                              </div>
                            </a-descriptions-item>
                            <a-descriptions-item label="单位变动成本整体加成(%)">
                              <div class="descriptions-span">
                                <SliderInputBlock v-model:value="params_cost_per" :planType="planType" />
                              </div>
                            </a-descriptions-item>
                            <a-descriptions-item label="产品合格率整体加成(%)">
                              <div class="descriptions-span">
                                <SliderInputBlock v-model:value="params_pass_per" :max="100" :step="0.5" :planType="planType" />
                              </div>
                            </a-descriptions-item>
                          </a-descriptions>
                        </div>
                      </box-card>

                      <box-card style="flex: 1">
                        <solution-struct-operate-table
                          ref="solutionStructOperateTable"
                          :planId="cvpDetail.id"
                          :pagination="pagination"
                          :planType="planType"
                          :isPrint="isPrint"
                          :params_tax_per="params_tax_per"
                          :params_sold_per="params_sold_per"
                          :params_cost_per="params_cost_per"
                          :params_pass_per="params_pass_per"
                          :excelData_yc="$store.state.tools.tableData_changeData"
                          :columns_step_mix="$store.state.tools.columns_step_mix"
                          :tableData_step_fixed="$store.state.tools.tableData_step_fixed"
                          :tableData_step_mix="$store.state.tools.tableData_step_mix || []"
                        />
                      </box-card>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </div>
            <!-- 1.0 整体参数 -->
            <div class="solution-children print-pdf" data-index="2">
              <a-row :gutter="8">
                <a-col :sm="24" :lg="7" v-show="getHasToolAuth('tools1_base_params')">
                  <box-card>
                    <guide-item-intro type="five" field="intro1" moduleName="本节导引" />
                  </box-card>
                </a-col>
                <a-col :sm="24" :lg="17">
                  <box-card>
                    <guide-item-intro type="five" field="intro_base" moduleName="假设背景" />
                  </box-card>
                </a-col>
              </a-row>
            </div>

            <!-- 基本表 -->
            <div class="print-pdf" data-index="3">
              <box-card>
                <solution-base-table
                  ref="solutionBaseTable"
                  :planId="cvpDetail.id"
                  :pagination="pagination"
                  :params_tax_per="params_tax_per"
                  :params_sold_per="params_sold_per"
                  :params_cost_per="params_cost_per"
                  :params_pass_per="params_pass_per"
                  :planType="planType"
                  :isPrint="isPrint"
                />
              </box-card>
            </div>

            <!-- 扩展表 -->
            <div class="print-pdf" data-index="4">
              <div class="solution-children">
                <box-card>
                  <div class="thead">扩展表</div>
                  <tools-item-intro type="five" field="intro3" />
                  <solution-cost-mix-table :planId="cvpDetail.id" :pagination="pagination" :planType="planType" :isPrint="isPrint" />
                </box-card>
              </div>
            </div>

            <!-- 分析指标 -->
            <!-- <div class="print-pdf" data-index="5">
              <div class="solution-children">
                <box-card>
                  <div class="head">分析指标</div>
                  <tools-item-intro type="five" field="intro4"/>
                  <solution-struct-operate-table 
                    ref="solutionStructOperateTable" 
                    :planId="cvpDetail.id" 
                    :pagination="pagination" 
                    :planType="planType" 
                    :isPrint="isPrint"
                    :params_tax_per="params_tax_per"
                    :params_sold_per="params_sold_per"
                    :params_cost_per="params_cost_per"
                    :params_pass_per="params_pass_per"
                    :excelData_yc="$store.state.tools.tableData_changeData"
                    :columns_step_mix="$store.state.tools.columns_step_mix"
                    :tableData_step_fixed="$store.state.tools.tableData_step_fixed"
                    :tableData_step_mix="$store.state.tools.tableData_step_mix||[]"   
                  />
                </box-card>
              </div>
            </div> -->

            <!-- 方案解读1 -->
            <div class="print-pdf" data-index="6">
              <div class="solution-children">
                <box-card>
                  <!-- <div class="head">方案解读</div> -->
                  <tools-item-intro type="five" field="intro6" />
                  <div class="row">
                    <div class="RichText"></div>
                  </div>
                </box-card>
              </div>
            </div>

            <!-- 编制备注 -->
            <div class="solution-children">
              <box-card>
                <div class="thead">编制备注</div>

                <!-- <tools-item-intro type="five" field="intro7" /> -->

                <div class="message" v-if="remark_base_mobile">
                  <div class="head">回复意见</div>
                  <div class="content">
                    <div class="item">
                      <div class="item-main">
                        <div class="item-main-desc">
                          {{ remark_base_mobile }}
                        </div>
                        <div class="item-main-action">回复时间：{{ remark_base_time }}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <a-textarea :maxlength="1000" v-model:value="remark_base" placeholder="备注" :auto-size="{ minRows: 8, maxRows: 16 }" />
                </div>
              </box-card>
            </div>
          </div>

          <div class="solution-wrap print-pdf-extra" v-show="planType === 'extra'">
            <div class="solution-children">
              <!-- <div class="head">散点图</div>
                <tools-item-intro type="five" field="intro9"/> -->
              <div class="row">
                <a-row :gutter="10" class="flex-row-local">
                  <a-col :sm="24" :lg="15" class="flex-col-local">
                    <!-- <solution-svp-scatter-before :planType="planType" :pagination="pagination"/> -->
                    <box-card style="flex: 1">
                      <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
                        <SvpScatter
                          v-show="getHasToolAuth('tools1_scatter_before')"
                          ref="svpCatterBefore"
                          :planType="planType"
                          echartId="svp-scatter-before"
                          :excelData_yc="$store.state.tools.excelData_ycOrigin"
                          :getWaterName="getWaterName"
                          :logo="getPlanLogo"
                          :title="getPlanName"
                          :subTitle="getWaterName"
                          subName="扩展方案1"
                        />

                        <SvpScatter
                          v-show="getHasToolAuth('tools1_scatter_after')"
                          ref="svpCatterAfter"
                          :planType="planType"
                          echartId="svp-scatter-after"
                          :excelData_yc="$store.state.tools.excelData_ycChange"
                          :getWaterName="getWaterName"
                          :logo="getPlanLogo"
                          :title="getPlanName"
                          :subTitle="getWaterName"
                          subName="扩展方案1"
                        />
                      </div>
                    </box-card>
                  </a-col>

                  <a-col :sm="24" :lg="9" class="recode-guide flex-col-local">
                    <box-card>
                      <div class="row">
                        <a-descriptions :column="1" size="small" bordered :labelStyle="getTransferWidth">
                          <a-descriptions-item label="含税售价整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_tax_per" :planType="planType" />
                            </div>
                          </a-descriptions-item>
                          <a-descriptions-item label="销量整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_sold_per" :planType="planType" />
                            </div>
                          </a-descriptions-item>
                          <a-descriptions-item label="单位变动成本整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_cost_per" :planType="planType" />
                            </div>
                          </a-descriptions-item>
                          <a-descriptions-item label="产品合格率整体加成(%)">
                            <div class="descriptions-span">
                              <SliderInputBlock v-model:value="params_pass_per" :max="100" :step="0.5" :planType="planType" />
                            </div>
                          </a-descriptions-item>
                        </a-descriptions>
                      </div>
                    </box-card>
                    <div class="flex-col-local" style="flex: 1">
                      <box-card style="flex: 1">
                        <guide-item-intro type="five" field="intro8" moduleName="本节导引" />
                      </box-card>
                      <box-card style="flex: 1">
                        <guide-item-intro type="five" field="intro_extra" moduleName="假设背景" />
                      </box-card>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>

            <!-- <div class="solution-children">
              <box-card>
                <a-row :gutter="100">
                  <a-col :sm="24" :lg="10"  v-show="getHasToolAuth('tools1_base_params')">
                    <div class="head">整体参数</div>

                    <tools-item-intro type="five" field="intro8"/>
                    
                    <div class="row">
                      <a-descriptions :column="1" size="small" bordered :labelStyle="{ width: '200px',minWidth:'200px' }">
                        <a-descriptions-item label="含税售价整体加成(%)">
                          <div class="descriptions-span">
                            <SliderInputBlock v-model:value="params_tax_per" :planType="planType" />
                          </div>
                        </a-descriptions-item>
                        <a-descriptions-item label="销量整体加成(%)">
                          <div class="descriptions-span">
                            <SliderInputBlock v-model:value="params_sold_per" :planType="planType" />
                          </div>
                        </a-descriptions-item>
                        <a-descriptions-item label="单位变动成本整体加成(%)">
                          <div class="descriptions-span">
                            <SliderInputBlock v-model:value="params_cost_per" :planType="planType" />
                          </div>
                        </a-descriptions-item>
                        <a-descriptions-item label="产品合格率整体加成(%)">
                          <div class="descriptions-span">
                            <SliderInputBlock v-model:value="params_pass_per" :max="100" :step="0.5" :planType="planType" />
                          </div>
                        </a-descriptions-item>
                      </a-descriptions>
                    </div>
                  </a-col>
                  <a-col :sm="24" :lg="14">
                    <div class="head">假设背景</div>
                    <div class="row">
                      <div class="RichText">
                        <tools-item-intro type="five" field="intro_extra" moduleName="background"/>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </box-card>
            </div> -->

            <!-- 敏感度分析 -->
            <div class="solution-children">
              <box-card>
                <div class="thead">敏感性分析</div>
                <tools-item-intro type="five" field="intro10" />
                <SolutionAnalysis
                  ref="solutionAnalysis"
                  :isPrint="isPrint"
                  :paramsTaxPer="params_tax_per"
                  :paramsSoldPer="params_sold_per"
                  :paramsCostPer="params_cost_per"
                  :paramsPassPer="params_pass_per"
                />
              </box-card>
            </div>

            <!-- 方案解读2 -->
            <div class="solution-children">
              <box-card>
                <!-- <div class="thead">方案解读</div> -->
                <tools-item-intro type="five" field="intro11" />
                <div class="row">
                  <div class="RichText"></div>
                </div>
              </box-card>
            </div>

            <!-- 编制备注 -->
            <div class="solution-children">
              <box-card>
                <div class="thead">编制备注</div>

                <!-- <tools-item-intro type="five" field="intro12" /> -->

                <div class="message" v-if="remark_extra_mobile">
                  <div class="head">回复意见</div>
                  <div class="content">
                    <div class="item">
                      <div class="item-main">
                        <div class="item-main-desc">
                          {{ remark_extra_mobile }}
                        </div>
                        <div class="item-main-action">
                          {{ remark_extra_time }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <a-textarea :maxlength="1000" v-model:value="remark_extra" placeholder="备注" :auto-size="{ minRows: 8, maxRows: 16 }" />
                </div>
              </box-card>
            </div>
          </div>

          <div class="button-group">
            <!-- <a-button type="primary" ghost size="large" @click="handleBack()"> 返回 </a-button> -->
            <OperateLimit type="primary" ghost size="large" :showErr="false" @doClick="handleBack()" @errClick="$router.back()" useTool="cjjtsj"> 返回 </OperateLimit>
            <!-- <a-button type="primary" size="large" @click="handleSave()"> 保存 </a-button> -->
            <OperateLimit type="primary" size="large" @doClick="handleSave()" useTool="cjjtsj"> 保存 </OperateLimit>
            <!-- <a-button type="primary" size="large" @click="downPDF"> 下载方案 </a-button> -->
            <OperateLimit type="primary" size="large" @doClick="downPDF" useTool="cjjtsj"> 下载方案 </OperateLimit>
            <template v-if="auth_share_state==1">
              <!-- <a-button type="primary" size="large" @click="handleShare()" v-if="!getIsSave"> 分享 </a-button> -->
              <OperateLimit type="primary" size="large" v-if="!getIsSave" @doClick="handleShare()" useTool="cjjtsj"> 分享 </OperateLimit>
              <ShareLink ref="shareLink" v-else :href="$baseURL + '/#/mobile5?id=' + listQuery.id" />
            </template>
          </div>
        </div>
      </a-watermark>
      <!-- <div class="btn-cricy"></div> -->

      <!-- <a-popover placement="left" trigger="hover">
        <template #content> 产品路径图 </template>
        <BarChartOutlined @click="goRouterChartOut('/dashboard/cloud8/solution', { id: listQuery.id })" class="fixd-chart-btn" />
      </a-popover> -->

      <a-popover placement="left" trigger="hover" v-if="authTools.indexOf('path')>-1">
        <template #content> 产品路径图 </template>
        <img src="@/assets/images/product-path-icon.png" @click="goRouterChartOut('/dashboard/cloud8/solution', { id: listQuery.id })" class="fixd-chart-btn" />
      </a-popover>


    </div>
  </div>
  <page-loading ref="pageLoading" />
  <SavePlan ref="savePlan" />
</template>

<script>
// import baseService from '@/utils/http/axios';
import { numberToFixed, tableDefautlLine, baseURL } from '@/utils/util'
// import { computed, ref, watch, nextTick } from 'vue';
// import { useRouter } from 'vue-router';
// import Line from '../components/Chart/Line.vue'
// import Scatter from '../components/Chart/Scatter.vue'
import { mapGetters } from 'vuex'
import SettingDrawer from '@/components/SettingDrawer/SettingDrawer.vue'
import ShareLink from '@/components/ShareLink.vue'
// 打印模板
import SolutionTemplatePDF from './components/SolutionTemplatePDF.vue'
import SolutionTemplatePDFExtra from './components/SolutionTemplatePDFExtra.vue'

import SolutionBaseTable from './components/SolutionBaseTable.vue'
import SolutionCostMixTable from './components/SolutionCostMixTable.vue'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import PageLoading from '@/components/PageLoading.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import guideItemIntro from '@/components/guideItemIntro.vue'
import SolutionStructOperateTable from './components/SolutionStructOperateTable.vue'
import SolutionSVPLineBefore from './components/SolutionSVPLineBefore.vue'
import SolutionSVPLineAfter from './components/SolutionSVPLineAfter.vue'
import SolutionSvpScatterBefore from './components/SolutionSvpScatterBefore.vue'
import SolutionSvpScatterAfter from './components/SolutionSvpScatterAfter.vue'
import SolutionAnalysis from './components/SolutionAnalysis.vue'
import SavePlan from '../components/SavePlan.vue'
import SvpLine from './components/SvpLine.vue'
import SvpScatter from './components/SvpScatter.vue'
import OpenVipBtn from '@/components/OpenVipBtn.vue'
// import { EditOutlined } from '@ant-design/icons-vue';
import { BarChartOutlined } from '@ant-design/icons-vue'
import BoxCard from '@/components/tools/boxCard.vue'
import { message } from 'ant-design-vue'
import { htmlToPDF } from '@/utils/html2pdf'
import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'
export default {
  components: {
    PageLoading,
    SettingDrawer,
    SolutionTemplatePDF,
    SolutionTemplatePDFExtra,
    SolutionBaseTable,
    SliderInputBlock,
    SolutionBaseTable,
    SolutionCostMixTable,
    SolutionStructOperateTable,
    SolutionSVPLineBefore,
    SolutionSVPLineAfter,
    SolutionSvpScatterBefore,
    SolutionSvpScatterAfter,
    SolutionAnalysis,
    SavePlan,
    ToolsItemIntro,
    guideItemIntro,
    SvpScatter,
    SvpLine,
    ShareLink,
    OpenVipBtn,
    BarChartOutlined,
    BoxCard
  },
  data() {
    return {
      isPrint: false,
      downLoading: false,
      pagination: false,
      listQuery: {
        id: ''
      },
      //方案详情
      detail: {},
      //cvp方案详情
      cvpDetail: {},
      //整体加成参数设置
      params_tax_per: 0,
      params_sold_per: 0,
      params_cost_per: 0,
      params_pass_per: 0,

      pageDataInitState: false,

      //备注-基础
      remark_base: '',
      remark_base_mobile: '',
      remark_base_time: '',
      //备注-扩展
      remark_extra: '',
      remark_extra_mobile: '',
      remark_extra_time: '',
      //方案解读-基础
      planResult_base: ``,
      //方案解读-扩展
      planResult_extra: ``,
      pdfFile: '',
      planType: 'base',
      
      auth_share_state:0,
      authTools:[],
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    },
    getTransferWidth() {
      if (document.body.clientWidth < 1441) {
        return { width: '118px', minWidth: '118px', fontSize: '9px', textAlign: 'left', padding: 1 }
      } else if (document.body.clientWidth > 1441 && document.body.clientWidth < 1919) {
        return { width: '160px', minWidth: '160px', fontSize: '12px', textAlign: 'left', padding: 1 }
      } else {
        return { width: '200px', minWidth: '200px' }
      }
    }
  },
  created() {
    this.listQuery.id = this.$route.query.id || 0

    this.planType = this.$route.query.planType == 'extra' ? 'extra' : 'base'

    // this.getTest()
  },
  mounted() {
    //项目初始化主題色
    let themeColor = (ls.get(SITE_SETTINGS) || {}).THEME_COLOR
    if (themeColor) {
      systemConfig.commit(THEME_COLOR, (ls.get(SITE_SETTINGS) || {}).THEME_COLOR)
    }
    this.$nextTick(() => {
      this.getPageData()
      // this.getPlanDetail()
    })
    this.$nextTick(() => {
      // this.initJs()
    })
  },

  unmounted() {
    //清空工具信息
    this.$store.commit('clearTools')
  },
  methods: {
    goRouterChartOut(path, query) {
      this.$router.push({ path: path, query: query })
    },

    handleShare() {
      message.error(`保存后才能进行分享`)
      this.handleSave(() => {
        this.$refs.savePlan.close()
        this.$nextTick(() => {
          this.$refs.shareLink.open()
        })
      })
    },
    onParamsPerChange(params) {
      console.log(params)
    },

    getPageData() {
      this.$network('/api/tools/planDetail', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const pageParams = detail.page_params || {}
          const base = detail.base || '' //方案基础类型

          this.auth_share_state = data.auth_share_state||0

          this.authTools = data.authTools||[]
          this.$store.commit('updateToolsState', { field: 'plan_base', value: base })

          this.detail = detail

          this.$nextTick(() => {
            if (detail.is_save) {
              this.params_tax_per = pageParams.params_tax_per || 0
              this.params_sold_per = pageParams.params_sold_per || 0
              this.params_cost_per = pageParams.params_cost_per || 0
              this.params_pass_per = pageParams.params_pass_per || 0
              this.remark_base = detail.remark_base || ''
              this.remark_base_mobile = detail.remark_base_mobile || ''
              this.remark_base_time = detail.remark_base_time || ''
              this.remark_extra = detail.remark_extra || ''
              this.remark_extra_mobile = detail.remark_extra_mobile || ''
              this.remark_extra_time = detail.remark_extra_time || ''
            }

            //阶梯价格
            this.getLadderDetail(pageParams)
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getLadderDetail(pageParams) {
      this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step5-ladder' }).then((res) => {
        const data = res.data || {}
        const list = data.list || []

        // this.ladderTableList = list;
        this.$store.commit('updateToolsState', { field: 'ycPrice', value: this.$priceLadderSort(list) })

        this.$nextTick(() => {
          this.getPlanDetail().finally(() => {
            if (this.detail.is_save) {
              //加载表格数据
              this.$refs.solutionBaseTable.setParams(pageParams.solutionBaseTable || {})
              //敏感数据
              this.$refs.solutionAnalysis.setParams(pageParams.solutionAnalysis || {})
            }
          })
        })
      })
    },

    //方案详情
    getPlanDetail() {
      return this.$network('/api/tools/planDetail', { id: this.detail.step5_cvp })
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const pageParams = detail.page_params || {}
          this.cvpDetail = detail

          this.$nextTick(() => {
            if (!this.detail.is_save) {
              this.params_tax_per = pageParams.params_tax_per || 0
              this.params_sold_per = pageParams.params_sold_per || 0
              this.params_cost_per = pageParams.params_cost_per || 0
              this.params_pass_per = pageParams.params_pass_per || 0
              this.remark_base = detail.remark_base || ''
              this.remark_base_mobile = detail.remark_base_mobile || ''
              this.remark_base_time = detail.remark_base_time || ''
              this.remark_extra = detail.remark_extra || ''
              this.remark_extra_mobile = detail.remark_extra_mobile || ''
              this.remark_extra_time = detail.remark_extra_time || ''
              this.$nextTick(() => {
                //加载表格数据
                this.$refs.solutionBaseTable.setParams(pageParams.solutionBaseTable || {})
                //敏感数据
                this.$refs.solutionAnalysis.setParams(pageParams.solutionAnalysis || {})
              })
            }
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // //测试使用
    getTest(id) {
      this.$network('/api/tools/planTest', this.listQuery)
        .then((res) => {
          const data = res.data || {}
        })
        .catch((err) => {
          console.error(err)
        })
    },
    handleBack() {
      this.$confirm({
        content: '是否保存方案',
        okText: '是',
        cancelText: '否',
        onOk: () => {
          this.handleSave(() => {
            this.$router.back()
          })
        },
        onCancel: () => {
          this.$router.back()
        }
      })
    },
    handleSave(callback = null) {
      this.$refs.savePlan.open(
        this.listQuery.id,
        {
          name: this.detail.name || '',
          remark_base: this.remark_base,
          remark_extra: this.remark_extra,
          params: {
            params_tax_per: this.params_tax_per,
            params_sold_per: this.params_sold_per,
            params_cost_per: this.params_cost_per,
            params_pass_per: this.params_pass_per,

            solutionBaseTable: this.$refs.solutionBaseTable.getSaveData(),
            solutionAnalysis: this.$refs.solutionAnalysis.getSaveData()
          }
        },
        () => {
          this.detail.is_save = 1
          console.log(callback)
          if (callback) {
            callback()
          } else {
            this.$router.replace({ path: '/history' })
          }
        }
      )
    },
    async downPDF() {
      this.downLoading = true
      this.isPrint = true
      this.$nextTick(() => {
        this.$refs.pageLoading.open('正在进行方案下载')
        //增加打印次数
        this.handlePrint()

        setTimeout(() => {
          this.$nextTick(async () => {
            this.$refs.pageLoading.text('方案构件中...')

            let elId = 'solutionTemplatePDF'
            let pdfName = '基础方案'
            if (this.planType == 'extra') {
              pdfName = '扩展方案'
              elId = 'solutionTemplatePDFExtra'
              await this.$refs.solutionTemplatePDFExtra.downPDF(this)
            } else {
              await this.$refs.solutionTemplatePDF.downPDF(this)
            }

            this.$refs.pageLoading.text('正在生成PDF文件')
            await htmlToPDF(elId, pdfName, 'PDF-foot')
            this.$refs.pageLoading.text('下载成功')
            // this.$refs.pageLoading.close()
            // this.isPrint = false
            setTimeout(() => {
              location.reload()
            }, 800)
          })
        }, 200)
      })

      // setTimeout(()=>{
      //   htmlToPDF(elId, pdfName,'PDF-foot')
      // },1000)

      return
      this.$refs.pageLoading.open('正在进行方案下载')

      setTimeout(() => {
        this.$nextTick(() => {
          this.downLoading = true
          this.isPrint = true
          this.handlePrint()
          this.generatePrintHtmlBlock()
        })
      })
    },
    generatePrintHtmlBlock() {
      this.$refs.pageLoading.text('方案构件中...')
      this.$nextTick(() => {
        //重新排序
        var printPDFBlock = document.getElementsByClassName('print-pdf-' + this.planType)[0]
        var printPDFItems = printPDFBlock.querySelectorAll('.print-pdf')
        var printPDFSort = new Array(printPDFItems.length)
        for (let i = 0; i < printPDFItems.length; i++) {
          const itemEl = printPDFItems[i]
          const index = itemEl.getAttribute('data-index')
          // console.log(itemEl)
          // console.log(index)
          printPDFSort[index] = itemEl
        }
        for (let i = 0; i < printPDFSort.length; i++) {
          printPDFBlock.appendChild(printPDFSort[i])
        }
        this.$refs.pageLoading.text('正在生成PDF文件')
        this.generatePdfFile()
      })
    },
    handlePrint() {
      this.$network('/api/tools/planPrintTimes', { id: this.listQuery.id, plan_type: this.planType, tool_type: 'one' })
    },
    async generatePdfFile() {
      await htmlToPDF('solution-tools', '工具一')
      this.downLoading = false
      this.$refs.pageLoading.text('下载成功')
      setTimeout(() => {
        location.reload()
      }, 800)
    },
    initJs() {
      const crossTables = document.querySelectorAll('.cross-table')

      //查找单元格
      const findTableTdIndex = (table, el) => {
        let tdEl = el
        if (el.tagName != 'TD') {
          tdEl = el.closest('td')
        }
        if (!tdEl) {
          return [-1, -1]
        }
        const trEl = tdEl.closest('tr')
        const tdIndex = Array.prototype.indexOf.call(trEl.children, tdEl)
        const trIndex = Array.prototype.indexOf.call(table.children, trEl)
        return [tdIndex, trIndex]
      }

      //高亮样式
      const ligntTableCross = (table, tableBody, colIndex, rowIndex) => {
        // .ant-table-cell-row-hover
        const sumTable = tableBody.parentNode.parentNode.nextElementSibling
        const allTr = tableBody.getElementsByTagName('tr')
        const sumTableTr = sumTable ? sumTable.getElementsByTagName('tr')[0] : null
        if (sumTableTr) {
          for (let si = 0; si < sumTableTr.children.length; si++) {
            sumTableTr.children[si].classList.remove('ant-table-cell-row-hover')
          }
          sumTableTr.children[colIndex].classList.add('ant-table-cell-row-hover')
        }

        for (let i = 0; i < allTr.length; i++) {
          if (i != rowIndex) {
            const tds = allTr[i].children
            for (let j = 0; j < tds.length; j++) {
              if (j != colIndex) {
                tds[j].classList.remove('ant-table-cell-row-hover')
              }
            }
          }
        }
        //添加列class
        for (let i = 0; i < allTr.length; i++) {
          allTr[i].children[colIndex].classList.add('ant-table-cell-row-hover')
        }
      }

      crossTables.forEach((table) => {
        const tableBody = table.getElementsByTagName('tbody')[0]
        let tableElIndex = [-1, -1]
        let debounce = 0
        //移入
        table.addEventListener('mouseenter', (event) => {
          var x = event.clientX
          var y = event.clientY
          var el = document.elementFromPoint(x, y)
          let [colIndex, rowIndex] = findTableTdIndex(tableBody, el)
          if (colIndex >= 0 && rowIndex >= 0) {
            console.log('>>>>>>>>>> 1 <<<<')
            tableElIndex = [colIndex, rowIndex]
            ligntTableCross(table, tableBody, colIndex, rowIndex)
          }
        })
        //移动
        table.addEventListener('mousemove', (event) => {
          var x = event.clientX
          var y = event.clientY
          var el = document.elementFromPoint(x, y)
          let [colIndex, rowIndex] = findTableTdIndex(tableBody, el)

          if (colIndex >= 0 && rowIndex >= 0) {
            if (tableElIndex[0] == colIndex && tableElIndex[1] == rowIndex) {
            } else {
              tableElIndex = [colIndex, rowIndex]
              if (debounce) clearTimeout(debounce)
              debounce = setTimeout(() => {
                ligntTableCross(table, tableBody, colIndex, rowIndex)
              }, 100)
            }
          }
        })
        //移除
        table.addEventListener('mouseleave', (event) => {
          var allNode = table.querySelectorAll('.ant-table-cell-row-hover')
          if (debounce) clearTimeout(debounce)
          debounce = setTimeout(() => {
            allNode.forEach((node) => {
              node.classList.remove('ant-table-cell-row-hover')
            })
          }, 500)
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.solution {
  background: #f8f9fd;
  .text-bold {
    font-weight: bold;
  }

  .body {
    height: auto !important;
    background: #eff7ff;

    .children {
      padding: 0px 24px 16px;
      background-color: transparent !important;
    }
  }
}

.flex-row-local {
  display: flex;
}

.flex-col-local {
  display: flex; /* 使a-col成为flex容器 */
  flex-direction: column; /* a-col内的内容垂直排列 */
}
</style>
