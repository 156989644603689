<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Logo',
})
</script>
<template>
  <div class="logo">
    <router-link :to="{ name: 'dashboard' }">
      <!-- <SvgIcon name="logo" /> -->
      <img class="icon-logo" src="@/assets/images/logo.png" alt="翎谋" />
      <!-- <h1 v-if="showTitle">{{ title }}</h1> -->
    </router-link>
  </div>
</template>

<script lang="ts" setup name="Logo">

const props = defineProps({
  title: {
    type: String,
    default: 'LinkModel',
    required: false
  },
  showTitle: {
    type: Boolean,
    default: true,
    required: false
  }
})
</script>
