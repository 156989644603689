<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'history',
})
</script>
<template>
    <div class="account">
        <div class="header">
            <div class="title">部门管理</div>
            <div class="subtitle">支持查看本地的历史方案</div>
        </div>

        <div class="body">
            <div id="table-container" class="children">
                <div class="searchBox">
                    <div class="aciton">
                        <a-button type="primary" @click="handleAdd">
                            <template #icon>
                                <PlusOutlined />
                            </template>

                            添加部门
                        </a-button>
                    </div>
                    <div class="search">
                        <a-space :size="16">
                            <a-input v-model:value="search" placeholder="搜索部门名称/部门成员">
                                <template #suffix>
                                    <search-outlined style="color: #999" />
                                </template>
                            </a-input>
                        </a-space>
                    </div>
                </div>

                <div class="tableWrapper">

                    <a-table :scroll="{ y: '100%' }" :columns="columns" :data-source="dataList">
                        <template #bodyCell="{ column, text, record, index }">

                            <template v-if="column.dataIndex === 'operation'">
                                <a-button type="link" @click="handlEdit(record)">编辑</a-button>
                                <a-button type="text" danger @click="handleDel(record.id, index)">删除</a-button>
                            </template>
                        </template>
                    </a-table>
                </div>
            </div>
        </div>

        <a-drawer title="添加部门" :width="400" :open="open" :body-style="{ paddingBottom: '80px' }"
            :footer-style="{ textAlign: 'right' }" @close="onClose">
            <a-form layout="vertical">

                <a-form-item label="部门名称" name="name">
                    <a-input v-model:value="form.name" placeholder="请输入部门名称" />
                </a-form-item>

            </a-form>
            <template #footer>
                <a-space>
                    <a-button @click="onClose">取消</a-button>
                    <a-button type="primary" @click="handleSubmit">保存</a-button>
                </a-space>
            </template>
        </a-drawer>

    </div>
</template>

<script lang="ts" setup name="history">
import { ref, reactive, computed, onMounted } from 'vue'
import baseService from '@/utils/http/axios'

import { Modal, message } from 'ant-design-vue';
import { PlusOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';

onMounted(() => {
    getData()
})
const tempData = {
    id: '',
    name: '',
};
const form = reactive(tempData);
const columns = [
    {
        title: '部门名称',
        dataIndex: 'name',
        width: 350,
    },
    {
        title: '部门人数',
        dataIndex: 'num',
        width: 200,
        align: 'center'
    },
    {
        title: '部门成员',
        dataIndex: 'memberList',
        ellipsis: true,
    },
    {
        title: '操作',
        dataIndex: 'operation',
        align: 'center'
    },

];

const dataList = ref([])
const getData = () => {
    baseService.post('/api/company/departmentList').then(res => {
        const data = res.data || {}
        dataList.value = data.list || []
    }).catch(err => {
        console.error(err)
    })
}
const handleDel = (e, index) => {
    console.log("log>>>>>", e, index)
    Modal.confirm({
        title: '删除提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '确定删除吗？删除后不可撤销',
        okText: '确定删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
            baseService.post('/api/company/departmentDel', { id: e }).then(res => {
                dataList.value.splice(index, 1);
            }).catch(err => {
                console.error(err)
            })
        },
        onCancel() {
            console.log('Cancel');
        },
    });

}

const open = ref<boolean>(false);

const handleAdd = () => {
    open.value = true;
};

const handlEdit = (e) => {
    open.value = true;
    console.log('handlEdit', e.id, e.name)
    form.id = e.id
    form.name = e.name
};
const onClose = () => {
    open.value = false;
};

const handleSubmit = (id) => {

    baseService.post('/api/company/departmentAdd', {
        id: form.id,
        name: form.name,
    }).then(res => {
        message.success(res.msg)
        onClose()
        getData()
    }).catch(err => {
        console.error(err)
    })
};

</script>

<style lang="less" scoped>
</style>
