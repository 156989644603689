<template>
  <div class="main user-layout-register">
    <div class="user-register-box">
      <div class="register-form">
        <a-form ref="formRef" :rules="rules" layout="vertical" :model="temp">
          <a-form-item name="type">
            <a-input-group compact>
              <div class="ant-input-group-label">注 册 类 型</div>
              <a-select v-model:value="temp.type" :bordered="false" placeholder="请选择注册类型">
                <a-select-option value="0">个人用户</a-select-option>
                <a-select-option value="1">企业用户</a-select-option>
              </a-select>
            </a-input-group>
          </a-form-item>
          <a-form-item name="phone">
            <a-input :bordered="false" v-model:value="temp.phone" :maxlength="11" placeholder="输入法人手机号">
              <template #prefix>
                <div class="ant-input-label addon">中 国 +86</div>
              </template>
            </a-input>
          </a-form-item>

          <a-form-item name="email">
            <a-input :bordered="false" type="text" placeholder="输入公司邮箱" v-model:value="temp.email">
              <template #prefix>
                <div class="ant-input-label">邮 箱</div>
              </template>
            </a-input>
          </a-form-item>

          <a-form-item name="verify">
            <a-input-group>
              <a-input :bordered="false" v-model:value="temp.verify" :maxlength="6" placeholder="输入验证码" style="width: calc(100% - 100px)">
                <template #prefix>
                  <div class="ant-input-label">手机验证码</div>
                </template>
              </a-input>
              <a-button type="primary" class="getCaptcha" :disabled="state.time > 0" @click.stop.prevent="handleSendVerify">{{ (!state.time && '获取验证码') || state.time + ' s' }}</a-button>
            </a-input-group>
          </a-form-item>

          <a-form-item name="password">
            <a-input :bordered="false" type="password" placeholder="输入密码" v-model:value="temp.password">
              <template #prefix>
                <div class="ant-input-label">密码</div>
              </template>
            </a-input>
          </a-form-item>

          <a-form-item class="noStyle">
            <a-checkbox v-model:checked="checkNick"
              >我已阅读并同意 <a @click.prevent="$refs.popupContent.open('serverProtocol')">《服务协议》</a>和
              <a @click.prevent="$refs.popupContent.open('protectProtocol_company')" v-if="temp.type === '1'">《企业信息保护政策》</a>
              <a @click.prevent="$refs.popupContent.open('protectProtocol')" v-else>《个人信息保护政策》</a>
            </a-checkbox>
          </a-form-item>

          <a-button type="primary" htmlType="submit" class="register-button" :loading="registerBtn" @click.stop.prevent="handleSubmit" :disabled="registerBtn">注 册</a-button>

          <router-link class="login" :to="{ path: '/user/login' }">使用已有账户登录</router-link>
        </a-form>
      </div>
    </div>
    <PopupContent ref="popupContent" />
  </div>
</template>

<script >
import baseService from '@/utils/http/axios'
import FileUpload from '@/components/tools/FileUpload.vue'
import { Form, message } from 'ant-design-vue'
// import { scorePassword } from '@/utils/util'
import { MailOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { sendSms, checkPasswordLevel, validateEmail, validatePhone } from '@/store/modules/helper'
export default {
  components: {
    FileUpload,
    Form,
    message,
    MailOutlined
  },
  data() {
    return {
      checkNick: false,
      registerBtn: false,
      rules: {
        phone: [{ required: true, message: '请输入手机号码' }, { validator: validatePhone }, { validateTrigger: ['change', 'blur'] }],
        email: [{ required: true, message: '请输邮箱' }, { validator: validateEmail }, { validateTrigger: ['change', 'blur'] }],
        verify: [{ required: true, message: '请输验证码' }, { validateTrigger: ['change', 'blur'] }],
        password: [{ required: true, message: '请输密码' }, { validator: checkPasswordLevel }, { validateTrigger: ['change', 'blur'] }]
      },
      temp: {
        type: '0',
        phone: '',
        emial: '',
        verify: '',
        password: ''
      },
      state: {
        time: 0
      },
      second: 0
    }
  },
  created() {
    this.temp.type = this.$route.query.type == 'company' ? '1' : '0'
  },
  methods: {
    handleSendVerify(e) {
      sendSms(this.temp.phone, 'reg', this.state)
    },
    handleSubmit() {
      if (!this.checkNick) {
        return this.$message.error('请同意协议')
      }
      this.$refs.formRef
        .validate()
        .then((res) => {
          this.registerBtn = true
          this.$network('/api/account/reg', this.temp)
            .then((res) => {
              this.$message.success('注册成功')
              setTimeout(() => {
                this.$router.replace({ path: '/user/login', params: {} })
              }, 800)
            })
            .finally(() => {
              this.registerBtn = false
            })
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }
}

// import { ref, reactive, computed } from 'vue'
// import baseService from '@/utils/http/axios'
// import FileUpload from '@/components/tools/FileUpload.vue'
// import { Form, message } from 'ant-design-vue'
// // import { scorePassword } from '@/utils/util'
// import { MailOutlined } from '@ant-design/icons-vue'
// import { useRouter } from 'vue-router'
// import { useGetCaptcha } from '@/store/modules/helper'

// const items = ref([
//     {
//         title: '填写企业信息',
//     },
//     {
//         title: '注册成功',
//     }
// ])
// const router = useRouter()
// const useForm = Form.useForm

// // 表单相关
// const form = reactive({
//     company_type: "公司",//注 册 类 型
//     company_nature: "股份制企业",//企 业 性 质
//     company_industry: "制造业",//所 属 行 业
//     company_name: "",//公 司 名 称
//     company_credit_code: "",//统一社会信用代码
//     company_email: "",//公司邮箱
//     company_business_license: "",//营业执照
//     phone: "",
//     account: "",
//     password: "",
//     verify: "",
//     type: 'reg',
//     checkNick: false,
// })
// const state = reactive({
//     time: 60,
//     level: 0,
//     smsSendBtn: false,
//     percent: 10,
//     progressColor: '#FF0000'
// })

// const getCaptcha = (e) => {

//     useGetCaptcha(e, validate, state, form)
// }
// const handlePasswordLevel = (rule, value) => {
//     const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
//     if (value === '') {
//         return Promise.resolve('密码必须得含有字母+符号+数字且长度在8-16位之间')
//     }
//     if (value.length >= 8) {
//         if (!regex.test(value)) {
//             return Promise.reject('密码必须得含有字母+符号+数字且长度在8-16位之间');
//         }
//         return Promise.resolve();
//     } else {
//         return Promise.reject(new Error('密码强度不够'))

//     }
//     return Promise.resolve()
// }
// const handlePasswordCheck = (rule, value) => {
//     if (value === '') {
//         return Promise.resolve('密码必须得含有字母+符号+数字且长度在8-16位之间');
//     } else if (value !== form.password) {
//         return Promise.reject('两次密码不一致');
//     }
//     return Promise.resolve()
// }

// const rules = reactive({
//     company_name: [
//         { required: true, message: '请输入公司名' },
//         { validateTrigger: ['change', 'blur'] }
//     ],
//     company_credit_code: [
//         { required: true, message: '请输入统一社会信用代码' },
//         { validateTrigger: ['change', 'blur'] }
//     ],
//     company_email: [
//         { required: true, message: '请输公司邮箱' },
//         { validateTrigger: ['change', 'blur'] }
//     ],
//     phone: [
//         { required: true, message: '请输入手机号码' },
//         { validateTrigger: ['change', 'blur'] }
//     ],
//     verify: [
//         { required: true, message: '请输入手机验证' },
//         { validateTrigger: ['change', 'blur'] }
//     ],
//     account: [
//         { required: true, message: '请填写账户名' },
//         { validateTrigger: ['change', 'blur'] }
//     ],
//     password: [
//         { required: true, message: '密码必须得含有字母+符号+数字且长度在8-16位之间' },
//         { validator: handlePasswordLevel },
//         { validateTrigger: ['change', 'blur'] }
//     ],
//     password2: [
//         { required: true, message: '密码必须得含有字母+符号+数字且长度在8-16位之间' },
//         { validator: handlePasswordCheck },
//         { validateTrigger: ['change', 'blur'] }
//     ],
// })

// const changeProps = (file, field) => {
//     form[field] = file

// }

// const { validate, validateInfos } = useForm(form, rules)
// const handleSubmit = () => {
//     if (!form.checkNick) {
//         message.error('请先阅读并同意《服务协议》、《个人信息保护政策》')
//         return;
//     }
//     validate().then((res) => {
//         baseService.post('/api/account/companyReg', {
//             company_type: form.company_type,
//             company_nature: form.company_nature,
//             company_industry: form.company_industry,
//             company_name: form.company_name,
//             company_credit_code: form.company_credit_code,
//             company_email: form.company_email,
//             company_business_license: form.company_business_license,
//             phone: form.phone,
//             account: form.account,
//             password: form.password,
//             verify: form.verify
//         }).then(res => {
//             console.log(res)

//             router.push({ path: '/user/registerResult', params: {} })
//         }).catch(err => {
//             console.error(err)
//         })
//         // router.push({ name: 'registerResult', params: { ...form } })
//     })
// }
</script>
<style lang="less">
.user-register {
  &.error {
    color: #ff0000;
  }

  &.warning {
    color: #ff7e05;
  }

  &.success {
    color: #52c41a;
  }
}

.user-layout-register {
  .ant-input-group-addon:first-child {
    background-color: #fff;
  }

  .register-form {
    .ant-form-item {
      border: 1px solid #d9d9d9;

      &.noStyle {
        border: none;
      }

      .ant-select {
        flex: 1;
        width: 100%;
        padding: 8px 0px;

        .ant-select-selector {
          padding: 0px 16px;
        }
      }

      .ant-input-group {
        display: flex;
        align-items: center;
      }

      .ant-input-group-compact {
        padding: 3px 0px;
      }

      .ant-input {
        padding: 12px 16px;
      }
    }
  }

  .picture-upload {
    display: flex;

    .ant-upload-wrapper.ant-upload-picture-card-wrapper {
      width: auto;

      .ant-upload.ant-upload-select {
        width: auto;
        height: auto;
      }
    }

    .picture-example {
      width: 210px;

      .picture-example-thumbnail {
        border: 1px dashed #d9d9d9;
        border-radius: 2px;
      }

      .picture-example-name {
        text-align: center;
        margin-top: 8px;
      }
    }

    .picture-button {
      width: 210px;
      height: 145px;
      background-color: #f6f9ff;

      .picture-button-cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.user-layout-register {
  width: 1200px;
  min-height: 100%;
  padding: 2.5% 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .user-register-box {
    width: 100%;
    padding: 12px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .register-steps {
      width: 100%;
      padding: 24px 16%;
      border-bottom: 1px solid #d9d9d9;
    }

    .register-form {
      width: 640px;
      padding: 48px 0px 24px;
    }
  }

  .ant-input-label {
    min-width: 78px;
    text-align: justify;
    height: 100%;
    display: flex;
    align-items: center;

    &.addon {
      border-right: 1px solid #d9d9d9;
    }
  }

  .ant-input-group-label {
    min-width: 72px;
    text-align: justify;
    padding: 0px 0px 0px 11px;
    margin-inline-end: 4px;
  }

  .getCaptcha {
    display: block;
    width: 200px;
    height: 54px;
  }

  .register-button {
    width: 100%;
    height: 54px;
    font-size: 16px;
  }

  .login {
    float: right;
    line-height: 40px;
  }
}
</style>
