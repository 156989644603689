<template>
  <div class="energy">
    <!-- <a-select v-model:value="selectGroup" :options="options" mode="multiple" placeholder="Please select" @change="handleChange"> </a-select> -->
    <ToolsMoveTouch :position="position" @changePosion="onChangePosion">
      <div class="legend">
        <div class="item" :class="{ active: selectGroup.includes(item.value) }" v-for="(item, index) in options" :key="index" @click="handleSelect(item,index)">
          <span class="line"></span>
          <span>{{ item.label }}</span>
        </div>
      </div>
    </ToolsMoveTouch>

    <div class="echartBox" :id="echartId" :style="style"></div>
  </div>
</template>
<script>
import { isMobile, isTablet, isDesktop } from '@/utils/device'
import * as echarts from 'echarts'
import { message } from 'ant-design-vue'
import ToolsMoveTouch from '@/components/ToolsMoveTouch.vue'
export default {
  name: 'EchartEnergyMobile',
  components: {
    ToolsMoveTouch
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: '桑葚图'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectGroup: ['area', 'group', 'customer'],
      options: [
        { label: '产品编码', value: 'no' },
        { label: '产品线', value: 'line' },
        { label: '产品类别', value: 'type' },
        { label: '客户', value: 'customer' },
        { label: '区域', value: 'area' },
        { label: '业务组', value: 'group' }
      ],
      visible: false,
      echartId: 'echart-' + new Date().getTime() + '-' + Math.ceil(Math.random() * 10),
      nodeType: 'customer',
      echart: undefined,
      echartInit: false,
      echartData: {
        nodes: [],
        links: []
      },

      isMobile: isMobile,
      isTablet: isTablet,
      position: {}
    }
  },

  watch: {
    selectGroup: {
      handler(value) {
        this.handleEchart()
      }
    },
    data: {
      handler(value) {
        this.handleEchart()
      }
    },
    width:{
      immediate:true,//首次加载的时候执行函数
      handler:function(value){
        const position = {}
        if (this.isMobile) {
          position.top = '10px'
          position.left = this.width - 120 + 'px'
          position.transform = 'rotate(90deg)'
          position.marginTop = '44px'
        } else {
          position.top = '60px'
          position.left = '20px'
        }
        this.position = position
      }
    },
  },
  mounted() {
    this.echart = echarts.init(document.getElementById(this.echartId), null, {
      renderer: 'canvas',
      useDirtyRect: false
    })
    this.handleData()
  },
  computed: {
    marginLeft() {
      return this.width / 2
    },
    style() {
      const style = {}
      if (this.isMobile) {
        style.width = this.height + 'px'
        style.height = this.width + 'px'
        style.marginTop = '-100%'
        style.transform = 'rotate(90deg)'
        style.transformOrigin = 'left bottom'
      } else {
        style.width = this.width + 'px'
        style.height = this.height + 'px'
      }
      return style
    },
    // position() {
    //   const style = {}
    //   if (this.isMobile) {
    //     style.top = '10px'
    //     style.left = this.width - 120 + 'px'
    //     style.transform = 'rotate(90deg)'
    //     style.marginTop = '44px'
    //   } else {
    //     style.top = '60px'
    //     style.left = '20px'
    //   }
    //   return style
    // }
  },
  methods: {
    onChangePosion(e){
      console.log('>>>>>?', e)
      this.position.top = e.top
      this.position.left = e.left
    },
    handleChange(e) {
      console.log('>>>>>?', e)
    },
    handleSelect(item,index) {
      if (this.isSelected(item,index)) {
        // 如果项目已选中，则取消选中
        const selectedIndex = this.selectGroup.indexOf(item.value);
        this.selectGroup.splice(selectedIndex, 1);
      } else {
        // 如果项目未选中，则添加到选中列表中
        this.selectGroup.push(item.value);
      }
      this.handleEchart()
    },
    isSelected(item) {
      console.log('item>>>',item.value)
      return this.selectGroup.includes(item.value);
    },

    handleEchart() {
      const that = this

      try {
        that.handleData()
        that.drawEchart()
      } catch (e) {
        console.error(e)
      }
    },

    handleData() {
      this.echartData.nodes = []
      this.echartData.links = []
      const selectGroupLen = this.selectGroup.length
      if (selectGroupLen <= 1) {
        return
      }
      const addNodesFnc = (type, name) => {
        const nodeIndex = this.echartData.nodes.findIndex((nodeItem) => {
          return nodeItem.type == type && nodeItem.name == name
        })
        if (nodeIndex === -1) {
          this.echartData.nodes.push({
            name: name,
            type: type
          })
        }
      }

      const addLinksFnc = (source, target, val) => {
        const nodeIndex = this.echartData.links.findIndex((nodeItem) => {
          return nodeItem.source == source && nodeItem.target == target
        })
        // console.log(source,target,nodeIndex)
        // if(nodeIndex===-1){
        this.echartData.links.push({
          source: source,
          target: target,
          value: val
        })
        // }else{
        //     this.echartData.links[nodeIndex].value += val*1
        // }
      }
      this.data.map((item) => {
        const _value = item.price_come_in || '' //不含税总收入

        for (let i = 0; i < selectGroupLen; i++) {
          const groupItem = this.selectGroup[i]
          const groupItem_next = this.selectGroup[i + 1] || ''
          addNodesFnc(groupItem, item[groupItem])

          if (groupItem_next) {
            addLinksFnc(item[groupItem], item[groupItem_next], _value)
          } else if (selectGroupLen === 1) {
            addLinksFnc(item[groupItem], '', _value)
          }
        }
      })
      console.log(this.echartData)
    },
    drawEchart() {
      const that = this
      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: [
          {
            type: 'sankey',
            data: this.echartData.nodes,
            links: this.echartData.links,
            emphasis: {
              focus: 'adjacency'
            },
            lineStyle: {
              color: 'gradient',
              curveness: 0.5
            }
          }
        ]
      }
      // 释放资源
      this.echart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.echartBox {
  position: absolute;
}
.legend {
  width: 100px;
  height: 120px;
  .item {
    display: flex;
    align-items: center;
    margin: 5px 0px;
    .line {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      &::after {
        content: '';
        display: block;
        width: 23px;
        height: 2px;
        background-color: #aaa;
      }
      &::before {
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #aaa;
        position: absolute;
      }
    }
    span {
      color: #999;
      font-size: 12px;
    }
    &.active {
      .line {
        &::after {
          border: 1px solid #1e6dff;
        }
        &::before {
        }
      }
      span {
        color: #333;
      }
    }
  }
}
</style>
