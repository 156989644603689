<template>
    <a-drawer title="批量设置" :width="400" :open="visible" :body-style="{ paddingBottom: '80px' }"
        :footer-style="{ textAlign: 'right' }" @close="close">
        <a-form layout="vertical">

            <a-form-item label="部门" name="name">
                <a-select v-model:value="company_department_id" mode="tags" :field-names="{ label: 'name', value: 'id' }"
                    style="width: 100%" placeholder="选择部门">
                </a-select>
            </a-form-item>

            <a-form-item label="状态" name="name">
                <a-select ref="select" v-model:value="is_disabled" style="width: 100%" placeholder="选择状态">
                    <a-select-option :value="0" label="启用">启用</a-select-option>
                    <a-select-option :value="1" label="禁用">禁用</a-select-option>
                </a-select>
            </a-form-item>

        </a-form>
        <template #footer>
            <a-space>
                <a-button @click="close">取消</a-button>
                <a-button type="primary" @click="handleSubmit">保存</a-button>
            </a-space>
        </template>
    </a-drawer>
</template>

<script lang="ts" setup>
import { ref, reactive, computed, onMounted } from 'vue'
import baseService from '@/utils/http/axios'

import { message } from 'ant-design-vue';

onMounted(() => {
    getData()
})

const visible = ref(false)

const open = () => {
    visible.value = true
}
const close = () => {
    visible.value = false
}

defineExpose({
    open
})


const company_department_id = ref([])
const is_disabled = ref()

const list = ref([])
const getData = () => {
    baseService.post('/api/company/departmentList').then(res => {
        const data = res.data || {}
        list.value = data.list || []
    }).catch(err => {
        console.error(err)
    })
}

</script>
<style lang="less" scoped>
.tips {
    padding: 10px 16px;
    margin-bottom: 24px;
    background: #f8f8f8;
    line-height: 28px;
    color: #353535;
}

.ant-form-text {
    font-size: 14px;
    font-weight: bold;
}
</style>
