<template>
    <a-modal title="职衔方向变更申请" :width="1000" :open="visible" :confirmLoading="loading" :footer="null" @ok="handleSubmit"
        @cancel="close">
        <div class="tips">
            {{ $store.state.project.ident_change_intro }}
        </div>
        <a-form ref="formRef" :model="form" v-bind="formItemLayout">

            <a-form-item label="申请理由" required name="content">
                <a-textarea v-model:value="form.content" placeholder="请详细输入扩增理由，方便工作人员审核..."
                    :auto-size="{ minRows: 5, maxRows: 5 }" />
            </a-form-item>

            <!-- 保存按钮 -->
            <a-form-item v-bind="formTailLayout">
                <a-button type="primary" @click="handleSubmit">提交申请</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script lang="ts" setup>
import { ref, reactive, computed, onMounted, toRaw } from 'vue'
import baseService from '@/utils/http/axios'

import { Form, message } from 'ant-design-vue';

const loading = ref(false)
const visible = ref(false)

const open = () => {
    visible.value = true
}
const close = () => {
    visible.value = false
}

defineExpose({
    open
})

const formItemLayout = {
    labelCol: { span: 2 },
};
const formTailLayout = {
    wrapperCol: { offset: 2 },
};
const formRef = ref();
const form = reactive({
    content: ''
});

const handleSubmit = () => {
    baseService.post('/api/user/reqIdent', form).then(res => {
        message.success(res.msg)
        setTimeout(() => {
            formRef.value.resetFields();
            close()
        }, 700);
    }).catch(err => {
        console.error(err)
    })
};

</script>
<style lang="less" scoped>
</style>
