<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SideMenu',
})
</script>
<template>
  <a-layout-sider
    :class="['sider', device === 'desktop' ? null : 'shadow', theme, fixSiderbar ? 'ant-fixed-sidemenu' : null]"
    :width="sideWidth + 'px'"
    :collapsible="collapsible"
    v-model:collapsed="sideMenuCollapsed"
    :trigger="null"
  >
    <logo />
    <Menu :collapsed="collapsed" :menu="menus" :theme="theme" :mode="mode" @select="onSelect"></Menu>
  </a-layout-sider>
</template>

<script lang="ts" setup name="SideMenu">
import { watch, ref, computed } from 'vue'
import Logo from '@/components/tools/Logo.vue'
import Menu from './Menu.vue'
import { isDesktop } from '@/utils/device'
import useSiteSettings from '@/store/useSiteSettings'
const { fixSiderbar, device } = useSiteSettings()

const props = defineProps({
  mode: {
    type: String,
    required: false,
    default: 'inline'
  },
  theme: {
    type: String,
    required: false,
    default: 'dark'
  },
  collapsible: {
    type: Boolean,
    required: false,
    default: false
  },
  collapsed: {
    type: Boolean,
    required: false,
    default: false
  },
  menus: {
    type: Array,
    required: true
  }
})
const sideFaultWidth = ref<Number>(210)
const sideMenuCollapsed = ref(false)
const getWidth = computed(() => {
  return document.body.clientWidth
})
const sideWidth = computed(() => {
  // if(document.body.clientWidth < 1366){ // 1280
  //     return 180
  // }else if(document.body.clientWidth > 1300 && document.body.clientWidth < 1440){ //1360
  //     return 180
  // }else if(document.body.clientWidth > 1400 && document.body.clientWidth < 1920){ //1440
  //     return 180
  // }
  return 210
})

console.log(getWidth)

watch(
  () => props.collapsed,
  (newVal) => {
    sideMenuCollapsed.value = newVal
  },
  {
    immediate: true
  }
)

const emit = defineEmits(['menuSelect'])
const onSelect = (obj) => {
  emit('menuSelect', obj)
}
</script>
