import { createApp } from "vue"

import PopupContent from '@/components/PopupContent.vue'

export default (type)=>{
    const div = document.createElement('div')
    document.body.appendChild(div)
    const app = createApp(PopupContent,{
        type,
        onClick(){
            console.log('>>>>>>onclick')
        },
    })
    app.mount(div)
}