<template>
  <div class="">
    <div class="row">
      <a-radio-group v-model:value="showTable" button-style="solid" @change="(e)=>onShowTableChange(e.target.value)">
          <a-radio-button value="tableList_material" v-if="usePlan.indexOf('step3-material')>-1">材料</a-radio-button>
          <a-radio-button value="tableList_stock" v-if="usePlan.indexOf('step3-stock')>-1">成品库存</a-radio-button>
          <a-radio-button value="tableList_send_product" v-if="usePlan.indexOf('step3-send-product')>-1">发出商品</a-radio-button>
      </a-radio-group>
      <div class="table-container" >
        <vxe-table
      border
      show-overflow
      show-header-overflow
      show-footer-overflow
      ref="tableRef"
      height="400"
      :loading="tableLoading"
      :scroll-y="{enabled: true, gt: 0}"
      :scroll-x="{enabled: true, gt: 0}"
      show-footer
      :footer-data="getFooterData"
      :footer-cell-class-name="onFooterCellClassName"
      @footer-cell-click="onFooterCellClick"

      :column-config="{resizable: true}"
      :sort-config="{remote: true}"
      @sort-change="(e)=>sortChangeEvent(tableData,e)"
      :row-class-name="onRowClassName"
      :cell-class-name="onCellClassName"
      >
      <vxe-column field="dataTypeName" title="存货" width="120" ></vxe-column>
      <vxe-column field="showMonth" title="结存时间" width="120"></vxe-column>
      <vxe-column field="type" title="种类" width="120"></vxe-column>
      <vxe-column field="store" title="仓位" width="120"></vxe-column>
      <vxe-column field="abc" title="ABC分类" width="120"></vxe-column>
      <vxe-column field="customer_supper" title="客户/供应商" width="120"></vxe-column>
      <vxe-column field="no" title="材料编码" width="120"></vxe-column>
      <vxe-column field="sum_num_balance" title="结存数量" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="price_balance" title="单价" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="sum_money_balance" title="结存金额" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_30_num" title="＜30天数量" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_30_money" title="＜30天金额" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_30_money" title="＜30占比" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_60_num" title="＜60天数量" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_60_money" title="＜60天金额" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_90_num" title="＜90天数量" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_90_money" title="＜90天金额" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_180_num" title="＜180天数量" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_180_money" title="＜180天金额" width="120" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_360_num" title="＜360天数量" width="130" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_360_money" title="＜360天金额" width="130" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_360_more_num" title=">=360天数量" width="130" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      <vxe-column field="date_360_more_money" title=">=360天金额" width="130" sortable :formatter="(e)=>$dealThousands(e.cellValue )"></vxe-column>
      
    </vxe-table>
       
    
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ExclamationCircleOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue'
import { numberToFixed, checkZero, tableDefaultPage, tableDefautlLine } from '@/utils/util'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import tableSort from '../../components/tableSort.vue'

//隐藏排序功能
const tableSortFnc = () => {}
const ROWS = 15,          // 局部渲染的数据条数
      HEIGHT = 29.6,      // 每行的高度
      TABLEHEIGHT = 446;  // 表格可视高度

export default {
  name: 'SolutionTable',
  components: {
    ExclamationCircleOutlined,
    ToolsItemIntro,
    CaretDownOutlined,
    CaretUpOutlined,
    CaretUpOutlined,
  },
  props: {
    initDataState: { //数据初始化状态
      type:  String,
      default: ''
    },
    planId: {
      type: [Number, String],
      default: 0
    },
    isPrint: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: ()=>[]
    },
    tableList_material: {
      type: Array,
      default: ()=>[]
    },
    tableList_stock: {
      type: Array,
      default: ()=>[]
    },
    tableList_send_product: {
      type: Array,
      default: ()=>[]
    },

    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    },
    rows: {   // 可视区域展示多少行
      type: Number,
      default: ROWS
    },
    rowHeight: { // 每行的高度
      type: Number,
      default: HEIGHT
    },
    tableHeight: { // 可是区域高度
      type: Number,
      default: TABLEHEIGHT
    },
    usePlan: {
      type: Array,
      default: () => []
    },
    
  },
  mixins: [tableSort],
  data() {
    return {
      clearInitTimer: 0,
      showTable: '',
      showWay: '1',
      tableLoading: false,
      tableWidth: 0,
      tableLoading: false,
      tableWidth: 0,
      tableData: [],
      scrollEle: '',
      selectedRowKeys: [],
      totalHeight: 446,  // 数据总高度
      idx: 0,            // 当前开始下标

      scrollEle: '',
      selectedRowKeys: [],
      totalHeight: 446,  // 数据总高度
      idx: 0,            // 当前开始下标

      colSpan: {},
      summaryObject: {},



      topSumObj:{
        sum_num_balance:0,
        sum_money_balance:0,
        date_30_num:0,
        date_30_money:0,
        sum_num_balance:0,
        sum_money_balance:0,
        date_30_num:0,
        date_30_money:0,

        date_60_num:0,
        date_60_money:0,
        date_60_num:0,
        date_60_money:0,

        date_90_num:0,
        date_90_money:0,
        date_90_num:0,
        date_90_money:0,

        date_180_num:0,
        date_180_money:0,
        date_180_num:0,
        date_180_money:0,

        date_360_num:0,
        date_360_money:0,
        date_360_num:0,
        date_360_money:0,

        date_360_more_num:0,
        date_360_more_money:0,

        upSortData: [],
        date_360_more_num:0,
        date_360_more_money:0,

        upSortData: [],
      },

      totalSumObj:{
        sum_num_balance:0,
        sum_money_balance:0,
        date_30_num:0,
        date_30_money:0,
        date_60_num:0,
        date_60_money:0,
        date_90_num:0,
        date_90_money:0,
        date_180_num:0,
        date_180_money:0,
        date_360_num:0,
        date_360_money:0,
        date_360_more_num:0,
        date_360_more_money:0,
      },
      tableNumberField:['sum_num_balance','sum_money_balance','date_30_num','date_30_money','date_60_num','date_60_money','date_90_num','date_90_money','date_180_num','date_180_money','date_360_num','date_360_money','date_360_more_num','date_360_more_money',],
    }
  },
  computed: {
    getFooterData(){
      return [
        {
          dataTypeName: '其它',
          datetime: '--',
          type: '--',
          store: '--',
          abc: '--',
          customer_supper:'--',
          no:'--',
          price_balance:'--',
          sum_num_balance: this.$dealThousands(this.totalSumObj.sum_num_balance - this.topSumObj.sum_num_balance ),
          sum_money_balance: this.$dealThousands(this.totalSumObj.sum_money_balance - this.topSumObj.sum_money_balance ),
          date_30_num: this.$dealThousands(this.totalSumObj.date_30_num - this.topSumObj.date_30_num ),
          date_30_money: this.$dealThousands(this.totalSumObj.date_30_money - this.topSumObj.date_30_money ),
          date_60_num: this.$dealThousands(this.totalSumObj.date_60_num - this.topSumObj.date_60_num ),
          date_60_money: this.$dealThousands(this.totalSumObj.date_60_money - this.topSumObj.date_60_money ),
          date_90_num: this.$dealThousands(this.totalSumObj.date_90_num - this.topSumObj.date_90_num ),
          date_90_money: this.$dealThousands(this.totalSumObj.date_90_money - this.topSumObj.date_90_money ),
          date_180_num: this.$dealThousands(this.totalSumObj.date_180_num - this.topSumObj.date_180_num ),
          date_180_money: this.$dealThousands(this.totalSumObj.date_180_money - this.topSumObj.date_180_money ),
          date_360_num: this.$dealThousands(this.totalSumObj.date_360_num - this.topSumObj.date_360_num ),
          date_360_money: this.$dealThousands(this.totalSumObj.date_360_money - this.topSumObj.date_360_money ),
          date_360_more_num: this.$dealThousands(this.totalSumObj.date_360_more_num - this.topSumObj.date_360_more_num ),
          date_360_more_money: this.$dealThousands(this.totalSumObj.date_360_more_money - this.topSumObj.date_360_more_money ),
        },
        {
          dataTypeName: '合计',
          datetime: '--',
          type: '--',
          store: '--',
          abc: '--',
          customer_supper:'--',
          no:'--',
          price_balance:this.$dealThousands(this.totalSumObj.sum_num_balance*1!=0 ? this.totalSumObj.sum_money_balance/this.totalSumObj.sum_num_balance:0 ),
          sum_num_balance: this.$dealThousands(this.totalSumObj.sum_num_balance ),
          sum_money_balance: this.$dealThousands(this.totalSumObj.sum_money_balance ),
          date_30_num: this.$dealThousands(this.totalSumObj.date_30_num ),
          date_30_money: this.$dealThousands(this.totalSumObj.date_30_money ),
          date_60_num: this.$dealThousands(this.totalSumObj.date_60_num ),
          date_60_money: this.$dealThousands(this.totalSumObj.date_60_money ),
          date_90_num: this.$dealThousands(this.totalSumObj.date_90_num ),
          date_90_money: this.$dealThousands(this.totalSumObj.date_90_money ),
          date_180_num: this.$dealThousands(this.totalSumObj.date_180_num ),
          date_180_money: this.$dealThousands(this.totalSumObj.date_180_money ),
          date_360_num: this.$dealThousands(this.totalSumObj.date_360_num ),
          date_360_money: this.$dealThousands(this.totalSumObj.date_360_money ),
          date_360_more_num: this.$dealThousands(this.totalSumObj.date_360_more_num ),
          date_360_more_money: this.$dealThousands(this.totalSumObj.date_360_more_money ),
        },
      ]
    },
  },
  created() {},
  mounted() {

  },
  mounted() {

  },
  watch: {
    initDataState:{
      immediate:true,
      handler(value){
        if(value==='complete'){ //数据初始化完成
          this.initComponentData()
        }
      }
    },
  },
  methods: {
    initComponentData(){
      const firstPlan = this.usePlan[0]||''
      const showTable = firstPlan.replace("step3","tableList").replace('-','_')
      if(!this.showTable && showTable && this[showTable]){
        this.showTable = showTable
        this.onShowTableChange(showTable)
      }
    },
    onShowTableChange(showTable){
      console.log(showTable)
      this.tableData = this[showTable]||[];
      this.calOtherSum(this.tableData)
      this.calTotalSum(this.tableData)
      
      this.handleInitLoadData()
    },
    onRowClassName({row, column}){
      if(row.dataType.indexOf('_total')>-1){
        return 'text-bold';
      }
    },
    onCellClassName({row, column}){
      if(this.tableNumberField.indexOf(column.field)>-1 && row[column.field]*1<0){
        return 'text-red';
      }
     
    },
    sortTableFilterItem(e){
      if('dataType' in e && e.dataType.indexOf('_total')>-1){
        return false;
      }
      return true
    },
    onFooterCellClick(e){
      if(e.rowIndex===0 && e.columnIndex===0){ //点击其它
        this.showMoreTableList()
      }else{

      }
    },
    onFooterCellClassName(e){
      const field = e.column.field||''
      const value = e.items[field]
      if(e.rowIndex===0 && e.columnIndex===0){ //查看更多
        if(this.pagination===false){
          return "footer-close-more"
        }else{
          return 'footer-show-more';
        }
      }

      if(this.tableNumberField.indexOf(field)>-1 && value*1<0){
        return 'text-red';
      }else{
        return ''
      }
    },
    handleInitLoadData(){
      if(this.$refs.tableRef && this.tableData.length>0){
        this.$refs.tableRef.loadData(this.pagination===false ? this.tableData : this.tableData.slice(0,this.pagination.pageSize)).then((e) => {
          
        })
      }else{
        // this.handleInitData()
      }
      

    },
    calTotalSum(currentDataSource){
      const totalObj ={}
      for(let key in this.totalSumObj){
        const field = key
        const topSumObj_field = key;
        let total = 0;
        currentDataSource.map((item)=>{
          if(item.dataType.indexOf('_total')>-1){
            return;
          }
          if(item.dataType.indexOf('_total')>-1){
            return;
          }
          total += item[field]*1
        })
        totalObj[topSumObj_field] = total;
      }
      this.totalSumObj = totalObj
    },

    getRowClassName(item,e){
      if(item.dataType.indexOf('_total')>-1){
        return 'tr-item-weight';
      }
    },
  }
}
</script>

<style lang="less">

</style>

<style lang="less">

</style>
 
