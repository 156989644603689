import baseService from '@/utils/http/axios'



export default {
    state: {
        //文件上传信息
        uploadInfo: {},
        //开发人员信息
        company_member_num_limit: 0,
        //申请成员说明
        company_member_req_intro: '',
        //提现说明
        withdraw_money_intro: '',
        //变更身份说明
        ident_change_intro: '',
        //发票地址说明
        invoice_address_intro: '',
        //模板收益提现说明
        template_withdraw_intro: '',
        //密码设置
        password_setting_intro: '',
        //绑定手机
        bind_phone_intro: '',
        //绑定邮箱
        bind_email_intro: '',
        //每日tips
        bind_ad_tips_intro: '',
        //vip广告
        bind_vip_intro: '',
        //对公账户-名称
        corporate_account_name: '',
        //对公账户-开户名
        corporate_account_bank_name: '',
        //对公账户-开户号
        corporate_account_bank_no: '',

    },
    mutations: {

    },
    actions: {
        initConfig(context) {
            console.log(">>>>>>>>>> initConfig <<<<<<<<<<<")
            baseService.get('/api/platform/initData', {
                custom: { ignoreMessage: true }
            }).then((res) => {
                const data = res.data || {}
                context.state.company_member_num_limit = data.company_member_num_limit || ''
                context.state.company_member_req_intro = data.company_member_req_intro || ''
                context.state.withdraw_money_intro = data.withdraw_money_intro || ''
                context.state.ident_change_intro = data.ident_change_intro || ''
                context.state.invoice_address_intro = data.invoice_address_intro || ''
                context.state.template_withdraw_intro = data.template_withdraw_intro || ''

                context.state.password_setting_intro = data.password_setting_intro || ''
                context.state.bind_phone_intro = data.bind_phone_intro || ''
                context.state.bind_email_intro = data.bind_email_intro || ''
                context.state.bind_ad_tips_intro = data.bind_ad_tips_intro || ''
                context.state.bind_vip_intro = data.bind_vip_intro || ''

                context.state.uploadInfo = data.uploadInfo || {}

                context.state.corporate_account_name = data.corporate_account_name || {}
                context.state.corporate_account_bank_name = data.corporate_account_bank_name || {}
                context.state.corporate_account_bank_no = data.corporate_account_bank_no || {}
            }).catch((err) => {
                console.error("+++ initConfig +++")
                console.error(err)
                setTimeout(() => {
                    context.dispatch('initConfig')
                }, 800)
            })
        }
    }
}
