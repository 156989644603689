<template>
  <div class="user-wrapper">
    <div class="content-box">
      <div class="common-btns" v-if="isDesktop">
        <div class="notice-item">
          <router-link v-for="(item, index) in noticeList" :key="index" :to="{ path: '/notice/detail', query: { id: item.id } }">
            <span>{{ item.title }}</span>
          </router-link>
        </div>
        <div class="btns-item">
          <a-popover>
            <template #content>
              <div class="qrcode">
                <img src="" alt="联系客服" />
              </div>
            </template>
            <a-badge :count="$store.state.user.noticeNum" :offset="[6, -6]">
              <SvgIcon name="icon-geren" size="16" color="#1677ff" />
            </a-badge>
          </a-popover>
        </div>
        <div class="btns-item">
          <router-link :to="{ path: '/account/notice' }">
            <a-badge :count="$store.state.user.noticeNum" :offset="[6, -6]">
              <SvgIcon name="icon-lingdang" size="16" color="#1677ff" />
            </a-badge>
          </router-link>
        </div>
        <div class="btns-item">
          <router-link :to="{ path: '/account/setting' }">
            <SvgIcon name="icon-setting" size="16" />
          </router-link>
        </div>
        <div class="btns-item">
          <router-link :to="{ path: '/account/question' }">
            <SvgIcon name="icon-wenhao" size="16" />
          </router-link>
        </div>
      </div>

      <a-popover placement="bottomRight">
        <span class="userAction">
          <a-avatar class="avatar" :src="($store.state.user || {}).avatar" />
          <a-space direction="vertical">
            <span style="line-height: normal">{{ ($store.state.user || {}).name }}</span>
            <VipTag />
          </a-space>

          <DownOutlined :style="{ fontSize: '12px' }" />
        </span>

        <template #content>
          <div class="user-content">
            <div class="userAvatar">
              <a-avatar class="avatar" :size="50" :src="($store.state.user || {}).avatar" />
              <span class="nickname">{{ ($store.state.user || {}).name }}</span>
              <a-space>
                <VipTag />
                <UserLevel />
              </a-space>
            </div>

            <div class="userLevelInfo">
              <div class="info">
                <div class="action">
                  <em>LV{{ $store.state.user.level }}</em>
                </div>
                <div class="action">{{ $store.state.user.level_exp }}/{{ $store.state.user.total_exp }}</div>
              </div>
              <a-progress :percent="$store.state.user.level_per" :size="8" stroke-color="#0163E7" trail-color="#E4EDFF" :show-info="false" />
            </div>
            <!-- <a-divider /> -->
            <!-- <div class="userInfoBox">
                            <div class="topMsg">
                                <div class="msgItem">
                                    <div class="Itemtitle" style="white-space: nowrap;">累计收益</div>
                                    <div class="ItemNum">{{history_money}}</div>
                                </div>
                                <div class="msgItem">
                                    <div class="Itemtitle" style="white-space: nowrap;">可提现金额</div>
                                    <div class="ItemNum">{{money}}</div>
                                </div>
                                <div class="msgItem" @click="$router.push({ path: '/works/income' })">
                                    <a-button type="primary" shape="round">去提现</a-button>
                                </div>
                            </div>
                        </div> -->
            <a-divider />
            <div class="userInfoBox">
              <div class="bottomMsg">
                <div class="msgItem" @click="$router.push({ path: '/works/list/article' })">
                  <div class="ItemNum">{{ article_count }}</div>
                  <div class="Itemtitle" style="white-space: nowrap">文章</div>
                </div>
                <div class="msgItem" @click="$router.push({ path: '/works/list/asks' })">
                  <div class="ItemNum">{{ question_count }}</div>
                  <div class="Itemtitle" style="white-space: nowrap">提问</div>
                </div>
                <div class="msgItem">
                  <div class="ItemNum">{{ question_reply_count }}</div>
                  <div class="Itemtitle" style="white-space: nowrap">问答</div>
                </div>
              </div>
            </div>
            <a-divider />
            <div class="listMenu">
              <div class="item">
                <router-link :to="{ path: '/account/setting' }">
                  <SvgIcon name="icon-geren" />
                  <div>个人中心</div>
                </router-link>
              </div>
              <div class="item">
                <router-link :to="{ path: '/works/list' }">
                  <SvgIcon name="icon-works" />
                  <div>创作中心</div>
                </router-link>
              </div>
              <!-- <div class="item">
                                <router-link :to="{ path: '/account/setting' }">
                                    <SvgIcon name="icon-setting" />
                                    <span>账户设置</span>
                                </router-link>
                            </div> -->
              <!-- <a-divider /> -->
              <div class="item logout">
                <a href="javascript:;" @click="handleLogout">
                  <SvgIcon name="icon-exit" />
                  <div>退出登录</div>
                </a>
              </div>
            </div>
          </div>
        </template>
      </a-popover>
    </div>
  </div>
</template>

<script >
import { ref, reactive, computed, onMounted } from 'vue'
import baseService from '@/utils/http/axios'

import { logout } from '@/views/user/service'
import { USER_INFO } from '@/store/mutation-types'
import { Modal } from 'ant-design-vue'
import { QuestionCircleOutlined, SettingOutlined, LogoutOutlined, LockOutlined, DownOutlined } from '@ant-design/icons-vue'
import SvgIcon from '@/components/SvgIcon/index.vue'
import UserLevel from '@/components/tools/UserInfo/UserLevel.vue'
import VipTag from '@/components/VipTag.vue'

import { isMobile, isTablet, isDesktop } from '@/utils/device'
import { systemConfig } from '@/store/reactiveState'
import ls from '@/utils/Storage'
import { useRouter } from 'vue-router'
import { clearUserInfo } from '@/utils/util'
import { useStore } from 'vuex'
export default {
  components: {
    QuestionCircleOutlined,
    SettingOutlined,
    LogoutOutlined,
    LockOutlined,
    Modal,
    SvgIcon,
    UserLevel,
    VipTag,
    DownOutlined
  },
  data() {
    return {
      isMobile: isMobile,
      isTablet: isTablet,
      isDesktop: isDesktop,
      money: 0,
      history_money: 0,
      article_count: 0,
      question_count: 0,
      question_reply_count: 0,
      noticeList: []
    }
  },
  mounted() {
    this.updateInfo()
    this.getUserInfo()
    this.getNoticeData()
    this.$store.dispatch('noticeNum')
  },
  methods: {
    updateInfo() {
      this.$store.dispatch('userLevel')
    },
    getUserInfo() {
      baseService
        .post('/api/user/info')
        .then((res) => {
          const data = res.data || {}
          this.money = data.money || 0
          this.history_money = data.history_money || 0
          this.article_count = data.article_count || 0
          this.question_count = data.question_count || 0
          this.question_reply_count = data.question_reply_count || 0
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getNoticeData() {
      baseService
        .post('/api/platform/notice')
        .then((res) => {
          const data = res.data || {}
          this.noticeList = data.list || []
          console.log(this.noticeList)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    handleLogout() {
      Modal.confirm({
        title: '提示',
        content: '真的要注销登录吗？',
        onOk: () => {
          // logout().then((res) => {
          clearUserInfo()
          this.$router.push({ path: '/user/login' })
          // })
        },
        onCancel() {}
      })
    }
  }
}

// import { ref, reactive, computed, onMounted } from 'vue';
// import baseService from '@/utils/http/axios'

// import { logout } from '@/views/user/service'
// import { USER_INFO } from '@/store/mutation-types'
// import { Modal } from 'ant-design-vue'
// import {
//     QuestionCircleOutlined,
//     SettingOutlined,
//     LogoutOutlined,
//     LockOutlined,
//     DownOutlined
// } from '@ant-design/icons-vue'
// import SvgIcon from '@/components/SvgIcon/index.vue'
// import UserLevel from '@/components/tools/UserInfo/UserLevel.vue'

// import { isMobile, isTablet, isDesktop } from '@/utils/device'
// import { systemConfig } from '@/store/reactiveState'
// import ls from '@/utils/Storage'
// import { useRouter } from 'vue-router'
// import { clearUserInfo } from '@/utils/util'
// import { useStore } from 'vuex'
// const store = useStore()
// onMounted(() => {
//     getNoticeData()
//     store.dispatch('noticeNum')
// })
// const noticeList = ref([])
// const getNoticeData = () => {
//     baseService.post('/api/platform/notice',).then(res => {
//         const data = res.data || {}
//         noticeList.value = data.list || []
//     }).catch(err => {
//         console.error(err)
//     })
// }

// const props = defineProps(['theme'])
// const router = useRouter()
// const UserInfo = ls.get(USER_INFO)
// const handleLogout = () => {
//     Modal.confirm({
//         title: '提示',
//         content: '真的要注销登录吗？',
//         onOk: () => {
//             // logout().then((res) => {
//             clearUserInfo()
//             router.push({ path: '/user/login' })
//             // })
//         },
//         onCancel() { }
//     })
// }
</script>
<style lang="less" scoped>
.ant-divider-horizontal {
  margin: 16px 0px;
}

.user-wrapper {
  display: flex;
  align-items: center;

  .content-box {
    display: flex;
    align-items: center;

    .common-btns {
      display: flex;
      align-items: center;

      .notice-item {
        min-width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background-color: #e8f1fe;
        margin-right: 16px;
        padding: 0px 24px;

        span {
          color: #333333;
          font-size: 12px;
        }
      }

      .btns-item {
        min-width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background-color: #e8f1fe;
        margin-right: 16px;
        .qrcode {
          width: 50px;
          height: 50px;
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .userAction {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      display: block;
      margin: 0px 4px;
      line-height: 1.5;
    }
  }

  .ant-popover-placement-bottomRight .ant-popover-arrow {
    display: none;
  }
  .ant-popover .ant-popover-inner {
    border-radius: 12px;
  }
}

.user-content {
  width: 260px;
  padding: 20px;

  .userAvatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: -24px;

    .nickname {
      font-size: 16px;
      font-weight: bold;
      margin: 6px 0;
    }
  }

  .userLevelInfo {
    padding-top: 12px;

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .userInfoBox {
    .msgItem {
      .ItemNum {
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 18px;
        font-weight: bold;
        color: #28282a;
        display: flex;
        align-items: center;
      }
    }

    .topMsg {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .bottomMsg {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .msgItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 12px;
        cursor: pointer;
      }
    }
  }

  .listMenu {
    margin-left: -12px;
    margin-right: -12px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .ant-divider-horizontal {
      margin: 2px 0px;
    }

    .item {
      width: 100%;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 40px;
        color: #333333;

        &:hover {
          background-color: #f5f5f5;
        }
      }

      span {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
