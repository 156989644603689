<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FileUpload',
})
</script>
<template>
    <a-upload v-model:file-list="modelFileList" :action="$store.state.project.uploadInfo.url" :headers="$store.state.project.uploadInfo.data"
        :maxCount="limit" :list-type="listType" :multiple="limit > 1" :accept="accept" :showUploadList="showUploadList"
        @change="onChange">
        <!-- 插槽出口 -->
        <slot></slot>

    </a-upload>
</template>

<script lang="ts" setup name="FileUpload">
import { ref, reactive, watch, onMounted, toRefs } from 'vue'
import baseService from '@/utils/http/axios'

// import type { UploadProps } from 'ant-design-vue';

import { useRouter } from 'vue-router'
const router = useRouter()

const props = defineProps({
    value:{
        type:[Array,String],
        default:""
    },
    limit: {
        type: Number,
        default: 6,
    },
    listType: {
        type: String,
        default: 'picture-card'
    },
    accept: {
        type: String,
        default: ''
    },
    showUploadList: {
        type: Boolean,
        default: true
    },

})
const emit = defineEmits(['change'])


import type { UploadProps } from 'ant-design-vue';
import { getFileSize } from '@/utils/util';
const modelFileList = ref([])

watch(()=>props.value,(value,oldValue)=>{
    console.log('watch-value', value)
    console.log('watch-value', modelFileList.value)
    // if(!value || !value.length){}
    let {resultData, fsize} = getFileList(modelFileList.value)
    let str = "";
    try{
        str = JSON.stringify(value)
    }catch(e){ }
    let allFiles = []
    console.log('watch-value-if', str[0])
    console.log('watch-value-if1', value)
    if(str[0]==='[' ){ //数组
        allFiles = value
    }else{
        if(value){
            allFiles = [value]
        }
    }
    console.log('watch-value-allFiles', allFiles)
    if(!allFiles.length){
        modelFileList.value = []
        return;
    }

    if(resultData.join(',') === allFiles.join(',')){
        return;
    }
    modelFileList.value = []
    allFiles.map(function(file){
        modelFileList.value.push({
            name: file,
            status: 'done',
            url: file,
        })
    })



}, { deep: true })

function getFileList(fileList){
    const resultData = [];
    let fsize = 0;
    fileList.map(function (item) {
        console.log(item)
        let key = ""
        if('response' in item){
            const res = item.response || {}
            const data = res.data || {}
            const dataFsize = data.fsize || 0
            key = data.key||''
            fsize += dataFsize;
        }else if('url' in item){
            key = item.url
            fsize += getFileSize(item.url)
        }else{
            return;
        }
        
        
        resultData.push(key)
    })
    return {resultData,fsize}
}


function onChange({ file, fileList }) {
    console.log('FileUpload-onChange',file, fileList)
    if(['removed','done'].indexOf(file.status)>-1){
        let {resultData, fsize}  = getFileList(fileList)
        emit('change', resultData, fsize)
    }
   
}

</script>
