<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Line',
})
</script>
<template>
    <div :id="id" style="width:500px;height:375px;"></div>
</template>
<script lang="ts" setup name="Line">
import { computed, onMounted, ref ,watch,nextTick} from 'vue'
import * as echarts from 'echarts';
import { numberToFixed } from '@/utils/util';

const props = defineProps([ 
  //echart id
  'id',
  //总销量
  'xMaxNum',
  ////不含税单价
  'comeInPrice',
  //成本单价
  'materialCostPrice' ,
  //固定成本计算范围值
  'fixedRangeCostFnc',
  //混合成本计算范围值
  'mixRangeCostFnc',
  //原单位变动成本总成本
  'sumMaterialCost',
  //固定成本
  'fixedMoney',
  //边际贡献
  'sideMoeny',
  //单位边际贡献
  'sideUnitMoeny',
  //毛利润
  'profitMoeny',
])

const echartId = ref(  'echartId-'+(new Date()).getTime())
const myChart = ref()

//平分等分
const xAxis_divide = 1000;

const xAxisData = ref([])
//最大数值范围变化
watch(()=>props.xMaxNum,(value)=>{
  xMaxNumChange(value)
})
const xMaxNumChange = (value)=>{
    console.log('>>>>>>>>>>><<<<<<<<<<<')
    console.log(value)

    const xMaxNum = Math.ceil(value??0);
    const step = xMaxNum / xAxis_divide;
    const xAxisDataGroup = [];
    for(let i=0; i<xMaxNum; i++){
        xAxisDataGroup.push(numberToFixed ( step*(i+1)) )
    }
    xAxisData.value = xAxisDataGroup
    nextTick(()=>{
        drawEchart()
    })
}


onMounted(()=>{
    initEchart()
    //初始化x轴
    xMaxNumChange(props.xMaxNum)

})


//总收入
const echartData = ref([
    //收入=0*加权平均单位不含税售价     1.7*加权平均单位不含税售价   3.4*加权平均单位不含税售价  5.1*加权平均单位不含税售价...
    {name:'comeInPrice',data:{ name: '总收入', type: 'line', stack: 'comeInPrice', data:[]}},
    //总成本＝变动成本总额+固定成本总额 + 对应销量区间的混合成本总额
    {name:'materialCostMixRagePrice',data:{ name: '总成本', type: 'line', stack: 'materialCostMixRagePrice', data:[]}},
    //变动成本总额＝产量*加权平均单位变动成本
    {name:'materialCostPrice',data:{ name: '总变动成本', type: 'line', stack: 'materialCostPrice', data:[]}},
    //总固定成本=固定成本总额
    {name:'fixedMoeny',data:{ name: '总固定成本', type: 'line', stack: 'fixedMoeny', data:[]}},
    //总混合成本=对应销量区间混合成本总额
    {name:'mixMoeny',data:{ name: '总混合成本', type: 'line', stack: 'mixMoeny', data:[]}},
    //加成后的总边际贡献 = 总收入-总变动成本
    {name:'sideMoeny',data:{ name: '总边际贡献', type: 'line', stack: 'sideMoeny', data:[]}},
    //毛利润 = 总收入-变动成本总额-制造环节固定成本总额-制造环节对应销量所在区间的混合成本总额
    {name:'profitMoeny',data:{ name: '毛利润', type: 'line', stack: 'profitMoeny', data:[]}},
    //管理利润 = 总收入-变动成本总额-固定成本总额-对应销售区间混合成本总额
    {name:'managerProfitMoeny',data:{ name: '管理利润', type: 'line', stack: 'managerProfitMoeny', data:[]}},
    //预测X轴点 = 管理利润为0的点的销量
    {name:'prediction',data:{ name: '预测', type: 'line', stack: 'prediction', data:[]}},
]);

const checkEchartDataIndex = (name)=>{
    let index = -1;
    for(let i=0;echartData.value.length;i++){
        const info = echartData.value[i]
        if(info.name===name){
            index = i;
            break;
        }
    }
    return index;
}

//总收入 = 不含税售价✖销量再合计所有的产品
watch(()=>[props.comeInPrice,xAxisData.value],(info)=>{
    const value = info[0];
    const xAxis = info[1];
    const data = [];
    xAxis.map(function(item){
        data.push( numberToFixed( item * value ))
    })
    echartData.value[checkEchartDataIndex('comeInPrice')].data.data = data
})

 //总成本＝变动成本总额+固定成本总额 + 对应销量区间的混合成本总额
watch(()=>[props.materialCostPrice,xAxisData.value],(info)=>{
    const value = info[0];
    const xAxis = info[1];
    const data = [];
    xAxis.map(function(item){
        data.push( numberToFixed( props.sumMaterialCost*1 + props.mixRangeCostFnc('total', item) ))
    })
    echartData.value[checkEchartDataIndex('materialCostMixRagePrice')].data.data = data
})

 //变动成本总额＝产量*加权平均单位变动成本
watch(()=>[props.sumMaterialCost,xAxisData.value],(info)=>{
    const value = info[0];
    const xAxis = info[1];
    const data = [];
    xAxis.map(function(item){
        data.push( numberToFixed( numberToFixed( value * item )) )
    })
    echartData.value[checkEchartDataIndex('materialCostPrice')].data.data = data
})

 //总混合成本=对应销量区间混合成本总额
watch(()=>[xAxisData.value],(info)=>{
    const xAxis = info[0];
    const data = [];
    xAxis.map(function(item){
        data.push( props.mixRangeCostFnc('total', item))
    })
    echartData.value[checkEchartDataIndex('mixMoeny')].data.data = data
})

//总边际贡献=总收入-总变动成本
watch(()=>[props.sideUnitMoeny,xAxisData.value],(info)=>{
    const value = info[0];
    const xAxis = info[1];
    const data = [];
    xAxis.map(function(item){
        data.push( numberToFixed( props.sideUnitMoeny*item ))
    })
    echartData.value[checkEchartDataIndex('sideMoeny')].data.data = data
})

//毛利润 = 总收入-变动成本总额-制造环节固定成本总额-制造环节对应销量所在区间的混合成本总额
watch(()=>[xAxisData.value, props.comeInPrice,],(info)=>{
    const xAxis = info[0];
    const comeInPrice = info[1];
    const data = [];
    xAxis.map(function(item){
        data.push( numberToFixed( item*1 * (comeInPrice - props.fixedRangeCostFnc('zhizao_sum') - props.mixRangeCostFnc('zhizao_sum', item) )  ) )
    })
    echartData.value[checkEchartDataIndex('profitMoeny')].data.data = data
})

//管理利润 = 总收入-变动成本总额-固定成本总额-对应销售区间混合成本总额
watch(()=>[xAxisData.value, props.comeInPrice, props.sumMaterialCost],(info)=>{
    const xAxis = info[0];
    const comeInPrice = info[1];
    const sumMaterialCost = info[2];
    const data = [];
    xAxis.map(function(item){
        data.push( numberToFixed( item*1 * (comeInPrice - sumMaterialCost - props.mixRangeCostFnc('total', item) )  ) )
    })
    echartData.value[checkEchartDataIndex('managerProfitMoeny')].data.data = data
})

//预测X轴点 = 管理利润为0的点的销量
watch(()=>[xAxisData.value],(info)=>{
    const xAxis = info[0];
    
    echartData.value[checkEchartDataIndex('prediction')].data.data = []
})



watch(echartData,(value)=>{
   
    drawEchart()
},{deep:true})

const initEchart = ()=>{
    var chartDom = document.getElementById(props.id);
    myChart.value = echarts.init(chartDom);
    drawEchart()
}

const drawEchart = ()=>{
    var option;

    option = {
      title: {
        text: ''
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: echartData.value.map((item)=>item.data.name)
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisData.value
      },
      yAxis: {
        type: 'value'
      },
      series: echartData.value.map((item)=>item.data)
    };


option && myChart.value.setOption(option);
}

</script>
<style lang="less">
</style>
