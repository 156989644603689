<template>
  <div class="import-steps">
    <div class="import-steps_head">
        <div class="tag">STEPS {{ getIndex }}</div>
        <a-button type="text" @click="handleDownTemp(item)">
            模版下载
            <template #icon>
                <download-outlined />
            </template>
        </a-button>
    </div>
    <div class="import-steps_desc">
        <tools-item-intro :type="type" :field="getIntroField" moduleName="background"/>
    </div>
    <div class="import-steps_upload">
        <a-spin :spinning="item.uploading=='uploading'">
            <!--  -->
            <a-upload-dragger accept=".xls,.xlsx,.csv" :max-count="1"  :headers="uploadHeaders" :showUploadList="false"
                :beforeUpload="beforeUpload" 
                :customRequest="localParse?onCustomRequest:null"
                name="file" :action="uploadAction" @change="handleChange" :data="{type:item.temp,plan:JSON.stringify(uploadData)}"
                >
                <div class="ant-upload-custom">


                    <template v-if="item.file && item.file.id">
                        <p class="ant-upload-custom-icon">
                            <CheckCircleOutlined :style="{ color: '#1e6dff' }" />
                        </p>

                        <div class="ant-upload-custom-file">{{ item.file.filename }}</div>
                    </template>
                    <template v-else>
                        <p class="ant-upload-custom-icon">
                            <InboxOutlined :style="{ color: '#1e6dff' }" />
                        </p>
                        <p class="ant-upload-custom-text">将文件拖到此处，或 点击上传</p>
                    </template>

                    <p class="ant-upload-custom-hint">仅支持后缀名为xls、xlsx、csv的Excel文件，文件大小限10M以内</p>
                </div>

                <div class="ant-upload-drag-btns">
                    <a-button type="text">
                        本地上传
                    </a-button>
                    <a-button type="text" @click.stop="$emit('handleHisFile')">
                        历史上传
                    </a-button>
                </div>
            </a-upload-dragger>
        </a-spin>
    </div>

    <div class="import-steps_name">{{ item.name }}</div>
</div>
</template>

<script>
import * as XLSX from 'xlsx'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import { InboxOutlined, DownloadOutlined, DownCircleFilled,CheckCircleOutlined } from '@ant-design/icons-vue';
import { baseURL, download } from '@/utils/util';
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import ls from '@/utils/Storage'
import { message } from 'ant-design-vue';
import { Exception } from 'sass';
export default {
    name:'ToolsImport',
    components:{
        InboxOutlined,DownCircleFilled,DownloadOutlined,CheckCircleOutlined,ToolsItemIntro
    },
    props:{
        index:{
            type:Number,
            default:1,
        },
        introField:{
            type:String,
            default:'',
        },
        localParse:{
            type:Boolean,
            default:true,
        },
        xlsxMoreSheet:{ //解析多sheet
            type:Boolean,
            default:false,
        },
        type:{
            type:String,
            required:true,
        },
        item:{
            type:Object,
            default:()=>{},
            required:true,
        },
        uploadData:{
            type:Object,
            default:()=>{},
            required:true,
        },
        beforeUpload:{
            type:Function,
            default:(file,fileList)=>{}
        }
    },
    
    data(){
        return {
            uploading:false,
        }
    },
    computed:{
       getIndex(){
        return this.index+1
       },
        uploadAction(){
            return `${baseURL}/api/tools/excelUpload`
        },
        uploadHeaders(){
            return {Authorization:'Bearer '+ls.get(ACCESS_TOKEN)}
        },
        getIntroField(){
            let intro = 'import_intro'+this.getIndex;
            if(this.introField){
                intro = this.introField
            }
            return intro;
        },
    },
    methods:{
       
        handleChange(info){
            this.$emit('handleChange',info)
        },
        handleDownTemp(item){
            download(`${baseURL}/excel-temp/${item.temppath}`)
        },
        onCustomRequest(customRequestData){
            const that = this;
            const { file, onProgress, onSuccess,onError } = customRequestData; // 结构出内置的方法
            const reader = new FileReader();
            reader.onload = function(e) {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                // 假设我们只读取第一个工作表
                let jsonData = [];
                if(that.xlsxMoreSheet){
                    for(let i=0;i<workbook.SheetNames.length;i++){
                        const firstSheetName = workbook.SheetNames[i];
                        const worksheet = workbook.Sheets[firstSheetName];
                        const json = XLSX.utils.sheet_to_json(worksheet,{header: 1});
                        jsonData.push(json)
                    }
                }else{
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet,{header: 1});
                    jsonData = json
                }
                that.$network('/api/tools/excelUploadSaveData',{
                    type:that.item.temp,
                    plan:that.uploadData,
                    excelData:jsonData,
                    fname:file.name,
                    fsize:file.size,
                    ftype:file.type,
                }).then(res=>{
                    onProgress({percent:100})
                    onSuccess(res)
                }).catch(err=>{
                    onError(err)
                })
                //处理数据
                // console.log(jsonData); // 这里是Excel内容的JSON表示
                // onProgress({percent:100},file)
                // onSuccess({code:200,excelData:json},file)
            };
            reader.readAsArrayBuffer(file);
        },
    }
}
</script>
<style lang="less" scoped>
.import {
    .vabTabsBox {
        border-bottom: 0 !important;

        .title {
            font-size: 18px;
            font-weight: bold;
            height: 64px;
            display: flex;
            align-items: center;

            &::before {
                content: '';
                display: block;
                width: 6px;
                height: 20px;
                border-radius: 8px;
                margin-right: 10px;
                background: #1e6dff;
            }
        }
    }

    .body {
        height: auto;
    }
}
</style>


<style lang="less">
.import {
    .ant-alert-info {
        border: none;
        background: rgba(230, 244, 255, 0.5);

    }

    .import-box {
        // display: flex;
        // flex-wrap: wrap;
        margin-top: 40px;

        .import-steps {
            // width: calc((100% - 120px)/4);
            // margin-right: 40px;
            margin-bottom: 48px;

            // &:nth-child(4n) {
            //     margin-right: 0;
            // }

            &_head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                .tag {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 70px;
                    height: 28px;
                    background: #1E6DFF;
                    border-radius: 0px 30px 30px 0px;
                    color: #ffffff;
                }
            }

            &_desc {
                color: #333333;
                line-height: 24px;
                margin-bottom: 20px;
            }

            &_upload {
                margin-bottom: 20px;

                .ant-upload-wrapper {
                    .ant-upload-drag {
                        border: 1px dashed #1E6DFF;
                        background: rgba(232, 241, 254, 0.19);
                        border-radius: 10px;

                        .ant-upload-btn {
                            height: 200px;
                            padding: 0px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .ant-upload-custom {
                    flex: 1;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 12px 6px;

                    .ant-upload-custom-icon {
                        margin-bottom: 16px;

                        .anticon {
                            font-size: 48px;
                        }
                    }

                    .ant-upload-custom-file {
                        color: #1e6dff;
                        font-weight:bold;
                    }

                    .ant-upload-custom-text {
                        margin: 0 0 4px;
                        color: rgba(0, 0, 0, 0.88);
                    }

                    .ant-upload-custom-hint {
                        color: rgba(0, 0, 0, 0.45);
                        font-size: 12px;
                        margin: 0;
                    }
                }

                .ant-upload-drag-btns {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .ant-btn {
                        flex: 1;
                        height: 40px;
                    }
                }
            }

            &_name {
                font-size: 16px;
                text-align: center;
                margin-bottom: 12px;
            }

            &_alert {
                color: #FF0000;
                text-align: center;
            }
        }
    }

    .import-btns {
        display: flex;
        flex-wrap: wrap;

        .ant-btn {
            width: 120px;
            height: 40px;
            border-radius: 6px;
            margin-right: 20px;
        }
    }
}
</style>
