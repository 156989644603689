<template>
  <div class="solution-children">
    <div class="head">工序投入产出明细表</div>
    <div class="row">
      <a-radio-group v-model:value="showWay" name="radioGroup" optionType="button" button-style="solid">
        <a-radio value="1">明细</a-radio>
        <a-radio value="2">基本成本</a-radio>
      </a-radio-group>
    </div>

    <div class="row">
      <div class="table-children">
        <a-table :columns="columns" :data-source="tools2_excelData_tableData" :pagination="pagination" size="small" bordered :scroll="{ x: 1500,y: 650 }"  @resizeColumn="handleResizeColumn">
          <template #headerCell="{ column, text }">
            <template v-if="column.helper">
              <span>
                <a-tooltip>
                  <template #title>{{ column.helper }}</template>
                  <ExclamationCircleOutlined />{{ column.title }}
                </a-tooltip>
              </span>
            </template>
          </template>

          <template #bodyCell="{ column, record, text }">
            <template v-if="column.dataIndex === 'min_product_num'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).min_product_num || $numberToFixed(0) }}</a>
            </template>
            <template v-if="column.dataIndex === 'pass_per'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).pass_per || $numberToFixed(0) }}</a>
            </template>
            <template v-if="column.dataIndex === 'stand_in_stock'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).stand_in_stock || $numberToFixed(0) }}</a>
            </template>

            <template v-if="column.dataIndex === 'need_stock'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).need_stock || $numberToFixed(0) }}</a>
            </template>

            <template v-if="column.dataIndex === 'is_need_stock'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).is_need_stock }}</a>
            </template>

            <template v-if="column.dataIndex === 'serial_stand_actuality_cost_total_current'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).serial_stand_actuality_cost_total_current }}</a>
            </template>

            <template v-if="column.dataIndex === 'sold_num'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).sold_num }}</a>
            </template>

            <template v-if="column.dataIndex === 'stock'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).stock }}</a>
            </template>

            <template v-if="column.dataIndex === 'serial_stand_product_num_stand_actuality_min'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).serial_stand_product_num_stand_actuality_min }}</a>
            </template>

            <template v-if="column.dataIndex === 'trans_in_money'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).trans_in_money }}</a>
            </template>

            <template v-if="column.dataIndex === 'sold_num_begin'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).sold_num_begin }}</a>
            </template>

            <template v-if="column.dataIndex === 'sold_num_end'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).sold_num_end }}</a>
            </template>

            <template v-if="column.dataIndex === 'cost_begin'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).cost_begin }}</a>
            </template>

            <template v-if="column.dataIndex === 'stock_cost_price_begin'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).stock_cost_price_begin }}</a>
            </template>

            <template v-if="column.dataIndex === 'stock_cost_to_sold_money_begin'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).stock_cost_to_sold_money_begin }}</a>
            </template>

            <template v-if="column.dataIndex === 'stock_cost_to_sold_money_current'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).stock_cost_to_sold_money_current }}</a>
            </template>

            <template v-if="column.dataIndex === 'sold_money_fifo'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).sold_money_fifo }}</a>
            </template>

            <template v-if="column.dataIndex === 'cost_avco'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).cost_avco }}</a>
            </template>

            <template v-if="column.dataIndex === 'price_avco'">
              <a href="javascript:;">{{ (colSpan[record.customerNo] || {}).price_avco }}</a>
            </template>


          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { numberToFixed, checkZero } from '@/utils/util'
export default {
  name: 'SolutionSerialProduction',
  components: {
    ExclamationCircleOutlined
  },
  props: {
    planId: {
      type: [Number, String],
      default: 0
    },
    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    }
  },
  data() {
    return {
      showWay: '1',
      colSpan: {},
      columns: [
        { title: '产品编码ID', dataIndex: 'no', align: 'center', width: 100, fixed: 'left',resizable: true },
        { title: '客户', dataIndex: 'customer', align: 'center', width: 100, fixed: 'left',resizable: true },
        { title: '产品规格/描述', dataIndex: 'attr', align: 'center', width: 150 ,resizable: true},
        { title: '计量单位', dataIndex: 'unit', align: 'center', width: 100,resizable: true },
        { title: '工序（组）ID', dataIndex: 'serial_id', align: 'center', width: 100,resizable: true },
        { title: '工序（组）名称', dataIndex: 'serial_name', align: 'center', width: 100,resizable: true },
        { title: '基本成本中心', dataIndex: 'cost_name', align: 'center', ellipsis: true, width: 100,resizable: true },
        { title: '成本中心属性', dataIndex: 'cost_attr', align: 'center', ellipsis: true, width: 100,resizable: true },
        { title: '期初余额', dataIndex: 'balance_start', align: 'center', width: 100,resizable: true },
        { title: '本期投入-料', dataIndex: 'deadline_material', align: 'center', width: 100,resizable: true },
        { title: '本期投入-工', dataIndex: 'deadline_worker', align: 'center', width: 100 ,resizable: true},
        { title: '本期投入-费', dataIndex: 'deadline_money', align: 'center', width: 100,resizable: true },
        { title: '期末余额', dataIndex: 'balance_end', align: 'center', width: 100,resizable: true },
        { title: '净投入额', dataIndex: 'amount', align: 'center', width: 100,resizable: true },
        { title: '工序（组）标准产量 ', helper: '(未考虑工序废品影响，含废品）', dataIndex: 'serial_stand_product_num', align: 'center', width: 120,resizable: true },
        { title: '折合产品产量', dataIndex: 'serial_stand_product_num_stand', align: 'center', width: 100 ,resizable: true},

        { title: '产品标准产量', dataIndex: 'min_product_num', align: 'center', width: 100, customCell: this.tableCustomCell,resizable: true },
        { title: '产品客户合格率', dataIndex: 'pass_per', align: 'center', width: 100, customCell: this.tableCustomCell,resizable: true },
        { title: '产品标准完工入库量', dataIndex: 'stand_in_stock', align: 'center', width: 100, customCell: this.tableCustomCell,resizable: true },
        { title: '销售净需求', dataIndex: 'need_stock', align: 'center', width: 100, customCell: this.tableCustomCell,resizable: true },
        { title: '标准产出是否满足销售', dataIndex: 'is_need_stock', align: 'center', width: 100, customCell: this.tableCustomCell,resizable: true },

        { title: '工序标准单位变动成本', dataIndex: 'serial_stand_cost', align: 'center', width: 100,resizable: true },
        { title: '工序（组）实际产量', dataIndex: 'serial_product_num', align: 'center', width: 100,resizable: true },

        { title: '工序实际单位成本', dataIndex: 'serial_stand_actuality_cost', align: 'center', width: 100,resizable: true },
        { title: '工序完工成本', dataIndex: 'serial_stand_actuality_cost_total', align: 'center', width: 100,resizable: true },
        { title: '当期完工产品实际单位变动成本', dataIndex: 'serial_stand_actuality_cost_total_current', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},

        { title: '转入存货变动金额', dataIndex: 'trans_in_money', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '销量', dataIndex: 'sold_num', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '期初库存', dataIndex: 'stock', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '实际入库量', dataIndex: 'serial_stand_product_num_stand_actuality_min', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '期初转销售出库量', dataIndex: 'sold_num_begin', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '入库转销售出库量', dataIndex: 'need_stock', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '完工入库实际单位变动成本', dataIndex: 'trans_in_money', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '期末存货部分结存量', dataIndex: 'sold_num_end', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '期初存货单位成本', dataIndex: 'stock_cost_price_begin', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '期初存货转销售', dataIndex: 'stock_cost_to_sold_money_begin', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '当期完工转销售', dataIndex: 'stock_cost_to_sold_money_current', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '转入销售成本（FIFO）', dataIndex: 'sold_money_fifo', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '加权单位变动成本', dataIndex: 'cost_avco', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},
        { title: '转入销售成本（AVCO）', dataIndex: 'price_avco', align: 'center', width: 100 , customCell: this.tableCustomCell,resizable: true},


      ]
    }
  },
  computed: {
    ...mapGetters(['tools2_excelData_serial_stand']),
    ycStockData_customerNo() {
      return this.$store.state.tools2.ycStockData_customerNo
    },
    tools2_excelData_serial_stand__no_and_serial_group() {
      return this.$store.state.tools2.tools2_excelData_serial_stand__no_and_serial_group
    },
    tools2_excelData_serial_product() {
      return this.$store.state.tools2.tools2_excelData_serial_product
    },
    tools2_excelData_tableData() {
      return this.$store.state.tools2.tools2_excelData_tableData
    }
  },
  created() {},
  mounted() {
    this.createWatch_tableData()

    this.getDetail()
  },
  watch: {},
  methods: {
    getDetail() {
      return this.$network('/api/tools/planResultData', {
        id: this.planId,
        type: 'step2-serial-production'
      })
        .then((res) => {
          const data = res.data || {}
          const list = data.list || []
          this.$store.commit('updateTools2State', { field: 'tools2_excelData_serial_product', value: list })
        })
        .catch((err) => {
          console.error(err)
        })
    },

    tableCustomCell(item, index) {
      const customerNo = item.customerNo || ''
      const customerNoObj = this.colSpan[customerNo] || {}
      const arr = customerNoObj.index || []
      const len = arr.length || 0
      const colSpanIndex = arr.indexOf(index)
      return { rowSpan: colSpanIndex === 0 ? len : 0 }
    },
    createWatch_tableData() {
      this.$watch(
        () => [this.tools2_excelData_serial_product, this.tools2_excelData_serial_stand__no_and_serial_group, this.ycStockData_customerNo],
        (info) => {
            //原数据
            const excelData = info[0] || []
            //单位产品工序用量与标准成本表
            const serial_group = info[1] || {}
            //预测表
            const ycStock_customerNo = info[2] || {}
            // console.log('>>>>>>>>>>>>>>> createWatch_tableData <<<<<<<<<<<<')
            // console.log(ycStock_customerNo)
            //实际最小产量
            const serial_stand_product_num_stand_actuality_min_obj = {}

            const tableData = excelData.map((item) => {
              //客户分组
              const customerNo = item.customerNo || ''
              const ycStock_customerNoInfo = ycStock_customerNo[customerNo] || {}
              // 产品编码Id + 工序组 ID
              const serial_id_no = item.serial_id_no || ''
              if ( !(customerNo in serial_stand_product_num_stand_actuality_min_obj )){
                serial_stand_product_num_stand_actuality_min_obj[customerNo] = {}
              }
              if ( !(serial_id_no in serial_stand_product_num_stand_actuality_min_obj[customerNo] )){
                serial_stand_product_num_stand_actuality_min_obj[customerNo][serial_id_no] = {
                  serial_stand_product_num_stand_actuality:0,
                }
              }

              //净投入额
              const amount = item.amount
              // 标准成本表
              const serial_stand_info = serial_group[serial_id_no] ?? {}
              //工序标准单位变动成本
              const serial_stand_cost = numberToFixed(serial_stand_info.cost)
              //产品标准用量
              const serial_stand_stand = numberToFixed(serial_stand_info.stand)
              item.serial_stand_stand = serial_stand_stand
              item.serial_stand_cost = serial_stand_cost
              //工序（组）标准产量 （未考虑工序废品影响，含废品）
              const serial_stand_product_num = checkZero(serial_stand_cost) ? 0 : numberToFixed(amount / serial_stand_cost)
              item.serial_stand_product_num = serial_stand_product_num
              //实际折合产品产量
              const serial_stand_product_num_stand_actuality = checkZero(serial_stand_stand) ? 0 : numberToFixed(item.serial_product_num / serial_stand_stand)
              item.serial_stand_product_num_stand_actuality = serial_stand_product_num_stand_actuality
              serial_stand_product_num_stand_actuality_min_obj[customerNo][serial_id_no].serial_stand_product_num_stand_actuality = serial_stand_product_num_stand_actuality

              //产品标准产量
              item.min_product_num = 0

              //产品客户合格率
              item.pass_per = numberToFixed(ycStock_customerNoInfo.pass_per || 0)
              //产品标准完工入库量（标准产量×合格率）
              item.stand_in_stock = 0
              //销售净需求（同中间表1)
              item.need_stock = numberToFixed(ycStock_customerNoInfo.need_stock || 0)
              //标准产出是否满足销售
              item.is_need_stock = 'Y'
              //工序实际单位成本
              const serial_stand_actuality_cost =checkZero(item.serial_product_num) ? 0 : numberToFixed( amount / item.serial_product_num)
              item.serial_stand_actuality_cost = serial_stand_actuality_cost
              //工序完工成本
              item.serial_stand_actuality_cost_total = checkZero(item.serial_product_num) ? 0 :numberToFixed( serial_stand_actuality_cost*item.serial_product_num )
              //当期完工产品 实际单位变动成本
              item.serial_stand_actuality_cost_total_current = 0

              return item
            })

            let colSpan = {}
            tableData.map((item, index) => {
              const customerNo = item.customerNo
              const serial_id_no = item.serial_id_no || ''
              const ycStock_customerNoInfo = ycStock_customerNo[customerNo] || {}

              const stand_actuality_min_obj = serial_stand_product_num_stand_actuality_min_obj[customerNo]||{}
              let serial_stand_product_num_stand_actuality_min = 0;
              for(let key in stand_actuality_min_obj){
                const minInfo = stand_actuality_min_obj[key]
                const minValue = minInfo.serial_stand_product_num_stand_actuality
                if(serial_stand_product_num_stand_actuality_min===0 || serial_stand_product_num_stand_actuality_min*1 > minValue*1){
                  serial_stand_product_num_stand_actuality_min = minValue
                }
              }

              if (!(customerNo in colSpan)) {
                const sold_num = ycStock_customerNoInfo.sold_num||0
                const stock = ycStock_customerNoInfo.stock||0
                const serial_stand_product_num_stand_actuality_min_ceil =Math.ceil(serial_stand_product_num_stand_actuality_min);
                const sold_num_begin = stock*1 > sold_num*1 ? sold_num : stock ;
                const stock_cost_price_begin = ycStock_customerNoInfo.cost_price||0;

                colSpan[customerNo] = {
                  index: [],
                  //产品标准产量
                  min_product_num: item.serial_stand_product_num_stand,
                  stand_in_stock: item.serial_stand_product_num_stand * item.pass_per,
                  is_need_stock: 'Y',
                  pass_per: item.pass_per,
                  need_stock: item.need_stock,
                  sold_num: sold_num,
                  stock: stock,
                  sold_num_begin: sold_num_begin,
                  sold_num_end: stock*1+ serial_stand_product_num_stand_actuality_min_ceil*1 - sold_num ,
                  //投入成本
                  cost_sum: 0,
                  //期初存货单位成本
                  cost_begin:0,
                  serial_stand_actuality_cost_total_current: 0,
                  //实际完工产品产量（实际入库，不含废品）
                  serial_stand_product_num_stand_actuality_min: serial_stand_product_num_stand_actuality_min_ceil,
                  //期初存货单位成本
                  stock_cost_price_begin: stock_cost_price_begin,
                  //期初存货转销售
                  stock_cost_to_sold_money_begin: numberToFixed( sold_num_begin*stock_cost_price_begin ),
                  //转入存货变动金额
                  trans_in_money : 0,
                  //当期完工转销售
                  stock_cost_to_sold_money_current: 0,
                  //转入销售成本（FIFO）
                  sold_money_fifo: 0,
                  //加权单位变动成本
                  cost_avco: 0,
                  //转入销售成本（AVCO）
                  price_avco: 0,
                }
              }

              colSpan[customerNo].index.push(index)


              colSpan[customerNo].cost_sum = numberToFixed( colSpan[customerNo].cost_sum*1 + item.balance_start*1 + item.deadline_material*1 + item.deadline_worker*1 + item.deadline_money*1 )

              colSpan[customerNo].trans_in_money = numberToFixed(colSpan[customerNo].trans_in_money*1 + item.serial_stand_actuality_cost*item.serial_stand_stand)
              colSpan[customerNo].serial_stand_actuality_cost_total_current = numberToFixed(colSpan[customerNo].serial_stand_actuality_cost_total_current*1 + item.serial_stand_actuality_cost*item.serial_stand_stand)
              colSpan[customerNo].stock_cost_to_sold_money_current = numberToFixed(colSpan[customerNo].serial_stand_actuality_cost_total_current*item.need_stock)
              colSpan[customerNo].sold_money_fifo = numberToFixed(colSpan[customerNo].stock_cost_to_sold_money_current*1 + colSpan[customerNo].stock_cost_to_sold_money_begin*1)


              //期初耗费
              const cost_price_begin = numberToFixed(colSpan[customerNo].stock_cost_price_begin*colSpan[customerNo].stock);
              //实际完工耗费
              const cost_price_actuality = numberToFixed(colSpan[customerNo].trans_in_money*colSpan[customerNo].serial_stand_product_num_stand_actuality_min);
              //消耗数量
              const product_num_begin_actuality = colSpan[customerNo].stock*1 + colSpan[customerNo].serial_stand_product_num_stand_actuality_min*1
              const  cost_avco = checkZero(product_num_begin_actuality) ? 0 : numberToFixed( (cost_price_begin*1 + cost_price_actuality*1) / product_num_begin_actuality )
              colSpan[customerNo].cost_avco = cost_avco
              colSpan[customerNo].price_avco = numberToFixed(cost_avco*(colSpan[customerNo].sold_num_begin*1+colSpan[customerNo].need_stock*1))

              if (colSpan[customerNo].min_product_num * 1 > item.serial_stand_product_num_stand * 1) {
                //取最小值
                colSpan[customerNo].min_product_num = item.serial_stand_product_num_stand
                const stand_in_stock = numberToFixed((item.serial_stand_product_num_stand * item.pass_per) / 100)
                colSpan[customerNo].stand_in_stock = stand_in_stock
                colSpan[customerNo].is_need_stock = stand_in_stock * 1 > item.need_stock * 1 ? 'Y' : 'N'
              }
            })

            //再次计算
            const serialProductDataObj = {};
            const newTableData = []
            tableData.map(function(tableItem){
              const item = {...tableItem}
              const no = item.no
              const customerNo = item.customerNo

              const serial_id_no = item.serial_id_no || ''

              if ( !(no in serialProductDataObj) ) {
                serialProductDataObj[no] = {}
              }



              const colSpanInfo = colSpan[customerNo]||{}
              const serial_stand_product_num_stand_actuality_min = colSpanInfo.serial_stand_product_num_stand_actuality_min||0
              // const trans_in_money = colSpanInfo.trans_in_money;//numberToFixed(serial_stand_product_num_stand_actuality_min*colSpanInfo.serial_stand_actuality_cost_total_current)


              // colSpanInfo.trans_in_money = trans_in_money
              const stand_in_stock = colSpanInfo.stand_in_stock||0
              const need_stock = colSpanInfo.need_stock||0
              item.serial_stand_product_num_stand_actuality_min = serial_stand_product_num_stand_actuality_min
              //工序折合产品转存货金额
              item.serial_product_in_stock_money = numberToFixed(item.serial_stand_actuality_cost * serial_stand_product_num_stand_actuality_min * item.serial_stand_stand)
              //工序折合产品转销售成本（FIFO)
              item.product_num_sold_fifo = numberToFixed(item.serial_stand_actuality_cost * item.need_stock * item.serial_stand_stand)
              //工序折合产品转销售成本（AVCO)
              item.product_num_sold_avco = ''
              //折算实际产品产量（未取整，完工入库量)
              item.product_num_actuality = checkZero(item.serial_stand_stand) ? 0 : numberToFixed( item.serial_product_num / item.serial_stand_stand)


              //产品完整度判断（略）
              item.product_is_whole = 'Y'

              //满足销售所需
              item.is_sold_state = 'Y'


              serialProductDataObj[no][serial_id_no] = item
              //实际完工产品产量（实际入库，不含废品）
              //取最小值
              if (serialProductDataObj[no][serial_id_no].product_num_actuality===0 || serialProductDataObj[no][serial_id_no].product_num_actuality * 1 >  item.product_num_actuality * 1) {
                serialProductDataObj[no][serial_id_no].product_num_actuality = item.product_num_actuality
                serialProductDataObj[no][serial_id_no].is_sold_state = item.product_num_actuality*1 > item.need_stock*1 ? 'Y':'N'
              }
              newTableData.push(item)
            })




            // console.log(serialProductDataObj)
            this.colSpan = colSpan
            this.$store.commit('updateTools2State', { field: 'tools2_excelData_tableData_customerNo_mind', value: colSpan })
            this.$store.commit('updateTools2State', { field: 'tools2_excelData_tableData', value: newTableData })
            this.$store.commit('updateTools2State', { field: 'tools2_serialProductDataObj', value: serialProductDataObj })
          }
        )
    },

    handleResizeColumn(w, col) {
        col.width = w;
    },
  }
}
</script>
