<template>
  <div class="table-children">
    <div class="tabs-group" v-show="!isMobile">
      <a-radio-group v-model:value="showWay" name="radioGroup" optionType="button" button-style="solid">
        <a-radio value="all">明细</a-radio>
        <a-radio value="no">产品编码</a-radio>
        <a-radio value="customer">客户编码</a-radio>
      </a-radio-group>
    </div>
    <a-table class="swiper-no-swiping" ref="tableList" :columns="getTableColumns" :data-source="getTableData" :pagination="pagination" @change="onTableChange" size="small" bordered :scroll="isMobile?{ x: 450,  }:{}">

      <template #bodyCell="{ column, record, text }">
        <template v-if="['customer','no'].indexOf(column.dataIndex)===-1">
          {{ handleNumber(text) }}
        </template>
      </template>

      <template #summary>
        <a-table-summary fixed>
          <a-table-summary-row class="gray-bg-def-deep" v-show="getTableColumnsKeys.indexOf('no') > -1">
            <a-table-summary-cell :index="0" align="center">
              <a-tooltip @click="showMoreTableList">
                <template #title>{{ pagination ? '点击查看全部' : '点击收起' }}</template>
                其它
                <CaretDownOutlined v-if="pagination" />
                <CaretUpOutlined v-else />
              </a-tooltip>
            </a-table-summary-cell>
            <a-table-summary-cell :index="0" align="right" v-show="getTableColumnsKeys.indexOf('customer') > -1"> / </a-table-summary-cell>
            <a-table-summary-cell :index="1" align="right" v-show="getTableColumnsKeys.indexOf('stock') > -1"> {{handleNumber( $numberToFixed(getTableSum.stock - topSumObj.stock)) }} </a-table-summary-cell>
            <a-table-summary-cell :index="2" align="right" v-show="getTableColumnsKeys.indexOf('stock_cost') > -1">
              {{ handleNumber( $numberToFixed($checkZero(getTableSum.stock - topSumObj.stock) ? 0 : (getTableSum.stock_cost_money - topSumObj.stock_cost_money) / (getTableSum.stock - topSumObj.stock)) ) }}
            </a-table-summary-cell>
            <a-table-summary-cell :index="3" align="right" v-show="getTableColumnsKeys.indexOf('stock_cost_money') > -1">
              {{handleNumber( $numberToFixed(getTableSum.stock_cost_money - topSumObj.stock_cost_money) )}}
            </a-table-summary-cell>
            <a-table-summary-cell :index="4" align="right" v-show="getTableColumnsKeys.indexOf('stock_in') > -1"> {{ handleNumber($numberToFixed(getTableSum.stock_in - topSumObj.stock_in)) }} </a-table-summary-cell>
            <a-table-summary-cell :index="5" align="right" v-show="getTableColumnsKeys.indexOf('stock_in_cost') > -1">
              {{
                handleNumber( $numberToFixed(
                  $checkZero(getTableSum.stock_in - topSumObj.stock_in) ? 0 : (getTableSum.stock_in_cost_money - topSumObj.stock_in_cost_money) / (getTableSum.stock_in - topSumObj.stock_in)
                ) )
              }}
            </a-table-summary-cell>
            <a-table-summary-cell :index="7" align="right" v-show="getTableColumnsKeys.indexOf('stock_in_cost_money') > -1">
              {{ handleNumber( $numberToFixed(getTableSum.stock_in_cost_money - topSumObj.stock_in_cost_money) ) }}
            </a-table-summary-cell>
            <a-table-summary-cell :index="8" align="right" v-show="getTableColumnsKeys.indexOf('sold_num_begin') > -1">
              {{ handleNumber( $numberToFixed(getTableSum.sold_num_begin - topSumObj.sold_num_begin) ) }}
            </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_begin_cost') > -1">
              {{
                handleNumber( $numberToFixed(
                  $checkZero(getTableSum.sold_num_begin - topSumObj.sold_num_begin)
                    ? 0
                    : (getTableSum.sold_num_begin_cost_money - topSumObj.sold_num_begin_cost_money) / (getTableSum.sold_num_begin - topSumObj.sold_num_begin)
                ) )
              }}
            </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_begin_cost_money') > -1">
              {{ handleNumber( $numberToFixed(getTableSum.sold_num_begin_cost_money - topSumObj.sold_num_begin_cost_money) ) }}
            </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock_sold_num') > -1">
              {{ handleNumber( $numberToFixed(getTableSum.stock_sold_num - topSumObj.stock_sold_num) ) }}
            </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock_sold_num_cost') > -1">
              {{
                handleNumber( $numberToFixed(
                  $checkZero(getTableSum.stock_sold_num - topSumObj.stock_sold_num)
                    ? 0
                    : (getTableSum.stock_sold_num_cost_money - topSumObj.stock_sold_num_cost_money) / (getTableSum.stock_sold_num - topSumObj.stock_sold_num)
                ) )
              }}
            </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock_sold_num_cost_money') > -1">
              {{ handleNumber( $numberToFixed(getTableSum.stock_sold_num_cost_money - topSumObj.stock_sold_num_cost_money) )}}
            </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_end') > -1">
              {{ handleNumber( $numberToFixed(getTableSum.sold_num_end - topSumObj.sold_num_end) ) }}
            </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_end_cost') > -1">
              {{
                handleNumber($numberToFixed(
                  $checkZero(getTableSum.sold_num_end - topSumObj.sold_num_end)
                    ? 0
                    : (getTableSum.sold_num_end_cost_money - topSumObj.sold_num_end_cost_money) / (getTableSum.sold_num_end - topSumObj.sold_num_end)
                ))
              }}
            </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_end_cost_money') > -1">
              {{ handleNumber( $numberToFixed(getTableSum.sold_num_end_cost_money - topSumObj.sold_num_end_cost_money) ) }}
            </a-table-summary-cell>
          </a-table-summary-row>
        </a-table-summary>
        <a-table-summary fixed>
          <a-table-summary-row class="gray-bg-def-deep">
            <a-table-summary-cell :index="0" align="center" v-show="getTableColumnsKeys.indexOf('no') > -1"> 总计 </a-table-summary-cell>
            <a-table-summary-cell :index="0" align="center" v-show="getTableColumnsKeys.indexOf('customer') > -1"> / </a-table-summary-cell>
            <a-table-summary-cell :index="1" align="right" v-show="getTableColumnsKeys.indexOf('stock') > -1"> {{ handleNumber(getTableSum.stock || 0) }} </a-table-summary-cell>
            <a-table-summary-cell :index="2" align="right" v-show="getTableColumnsKeys.indexOf('stock_cost') > -1"> {{ handleNumber(getTableSum.stock_cost || 0) }} </a-table-summary-cell>
            <a-table-summary-cell :index="3" align="right" v-show="getTableColumnsKeys.indexOf('stock_cost_money') > -1"> {{ handleNumber(getTableSum.stock_cost_money || 0) }} </a-table-summary-cell>
            <a-table-summary-cell :index="4" align="right" v-show="getTableColumnsKeys.indexOf('stock_in') > -1"> {{ handleNumber(getTableSum.stock_in || 0) }} </a-table-summary-cell>
            <a-table-summary-cell :index="5" align="right" v-show="getTableColumnsKeys.indexOf('stock_in_cost') > -1"> {{ handleNumber(getTableSum.stock_in_cost || 0) }} </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock_in_cost_money') > -1"> {{ handleNumber(getTableSum.stock_in_cost_money || 0) }} </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_begin') > -1"> {{ handleNumber(getTableSum.sold_num_begin || 0) }} </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_begin_cost') > -1"> {{ handleNumber(getTableSum.sold_num_begin_cost || 0) }} </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_begin_cost_money') > -1"> {{ handleNumber(getTableSum.sold_num_begin_cost_money || 0) }} </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock_sold_num') > -1"> {{ handleNumber(getTableSum.stock_sold_num || 0) }} </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock_sold_num_cost') > -1"> {{ handleNumber(getTableSum.stock_sold_num_cost || 0) }} </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('stock_sold_num_cost_money') > -1"> {{ handleNumber(getTableSum.stock_sold_num_cost_money || 0) }} </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_end') > -1"> {{ handleNumber(getTableSum.sold_num_end || 0) }} </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_end_cost') > -1"> {{ handleNumber(getTableSum.sold_num_end_cost || 0) }} </a-table-summary-cell>
            <a-table-summary-cell align="right" v-show="getTableColumnsKeys.indexOf('sold_num_end_cost_money') > -1"> {{ handleNumber(getTableSum.sold_num_end_cost_money || 0) }} </a-table-summary-cell>
          </a-table-summary-row>
        </a-table-summary>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { ExclamationCircleOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue'
import { numberToFixed, debounce, checkZero, tableSortFnc, tableDefaultPage, tableDefautlLine } from '@/utils/util'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import tableSort from '../../components/tableSort.vue'

const initSumField = () => {
  return {
    stock: 0,
    stock_cost: 0,
    stock_cost_money: 0,
    stock_in: 0,
    stock_in_cost: 0,
    stock_in_cost_money: 0,
    sold_num_begin: 0,
    sold_num_begin_cost: 0,
    sold_num_begin_cost_money: 0,
    stock_sold_num: 0,
    stock_sold_num_cost: 0,
    stock_sold_num_cost_money: 0,
    sold_num_end: 0,
    sold_num_end_cost_money: 0,
    sold_num_end_cost: 0
  }
}

export default {
  name: 'SolutionExtraPlan_SFCBB',
  components: {
    ExclamationCircleOutlined,
    ToolsItemIntro,
    CaretDownOutlined,
    CaretUpOutlined
  },
  mixins: [tableSort],
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    numberFnc: {
      type: Function,
      default: null
    },
    hasActualityData: {
      type: Boolean,
      default: false
    },
    // pagination: {
    //   type: [Boolean, Object],
    //   default: false
    // },
  },
  data() {
    const sumObj = initSumField()
    return {
      showWay: 'all',
      tableData: [],
      tableData_sum: sumObj,
      tableData_no: [],
      tabledata_no_sum: sumObj,
      tableData_customer: [],
      tableData_customer_sum: sumObj,
      columns: [
        { title: '产品编码ID', dataIndex: 'no', align: 'center', ...tableSortFnc('no','string') },
        { title: '客户', dataIndex: 'customer', align: 'center', ...tableSortFnc('customer','string') },
        { title: '期初库存', dataIndex: 'stock', align: 'right', ...tableSortFnc('stock') },
        { title: '期初单位成本', dataIndex: 'stock_cost', align: 'right', ...tableSortFnc('stock_cost') },
        { title: '期初金额', dataIndex: 'stock_cost_money', align: 'right', ...tableSortFnc('stock_cost_money') },
        { title: '入库数量', dataIndex: 'stock_in', align: 'right', ...tableSortFnc('stock_in') },
        { title: '入库单位成本', dataIndex: 'stock_in_cost', align: 'right', ...tableSortFnc('stock_in_cost') },
        { title: '入库金额', dataIndex: 'stock_in_cost_money', align: 'right', ...tableSortFnc('stock_in_cost_money') },
        { title: '期初销售数量', dataIndex: 'sold_num_begin', align: 'right', ...tableSortFnc('sold_num_begin') },
        { title: '销售单位成本①', dataIndex: 'sold_num_begin_cost', align: 'right', ...tableSortFnc('sold_num_begin_cost') },
        { title: '期初销售金额', dataIndex: 'sold_num_begin_cost_money', align: 'right', ...tableSortFnc('sold_num_begin_cost_money') },
        { title: '入库销售数量', dataIndex: 'stock_sold_num', align: 'right', ...tableSortFnc('stock_sold_num') },
        { title: '销售单位成本②', dataIndex: 'stock_sold_num_cost', align: 'right', ...tableSortFnc('stock_sold_num_cost') },
        { title: '入库销售金额', dataIndex: 'stock_sold_num_cost_money', align: 'right', ...tableSortFnc('stock_sold_num_cost_money') },
        { title: '期末数量', dataIndex: 'sold_num_end', align: 'right', ...tableSortFnc('sold_num_end') },
        { title: '期末单位成本', dataIndex: 'sold_num_end_cost', align: 'right', ...tableSortFnc('sold_num_end_cost') },
        { title: '期末金额', dataIndex: 'sold_num_end_cost_money', align: 'right', ...tableSortFnc('sold_num_end_cost_money') }
      ],
      otherSortCal: {
        stock_cost: ['stock', 'stock_cost_money'],
        stock_in_cost: ['stock_in', 'stock_in_cost_money'],
        sold_num_begin_cost: ['sold_num_begin', 'sold_num_begin_cost_money'],
        stock_sold_num_cost: ['stock_sold_num', 'stock_sold_num_cost_money'],
        sold_num_end_cost: ['sold_num_end', 'sold_num_end_cost_money']
      },
      topSumObj: initSumField()
    }
  },
  computed: {
    ...mapGetters([]),
    getTableColumns() {
      let colmums = this.columns
      if (this.isMobile) {
        colmums = [
          { title: '产品编码ID', dataIndex: 'no', align: 'center',fixed: 'left', ...tableSortFnc('no') },
          { title: '期末数量', dataIndex: 'sold_num_end', align: 'right', ...tableSortFnc('sold_num_end') },
          { title: '期末单位成本', dataIndex: 'sold_num_end_cost', align: 'right', ...tableSortFnc('sold_num_end_cost') },
          { title: '期末金额', dataIndex: 'sold_num_end_cost_money', align: 'right', ...tableSortFnc('sold_num_end_cost_money') }
        ]
        if (this.showWay == 'no') {
          
        } else if (this.showWay == 'customer') {
          colmums = [
            { title: '客户', dataIndex: 'customer', align: 'center',fixed: 'left', ...tableSortFnc('no') },
            { title: '期末数量', dataIndex: 'sold_num_end', align: 'right', ...tableSortFnc('sold_num_end') },
            { title: '期末单位成本', dataIndex: 'sold_num_end_cost', align: 'right', ...tableSortFnc('sold_num_end_cost') },
            { title: '期末金额', dataIndex: 'sold_num_end_cost_money', align: 'right', ...tableSortFnc('sold_num_end_cost_money') }
          ]
        }
      } else {
        if (this.showWay == 'no') {
          colmums = colmums.slice(0, 1).concat(colmums.slice(2))
        } else if (this.showWay == 'customer') {
          colmums = colmums.slice(1)
        }
      }

      return colmums
    },
    getTableColumnsKeys() {
      return this.getTableColumns.map((item) => {
        return item.dataIndex
      })
    },
    getTableData() {
      if (this.showWay == 'no') {
        return this.tableData_no
      } else if (this.showWay == 'customer') {
        return this.tableData_customer
      } else {
        return this.tableData
      }
    },
    getTableSum() {
      let sumobj = this.tableData_sum
      if (this.showWay == 'no') {
        sumobj = this.tableData_no_sum
      } else if (this.showWay == 'customer') {
        sumobj = this.tableData_customer_sum
      }
      return sumobj || initSumField()
    }
  },
  mounted() {},
  methods: {
    handleNumber(number) {
      if(this.numberFnc){
        return this.numberFnc(number);
      }else{
        return this.$dealThousands(number);
      }
    },
    handleTableGroup(field) {
      const data = {}
      this.tableData.map((item) => {
        const key = item[field]
        if (!(key in data)) {
          data[key] = {
            no: item.no,
            customer: item.customer,
            customerNo: item.customerNo,
            pass_per: item.pass_per,
            stock: 0,
            stock_cost: 0,
            stock_cost_money: 0,
            stock_in: 0,
            stock_in_cost: 0,
            stock_in_cost_money: 0,
            sold_num_begin: 0,
            sold_num_begin_cost: 0,
            sold_num_begin_cost_money: 0,
            stock_sold_num: 0,
            stock_sold_num_cost: 0,
            stock_sold_num_cost_money: 0,
            sold_num_end: 0,
            sold_num_end_cost_money: 0,
            sold_num_end_cost: 0
          }
        }

        data[key].stock = numberToFixed(data[key].stock * 1 + item.stock * 1)
        data[key].stock_cost = numberToFixed(data[key].stock_cost * 1 + item.stock_cost * 1)
        data[key].stock_cost_money = numberToFixed(data[key].stock_cost_money * 1 + item.stock_cost_money * 1)
        data[key].stock_in = numberToFixed(data[key].stock_in * 1 + item.stock_in * 1)
        data[key].stock_in_cost = numberToFixed(data[key].stock_in_cost * 1 + item.stock_in_cost * 1)
        data[key].stock_in_cost_money = numberToFixed(data[key].stock_in_cost_money * 1 + item.stock_in_cost_money * 1)
        data[key].sold_num_begin = numberToFixed(data[key].sold_num_begin * 1 + item.sold_num_begin * 1)
        data[key].sold_num_begin_cost = numberToFixed(data[key].sold_num_begin_cost * 1 + item.sold_num_begin_cost * 1)
        data[key].sold_num_begin_cost_money = numberToFixed(data[key].sold_num_begin_cost_money * 1 + item.sold_num_begin_cost_money * 1)
        data[key].stock_sold_num = numberToFixed(data[key].stock_sold_num * 1 + item.stock_sold_num * 1)
        data[key].stock_sold_num_cost = numberToFixed(data[key].stock_sold_num_cost * 1 + item.stock_sold_num_cost * 1)
        data[key].stock_sold_num_cost_money = numberToFixed(data[key].stock_sold_num_cost_money * 1 + item.stock_sold_num_cost_money * 1)
        data[key].sold_num_end = numberToFixed(data[key].sold_num_end * 1 + item.sold_num_end * 1)
        data[key].sold_num_end_cost_money = numberToFixed(data[key].sold_num_end_cost_money * 1 + item.sold_num_end_cost_money * 1)
        data[key].sold_num_end_cost = numberToFixed(data[key].sold_num_end_cost * 1 + item.sold_num_end_cost * 1)
      })
      return Object.values(data)
    },

    createTableData(tableData, serialStandObj, customerNoProductObj, showWay = 'all') {
      // const tableData = info[0]
      // const serialStandObj = info[1]||{}
      // const customerNoProductObj = info[2]||{}

      this.showWay = showWay
      let tableDataNew = {}
      tableData.map((tableItem) => {
        const item = { ...tableItem }
        const no = item.no
        const pass_per = item.pass_per
        const customerNo = item.customerNo

        const serialStandObjInfo = serialStandObj[no] || ''
        const customerNoProductObjInfo = customerNoProductObj[customerNo] || ''

        const cost_stand = serialStandObjInfo.total_stand_cost || '0'
        const cost_current = customerNoProductObjInfo.trans_in_money || '0'
        const cost_sum = customerNoProductObjInfo.cost_sum || '0'
        const in_stock_actuality = customerNoProductObjInfo.serial_stand_product_num_stand_actuality_min

        const product_num_stand = numberToFixed(checkZero(cost_stand) ? 0 : (cost_sum / cost_stand) * (pass_per / 100))
        const in_stock_current = numberToFixed(checkZero(cost_current) ? 0 : cost_sum / cost_current)

        if (!(customerNo in tableDataNew)) {
          const sold_num_end_cost_money =
            customerNoProductObjInfo.stock * customerNoProductObjInfo.stock_cost_price_begin +
            customerNoProductObjInfo.stock_cost_to_sold_money_current_actuality * 1 -
            customerNoProductObjInfo.stock_cost_to_sold_money_begin -
            customerNoProductObjInfo.need_stock * customerNoProductObjInfo.serial_stand_actuality_cost_total_current

          const end_num =  numberToFixed( customerNoProductObjInfo.stock*1+ customerNoProductObjInfo.need_stock*1 - customerNoProductObjInfo.sold_num_begin - customerNoProductObjInfo.need_stock);

          tableDataNew[customerNo] = {
            no: item.no,
            customer: item.customer,
            customerNo: item.customerNo,
            pass_per: item.pass_per,

            //期初库存
            stock: customerNoProductObjInfo.stock,
            //期初单位成本
            stock_cost: customerNoProductObjInfo.stock_cost_price_begin,
            //期初金额
            stock_cost_money: numberToFixed(customerNoProductObjInfo.stock * customerNoProductObjInfo.stock_cost_price_begin),

            //入库数量
            stock_in: customerNoProductObjInfo.need_stock,
            //入库单位成本
            stock_in_cost: customerNoProductObjInfo.serial_stand_actuality_cost_total_current,
            //入库金额
            stock_in_cost_money: customerNoProductObjInfo.stock_cost_to_sold_money_current_actuality,

            //期初销售数量
            sold_num_begin: customerNoProductObjInfo.sold_num_begin,
            //销售单位成本①
            sold_num_begin_cost: customerNoProductObjInfo.stock_cost_price_begin,
            //期初销售金额
            sold_num_begin_cost_money: customerNoProductObjInfo.stock_cost_to_sold_money_begin,

            //入库销售数量
            stock_sold_num: customerNoProductObjInfo.need_stock,
            //销售单位成本②
            stock_sold_num_cost: customerNoProductObjInfo.serial_stand_actuality_cost_total_current,
            //入库销售金额
            stock_sold_num_cost_money: numberToFixed(customerNoProductObjInfo.need_stock * customerNoProductObjInfo.serial_stand_actuality_cost_total_current),

            //期末数量
            sold_num_end:  end_num,
            //期末金额
            sold_num_end_cost_money:  numberToFixed(sold_num_end_cost_money),
            //期末单位成本
            sold_num_end_cost: numberToFixed(
              checkZero(end_num) || checkZero(sold_num_end_cost_money) ? 0 : sold_num_end_cost_money / end_num
            )
          }
        }

        return item
      })
      this.tableData = Object.values(tableDataNew)
      this.$nextTick(() => {
        this.tableData_no = this.handleTableGroup('no')
        this.tableData_customer = this.handleTableGroup('customer')

        this.tableDataSum('tableData')
        this.tableDataSum('tableData_no')
        this.tableDataSum('tableData_customer')

        this.$nextTick(() => {
          this.calOtherSum(this.getTableData)
        })
      })
      // console.log(tableData)
      // console.log(tableDataNew)
    },
    otherSortCalResultFnc(key, item) {
      // const arr = this.otherSortCal[key]
      // console.log(key,arr,item[arr[1]],item[arr[0]],item[arr[1]]/item[arr[0]])
      // return numberToFixed( checkZero(arr[0])  ? 0 : item[arr[1]]/item[arr[0]]);
    },
    tableDataSum(tableDataField) {
      let tableData = this[tableDataField]
      let sumObj = initSumField()

      tableData.map((item) => {
        for (let key in sumObj) {
          if (key in this.otherSortCal) {
          } else {
            sumObj[key] = numberToFixed(sumObj[key] * 1 + item[key] * 1)
          }
        }
      })
      for (let key in sumObj) {
        if (key in this.otherSortCal) {
          const arr = this.otherSortCal[key]
          sumObj[key] = numberToFixed(checkZero(arr[0]) ? 0 : sumObj[arr[1]] / sumObj[arr[0]])
        } else {
        }
      }
      this[tableDataField + '_sum'] = sumObj
    }
  }
}
</script>
<style lang="less" scoped>
.table-children {
  .subtitle {
    margin-top: 24px;
  }
}
</style>
