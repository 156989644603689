<script>
import { message } from 'ant-design-vue'
import { numberToFixed, debounce, fnc_excelData_yc_after, checkZero, tableSortFnc, roundToDotNumber, tableDefaultPage } from '@/utils/util'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      listQuery: {
        id: '',
        id1: '',
        id2: '',
        id3: '',
        id4: ''
      },

      planType: '',

      detail: {},
      downLoading: false,

      columns: [],
      tableData: []
    }
  },
  created() {
    this.listQuery.id = this.$route.query.id || 0
    this.listQuery.id1 = this.$route.query.id1 || 0
    this.listQuery.id2 = this.$route.query.id2 || 0
    this.listQuery.id3 = this.$route.query.id3 || 0
    this.listQuery.id4 = this.$route.query.id4 || 0

    this.getPlanDetail()
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.compare_name || ''
    },
    getPlanName_en() {
      return this.detail.compare_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    },
    getTitle() {
      return ''
    }
  },

  methods: {
    getPlanDetail() {
      //结构化数据
      const structData = (item = {}) => {
        const name = item.name || ''
        const pageParams = item.page_params || {}
        const column = { width: 150, title: name, dataIndex: 'tax_per', align: 'right', resizable: false, ellipsis: true }
        const dataItem = {
          //投资总现金流入
          compare_tzzxjlr: pageParams.compare_tzzxjlr || 0,
          //投资总规模
          compare_tzzkm: pageParams.compare_tzzkm || 0,
          //前期投资
          compare_qqtz: pageParams.compare_qqtz || 0,
          //净现金流量
          compare_jxjll: pageParams.compare_jxjll || 0,
          //累计净现金流量
          compare_ljjxjll: pageParams.compare_ljjxjll || 0,
          //折现净现金流量
          compare_zxjxjll: pageParams.compare_zxjxjll || 0,
          //折现累计净现金流量
          compare_zxljjxjll: pageParams.compare_zxljjxjll || 0,
          //净利润
          compare_jlr: pageParams.compare_jlr || 0,
          //内部收益率
          compare_nbsyl: pageParams.compare_nbsyl || 0,
          //投资回收期 (动态)
          compare_tzhsq_dt: pageParams.compare_tzhsq_dt || 0,
          //空行
          null_row: ''
        }
        return { column, dataItem }
      }

      const PromiseAll = []
      Object.values(this.listQuery).map((id) => {
        if (parseInt(id) > 0) {
          PromiseAll.push(this.$network('/api/tools/planDetail', { id: id }))
        }
      })
      Promise.all(PromiseAll).then((resAll) => {
        const columns = [
          {
            dataIndex: 'index',
            title: '序号',
            width: 50,
            align: 'center',
            customCell: (_, index) => {
              if (index === 0) {
                return { rowSpan: 2 }
              }
              if (index === 1) {
                return { rowSpan: 0 }
              }
              if (index === 2) {
                return { rowSpan: 2 }
              }
              if (index === 3) {
                return { rowSpan: 0 }
              }
              if (index === 4) {
                return { rowSpan: 2 }
              }
              if (index === 5) {
                return { rowSpan: 0 }
              }
            }
          },
          {
            dataIndex: 'name',
            title: '项',
            width: 200
            // customCell: (_, index) => {
            //   if (index === 0) {
            //     return { rowSpan: 2 }
            //   }
            //   if (index === 1) {
            //     return { rowSpan: 0 }
            //   }
            // }
          }
        ]
        const tableData = [
          { name: '投资总规模', field: 'compare_tzzkm', index: 1 },
          { name: '前期投资', field: 'compare_qqtz', index: 1 },
          // { name: '', field: 'null_row' },
          { name: '折现净现金流量', field: 'compare_zxjxjll', index: 2 },
          { name: '净利润', field: 'compare_jlr', index: 2 },
          // { name: '', field: 'null_row' },
          { name: '内部收益率', field: 'compare_nbsyl', index: 3 },
          { name: '投资回收期 (动态)', field: 'compare_tzhsq_dt', index: 3 }
        ]

        resAll.map((res, index) => {
          const data = res.data || {}
          const detail = data.detail || {}
          // const page_params = detail.page_params||{}

          if (index === 0) {
            this.detail = detail
          }
          const { column, dataItem } = structData(detail)
          const key = 'item_' + index
          column.dataIndex = key
          columns.push(column)
          tableData.map((item) => {
            const field = item.field
            item[key] = dataItem[field]
          })
        })

        // columns.forEach((item, index) => {
        //   item.customCell = this.mergeCells
        // })

        this.columns = columns
        this.tableData = tableData

        // console.log('================================columns', this.columns)
        // console.log('================================tableData', this.tableData)
      })
    },
    // mergeCells(item, index, column) {
    //   let colSpan = 1
    //   if (item.name === '') {
    //     if (column.dataIndex === 'name') {
    //       colSpan = 3
    //     } else {
    //       colSpan = 0
    //     }
    //   }
    //   return {
    //     colSpan: colSpan
    //     // colSpan : colSpanLen
    //   }
    // },

    handleShare() {
      message.error(`保存后才能进行分享`)
      this.handleSave(() => {
        this.$refs.savePlan.close()
        this.$nextTick(() => {
          this.$refs.shareLink.open()
        })
      })
    },
    handleBack() {
      this.$confirm({
        content: '是否保存方案',
        okText: '是',
        cancelText: '否',
        onOk: () => {
          this.handleSave(() => {
            this.$router.back()
          })
        },
        onCancel: () => {
          this.$router.back()
        }
      })
    },
    handleSave(callback) {
      this.$refs.SavePlanCompare.open(
        {
          name: this.detail.name || '',
          remark: this.remark,
          plan1: this.listQuery.id,
          plan2: this.listQuery.id1,
          plan_extra: this.listQuery,
          tool_type: 'seven'
        },
        () => {
          if (callback) {
            callback()
          } else {
            this.$router.replace({ path: '/history/compare' })
          }
        }
      )
    },

    async downPDF() {
      this.downLoading = true
      this.isPrint = true
      this.$refs.pageLoading.open('正在进行方案下载')
      //增加打印次数
      this.handlePrint()

      setTimeout(() => {
        this.$nextTick(async () => {
          this.$refs.pageLoading.text('方案构件中...')

          let elId = 'solutionTemplatePDF'
          let pdfName = '基础方案'
          if (this.planType == 'extra') {
            pdfName = '扩展方案'
            elId = 'solutionTemplatePDFExtra'
            await this.$refs.solutionTemplatePDFExtra.downPDF(this)
          } else {
            await this.$refs.solutionTemplatePDF.downPDF(this)
          }

          this.$refs.pageLoading.text('正在生成PDF文件')
          // await htmlToPDF(elId, pdfName, 'PDF-foot')
          this.$refs.pageLoading.text('下载成功')
          setTimeout(() => {
            location.reload()
          }, 800)
        })
      }, 200)

      return
    },
    handlePrint() {
      this.$network('/api/tools/planPrintTimes', { id: this.listQuery.id, tool_type: 'two' })
    }
  }
}
</script>
