<template>
  <div style="width: 100%">
    <a-row>
      <a-col :span="12">
        <SliderInputBlock v-model:value="xAxisNum" :min="0.1" :max="3" :step="0.1" />
      </a-col>
    </a-row>
    <div id="svp-line-before" style="height: 750px"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
import { numberToFixed, checkZero, debounce, getMindValue,echartGraphic } from '@/utils/util'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import calMixIn from '../calMixIn.vue'
// echart变量
let myChart

const lineStyle = {lineStyle:{ width:3 }};

const defaultEchartData = [
  //收入=0*加权平均单位不含税售价     1.7*加权平均单位不含税售价   3.4*加权平均单位不含税售价  5.1*加权平均单位不含税售价...
  { flagComplete: false, name: 'priceComeInPrice', data: {...lineStyle,markArea:{}, name: '总收入', type: 'line', stack: 'priceComeInPrice', data: [], showEchartlegend: true } },
  //总成本＝变动成本总额+固定成本总额 + 对应销量区间的混合成本总额
  { flagComplete: false, name: 'materialCostMixRagePrice', data: {...lineStyle, name: '总成本', type: 'line', stack: 'materialCostMixRagePrice', data: [], showEchartlegend: true } },
  //管理利润 = 总收入-变动成本总额-固定成本总额-对应销售区间混合成本总额
  { flagComplete: false, name: 'managerProfitMoeny', data: {...lineStyle, name: '管理利润', type: 'line', stack: 'managerProfitMoeny', data: [], showEchartlegend: true } },
  //预测X轴点 = 管理利润为0的点的销量
  { flagComplete: false, name: 'prediction', data: {...lineStyle, name: '预测', type: 'line', stack: 'prediction', data: [], showEchartlegend: true } },
  //变动成本总额＝产量*加权平均单位变动成本
  { flagComplete: false, name: 'materialCostPrice', data: {...lineStyle, name: '变动成本', type: 'line', stack: 'materialCostPrice', data: [], showEchartlegend: false } },
  //总固定成本=固定成本总额
  { flagComplete: false, name: 'fixedMoeny', data: {...lineStyle, name: '总固定成本', type: 'line', stack: 'fixedMoeny', data: [], showEchartlegend: false } },
  //加成后的总边际贡献 = 总收入-总变动成本
  { flagComplete: false, name: 'sideMoeny', data: {...lineStyle, name: '总边际贡献', type: 'line', stack: 'sideMoeny', data: [], showEchartlegend: false } },
  //毛利润 = 总收入-变动成本总额-制造环节固定成本总额-制造环节对应销量所在区间的混合成本总额
  { flagComplete: false, name: 'profitMoeny', data: {...lineStyle, name: '毛利润', type: 'line', stack: 'profitMoeny', data: [], showEchartlegend: false } },

  //这一条总保持再最后一条记录不然再画混合线条会出问题
  //总混合成本=对应销量区间混合成本总额
  // { flagComplete: false, name: 'mixMoeny', data: { name: '总混合成本', type: 'line', stack: 'mixMoeny', data: [], showEchartlegend: true } }
]

export default {
  name: 'SolutionSVPLineBefore',
  props: {
    
    isPrint: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    },
    planType: {
      type: String,
      default: ''
    }
  },
  components: {
    SliderInputBlock
  },
  mixins:[calMixIn],
  data() {
    return {
      sureDrawEchart: false,
      xAxisNum: 1,

      points: [],
      //x坐标轴
      xAxisData: [],

      echartData: defaultEchartData,
      //图例
      echartlegend: {},
      showEchartlegend: {},
      debounce: () => {},

      intro:[],
    }
  },
  computed: {
    ...mapGetters([
      'sum_sold_num',
      'sum_price_add_tax',
      'avg_price_no_tax_unit_money',
      'sum_fixed_total_money',
      'sum_pass_sold_num_price',
      'tableData_step_mix_field',
      'tableData_step_fixed_field',
      'mixRangeData',
      'sum_pass_per'
    ]),
    // tableData_step_mix() {
    //   return this.$store.state.tools.tableData_step_mix
    // },
    // tableData_step_fixed() {
    //   return this.$store.state.tools.tableData_step_fixed
    // },
    svpYMaxValue() {
      return this.$store.state.tools.svpYMaxValue
    },
    svpXMap(){
      return this.$store.state.tools.svpXMap||[]
    },



    tableData_step_yc(){
      return this.$store.state.tools.tableData_step_yc
    },

    excelData_ycChange(){
      return this.$store.state.tools.excelData_ycChange
    },
    columns_step_mix(){
      return this.$store.state.tools.columns_step_mix
    },
    tableData_step_fixed(){
      return this.$store.state.tools.tableData_step_fixed
    },
    tableData_step_mix(){
      return this.$store.state.tools.tableData_step_mix
    },




  },
  watch: {
    //需要与加成后的方案对比x轴
    svpXMap(value){
      this.xAxisData = value
      this.$nextTick(() => {
        //重新画图
        this.debounce()
      })
    },
    planType(value) {
      if (value == 'base') {
        this.$nextTick(() => {
          setTimeout(() => {
            myChart.resize()
          }, 200)
        })
      }
    },
    // sum_sold_num(value) {
    //   //平分等分
    //   const xAxis_divide = 1000

    //   const xMaxNum = Math.ceil(value ?? 0)
    //   const step = xMaxNum / xAxis_divide
    //   const xAxisDataGroup = []
    //   for (let i = 0; i <= xMaxNum; i++) {
    //     xAxisDataGroup.push( numberToFixed( step * i * this.xAxisNum) )
    //   }

    //   this.xAxisData = xAxisDataGroup
    //   this.$nextTick(() => {
    //     //重新画图
    //     this.drawEchart()
    //   })
    // },

    echartData: {
      //重新画图
      deep: true,
      handler: function (value) {
        let flagComplete = true
        value.map(function (item) {
          if (!item.flagComplete) {
            flagComplete = false
          }
        })
        if(!flagComplete){
          return;
        }
        this.sureDrawEchart = true;
          
          // console.log('>>>>>>>>>>>>>>>showEchartlegend<<<<<<<<<<<<<<<<')
          // console.log(this.showEchartlegend)
          this.$nextTick(() => {
            this.debounce()
          })
      }
    },
    svpYMaxValue(value) {
      this.debounce()
    }
  },
  mounted() {
    

    this.debounce = debounce(this.drawEchart)

    this.createWatch_sum_sold_num_and_xAxisNum()
    //处理临界点问题
    this.createWatch_calGroup()
    // this.createWatch_calGroupFnc()

    this.createWatch_priceComeInPrice()
    this.createWatch_fixedMoeny()
    this.createWatch_prediction()
    this.createWatch_materialCostPrice()
    this.createWatch_materialCostMixRagePrice()
    // this.createWatch_mixMoeny()
    this.createWatch_mixMoeny_line()
    this.createWatch_sideMoeny()
    this.createWatch_profitMoeny()
    this.createWatch_managerProfitMoeny()

    var chartDom = document.getElementById('svp-line-before')
    myChart = echarts.init(chartDom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    })

    this.$nextTick(() => {
      this.debounce()
    })
  },
  methods: {
    drawSvp(){

    },

    // //计算盈亏平衡位置
    // createWatch_calGroupFnc(){
    //   this.$watch(
    //     () => [ this.tableData_step_yc,this.columns_step_mix,this.tableData_step_fixed,this.tableData_step_mix ],
    //     (info) => {
    //         var excelData_yc = info[0];
    //         var columns_step_mix = info[1];
    //         var tableData_step_fixed = info[2];
    //         var tableData_step_mix = info[3]||[];
    //         var tableDataMixMoney = tableData_step_mix[tableData_step_mix.length-1]||{}
    //         const obj = this.handleData(excelData_yc,tableData_step_fixed,tableData_step_mix,columns_step_mix,{})
    //         console.log(obj)

    //         //合格率
    //         const productPer = checkZero(obj.sum_pass_sold_num) ? 0 : obj.sum_sold_num/obj.sum_pass_sold_num;
    //         console.log('合格率：', productPer);

    //         //不含税单价
    //         const noTaxPrice = checkZero(obj.sum_sold_num) ? 0 : obj.sum_price_come_in / obj.sum_sold_num
    //         console.log('不含税单价:', noTaxPrice);

    //         //成本单价
    //         const costMoney = checkZero(obj.sum_pass_sold_num) ? 0 : obj.sum_material_cost_money / obj.sum_pass_sold_num
    //         console.log('成本单价:', costMoney);

    //         //固定费用
    //         const fixMoney = obj.sum_fixed_total_money;

    //         const valuePer = checkZero(productPer) ? 0 : costMoney/productPer;
    //         // const soldNum = tableData_step_fixed *1 / ( noTaxPrice - valuePer );

    //         //销量*不含税单价 = 销量/合格率 * 成本单价 + 固定 + 对应销量混合范围的金额
    //         const soldNumFnc = (disNum)=>{
    //           return checkZero(noTaxPrice - valuePer) ? 0 : (disNum *1 /( noTaxPrice - valuePer )) ;
    //         }

    //         const values = [];
    //         columns_step_mix.map(function(item,index){
    //           if(index>0){
    //             const range_min = item.range_min||0
    //             const range_max = item.range_max||0
                
    //             const mixMoney = tableDataMixMoney[item.dataIndex]
    //             const soldNum = soldNumFnc(mixMoney*1+fixMoney*1);
    //             // console.log(mixMoney*1,fixMoney*1)
    //             console.log("--------------------")
    //             console.log("销量区间范围:",range_min,range_max)
    //             console.log("盈亏平衡点销量:",soldNum)
    //             // console.log(range_min,range_max,soldNum,mixMoney,item.dataIndex)
    //             if(soldNum>=range_min && soldNum<=range_max){
    //               values.push(soldNum)
    //             }
    //           }

    //         })
        
    //         console.log(values)

    //     })
    // },

    //计算盈亏平衡位置
    createWatch_calGroup(){
      //总收入 = 不含税售价✖销量再合计所有的产品
      this.$watch(
        () => [this.xAxisData, this.avg_price_no_tax_unit_money, this.sum_pass_sold_num_price, this.sum_fixed_total_money, this.sum_pass_per, this.tableData_step_mix],
        (info) => {
          const xAxis = info[0]
          const value = info[1]

          const cost_value = info[2]
          const fixed_money = info[3]
          const per = info[4]

          const data = []
          let costInUp = null;
          let hasCross = false;
          let upItem = "";
          let hasCrossData = [];
          xAxis.map( (item)=>{
            //产量
            const num = checkZero(per) ? 0 : item / (per / 100)
            //收入
            const comeIn = numberToFixed(item * value);
            //成本
            const costIn = cost_value * num * 1 + fixed_money * 1 + this.tableData_step_mix_field('total', item) * 1;

            const diff = comeIn - costIn;

            if(costInUp!==null){
              if(diff==0 || (diff<0 && costInUp>0 ) || (diff>0 && costInUp<0)){
                hasCross = true;
                data.push({sold_num:item,product_num:num,comeIn:comeIn,costIn:costIn,in:comeIn - costIn})
              }
              var color = diff>0?'#219af3':'#ff0000'
              if(hasCross){
                hasCrossData.push([{coord:[item,comeIn*1],itemStyle:{color:color}},{coord:[upItem,costIn*1],itemStyle:{color:color}}])
              }

            }
            upItem = item;
            costInUp = diff
          })
          // console.log(data)
          // console.log(hasCrossData)
          this.echartData[this.checkEchartDataIndex('priceComeInPrice')].data.markArea = {
            data:hasCrossData
          }
          
    // this.$nextTick(() => {
    //   this.debounce()
    // })
        }
      )
    },

    createWatch_sum_sold_num_and_xAxisNum() {
      this.$watch(
        () => [this.sum_sold_num, this.xAxisNum],
        (info) => {
          const value = info[0]
          const xAxisNum = info[1] * 1
          //平分等分
          const xAxis_divide = 1000

          const xMaxNum = Math.ceil(value ?? 0)
          const step = xMaxNum / xAxis_divide
          const xAxisDataGroup = []
          for (let i = 0; i <= xMaxNum; i++) {
            xAxisDataGroup.push(numberToFixed(step * i * xAxisNum))
          }

          this.xAxisData = xAxisDataGroup
          this.$nextTick(() => {
            //重新画图
            this.debounce()
          })
        }
      )
    },
    createWatch_priceComeInPrice() {
      //总收入 = 不含税售价✖销量再合计所有的产品
      this.$watch(
        () => [this.xAxisData, this.avg_price_no_tax_unit_money],
        (info) => {
          const xAxis = info[0]
          const value = info[1]
          const data = []
          xAxis.map(function (item) {
            data.push(numberToFixed(item * value))
          })
          this.echartData[this.checkEchartDataIndex('priceComeInPrice')].data.data = data
          this.echartData[this.checkEchartDataIndex('priceComeInPrice')].flagComplete = true
        }
      )
    },
    createWatch_fixedMoeny() {
      //总收入 = 不含税售价✖销量再合计所有的产品
      this.$watch(
        () => [this.xAxisData, this.sum_fixed_total_money],
        (info) => {
          const xAxis = info[0]
          const value = info[1]
          const data = []
          xAxis.map(function (item) {
            data.push(numberToFixed(value * 1))
          })
          this.echartData[this.checkEchartDataIndex('fixedMoeny')].flagComplete = true
          this.echartData[this.checkEchartDataIndex('fixedMoeny')].data.data = data
        }
      )
    },
    createWatch_prediction() {
      this.$watch(
        () => [this.xAxisData, this.sum_sold_num],
        (info) => {
          const xAxis = info[0]
          const value = info[1]
          const obj = getMindValue(xAxis, 0, value)
          const letMax = obj.letMax
          this.echartData[this.checkEchartDataIndex('prediction')].flagComplete = true
          this.echartData[this.checkEchartDataIndex('prediction')].data = {
            name: '预测',
            showEchartlegend: true,
            type: 'line',
            markLine: {
              ...lineStyle,
              yAxisIndex: 0,
              symbol: 'none', //去掉箭头
              data: [{ name: '预测', xAxis: letMax }]
            }
          }
        }
      )
    },
    createWatch_materialCostPrice() {
      this.$watch(
        () => [this.xAxisData, this.sum_pass_sold_num_price, this.sum_pass_per],
        (info) => {
          const xAxis = info[0]
          const value = info[1]
          const per = info[2]

          const data = []
          xAxis.map(function (item) {
            //销量
            const num = checkZero(per) ? 0 : item / (per / 100)
            data.push(numberToFixed(value * 1 * num))
          })
          this.echartData[this.checkEchartDataIndex('materialCostPrice')].data.data = data
          this.echartData[this.checkEchartDataIndex('materialCostPrice')].flagComplete = true
        }
      )
    },
    createWatch_materialCostMixRagePrice() {
      this.$watch(
        () => [this.xAxisData, this.sum_pass_sold_num_price, this.sum_fixed_total_money, this.sum_pass_per, this.tableData_step_mix],
        (info) => {
          const xAxis = info[0]
          const value = info[1]
          const fixed_money = info[2]
          const per = info[3]

          const data = []
          xAxis.map((item) => {
            //销量
            const num = checkZero(per) ? 0 : item / (per / 100)

            data.push(numberToFixed(value * num * 1 + fixed_money * 1 + this.tableData_step_mix_field('total', item) * 1))
          })
          this.echartData[this.checkEchartDataIndex('materialCostMixRagePrice')].data.data = data
          this.echartData[this.checkEchartDataIndex('materialCostMixRagePrice')].flagComplete = true
        }
      )
    },
    createWatch_sideMoeny() {
      this.$watch(
        () => [this.xAxisData, this.avg_price_no_tax_unit_money, this.sum_pass_sold_num_price, this.sum_pass_per],
        (info) => {
          const xAxis = info[0]
          const price = info[1]
          const cost = info[2]
          const per = info[3]

          const data = []
          xAxis.map(function (item) {
            //产量
            const num = checkZero(per) ? 0 : item / (per / 100)

            data.push(numberToFixed(item * price - cost * num))
          })

          this.echartData[this.checkEchartDataIndex('sideMoeny')].data.data = data
          this.echartData[this.checkEchartDataIndex('sideMoeny')].flagComplete = true
        }
      )
    },
    createWatch_profitMoeny() {
      this.$watch(
        () => [this.xAxisData, this.avg_price_no_tax_unit_money, this.sum_pass_sold_num_price, this.sum_pass_per, this.tableData_step_mix, this.tableData_step_fixed],
        (info) => {
          const xAxis = info[0]
          const price = info[1]
          const cost = info[2]
          const per = info[3]
          const data = []
          xAxis.map((item) => {
            //产量
            const num = checkZero(per) ? 0 : item / (per / 100)
            data.push(numberToFixed(item * price - (cost * num + this.tableData_step_fixed_field('zhizao_sum') * 1 + this.tableData_step_mix_field('zhizao_sum', item * 1))))
          })

          this.echartData[this.checkEchartDataIndex('profitMoeny')].data.data = data
          this.echartData[this.checkEchartDataIndex('profitMoeny')].flagComplete = true
        }
      )
    },
    createWatch_managerProfitMoeny() {
      this.$watch(
        () => [this.xAxisData, this.avg_price_no_tax_unit_money, this.sum_pass_sold_num_price, this.sum_pass_per, this.sum_fixed_total_money, this.tableData_step_mix],
        (info) => {
          const xAxis = info[0]
          const price = info[1]
          const cost = info[2]
          const per = info[3]
          const fixed_money = info[4]

          const data = []
          xAxis.map((item) => {
            //产量
            const num = checkZero(per) ? 0 : item / (per / 100)
            data.push(numberToFixed(item * price - (cost * num * 1 + fixed_money * 1 + this.tableData_step_mix_field('total', item) * 1)))
          })

          this.echartData[this.checkEchartDataIndex('managerProfitMoeny')].data.data = data
          this.echartData[this.checkEchartDataIndex('managerProfitMoeny')].flagComplete = true
        }
      )
    },

    // createWatch_mixMoeny() {
    //     this.$watch(
    //     () => [this.xAxisData,this.mixRangeData],
    //     (info) => {
    //     	const xAxis = info[0]
    //       const value = info[1]

    //       let forminIndex =0 ;
    //       const data = []
    //       value.map( (item) => {
    //         const min = item.min*1;
    //         const max = item.max*1;
    //         const name = item.money+'( '+ min + '~' + max + ' )';

    //         const {letMin,letMax} = getMindValue(xAxis,min,max)
    //         data.push([
    //             {coord:[letMin, item.money*1], label: { formatter: name, position: 'insideMiddleTop' }},
    //             {coord:[letMax,item.money*1]},
    //         ])
    //       })

    //       this.echartData[this.checkEchartDataIndex('mixMoeny')].data = {
    //         name: '',
    //         type: 'line',
    //         markLine: {
    //           yAxisIndex: 0,
    //           symbol: 'none', //去掉箭头
    //           data: data
    //         }
    //       }
    //     }
    //   )
    // },

    createWatch_mixMoeny_line() {
      this.$watch(
        () => [this.xAxisData, this.mixRangeData],
        (info) => {
          const xAxis = info[0]
          const value = info[1]

          const data = [
            { flagComplete: false, name: 'mixMoeny', data: {...lineStyle, name: '总混合成本', type: 'line', stack: 'mixMoeny',lineStyle:{width:10}, data: [], showEchartlegend: false } }
          ]
          const data2 = []
          xAxis.map((item) => {
            data2.push(numberToFixed(this.tableData_step_mix_field('total', item) * 1))
          })
          value.map((item, index) => {
            const min = item.min * 1
            const max = item.max * 1
            const name = '( ' + min + '~' + max + ' )'
            const { letMin, letMax } = getMindValue(xAxis, min, max)

            data.push({
              name: '混合成本' + name,
              flagComplete: true,
              data: {
                ...lineStyle,
                name: '混合成本' + name,
                showEchartlegend: false,
                type: 'line',
                stack: 'mixMoeny' + index,
                data: [
                  [letMin, item.money * 1],
                  [letMax, item.money * 1]
                ]
              }
            })
          })
          // const index = this.checkEchartDataIndex('mixMoeny');
          // const spliceLen = this.echartData.length-(index+1);
          // console.log('>>>>>>>> index <<<<<<<<<<<')
          // console.log(index)
          // console.log(spliceLen)
          // if(spliceLen>0){
          // this.echartData.splice(index,spliceLen)
          // }
          if(data.length>1){
            data[0].flagComplete = true
            data[0].data.data = data2
            const echartData = defaultEchartData.concat(data)
            // echartData[this.checkEchartDataIndex('mixMoeny')].flagComplete = true
            // echartData[this.checkEchartDataIndex('mixMoeny')].data.data = data2
            this.echartData = echartData
          }
          

          // this.echartData[this.checkEchartDataIndex('mixMoeny')].flagComplete = true
          // this.echartData[this.checkEchartDataIndex('mixMoeny')].data.data = data2
        }
      )
    },
    checkEchartDataIndex(name) {
      let index = -1
      for (let i = 0; this.echartData.length; i++) {
        const info = this.echartData[i]
        if (info && info.name === name) {
          index = i
          break
        }
      }
      return index
    },
    drawEchart() {
      if (Object.keys(this.echartlegend).length != this.echartData.length) {
        const echartlegend = {}
        this.echartData.map(function (item) {
          const data = item.data || {}
          const name = data.name
          echartlegend[name] = !!data.showEchartlegend
        })
        this.echartlegend = echartlegend
        this.showEchartlegend = echartlegend
      } else {
        this.showEchartlegend = {}
      }

      this._drawEchart()

    },
    _drawEchart() {
      const seriesData = this.echartData.map((item) => item.data)
      //获取Y轴最大的值
      var seriesMaxValue = 0
      seriesData.map(function (item) {
        for (let i = 0; i < (item.data || []).length; i++) {
          const value = item.data[i]
          if (!isNaN(value) && value * 1 > seriesMaxValue * 1) {
            seriesMaxValue = value * 1
          }
        }
      })
      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params, ticket, callback) {
            let str = '销量：' + (params[0] || {}).name + '<br />'
            params.map(function (item, index) {
              str += item.marker + item.seriesName + ' : ' + item.value + '<br />'
            })
            return str
          }
        },
        legend: {
          data: this.echartData.map((item) => item.data.name),
          selected: this.showEchartlegend,
          orient: 'vertical',
          align: 'left',
          padding: 10,
          top: '25',
          left: '115',
          backgroundColor: '#ffffff'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xAxisData
        },
        yAxis: {
          type: 'value',
          max: this.svpYMaxValue
        },
        graphic: echartGraphic(),
        series: this.echartData.map((item) => item.data)
      }
      // console.log(this.xAxisData)
      // console.log(this.echartData.map((item) => item.data))
      // 释放资源
      myChart.setOption(option)
    }
  }
}
</script>

<style>
</style>
