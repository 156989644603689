<template>
    <div class="guide-box" v-if="moduleName">
        <div class="RichText" >{{typeContent}}</div>
    </div>
   <div class="guide-box" v-else>
        <div class="subtitle">本节导引</div>
        <div class="RichText">{{typeContent}}</div>
    </div>
    
</template>

<script>
export default {
    name:'ToolsItemIntro',
    props:{
        moduleName:{
            type:String,
            default:''
        },
        type:{
            type:String,
            default:''
        },
        field:{
            type:String,
            default:''
        },
    },
    data(){
        return {
            
        }
    },
    computed:{
        itemObj(){
            return this.$store.state.tools.itemIntro[this.type]||{}
        },
        typeContent(){
            return this.itemObj[this.field]||''
        }
    },
    mounted(){
       
    },
    methods:{
        getData(){
         
        }
    }
}
</script>
